import React, {useState, useContext,useEffect} from "react";
import {useMutation, useQuery} from "@apollo/client";
import {GET_SCORE_BY_APPLICANTID} from "./graphql/queries";
import {CREATE_SCORE, updateScoreMutation} from "./graphql/mutations";
import "../../common/styles/card.css";
import greenCheckTime from "../../../assets/imgs/greenCheckTime.svg";
import dangerYellow from "../../../assets/imgs/dangerYellow.svg";
import Spinner from '../../common/spinner';


const ScoresCard = ({inAppForm, updateIsScore, isNested, applicant}) => {
  // inAppForm = true;
  const [inAppFormState, setInAppForm] = useState(inAppForm);
  
  console.log(inAppForm);
  console.log("applicant in scores =>", applicant)

  const [dataScore, setDataScore] = useState({});
  const [usmleStep1Score, setUsmlStep1Score] = useState("");
  const [usmleStep1Error, setUsmleStep1Error] = useState(false);
  const [usmleStep2Score, setUsmlStep2Score] = useState("");
  const [usmleStep3Score, setUsmlStep3Score] = useState("");
  const [usmleStep2Error, setUsmleStep2Error] = useState(false);
  const [usmleStep3Error, setUsmleStep3Error] = useState(false);
  const [comlexLevel1Score, setComlexLevel1Score] = useState("");
  const [comlex1Error, setComlex1Error] = useState(false);
  const [comlexLevel2Score, setComlexLevel2Score] = useState("");
  const [comlex2Error, setComlex2Error] = useState(false);
  const [anyFails, setAnyFails] = useState("");
  const [anyFailsError, setAnyFailsError] = useState(false);
  const [testFailsDetails, setTestFailsDetails] = useState("");
  const [scoreRequestEmail, setScoreRequestEmail] = useState("");
  const [isEdit, setIsEdit] = useState(true);


  const [createScore, { data: createScoreData }] = useMutation(CREATE_SCORE,{
    onCompleted:()=>{
        refetch();
    }
  }
    );

  const [updateScore, { data: updateScoreData }] = useMutation(updateScoreMutation,{
    onCompleted:()=>{
        refetch();
    }
  }
    );

  const { loading, error, data, refetch } = useQuery(
    GET_SCORE_BY_APPLICANTID,
    {
      variables: { applicantId: applicant.id },
    }
  );

  useEffect(() => {
    if (data && data.getScoreByApplicantId) {
        setDataScore(data.getScoreByApplicantId);
        setUsmlStep1Score(data.getScoreByApplicantId.usmleStep1Score);
        setUsmlStep2Score(data.getScoreByApplicantId.usmleStep2Score);
        setUsmlStep3Score(data.getScoreByApplicantId.usmleStep3Score);
        setComlexLevel1Score(data.getScoreByApplicantId.comlexLevel1Score);
        setComlexLevel2Score(data.getScoreByApplicantId.comlexLevel2Score);
        setAnyFails(data.getScoreByApplicantId.previousUsmleComlexFails ? 'Yes' : 'No');
        setTestFailsDetails(data.getScoreByApplicantId.usmleComlexDescription);
        setScoreRequestEmail(data.getScoreByApplicantId.mspeReqEmailAdd);
        if (inAppForm && data.getScoreByApplicantId.stage === "Uploaded") {
          updateIsScore(true);
        }
        else if(inAppForm){
          updateIsScore(false);
        }
    }
  }, [data, createScoreData, updateScoreData]);






const checkForChanges = () => {

  const checkAnyFails = anyFails === 'Yes' ? true : false;
  if (isEdit) {
    return false;
  }
  if (dataScore.usmleStep1Score !== usmleStep1Score || dataScore.usmleStep2Score !== usmleStep2Score || dataScore.usmleStep3Score !== usmleStep3Score || dataScore.comlexLevel1Score !== comlexLevel1Score || dataScore.comlexLevel2Score !== comlexLevel2Score || dataScore.previousUsmleComlexFails !== checkAnyFails || dataScore.usmleComlexDescription !== testFailsDetails || dataScore.mspeReqEmailAdd !== scoreRequestEmail) {
    console.log('there are changes')
    console.log(dataScore.usmleStep1Score, usmleStep1Score)
    console.log(dataScore.usmleStep2Score, usmleStep2Score)
    console.log(dataScore.usmleStep3Score, usmleStep3Score)
    console.log(dataScore.comlexLevel1Score, comlexLevel1Score)
    console.log(dataScore.comlexLevel2Score, comlexLevel2Score)
    console.log(dataScore.previousUsmleComlexFails, checkAnyFails)
    console.log(dataScore.usmleComlexDescription, testFailsDetails)
    console.log(dataScore.mspeReqEmailAdd, scoreRequestEmail)
    
    return false;
  }else{
    console.log('there are no changes')
    return true;
  }
}

if (loading) {
  return <Spinner />;
}


    return (
        // <div className='card' style={{width:'100%'}}>
        <div className='card'>
            {loading && <Spinner />}


{/*  dataScore.stage === "Pending" */}
{/* data && data.getScoreByApplicantId */}

{(!data || !data.getScoreByApplicantId )&&(

<div className='card-header' style={{marginBottom:'2vh'}}>
              <div style={{display:'flex',flexDirection:'row', justifyContent:'space-between'}}>
            <h2 className="card-header-title" style={{marginBottom:'2vh'}}>Score Verification</h2>
                <div className="status-tag-pending">
                <img src={dangerYellow} alt="yellow danger" style={{ width: '2vw' }}/>
                <span>Pending upload</span>
                </div>
            </div>
            </div>



) }

{data && data.getScoreByApplicantId &&  dataScore.stage === "Pending" && (
  <div className='card-header' style={{marginBottom:'2vh'}}>
              <div style={{display:'flex',flexDirection:'row', justifyContent:'space-between'}}>
            <h2 className="card-header-title" style={{marginBottom:'2vh'}}>Score Verification</h2>
                <div className="status-tag-pending">
                <img src={dangerYellow} alt="yellow danger" style={{ width: '2vw' }}/>
                <span>Pending upload</span>
                </div>
            </div>
            </div>


)}



{data && data.getScoreByApplicantId &&  dataScore.stage === "Uploaded"&&(
  <>
            <div className='card-header' style={{marginBottom:'2vh'}}>
              <div style={{display:'flex',flexDirection:'row', justifyContent:'space-between'}}>
            <h2 className="card-header-title" style={{marginBottom:'2vh'}}>Score Verification</h2>

            <div className="status-tag-uploaded">
            <img src={greenCheckTime} alt="green check" style={{ width: '2vw' }} />
            <span>Uploaded</span>
            </div>

            </div>
            {/* <p style={{textAlign:'justify'}}>These are the scores that we have received from NBME</p> */}
            </div>
            <div className="card-body">
            <div className='step1-upper-right-container'>
    <div className='step1-upper-right-inner-container'>
    <div className='application-form-field-group'>
    <label htmlFor="usmlStep1Score" className='applicant-form-field-label'>USMLE Step 1 Score</label>
      <label className="application-title" style={{ fontSize: '1.2rem'}}>{usmleStep1Score}</label>

   
    <div className='application-form-error-message' style={{margin:'0',width:'17.5vw'}}> 
    {usmleStep1Error &&
    <p>Please enter a valid score. (Numerical: 1-300,Pass/Fail: Pass, Fail)</p>
    }
  </div>
  </div>

  <div className='application-form-field-group'>
  <label htmlFor="usmlStep2Score" className='applicant-form-field-label'>USMLE Step 2 Score</label>

     <label className="application-title" style={{ fontSize: '1.2rem'}}>{usmleStep2Score}</label>


  <div className='application-form-error-message' style={{margin:'0',width:'17.5vw'}}> 
  {usmleStep2Error &&
    <p>Please enter a valid score. (Numerical: 1-300)</p>
    }
  </div>
    </div>

    <div className='application-form-field-group'>
  <label htmlFor="usmlStep2Score" className='applicant-form-field-label'>USMLE Step 3 Score</label>

     <label className="application-title" style={{ fontSize: '1.2rem'}}>{usmleStep3Score}</label>


  <div className='application-form-error-message' style={{margin:'0',width:'17.5vw'}}> 
  {usmleStep3Error &&
    <p>Please enter a valid score. (Numerical: 1-300)</p>
    }
  </div>
    </div>


    <div className='application-form-field-group'>
  <label  className='applicant-form-field-label'>Any previous failed STEP/COMLEX exam attempts?</label>
 
       <label className="application-title" style={{ fontSize: '1.2rem'}}>{anyFails}</label>
  
    <div className='application-form-error-message' style={{margin:'0',width:'17.5vw'}}> 
  {anyFailsError &&
    <p>Please enter a valid response (Yes or No)</p>
    }
  </div>
    </div>
    </div>

</div>

<div className='step1-upper-right-container'>
    <div className='step1-upper-right-inner-container' style={{marginBottom:'3vh'}}>
    <div className='application-form-field-group'>
    <label htmlFor="comlexLevel1Score" className='applicant-form-field-label'>COMLEX Level 1 Score</label>
  
        <label className="application-title" style={{ fontSize: '1.2rem'}}>{comlexLevel1Score}</label>
       
  
    <div className='application-form-error-message' style={{margin:'0',width:'17.5vw'}}> 
    {comlex1Error &&
    <p>Please enter a valid score. (Numerical: 9-999,Pass/Fail: Pass, Fail)</p>
    }
  </div>
  </div>

  <div className='application-form-field-group'>
  <label htmlFor="comlexLevel2Score" className='applicant-form-field-label'>COMLEX Step 2 Score</label>
       <label className="application-title" style={{ fontSize: '1.2rem'}}>{comlexLevel2Score}</label>
      
 
  <div className='application-form-error-message' style={{margin:'0',width:'17.5vw'}}> 
  {comlex2Error &&
    <p>Please enter a valid score. (Numerical: 9-999)</p>
    }
  </div>
    </div>
    
    {anyFails === 'Yes' &&
    <div className='application-form-field-group'>
  <label  className='applicant-form-field-label'>Please list the exams and their dates</label>
  <textarea
  disabled={true}
  value={testFailsDetails}
    name="testFailsDetails"
    className='application-form-standard-input one'
    style={{height:'22vh', margin:'0', width:'32vw'}}
  />
 
    </div>
}
    </div>
    
    {/* <div className='step1-upper-right-container'>
        <div className="card-body">
        {data.getScoreByApplicantId&&
        <div style={{ display: 'flex', flexDirection: 'row', flexFlow: 'wrap', gap: '.5vw' }}>
            <p style={{ textAlign: 'justify', lineHeight: '2.7vh' }}>Your request has been sent to:</p>
            <p style={{ textAlign: 'justify', lineHeight: '2.7vh', fontWeight: '800' }}>{dataScore.mspeReqEmailAdd}</p>
            <p style={{ textAlign: 'justify', lineHeight: '2.7vh' }}>on</p>
            <p style={{ textAlign: 'justify', lineHeight: '2.7vh', fontWeight: '800' }}>{new Date(parseInt(dataScore.requestedAt)).toLocaleDateString()}</p>
          </div>
}
        </div>




    
    </div> */}
</div>
            </div>
            </>
)}


        </div>
    );
}

export default ScoresCard;