import React, { useState, useEffect, useContext } from 'react';
import logo from '../../../../assets/imgs/logo.svg'
import { useMutation } from '@apollo/client';
import {UPDATE_RUBRIC_TEMPLATE} from '../graphql/mutations';
import {ProgramIdContext} from '../../progIdContext'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const EditFieldModal = ({ isOpen, onClose, onEditRubric, lastRubricOrder, existingRubric, rubricsNames }) => {

    const programId = useContext(ProgramIdContext);
    const [updateRubricTemplate] = useMutation(UPDATE_RUBRIC_TEMPLATE);
    const [label, setLabel] = useState('');
    const [inputType, setInputType] = useState('text');
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [maxScale, setMaxScale] = useState(5); // Default value set to 5
    const [lowestScaleText, setLowestScaleText] = useState('');
    const [highestScaleText, setHighestScaleText] = useState('');
    const [showError, setShowError] = useState(false)
    const [editableIndex, setEditableIndex] = useState(null);
    const [editableOption, setEditableOption] = useState('');
    const [editableScore, setEditableScore] = useState('');

    useEffect(() => {
        setLabel(existingRubric ? existingRubric.label : '');
        setInputType(existingRubric ? existingRubric.inputType : 'text');
        setDropdownOptions(existingRubric ? existingRubric.dropdownOptions : []);
        setMaxScale(existingRubric ? existingRubric.maxScale : 5);
        setLowestScaleText(existingRubric ? existingRubric.lowestScaleText : '');
        setHighestScaleText(existingRubric ? existingRubric.highestScaleText : '');
    }, [existingRubric]);

    if (!isOpen) return null;

    const handleAddOption = (option) => {
        setDropdownOptions([...dropdownOptions, option]);
    };

    const handleRemoveOption = (index) => {
        setDropdownOptions(prevOptions => prevOptions.filter((_, i) => i !== index));
    };

    const handleEditScore = (index, option) => {
        console.log("inside handleEditScore")
        setEditableIndex(index);
        setEditableScore(option.score);
    }

    const handleSaveScore = (index) => {
        // Create a new array with the same objects except for the one being updated
        const updatedDrop = dropdownOptions.map((option, idx) => {
            if (idx === index) {
                // Create a new object with the updated score
                return { ...option, score: parseInt(editableScore) };
            }
            // remove __typename from the object
            return option;
        });
        setDropdownOptions(updatedDrop);
        setEditableIndex(null);
    };


    const handleEditOption = (index, option) => {
        console.log("inside handleEditOption", index)
        setEditableIndex(index);
        setEditableOption(option.option);
    };

    const handleSaveOption = (index) => {
        const updatedDrop = dropdownOptions.map((option, idx) => {
            if (idx === index) {
                // Create a new object with the updated score
                return { score: option.score, option: editableOption };
            }
            return option;
        });
        setDropdownOptions(updatedDrop);
        setEditableIndex(null);
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
    
        const items = Array.from(dropdownOptions);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
    
        setDropdownOptions(items);
    };


    const handleUpdateRubric = () => {
        // if(rubricsNames.includes(label)) {
        //     setShowError(true)
        //     return 
        // } else  setShowError(false)

// check if the dropdown options have __typename and remove it
        const updatedDropdownOptions = dropdownOptions.map(option => {
            if (option.__typename) {
                const { __typename, ...rest } = option;
                return rest;
            }
            return option;
        });
        const newRubric = {
            id: existingRubric.id,
            label: label,
            inputType: inputType,
            dropdownOptions: inputType === 'dropdown' ? updatedDropdownOptions : [],
            maxScale: inputType === 'scale' ? maxScale : undefined,
            lowestScaleText: inputType === 'scale' ? lowestScaleText : undefined,
            highestScaleText: inputType === 'scale' ? highestScaleText : undefined,
            order: existingRubric.order,
        };
        onEditRubric(newRubric);
        console.log("existingRubric =>", existingRubric)
        updateRubricTemplate({ variables: { programId:programId, rubricTemplateId:existingRubric.id, input:newRubric } });
        setLabel('');
        setInputType('text');
        setDropdownOptions([]);
        setMaxScale(5);
        setLowestScaleText('');
        setHighestScaleText('');
        onClose(); // Close the modal
    };

    return (
        <div className="modal-backdrop" style={{zIndex: 999}}>
            <div className="add-field-modal" style={{height:'94vh'}}>
                <div className='add-field-modal-header'>
                <h2>EDIT FIELD</h2>
                <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
                </div>
                <form style={{height:'61vh', overflowY:'scroll'}}>
                    <div className="form-group">
                        <label style={{
                            fontFamily: 'Montserrat',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '16px',
                            color: '#848484',
                            lineHeight: '24px',
                            marginBottom: '19px',
                            }}>Label</label>
                        <input
                            type="text"
                            className="application-form-standard-input"
                            value={label}
                            onChange={(e) => setLabel(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                    <label style={{
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '16px',
                        color: '#848484',
                        lineHeight: '24px',
                        marginBottom: '19px',
                        }}>Input Type</label>
                        <select
                            value={inputType}
                            onChange={(e) => setInputType(e.target.value)}
                            className="application-form-standard-input"
                        >
                            <option value="text">Text</option>
                            <option value="dropdown">Dropdown</option>
                            <option value="scale">Scale</option>
                        </select>
                    </div>
                    {inputType === 'dropdown' && (
                        <div className="form-group">
                            <label>Dropdown Options</label>
                            <input
                                type="text"
                                className="application-form-standard-input"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleAddOption(e.target.value);
                                        e.target.value = ''; // Clear input after adding
                                    }
                                }}
                                placeholder="Type option and press Enter"
                            />
                                      <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId="dropdownOptions">
                                    {(provided) => (
                                        <ul {...provided.droppableProps} ref={provided.innerRef}>
                                            {dropdownOptions.map((option, index) => (
                                                <Draggable key={option.option} draggableId={option.option} index={index}>
                                                    {(provided) => (
                                                        <li 
                                                            ref={provided.innerRef} 
                                                            {...provided.draggableProps} 
                                                            {...provided.dragHandleProps}
                                                            style={{ ...provided.draggableProps.style, border: '.2vh solid #145388', color: '#145388', fontSize: '3vh', padding: '1vh', borderRadius: '5px', marginTop: '1vh', marginBottom: '1vh' }}
                                                        >
                                                            {editableIndex === index ? (
                                                                <div style={{ display: 'flex', width: '20vw' }}>
                                                                <input
                                                                    type="text"
                                                                    value={editableOption}
                                                                    onChange={(e) => {
                                                                        console.log("editableOption =>", editableOption)
                                                                        setEditableOption(e.target.value)}}
                                                                    onBlur={() => handleSaveOption(index)}
                                                                    onKeyPress={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            handleSaveOption(index);
                                                                        }
                                                                    }}
                                                                    // autoFocus
                                                                />
                                                                    -
                                                                    <input
                                                                    type="text"
                                                                    value={editableScore}
                                                                    onChange={(e) => {
                                                                        console.log("editableScore =>", editableScore)
                                                                        setEditableScore(e.target.value)}}
                                                                    onBlur={() => handleSaveScore(index)}
                                                                    onKeyPress={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            handleSaveScore(index);
                                                                        }
                                                                    }}
                                                                    // autoFocus
                                                                />
                                                                </div>
                                                              
                                                            ) : (
                                                                <div>
                                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                                                       <div style={{ width:'6vw', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1vh'}}>
                                                                            <label onClick={() => handleEditOption(index, option)} style={{color: "#145388", fontSize: '1.4rem', cursor: 'pointer'}}>{option.option} </label>
                                                                            <label style={{color: "#145388", fontSize: '1.4rem'}}>-</label>
                                                                            <label onClick={() => handleEditScore(index, option)} style={{color: "#145388", fontSize: '1.4rem', cursor: 'pointer'}}>{option.score}</label>
                                                                        </div>

                                                                        <button type="button" onClick={() => handleRemoveOption(index)} style={{marginLeft: '10px', color:'red', fontWeight:'bold', fontSize:'2vh', float:'right'}}>X</button>
                                                                    </div>

                                                                </div>
                                                            )}
                                                        </li>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </ul>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    )}

{inputType === 'scale' && (
                        <div className="form-group">
                            <label>Number of Items in Scale (Max: 10)</label>
                            <input
                                type="number"
                                className="application-form-standard-input"
                                value={maxScale}
                                onChange={(e) => setMaxScale(Math.min(parseInt(e.target.value), 10))}
                            />
                            <label>Text for Left Side of Scale</label>
                            <input
                                type="text"
                                className="application-form-standard-input"
                                value={lowestScaleText}
                                onChange={(e) => setLowestScaleText(e.target.value)}
                            />
                            <label>Text for Right Side of Scale</label>
                            <input
                                type="text"
                                className="application-form-standard-input"
                                value={highestScaleText}
                                onChange={(e) => setHighestScaleText(e.target.value)}
                            />
                        </div>
                    )}
                    
                </form>
                <div className='button-container' style={{display:'flex', flexDirection:'row', justifyContent:'space-between', gap:'2vw'}}>
                    <button type="button" className='gradient-button' 
                    onClick={handleUpdateRubric}
                    disabled={label === '' || (inputType === "dropdown" && dropdownOptions.length === 0) || (inputType === "scale" && (lowestScaleText === '' || highestScaleText === '' || maxScale === '' || lowestScaleText === null || highestScaleText === null || maxScale === null))}
                    >Save</button>
                    <button type="button" className='white-button' onClick={onClose}>Cancel</button>
                    </div>
                   { showError && <label style={{ color: 'red'}}>Label name already exists. Please change it.</label>}
            </div>
        </div>
    );
};

export default EditFieldModal;