import React, { useState, useEffect } from 'react';
import { useTable, useSortBy, useRowSelect } from 'react-table';
import Pagination from './paginationComponent';
import PageSizeSelector from './pageSizeSelector';
import Spinner from '../../../common/spinner';

const GeneralTable = ({
  columns,
  data,
  onSelectionChange,
  onSelectedApplication,
  sortData,
  limit,
  totalPages,
  currentPage,
  handlePageChange,
  handlePageSizeChange,
  loadingData,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { selectedRowIds },
  } = useTable({ 
    columns, 
    data,
  }, useSortBy, useRowSelect);
  const [prevSelectedRows, setPrevSelectedRows] = useState([]);

  useEffect(() => {
    const selectedRows = rows.filter(row => selectedRowIds[row.id]);
    const simplifiedSelectedRows = selectedRows.map(row => row.original); // Only keep the original row data

    if (JSON.stringify(simplifiedSelectedRows) !== JSON.stringify(prevSelectedRows)) {
      onSelectionChange(simplifiedSelectedRows);
      setPrevSelectedRows(simplifiedSelectedRows);
    }
  }, [onSelectionChange, rows, selectedRowIds, prevSelectedRows]);

  const handleClickCell = row => {
    console.log('row clicked', row);
  };

  const sortClicked = (direction, column) => {
    console.log('sorting =>', column.id, direction);
    sortData(column.id, direction);
  };

  return (
    <div style={{ overflowX: 'auto', minHeight: '72vh', height: '130vh' }}>
      <table className="table-programs-list" {...getTableProps()}>
        <thead className="tHead-programs-list">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                column.id !== 'greenDot' && column.id !== 'view' && column.id !== 'selection' && column.id !== 'actions' && column.id !== 'applicationIds' ? (
                  <th
                    style={{ lineHeight: '4vh' }}
                    className="table-header-title"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    onClick={(e) => {
                      column.getSortByToggleProps().onClick(e);
                      sortClicked(column.isSortedDesc, column);
                    }}
                  >
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                    {column.render('Header')}
                  </th>
                ) : (
                  <th className="table-header-title" {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                )
              ))}
            </tr>
          ))}
        </thead>
        {
          loadingData === true ? null
            :
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                const rowStyle = row.values.userName === "Pending Invitation" ? { background: 'gainsboro', fontStyle: 'italic', fontWeight: 'bold' } : { height: '4vh', width: '5vw' };
                return (
                  <tr className='programs-list-table-row table-row-height' {...row.getRowProps()} style={rowStyle}>
                    {row.cells.map(cell => (
                      <td style={{}} className='programs-list-table-row-text' onClick={() => handleClickCell(row.original)} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
        }
      </table>
      {
        loadingData === true && <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '3vh', marginBottom: '3vh' }}><Spinner /></div>
      }
      <div className="faculty-application-table-footer" style={{ height: '4vh' }}>
        <PageSizeSelector itemsPerPage={limit} onPageSizeChange={handlePageSizeChange} />
        <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
      </div>
    </div>
  );
};

export default GeneralTable;
