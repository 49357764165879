import React,{ useState, useEffect } from "react";
import "./styles/settings.css";
import Card from "../../common/card";
import { updateUserMutation, updateUserPasswordMutation } from '../applicantComponents/graphql/mutations';
import client from '../../../config/apolloClient';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../applicantComponents/applicationFormSteps/getCroppedImg';
import uploadFile from '../../../assets/imgs/uploadFile.svg';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input'
import packageInfo from '../../../../package.json';


const Settings = ({ applicant, getApplicant }) => {
    const scoreValidationSubtitle= `Please enter your standardized exam scores. These scores will be verified by your medical school's student affairs office at the time of MSPE/Dean's Letter upload. This verification system helps us reduce cost by eliminating the additional NBME fee. Your MSPE will be released September 27th on the AAMC timeline.`
    const [isEditable, setIsEditable] = useState(false)
    const [isChangePassword, setIsChangePassword] = useState(false)
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [institution, setInstitution] = useState("")
    const [currentPass, setCurrentPass] = useState("")
    const [newPass, setNewPass] = useState("")
    const [confirmPass, setConfirmPass] = useState("")
    const [imageSrc, setImageSrc] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [editSuccess, setEditSuccess] = useState(false);

    console.log(applicant.profilePhotoUrl, 'profilePhotoUrl')

    const updateFormData = () => {
        if(applicant) {
            setFirstName(applicant.firstName)
            setLastName(applicant.lastName)
            setEmail(applicant.email)
            setPhone(applicant.phone)
            setInstitution(applicant.institution)

        }
    }


    const updateData = () => {
        client.mutate({
            mutation: updateUserMutation,
            variables: {
                id: applicant.id,
                input: {
                    firstName,
                    lastName,
                    email,
                    phone,
                    institution
                }
            }
        })
        .then(res => {
            console.log("user updated!", res)
            setEditSuccess(true)
            setTimeout(() => {
                setEditSuccess(false)
            }, 3000);
        })
    }


    useEffect(() => {
        updateFormData()
    }, [])


    const handleImageChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            setImageSrc(reader.result);
          };
          reader.readAsDataURL(file);
        }
      };

      const handleCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
      };


    const updateUserPassword = async () => {
        if(newPass !== confirmPass) {
            // alert password different
            console.log("different pass!")
        } else {
            client.mutate({
                mutation: updateUserPasswordMutation,
                variables: {
                    id: applicant.id,
                    previousPass: currentPass,
                    newPass
                }
            })
            .then(res => {
                setPasswordChanged(true)
                setTimeout(() => {
                    setPasswordChanged(false)
                }, 5000);
            })
        }
    }

    const handleCancelCrop = () => {
        setImageSrc(null);
        setCrop({ x: 0, y: 0 });
        setZoom(1);
        setCroppedAreaPixels(null);
        setCroppedImage(null);
      };

    const handleUpload = async () => {
        setIsLoading(true);
        const croppedBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
        const formData = new FormData();
        formData.append('image', croppedBlob); // Append the cropped image blob
        formData.append('loginToken', localStorage.getItem('login_token'));
      
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/upload_profile`, {
          method: 'POST',
          headers: { 'App-Version': packageInfo.version },
          body: formData
        });
        const data = await response.json();
        console.log('Image uploaded successfully:', data.imageUrl);
        setCroppedImage(null); // Clear the cropping state after upload
      
        // Update the applicant profile photo URL in the parent component
        // updateUserData({ ...values, profilePhotoUrl: data.imageUrl }, 1);
        getApplicant()
        // Set imageSrc to the new image URL
        setImageSrc(data.imageUrl);
        setIsLoading(false);
      };

    console.log("applicant in settings =>", applicant)
  
    return (
        <div className='applicant-home-container'>
            <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop2' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="applicant-home-header">
                <h2 className="applicant-home-header-title">EDIT PROFILE</h2>
                <seperator className="applicant-home-header-seperator"/>
            </div>
            {
                imageSrc ? (
                    <div>
                      <div className="crop-container">
                        <Cropper
                          image={imageSrc}
                          crop={crop}
                          zoom={zoom}
                          aspect={1}
                          onCropChange={setCrop}
                          onZoomChange={setZoom}
                          onCropComplete={handleCropComplete}
                        />
                      </div>
                      <div className="crop-buttons">
                      <button onClick={handleUpload} style={{ fontFamily: 'Montserrat'}}>Upload Image</button>
        <button onClick={handleCancelCrop} style={{ fontFamily: 'Montserrat'}}>Cancel</button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <img id="previewImage" style={{margin:'0'}} src={applicant.profilePhotoUrl ? applicant.profilePhotoUrl : "https://via.placeholder.com/282"} alt="profile" className="settings-profile-image"/>   
                      <label htmlFor="imageInput" style={{cursor:'pointer',
    position: 'relative',
    top: '-14%',
    left: '23%',   
    display:'flex',
    width:'4vw'}}>
                        <img src={uploadFile}></img>
                      </label>
                      <input type="file" style={{display:'none'}} id="imageInput" accept="image/*" onChange={handleImageChange}/>
                    </>
                  )
            }
                {/* <img id="previewImage" src={applicant.profilePhotoUrl ? applicant.profilePhotoUrl : "https://via.placeholder.com/282"} alt="profile" className="settings-profile-image"/>
                <input type="file" id="imageInput" accept="image/*" onChange={handleImageChange} style={{marginBottom:'4vh'}}/> */}
                <h2 className="settings-body-top">Edit Personal Information</h2>
                { editSuccess && <div style={{ display: 'flex', justifyContent: 'flex-end', color: "#145388"}}><label>Personal Information Changed Successfully.</label></div> }
                <div className='settings-container'>
                    <row className='settings-row'>
                         <column className='settings-column'>
                                <label className='settings-label' htmlFor="firstName">First Name</label>
                                <input className="settings-input"  value={firstName} type="firstName" id="firstName" disabled={!isEditable} onChange={(e) => setFirstName(e.target.value)} />
                            </column>

                            <column className='settings-column'>
                                <label className='settings-label' htmlFor="lastName">Last Name</label>
                                <input className="settings-input" value={lastName} type="lastName" id="lastName" disabled={!isEditable} onChange={(e) => setLastName(e.target.value)}/>
                            </column>

                            <column className='settings-column'>
                                <label className='settings-label' htmlFor="email">Email Address</label>
                                <input className="settings-input" value={email} type="email" id="email" disabled={!isEditable} onChange={(e) => setEmail(e.target.value)}/>
                            </column>
                    </row>

                    <row className='settings-row'>
                    <column className='settings-column'>
                                <label className='settings-label' htmlFor="phoneNumber">Phone Number</label>
                                <PhoneInput
                                    style={{background:'linear-gradient(0deg, #f2f2f2, #f2f2f2), #fff'}}
                                    international
                                    defaultCountry="US"
                                    value={phone}
                                    onChange={newPhone => setPhone(newPhone)}
                                    className='application-form-standard-input'
                                    disabled={!isEditable}
                                    />
                                
                                
                                {/* <input className="settings-input" value={phone} type="phoneNumber" id="phoneNumber" disabled={!isEditable} onChange={(e) => setPhone(e.target.value)}/> */}
                            </column>
{/* 
                            <column className='settings-column'>
                                <label className='settings-label' htmlFor="institution">Institution</label>
                                <input className="settings-input" value={institution} type="institution" id="institution"  disabled={!isEditable} onChange={(e) => setInstitution(e.target.value)}/>
                            </column> */}
                    </row>
                </div>
                <row className='settings-row1'>
                {!isEditable &&(<button className="edit-button" onClick={()=>setIsEditable(!isEditable)}>Edit</button>)}
                {isEditable &&(<button className="edit-button" onClick={()=>{
                    setIsEditable(!isEditable)
                    updateData()
                }}>Save</button>)}
                {isEditable &&(<button className="edit-button" onClick={()=>setIsEditable(!isEditable)} style={{color:'#145388', border:' solid #145388', backgroundColor:'white', position:'relative', }}>Cancel</button>)}
                </row>
                

                <h2 className="settings-body-top">Change Password</h2>
                { passwordChanged && <div style={{ display: 'flex', justifyContent: 'flex-end', color: "#145388"}}><label>Password Changed Successfully.</label></div> }
                <row className='settings-row'>
                    
                    <column className='settings-column'>
                                <label className='settings-label' htmlFor="phoneNumber">Current Password</label>
                                <input className="settings-input" type="password" id="password"  value={currentPass} onChange={(e) => setCurrentPass(e.target.value)}/>
                            </column>

                            <column className='settings-column'>
                                <label className='settings-label' htmlFor="institution">New Password</label>
                                <input className="settings-input" type="password" id="password"  value={newPass} onChange={(e) => setNewPass(e.target.value)}/>
                            </column>
                            <column className='settings-column'>
                                <label className='settings-label' htmlFor="institution">Confirm Password</label>
                                <input className="settings-input" type="password" id="password" value={confirmPass} onChange={(e) => setConfirmPass(e.target.value)}/>
                            </column>
                    </row>
                    <row className='settings-row1'>
                <button className="edit-button" onClick={updateUserPassword}>Update Password</button>
              
                </row>
                


        </div>
    );
}

export default Settings;

