import { gql } from "@apollo/client";

export const GET_PROGRAM_USERS_BY_PROGRAM_ID = gql`
query getProgramMembersByProgramIdWithSort($programId: ID!, $sort: SortInput) {
  getProgramMembersByProgramIdWithSort(programId: $programId, sort: $sort) {
      userId {
        id
        firstName
        lastName
        email
        isPending
      }
      roles
    }
  }
`;