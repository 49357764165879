import React, { useState,useEffect, useContext } from "react";
import "../../common/styles/card.css";
import greenCheckTime from "../../../assets/imgs/greenCheckTime.svg";
import dangerYellow from "../../../assets/imgs/dangerYellow.svg";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_MSPES_BY_APPLICANT_ID,
  GET_TRANSRIPTS_BY_APPLICANT_ID,
} from "./graphql/queries";
import { CreateMspe, CreateTranscript } from "./graphql/mutations";
import {UserContext} from '../../../context/userContext';
import Spinner from '../../common/spinner';
import { Formik, Form, Field, ErrorMessage } from 'formik';



const Card = ({ header, cardInfo, inAppForm, updateIsMSPE, updateIsTranscript }) => {
  const { user } = useContext(UserContext);
  const [body, setBody] = useState(cardInfo);
  const [mspe, setMspe] = useState(null);
  const [transcript, setTranscript] = useState(null);
  const [mspeReqEmail, setMspeReqEmail] = useState("");
  const [transcriptReqEmail, setTranscriptReqEmail] = useState("");
  const [isEditTranscript, setIsEditTranscript] = useState(false);
  const [isEditMspe, setIsEditMspe] = useState(false);


const [createMspe] = useMutation(CreateMspe,{
        onCompleted:()=>{
            refetchMspe();
        }
}
    );
const [createTranscript] = useMutation(CreateTranscript,{
    onCompleted:()=>{
        refetchTranscript();
    }
});

  const { loading:loadingMspe, error:errorMspe, data:dataMspe, refetch:refetchMspe } = useQuery(
    GET_MSPES_BY_APPLICANT_ID,
    {
      variables: { applicantId: user.userId },
    }
  );


  const { loading:loadingTranscript, error:errorTranscript, data:dataTranscript, refetch:refetchTranscript } = useQuery(
    GET_TRANSRIPTS_BY_APPLICANT_ID,
    {
      variables: { applicantId: user.userId },
    }
  );

  useEffect(() => {
    refetchMspe();

}
    , [CreateMspe]);

useEffect(() => {
    refetchTranscript();

}
    , [CreateTranscript]);
    

    useEffect(() => {
        if (dataMspe && dataMspe.getMspesByApplicantId.length > 0) {
            setMspe(dataMspe.getMspesByApplicantId[0]);
            console.log("mspe =>", dataMspe.getMspesByApplicantId[0])
            setMspeReqEmail(dataMspe.getMspesByApplicantId[0].uploaderEmail)
            if(inAppForm&&dataMspe.getMspesByApplicantId[0].stage === "Uploaded"){
              updateIsMSPE(true);
            }else if (inAppForm){
              updateIsMSPE(false);
            }
        }
    }, [dataMspe]);
    


    useEffect(() => {
        if (dataTranscript && dataTranscript.getTranscriptsByApplicantId.length > 0) {
            setTranscript(dataTranscript.getTranscriptsByApplicantId[0]);
            setTranscriptReqEmail(dataTranscript.getTranscriptsByApplicantId[0].uploaderEmail)
            if(inAppForm&&dataTranscript.getTranscriptsByApplicantId[0].stage === "Uploaded"){
                updateIsTranscript(true);
            }else if (inAppForm) {
                updateIsTranscript(false);
            }
        }
    }, [dataTranscript]);


const handleMspeRequest = () => {
    createMspe({
        variables: {
            input: {
                applicantId: user.userId,
                mspeReqEmail: mspeReqEmail,
            }
        },
    });
};

const handleTranscriptRequest = () => {
    createTranscript({
        variables: {
            input: {
                applicantId: user.userId,
                transcriptReqEmail: transcriptReqEmail,
            }
        },
    });
};



  return (
    <div className="card" style={{padding:'2vh', width:'38vw'}}>
  {body === "mspe" && (
  <>
    {loadingMspe && <Spinner />}
    <>
      <div className="card-header" style={{ marginBottom: '2vh', display:'flex' ,justifyContent:'space-between'  }}>
        <h2 className="card-header-title">{header}</h2>
        {
          mspe && mspe.stage === "Uploaded" && 
          <div className="status-tag-uploaded">
            <img src={greenCheckTime} alt="green check" style={{ width: '2vw' }} />
            <span>Uploaded</span>
          </div>
        }
        {
          (!mspe || mspe.stage !== "Uploaded") && 
          <div className="status-tag-pending">
            <img src={dangerYellow} alt="yellow danger" style={{ width: '2vw' }}/>
            <span>Pending upload</span>
          </div>
        }
      </div>          
    </>
  </>
)}
{body === "transcript" && 
  <>
    <div className="card-body">
      {loadingTranscript && <Spinner />}
      <>
        <div className="card-header" style={{ marginBottom: '2vh', display:'flex' ,justifyContent:'space-between'  }}>
          <h2 className="card-header-title">{header}</h2>
          {
            transcript && transcript.stage === "Uploaded" && 
            <div className="status-tag-uploaded">
              <img src={greenCheckTime} alt="green check" style={{ width: '2vw' }} />
              <span>Uploaded</span>
            </div>
          }
          {
            (!transcript || transcript.stage !== "Uploaded") && 
            <div className="status-tag-pending">
              <img src={dangerYellow} alt="yellow danger" style={{ width: '2vw' }}/>
              <span>Pending upload</span>
            </div>
          }
        </div>
      </>
    </div>
  </>
}
    </div>
  );
};




export default Card;
