import React, { useState, useEffect, useContext } from 'react';
import logo from '../../../../assets/imgs/logo.svg'
import Select from 'react-select';
import {useDropzone} from 'react-dropzone';
import { useParams } from 'react-router-dom';
import {CREATE_SCORE, updateScoreMutation} from "./graphql/mutations";
import {useMutation, useQuery} from "@apollo/client";
import { UserContext } from '../../../../context/userContext';
import ScoresCard from './ScoresCard';
import packageInfo from '../../../../../package.json';

const DocsModal = ({ isOpen, onClose, isUpdate, doc, applicant, setShouldRefetch, refetchUsers }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [usmleStep1Score, setUsmlStep1Score] = useState("");
    const [usmleStep1Error, setUsmleStep1Error] = useState(false);
    const [usmleStep2Score, setUsmlStep2Score] = useState("");
    const [usmleStep3Score, setUsmlStep3Score] = useState("");
    const [usmleStep2Error, setUsmleStep2Error] = useState(false);
    const [usmleStep3Error, setUsmleStep3Error] = useState(false);
    const [comlexLevel1Score, setComlexLevel1Score] = useState("");
    const [comlex1Error, setComlex1Error] = useState(false);
    const [comlexLevel2Score, setComlexLevel2Score] = useState("");
    const [comlex2Error, setComlex2Error] = useState(false);
    const [anyFails, setAnyFails] = useState("");
    const [anyFailsError, setAnyFailsError] = useState(false);
    // const [scoreRequestEmail, setScoreRequestEmail] = useState("");
    const [testFailsDetails, setTestFailsDetails] = useState("");
    const [score, setScore] = useState(null);
    const { user } = useContext(UserContext);




    const [createScore, { data: createScoreData }] = useMutation(CREATE_SCORE,{
        onCompleted:()=>{
            setShouldRefetch(true);
            // refetch();
            onClose();
            refetchUsers()
        }
      }
        );
    
      const [updateScore, { data: updateScoreData }] = useMutation(updateScoreMutation,{
        onCompleted:()=>{
            setShouldRefetch(true);
            // refetch();
            onClose();
            refetchUsers()
        }
      }
        );

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop: async (acceptedFiles) => {
            // console.log("acceptedFiles", acceptedFiles)

            const file = acceptedFiles[0];
            console.log("file =>", file)
            if(file) {
                if(doc.type === "mspe") {
                    if(isUpdate) {
                        const formData = new FormData();
                        console.log("mspe =>", doc)
                        formData.append('file', file);
                        formData.append('mspeId', doc.id);
                        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/upload_mspe_file`, {
                            method: 'POST',
                            headers: { 'App-Version': packageInfo.version },
                            body: formData
                        });
                        if (response.ok) {
                            const data = await response.json();
                            // Handle success, possibly using data from the response
                            console.log('Upload successful', data);
                            setShouldRefetch(true);
                            refetchUsers()
                            onClose();
                        } else {
                            // Handle error
                            console.error('Upload failed', await response.text());
                        }
                    } else {
                        const formData = new FormData();
                        formData.append('file', file);
                        formData.append('userId', user.userId);
                        formData.append('applicantId', applicant.id);
                        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/create_mspe_file`, {
                            method: 'POST',
                            headers: { 'App-Version': packageInfo.version },
                            body: formData
                        });
                        if (response.ok) {
                            const data = await response.json();
                            // Handle success, possibly using data from the response
                            console.log('File creation successful', data);
                            setShouldRefetch(true);
                            refetchUsers()
                            onClose();
                        } else {
                            // Handle error
                            console.error('File creation failed', await response.text());
                        }
                    }
                  
                }
                if(doc.type === "transcript") {
                    const formData = new FormData();
                    formData.append('file', file);
                    if(isUpdate) {
                        formData.append('transcriptId', doc.id);
                        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/upload_transcript_file`, {
                            method: 'POST',
                            headers: { 'App-Version': packageInfo.version },
                            body: formData
                        });
                        if (response.ok) {
                            const data = await response.json();
                            // Handle success, possibly using data from the response
                            console.log('Transcript upload successful', data);
                            setShouldRefetch(true);
                            refetchUsers()
                            onClose();
                        } else {
                            // Handle error
                            console.error('Transcript upload failed', await response.text());
                        }
                    } else {
                        formData.append('userId', user.userId);
                        formData.append('applicantId', applicant.id);
                        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/create_transcript_file`, {
                            method: 'POST',
                            headers: { 'App-Version': packageInfo.version },
                            body: formData
                        });
                        if (response.ok) {
                            const data = await response.json();
                            // Handle success, possibly using data from the response
                            console.log('Transcript creation successful', data);
                            setShouldRefetch(true);
                            refetchUsers()
                            onClose();
                        } else {
                            // Handle error
                            console.error('Transcript creation failed', await response.text());
                        }
                    }
                }
           
                
            }
        },
        accept: 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document' ,
        multiple: false  // This ensures that multiple file selection is disabled

    });
    const { id } = useParams();

   
    useEffect(() => {
        if(applicant !== null && applicant !== undefined) {
            var score = applicant.scores
            console.log("applicant =>", applicant)
            if(score !== null && score !== undefined) {
                console.log("score in useEffect =>", score)
                setScore(score)
                setUsmlStep1Score(score.usmleStep1Score)
                setUsmlStep2Score(score.usmleStep2Score)
                setUsmlStep3Score(score.usmleStep3Score)
                setComlexLevel1Score(score.comlexLevel1Score)
                setComlexLevel2Score(score.comlexLevel2Score)
                // setScoreRequestEmail(score.mspeReqEmailAdd)
            }
        }
    }, [applicant])


    if (!isOpen) return null;


    const handleCreateScore = async () => {
        const input = {
            uploaderId: user.userId,
            applicantId: applicant.id,
            stage: "Uploaded",
            scoresVerified: false,
            usmleScoresVerified: usmleStep1Score?true:false, 
            comlexScoresVerified: comlexLevel1Score?true:false,
            usmleStep1Score:usmleStep1Score,
            comlexLevel1Score:comlexLevel1Score,
            usmleStep2Score:parseInt(usmleStep2Score),
            usmleStep3Score:parseInt(usmleStep3Score),
            comlexLevel2Score:parseInt(comlexLevel2Score),
            previousUsmleComlexFails: anyFails==='Yes'?true:false,
            usmleComlexDescription: testFailsDetails,
            mspeReqEmailAdd: user.email,
            submittedAt: new Date(),
          };

          console.log("input =>", input)
          const { createScoreData } = await createScore({ variables: { input } });
    }

    
    const handleScoreEdit = async () => {
          try {
      
            const input = {
                uploaderId: user.userId,
                applicantId: applicant.id,
                stage: "Uploaded",
                scoresVerified: true,
                usmleScoresVerified: usmleStep1Score?true:false, 
                comlexScoresVerified: comlexLevel1Score?true:false,
                usmleStep1Score:usmleStep1Score,
                comlexLevel1Score:comlexLevel1Score,
                usmleStep2Score:parseInt(usmleStep2Score),
                usmleStep3Score:parseInt(usmleStep3Score),
                comlexLevel2Score:parseInt(comlexLevel2Score),
                previousUsmleComlexFails: anyFails==='Yes'?true:false,
                usmleComlexDescription: testFailsDetails,
                mspeReqEmailAdd: user.email,
                submittedAt: new Date(),
            };
        
            const { updateScoreData } = await updateScore({ variables: { id:score.id, input } });
          } catch (error) {
            console.error(error);
          }
      }
      


return (

<div className="modal-backdrop" >
<div className="add-field-modal" style={{ height: '90vh', overflowY: 'scroll'}}>
    <div className='add-field-modal-header'>
    <h2>{isUpdate ? "UPDATE DOCUMENT" : "UPLOAD DOCUMENT"} - {doc.name}</h2>
    <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
    </div>

    {
        doc.type === "mspe" &&
        <div className="document-home-body">

        <div className={ isDragActive ?'file-drop-zone hover':"file-drop-zone"} style={{ width: '50vw', zIndex:'50' }} {...getRootProps()}>
            <input  {...getInputProps()}  multiple={false}/>
            {
                isDragActive ?
                <p >Drop to upload the file</p> :
                <p >Drag & drop your MSPE file here, or click to select files</p>
            }
        </div>
            <div className="specific-rec-submit">
                <button className='green-button' style={{padding:'1vh 1vw', width:'50vw'}}>Upload Document</button>
                <button className='green-button' style={{padding:'1vh 1vw', width:'50vw', marginTop: '2vh'}} onClick={onClose}>Close</button>
            </div>
    </div>
    }
    {
          doc.type === "transcript" &&
          <div className="document-home-body">

          <div className={ isDragActive ?'file-drop-zone hover':"file-drop-zone"}  style={{ width: '50vw', zIndex:'50'}} {...getRootProps()}>
              <input  {...getInputProps()}  multiple={false}/>
              {
                  isDragActive ?
                  <p >Drop to upload the file</p> :
                  <p >Drag & drop your Transcript file here, or click to select files</p>
              }
          </div>
              <div className="specific-rec-submit">
                  <button className='green-button' style={{padding:'1vh 1vw', width:'50vw'}}>Upload Document</button>
                  <button className='green-button' style={{padding:'1vh 1vw', width:'50vw', marginTop: '2vh'}} onClick={onClose}>Close</button>
              </div>
      </div>
    }
    {
        doc.type === "score" &&
        <div style={{ marginTop: '2vh'}}>
            <ScoresCard inAppForm={false} updateIsScore={() => {}} isNested={true} applicant={applicant} refetchApplicants={refetchUsers} applicant={applicant}/>
            <button className='green-button' style={{padding:'1vh 1vw', width:'49vw', marginTop: '5vh'}} onClick={onClose}>Close</button>
        </div>

    }

   
              
</div>
</div>
)
};

export default DocsModal;