import React, {useState, useRef} from "react";
import rubricItemHandler from '../../../../../assets/imgs/rubricItemHandlerIcon.svg';
import { useDrag, useDrop } from 'react-dnd';
import EditFieldModal from "./editFieldModal";




const RubricItem = ({rubric, updateRubric, deleteRubric, moveRubric, index, canEditDelete, rubricsNames, program}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentRubric, setCurrentRubric] = useState(rubric); // Initialize with rubric prop

    const handleDeleteRubric = () => {
        deleteRubric(rubric.id);
    };

    const handleUpdateRubric = (updatedRubric) => {
        setCurrentRubric(updatedRubric); // Update currentRubric state
        updateRubric(updatedRubric);
    }

    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: 'rubricItem',
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index; // Use index prop
    
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
    
            // Move the content in the array
            moveRubric(dragIndex, hoverIndex);
    
            // Note: we're mutating the monitor item here!
            // Generally, it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });
    
    const [{ isDragging }, drag] = useDrag({
        type: 'rubricItem',
        item: () => {
            return { id: rubric._id, index: index }; // Use index prop
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    const handleEditRubric = (rubric) => {
        setCurrentRubric(rubric);
        setIsModalOpen(true);
    }

    return (
        <>
          <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }} className="rubric-item-container">
        <div className="rubric-item-header">
            <h3>{rubric.label}</h3>
            </div>
            
        <div className="rubric-item-container" style={canEditDelete ? {display:'flex', flexDirection:'row', width: '78vw'}: {display:'flex', flexDirection:'row', width: '103vw'}}>
        <div className='rubric-item-handler'>
            <img style={{width:'3vw', marginRight:'1vw', pointerEvents:'none'}} src={rubricItemHandler} alt='rubric item handler' />
            </div>
            {rubric.inputType === 'text' && <input type='text' style={{width:'100%'}} className='application-form-standard-input' />}
            {rubric.inputType === 'dropdown' && (
    <select style={{width: '100%'}} className='application-form-standard-input'>
        {
            console.log("rubric.dropdownOptions =>", rubric.dropdownOptions)
        }
        {rubric.dropdownOptions.map((option, index) => (
            <option key={index} value={option}>{option.option} - {option.score}</option>
        ))}
    </select>
)}

{rubric.inputType === 'scale' && (
       <div style={{width: '100%'}}>
           <label className='scale-label'>
               <span>{rubric.lowestScaleText}</span>
               <input type="range" min="1" max={rubric.maxScale} className='scale-input' />
               <span>{rubric.highestScaleText}</span>
           </label>
           <div className='scale-numbers'>  
               {Array.from({length: rubric.maxScale}, (_, i) => i + 1).map(number => (
                   <span key={number} style={{flex: 1, textAlign: 'center'}}>{number}</span>
               ))}
           </div>
       </div>
   )}

{isModalOpen && <EditFieldModal isOpen={true} onEditRubric={handleUpdateRubric} onClose={() => setIsModalOpen(false)} existingRubric={currentRubric} rubricsNames={rubricsNames} program={program}/>}
            {
                canEditDelete ?
                <div  className="rubric-item-buttons-container" style={{display:'flex', flexDirection:'column', gap:'.3vh', marginLeft:'1vw', width: '8vw'}}>    
                    <button 
                    className="rubric-item-green-button" 
                    onClick={() => handleEditRubric(currentRubric)} 
                    disabled={canEditDelete}>Edit</button>
                    <button className="rubric-item-white-button" onClick={handleDeleteRubric} disabled={canEditDelete}>Delete</button>
                </div>
            :
                
                    <div  className="rubric-item-buttons-container" style={{display:'flex', flexDirection:'column', gap:'.3vh', marginLeft:'1vw', width: '6.5vw'}}>    
                        <button 
                        className="rubric-item-green-button" 
                        onClick={() => handleEditRubric(currentRubric)} 
                        disabled={canEditDelete}>Edit</button>
                        <button className="rubric-item-white-button" onClick={handleDeleteRubric} disabled={canEditDelete}>Delete</button>
                    </div>
            }
          

        </div>
        
        </div>
        </>

    )
}

export default RubricItem;