import React, { useState, useEffect } from 'react';
import logo from '../../../../assets/imgs/logo.svg'


const UpdateRoleModal = ({ isOpen, onClose, user, onEditUser }) => {
    const [roles, setRoles] = useState(user.roles);
    const [isReviewerAutoSelected, setIsReviewerAutoSelected] = useState(false);

    useEffect(() => {
        setRoles(user.roles);
    }, [user]);


if (!isOpen) return null;

const handleRoleChange = (e) => {
    const { value, checked } = e.target;

        setRoles((prevRoles) =>
          checked ? [...prevRoles, value] : prevRoles.filter((role) => role !== value)
        );
};


const handleSave = () => {
    onEditUser({ ...user, roles });
    setRoles([]); // reset roles
};

const handleCancel = () => {
    onClose();
}

return (

<div className="modal-backdrop">
<div className="add-field-modal">
    <div className='add-field-modal-header'>
    <h2>MANAGE ROLES</h2>
    <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
    </div>

    <form>
        <div className="form-group">
         
            <div className='update-role-selection-container' >

                <div style={{display:'flex', alignItems: 'center'}}>
                    <input type="checkbox" id="HRAdmin" name="HRAdmin" value="HRAdmin" checked={roles.includes('HRAdmin')} onChange={handleRoleChange} />
                    <label for="role1" style={{ marginBottom: 0}}>HR Admin</label>
                </div>
                
                <div style={{display:'flex'}}>
                    <input type="checkbox" id="HRS" name="HRS" value="HRS" checked={roles.includes('HRS')} onChange={handleRoleChange} />
                    <label for="role2" style={{ marginBottom: 0}}>HR Sourcer</label>
                </div>
                
                <div style={{display:'flex'}}>
                    <input type="checkbox" id="HRR" name="HRR" value="HRR" checked={roles.includes('HRR')} onChange={handleRoleChange}   style={{
                        accentColor: roles.includes('HRR') ? (isReviewerAutoSelected ? '#8f8686' : '#145388') : 'transparent',
                    }}/>
                    <label for="role3" style={{ marginBottom: 0}}>HR Recruiter</label>
                </div>
                
                <div style={{display:'flex'}}>
                    <input type="checkbox" id="HRC" name="HRC" value="HRC" checked={roles.includes('HRC')} onChange={handleRoleChange} />
                    <label for="role4" style={{ marginBottom: 0}}>HR Compensation</label>
                </div>
                
                <div style={{display:'flex'}}>
                    <input type="checkbox" id="HM" name="HM" value="HM" checked={roles.includes('HM')} onChange={handleRoleChange} />
                    <label for="role5" style={{ marginBottom: 0}}>Hiring Manager</label>
                </div>

            </div>
        </div>

        <div className='button-container' style={{display:'flex', flexDirection:'row', justifyContent:'space-between', gap:'2vw'}}>
        <button type="button" className='gradient-button' style={{fontWeight:'500'}} onClick={handleSave} disabled={roles.length === 0}>Update</button>
            <button type="button" className='white-button'  onClick={handleCancel}>Cancel</button>
        </div>
    </form>
    
</div>
</div>
)
};

export default UpdateRoleModal;