import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import { getUserVerificationTokenByOneTimeTokenQuery, getUserDirectApply } from './graphql/queries';
import { UserContext } from '../../context/userContext.js';
import { useNavigate } from 'react-router-dom';
import logo from "../../assets/imgs/logo.svg";
import ApplicationForm from "../applicant/applicantComponents/ApplicationForm.jsx";


const ApplicantDirectApply = () => {
    const { token } = useParams();
    const { user, setUser } = useContext(UserContext);
    const [userFromQuery, setUserFromQuery] = useState(null);
    const navigate = useNavigate();

    const { data, loading, error } = useQuery(getUserDirectApply, {
        variables: { oneTimeLoginToken: token },
    });   

    useEffect(() => {
        if(!loading && !error && data) {
            console.log("data =>", data.getUserDirectApply)
            const userData = data.getUserDirectApply
            if(userData) {
                const userToken = userData.token
                localStorage.setItem('login_token', userData.token)
                if(userToken) {
                    setUser(userData);
                    setUserFromQuery(userData);
                    // navigate('/applicant', { state: { positionId: userData.positionId, routeDirectlyToForm:true } })
                    // navigate('/applicant', {state:{positionId: userData.positionId}})
                    navigate('/applicant', {state:{page:'appManager'}})
                }
            }
            
            

        }
    }, [loading, error, data])


    console.log("token1 =>", token)

    return (
        <div>
         {
               loading &&(
               <div
               className="faculty-home-container"
               style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}
             >
               <ellipse className="ellipseDesignTop1" />
               <ellipse className="ellipseDesignTop2" />
               <ellipse className="ellipseDesignTop3" />
               <ellipse className="ellipseDesignTop4" />
               <div className="faculty-home-header">
                 {/* <img src={logo} alt="logo" style={{ float: "right" }} /> */}
                 <h2 className="faculty-home-header-title">
                   WELCOME TO Smart Recruiting
                 </h2>
                 {/* <p className="faculty-home-header-subtitle"> Please fill in you details below to finalize your account creation</p> */}
               </div>
               <seperator className="faculty-home-header-seperator" />
               <div className="faculty-home-body">
               
               </div>
             </div>
              )}

{!userFromQuery && !loading &&(
             <div
             className="faculty-home-container"
             style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}
           >
             <ellipse className="ellipseDesignTop1" />
             <ellipse className="ellipseDesignTop2" />
             <ellipse className="ellipseDesignTop3" />
             <ellipse className="ellipseDesignTop4" />
             <div className="faculty-home-header">
               {/* <img src={logo} alt="logo" style={{ float: "right" }} /> */}
               <h2 className="faculty-home-header-title">
                 WELCOME TO Smart Recruiting
               </h2>
               {/* <p className="faculty-home-header-subtitle"> Please fill in you details below to finalize your account creation</p> */}
             </div>
             <seperator className="faculty-home-header-seperator" />
             <div className="faculty-home-body">
               <h2
                 style={{
                   marginTop: "14vh",
                   color: "red",
                   fontSize: "4vh",
                   fontFamily: "Montserrat",
                   fontWeight: "500",
                 }}
               >
                 Invalid or Expired Token, please contact Customer Support
               </h2>
             </div>
           </div>
           )}
        </div>
    )

}

export default ApplicantDirectApply;