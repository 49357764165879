import React, {useState} from "react";


const WorkExperience = ({workExperiences}) => {
    



return (
    <div className="application-view-main-demographics">
        <div className="application-view-main-demographics-header">
            <h2 className="application-view-main-title">Work EXPERIENCES</h2>
            <seperator className="application-view-main-demographics-seperator"/>
        </div>

        <div className="application-view-main-demographics-body" style={{width:'98%'}}>
        {workExperiences.map((workExp, index) => {
            return (
                <div className="application-view-main-education-degrees-section" style={{display:'flex', flexDirection:'row'}}>
                    <div>
                        <div className="date" style={{marginLeft:'3vw', width:'11vw',fontSize:'13px'}}>{workExp.startDate} - {workExp.endDate}</div>
                        {/* <div className="ball"/> */}
                    </div>
                    <div className="institution-and-degree" style={{marginBottom:'2vh', textAlign:'justify'}}>
                        <div className="institution" style={{marginLeft:'1vw', fontSize:'15px'}}>{workExp.title}</div>
                        <div className="degree" style={{marginLeft:'1vw', fontWeight:'500', fontSize:'15px'}}>{workExp.employer}</div>
                        <div className="degree" style={{marginLeft:'1vw', fontStyle:'italic', fontSize:'13px'}}>Mentor: {workExp.mentorName},  {workExp.mentorEmail}</div>
                        <div className="degree" style={{marginLeft:'1vw', fontSize:'13px'}}>{workExp.description}</div>
                    </div>
                </div>
            )
        })}

        </div>
    </div>
)
}

export default WorkExperience;