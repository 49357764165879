import React,{useState, useContext} from "react";
import { UserContext } from '../../../context/userContext';
import './index.css';
import MedSchoolPortalUserGuide from '../../../assets/files/MedSchoolPortalUserGuide.pdf';

const UserGuide = ({ setPage }) => {
    const { user } = useContext(UserContext);

    return (
        <div className='faculty-home-container'>
                <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="faculty-home-header">
                <h2 className="faculty-home-header-title">User Guide</h2>
            </div>
            <seperator className="faculty-home-header-seperator"/>
            <div className="faculty-home-body"></div>
            {/* add an Iframe to view a PDF File */}
            <iframe src={MedSchoolPortalUserGuide} width="100%" height="1700vh"></iframe>
        </div>
    )
}

export default UserGuide;