import React, {useState, useMemo} from 'react';
import { useTable, useSortBy, useRowSelect, useResizeColumns, useColumnOrder, useBlockLayout } from 'react-table';
import magGlass from '../../../../assets/imgs/magGlass.svg';


const ApplicationsTable = ({columns, data, onSelectionChange, onSelectedApplication, sortData }) => {
  const [columnWidths, setColumnWidths] = useState({});


  const defaultColumn = useMemo(
    () => ({
      minWidth: 30, // Minimum width of a column
      width: 35, // Default width of a column
      maxWidth: 400, // Maximum width of a column
    }),
    []
  );


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { selectedRowIds, sortBy },
      } = useTable({ 
        columns, 
        data,
        defaultColumn,
        manualSortBy: true, // Enable manual sorting
        disableSortRemove: true, // Optional: Prevent removing sort by clicking the header again
      },  useSortBy,
      useRowSelect,
      useResizeColumns,
      useColumnOrder
      );

      React.useEffect(() => {
        if (sortBy.length > 0) {
          const { id, desc } = sortBy[0];
          sortData(id, desc);
        } 
      }, [sortBy]);

      const [prevSelectedRows, setPrevSelectedRows] = useState([]);


      React.useEffect(() => {
        const selectedRows = rows.filter(row => selectedRowIds[row.id]);
        const simplifiedSelectedRows = selectedRows.map(row => row.original); // Only keep the original row data
    
        console.log("selectedRows =>", selectedRows, prevSelectedRows)
        // Only call onSelectionChange if the selected rows have actually changed
        if (JSON.stringify(simplifiedSelectedRows) !== JSON.stringify(prevSelectedRows)) {
            onSelectionChange(simplifiedSelectedRows);
            setPrevSelectedRows(simplifiedSelectedRows);
        }
    }, [onSelectionChange, rows, selectedRowIds]);



    const handleResizeMouseDown = (e, column) => {
      // Prevent the event from triggering sorting or other behaviors
      e.stopPropagation();
    
      const startWidth = column.getHeaderProps().style.width;
      const startX = e.clientX;
    
      const handleMouseMove = (e) => {
        // Calculate the new width based on the mouse movement
        const newWidth = startWidth + (e.clientX - startX);
        // Update the column's width (you'll need to store column widths in a state and pass them to your columns)
        setColumnWidths({ ...columnWidths, [column.id]: newWidth });
      };
    
      const handleMouseUp = () => {
        // Remove the event listeners when the mouse is released
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    
      // Add event listeners to the document to track mouse movement/up globally
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    };


      const handleClickCell = (row) => {
          onSelectedApplication(row);
          console.log("row clicked",row);
      }

      // const sortClicked = (direction, column) => {
      //   console.log("sorting =>", column.id, direction)
      //   sortData(column.id, direction)
      // }
    

      return (
        <div style={{ overflowX: 'auto', width: '100%' }}>
          <table className='table-programs-list' {...getTableProps()} style={{ borderSpacing: '10px 0' }}>
          <thead className='tHead-programs-list'>
              {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
              {
                headerGroup.headers.map((column,index) => (
                (column.id !== 'greenDot' && column.id !== 'view' && column.id !=='selection' && column.id !== "archiveBtn" && column.id !== "remove") ?
                <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                style={{
                  lineHeight: '4vh',
                  padding: '0 1vh 0 1vh',
                  width: 'fit-content',
                  position: 'relative',
                  cursor: 'pointer',
                }}
                className='app-table-header-title'
              >
                {column.render('Header')}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔼'
                      : ' 🔽'
                    : ''}
                </span>
                {/* <div
        {...column.getResizerProps()}
        className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
        onMouseDown={(e) => handleResizeMouseDown(e, column)}
        style={{
          display: 'inline-block',
          width: '10px',
          height: '100%',
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: 1,
          cursor: 'col-resize',
          touchAction: 'none',
        }}
      /> */}
              </th>
                :
                <th className='table-header-title start'
                style={{ 
                  // lineHeight: '4vh', 
                  paddingLeft: '1vw',
                   width: 'fit-content', 
                  // minWidth: '100px',
                  // maxWidth: '200px',
                  // overflow: 'hidden',
                  // textOverflow: 'ellipsis',
                  // whiteSpace: 'nowrap'
                }}
                   {...column.getHeaderProps()}>
                  {column.render('Header')}
                  {/* <div
        {...column.getResizerProps()}
        className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
        onMouseDown={(e) => handleResizeMouseDown(e, column)}
        style={{
          display: 'inline-block',
          width: '10px',
          height: '100%',
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: 1,
          cursor: 'col-resize',
          touchAction: 'none',
        }}
      /> */}
                </th>
              ))}
            </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                
                prepareRow(row);
                const cellStyle = {padding:'1vh'}
                return (
                  <tr className='programs-list-table-row table-row-height' {...row.getRowProps()}>
                      {row.cells.map(cell => (
                          <td className='programs-list-table-row-text'  style={cellStyle} onClick={() => handleClickCell(row.original)} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
  }
  
    
    export default ApplicationsTable;


