import { gql } from "@apollo/client";

export const getProgramByIdQuery = gql`
query getProgramById($id: ID!) {
    getProgramById(id: $id) {
        id
        isRubricLocked
        name
         defaultLegalDisclaimers
            defaultEqualEmploymentOpportunityStatement
        description
        rubricTemplate {
            id
            label
            isPreInterview
            inputType
            maxScale
            lowestScaleText
            highestScaleText
            dropdownOptions {
                option
                score
            }
            text
            order
        }
    }
}
`;

export const getLorsByApplicationAndUserQuery = gql`
    query getLorsByApplicationAndUser($applicationId: ID, $applicantId: ID) {
        getLorsByApplicationAndUser(applicationId:$applicationId, applicantId:$applicantId) {
           id
            applicationId
            applicantId
            recommenderEmail
            recommenderName
            docUrl
            isSlor
            slor{
            email
  howLongKnownTheApplicant
  improvementAspect
  natureOfContact
  presentPosition
  radioAcademicSkills
  radioCommunicationSkills
  radioOperativeSkill
  radioOverallComparedToOtherApplicants
  radioPatientCare
  radioProfessionalism
  radioResearchAndTeaching
  radioSelfInitiative
  radioTeamPlayer
  radioWorkEthic
  rank
  strongestPoint
  willingToDiscuss
  writtenComments
            }
        }
    }

`

export const getMspeByApplicantAndApplicationQuery = gql`
    query getMspeByApplicantAndApplication($applicantId: ID, $applicationId: ID) {
        getMspeByApplicantAndApplication(applicantId: $applicantId, applicationId: $applicationId) {
            id
            applicantId
            applicationId
            uploaderId {
                _id
                firstName
                lastName
            }
            stage
            docUrl
            requestedAt
            submittedAt
            uploaderEmail
        } 
    }

`


export const getTranscriptsByApplicantAndApplicationQuery = gql`
    query getTranscriptsByApplicantAndApplication($applicantId: ID, $applicationId: ID){
        getTranscriptsByApplicantAndApplication(applicantId: $applicantId, applicationId: $applicationId) {
            id
            applicantId
            applicationId
            uploaderId {
                _id
                firstName
                lastName
            }
            stage
            docUrl
            requestedAt
            submittedAt
            uploaderEmail
        }
    }
`

export const GET_REVIEWERS_APPLICATIONS_BY_PROGRAM = gql`
query getReviewersApplicationsByProgram($reviewerId: ID!, $programId: ID!, $offset: Int, $limit: Int, $sort: SortInput) {
    getReviewersApplicationsByProgram(reviewerId: $reviewerId, programId: $programId, offset: $offset, limit: $limit, sort: $sort) {
    id
    userId
    firstName
    lastName
    status
    reviewerId
    }
  }
`;

export const getApplicationSharedNotesQuery = gql`
query getApplicationSharedNotes($applicationId: ID!) {
    getApplicationSharedNotes(applicationId: $applicationId) {
        userId {
            id
            firstName
            lastName
        }
        note
        timestamp
    }
}
`;

export const getReviewByReviewerAndApplicationQuery = gql`
query getReviewByReviewerAndApplication($reviewerId: ID!, $applicationId: ID!) {
    getReviewByReviewerAndApplication(reviewerId: $reviewerId, applicationId: $applicationId) {
        id
        stage
        rubric{
            label
            inputType
            isPreInterview
            maxScale
            lowestScaleText
            highestScaleText
            dropdownOptions{
                option
                score
            }
            text
            order
            result
        }
    }
}
`;