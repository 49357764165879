import React, { useContext, useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage,useField } from 'formik';
import { FormContext } from '../ApplicationForm';
import Spinner from '../../../common/spinner';

function countWords(input) {
    if (input) {
      const words = input.trim().split(/\s+/);
      return words.length;
    }
    return 0;
  }
  
  const WordCountField = ({ label, value, ...props }) => {
    const [field, meta] = useField(props);
    
    const wordCount = countWords(value);
    const isOverLimit = wordCount > 750;



  return (
    <div>
      <label htmlFor={props.id || props.name}>{label}</label>
      <textarea style={{height:'47vh', marginBottom:'0'}} {...field} {...props} onChange={(event) => props.updateValue(event)} />
      {/* {meta.touched && meta.error ? (
        <div style={{color:'red'}} className="error">{meta.error}</div>
      ) : null} */}
      {/* <div><ErrorMessage name={props.name} /></div> */}
      {isOverLimit && <div className="error" style={{color:'red', fontWeight:'500'}}>Word count exceeds the limit!</div>}
      <div style={{marginTop:'-2vh'}}>Word count: {wordCount}/750</div>
    </div>
  );
  };
  

const ApplicationFormStep3 = ({isNested, updateUserData, updatePhase, phases, viewOnly}) => {
  const { values, setStep, step, setFieldValue  } = useContext(FormContext);
  const [isEdit, setIsEdit] = useState(!viewOnly ? phases.thirdPhase? false : true: false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [finishSave, setFinishSave] = useState(false);
  const [saveError, setSaveError] = useState(null);


  console.log("values.personalStatement =>", values.personalStatement)

  useEffect(() => {
    let timer;
    if (finishSave) {
      timer = setTimeout(() => {
        setFinishSave(false);
      }, 5000); // 5 seconds
    }
  
    // Cleanup timer
    return () => clearTimeout(timer);
  }, [finishSave]);

  const handleSave = async () => {
    if (saveError !== null) {
      setSaveError(null); 
    } 
    setIsSaveLoading(true)
    try {
      await updateUserData(values, 3);
      setIsSaveLoading(false);
      setFinishSave(true); // Indicate success
    } catch (errorMessage) {
      setIsSaveLoading(false);
      console.error(errorMessage);
      setSaveError(errorMessage); // Indicate failure
    }
  }


  return (
<>
{
  !viewOnly &&

  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-end', width: '94%', flexDirection: 'column'}}>
  {finishSave && <div className="floating-btn" style={{ fontFamily: 'Montserrat', color: "#145388", marginBottom: '2vh', marginTop: '-4vh'}}>Application saved!</div>}
  {saveError && <div className="floating-btn" style={{ color:'red',fontFamily: 'Montserrat', marginBottom: '2vh', marginTop: '-4vh'}}>Not Saved. please try again.</div>}
  <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '15%', flexDirection: 'row'}}>
  {!isSaveLoading&&<button className='white-button floating-btn'  style={{width: '10vw'}} disabled={!isEdit} onClick={ () => handleSave()}>Save</button>}
    { isSaveLoading && <div className='floating-btn' style={{ width: '20px', top:'7vh', right:'10vw'}}><Spinner/></div>}
  </div>
</div>
}

<div style={{
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '24px',
    color: '#145388',
    lineHeight: '51px',
    borderBottom: '1px solid #145388',
    marginBottom: '30px'
}}>


<WordCountField
    label="Please provide your Cover Letter. The limit is 750 words."
    name="personalStatement"
    placeholder="Cover Letter"
    className='application-form-standard-input one'
    disabled={!isEdit}
    value={values.personalStatement}
    updateValue={(event) => {console.log("statment changed!", event.target.value); setFieldValue("personalStatement", event.target.value);}}
  />



{!isNested && isEdit&&(
  <button className="mark-as-complete" type="button" 
  disabled={countWords(values.personalStatement) > 750}
  
  onClick={() => {
    handleSave()
    updatePhase("thirdPhase")
    setStep(prevStep => prevStep + 1);    
    }}>
    Next
  </button>
)}
{!isNested && !isEdit && (
  <button className="white-button"
  style={{width:'100%', fontSize:'2vh', height:'7vh'}}
  onClick={() => {
 setIsEdit(true)
 updatePhase("thirdPhase")
    }}>
    Reopen Task
  </button>
)}
</div>
</>
)
}
export default ApplicationFormStep3;