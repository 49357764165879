import { ApolloClient, InMemoryCache, HttpLink, split, ApolloLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { print } from 'graphql';
import packageInfo from '../../package.json';
import { Observable } from '@apollo/client/utilities';

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_SERVER_URL}/graphql`, // Your GraphQL endpoint
});

// Custom WebSocketLink using graphql-ws
const wsClient = createClient({
  url: `${process.env.REACT_APP_SERVER_WS_URL}/graphql`,
connectionParams: () => ({
  Authorization: `Bearer ${localStorage.getItem('login_token')}`,
  'App-Version': packageInfo.version,
}),
  on: {
    connected: () => console.log('WebSocket connected'),
    closed: () => console.log('WebSocket closed'),
    error: (err) => console.error('WebSocket error', err),
  },
});


const wsLink = new ApolloLink((operation) => {
  const { query, variables } = operation;
  return new Observable((observer) => {
    const unsubscribe = wsClient.subscribe(
      { query: print(query), variables },
      {
        next: observer.next.bind(observer),
        error: (err) => {
          console.error('WebSocket subscription error', err);
          observer.error(err);
        },
        complete: observer.complete.bind(observer),
      },
    );
    return () => {
      unsubscribe();
    };
  });
});

// Middleware for adding the auth token to the context
const authLink = new ApolloLink((operation, forward) => {

  
  // Retrieve the authentication token from local storage if it exists
  const token = localStorage.getItem('login_token');
  console.log('token in the authLink:', token)
  // Use the setContext method to set the HTTP headers
  operation.setContext({
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
      'App-Version': packageInfo.version
    },
  });

  // Call the next link in the middleware chain
  return forward(operation);
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink, // Use the custom WebSocket link for subscriptions
  authLink.concat(httpLink), // HTTP link for queries and mutations
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

export default client;