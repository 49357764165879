import { useEffect, useState } from 'react';
import { useSubscription } from '@apollo/client';

const useConditionalSubscription = (subscription, variables) => {
    const [shouldSubscribe, setShouldSubscribe] = useState(false);

    // Initially, do not subscribe if programIds are not available
    const skipSubscription = !variables.programIds || variables.programIds.length === 0;

    useEffect(() => {
        // Enable subscription only if programIds are available
        setShouldSubscribe(!skipSubscription);
    }, [variables.programIds, skipSubscription]);

    // Use the subscription if conditions are met
    const { data, error } = useSubscription(subscription, {
        variables,
        skip: !shouldSubscribe, // This line is conceptual; useSubscription does not support 'skip'
    });

    // Handle subscription state
    useEffect(() => {
        if (!skipSubscription) {
            // Logic to handle data or error from the subscription
            // This might involve setting state or triggering side effects based on the subscription result
        }
    }, [data, error, skipSubscription]);

    return { data, error };
};

export default useConditionalSubscription;