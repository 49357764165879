const medicalSchools = [
  "Albany Medical College",
  "Boston University School of Medicine",
  "Brody School of Medicine at East Carolina University",
  "California Northstate University College of Medicine",
  "California University of Science and Medicine - School of Medicine",
  "Baylor College of Medicine",
  "Albert Einstein College of Medicine",
  "Central Michigan University College of Medicine",
  "Charles E. Schmidt College of Medicine at Florida Atlantic University",
  "Chicago Medical School of Rosalind Franklin University of Medicine & Science",
  "Columbia University Vagelos College of Physicians and Surgeons",
  "Cooper Medical School of Rowan University",
  "Creighton University School of Medicine",
  "CUNY School of Medicine",
  "Dalhouse University Faculty of Medicine",
  "Donald and Barbara Zucker School of Medicine at Hofstra/Northwell",
  "Drexel University College of Medicine",
  "East Tennessee State University James H. Quillen College of Medicine",
  "Duke University School of Medicine",
  "Case Western Reserve University School of Medicine",
  "Emory University School of Medicine",
  "Carle Illinois College of Medicine",
  "Florida International University Herbert Wertheim College of Medicine",
  "Florida State University College of Medicine",
  "Frank H. Netter MD School of Medicine at Quinnipiac University",
  "Geisel School of Medicine at Dartmouth",
  "Geisinger Commonwealth School of Medicine",
  "George Washington University School of Medicine and Health Sciences",
  "Georgetown University School of Medicine",
  "Icahn School of Medicine at Mount Sinai",
  "Howard University College of Medicine",
  "Hackensack-Meridian School of Medicine",
  "Harvard Medical School",
  "Indiana University School of Medicine",
  "Jacobs School of Medicine and Biomedical Sciences at the University of Buffalo",
  "Johns Hopkins University School of Medicine",
  "Kaiser Permanente Bernard J. Tyson School of Medicine",
  "Keck School of Medicine at the University of Southern California",
  "Lewis Katz School of Medicine at Temple University",
  "Loma Linda University School of Medicine",
  "Louisiana State University School of Medicine in New Orleans",
  "Louisiana State University School of Medicine in Shreveport",
  "Loyola University Chicago Stritch School of Medicine",
  "Marshall University Joan C. Edwards School of Medicine",
  "Eastern Virginia Medical School",
  "McGill University Faculty of Medicine",
  "Mayo Clinic Alix School of Medicine",
  "Faculty of Medicine Universite Laval",
  "McMaster University Michael G. DeGroote School of Medicine",
  "Medical College of Georgia at Augusta University",
  "Medical College of Wisconsin",
  "Medical University of South Carolina College of Medicine",
  "Meharry Medical College",
  "Michigan State University College of Human Medicine",
  "Morehouse School of Medicine",
  "Memorial University of Newfoundland Faculty of Medicine",
  "Mercer University School of Medicine",
  "New York Medical College",
  "New York University Grossman School of Medicine",
  "New York University Long Island School of Medicine",
  "Northeast Ohio Medical University",
  "Northern Ontario School of Medicine",
  "Nova Southeastern University Dr. Kiran C. Patel College of Allopathic Medicine",
  "Northwestern University Feinberg School of Medicine",
  "Oakland University William Beaumont School of Medicine",
  "Ohio State University College of Medicine",
  "Oregon Health & Science University School of Medicine",
  "Pennsylvania State University College of Medicine",
  "Ponce Health Sciences University School of Medicine",
  "Perelman School of Medicine at the University of Pennsylvania",
  "Robert Larner, MD College of Medicine at the University of Vermont",
  "Queen's University Faculty of Health Sciences",
  "Renaissance School of Medicine at Stony Brook University",
  "Rush Medical College of Rush University Medical Center",
  "Rutgers New Jersey Medical School",
  "Rutgers, Robert Wood Johnson Medical School",
  "San Juan Bautista School of Medicine",
  "Sidney Kimmel Medical College at Thomas Jefferson University",
  "Southern Illinois University School of Medicine",
  "Max. Ready College of Medicine, Ready Faculty of Health Sciences, University of Manitoba",
  "State University of New York Downstate Medical Center College of Medicine",
  "McGovern Medical School at the University of Texas Health Science Center at Houston",
  "Anne Burnett Marion School of Medicine at Texas Christian University",
  "Texas A&M Health Science Center College of Medicine",
  "Texas Tech University Health Sciences Center Paul L. Foster School of Medicine",
  "Texas Tech University Health Sciences Center School of Medicine",
  "The Warren Alpert Medical School of Brown University",
  "Tufts University School of Medicine",
  "The University of Texas Health Science Center at San Antonio Joe R. and Teresa Lozano Long School of Medicine",
  "The University of Toledo College of Medicine and Life Sciences",
  "The University of Western Ontario - Schulich School of Medicine & Dentistry",
  "Tulane University School of Medicine",
  "Universidade Central del Caribe School of Medicine",
  "Uniformed Services University of the Health Sciences F. Edward Hebert School of Medicine",
  "University de Montreal Faculty of Medicine",
  "University of Alabama School of Medicine",
  "University of Alberta Faculty of Medicine and Dentistry",
  "University of Arizona College of Medicine - Phoenix",
  "University de Sherbrooke Faculty of Medicine",
  "University of Arizona College of Medicine",
  "University of Calgary Cumming School of Medicine",
  "University of Arkansas for Medical Sciences College of Medicine",
  "University of British Columbia Faculty of Medicine",
  "University of California Davis School of Medicine",
  "University of California Irvine School of Medicine",
  "University of California Los Angeles David Geffen School of Medicine",
  "University of California Riverside School of Medicine",
  "University of California San Diego School of Medicine",
  "University of California San Francisco School of Medicine",
  "University of Cincinnati College of Medicine",
  "University of Central Florida College of Medicine",
  "University of Chicago Pritzker School of Medicine",
  "University of Colorado School of Medicine",
  "University of Florida College of Medicine",
  "University of Connecticut School of Medicine",
  "University of Hawaii, John A. Burns School of Medicine",
  "University of Houston College of Medicine",
  "University of Illinois College of Medicine - Chicago",
  "University of Iowa Roy J. and Lucille A. Carver College of Medicine",
  "University of Kentucky College of Medicine",
  "University of Kansas School of Medicine",
  "University of Louisville School of Medicine",
  "University of Maryland School of Medicine",
  "University of Massachusetts Medical School",
  "University of Miami Leonard M. Miller School of Medicine",
  "Stanford University School of Medicine",
  "University of Minnesota Medical School",
  "State University of New York Upstate Medical University",
  "University of Missouri-Columbia School of Medicine",
  "University of Missouri-Kansas City School of Medicine",
  "University of Nebraska College of Medicine",
  "University of Nevada Las Vegas School of Medicine",
  "University of Nevada Reno School of Medicine",
  "University of New Mexico School of Medicine",
  "University of North Carolina at Chapel Hill School of Medicine",
  "University of North Dakota School of Medicine and Health Sciences",
  "University of Oklahoma College of Medicine",
  "University of Ottawa Faculty of Medicine",
  "University of Pittsburgh School of Medicine",
  "University of Puerto Rico School of Medicine",
  "University of Rochester School Medicine and Dentistry",
  "University of Saskatchewan College of Medicine",
  "University of South Alabama College of Medicine",
  "University of South Carolina College of Medicine",
  "University of South Carolina School of Medicine Greenville",
  "University of South Dakota, Sanford School of Medicine",
  "University of Tennessee Health Science Center College of Medicine",
  "University of Texas at Austin Dell Medical School",
  "University of Texas Medical Branch School of Medicine",
  "University of Texas Rio Grande Valley School of Medicine",
  "University of Texas Southwestern Medical School",
  "University of Toronto Faculty of Medicine",
  "University of Utah School of Medicine",
  "University of Virginia School of Medicine",
  "University of Wisconsin School of Medicine and Public Health",
  "University of Washington School of Medicine",
  "University of South Florida Health Morsani College of Medicine",
  "Vanderbilt University School of Medicine",
  "Virginia Commonwealth University School of Medicine",
  "Virginia Tech Carilion School of Medicine",
  "Wake Forest School of Medicine",
  "Washington University in St. Louis School of Medicine",
  "Washington State University Elson S. Floyd College of Medicine",
  "Wayne State University School of Medicine",
  "Yale School of Medicine",
  "Arizona College of Osteopathic Medicine of Midwestern University",
  "Weill Cornell Medicine",
  "West Virginia University School of Medicine",
  "Western Michigan University Homer Stryker MD School of Medicine",
  "Wright State University Boonshoft School of Medicine",
  "Alabama College of Osteopathic Medicine",
  "Arkansas College of Osteopathic Medicine",
  "A.T. Still University of Health Sciences School of Osteopathic Medicine in Arizona",
  "A.T. Still University of Health Sciences Kirksville College of Osteopathic Medicine",
  "Burrell College of Osteopathic Medicine at New Mexico State University",
  "California Health Sciences University College of Osteopathic Medicine",
  "Chicago College of Osteopathic Medicine of Midwestern University",
  "Campbell University Jerry M. Wallace School of Osteopathic Medicine",
  "Des Moines University College of Osteopathic Medicine",
  "Edward Via College of Osteopathic Medicine - Auburn Campus",
  "Edward Via College of Osteopathic Medicine - Carolinas Campus",
  "Edward Via College of Osteopathic Medicine - Louisiana Campus",
  "University of Michigan Medical School",
  "University of Mississippi School of Medicine",
  "Kansas City University College of Osteopathic Medicine",
  "Lake Erie College of Osteopathic Medicine",
  "Liberty University College of Osteopathic Medicine",
  "Lincoln Memorial University - DeBusk College of Osteopathic Medicine",
  "Michigan State University College of Osteopathic Medicine",
  "Marian University College of Osteopathic Medicine",
  "New York Institute of Technology College of Osteopathic Medicine",
  "Noorda College of Osteopathic Medicine",
  "Nova Southeastern University Dr. Kiran C Patel College of Osteopathic Medicine",
  "Ohio University Heritage College of Osteopathic Medicine",
  "Oklahoma State University Center for Health Sciences College of Osteopathic Medicine",
  "Philadelphia College of Osteopathic Medicine",
  "Pacific Northwest University of Health Sciences College of Osteopathic Medicine",
  "Philadelphia College of Osteopathic Medicine Georgia Campus",
  "Rocky Vista University College of Osteopathic Medicine",
  "Sam Houston State University College of Osteopathic Medicine",
  "Rowan University School of Osteopathic Medicine",
  "Touro College of Osteopathic Medicine - Harlem",
  "Touro University Nevada College of Osteopathic Medicine",
  "Touro University College of Osteopathic Medicine - California",
  "University of the Incarnate Word School of Osteopathic Medicine",
  "University of New England College of Osteopathic Medicine",
  "University of North Texas Health Science Center at Fort Worth - Texas College of Osteopathic Medicine",
  "University of Pikeville - Kentucky College of Osteopathic Medicine",
  "West Virginia School of Osteopathic Medicine",
  "Western University of Health Sciences College of Osteopathic Medicine of the Pacific",
  "William Carey University College of Osteopathic Medicine",
  "Edward Via College of Osteopathic Medicine - Virginia Campus",
  "Idaho College of Osteopathic Medicine",
  "ECGME",
  "ECFMG",
  "American University of Antigua College of Medicine",
  "Bond University Faculty of Health Sciences & Medicine",
  "Curtin Medical School",
  "Deakin University School of Medicine",
  "Flinders University of South Australia School of Medicine",
  "Xavier University School of Medicine, Aruba",
  "Australian National University Medical School",
  "Macquarie University Faculty of Medicine & Health Sciences",
  "Monash School of Medicine",
  "The University of Western Australia Faculty of Health and Medical Sciences",
  "University of Adelaide Faculty of Health and Medical Sciences",
  "University of Melbourne Faculty of Medicine, Dentistry & Health Sciences",
  "University of New South Wales Faculty of Medicine",
  "University of New England School of Rural Medicine",
  "University of Newcastle Faculty of Health and Medicine",
  "University of Notre Dame Australia School of Medicine, Fremantle",
  "University of Notre Dame Australia School of Medicine, Sydney",
  "University of Sydney, Sydney Medical School",
  "University of Queensland School of Medicine",
  "University of Tasmania School of Medicine",
  "James Cook University College of Medicine and Dentistry",
  "Griffith University School of Medicine",
  "Medizinische Universität Wien",
  "Arabian Gulf University College of Medicine and Medical Sciences",
  "Azerbaijan Medical University",
  "Royal College of Surgeons in Ireland - Medical University of Bahrain",
  "Ad-din Women's Medical College",
  "Abdul Malek Ukil Medical College",
  "Anwer Khan Modern Medical College",
  "Armed Forces Medical College (AFMC)",
  "Ashiyan Medical College",
  "Army Medical College Rangpur",
  "Bangladesh Medical College (BMSRI)",
  "B.G.C. Trust Medical College",
  "Barind Medical College and Hospital",
  "Bashundhara Ad-din Medical College",
  "CARe Medical College",
  "Chattagram Maa-O-Shishu Hospital Medical College",
  "Central Medical College, Comilla",
  "Chittagong Medical College and Hospital",
  "Comilla Medical College & Hospital",
  "Community Based Medical College, Bangladesh (CBMCB)",
  "Delta Medical College",
  "Cox's Bazar Medical College",
  "Dhaka Central International Medical College and Hospital",
  "Western Sydney University School of Medicine",
  "University of Wollongong School of Medicine",
  "Dhaka National Medical College & Hospital",
  "Diabetic Association Medical College",
  "Dr. Sirajul Islam Medical College and Hospital",
  "Eastern Medical College, Comilla",
  "Gazi Medical College",
  "East West Medical College",
  "Faridpur Medical College & Hospital",
  "Enam Medical College and Hospital",
  "Green Life Medical College and Hospital",
  "Gonoshasthaya Samaj Vittik Medical College",
  "Holy Family Red Crescent Medical College",
  "Ibn Sina Medical College",
  "Institute of Applied Health Sciences (IAHS)",
  "Ibrahim Medical College",
  "International Medical College",
  "Islami Bank Medical College",
  "Jessore Medical College",
  "Jahurul Islam Medical College & Hospital",
  "Jalalabad Ragib-Rabeya Medical College",
  "Khulna Medical College",
  "Khwaja Yunus Ali Medical College",
  "Kumudini Women's Medical College",
  "Kushtia Medical College",
  "M Abdur Rahim Medical College",
  "Mainamoti Medical College",
  "M.H. Samorita Hospital and Medical College",
  "Monno Medical College",
  "Medical College for Women & Hospital",
  "North Bengal Medical College",
  "Mymensingh Medical College",
  "Pabna Medical College",
  "North East Medical College",
  "Northern International Medical College",
  "Dhaka Community Medical College",
  "Prime Medical College",
  "Dhaka Medical College and Hospital",
  "President Abdul Hamid Medical College and Hospital",
  "Rajshahi Medical College & Hospital",
  "Rangamati Medical College",
  "Rangpur Community Medical College",
  "Satkhira Medical College",
  "Rangpur Medical College",
  "Shaheed M. Monsur Ali Medical College Sirajganj",
  "Shaheed Monsur Ali Medical College",
  "Shahabuddin Medical College",
  "Shaheed Tajuddin Ahmad Medical College",
  "Shaheed Suhrawardy Medical College",
  "Shaheed Syed Nazrul Islam Medical College",
  "Shaheed Ziaur Rahman Medical College",
  "Sheikh Hasina Medical College Jamalpur",
  "Sheikh Hasina Medical College Tangail",
  "Sher-E-Bangla Medical College",
  "Sheikh Sayera Khatun Medical College",
  "Sylhet M.A.G. Osmani Medical College",
  "Sir Salimullah Medical College",
  "Southern Medical College & Hospital",
  "Tairunnessa Memorial Medical College and Hospital",
  "Sylhet Women's Medical College and Hospital",
  "Universal Medical College",
  "Zainul Haque Sikder Women's Medical College & Hospital",
  "American University of Barbados School of Medicine",
  "T.M.S.S. Medical College",
  "Uttara Adhunik Medical College",
  "Ross University School of Medicine",
  "Grodno State Medical University",
  "University of the West Indies, Barbados Faculty of Medical Sciences",
  "Belarusian State Medical University",
  "Vitebsk State Medical University",
  "Gomel State Medical University",
  "Katholieke Universiteit Leuven (KULeuven) Faculteit Geneeskunde",
  "Universiteit Antwerpen Faculteit Geneeskunde en Gezondheidswetenschappen",
  "Vrije Universiteit Brussel Faculteit Geneeskunde & Farmacie",
  "Centro Universitário Barão de Mauá (CUBM)",
  "Centro Universitário Christus (Unichristus)",
  "Centro Universitário do Estado do Pará (CESUPA)",
  "Universiteit Gent Faculteit Geneeskunde en Gezondheidswetenschappen",
  "Centro Universitário de Volta Redonda (UniFOA) Escola de Ciências Médicas de Volta Redonda",
  "Centro Universitário São Camilo, São Paulo (SCAMILO)",
  "Faculdade de Medicina de Jundiaí (FMJ)",
  "Centro Universitário Serra dos Órgãos (UNIFESO) Centro de Ciências da Saúde",
  "Faculdade de Medicina de Marília (FAMEMA)",
  "Faculdade Evangélica Mackenzie do Paraná Faculdade de Medicina",
  "Faculdade de Ciências Médicas de Minas Gerais (FCMMG)",
  "Popular Medical College and Hospital",
  "Faculdade de Medicina de Petrópolis / Faculdade Arthur Sá Earp Neto (FMP/Fase)",
  "Faculdade de Medicina de Ribeirão Preto (FMRP-USP)",
  "Faculdade de Ciências Médicas da Santa Casa de São Paulo (FCMSCSP)",
  "Parkview Medical College",
  "Faculdade de Medicina de São José do Rio Preto (FAMERP)",
  "Faculdade Pernambucana de Saúde (FPS)",
  "Pontifícia Universidade Católica do Paraná Escola de Medicina, Câmpus Curitiba",
  "Faculdade Israelita de Ciências da Saúde Albert Einstein (FICSAE)",
  "Pontifícia Universidade Católica de Minas Gerais (PUC MINAS)",
  "Faculdade Santa Marcelina (FASM)",
  "Universidade de São Paulo Faculdade de Medicina São Paulo (FMSP-USP)",
  "Pontifícia Universidade Católica do Paraná Escola de Medicina, Câmpus Londrina",
  "Universidade Estadual de Campinas (UNICAMP) Faculdade de Ciências Médicas",
  "Universidade de Brasília (UnB) Faculdade de Medicina",
  "Pontifícia Universidade Católica do Rio Grande do Sul (PUCRS) Faculdade de Medicina",
  "Universidade de Santo Amaro (UNISA) Faculdade de Medicina",
  "Universidade Federal de Alagoas (UFAL) Faculdade de Medicina",
  "Universidade Estadual Paulista 'Júlio de Mesquita Filho' (UNESP) Faculdade de Medicina",
  "Universidade Federal de Minas Gerais (UFMG) Faculdade de Medicina",
  "Universidade Federal de Juiz de Fora (UFJF) Faculdade de Medicina Juiz de Fora",
  "Universidade Federal de Ciências da Saúde de Porto Alegre (UFCSPA) Faculdade de Medicina",
  "Universidade Federal de São Paulo (UNIFESP) Faculdade de Medicina",
  "Universidade Federal do Ceará Faculdade de Medicina Fortaleza",
  "Universidade Federal de Viçosa (UFV)",
  "Universidade Municipal de São Caetano do Sul Escola de Saúde, Campus Centro SCS",
  "Universidade Federal do Ceará Faculdade de Medicina Sobral",
  "Universidade Regional de Blumenau (FURB) Faculdade de Medicina",
  "St. Matthew's University School of Medicine (Grand Cayman)",
  "Anhui Medical University Faculty of Medicine",
  "Universidade Vila Velha (UVV)",
  "Baotou Medical College",
  "Anhui University of Science and Technology College of Medicine",
  "Changsha Medical University",
  "Beihua University School of Medicine",
  "Capital Medical University",
  "Binzhou Medical College",
  "Bengbu Medical College",
  "Changchun University of Traditional Chinese Medicine",
  "Changzhi Medical College",
  "China Medical University",
  "Chengdu University of Traditional Chinese Medicine",
  "Chengdu Medical College",
  "Chengde Medical University",
  "Gannan Medical University",
  "Fujian Medical University",
  "Dali University School of Medicine",
  "Dalian Medical University",
  "Chongqing Medical University",
  "Fujian College of Traditional Chinese Medicine",
  "Faculdade de Ciências da Saúde de Barretos Dr. Paulo Prata (FACISB)",
  "Guangzhou Medical University",
  "Guangdong Medical University",
  "Guangxi University of Chinese Medicine",
  "Guilin Medical University",
  "Guangxi Medical University",
  "Escola Superior de Ciências da Santa Casa de Misericórdia de Vitória Faculdade de Medicina",
  "Harbin Medical University",
  "Hainan Medical University",
  "Hebei University Medical College",
  "Hebei North University Faculty of Medicine",
  "Hangzhou School of Medicine, Hangzhou Normal University",
  "Hebei Medical University Clinical College",
  "Hebei University of Engineering School of Medicine",
  "Hubei Polytechnic University School of Medicine",
  "Hubei University of Arts and Science Medical College",
  "Hubei University for Nationalities Medical School",
  "Hubei University of Medicine",
  "Hubei University of Science and Technology Faculty of Medicine",
  "Hunan Normal University College of Medicine",
  "Inner Mongolia Medical University",
  "Jiamusi University School of Medicine",
  "Inner Mongolia University for the Nationalities",
  "Jiangnan University Wuxi Medical College",
  "Hunan University of Chinese Medicine",
  "Jianghan University School of Medicine",
  "Jiangsu University School of Medicine",
  "Jilin Medical University",
  "Jiangxi Medical College of Nanchang University",
  "Jinan University School of Medicine",
  "Jinggangshan University Medical School",
  "Jining Medical University",
  "Jinzhou Medical University",
  "Jishou University School of Medicine",
  "Kunming Medical University",
  "Jiujiang University Medical College",
  "Medical College of Dalian University",
  "Lanzhou University Faculty of Medicine",
  "Medical College of Nankai University",
  "Nantong University Medical School",
  "Ningbo University Medical School",
  "Medical College of China Three Gorges University",
  "Medical College of Henan University of Science and Technology",
  "Medical College of Nanjing University",
  "Medical College of Wuhan University of Science and Technology",
  "Medical College of Yan'an University",
  "Mudanjiang Medical University",
  "Nanjing Medical University",
  "Ningxia Medical University",
  "North China University of Science and Technology College of Medicine",
  "North Sichuan Medical College",
  "Norman Bethune Health Science Center of Jilin University",
  "Northwest University for Nationalities College of Medicine",
  "Panzhihua University School of Medical Science",
  "Peking Union Medical University",
  "Peking University Health Science Center",
  "Qiqihar Medical University",
  "Qingdao University College of Medical Science",
  "Shaanxi University of Chinese Medicine",
  "Shandong First Medical University",
  "Shanghai Jiao Tong University School of Medicine",
  "Shandong University Cheeloo College of Medicine",
  "Shanghai Medical College, Fudan University",
  "Shantou University Medical College",
  "Shanxi Medical University",
  "Gansu College of Traditional Chinese Medicine",
  "Shenzhen University College of Medicine",
  "Shihezi University School of Medicine",
  "Soochow University Medical College",
  "Southeast University Medical College",
  "Southern Medical University",
  "Guizhou Medical University",
  "Southwest Medical University",
  "Tongji Medical College of Huazhong University of Science & Technology",
  "Tongji University School of Medicine",
  "Tsinghua University School of Medicine",
  "Weifang Medical University",
  "Wannan Medical College",
  "University of South China Faculty of Medicine",
  "Wenzhou Medical University",
  "West China College of Medicine, Sichuan University",
  "Xi'an Jiaotong University College of Medicine",
  "Wuhan University School of Medicine",
  "Xinxiang Medical University",
  "Xi'an Medical University",
  "Xiamen University School of Medicine",
  "Xinjiang Medical University",
  "Xiangya School of Medicine, Central South University",
  "Xuzhou Medical University",
  "Yanbian University Health Science Center",
  "Yangtze University Medical School",
  "Yangzhou University College of Medicine",
  "Yichun University School of Medicine",
  "Zhejiang Chinese Medical University",
  "Youjiang Medical College for Nationalities",
  "Zhongshan School of Medicine, Sun Yat-Sen University",
  "Zhejiang University School of Medicine",
  "Zhengzhou University Medical School",
  "Corporación Universitaria Remington Facultad de Ciencias de la Salud",
  "Zunyi Medical University",
  "Pontificia Universidad Javeriana Cali Facultad de Ciencias de la Salud",
  "Fundación Universitaria Juan N. Corpas",
  "Fundación Universitaria de Ciencias de la Salud (FUCS) Facultad de Medicina",
  "Pontificia Universidad Javeriana Bogotá Facultad de Medicina",
  "Universidad Antonio Nariño Facultad de Medicina",
  "Universidad Autónoma de Bucaramanga Facultad de Medicina",
  "Universidad CES Escuela de Medicina",
  "Universidad de Antioquia Facultad de Medicina",
  "Universidad Cooperativa de Colombia Escuela de Medicina, Medellin",
  "Universidad de Boyacá Facultad de Ciencias de la Salud",
  "Universidad de Caldas Facultad de Ciencias para la Salud",
  "Universidad de Cartagena Facultad de Medicina",
  "Universidad de la Sabana Facultad de Medicina",
  "Universidad de Los Andes Facultad de Medicina (Colombia)",
  "Universidad de Ciencias Aplicadas y Ambientales Facultad de Ciencias Médicas",
  "Universidad de Santander (UDES) Facultad de Ciencias de la Salud",
  "Universidad de Manizales Campo de Ciencias de la Salud",
  "Universidad del Cauca Facultad de Ciencias de la Salud",
  "Universidad del Magdalena Facultad de Ciencias de la Salud",
  "Universidad del Quindío Facultad de Ciencias de la Salud",
  "Universidad del Norte Programa de Medicina",
  "Universidad del Sinú Elias Bechara Zainúm Escuela de Medicina Cartagena",
  "Universidad del Rosario Escuela de Medicina y Ciencias de la Salud",
  "Universidad del Sinú Facultad de Ciencias de la Salud Montería",
  "Universidad del Tolima Facultad de Ciencias de la Salud",
  "Universidad del Valle Escuela de Medicina, Cali",
  "Universidad El Bosque, Escuela Medicina Colombiana",
  "Universidad ICESI Facultad de Ciencias de la Salud",
  "Universidad Militar Nueva Granada Facultad de Medicina",
  "Universidad Libre de Colombia Facultad de Ciencias de la Salud",
  "Universidad Industrial de Santander Escuela de Medicina",
  "Universidad Libre de Cali Facultad de Ciencias de la Salud",
  "Universidad Nacional de Colombia Facultad de Medicina",
  "Universidad Pedagógica y Tecnológica de Colombia Facultad de Ciencias de la Salud",
  "Universidad Pontificia Bolivariana Facultad de Medicina",
  "Universidad Simón Bolívar Facultad de Ciencias de la Salud",
  "Universidad Surcolombiana Facultad de la Salud",
  "Universidad Tecnológica de Pereira Facultad de Ciencias de la Salud",
  "Universidad de Costa Rica Facultad de Medicina",
  "Universidad de Ciencias Medicas Andrés Vesalio Guzmán (UCIMED) Escuela de Medicina",
  "Shenyang Medical College",
  "Universidad Hispanoamericana",
  "Tianjin Medical University School of Basic Medical Sciences",
  "Avalon University School of Medicine",
  "European University Cyprus School of Medicine",
  "St. Martinus University Faculty of Medicine",
  "Lékařská Fakulta, Univerzity Karlovy",
  "University of Cyprus Medical School",
  "University of Nicosia Medical School",
  "Fakulta Vojenského Zdravotnictví, Univerzity Obrany",
  "Lékařská Fakulta UK v Hradci Králové",
  "Lékařská Fakulta v Plzni, Univerzita Karlova v Praze",
  "Masarykova Univerzita Lékařská Fakulta",
  "Ostravská Univerzita v Ostravě Lékařská Fakulta",
  "Univerzity Palackého v Olomouci Lékařská Fakulta",
  "All Saints University School of Medicine, Dominica",
  "American Canadian School of Medicine (ACSOM)",
  "O&Med Medical School, Universidad Dominicana O&M",
  "Instituto Tecnológico de Santo Domingo (INTEC) Facultad de Ciencias de la Salud",
  "Pontificia Universidad Católica Madre y Maestra (PUCMM) Facultad de Ciencias de la Salud",
  "Pontificia Universidad Católica Madre y Maestra Facultad de Ciencias de la Salud Santo Domingo",
  "Universidad Autónoma de Santo Domingo Facultad de Ciencias de la Salud",
  "Universidad Iberoamericana (UNIBE) School of Medicine, Santo Domingo",
  "Universidad Católica Nordestana (UCNE) Facultad de Ciencias Medicas",
  "Universidad Central del Este (UCE) Facultad de Medicina",
  "Universidad Eugenio Maria de Hostos Escuela de Medicina",
  "Universidad Tecnológica de Santiago Escuela de Medicina, Santiago Campus",
  "Ain Shams University Faculty of Medicine",
  "Al-Azhar University Faculty of Medicine for Girls",
  "Alexandria University Faculty of Medicine",
  "Assiut University Faculty of Medicine",
  "Universidad Nacional Pedro Henríquez Ureña (UNPHU) Escuela de Medicina",
  "Universidad Tecnológica de Santiago Escuela de Medicina, Santo Domingo Campus",
  "Al-Azhar University Faculty of Medicine for Boys",
  "Beni Suef University Faculty of Medicine",
  "Cairo University Faculty of Medicine",
  "College of Human Medicine Benha University",
  "Damietta Faculty of Medicine, Al-Azhar University",
  "Mansoura University Faculty of Medicine",
  "Menoufia University Faculty of Medicine",
  "Suez Canal University Faculty of Medicine",
  "Sohag University Faculty of Medicine",
  "Qena Faculty of Medicine, South Valley University",
  "Tanta University Faculty of Medicine",
  "Zagazig University Faculty of Medicine",
  "Universidad Evangélica de El Salvador Facultad de Medicina",
  "Akaki Tsereteli State University Faculty of Medicine",
  "Alte University School of Medicine",
  "Caucasus International University Faculty of Medicine",
  "Batumi Shota Rustaveli State University Faculty of Natural Sciences and Health Care",
  "BAU International University Faculty of Medicine",
  "Caucasus University School of Medicine and Healthcare Management",
  "David Aghmashenebeli University of Georgia Faculty of Medicine",
  "David Tvildiani Medical University AIETI Medical School",
  "East European University Faculty of Healthcare Sciences",
  "East-West Teaching University School of Medicine",
  "European University Faculty of Medicine",
  "Georgian American University",
  "Georgian National University SEU Faculty of Medicine",
  "Grigol Robakidze University School of Medicine",
  "Ilia State University Faculty of Natural Sciences and Medicine",
  "Ivane Javakhishvili Tbilisi State University Faculty of Medicine",
  "Ken Walker International University Faculty of Medicine and Stomatology",
  "Kutaisi University Faculty of Medicine",
  "New Vision University School of Medicine",
  "Petre Shotadze Tbilisi Medical Academy Faculty of Medicine",
  "Tbilisi State Medical University Faculty of Medicine",
  "Teaching University Geomedi Faculty of Medicine",
  "University of Georgia School of Health Sciences and Public Health",
  "St. George's University School of Medicine",
  "Charité-Universitätsmedizin Berlin Medizinische Fakultät",
  "Universidad Francisco Marroquín Facultad de Medicina",
  "Universidad Rafael Landívar Facultad de Ciencias de la Salud",
  "Universidad Mariano Gálvez de Guatemala Facultad de Ciencias Médicas y de la Salud",
  "University of Guyana School of Medicine",
  "Texila American University College of Medicine",
  "Semmelweis University Faculty of General Medicine",
  "University of Debrecen Faculty of Medicine",
  "University of Pécs Medical School",
  "Employees' State Insurance Corporation Medical College, Bangalore",
  "University of Szeged Faculty of Medicine",
  "Employees' State Insurance Corporation Medical College, Faridabad",
  "Employees' State Insurance Corporation Medical College, Gulbarga",
  "Universidad de Iberoamérica Escuela de Medicina",
  "Employees' State Insurance Corporation Medical College, Sanathnagar",
  "A.C.S. Medical College and Hospital",
  "A.J. Institute of Medical Sciences and Research Centre",
  "Aarupadai Veedu Medical College",
  "Agartala Government Medical College",
  "Universidad Latina de Costa Rica Facultad de Ciencias de la Salud, Escuela de Medicina",
  "Acharya Shri Chander College of Medical Sciences",
  "Adesh Institute of Medical Sciences & Research",
  "Adichunchanagiri Institute of Medical Sciences",
  "Al-Azhar Medical College and Super Speciality Hospital",
  "Al-Ameen Medical College",
  "All India Institute of Medical Sciences, Bhopal",
  "All India Institute of Medical Sciences, Bhubaneswar",
  "All India Institute of Medical Sciences, Jodhpur",
  "All India Institute of Medical Sciences, New Delhi",
  "All India Institute of Medical Sciences, Patna",
  "All India Institute of Medical Sciences, Raipur",
  "All India Institute of Medical Sciences, Rishikesh",
  "Amala Institute of Medical Sciences",
  "American International Institute of Medical Sciences",
  "Alluri Sitaram Raju Academy of Medical Sciences",
  "Amrita School of Medicine",
  "Ananta Institute of Medical Sciences & Research Centre",
  "Andaman & Nicobar Islands Institute of Medical Sciences",
  "Andhra Medical College",
  "Annapoorna Medical College and Hospital",
  "Anugrah Narayan Magadh Medical College and Hospital",
  "Apollo Institute of Medical Sciences and Research, Chittoor",
  "Apollo Institute of Medical Sciences and Research, Hyderabad",
  "Armed Forces Medical College",
  "Army College of Medical Sciences",
  "Assam Medical College",
  "B.G.S. Global Institute of Medical Sciences",
  "Azeezia Institute of Medical Science",
  "Ashwini Rural Medical College, Hospital and Research Centre",
  "B.J. Government Medical College, Pune",
  "B.J. Medical College, Ahmedabad",
  "B.K.L. Walawalkar Rural Medical College",
  "B.P.S. Government Medical College for Women",
  "Baba Raghav Das Medical College",
  "Bangalore Medical College and Research Institute",
  "Bankura Sammilani Medical College",
  "Basaveshwara Medical College and Hospital",
  "Belgaum Institute of Medical Sciences",
  "Bharat Ratna Late Shri Atal Bihari Vajpyee Memorial Medical College",
  "Bhagwan Mahavir Institute of Medical Sciences",
  "Bharati Vidyapeeth Medical College, Pune",
  "Bharati Vidyapeeth Medical College and Hospital, Sangli",
  "Bidar Institute of Medical Sciences",
  "Bhaskar Medical College",
  "Burdwan Medical College",
  "C.U. Shah Medical College",
  "Career Institute of Medical Sciences & Hospital",
  "Calcutta National Medical College",
  "Chalmeda Anand Rao Institute of Medical Sciences",
  "Chamarajanagar Institute of Medical Sciences",
  "Chandulal Chandrakar Memorial Medical College",
  "Chengalpattu Medical College",
  "Chhattisgarh Institute of Medical Sciences",
  "Chettinad Hospital & Research Institute",
  "Chintpurni Medical College and Hospital, Gurdaspur",
  "Coimbatore Medical College",
  "Chirayu Medical College and Hospital",
  "Christian Medical College, Ludhiana",
  "College of Medicine and Sagore Dutta Hospital",
  "Christian Medical College, Vellore",
  "College of Medicine and J.N.M. Hospital",
  "D.M. Wayanad Institute of Medical Sciences",
  "D.Y. Patil Medical College, Kolhapur",
  "Dayanand Medical College",
  "Darbhanga Medical College and Hospital",
  "Deccan College of Medical Sciences",
  "D.Y. Patil University School of Medicine",
  "Dhanalakshmi Srinivasan Medical College and Hospital, Perambalur",
  "Dr. B.R. Ambedkar Medical College",
  "Dr. D.Y. Patil Medical College, Hospital and Research Centre",
  "Dr. Baba Saheb Ambedkar Medical College",
  "Dr. Panjabrao Deshmukh Memorial Medical College",
  "Dr. S.N. Medical College",
  "Dr. Shankarrao Chavan Government Medical College, Nanded",
  "Dr. Pinnamaneni Siddhartha Institute of Medical Sciences",
  "Dr. Rajendra Prasad Government Medical College",
  "Dr. Ulhas Patil Medical College & Hospital",
  "Dr. Somervell Memorial CSI Hospital & Medical College",
  "Dr.Vasantrao Pawar Medical College Hospital and Research Centre",
  "Dr. Vaishampayan Memorial Medical College",
  "Dr. Vithalrao Vikhe Patil Foundation's Medical College",
  "Dr. V.R.K. Women's Medical College",
  "F.H. Medical College & Hospital",
  "G.M.E.R.S. Medical College and Hospital, Gandhinagar",
  "Employees' State Insurance Corporation Medical College, Joka",
  "Era's Lucknow Medical College",
  "E.S.I.C. Medical College & Postgraduate Institute of Medical Sciences and Research",
  "Fathima Institute of Medical Sciences, Kadapa",
  "G.M.E.R.S. Medical College and Hospital, Dharpur Patan",
  "G.C.S. Medical College, Hospital and Research Centre",
  "Father Muller Medical College",
  "G.M.E.R.S. Medical College and Hospital, Sola",
  "A.C.S.R. Government Medical College",
  "G.M.E.R.S. Medical College, Junagadh",
  "G.M.E.R.S. Medical College, Valsad",
  "G.S.L. Medical College, Rajahmundry",
  "G.S.V.M. Medical College",
  "Gayatri Vidya Parishad Institute of Health Care & Medical Technology",
  "Gadag Institute of Medical Sciences, Mallasamudra",
  "Gauhati Medical College",
  "Gajra Raja Medical College",
  "Gandhi Medical College, Bhopal",
  "Gian Sagar Medical College",
  "GITAM Institute of Medical Sciences and Research",
  "Goa Medical College",
  "Gandhi Medical College and Hospital, Secunderabad",
  "Geetanjali Medical College and Hospital",
  "Government Medical College Amritsar",
  "Government Medical College Akola",
  "Government Kilpauk Medical College",
  "Government Medical College & ESIC Hospital, Coimbatore",
  "Government Doon Medical College",
  "Government Erode Medical College and Hospital Perundurai",
  "Government Dharmapuri Medical College",
  "Government Medical College Anantapuramu",
  "Government Medical College and Hospital Cuddalore",
  "Government Medical College Aurangabad",
  "Government Medical College Chandigarh",
  "Government Medical College Ernakulam",
  "Government Medical College Kannur",
  "Government Medical College Haldwani",
  "Government Medical College Chandrapur",
  "Government Medical College Bhavnagar",
  "Government Medical College Jammu",
  "Government Medical College Kota",
  "Government Medical College Nizamabad",
  "Government Medical College Kozhikode",
  "Government Medical College Omandurar",
  "Government Medical College Latur",
  "Government Medical College Kottayam",
  "Government Medical College Miraj",
  "Government Medical College Mahabubnagar",
  "Government Medical College Nagpur",
  "Government Medical College Patiala",
  "Government Medical College Srinagar",
  "Government Medical College Surat",
  "Government Medical College Thrissur",
  "Government Medical College, Ongole",
  "Government Villupuram Medical College",
  "Grant Government Medical College, Mumbai",
  "Government Medical College Vellore",
  "Government Medical College, Srikakulam",
  "Government Sivagangai Medical College",
  "Government Thiruvannamalai Medical College",
  "Gujarat Adani Institute of Medical Sciences",
  "Guru Gobind Singh Medical College",
  "Hassan Institute of Medical Sciences",
  "Government Medical College, Kadapa",
  "Great Eastern Medical School and Hospital",
  "Heritage Institute of Medical Sciences, Varanasi",
  "Guntur Medical College",
  "H.B.T. Medical College & Dr. R.N. Cooper Municipal General Hospital",
  "Hamdard Institute of Medical Sciences & Research, New Delhi",
  "Gulbarga Institute of Medical Sciences",
  "Hi-Tech Medical College and Hospital, Bhubaneswar",
  "Indira Gandhi Government Medical College, Nagpur",
  "Hi-Tech Medical College and Hospital, Rourkela",
  "Himalayan Institute of Medical Sciences",
  "Indian Institute of Medical Science & Research",
  "Index Medical College & Research Centre",
  "Hind Institute of Medical Sciences, Lucknow",
  "Indira Gandhi Institute of Medical Sciences",
  "Institute of Medical Sciences, Varanasi",
  "Indira Gandhi Medical College and Research Institute",
  "Indira Gandhi Medical College, Shimla",
  "Institute of Medical Sciences & SUM Hospital",
  "Institute of Postgraduate Medical Education & Research, Kolkata",
  "Integral Institute of Medical Sciences & Research",
  "IQ City Medical College",
  "J.S.S. Medical College",
  "J.J.M. Medical College, Davangere",
  "Jawaharlal Institute of Post-Graduate Medical Education and Research (JIPMER)",
  "Jaipur National University Institute for Medical Sciences and Research Centre",
  "Jawaharlal Nehru Institute of Medical Sciences, Porompat",
  "Jawaharlal Nehru Medical College, Ajmer",
  "Jawaharlal Nehru Medical College, Aligarh",
  "Jawaharlal Nehru Medical College, Belgaum",
  "Jawaharlal Nehru Medical College, Bhagalpur",
  "Jawaharlal Nehru Medical College, Wardha",
  "Jhalawar Medical College",
  "JMF's A.C.P.M. Medical College",
  "K.D. Medical College Hospital & Research Centre",
  "Jubilee Mission Medical College and Research Institute",
  "Jorhat Medical College and Hospital",
  "K.A.P. Viswanatham Government Medical College",
  "K.J. Somaiya Medical College",
  "K.M.C.T. Medical College",
  "K.S. Hegde Medical Academy",
  "G.M.E.R.S. Medical College and Hospital, Himmatnagar",
  "K.V.G. Medical College",
  "Kakatiya Medical College",
  "Kalinga Institute of Medical Sciences",
  "Kamineni Institute of Medical Sciences",
  "Kannur Medical College",
  "Kanachur Institute of Medical Sciences",
  "Kanyakumari Government Medical College",
  "Kamineni Academy of Medical Sciences & Research Center",
  "G.M.E.R.S. Medical College, Gotri",
  "Karpaga Vinayaga Institute of Medical Sciences",
  "Karpagam Faculty of Medical Sciences and Research",
  "Karwar Institute of Medical Sciences",
  "Karuna Medical College",
  "Kasturba Medical College Mangalore",
  "Kasturba Medical College Manipal",
  "Katihar Medical College",
  "Kempegowda Institute of Medical Sciences",
  "Katuri Medical College, Guntur",
  "Khaja Bandanawaz University Faculty of Medical Sciences",
  "King George's Medical University",
  "Krishna Vishwa Vidyapeeth Faculty of Medical Sciences",
  "Kodagu Institute of Medical Sciences",
  "Kolkata Medical College & Hospital",
  "Koppal Institute of Medical Sciences",
  "Konaseema Institute of Medical Sciences & Research Foundation",
  "Lady Hardinge Medical College",
  "Lala Lajpat Rai Memorial Medical College",
  "Late Baliram Kashyap Memorial Government Medical College",
  "Kurnool Medical College",
  "L.N. Medical College and Research Centre",
  "Lokmanya Tilak Municipal Medical College",
  "M.N.R. Medical College",
  "M.E.S. Medical College",
  "M.P. Shah Government Medical College",
  "Madha Medical College and Research Institute",
  "M.S. Ramaiah Medical College",
  "M.V.J. Medical College and Research Hospital",
  "Madras Medical College",
  "Madurai Medical College",
  "Mahadevappa Rampure Medical College",
  "Maharajah's Institute of Medical Sciences, Vizianagaram",
  "Maharaja Krishna Chandra Gajapati Medical College",
  "Maharaja Agrasen Medical College",
  "Maharani Laxmi Bai Medical College",
  "Maharashtra Institute of Medical Education & Research (MIMER)",
  "Maharashtra Institute of Medical Sciences & Research (MIMSR)",
  "Mahatma Gandhi Institute of Medical Sciences, Wardha",
  "Maharishi Markandeshwar Institute of Medical Sciences & Research",
  "Maharishi Markandeshwar Medical College & Hospital, Solan",
  "Mahatma Gandhi Medical College and Hospital, Jaipur",
  "Mahatma Gandhi Medical College and Research Institute",
  "Mahatma Gandhi Memorial Medical College, Jamshedpur",
  "Mahatma Gandhi Memorial Medical College, Indore",
  "Mahatma Gandhi Mission's Medical College, Navi Mumbai",
  "Mahatma Gandhi Mission's Medical College, Aurangabad",
  "Mahavir Institute of Medical Sciences",
  "Maheshwara Medical College",
  "Major S.D. Singh Medical College and Hospital",
  "Malabar Medical College",
  "Malankara Orthodox Syrian Church Medical College",
  "Malla Reddy Institute of Medical Sciences",
  "Malda Medical College and Hospital",
  "Mandya Institute of Medical Sciences",
  "Mamata Medical College",
  "Mallareddy Medical College for Women",
  "Mata Gujri Memorial Medical College",
  "Maulana Azad Medical College",
  "Mayo Institute of Medical Sciences",
  "Medical College Baroda",
  "Medical College Thiruvananthapuram",
  "MediCiti Institute of Medical Sciences",
  "Meenakshi Medical College Hospital and Research Institute",
  "Melmaruvathur Adhiparasakthi Institute of Medical Sciences & Research",
  "Mohan Kumaramangalam Medical College",
  "Moti Lal Nehru Medical College, Allahabad",
  "Murshidabad Medical College and Hospital",
  "Midnapore Medical College",
  "Mount Zion Medical College",
  "Muzaffarnagar Medical College",
  "Mysore Medical College and Research Institute",
  "N.K.P. Salve Institute of Medical Sciences",
  "N.R.I. Institute of Medical Sciences, Visakhapatnam",
  "N.C. Medical College & Hospital",
  "N.R.I. Medical College, Guntur",
  "Nalanda Medical College",
  "Narayan Medical College and Hospital",
  "Narendra Modi Medical College Ahmedabad",
  "Narayana Medical College",
  "Navodaya Medical College",
  "Netaji Subhash Chandra Bose Medical College",
  "National Institute of Medical Sciences and Research Medical College and Hospital",
  "Nimra Institute of Medical Sciences",
  "Nilratan Sircar Medical College",
  "North Bengal Medical College",
  "North Delhi Municipal Corporation Medical College",
  "Osmania Medical College",
  "North Eastern Indira Gandhi Regional Institute of Health and Medical Sciences",
  "P.E.S. Institute of Medical Sciences and Research",
  "P.S.G. Institute of Medical Sciences and Research",
  "P.K. Das Institute of Medical Sciences",
  "Pacific Institute of Medical Sciences",
  "Pacific Medical College & Hospital",
  "Pandit Deendayal Upadhyay Medical College, Rajkot",
  "Patna Medical College",
  "People's College of Medical Sciences & Research Centre",
  "Parul Institute of Medical Sciences & Research",
  "Pramukhswami Medical College",
  "Pondicherry Institute of Medical Sciences and Research",
  "Prathima Institute of Medical Sciences",
  "Pt. Jawahar Lal Nehru Memorial Medical College Raipur",
  "R.K.D.F. Medical College Hospital & Research Centre, Jatkhedi",
  "Pt. B.D.S. Postgraduate Institute of Medical Sciences",
  "Pushpagiri Institute of Medical Sciences and Research Centre",
  "Punjab Institute of Medical Sciences",
  "K.P.C. Medical College and Hospital",
  "R.V.M. Institute of Medical Sciences and Research Center",
  "R.U.H.S. College of Medical Sciences",
  "Raichur Institute of Medical Sciences",
  "Rajarshi Chhatrapati Shahu Maharaj Government Medical College Kolhapur",
  "Rajarajeswari Medical College & Hospital",
  "Rajendra Institute of Medical Sciences, Ranchi",
  "Rajiv Gandhi Institute of Medical Sciences, Adilabad",
  "Rajiv Gandhi Medical College and Chhatrapati Shivaji Maharaj Hospital",
  "Rajkiya Medical College, Jalaun",
  "Karnataka Institute of Medical Sciences",
  "Rama Medical College and Hospital, Hapur",
  "Rama Medical College and Hospital, Kanpur",
  "Rangaraya Medical College",
  "Regional Institute of Medical Sciences",
  "Ravindra Nath Tagore Medical College",
  "Rohilkhand Medical College & Hospital",
  "Rural Medical College of Pravara Medical Trust",
  "Ruxmaniben Deepchand Gardi Medical College",
  "S. Nijalingappa Medical College and H.S.K. Hospital & Research Centre",
  "S.M.B.T. Institute of Medical Sciences & Research Centre",
  "S.R.M. Medical College Hospital and Research Centre",
  "S.D.M. Medical College",
  "S.G.T. Medical College, Hospital and Research Institute",
  "S.S. Institute of Medical Sciences & Research Centre, Davangere",
  "S.V.S. Medical College",
  "Santhiram Medical College",
  "Santosh Medical College",
  "Sapthagiri Institute of Medical Sciences & Research Centre",
  "Saraswathi Institute of Medical Sciences",
  "Sardar Patel Medical College",
  "Saraswati Medical College",
  "Sarojini Naidu Medical College",
  "Saveetha Medical College and Hospital",
  "Sawai Man Singh Medical College",
  "School of Medical Sciences and Research, Greater Noida",
  "Seth G.S. Medical College",
  "Shahed Nirmal Mahto Medical College and Hospital",
  "Shadan Institute of Medical Sciences Teaching Hospital & Research Centre",
  "Shaheed Hasan Khan Mewati Government Medical College",
  "Shaikh-Ul-Hind Maulana Mahmood Hasan Medical College",
  "Sher-i-Kashmir Institute of Medical Sciences (SKIMS)",
  "Shimoga Institute of Medical Sciences",
  "Shri B.M. Patil Medical College Hospital & Research Centre, B.L.D.E. University",
  "Shri Bhausaheb Hire Government Medical College",
  "Shri Guru Ram Rai Institute of Medical & Health Sciences",
  "Shri Ram Murti Smarak Institute of Medical Sciences",
  "Shri Vasantrao Naik Government Medical College",
  "Shri Sathya Sai Medical College and Research Institute",
  "Shyam Shah Medical College",
  "Shridevi Institute of Medical Sciences & Research Hospital",
  "Siddhartha Medical College",
  "Sikkim Manipal Institute of Medical Sciences, Sikkim Manipal University",
  "Silchar Medical College",
  "Smt. B.K. Shah Medical Institute and Research Centre",
  "Smt. Kashibai Navale Medical College and Hospital",
  "Smt. N.H.L. Municipal Medical College",
  "Sree Balaji Medical College and Hospital",
  "Sree Gokulam Medical College & Research Foundation",
  "Sree Mookambika Institute of Medical Sciences",
  "Sree Narayana Institute of Medical Sciences",
  "Sri Aurobindo Institute of Medical Sciences",
  "Sree Uthradom Thirunal Academy of Medical Sciences",
  "Sri Devaraj Urs Medical College",
  "Sri Krishna Medical College",
  "Sri Guru Ram Das Institute of Medical Sciences & Research",
  "Sri Lakshmi Narayana Institute of Medical Sciences",
  "Sri Manakula Vinayagar Medical College & Hospital",
  "Sri Muthukumaran Medical College Hospital and Research Institute",
  "Sri Padmavathi Medical College for Women",
  "Sri Ramachandra Institute of Higher Education and Research (Deemed University)",
  "Sri Siddhartha Medical College",
  "Sri Venkateshwaraa Medical College Hospital & Research Centre",
  "Sri Venkateswara Medical College",
  "Srinivas Institute of Medical Sciences and Research Centre",
  "Sriram Chandra Bhanja Medical College",
  "St. John's Medical College",
  "Subharti Medical College",
  "Surat Municipal Institute of Medical Education and Research",
  "Stanley Medical College",
  "Swami Ramanand Teerth Rural Medical College",
  "Subbaiah Institute of Medical Sciences",
  "Tagore Medical College and Hospital",
  "T.S. Misra Medical College & Hospital",
  "T.D. Medical College",
  "Teerthankar Mahaveer Medical College",
  "Terna Medical College",
  "Thanjavur Medical College",
  "The Oxford Medical College, Hospital & Research Centre",
  "Theni Government Medical College",
  "Thoothukudi Medical College",
  "Tirunelveli Medical College",
  "Topiwala National Medical College",
  "Travancore Medical College",
  "Trichy SRM Medical College Hospital and Research Centre",
  "Tripura Medical College & Dr. B. R. Ambedkar Memorial Teaching Hospital",
  "University College of Medical Sciences and Guru Teg Bahadur Hospital",
  "Uttar Pradesh University of Medical Sciences",
  "Vardhman Mahavir Medical College & Safdarjung Hospital",
  "Veer Chandra Singh Garhwali Government Institute of Medical Science & Research",
  "Velammal Medical College Hospital and Research Institute",
  "Veer Surendra Sai Institute of Medical Sciences and Research",
  "Venkateshwara Institute of Medical Sciences",
  "Vijayanagar Institute of Medical Sciences",
  "Vinayaka Mission's Kirupananda Variyar Medical College, Salem",
  "Vinayaka Mission's Medical College, Karaikal",
  "Viswabharathi Medical College",
  "Vydehi Institute of Medical Sciences & Research Centre, Bangalore",
  "Yenepoya Medical College",
  "Jakarta Christian University (UKRIDA) Faculty of Medicine",
  "Universitas Ahmad Yani Fakultas Kedokteran",
  "Universitas Airlangga Fakultas Kedokteran",
  "Universitas Andalas Fakultas Kedokteran",
  "Universitas Baiturrahmah Fakultas Kedokteran",
  "Universitas Brawijaya Fakultas Kedokteran",
  "Universitas Diponegoro Fakultas Kedokteran",
  "Universitas Gadjah Mada Sekip Fakultas Kedokteran, Kesehatan Masyarakat dan Keperawatan",
  "Universitas Haluoleo Fakultas Kedokteran",
  "Universitas Hang Tuah Fakultas Kedokteran",
  "Universitas Hasanuddin Fakultas Kedokteran",
  "Universitas Indonesia Fakultas Kedokteran",
  "Universitas Islam Sultan Agung (UNISSULA) Fakultas Kedokteran",
  "Universitas Islam Sumatra Utara Fakultas Kedokteran",
  "R.G. Kar Medical College",
  "Universitas Katolik Indonesia Atma Jaya Fakultas Kedokteran",
  "Universitas Muhammadiyah Malang Fakultas Kedokteran",
  "Universitas Methodist Indonesia Faculty of Medicine",
  "Universitas Kristen Maranatha Fakultas Kedokteran",
  "Universitas Kristen Indonesia Fakultas Kedokteran",
  "Universitas Lambung Mangkurat Fakultas Kedokteran",
  "Universitas Muhammadiyah Jakarta Fakultas Kedokteran dan Kesehatan",
  "Universitas Padjadjaran Fakultas Kedokteran",
  "Rajshree Medical Research Institute",
  "Universitas Pembangunan Nasional Veteran Fakultas Kedokteran",
  "Universitas Prima Indonesia Fakultas Kedokteran",
  "Universitas Sam Ratulangi Fakultas Kedokteran",
  "Universitas Riau Fakultas Kedokteran",
  "Universitas Sebelas Maret Fakultas Kedokteran",
  "Universitas Sumatera Utara Fakultas Kedokteran",
  "Universitas Trisakti Fakultas Kedokteran",
  "Universitas Sriwijaya Fakultas Kedokteran",
  "Universitas Syiah Kuala Fakultas Kedokteran",
  "Universitas Tarumanagara Fakultas Kedokteran",
  "Universitas Udayana Fakultas Kedokteran",
  "Universitas Mulawarman Fakultas Kedokteran",
  "Universitas Muslim Indonesia Fakultas Kedokteran",
  "Universitas Wijayakusuma Fakultas Kedokteran",
  "Universitas Muhammadiyah Yogyakarta Fakultas Kedokteran",
  "Abadan University of Medical Sciences Medical School",
  "Universitas Yarsi Fakultas Kedokteran",
  "Ahvaz Jondishapour University of Medical Sciences",
  "Alborz University of Medical Sciences School of Medicine",
  "Arak University of Medical Sciences School of Medicine",
  "Army University of Medical Sciences Medical School",
  "Bam University of Medical Sciences and Health Services",
  "Ardabil University of Medical Sciences Faculty of Medicine",
  "Babol University of Medical Sciences Faculty of Medicine",
  "Bushehr University of Medical Sciences and Health Services",
  "Birjand University of Medical Sciences and Health Services Medical School",
  "Dezful University of Medical Sciences and Health Services",
  "Hamadan University of Medical Sciences Medical School",
  "Golestan University of Medical Sciences Medical School",
  "Fasa University of Medical Sciences Faculty of Medicine",
  "Guilan University of Medical Sciences",
  "Gonabad University of Medical Sciences and Health",
  "Hormozgan University of Medical Sciences (HUMS) Faculty of Medicine",
  "Ilam University of Medical Sciences School of Medicine",
  "Iran University of Medical Sciences (IUMS) School of Medicine",
  "Iranshahr University of Medical Sciences & Health Services",
  "Isfahan University of Medical Sciences Faculty of Medicine",
  "Islamic Azad University Ardabil Branch Faculty of Medicine",
  "Islamic Azad University Kazeroon Branch Faculty of Medicine",
  "Islamic Azad University Kerman Branch Faculty of Medicine",
  "Islamic Azad University Najafabad Branch Faculty of Medical Science",
  "Islamic Azad University Qeshm Branch Faculty of Medicine",
  "Islamic Azad University Mashhad Branch Faculty of Medicine",
  "Islamic Azad University Sari Branch Faculty of Medicine",
  "Islamic Azad University Shahrood Branch Faculty of Medicine",
  "Islamic Azad University Tabriz Branch Faculty of Medicine",
  "Islamic Azad University Tonekabon Branch Faculty of Medicine",
  "Islamic Azad University Tehran Faculty of Medicine",
  "Islamic Azad University Yazd Ali ibn Abitaleb Medical School",
  "Islamic Azad University Zahedan Branch Faculty of Medicine",
  "Jahrom University of Medical Sciences Faculty of Medicine",
  "Jiroft University of Medical Sciences and Health Services",
  "Kerman University of Medical Sciences Faculty of Medicine",
  "Kashan University of Medical Sciences and Health Services Faculty of Medicine",
  "Kermanshah University of Medical Sciences School of Medicine",
  "Kurdistan University of Medical Sciences School of Medicine",
  "Lorestan University of Medical Sciences School of Medicine",
  "Mazandaran University of Medical Sciences Faculty of Medicine",
  "Mashhad University of Medical Sciences Faculty of Medicine",
  "North Khorasan University of Medical Sciences Faculty of Medicine",
  "Qazvin University of Medical Sciences Shahid Babaei Faculty of Medicine",
  "Qom Islamic Azad University Medicine College",
  "Qom University of Medical Sciences Faculty of Medicine",
  "Rafsanjan University of Medical Sciences School of Medicine",
  "Sabzevar Faculty of Medical Sciences School of Medicine",
  "Semnan University of Medical Sciences Faculty of Medicine",
  "Shahed University Faculty of Medicine",
  "Shahid Beheshti University of Medical Sciences Faculty of Medicine",
  "Shahid Sadooghi University of Medical Sciences College of Medicine",
  "Shahrekord University of Medical Sciences Faculty of Medicine",
  "Shahroud University of Medical Sciences Faculty of Medicine",
  "Shiraz University of Medical Sciences School of Medicine",
  "Tabriz University of Medical Sciences Faculty of Medicine",
  "Tehran University of Medical Sciences School of Medicine",
  "Urmia University of Medical Sciences Faculty of Medicine",
  "Yasuj University of Medical Sciences Faculty of Medicine",
  "Zabol University of Medical Sciences and Health Sciences",
  "Zanjan University of Medical Sciences Faculty of Medicine",
  "Al-Kindy College of Medicine",
  "Zahedan University of Medical Sciences Faculty of Medicine",
  "Al-Nahrain University College of Medicine",
  "Hawler Medical University College of Medicine",
  "Kufa University College of Medicine",
  "University of Al-Mustansiriyah College of Medicine",
  "University of Baghdad College of Medicine",
  "University of Basrah College of Medicine",
  "University of Duhok College of Medicine",
  "University of Mosul College of Medicine",
  "University of Wasit College of Medicine",
  "NUI Galway School of Medicine",
  "Royal College of Surgeons in Ireland School of Medicine",
  "Trinity College Dublin School of Medicine",
  "University College Cork School of Medicine",
  "University College Dublin School of Medicine",
  "University of Limerick School of Medicine",
  "Bar-Ilan University Faculty of Medicine in the Galilee",
  "Ruth and Bruce Rappaport Faculty of Medicine, Technion Israel Institute of Technology",
  "Ben-Gurion University of the Negev Faculty of Health Sciences",
  "Sackler School of Medicine, Tel Aviv University",
  "Asahikawa Medical University",
  "University of the West Indies Faculty of Medical Sciences, Jamaica",
  "Ehime University School of Medicine",
  "Akita University School of Medicine",
  "Fujita Health University School of Medicine",
  "Aichi Medical University",
  "The Hebrew University-Hadassah Medical School",
  "Dokkyo Medical University",
  "Chiba University School of Medicine",
  "Gifu University School of Medicine",
  "Fukuoka University School of Medicine",
  "Fukushima Medical University School of Medicine",
  "Hiroshima University Faculty of Medicine",
  "Hamamatsu University School of Medicine",
  "Gunma University Faculty of Medicine",
  "Hokkaido University School of Medicine",
  "Hirosaki University School of Medicine",
  "Iwate Medical University School of Medicine",
  "Hyogo College of Medicine",
  "Jichi Medical University School of Medicine",
  "Jikei University School of Medicine",
  "Juntendo University School of Medicine",
  "Kagawa University Faculty of Medicine",
  "Kagoshima University Faculty of Medicine",
  "Kanazawa Medical University School of Medicine",
  "Kanazawa University School of Medicine",
  "Universitas Jenderal Soedirman Fakultas Kedokteran dan Ilmu-Ilmu Kesehatan",
  "Kawasaki Medical School",
  "Universitas Pelita Harapan Fakultas Kedokteran",
  "Kitasato University School of Medicine",
  "Kindai University School of Medicine",
  "Kobe University School of Medicine",
  "Kumamoto University School of Medicine",
  "Kurume University School of Medicine",
  "Kyorin University School of Medicine",
  "Kyoto Prefectural University of Medicine",
  "Mie University School of Medicine",
  "Kyoto University Faculty of Medicine",
  "Kyushu University Faculty of Medicine",
  "Nara Medical University Faculty of Medicine",
  "Oita University Faculty of Medicine",
  "Nagasaki University School of Medicine",
  "Nagoya University School of Medicine",
  "Okayama University School of Medicine",
  "Nagoya City University Medical School",
  "Nippon Medical School",
  "National Defense Medical College",
  "Nihon University School of Medicine",
  "Niigata University School of Medicine",
  "Osaka City University Medical School",
  "Sapporo Medical University School of Medicine",
  "Shinshu University Faculty of Medicine",
  "Osaka Medical College",
  "Showa University School of Medicine",
  "St. Marianna University School of Medicine",
  "Toho University Faculty of Medicine",
  "Tokai University School of Medicine",
  "Tokushima University School of Medicine",
  "Tokyo Medical and Dental University Faculty of Medicine",
  "Tottori University Faculty of Medicine",
  "University of Occupational & Environmental Health School of Medicine",
  "University of Miyazaki Faculty of Medicine",
  "University of the Ryukyus Faculty of Medicine",
  "University of Tokyo Faculty of Medicine",
  "Wakayama Medical University School of Medicine",
  "University of Yamanashi Faculty of Medicine",
  "Yamagata University School of Medicine",
  "Yamaguchi University Faculty of Medicine and Health Sciences",
  "University of Toyama School of Medicine",
  "University of Tsukuba School of Medicine",
  "Saga University Faculty of Medicine",
  "Tokyo Medical University",
  "Saitama Medical University Faculty of Medicine",
  "Tohoku University School of Medicine",
  "Osaka University School of Medicine",
  "Tokyo Women's Medical University School of Medicine",
  "Teikyo University School of Medicine",
  "Yokohama City University School of Medicine",
  "Shiga University of Medical Science",
  "Al-Balqa Applied University Faculty of Medicine",
  "Hashemite University Faculty of Medicine",
  "Jordan University of Science and Technology Faculty of Medicine",
  "Mu'tah University Faculty of Medicine",
  "University of Jordan Faculty of Medicine",
  "Yarmouk University Faculty of Medicine",
  "International Kazakh-Turkish University named after Khoja Ahmet Yassawi Faculty of Medicine",
  "JSC National Medical University",
  "Kazakh-Russian Medical University",
  "Karaganda Medical University",
  "NJSC Astana Medical University",
  "Semey Medical University",
  "West Kazakhstan State Medical University named after Marat Ospanov",
  "Kuwait University Faculty of Medicine",
  "South Kazakhstan State Pharmaceutical Academy",
  "Al-Farabi Kazakh National University Faculty of Medicine and Health Care",
  "Eurasian International University Faculty of Medicine",
  "Avicenna International Medical University",
  "I.K. Akhunbaev Kyrgyz State Medical Academy Faculty of General Medicine",
  "Adam University School of Medicine",
  "International Higher School of Medicine",
  "Ala-Too International University",
  "International School of Medicine, International University of Kyrgyzstan Eastern Medical Campus",
  "Osh International Medical University",
  "International Medical University",
  "Osh State University Medical Faculty",
  "Jalal-Abad State University Medical Faculty",
  "Kyrgyz-Russian Slavic State University (KRSU) Medical Faculty",
  "Royal Metropolitan University",
  "S. Tentishev Asian Medical Institute",
  "Salymbekov University Faculty of Medicine",
  "American University of Beirut Faculty of Medicine",
  "Beirut Arab University Faculty of Medicine",
  "Libyan International Medical University Faculty of Medicine",
  "University of Benghazi Faculty of Medicine",
  "University of Tripoli Faculty of Medicine",
  "Université Saint Esprit de Kaslik Faculté de Médecine et des Sciences Médicales",
  "University of Balamand Faculty of Medicine and Medical Sciences",
  "LAU Gilbert and Rose-Marie Chagoury School of Medicine",
  "Misurata University Faculty of Medicine",
  "Vilnius University Faculty of Medicine",
  "Lithuanian University of Health Sciences Faculty of Medicine",
  "University of Zawia Faculty of Medicine",
  "Asian Institute of Medicine, Science and Technology (AIMST)",
  "International Islamic University Malaysia Kulliyyah of Medicine",
  "Jeffrey Cheah School of Medicine and Health Sciences (Monash University)",
  "International Medical School, Management and Science University",
  "Manipal University College Malaysia",
  "Newcastle University Medicine Malaysia",
  "Lincoln University College Faculty of Medicine",
  "International Medical University Faculty of Medicine and Health",
  "Perdana University Graduate School of Medicine",
  "Perdana University Royal College of Surgeons in Ireland (PU-RCSI) School of Medicine",
  "MAHSA University Faculty of Medicine",
  "SEGi University",
  "RCSI & UCD Malaysia Campus",
  "UCSI University Faculty of Medicine and Health Sciences",
  "Quest International University Perak Faculty of Medicine",
  "Taylor's University School of Medicine",
  "Universiti Kebangsaan Malaysia Fakulti Perubatan",
  "Universiti Kuala Lumpur Royal College of Medicine Perak",
  "Universiti Malaya Fakulti Perubatan",
  "Universiti Malaysia Sabah Fakulti Perubatan dan Sains Kesihatan",
  "Universiti Putra Malaysia Fakulti Perubatan dan Sains Kesihatan",
  "Universiti Malaysia Sarawak Fakulti Perubatan dan Sains Kesihatan",
  "Universiti Sains Islam Malaysia Fakulti Perubatan dan Sains Kesihatan",
  "Universiti Sains Malaysia School of Medical Sciences",
  "Universiti Tunku Abdul Rahman Faculty of Medicine and Health Sciences",
  "University of Cyberjaya Faculty of Medicine",
  "Benemérita Universidad Autónoma de Puebla Facultad de Medicina",
  "Universiti Sultan Zainal Abidin Faculty of Medicine",
  "Centro de Estudios Universitarios Xochicalco, Escuela de Medicina Mexicali",
  "Universiti Teknologi Mara Faculty of Medicine",
  "Centro de Estudios Universitarios Xochicalco, Escuela de Medicina Ensenada",
  "Escuela Médico Naval de la Secretaría de Marina",
  "Centro de Estudios Universitarios Xochicalco, Escuela de Medicina Tijuana",
  "Instituto Politécnico Nacional Escuela Superior de Medicina",
  "Escuela Médico Militar, Universidad del Ejército y Fuerza Aérea",
  "Instituto Politécnico Nacional Escuela Nacional de Medicina y Homeopatía",
  "Instituto de Estudios Superiores de Chiapas Escuela de Medicina Tuxtla Gutierrez",
  "Tecnológico de Monterrey Escuela de Medicina Monterrey",
  "Universidad Anáhuac Mayab Escuela de Medicina",
  "Universidad Anáhuac Cancún Escuela Internacional de Medicina",
  "Universidad Anáhuac Puebla Escuela de Medicina",
  "Universidad Anáhuac Querétaro Escuela de Medicina",
  "Universidad Anáhuac México Escuela de Medicina",
  "Universidad Anáhuac Xalapa Escuela de Medicina",
  "Universidad Autónoma Benito Juárez de Oaxaca Facultad de Medicina y Cirugía",
  "Universidad Autónoma de Aguascalientes Centro de Ciencias de la Salud",
  "Kansai Medical University School of Medicine",
  "Keio University School of Medicine",
  "Universidad Autónoma de Baja California Facultad de Medicina Mexicali",
  "Universidad Autónoma de Baja California Tijuana Facultad de Medicina y Psicología",
  "Universidad Autónoma de Chiapas Facultad de Medicina Humana",
  "Universidad Autónoma de Campeche Facultad de Medicina",
  "Universidad Autónoma de Chihuahua Facultad de Medicina",
  "Universidad Autónoma de Ciudad Juárez Instituto de Ciencias Biomédicas",
  "Universidad Autónoma de Coahuila Escuela de Medicina de Torreón",
  "Universidad Autónoma de Coahuila Facultad de Medicina Saltillo",
  "Universidad Autónoma de Durango Campus Zacatecas Escuela de Medicina",
  "Universidad Autónoma de Nayarit Escuela de Medicina",
  "Universidad Autónoma de San Luis Potosí Facultad de Medicina",
  "Universidad Autónoma de Guadalajara Facultad de Medicina Tabasco",
  "Universidad Autónoma de Yucatán Facultad de Medicina",
  "Universidad Autónoma de Zacatecas Unidad Académica de Medicina y Ciencias de la Salud",
  "Universidad Autónoma del Estado de Hidalgo Instituto de Ciencias de la Salud",
  "Universidad Autónoma de Guadalajara Facultad de Medicina Guadalajara",
  "Universidad Autónoma del Estado de México Facultad de Medicina",
  "Universidad de Guadalajara Centro Universitario de Ciencias de la Salud",
  "Universidad Autónoma de Sinaloa Facultad de Medcina Culiacán",
  "Universidad de Guadalajara Centro Universitario del Sur",
  "Universidad Autónoma de Tamaulipas Facultad de Medicina Humana de Matamoros",
  "Universidad de Colima Facultad de Medicina",
  "Universidad Cristóbal Colón Escuela de Medicina",
  "Universidad Autónoma del Estado de Morelos Facultad de Medicina",
  "Universidad Cuauhtémoc Plantel Aguascalientes Escuela de Medicina",
  "Universidad Autónoma de Tamaulipas Facultad de Medicina de Tampico 'Dr. Alberto Romo Caballero'",
  "Universidad Autónoma de Querétaro Facultad de Medicina",
  "Universidad de la Salle Facultad Mexicana de Medicina",
  "Universidad de las Américas Puebla",
  "Universidad de Sonora Departamento de Medicina y Ciencias de la Salud",
  "Universidad de Monterrey Facultad de Medicina",
  "Universidad del Valle de México Campus Zapopan",
  "Universidad Juárez Autónoma de Tabasco Escuela de Medicina Humana",
  "Universidad del Valle de México Campus Querétaro Facultad de Medicina",
  "Universidad del Noreste Escuela de Medicina 'Dr. José Sierra Flores'",
  "Universidad Juárez del Estado de Durango Facultad de Medicina y Nutrición",
  "Universidad Juárez del Estado de Durango Facultad de Ciencias de la Salud de Gómez Palacio",
  "Universidad Justo Sierra Escuela de Medicina",
  "Universidad Lamar Escuela de Medicina, Campus Vallarta",
  "Universidad Latinoamericana Escuela de Medicina, Campus Cuernavaca",
  "Universidad Nacional Autónoma de México Facultad de Estudios Superiores Zaragoza",
  "Universidad Nacional Autónoma de México Facultad de Estudios Superiores Iztacala",
  "Universidad Quetzalcóatl en Irapuato Escuela de Medicina",
  "Universidad Veracruzana Facultad de Medicina Minatitlán",
  "Universidad Nacional Autónoma de México Facultad de Medicina",
  "Universidad Veracruzana Facultad de Medicina Poza Rica",
  "Universidad Veracruzana Facultad de Medicina Xalapa",
  "Universidad Veracruzana Facultad de Medicina Veracruz",
  "State University of Medicine and Pharmacy Nicolae Testemitanu",
  "Universidad de Guanajuato División de Ciencias de la Salud",
  "Universidad Westhill Facultad de Medicina",
  "Universidad Pablo Guajardo Chávez",
  "Universidad Panamericana Escuela de Medicina",
  "Universidad Marista de Mérida",
  "Universidad Popular Autónoma del Estado de Puebla Facultad de Medicina",
  "Universidad Veracruzana Facultad de Medicina Orizaba-Córdoba",
  "Universidad Regional del Sureste Escuela de Medicina e Cirugía",
  "Universidad de Montemorelos Escuela de Medicina",
  "Universidad Vasco de Quiroga Escuela de Medicina General",
  "Leiden University Medical Center",
  "Erasmus Universiteit Faculteit der Geneeskunde",
  "Maastricht University Faculty of Health, Medicine and Life Sciences",
  "Rijksuniversiteit Groningen Faculteit der Medische Wetenschappen",
  "Radboud Universiteit Nijmegen Faculteit der Medische Wetenschappen",
  "Universiteit Utrecht Faculteit Geneeskunde",
  "Universiteit van Amsterdam Faculteit der Geneeskunde",
  "Otago Medical School",
  "VUmc School of Medical Sciences",
  "University of Auckland Faculty of Medicine and Health Sciences",
  "Abbottabad International Medical College",
  "Abwa Medical College",
  "Al-Nafees Medical College",
  "Ameer-ud-Din Medical College",
  "Al-Tibri Medical College",
  "Amna Inayat Medical College",
  "Aga Khan University Medical College",
  "Al-Aleem Medical College",
  "Allama Iqbal Medical College",
  "Akhtar Saeed Medical & Dental College",
  "Army Medical College",
  "Avicenna Medical College",
  "Aziz Fatimah Medical & Dental College",
  "Ayub Medical College",
  "Bolan Medical College",
  "Bilawal Medical College",
  "Bannu Medical College",
  "Bakhtawar Amin Medical and Dental College",
  "Azra Naheed Medical College",
  "Baqai Medical College",
  "C.M.H. Kharian Medical College",
  "C.M.H. Institute of Medical Sciences, Bahawalpur",
  "Bahria University Medical & Dental College",
  "Bacha Khan Medical College",
  "Azad Jammu & Kashmir Medical College",
  "C.M.H. Lahore Medical College and Institute of Dentistry",
  "Continental Medical College",
  "C.M.H. Multan Institute of Medical Sciences",
  "Chandka Medical College",
  "Central Park Medical College",
  "D.G. Khan Medical College",
  "Dow International Medical College (DIMC)",
  "Dow Medical College",
  "Faisalabad Medical University",
  "Fazaia Ruth Pfau Medical College",
  "F.M.H. College of Medicine and Dentistry",
  "Federal Medical and Dental College",
  "Fatima Jinnah Medical University",
  "Frontier Medical & Dental College, Abbottabad",
  "Foundation University Medical College (FUMC)",
  "Fazaia Medical College",
  "Gajju Khan Medical College",
  "Gambat Medical College of Pir Abdul Qadir Shah Jeelani Institute of Medical Sciences (PAQSJIMS)",
  "Ghulam Mohammad Mahar Medical College",
  "Gomal Medical College",
  "Gujranwala Medical College",
  "Islamabad Medical & Dental College",
  "Kabir Medical College",
  "Karachi Institute of Medical Sciences (KIMS)",
  "Indus Medical College",
  "Isra University, Hyderabad Faculty of Medicine and Allied Medical Sciences",
  "Independent Medical College",
  "Islam Medical College",
  "Karachi Medical and Dental College",
  "Jinnah Medical and Dental College",
  "Jinnah Medical College",
  "Islamic International Medical College",
  "Hamdard College of Medicine & Dentistry",
  "Jinnah Sindh Medical University",
  "H.B.S. Medical & Dental College",
  "Hashmat Medical & Dental College",
  "HITEC Institute of Medical Sciences",
  "Khawaja Muhammad Safdar Medical College",
  "Lahore Medical and Dental College",
  "Khairpur Medical College",
  "Khyber Medical College",
  "Khyber Girls Medical College",
  "KMU Institute of Medical Sciences",
  "Liaquat University of Medical & Health Sciences Jamshoro",
  "Liaquat National Hospital and Medical College",
  "Mohiuddin Islamic Medical College",
  "King Edward Medical University",
  "Liaquat College of Medicine and Dentistry",
  "Mohtarma Benazir Bhutto Shaheed Medical College",
  "Universidad Autónoma de Baja California Escuela de Ciencias de la Salud Ensenada",
  "Universidad Autónoma de Baja California Escuela de Ciencias de la Salud, Unidad Valle de las Palmas",
  "Nawaz Sharif Medical College",
  "Niazi Medical and Dental College",
  "Nishtar Medical University",
  "Nowshera Medical College",
  "Northwest School of Medicine",
  "Poonch Medical College",
  "Pak International Medical College",
  "Quaid-e-Azam Medical College",
  "Peshawar Medical College",
  "People's University of Medical & Health Sciences for Women",
  "Pak Red Crescent Medical & Dental College",
  "Rawal Institute of Health Sciences",
  "Sahara Medical College",
  "Rehman Medical College",
  "Saidu Medical College",
  "Rai Medical College",
  "Quetta Institute of Medical Sciences",
  "Rawalpindi Medical University",
  "Rahbar Medical and Dental College",
  "Sahiwal Medical College",
  "Rashid Latif Medical College",
  "Sargodha Medical College",
  "Shaikh Khalifa Bin Zayed Al-Nahyan Medical and Dental College",
  "Sheikh Zayed Medical College and Hospital",
  "Sharif Medical and Dental College",
  "Services Institute of Medical Sciences",
  "Shaheed Mohtarma Benazir Bhutto Medical College",
  "Shahida Islam Medical College",
  "Shalamar Medical and Dental College",
  "Shifa College of Medicine",
  "Sialkot Medical College",
  "United Medical & Dental College",
  "Sir Syed College of Medical Sciences for Girls",
  "University College of Medicine & Dentistry Lahore",
  "University Medical & Dental College Faisalabad",
  "Wah Medical College",
  "Yusra Medical & Dental College",
  "Women Medical & Dental College",
  "Ziauddin Medical College",
  "Universidad de Panamá Facultad de Medicina",
  "Dr. Jose P. Rizal College of Medicine",
  "Ateneo de Zamboanga University College of Medicine",
  "Cebu Doctors' University College of Medicine",
  "Angeles University Foundation School of Medicine",
  "Far Eastern University Institute of Medicine, Nicanor Reyes Medical Foundation",
  "Cebu Institute of Medicine",
  "Davao Medical School Foundation College of Medicine",
  "St. Louis University School of Medicine",
  "Manila Central University-Filemon D. Tanchoco Foundation College of Medicine",
  "Our Lady of Fatima University College of Medicine",
  "De La Salle Medical and Health Sciences Institute College of Medicine",
  "San Beda University College of Medicine",
  "Silliman University Medical School",
  "St. Luke's Medical Center College of Medicine - William H. Quasha Memorial (SLMCCM-WHQM)",
  "Faculty of Medicine and Dentistry in Zabrze",
  "Jagiellonian University Medical College",
  "University of the Philippines Manila College of Medicine",
  "University of the East/Ramon Magsaysay Memorial Medical Center College of Medicine",
  "Collegium Medicum im. Ludwika Rydygiera, Uniwersytet Mikolaja Kopernika w Toruniu",
  "West Visayas State University College of Medicine",
  "University of the City of Manila College of Medicine",
  "University of Santo Tomas Faculty of Medicine and Surgery",
  "Jan Kochanowski University Collegium Medicum",
  "Krakowska Akademia im. Andrzeja Frycza Modrzewskiego Wydział Lekarski i Nauk o Zdrowiu",
  "Medical University of Bialystok Faculty of Medicine",
  "Medical University of Gdańsk Faculty of Medicine",
  "Medical University of Lublin",
  "Medical University of Lodz Faculty of Medicine",
  "Medical University of Silesia, School of Medicine in Katowice",
  "Opole University Faculty of Medicine",
  "Medical University of Warsaw Faculty of Medicine",
  "Poznan University of Medical Sciences, Faculty of Medicine I",
  "Pomorski Uniwersytet Medyczny w Szczecinie",
  "Poznan University of Medical Sciences Center for Medical Education in English",
  "University of Warmia and Mazury in Olsztyn, Collegium Medicum, School of Medicine",
  "University of Rzeszów Faculty of Medicine",
  "Uczelnia Łazarskiego Wydział Medyczny",
  "Universitatea de Medicină și Farmacie Victor Babeş Facultatea de Medicină",
  "Weill Cornell Medical College in Qatar",
  "University of Zielona Góra Faculty of Medicine and Health Sciences",
  "Bashkir State Medical University",
  "Wroclaw Medical University Faculty of Medcine",
  "Altai State Medical University",
  "Universitatea de Medicină și Farmacie Grigore T. Popa Facultatea de Medicină",
  "Wyższa Szkoła Techniczna w Katowicach Wydział Nauk Medycznych",
  "Universitatea de Medicină, Farmacie, Științe și Tehnologie 'George Emil Palade' Târgu Mureș",
  "Cecenja State University Medical Institute",
  "Ingush State University Faculty of Medicine",
  "Kazan State Medical University",
  "North Ossetian State Medical Academy",
  "Samara State Medical University",
  "Kemerovo State Medical University",
  "Kirov State Medical University",
  "Kursk State Medical University",
  "Siberian State Medical University",
  "Smolensk State Medical University",
  "Volgograd State Medical University",
  "Saba University School of Medicine",
  "Medical University of the Americas (Nevis)",
  "University of Medicine and Health Sciences, St. Kitts",
  "Windsor University School of Medicine",
  "Saint James School of Medicine St. Vincent and the Grenadines",
  "Trinity School of Medicine",
  "Al-Imam Muhammad Bin Saud Islamic University College of Medicine",
  "Oceania University of Medicine",
  "Al Baha University School of Medicine",
  "Al Jouf University College of Medicine",
  "Alfaisal University College of Medicine",
  "Batterjee Medical College for Sciences and Technology",
  "Almajmaah University College of Medicine",
  "Dar Al Uloom University College of Medicine",
  "College of Medicine Jeddah, King Saud Bin Abdulaziz University for Health Sciences",
  "Jazan University Faculty of Medicine",
  "Imam Abdulrahman Bin Faisal University College of Medicine",
  "College of Medicine of Vision Colleges",
  "King Abdulaziz University College of Medicine",
  "Ibn Sina National College for Medical Studies Faculty of Medicine",
  "King Faisal University, College of Medicine in Al-Ahsa",
  "King Khalid University College of Medicine and Health Sciences",
  "King Saud bin Abdulaziz University for Health Sciences College of Medicine",
  "King Saud University, Riyadh College of Medicine",
  "Najran University College of Medicine",
  "Prince Sattam bin Abdulaziz University College of Medicine",
  "Qassim University College of Medicine",
  "Taibah University College of Medicine",
  "Taif University College of Medicine",
  "Umm Al-Qura University Faculty of Medicine",
  "Princess Nourah Bint Abdulrahman University College of Medicine",
  "Sulaiman Alrajhi University College of Medicine",
  "University of Ha’il College of Medicine",
  "University of Tabuk Faculty of Medicine",
  "Duke-NUS Medical School",
  "American University of the Caribbean School of Medicine",
  "Ajou University School of Medicine",
  "Catholic Kwandong University College of Medicine",
  "Catholic University of Daegu School of Medicine",
  "Catholic University of Korea College of Medicine",
  "Cha University School of Medicine",
  "Chung-Ang University College of Medicine",
  "Chosun University College of Medicine",
  "Chonnam National University College of Medicine",
  "Dong-A University College of Medicine",
  "Chungnam National University College of Medicine",
  "Chungbuk National University College of Medicine",
  "Dankook University College of Medicine",
  "Gachon University School of Medicine",
  "Ewha Womans University School of Medicine",
  "Gyeongsang National University College of Medicine",
  "Hallym University College of Medicine",
  "Hanyang University College of Medicine",
  "Eulji University College of Medicine",
  "Dongguk University School of Medicine",
  "Jeonbuk National University College of Medicine",
  "Inha University College of Medicine",
  "Jeju National University School of Medicine",
  "Inje University College of Medicine",
  "Konkuk University College of Medicine",
  "Kangwon National University School of Medicine",
  "Keimyung University College of Medicine",
  "Korea University College of Medicine",
  "Kosin University College of Medicine",
  "Konyang University College of Medicine",
  "Kyung Hee University College of Medicine",
  "Muhammad Medical College",
  "Multan Medical and Dental College",
  "Sungkyunkwan University School of Medicine & Graduate School of Medicine",
  "Seoul National University College of Medicine",
  "Yeungnam University College of Medicine",
  "Universidad de Castilla-La Mancha Facultad de Medicina de Albacete",
  "Universidad Complutense de Madrid Facultad de Medicina",
  "General Sir John Kotelawala Defence University Faculty of Medicine",
  "Soon Chun Hyang University College of Medicine",
  "Wonkwang University College of Medicine",
  "University of Ulsan College of Medicine",
  "Yonsei University College of Medicine",
  "Yonsei University Wonju College of Medicine",
  "Universidad de Navarra Facultad de Medicina",
  "Universidad Francisco de Vitoria Facultad de Ciencias Biosanitarias",
  "Rajarata University of Sri Lanka Faculty of Medicine and Allied Sciences",
  "Faculty of Health-Care Sciences, Eastern University, Sri Lanka",
  "University of Kelaniya Faculty of Medicine",
  "University of Colombo Faculty of Medicine",
  "University of Jaffna Faculty of Medicine",
  "Universität Zürich Medizinische Fakultät",
  "University of Peradeniya Faculty of Medicine",
  "Universität Basel Medizinische Fakultät",
  "University of Khartoum Faculty of Medicine",
  "University of Sri Jayewardenepura Faculty of Medical Sciences",
  "University of Ruhuna Faculty of Medicine",
  "Universität Bern Medizinische Fakultät",
  "University of Gezira Faculty of Medicine",
  "Chang Gung University College of Medicine",
  "Université de Genève Faculté de Médecine",
  "Université de Fribourg Faculté des Sciences et de Médecine",
  "University of Tishreen Faculty of Medicine",
  "Université de Lausanne Faculté de Biologie et de Médecine",
  "University of Damascus Faculty of Medicine",
  "China Medical University College of Medicine",
  "Chung Shan Medical University",
  "Fu Jen Catholic University School of Medicine",
  "Kaohsiung Medical University",
  "National Cheng Kung University College of Medicine",
  "National Defense Medical Center",
  "National Taiwan University College of Medicine",
  "Mackay Medical College",
  "National Yang-Ming University School of Medicine",
  "Taipei Medical University College of Medicine",
  "Avicenna Tajik State Medical University",
  "Tzu Chi University College of Medicine",
  "Burapha University Faculty of Medicine",
  "Chiang Mai University Faculty of Medicine",
  "Chulalongkorn University Faculty of Medicine",
  "Faculty of Medicine Vajira Hospital",
  "Khon Kaen University Faculty of Medicine",
  "HRH Princess Chulabhorn College of Medical Science",
  "Mae Fah Luang University School of Medicine",
  "King Mongkut's Institute of Technology Ladkrabang Faculty of Medicine",
  "Mahasarakham University Faculty of Medicine",
  "Naresuan University Faculty of Medicine",
  "Prince of Songkla University Faculty of Medicine",
  "Phramongkutklao Medical College",
  "Ramathibodi Hospital Faculty of Medicine",
  "Princess of Naradhiwas University Faculty of Medicine",
  "Siriraj Hospital Faculty of Medicine",
  "Siam University Faculty of Medicine",
  "Rangsit University Faculty of Medicine",
  "Srinakharinwirot University Faculty of Medicine",
  "University of the West Indies Faculty of Medicine St. Augustine, Trinidad",
  "Suranaree University of Technology Institute of Medicine",
  "Walailak University School of Medicine",
  "Ubon Ratchathani University College of Medicine and Public Health",
  "Thammasat University Faculty of Medicine",
  "Acibadem Mehmet Ali Aydinlar Üniversitesi Tıp Fakültesi",
  "Akdeniz Üniversitesi Tıp Fakültesi",
  "Ataturk Üniversitesi Tıp Fakültesi",
  "Ankara Üniversitesi Tıp Fakültesi",
  "Bezmialem Vakif Üniversitesi Tıp Fakültesi",
  "Baskent Üniversitesi Tıp Fakültesi",
  "Celal Bayar Üniversitesi Tıp Fakültesi",
  "Canakkale Onsekiz Mart Üniversitesi Tıp Fakültesi",
  "Cukurova Üniversitesi Tıp Fakültesi",
  "Cumhuriyet Üniversitesi Tıp Fakültesi",
  "Dicle Üniversitesi Tıp Fakültesi",
  "Ege Üniversitesi Tıp Fakültesi",
  "Dokuz Eylül Üniversitesi Tıp Fakültesi",
  "Eskişehir Osmangazi Üniversitesi Tıp Fakültesi",
  "Erciyes Üniversitesi Tıp Fakültesi",
  "Hacettepe Üniversitesi Tıp Fakültesi",
  "Firat Üniversitesi Tıp Fakültesi",
  "Gaziantep Üniversitesi Tıp Fakültesi",
  "Gazi Üniversitesi Tıp Fakültesi",
  "İnönü Üniversitesi Tıp Fakültesi",
  "İstanbul Medipol Üniversitesi Tıp Fakültesi",
  "İstanbul Üniversitesi, Cerrahpasa Tıp Fakültesi",
  "İstanbul Üniversitesi, İstanbul Tıp Fakültesi",
  "İzmir Kâtip Çelebi Üniversitesi Tıp Fakültesi",
  "Koç Üniversitesi Tıp Fakültesi",
  "Karadeniz Teknik Üniversitesi Tıp Fakültesi",
  "Marmara Üniversitesi Tıp Fakültesi",
  "Kocaeli Üniversitesi Tip Fakültesi",
  "Mersin Üniversitesi Tıp Fakültesi",
  "Selçuk Üniversitesi Tıp Fakültesi",
  "Süleyman Demirel Üniversitesi Tıp Fakültesi",
  "Ondokuz Mayis Üniversitesi Tıp Fakültesi",
  "Namik Kemal Üniversitesi Tıp Fakültesi",
  "Sağlık Bilimleri Üniversitesi Gülhane Tıp Fakültesi",
  "Pamukkale Üniversitesi Tıp Fakültesi",
  "Trakya Üniversitesi Tıp Fakültesi",
  "Ufuk Üniversitesi Tıp Fakültesi",
  "Uludag Üniversitesi Tıp Fakültesi",
  "Yeditepe Üniversitesi Tıp Fakültesi",
  "Zonguldak Bülent Ecevit University Tıp Fakültesi",
  "Kharkiv National Medical University",
  "Dnipro State Medical University",
  "International European University European Medical School",
  "Odessa National Medical University",
  "Poltava State Medical University",
  "Ajman University College of Medicine",
  "Dubai Medical College for Girls",
  "Sumy State University Medical Institute",
  "Gulf Medical University College of Medicine",
  "Khalifa University College of Medicine and Health Sciences",
  "Brighton and Sussex Medical School",
  "University of Sharjah College of Medicine",
  "Barts and the London School of Medicine and Dentistry",
  "RAK College of Medical Sciences",
  "United Arab Emirates University College of Medicine and Health Sciences",
  "Mohammed Bin Rashid University of Medicine and Health Sciences",
  "Cardiff University School of Medicine",
  "Bristol Medical School, University of Bristol",
  "Edinburgh Medical School, College of Medicine and Veterinary Medicine, University of Edinburgh",
  "Hull York Medical School",
  "Imperial College London Faculty of Medicine",
  "King's College London GKT School of Medicine",
  "Keele University School of Medicine",
  "Lancaster University Medical School",
  "Manchester Medical School",
  "Leicester Medical School, University of Leicester",
  "Newcastle University Faculty of Medical Sciences",
  "Norwich Medical School, University of East Anglia",
  "Queen's University Belfast School of Medicine, Dentistry and Biomedical Sciences",
  "Peninsula Medical School, University of Plymouth",
  "Sheffield University School of Medicine and Biomedical Sciences",
  "St. George's University of London",
  "Swansea University Medical School",
  "UCL Medical School",
  "University of Aberdeen School of Medicine, Medical Sciences and Nutrition",
  "University of Birmingham College of Medical and Dental Sciences",
  "University of Cambridge School of Clinical Medicine",
  "University of Buckingham Medical School",
  "University of Central Lancashire School of Medicine",
  "University of Dundee School of Medicine",
  "University of Exeter College of Medicine and Health",
  "University of Leeds School of Medicine",
  "University of Liverpool School of Medicine",
  "University of Glasgow School of Medicine, Dentistry and Nursing",
  "University of Nottingham School of Medicine",
  "University of Southampton Faculty of Medicine",
  "Warwick Medical School",
  "University of Oxford Medical Sciences Division",
  "Tashkent Pediatric Medical Institute",
  "Kyungpook National University School of Medicine",
  "Pusan National University School of Medicine",
  "Baptist Health Sciences University College of Osteopathic Medicine",
  "Kansas City University College of Osteopathic Medicine - Joplin",
  "Lake Erie College of Osteopathic Medicine - Seton Hill",
  "Montana College of Osteopathic Medicine",
  "Orlando College of Osteopathic Medicine",
  "Touro College of Osteopathic Medicine - Middletown",
  "University of Illinois College of Medicine - Rockford",
  "University of Illinois College of Medicine - Peoria",
  "Université Saint-Joseph faculté de medecine",
  "Universidad Central de Venezuela",
  "Al-Quds University",
  "CaRMS",
  "Dalhousie University",
  "Toronto Metropolitan University",
  "Western University, Canada",
  "Paracelsus Medical University",
  "Caribbean Medical University School of Medicine",
  "University of Geneva",
  "Heinrich Heine University Düsseldorf",
  "Universidade Federal de Sergipe",
  "Medical University of Vienna",
  "Faculté de Santé de Sorbonne Université, Paris, FRANCE",
  "Universidad del Azuay",
  "Università degli Studi di Pavia",
  "University of Novi Sad Faculty of Medicine",
  "National University of Singapore",
  "Universidad Autonoma de Nuevo Leon",
  "University of Texas at Tyler",
  "University of Paris Saclay",
  "Ternopil National Medical University",
  "Universidade Federal do Rio de Janeiro - Brazil",
  "University of Cologne",
  "B.P Koirala Institute of Health Sciences",
  "Faculty of Medicine - Federal University of Bahia",
  "Nnamdi Azikiwe University Teaching Hospital",
  "An-Najah National University Medical School",
  "Kütahya Sağlık Bilimleri Üniversitesi",
  "Universidad del Zulia",
  "Samarkand State Medical University",
  "Facultad de Medicina University of Girona",
  "Instituto Superior de Ciencias Medicas de Santiago de Cuba",
  "Universidade Estadual de Santa Cruz",
  "Universidade de Pernambuco",
  "Sidi Mohamed Ben Abdellah University",
  "American International University West Africa",
  "Universidade Federal de Santa Catarina",
  "Faculdade de Medicina de Itajuba",
  "Universidad de Oriente",
  "Chinese University of Hong Kong",
  "Vita Salute San Raffaele",
  "University of Medicine of Tirana Faculty of Medicine",
  "Universidade Federal do Rio Grande do Sul",
  "Addis Ababa University",
  "University of Lagos",
  "University of Nairobi School of Medicine",
  "Universidad de Favaloro",
  "Faculdade de Medicina de Campos",
  "Gandaki Medical College",
  "Universidade do Estado do Rio de Janeiro",
  "Nepalgunj Medical College",
  "Kathmandu Medical College",
  "The University of the West Indies at Cave Hill, Barbados",
  "Universidad Dr. José Matías Delgado Facultad de Ciencias de la Salud Dr. Luis Edmundo Vásquez, Antiguo Cuscatlan, EL SALVADOR",
  "Nepal Medical College",
  "Fundación Universitaria San Martín Facultad de Medicina Bogotá, Bogotá",
  "Greenheart Medical University",
  "Friedrich Alexander University of Erlangen-Nuremberg",
];

  
export default medicalSchools;