import React, {useState, useContext,useEffect} from "react";
import {useMutation, useQuery} from "@apollo/client";
import {GET_SCORE_BY_APPLICANTID} from "./graphql/queries";
import {CREATE_SCORE, updateScoreMutation} from "./graphql/mutations";
import "../../../common/styles/card.css";
import greenCheckTime from "../../../../assets/imgs/greenCheckTime.svg";
import dangerYellow from "../../../../assets/imgs/dangerYellow.svg";
import { UserContext } from "../../../../context/userContext";
import Spinner from '../../../common/spinner';
import infoIcon from '../../../../assets/imgs/infoIcon.svg';
import { Tooltip } from 'reactstrap';


const ScoresCard = ({inAppForm, updateIsScore, isNested, applicant, refetchApplicants}) => {
  const user = applicant
  
  


  const [dataScore, setDataScore] = useState({});
  const [usmleStep1Score, setUsmlStep1Score] = useState("");
  const [usmleStep1Error, setUsmleStep1Error] = useState(false);
  const [usmleStep2Score, setUsmlStep2Score] = useState("");
  const [usmleStep3Score, setUsmlStep3Score] = useState("");
  const [usmleStep2Error, setUsmleStep2Error] = useState(false);
  const [usmleStep3Error, setUsmleStep3Error] = useState(false);
  const [comlexLevel1Score, setComlexLevel1Score] = useState("");
  const [comlex1Error, setComlex1Error] = useState(false);
  const [comlexLevel2Score, setComlexLevel2Score] = useState("");
  const [comlex2Error, setComlex2Error] = useState(false);
  const [anyFails, setAnyFails] = useState("");
  const [anyFailsError, setAnyFailsError] = useState(false);
  const [testFailsDetails, setTestFailsDetails] = useState("");
  const [scoreRequestEmail, setScoreRequestEmail] = useState("");
  const [isEdit, setIsEdit] = useState(true);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);


  const [createScore, { data: createScoreData }] = useMutation(CREATE_SCORE,{
    onCompleted:()=>{
        refetch();
    }
  }
    );

  const [updateScore, { data: updateScoreData }] = useMutation(updateScoreMutation,{
    onCompleted:()=>{
        refetch();
        alert('Your score request has been updated')
        setIsEdit(true);
    }
  }
    );

  const { loading, error, data, refetch } = useQuery(
    GET_SCORE_BY_APPLICANTID,
    {
      variables: { applicantId: user.id },
    }
  );

  useEffect(() => {
    if (data && data.getScoreByApplicantId) {
        setDataScore(data.getScoreByApplicantId);
        setUsmlStep1Score(data.getScoreByApplicantId.usmleStep1Score);
        setUsmlStep2Score(data.getScoreByApplicantId.usmleStep2Score);
        setUsmlStep3Score(data.getScoreByApplicantId.usmleStep3Score);
        setComlexLevel1Score(data.getScoreByApplicantId.comlexLevel1Score);
        setComlexLevel2Score(data.getScoreByApplicantId.comlexLevel2Score);
        setAnyFails(data.getScoreByApplicantId.previousUsmleComlexFails ? 'Yes' : 'No');
        setTestFailsDetails(data.getScoreByApplicantId.usmleComlexDescription);
        setScoreRequestEmail(data.getScoreByApplicantId.mspeReqEmailAdd);
        if (inAppForm && data.getScoreByApplicantId.stage === "Uploaded") {
          updateIsScore(true);
        }
        else if(inAppForm){
          updateIsScore(false);
        }
    }
  }, [data, createScoreData, updateScoreData]);



const handleCreateScore = async () => {
  
  try {

    const input = {
      applicantId: user.id,
      stage: "Uploaded",
      scoresVerified: false,
      usmleScoresVerified: false, 
      comlexScoresVerified: false,
      usmleStep1Score:usmleStep1Score,
      comlexLevel1Score:comlexLevel1Score,
      usmleStep2Score:parseInt(usmleStep2Score),
      usmleStep3Score:parseInt(usmleStep3Score),
      comlexLevel2Score:parseInt(comlexLevel2Score),
      previousUsmleComlexFails: anyFails==='Yes'?true:false,
      usmleComlexDescription: testFailsDetails,
      mspeReqEmailAdd: scoreRequestEmail,
      requestedAt: new Date(),
    };

    const { createScoreData } = await createScore({ variables: { input } }).then((res) => {
      // refetch();
      refetchApplicants()

    });
    console.log(data);
  } catch (error) {
    console.error(error);
  }
};


const handleScoreEdit = async () => {
  if(!isEdit){
    try {

      const input = {
        applicantId: user.id,
        stage: "Uploaded",
        scoresVerified: false,
        usmleScoresVerified: false, 
        comlexScoresVerified: false,
        usmleStep1Score:usmleStep1Score,
        comlexLevel1Score:comlexLevel1Score,
        usmleStep2Score:parseInt(usmleStep2Score),
        usmleStep3Score:parseInt(usmleStep3Score),
        comlexLevel2Score:parseInt(comlexLevel2Score),
        previousUsmleComlexFails: anyFails==='Yes'?true:false,
        usmleComlexDescription: testFailsDetails,
        mspeReqEmailAdd: scoreRequestEmail,
        requestedAt: new Date(),
      };
  
      const { updateScoreData } = await updateScore({ variables: { id:dataScore.id, input } });
      console.log(data);
      refetchApplicants()
    } catch (error) {
      console.error(error);
    }
  } else {
    setIsEdit(false);
  }
}

const checkForChanges = () => {

  const checkAnyFails = anyFails === 'Yes' ? true : false;
  if (isEdit) {
    return false;
  }
  if (dataScore.usmleStep1Score !== usmleStep1Score || dataScore.usmleStep2Score !== usmleStep2Score || dataScore.comlexLevel1Score !== comlexLevel1Score || dataScore.comlexLevel2Score !== comlexLevel2Score || dataScore.previousUsmleComlexFails !== checkAnyFails || dataScore.usmleComlexDescription !== testFailsDetails || dataScore.mspeReqEmailAdd !== scoreRequestEmail || dataScore.usmleStep1Error !== usmleStep3Score) {
    console.log('there are changes')
    console.log(dataScore.usmleStep1Score, usmleStep1Score)
    console.log(dataScore.usmleStep2Score, usmleStep2Score)
    console.log(dataScore.usmleStep3Score, usmleStep3Score)
    console.log(dataScore.comlexLevel1Score, comlexLevel1Score)
    console.log(dataScore.comlexLevel2Score, comlexLevel2Score)
    console.log(dataScore.previousUsmleComlexFails, checkAnyFails)
    console.log(dataScore.usmleComlexDescription, testFailsDetails)
    console.log(dataScore.mspeReqEmailAdd, scoreRequestEmail)
    
    return false;
  }else{
    console.log('there are no changes')
    return true;
  }
}

if (loading) {
  return <Spinner />;
}


    return (
        <div className='card' style={{width:'100%'}}>
            {loading && <Spinner />}
            {(!data || !data.getScoreByApplicantId)&& !inAppForm&& (
              <>
                        <div className='card-header' style={{marginBottom:'2vh'}}>
                        <h2 className="card-header-title" style={{marginBottom:'2vh', display: 'flex', alignItems: 'center'}}>
  Score Verification Request
  <div id="TooltipExample" onMouseOver={toggleTooltip} onMouseOut={toggleTooltip}>
    <img src={infoIcon} style={{width:'1vw', paddingBottom:'1vh', marginLeft: '.3vw'}}/>
  </div>
</h2>
                      <Tooltip placement="top" isOpen={tooltipOpen} target="TooltipExample" toggle={toggleTooltip}
    style={{borderRadius:'4px', border:'solid #145388 .1vh',fontFamily:'Montserrat',zIndex:'2', backgroundColor:'white', color:'#145388', fontSize:'2vh', width:'20vw', textAlign:'center', padding:'2vh'}}
  >
    Please allow up to 14 business days for your official score reports to be uploaded following request from the Federation of State Medical Boards (FSMB). This will not impact application submission
  </Tooltip>
                        <p style={{textAlign: 'justify'}}>
            Please enter your standardized exam scores. <br /> 
            For Applicants applying to Plastic Surgery: to request a STEP score report from the FSMB, visit   
            <a style={{ fontWeight:'800', color:'#145388'}} href="https://www.fsmb.org" target="_blank" rel="noopener noreferrer"> www.fsmb.org </a> 
            and navigate to the 'Licensing and Exams' section. Then, click on 'Exam Transcript.' When asked to select transcript recipients, choose 'Residency Application Services' followed by 'AccessGME CentralApp.'
          </p>
                        </div>
            <div className="card-body">
            <div className='step1-upper-right-container'>
            
    <div className='step1-upper-right-container'>
        <div className='step1-upper-right-inner-container' style={{marginBottom:'3vh'}}>
        <div className='application-form-field-group'>
        <label htmlFor="comlexLevel1Score" className='applicant-form-field-label'>COMLEX Level 1 Score</label>
        <input 
        disabled={inAppForm}
        type="text" 
        name="comlexLevel1Score" 
        className='application-form-standard-input one'
        style={{margin:'0',width:'17.5vw'}}
        onChange={(e) => {
            const value = e.target.value;
            const isNumber = /^\d+$/.test(value);
            if (
              (isNumber && parseInt(value) >= 9 && parseInt(value) <= 999) ||
                ['pass', 'fail', 'Pass', 'Fail'].includes(value)
            ) {
                setComlexLevel1Score(value);
                setComlex1Error(false);
            } else {
    setComlex1Error(true);
            }
        }}
    />
        <div className='application-form-error-message' style={{margin:'0',width:'17.5vw'}}> 
        {comlex1Error &&
        <p>Please enter a valid score. (Numerical: 9-999,Pass/Fail: Pass, Fail)</p>
        }
      </div>
      </div>

      <div className='application-form-field-group'>
      <label htmlFor="comlexLevel2Score" className='applicant-form-field-label'>COMLEX Step 2 Score</label>
      <input 
        type="text" 
        name="comlexLevel2Score" 
        disabled={inAppForm}
        className='application-form-standard-input one'
        style={{margin:'0',width:'17.5vw'}}
        onChange={(e) => {
            const value = e.target.value;
            const isNumber = /^\d+$/.test(value);
            if (
              isNumber && parseInt(value) >= 9 && parseInt(value) <= 999
            ) {
                setComlexLevel2Score(value);
                setComlex2Error(false);
            } else {
    setComlex2Error(true);
            }
        }}
    />
      <div className='application-form-error-message' style={{margin:'0',width:'17.5vw'}}> 
      {comlex2Error &&
        <p>Please enter a valid score. (Numerical: 9-999)</p>
        }
      </div>
        </div>
        
       
        </div>
        {anyFails === 'Yes' &&
        <div className='application-form-field-group'>
      <label  className='applicant-form-field-label'>Please list the exams and their dates</label>
      <textarea
        name="testFailsDetails"
        disabled={inAppForm}
        className='application-form-standard-input one'
        style={{height:'22vh', margin:'0', width:'32vw'}}
      />
    
        </div>
    }

    </div>

    <div className='step1-upper-right-inner-container'>
    <div className='application-form-field-group' style={{width:'12.5vw'}}>
    <label htmlFor="usmlStep1Score" className='applicant-form-field-label'>USMLE Step 1 Score</label>
       <input 
    type="text" 
    name="usmlStep1Score" 
    className='application-form-standard-input one'
    style={{margin:'0',width:'12.5vw'}}
    disabled={inAppForm}
    onChange={(e) => {
        const value = e.target.value;
        const isNumber = /^\d+$/.test(value);
        if (
          (isNumber && parseInt(value) >= 1 && parseInt(value) <= 300) ||
            ['pass', 'fail', 'Pass', 'Fail'].includes(value)
        ) {
            setUsmlStep1Score(value);
            setUsmleStep1Error(false);
        } else {
        setUsmleStep1Error(true);
        }
    }}
/>

   
    <div className='application-form-error-message' style={{margin:'0',width:'17.5vw'}}> 
    {usmleStep1Error &&
    <p>Please enter a valid score. (Numerical: 1-300,Pass/Fail: Pass, Fail)</p>
    }
  </div>
  </div>

  <div className='application-form-field-group' style={{width:'12.5vw'}}>
  <label htmlFor="usmlStep2Score" className='applicant-form-field-label'>USMLE Step 2 Score</label>
  <input 
  disabled={inAppForm}
    type="text" 
    name="usmlStep2Score" 
    className='application-form-standard-input one'
    style={{margin:'0',width:'12.5vw'}}
    onChange={(e) => {
        const value = e.target.value;
        const isNumber = /^\d+$/.test(value);
        if (
          isNumber && parseInt(value) >= 1 && parseInt(value) <= 300
        ) {
            setUsmlStep2Score(value);
            setUsmleStep2Error(false);
        } else {
setUsmleStep2Error(true);
        }
    }}
/>
  <div className='application-form-error-message' style={{margin:'0',width:'17.5vw'}}> 
  {usmleStep2Error &&
    <p>Please enter a valid score. (Numerical: 1-300)</p>
    }
  </div>
    </div>
    <div className='application-form-field-group' style={{width:'12.5vw'}}>
  <label htmlFor="usmlStep3Score" className='applicant-form-field-label'>USMLE Step 3 Score</label>
  <input 
  disabled={inAppForm}
    type="text" 
    name="usmlStep3Score" 
    className='application-form-standard-input one'
    style={{margin:'0',width:'12.5vw'}}
    onChange={(e) => {
        const value = e.target.value;
        const isNumber = /^\d+$/.test(value);
        if (
          isNumber && parseInt(value) >= 1 && parseInt(value) <= 300
        ) {
            setUsmlStep3Score(value);
            setUsmleStep3Error(false);
        } else {
setUsmleStep3Error(true);
        }
    }}
/>
  <div className='application-form-error-message' style={{margin:'0',width:'17.5vw'}}> 
  {usmleStep2Error &&
    <p>Please enter a valid score. (Numerical: 1-300)</p>
    }
  </div>
    </div>
   
 
    </div>
    <div className='application-form-field-group'>
  <label  className='applicant-form-field-label'>Any previous failed exam attempts?</label>
    <select name="anyFails" 
    disabled={inAppForm}
    className='application-form-standard-input one'
     style={{marginBottom:'0'}} 
     onChange={(e) => {
      const value = e.target.value;
      if (
        value === 'Yes' || value === 'No'
      ) {
          setAnyFails(value);
          setAnyFailsError(false);
      } else {
        setAnyFailsError(true);
      }
  }}
     >
      <option value="">Select</option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
    </select>
    <div className='application-form-error-message' style={{margin:'0',width:'17.5vw'}}> 
  {anyFailsError &&
    <p>Please enter a valid response (Yes or No)</p>
    }
  </div>
    </div>

</div>
<div className='step1-upper-right-container'>
        <div className="card-body">
          {!inAppForm && 
          <button
            className='green-button'
            style={{ width: '100%', marginTop: '3vh', opacity: checkForChanges() ? '.5' : 'initial',cursor: checkForChanges() ? 'not-allowed' : 'pointer'}}
            onClick={() => handleCreateScore()}
            disabled={checkForChanges()}
          >
            {"Send Your Score Verification Request"}
          </button>
          }
        </div>
        </div>

            </div>
            </>
)}

{data && data.getScoreByApplicantId && !inAppForm&&(
  <>
            <div className='card-header' style={{marginBottom:'2vh'}}>
              <div style={{display:'flex',flexDirection:'row', justifyContent:'space-between'}}>
              <h2 className="card-header-title" style={{marginBottom:'2vh', display: 'flex', alignItems: 'center'}}>
  Score Verification Request
  <div id="TooltipExample" onMouseOver={toggleTooltip} onMouseOut={toggleTooltip}>
    <img src={infoIcon} style={{width:'1vw', paddingBottom:'1vh', marginLeft: '.3vw'}}/>
  </div>
</h2>
                      <Tooltip placement="top" isOpen={tooltipOpen} target="TooltipExample" toggle={toggleTooltip}
    style={{borderRadius:'4px', border:'solid #145388 .1vh',fontFamily:'Montserrat',zIndex:'2', backgroundColor:'white', color:'#145388', fontSize:'2vh', width:'20vw', textAlign:'center', padding:'2vh'}}
  >
    Please allow up to 14 business days for your official score reports to be uploaded following request from the Federation of State Medical Boards (FSMB). This will not impact application submission
  </Tooltip>
            {
            dataScore.stage === "Uploaded" && 
            <div className="status-tag-uploaded">
            <img src={greenCheckTime} alt="green check" style={{ width: '2vw' }} />
            <span>Uploaded</span>
            </div>
          }
            {
                dataScore.stage === "Pending" && 
                <div className="status-tag-pending">
                <img src={dangerYellow} alt="yellow danger" style={{ width: '2vw' }}/>
                <span>Pending upload</span>
                </div>
            }
            </div>
            <p style={{textAlign: 'justify'}}>
            Please enter your standardized exam scores. <br /> 
            For Applicants applying to Plastic Surgery: to request a STEP score report from the FSMB, visit   
            <a style={{ fontWeight:'800', color:'#145388'}} href="https://www.fsmb.org" target="_blank" rel="noopener noreferrer"> www.fsmb.org </a> 
            and navigate to the 'Licensing and Exams' section. Then, click on 'Exam Transcript.' When asked to select transcript recipients, choose 'Residency Application Services' followed by 'AccessGME CentralApp.'
          </p>
            </div>
            <div className="card-body">
            <div className='step1-upper-right-container'>
            
          <div className='step1-upper-right-inner-container' style={{marginBottom:'3vh'}}>
          <div className='application-form-field-group'>
          <label htmlFor="comlexLevel1Score" className='applicant-form-field-label'>COMLEX Level 1 Score</label>
          <input 
          type="text" 
          disabled={isEdit&&!inAppForm}
          name="comlexLevel1Score" 
          value={comlexLevel1Score}
          className='application-form-standard-input one'
          style={{margin:'0',width:'17.5vw'}}
          onChange={(e) => {
              const value = e.target.value;
              const isNumber = /^\d+$/.test(value);
              if (
                (value === '' || (isNumber && parseInt(value) >= 9 && parseInt(value) <= 999)) ||
                  ['pass', 'fail', 'Pass', 'Fail'].includes(value)
              ) {
                  setComlexLevel1Score(value);
                  setComlex1Error(false);
              } else {
      setComlex1Error(true);
              }
          }}
      />
          <div className='application-form-error-message' style={{margin:'0',width:'17.5vw'}}> 
          {comlex1Error &&
          <p>Please enter a valid score. (Numerical: 9-999,Pass/Fail: Pass, Fail)</p>
          }
        </div>
        </div>

        <div className='application-form-field-group'>
        <label htmlFor="comlexLevel2Score" className='applicant-form-field-label'>COMLEX Step 2 Score</label>
        <input 
        disabled={isEdit&&!inAppForm}
          type="text" 
          name="comlexLevel2Score" 
          value={comlexLevel2Score}
          className='application-form-standard-input one'
          style={{margin:'0',width:'17.5vw'}}
          onChange={(e) => {
              const value = e.target.value;
              const isNumber = /^\d+$/.test(value);
              if (
                value === '' || (isNumber && parseInt(value) >= 9 && parseInt(value) <= 999)
              ) {
                  setComlexLevel2Score(value);
                  setComlex2Error(false);
              } else {
      setComlex2Error(true);
              }
          }}
      />
        <div className='application-form-error-message' style={{margin:'0',width:'17.5vw'}}> 
        {comlex2Error &&
          <p>Please enter a valid score. (Numerical: 9-999)</p>
          }
        </div>
          </div>
          
        
          </div>
    
 
                <div className='step1-upper-right-inner-container'>
                <div className='application-form-field-group' style={{width:'12.5vw'}}>
                <label htmlFor="usmlStep1Score" className='applicant-form-field-label'>USMLE Step 1 Score</label>
                <input 
                type="text" 
                name="usmlStep1Score" 
                className='application-form-standard-input one'
                disabled={isEdit&&!inAppForm}
                style={{margin:'0',width:'12.5vw'}}
                value={usmleStep1Score}
                onChange={(e) => {
                    const value = e.target.value;
                    const isNumber = /^\d+$/.test(value);
                    if (
                      (value === '' || (isNumber && parseInt(value) >= 1 && parseInt(value) <= 300)) ||
                        ['pass', 'fail', 'Pass', 'Fail'].includes(value)
                    ) {
                        setUsmlStep1Score(value);
                        setUsmleStep1Error(false);
                    } else {
            setUsmleStep1Error(true);
                    }
                }}
            />
    <div className='application-form-error-message' style={{margin:'0',width:'17.5vw'}}> 
    {usmleStep1Error &&
    <p>Please enter a valid score. (Numerical: 1-300,Pass/Fail: Pass, Fail)</p>
    }
  </div>
  </div>

  <div className='application-form-field-group' style={{width:'12.5vw'}}>
  <label htmlFor="usmlStep2Score" className='applicant-form-field-label'>USMLE Step 2 Score</label>
  <input 
    type="text" 
    name="usmlStep2Score" 
    value={usmleStep2Score}
    disabled={isEdit&&!inAppForm}
    className='application-form-standard-input one'
    style={{margin:'0',width:'12.5vw'}}
    onChange={(e) => {
        const value = e.target.value;
        const isNumber = /^\d+$/.test(value);
        if (
          value === '' || (isNumber && parseInt(value) >= 1 && parseInt(value) <= 300)
        ) {
            setUsmlStep2Score(value);
            setUsmleStep2Error(false);
        } else {
setUsmleStep2Error(true);
        }
    }}
/>
  <div className='application-form-error-message' style={{margin:'0',width:'17.5vw'}}> 
  {usmleStep2Error &&
    <p>Please enter a valid score. (Numerical: 1-300)</p>
    }
  </div>
    </div>

    <div className='application-form-field-group' style={{width:'12.5vw'}}>
  <label htmlFor="usmlStep3Score" className='applicant-form-field-label'>USMLE Step 3 Score</label>
  <input 
    type="text" 
    name="usmlStep3Score" 
    value={usmleStep3Score}
    disabled={isEdit&&!inAppForm}
    className='application-form-standard-input one'
    style={{margin:'0',width:'12.5vw'}}
    onChange={(e) => {
        const value = e.target.value;
        const isNumber = /^\d+$/.test(value);
        if (
          value === '' || (isNumber && parseInt(value) >= 1 && parseInt(value) <= 300)
        ) {
            setUsmlStep3Score(value);
            setUsmleStep3Error(false);
        } else {
setUsmleStep3Error(true);
        }
    }}
/>
  <div className='application-form-error-message' style={{margin:'0',width:'17.5vw'}}> 
  {usmleStep3Error &&
    <p>Please enter a valid score. (Numerical: 1-300)</p>
    }
  </div>
    </div>



   
    </div>

    <div className='application-form-field-group'>
  <label  className='applicant-form-field-label'>Any previous failed exam attempts?</label>
    <select name="anyFails" 
    className='application-form-standard-input one'
     style={{marginBottom:'0'}} 
     disabled={isEdit&&!inAppForm}
     value={anyFails}
     onChange={(e) => {
      const value = e.target.value;
      if (
        value === 'Yes' || value === 'No'
      ) {
          setAnyFails(value);
          setAnyFailsError(false);
      } else {
        setAnyFailsError(true);
      }
  }}
     >
      <option value="">Select</option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
    </select>
    <div className='application-form-error-message' style={{margin:'0',width:'17.5vw'}}> 
  {anyFailsError &&
    <p>Please enter a valid response (Yes or No)</p>
    }
  </div>
    </div>

            </div>
<div className='step1-upper-right-container'>

     {anyFails === 'Yes' &&
    <div className='application-form-field-group'>
  <label  className='applicant-form-field-label'>Please list the exams and their dates2</label>
  <textarea
  disabled={isEdit&&!inAppForm}
  value={testFailsDetails}
  onChange={(e) => setTestFailsDetails(e.target.value)}
    name="testFailsDetails"
    className='application-form-standard-input one'
    style={{height:'22vh', margin:'0', width:'32vw'}}
  />
 
    </div>
}
    <div className='step1-upper-right-container'>
        <div className="card-body">
          {!inAppForm && 
          <button
            className='green-button'
            style={{ width: '100%', marginTop: '3vh', opacity: checkForChanges() ? '.5' : 'initial',cursor: checkForChanges() ? 'not-allowed' : 'pointer'}}
            onClick={() => handleScoreEdit()}
            disabled={checkForChanges()}
          >
            {isEdit ? "Unlock to Update Your Scores" : "Send Your Updated Request"}
          </button>
          }
        </div>




    
    </div>
</div>
            
            </div>
            </>
)}

{data && data.getScoreByApplicantId && inAppForm&&(
  <>
            <div className='card-header' style={{marginBottom:'2vh'}}>
              <div style={{display:'flex',flexDirection:'row', justifyContent:'space-between'}}>
              <h2 className="card-header-title" style={{marginBottom:'2vh', display: 'flex', alignItems: 'center'}}>
  Score Verification Request
  <div id="TooltipExample" onMouseOver={toggleTooltip} onMouseOut={toggleTooltip}>
    <img src={infoIcon} style={{width:'1vw', paddingBottom:'1vh', marginLeft: '.3vw'}}/>
  </div>
</h2>
                      <Tooltip placement="top" isOpen={tooltipOpen} target="TooltipExample" toggle={toggleTooltip}
    style={{borderRadius:'4px', border:'solid #145388 .1vh',fontFamily:'Montserrat',zIndex:'2', backgroundColor:'white', color:'#145388', fontSize:'2vh', width:'20vw', textAlign:'center', padding:'2vh'}}
  >
    Please allow up to 14 business days for your official score reports to be uploaded following request from the Federation of State Medical Boards (FSMB). This will not impact application submission
  </Tooltip>
            {
            dataScore.stage === "Uploaded" && 
            <div className="status-tag-uploaded">
            <img src={greenCheckTime} alt="green check" style={{ width: '2vw' }} />
            <span>Uploaded</span>
            </div>
          }
            {
                dataScore.stage === "Pending" && 
                <div className="status-tag-pending">
                <img src={dangerYellow} alt="yellow danger" style={{ width: '2vw' }}/>
                <span>Pending upload</span>
                </div>
            }
            </div>
            <p style={{textAlign:'justify'}}>Please navigate to the Documents screen on the left to enter and verify your scores.</p>
            </div>
            <div className="card-body">
            <div className='step1-upper-right-container'>
           
<div className='step1-upper-right-container'>
    <div className='step1-upper-right-inner-container' style={{marginBottom:'3vh'}}>
    <div className='application-form-field-group'>
    <label htmlFor="comlexLevel1Score" className='applicant-form-field-label'>COMLEX Level 1 Score</label>
    {
        isNested === true ? 
        <label className="application-title" style={{ fontSize: '1.2rem'}}>{comlexLevel1Score}</label>
        :
        <input 
        type="text" 
        disabled={true}
        name="comlexLevel1Score" 
        value={comlexLevel1Score}
        className='application-form-standard-input one'
        style={{margin:'0',width:'17.5vw'}}
        onChange={(e) => {
            const value = e.target.value;
            const isNumber = /^\d+$/.test(value);
            if (
              (isNumber && parseInt(value) >= 9 && parseInt(value) <= 999) ||
                ['pass', 'fail', 'Pass', 'Fail'].includes(value)
            ) {
                setComlexLevel1Score(value);
                setComlex1Error(false);
            } else {
    setComlex1Error(true);
            }
        }}
    />
    }
  
    <div className='application-form-error-message' style={{margin:'0',width:'17.5vw'}}> 
    {comlex1Error &&
    <p>Please enter a valid score. (Numerical: 9-999,Pass/Fail: Pass, Fail)</p>
    }
  </div>
  </div>

  <div className='application-form-field-group'>
  <label htmlFor="comlexLevel2Score" className='applicant-form-field-label'>COMLEX Step 2 Score</label>
  {
       isNested === true ? 
       <label className="application-title" style={{ fontSize: '1.2rem'}}>{comlexLevel2Score}</label>
       :
       <input 
       disabled={true}
         type="text" 
         name="comlexLevel2Score" 
         value={comlexLevel2Score}
         className='application-form-standard-input one'
         style={{margin:'0',width:'17.5vw'}}
         onChange={(e) => {
             const value = e.target.value;
             const isNumber = /^\d+$/.test(value);
             if (
               isNumber && parseInt(value) >= 9 && parseInt(value) <= 999
             ) {
                 setComlexLevel2Score(value);
                 setComlex2Error(false);
             } else {
     setComlex2Error(true);
             }
         }}
     />
  }
 
  <div className='application-form-error-message' style={{margin:'0',width:'17.5vw'}}> 
  {comlex2Error &&
    <p>Please enter a valid score. (Numerical: 9-999)</p>
    }
  </div>
    </div>
    
    </div>
    
</div>

    <div className='step1-upper-right-inner-container'>
    <div className='application-form-field-group' style={{width:'12.5vw'}}>
    <label htmlFor="usmlStep1Score" className='applicant-form-field-label'>USMLE Step 1 Score</label>
    {
      isNested === true ? 
      <label className="application-title" style={{ fontSize: '1.2rem'}}>{usmleStep1Score}</label>
      :
      <input 
        type="text" 
        name="usmlStep1Score" 
        className='application-form-standard-input one'
        disabled={true}
        style={{margin:'0',width:'12.5vw'}}
        value={usmleStep1Score}
        onChange={(e) => {
            const value = e.target.value;
            const isNumber = /^\d+$/.test(value);
            if (
              (isNumber && parseInt(value) >= 1 && parseInt(value) <= 300) ||
                ['pass', 'fail', 'Pass', 'Fail'].includes(value)
            ) {
                setUsmlStep1Score(value);
                setUsmleStep1Error(false);
            } else {
    setUsmleStep1Error(true);
            }
        }}
    />
    }
   
    <div className='application-form-error-message' style={{margin:'0',width:'17.5vw'}}> 
    {usmleStep1Error &&
    <p>Please enter a valid score. (Numerical: 1-300,Pass/Fail: Pass, Fail)</p>
    }
  </div>
  </div>

  <div className='application-form-field-group' style={{width:'12.5vw'}}>
  <label htmlFor="usmlStep2Score" className='applicant-form-field-label'>USMLE Step 2 Score</label>
  {
     isNested === true ? 
     <label className="application-title" style={{ fontSize: '1.2rem'}}>{usmleStep2Score}</label>
     :
     <input 
     type="text" 
     name="usmlStep2Score" 
     value={usmleStep2Score}
     disabled={true}
     className='application-form-standard-input one'
     style={{margin:'0',width:'12.5vw'}}
     onChange={(e) => {
         const value = e.target.value;
         const isNumber = /^\d+$/.test(value);
         if (
           isNumber && parseInt(value) >= 1 && parseInt(value) <= 300
         ) {
             setUsmlStep2Score(value);
             setUsmleStep2Error(false);
         } else {
 setUsmleStep2Error(true);
         }
     }}
 />
  }

  <div className='application-form-error-message' style={{margin:'0',width:'17.5vw'}}> 
  {usmleStep2Error &&
    <p>Please enter a valid score. (Numerical: 1-300)</p>
    }
  </div>
    </div>

    <div className='application-form-field-group' style={{width:'12.5vw'}}>
  <label htmlFor="usmlStep2Score" className='applicant-form-field-label'>USMLE Step 3 Score</label>
  {
     isNested === true ? 
     <label className="application-title" style={{ fontSize: '1.2rem'}}>{usmleStep3Score}</label>
     :
     <input 
     type="text" 
     name="usmlStep3Score" 
     value={usmleStep2Score}
     disabled={true}
     className='application-form-standard-input one'
     style={{margin:'0',width:'12.5vw'}}
     onChange={(e) => {
         const value = e.target.value;
         const isNumber = /^\d+$/.test(value);
         if (
           isNumber && parseInt(value) >= 1 && parseInt(value) <= 300
         ) {
             setUsmlStep3Score(value);
             setUsmleStep3Error(false);
         } else {
 setUsmleStep3Error(true);
         }
     }}
 />
  }
  <div className='application-form-error-message' style={{margin:'0',width:'17.5vw'}}> 
  {usmleStep3Error &&
    <p>Please enter a valid score. (Numerical: 1-300)</p>
    }
  </div>
    </div>




    <div className='application-form-field-group'>
  <label  className='applicant-form-field-label'>Any previous failed exam attempts?</label>
  {
       isNested === true ? 
       <label className="application-title" style={{ fontSize: '1.2rem'}}>{anyFails}</label>
       :
       <select name="anyFails" 
       className='application-form-standard-input one'
        style={{marginBottom:'0'}} 
        disabled={true}
        value={anyFails}
        onChange={(e) => {
         const value = e.target.value;
         if (
           value === 'Yes' || value === 'No'
         ) {
             setAnyFails(value);
             setAnyFailsError(false);
         } else {
           setAnyFailsError(true);
         }
     }}
        >
         <option value="">Select</option>
           <option value="Yes">Yes</option>
           <option value="No">No</option>
       </select>
  }
  
    <div className='application-form-error-message' style={{margin:'0',width:'17.5vw'}}> 
  {anyFailsError &&
    <p>Please enter a valid response (Yes or No)</p>
    }
  </div>
    </div>
    </div>

</div>
{anyFails === 'Yes' &&
    <div className='application-form-field-group'>
  <label  className='applicant-form-field-label'>Please list the exams and their dates</label>
  <textarea
  disabled={true}
  value={testFailsDetails}
    name="testFailsDetails"
    className='application-form-standard-input one'
    style={{height:'22vh', margin:'0', width:'32vw'}}
  />
 
    </div>
}


            </div>
            </>
)}



        </div>
    );
}

export default ScoresCard;