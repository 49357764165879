import React, {useState, useEffect} from 'react';

const colors = [
    '#F8F6EC',
    '#ECF8F7',
    '#F6ECF8',
    '#F8ECEC',
    '#ECF7F8',
]

function assignColor(slots) {
    // check if any of the colors are already used
    // if not, assign a color
    // if yes, assign a color that is not used
    slots.map(slot => {
        if (colors.includes(slot.itemColor)) {
            const index = colors.indexOf(slot.itemColor);
            colors.splice(index, 1);
        }
    }
    )
    return colors[0];
}

const SlotForm = ({ slots, setSlots }) => {
const [tempSlots, setTempSlots] = useState(slots);


useEffect(() => {
    setTempSlots(slots);
}, [slots]);

  const handleInputChange = (index, field, value) => {
    const newSlots = [...tempSlots];
    newSlots[index][field] = value;
    setTempSlots(newSlots);
  };

  const addSlot = () => {
    setTempSlots([...slots, { startTime: '', endTime: '', item: '', itemColor: '' }]);
  };

  const removeSlot = (index) => {
    


    const newSlots = [...slots];
    newSlots.splice(index, 1);
    setSlots(newSlots);
  };

const saveNewSlot = () => {
    const newSlot = tempSlots[tempSlots.length - 1]; // Assuming this is the slot you're trying to save

    const isOverlap = slots.some(slot => {
        return (newSlot.startTime < slot.endTime && newSlot.endTime > slot.startTime);
    });

    if (isOverlap) {
        alert('Time overlap');
        return; // Prevent saving the new slot
    }


    // assign colors to slots that don't have a color
    const colorSlots = [...tempSlots];
    colorSlots.map(slot => {
        if (slot.itemColor === '') {
            slot.itemColor = assignColor(slots);
        }
    }
    )
    setSlots(colorSlots);
    }

  return (
    <div>
        <button className='green-button' style={{marginBottom:'1vh'}} onClick={addSlot}>Add Activity</button>
        {tempSlots.map((slot, index) => {
        const isIdentical = slots.some(s => 
          s.startTime === slot.startTime && 
          s.endTime === slot.endTime && 
          s.item === slot.item && 
          s.itemColor === slot.itemColor
        );

        return (
          <div key={index} style={{ marginBottom: '10px', width: '100%', display: 'flex', flexDirection: "row" }}>
            <input
              type="text"
              placeholder="Title"
              value={slot.item}
              onChange={(e) => handleInputChange(index, 'item', e.target.value)}
              style={{ marginRight: '5px', height: '5vh', marginTop: '2vh' }}
            />
            <div>
              <label>Start Time</label>
              <input
                type="time"
                placeholder="Start Time"
                value={slot.startTime}
                onChange={(e) => handleInputChange(index, 'startTime', e.target.value)}
                style={{ marginRight: '5px' }}
              />
            </div>
            <div>
              <label>End Time</label>
              <input
                type="time"
                placeholder="End Time"
                value={slot.endTime}
                onChange={(e) => handleInputChange(index, 'endTime', e.target.value)}
                style={{ marginRight: '5px' }}
              />
            </div>
            <button 
              disabled={slot.item === '' || slot.startTime === '' || slot.endTime === '' || isIdentical}
              style={{ 
                color: 'white',
                padding: '1vh',
                borderRadius: '4px',
                height: '5vh', 
                alignSelf: 'end', 
                background: slot.item === '' || slot.startTime === '' || slot.endTime === '' || isIdentical ? 'gray' : 'green', 
                cursor: slot.item === '' || slot.startTime === '' || slot.endTime === '' || isIdentical ? 'not-allowed' : 'pointer', 
                marginRight: '.3vw'
              }}
              onClick={() => saveNewSlot(index)}
            >
              Save
            </button>
            <button 
              style={{ color: 'white', padding: '1vh', borderRadius: '4px', height: '5vh', alignSelf: 'end', background: 'red' }} 
              onClick={() => removeSlot(index)}
            >
              Remove
            </button>
          </div>
        );
      })}
      
    </div>
  );
};

export default SlotForm;