import { Form, Formik, Field, ErrorMessage } from 'formik';
import React, { useState, useEffect } from 'react';
import logo from '../../../../assets/imgs/logo.svg';
import * as Yup from "yup";


const validationSchema = Yup.object({
});

const AddInstitutionModal = ({ isOpen, onClose, saveInstitution }) => {
    


    if(!isOpen) return null;
    return (
        <div className="modal-backdrop">
        <div className="add-field-modal">
            <div className='add-field-modal-header'>
                <h2>ADD NEW INSTITUTION</h2>
                <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
            </div>
            <Formik
                    initialValues={{
                        name: '',
                        isInternational: '',
                        isCa: '',
                        isDo: ''
                    }}
                    onSubmit={values => {
                        console.log("values =>", values)
                        saveInstitution(values)
                        onClose()
                    }}
                    validationSchema={validationSchema}
                >
                 {({ handleSubmit, values, setFieldValue }) => (

                    <Form style={{ height: '55vh', overflowY: 'auto'}}>
                    <div className='application-form-field-group' >
                        <label htmlFor="name" className='applicant-form-field-label'>Name</label>
                        <Field name="name" placeholder="" className='application-form-standard-input' style={{width:'100%'}} />
                        <div className='application-form-error-message'> 
                            <ErrorMessage name="name" component="div" />
                        </div>
                        <div className='application-form-field-group' style={{position:'relative', zIndex:'5', marginBottom: '3vh', marginTop: '3vh'}}>
                            <label className='applicant-form-field-label'>International Institution?</label>
                            <div role="group" aria-labelledby="radio-group" className='application-form-group-radio'>
                            <label>
                                <Field className="application-form-radiobox" style={{display:'unset', marginRight:'.4vw', accentColor: "#145388"}} type="radio" name="isInternational" value="yes"/> Yes
                            </label>
                            <label>
                            <Field className="application-form-radiobox" style={{display:'unset', marginRight:'.4vw', accentColor: "#145388"}} type="radio" name="isInternational" value="no"/> No
                            </label>
                            </div>
                        </div>
                        <div className='application-form-field-group' style={{position:'relative', zIndex:'5', marginBottom: '3vh', marginTop: '3vh'}}>
                            <label className='applicant-form-field-label'>Canadian school?</label>
                            <div role="group1" aria-labelledby="radio-group" className='application-form-group-radio'>
                            <label>
                                <Field className="application-form-radiobox" style={{display:'unset', marginRight:'.4vw', accentColor: "#145388"}} type="radio" name="isCa" value="yes"/> Yes
                            </label>
                            <label>
                            <Field className="application-form-radiobox" style={{display:'unset', marginRight:'.4vw', accentColor: "#145388"}} type="radio" name="isCa" value="no"/> No
                            </label>
                            </div>
                        </div>
                        <div className='application-form-field-group' style={{position:'relative', zIndex:'5', marginBottom: '3vh', marginTop: '3vh'}}>
                            <label className='applicant-form-field-label'>Is Osteopathic?</label>
                            <div role="group2" aria-labelledby="radio-group" className='application-form-group-radio'>
                            <label>
                                <Field className="application-form-radiobox" style={{display:'unset', marginRight:'.4vw', accentColor: "#145388"}} type="radio" name="isDo" value="yes"/> Yes
                            </label>
                            <label>
                            <Field className="application-form-radiobox" style={{display:'unset', marginRight:'.4vw', accentColor: "#145388"}} type="radio" name="isDo" value="no"/> No
                            </label>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '2vh', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <button type="submit" className='green-button' style={{
                            border: 'none',
                            outline: 'none',
                            cursor: 'pointer',
                            background: 'linear-gradient(96.75deg, #2a93d5 0%, #596173 133.3%)',
                            borderRadius: '7px',
                            padding: '12px 30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#FFFFFF',
                            fontWeight:'700',
                            width: '20vw'
                        }}
                        disabled={values.name === "" || values.isDo === "" || values.isCa === '' ||
                            values.isInternational === "" }
                        >
                        Add Institution
                        </button>
                        <button className='lor-modal-submit-button' onClick={onClose} style={{
                            outline: 'none',
                            cursor: 'pointer',
                            background: '#FFFFFF',
                            border: '1px solid #145388',
                            borderRadius: '7px',
                            padding: '12px 30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#145388',
                            fontWeight:'700',
                            width: '20vw'

                        }}>Cancel</button>
                        </div>
                 </Form>
                 )}
                 </Formik>
                </div>
            </div>
    )
}

export default AddInstitutionModal;