import React,{useState, useContext, useEffect, useRef} from "react";
import {UserContext} from '../../../../context/userContext';
import './index.css'

const videos = [
    { link: "https://www.youtube.com/watch?v=kTSUeYkwM8A", title: "Creating Folders", videoId: "kTSUeYkwM8A"},
    { link: "https://www.youtube.com/watch?v=o-Nqq39WPlo", title: "Creating Evaluation Forms", videoId: "o-Nqq39WPlo"},
    { link: "https://www.youtube.com/watch?v=R6fn8De5qR0", title: "Sharing Account Access", videoId: "R6fn8De5qR0"},
    { link: "https://www.youtube.com/watch?v=uYWCeO4v934&feature=youtu.be", title: "Managing Review Team", videoId: "uYWCeO4v934"},
    { link: "https://www.youtube.com/watch?v=h0PaHZ2EcHE", title: "Assigning Reviewers", videoId: "h0PaHZ2EcHE"},
    { link: "https://www.youtube.com/watch?v=_RCAL51u2gc", title: "Viewing Applications", videoId: "_RCAL51u2gc" }
]

const Tutorials = () => {
    const { user } = useContext(UserContext);



    return (
        <div>
            <div className='faculty-home-container'>
                <ellipse className='ellipseDesignTop1' />
                <ellipse className='ellipseDesignTop2' />
                <ellipse className='ellipseDesignTop3' />
                <ellipse className='ellipseDesignTop4' />
                <div className="faculty-home-header">
                    <h2 className="faculty-home-header-title">Tutorials</h2>
                </div>
                <div className="video-section">
                    <div className="video-grid">
                        {videos.map((video, index) => (
                        <div className="video-container" key={index}>
                            <iframe
                            src={`https://www.youtube.com/embed/${video.videoId}`}
                            title={video.title}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            ></iframe>
                            <div className="video-caption">{video.title}</div>
                        </div>
                        ))}
                    </div>
    </div>
            </div>
        </div>
    )
}

export default Tutorials;