import React from "react";
import { useTable, useSortBy, useRowSelect } from 'react-table';


const columns = [
    {
        Header: 'Question',
        accessor: 'question',
    },
    {
        Header: 'Answers',
        accessor: 'answer',
    },
];

const QATable = ({ data }) => {

    console.log(data)
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data }, useSortBy, useRowSelect);

    return (
        <table style={{
            borderCollapse: 'collapse',
            width: '100%',
            background: 'white',
            borderRadius: '1vh',
            // boxShadow: '0 0 14px 2px #0000001f',
            marginTop: '2vh',

        }} {...getTableProps()}>
            <thead style={{
                height: '60px',
                background: '#145388',
                borderRadius: '4px',
                color: 'white',
                textAlign: 'left',
                fontWeight: '200',
            }}>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}
                            style={{fontWeight:'500', paddingLeft:'.5vw'}}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => (
                                <td style={{
                                    padding: '16px 10px',
                                    fontSize: cell.column.Header === 'Question' ? '12px' : '16px',
                                    fontStyle: 'normal',
                                    textAlign:'justify',
                                    width: cell.column.Header === 'Question' ? '30%' : '70%',
                                    alignContent: 'start',
                                }} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            ))}
                        </tr> 
                    );
                })}
            </tbody>
        </table>
    );
}

export default QATable;