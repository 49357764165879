import { gql } from '@apollo/client';

export const getUserByTokenQuery = gql`
    query getUserByToken($token: String!) {
        getUserByToken(token: $token) {
            id
            email
            firstName
            lastName
            phone
            isRecommender
            profilePhotoUrl
            institution
            institutionId
            lastLogin
            userLogins
            graduationYearUpdated
            applicationInformation {
                previousLastName
                prefferedName
                pronouns
                usmleId
                nbomeId
                aoaId
                permanentAddress
                licensureRevoked
                licensureRevokedDetails
                militaryObligation
                militaryObligationDetails
                otherServiceObligations
                medicalTrainingInterrupted
                medicalTrainingInterruptedDetails
                sigmaPhi
                aamc
                nrmpId
                gender
                otherRaceEthnicity
                otherWorkAuthorization
                partnerName
                dateOfBirth
                homeTown
                ethnicity
                homeAddress
                workAuthorization
                misdemeanorOrFelony
                misdemeanorOrFelonyDetails
                coupleMatch
                malpractice
                partnerFeild
                medicalSchoolType
                medicalSchoolName
                isLanguages
                fluentLanguages
                higherEducation {
                    institution
                    degree
                    date
                }
                aoaStatus
                goldHumanism
                awardsAndHonors
                coreClerkshipType
                otherCkMethod
                coreClerkshipGrades {
                    clerkshipEm: String
                    clerkshipFm: String
                    im: String
                    neurology: String
                    obgyn: String
                    pediatrics: String
                    psychiatry: String
                    surgery: String

                }

                awayRotations {
                    institution
                    degree
                    dateCompleted
                }
                nonCoreClerkshipGrades {
                    course
                    grade
                }
                firstAuthorPublications
                peerReviewedPublications
                posterPresentations
                podiumPresentations
              
            }
        }
    }
`

export const GetSpecialtiesQuery = gql`
    query getSpecialties {
        getSpecialties {
            id
            name
            description
            importantDates
            applicationRequirements {
                degree
                lors
                mspe
                transcript
                step1
                step2
                cost
            }
            releaseDate
        }
    }
`

export const GetProgramsBySpecialtyIdQuery = gql`
    query getProgramsBySpecialtyId($specialtyId: ID!) {
        getProgramsBySpecialtyId(specialtyId: $specialtyId) {
            id
            name
            url
        }
    }
`

export const getMasterApplicationByUserIdAndSpecialtyIdQuery = gql`
    query getMasterApplicationByUserIdAndSpecialtyId($userId: ID!, $specialtyId: ID!) {
        getMasterApplicationByUserIdAndSpecialtyId(userId: $userId, specialtyId: $specialtyId) {
            id
        }
    }
`

export const getAllMasterApplicationsByUserIdQuery = gql`
    query getAllMasterApplicationsByUserId($userId: ID!) {
        getAllMasterApplicationsByUserId(userId: $userId) {
            id
            userId
            positionId{
            id
            jobTitle
            department
            summary
            programId
            }
            specialtyId
            showSignalMessage
            paymentStatus
            releaseDate
            name
            createdAt
            updatedAt 
            firstPhase
            secondPhase
            thirdPhase
            fourthPhase
            fifthPhase
            sixthPhase
            seventhPhase
            carryingOutResponsibilities
            carryingOutResponsibilitiesElaboration
            previouslyAppliedToSpecialty
            applicationYear
            personalStatement
            researchExperience {
                category
                title
                authors
                journal
                datePublished
                pubMedId
                isFirstAuthor
                significantResearchExperience
                meeting
                meetingDate
                order
            }
            additionalResearchUrl
            meaningfulExperiences {
                category
                title
                startDate
                endDate
                description
            }
            workExperiences {
                employer
                jobTitle
                startDate
                endDate
                mentorName
                mentorEmail
                description
            }
            shortAnswerQuestions {
                question
                answer
            }
            hobbiesAndInterests
            approvedCodeOfEthics
            shortAnswerQuestions {
                question
                answer
            }
            hobbiesAndInterests
        }
    }
`

export const GET_SCORE_BY_APPLICANTID = gql`
query GetScoreByApplicantId($applicantId: ID!) {
    getScoreByApplicantId(applicantId: $applicantId) {
            id
            applicantId
            uploaderId
            stage
            usmleStep1Score
            comlexLevel1Score
            usmleStep2Score
            usmleStep3Score
            comlexLevel2Score
            previousUsmleComlexFails
            usmleComlexDescription
            usmleDocs
            comlexDocs
            scoresVerified
            requestedAt
            submittedAt
            usmleScoresVerified
            comlexScoresVerified
            medicalSchoolEmail
            mspeReqEmailAdd
        }
    }
`

export const getAllLorsByUserIdQuery = gql`
    query getAllLorsByUserId($applicantId: ID!){ 
        getAllLorsByUserId(applicantId: $applicantId) {
            id
            recommenderEmail
            recommenderName
            stage
            
        }
    }
`

export const getRecommenders = gql`
    query getRecommenders{
        getRecommenders{
            id
            email
            firstName
            lastName
            institution
            programId {
                id
                name
            }
        }
    }
`
