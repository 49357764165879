import React, { useState, useEffect } from 'react';
import logo from '../../../../assets/imgs/logo.svg';
import Spinner from '../../../common/spinner';
import packageInfo from '../../../../../package.json';

const VerificationLinkView = ({ isOpen, onClose, user, resendLink }) => {
    const [loadingGenerate, setLoadingGenerate] = useState(false);
    const [loadingEmail, setLoadingEmail] = useState(false);
    const [newToken, setNewToken] = useState(null);

    const verificationLink = `${process.env.REACT_APP_FRONTEND_URL}/create-account/${user.verificationToken}`;


    const generateNewToken = () => {
        setLoadingGenerate(true)
        fetch(`${process.env.REACT_APP_SERVER_URL}/generate_token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'App-Version': packageInfo.version
            },
            body: JSON.stringify({ id: user.id }) 
        })
        .then(response => {
            return response.json()
        })
        .then(data => {
            console.log("new token =>", data)
            setNewToken(data)
            setLoadingGenerate(false)
        })
        .catch((error) => {
            console.error("Error:", error);
            setLoadingGenerate(false)
            alert("Failed to generate token. Please try again later."); // Or use a more sophisticated method to display the error
        });
    }

    const getLinkFromToken = () => {
        return `${process.env.REACT_APP_FRONTEND_URL}/create-account/${newToken}`;
    }

    const resendEmail = () => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/resend_verification_token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'App-Version': packageInfo.version
            },
            body: JSON.stringify({ id: user.id }) 
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network error, please try again');
            }
            return response.json(); // Assuming the server responds with JSON
        })
        .then(data => {
            setLoadingEmail(true);
        })
        .catch((error) => {
            console.error("Error:", error);
            alert("Failed to send email. Please try again later."); // Or use a more sophisticated method to display the error
        });
    }



    if (!isOpen) return null;

    return (
        <div className="modal-backdrop">
            <div className="add-field-modal">
                <button className='close-icon' onClick={onClose}>X</button>
                <div className='add-field-modal-header' style={{ marginTop: '3vh'}}>
                    <h2>Verification Link</h2>
                    <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
                </div>
                <div>
                    <div className='verification-title'>Verification link:</div>
                    {
                        newToken !== null ? 
                        <a className='verification-title link' target="_blank" rel="noopener noreferrer" href={getLinkFromToken()}>{getLinkFromToken()}</a>
                        :
                        <a className='verification-title link' target="_blank" rel="noopener noreferrer" href={verificationLink}>{verificationLink}</a>

                    }
                </div>
                <div className='verification-buttons'>
                    <button className='green-button' 
                        disabled={loadingGenerate} 
                        onClick={generateNewToken}>Generate new verification token</button>
                    <button className='green-button' onClick={resendEmail}>Resend verification email</button>
                </div>
               
                { loadingGenerate && <div><Spinner /></div>}
                {loadingEmail && <div className='success-title'>Verification email sent successfully.</div>}
           
            </div>
        </div>
    )

}

export default VerificationLinkView;