import React,{useState, useContext, useEffect, useRef} from "react";
import "./index.css";
import dropdownArrowIcon from '../../../../assets/imgs/DropdownArrowIcon.svg';
import dropdownGreenArrowIcon from '../../../../assets/imgs/greenArrowDown.svg';
import ApplicationsTable from "./applicationsTable.jsx";
import GroupsDropdown from "./groupsDropdown.jsx";
import { getAllCompletedReviewsForProgramQuery, getGroupsByProgramIdQuery } from './graphql/queries.js';
import client from '../../../../config/apolloClient.js';
import { useQuery, useMutation } from '@apollo/client';
import { ProgramIdContext } from '../../progIdContext.jsx';
import { updateGroupsApplicationIds } from './graphql/mutations.js';
import ResponseModal from '../applicationView/responseView.jsx';
import Spinner from '../../../common/spinner.jsx';


const CompletedReviews = ({selectedGroup, onBack}) => {
    const [completedReviews, setCompletedReviews] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [checkedGroups, setCheckedGroups] = useState([]);
    const [isEditViewModalOpen, setIsEditViewModalOpen] = useState(false);
    const programId = useContext(ProgramIdContext);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [groups, setGroups] = useState([]);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isDownloadDropdownOpen, setIsDownloadDropdownOpen] = useState(false);
    const downDropdownRef = React.useRef(null);
    const [globalFilter, setGlobalFilter] = useState('');


    const getAllCompletedReviewsForProgramQueryData = useQuery(getAllCompletedReviewsForProgramQuery, {
        variables: {
            programId: programId
        }
    });

    const { data: groupsData, error: groupsError, isLoading: groupsLoading, refetch } = useQuery(getGroupsByProgramIdQuery, {
        variables: {
            programId,
            filter: {field: 'name', value: null}
        },
        fetchPolicy:'network-only'
    });
    const [dynamicColumns, setDynamicColumns] = useState([]);

useEffect(() => {
    if (groupsData) {
        setGroups(groupsData.getGroupsByProgramId);
    }
}, [groupsData]);
useEffect(() => {
    console.log('selectedRowIds', selectedRowIds);
}, [selectedRowIds]);


    useEffect(() => {
        if (getAllCompletedReviewsForProgramQueryData.data) {
            const dataToUpdate = getAllCompletedReviewsForProgramQueryData.data.getAllCompletedReviewsForProgram.map(review => {
                const decDisplayCompositeScore = Math.round((review.compositeScore + Number.EPSILON) * 100) / 100;
                // Spread the averageScores directly into the updatedReview object
                const scoresObject = review.averageScores.reduce((acc, score) => {
                    // limit the number of decimal places to 2
                    let decDisplayAvg = Math.round(score.average * 100) / 100;
                    if (isNaN(decDisplayAvg)) {
                        decDisplayAvg = 0;
                    }
                    acc[score.label] = decDisplayAvg
                    return acc;
                }, {});
        


                const updatedReview = {
                    applicantName: review.firstName + ' ' + review.lastName,
                    compositeScoreToShow: decDisplayCompositeScore, 
                    ...review, 
                    ...scoresObject ,
                };
        
                return updatedReview;
            });
            setCompletedReviews(dataToUpdate);
            const columnHeadersSet = new Set();
            const allColumns = getAllCompletedReviewsForProgramQueryData.data.getAllCompletedReviewsForProgram.reduce((acc, review) => {
                const columnsForReview = review.averageScores.map(score => ({
                    Header: score.label,
                    accessor: score.label,
                })).filter(column => {
                    const isDuplicate = columnHeadersSet.has(column.Header);
                    columnHeadersSet.add(column.Header);
                    return !isDuplicate;
                });
                return [...acc, ...columnsForReview];
            }, []);
            setDynamicColumns(allColumns);
            setIsDataLoading(false);
        }
    }, [getAllCompletedReviewsForProgramQueryData.data]);

const constHeaders = [
    {
        Header: 'Applicant Name',
        accessor: 'applicantName',
    },

]

const allTableColumns = [...constHeaders, ...dynamicColumns,    {
    Header: 'Composite Score',
    accessor: 'compositeScoreToShow',
}];

const handleClickOutside = (event) => {
    if (downDropdownRef.current && !downDropdownRef.current.contains(event.target)) {
        console.log("clicked outside!")
      // Close the dropdown
      setIsDownloadDropdownOpen(false);
    }
};

useEffect(() => {
    if (isDownloadDropdownOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
  
      // Clean up the event listener on component unmount
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
}, [isDownloadDropdownOpen])


const toggleRowSelection = (rowId) => {
    let newSelectedRowIds = [...selectedRowIds];
    if (selectedRowIds.includes(rowId)) {
        newSelectedRowIds = newSelectedRowIds.filter(id => id !== rowId);
    } else {
        newSelectedRowIds.push(rowId);
    }
    setSelectedRowIds(newSelectedRowIds);
}

const toggleSelectAll = () => {
    if (selectedRowIds.length === completedReviews.length) {
        console.log('Deselect all');
        setSelectedRowIds([]); // Deselect all
    } else {
        let newSelectedRowIds = completedReviews.map(review => review.applicationId);
        console.log('newSelectedRowIds:', newSelectedRowIds);
        setSelectedRowIds(newSelectedRowIds);
    }
}

const updateSelectedRows = (rows) => {
    console.log('rows:', rows);
    setSelectedRows(rows); // Assuming rows is an array
    setIsResponseModalOpen(true);
}

const handleApplyClick = async (updatedGroups) => {
    // Assuming updatedGroup is a single group object with updated applicationIds
    try {
        await Promise.all(updatedGroups.map(async updatedGroup => {
            const response = await client.mutate({
                mutation: updateGroupsApplicationIds,
                variables: {
                    groupIds: [updatedGroup.id],
                    applicationIds: updatedGroup.applicationIds,
                }
            });
    
            const updatedGroupFromResponse = response.data.updateGroupsApplicationIds;
    
            // Update the groupsState by merging the updated group
            setGroups(prevGroups => prevGroups.map(group =>
                group.id === updatedGroupFromResponse.id ? updatedGroupFromResponse : group
            ));
        }))

     
    } catch (err) {
        console.error("Error updating group:", err);
    }
}
    const toggleGroupsModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const DownloadCSV = () => {
        console.log("columns =>", allTableColumns);
        const csvRows = [];
    
        // Get headers
        const headers = allTableColumns
            .map(column => column.Header)
            .filter(header => typeof header === 'string')
            .map(header => {
                // Enclose the header in double quotes if it contains a comma
                return header.includes(",") ? `"${header}"` : header;
            });
    
        console.log("headers =>", headers);
        csvRows.push(headers.join(','));
    
        console.log("selectedRowIds length =>", selectedRowIds.length);
        var dataToExport = []
        if (selectedRowIds.length === 0) {
            dataToExport = completedReviews
        } else {
            console.log("selectedRows to export =>", selectedRowIds, completedReviews)
            const selectedRows = completedReviews.filter(review => selectedRowIds.includes(review.applicationId.toString()))
            console.log("selectedRows =>", selectedRows)
            dataToExport = selectedRows
        }

        dataToExport.forEach(review => {
            console.log("review to export =>", review);
            const values = headers.map(header => {
                console.log("header =>", header);
                const accessor = allTableColumns.find(column => column.Header === header.replace(/"/g, '')).accessor;
                console.log("accessor =>", accessor);
                if (accessor === 'applicantName') {
                    return `"${review.firstName} ${review.lastName}"`;
                } else {
                    // Escape commas in values
                    const value = review[accessor] === undefined ? "" : review[accessor];
                    return `"${value.toString().replace(/"/g, '""')}"`; // Handle values with quotes
                }
            });
            csvRows.push(values.join(','));
        });
    
        // Create blob and download
        const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'reviews.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };



    return (
        <div className='faculty-home-container' style={{width: '100vw'}}>
        <GroupsDropdown
            isOpen={isModalOpen}
            groups={groups}
            selectedGroups={checkedGroups}
            onApply={handleApplyClick}
            selectedRows={Array.isArray(selectedRowIds) ? [...selectedRowIds] : []} // Convert to array if not already
            finishMutation={() => refetch()}
            setIsOpen={setIsModalOpen}
            from={"completed"}
        />
        {
            isResponseModalOpen && (
                <ResponseModal  responses={selectedRows} onClose={() => setIsResponseModalOpen(false)} />
            )
        }
        <ellipse className='ellipseDesignTop1' />
        <ellipse className='ellipseDesignTop2' />
        <ellipse className='ellipseDesignTop3' />
        <ellipse className='ellipseDesignTop4' />
        <div className="faculty-home-header">
        <h2 className="faculty-home-header-title">COMPLETED REVIEWS</h2>
            <p className="faculty-home-header-description">Welcome to your completed reviews. Once you have assigned applications to reviewers, all completed reviews will be displayed here.</p>
        </div>
        <seperator className="faculty-home-header-seperator"/>
        <div className="faculty-application-top-bar" style={{ gap:'2vw'}}>
        <input
        value={globalFilter || ''}
        onChange={(e) => setGlobalFilter(e.target.value || undefined)}
        placeholder="Search for applicants"
        className='application-form-standard-input one'
        style={{ padding: '0.5rem', height:'100%', marginBottom:'0', width: '33vw', borderRadius: '5px', border: '1px solid #C4C4C4' }}    
      />
            <div className="faculty-application-top-bar-buttons" style={{ display: 'flex', alignItems: 'center', marginLeft:'27vw'}}>
                <button className="green-button" style={{height:'6vh'}} onClick={toggleGroupsModal}>Groups <img src={dropdownArrowIcon} alt="dropdown"/> </button>
            </div>
            <div className="dropdown" style={{border:'0',margin:'0',padding:'0', marginRight: '1.5vw'}}>
                <button className="white-button dropdown-toggle" style={{ height: '6vh', fontSize: '1rem'}} onClick={() => setIsDownloadDropdownOpen(prev => !prev)}>
                    Download <img src={dropdownGreenArrowIcon} alt="dropdown"/>
                </button>

                {isDownloadDropdownOpen && (
                    <div className="dropdown-menu" style={{zIndex:'3', right:'-.5vw'}} ref={downDropdownRef}>
                        <div className="dropdown-item" style={{ cursor: "pointer", fontFamily: 'Montserrat' }} onClick={DownloadCSV}>Download CSV</div>
                    </div>
                )}
               
            </div>
        </div>

      
       
        <div className="faculty-home-body">

        {isDataLoading && <Spinner />}
          {!isDataLoading &&(
            <ApplicationsTable
                columns={allTableColumns}
                data={completedReviews}
                toggleRowSelection={toggleRowSelection}
                toggleSelectAll={toggleSelectAll}
                selectedRowIds={selectedRowIds}
                updateSelectedRows={updateSelectedRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
            />
            )}
          </div>

        </div>
        
    )
}
export default CompletedReviews;

