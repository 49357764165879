import React from 'react';
import importExample from '../../../assets/files/ImportExample.csv';

const ImportFromCSVModal = ({ uploadProgress, isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="csv-import-modal" onClick={e => e.stopPropagation()}>
        <div className="csv-import-modal-header">
            <h3>Import from CSV</h3>
            <button onClick={onClose}>X</button>
        </div>
        
        <div className="bulk-import-instructions" style={{fontFamily:'Montserrat', textAlign:'justify', lineHeight:'3vh'}}>
        <p>To streamline the process of importing large sets of data, we offer a bulk import feature. This allows you to upload multiple entries at once, saving you time and effort.</p>
          <br />
          {uploadProgress===0 && (
            <>
          <h4 style={{fontWeight:'500', textDecoration:'underline'}}>How to Use Bulk Import:</h4>
          <ul>
            <li>1. Download the Example File: To ensure your data is correctly formatted, please <a style={{color:'#145388', fontWeight:'500'}} href={importExample} download>download our example file by clicking here</a>.</li>
            <li>2. Fill in Your Data: Use the example file as a template to organize your data. Ensure each column is filled with the appropriate information.</li>
            <li>3. Upload the File: Once your data is prepared, upload the file through the bulk import interface on our app.</li>
          </ul>
          <br />
          <h4 style={{fontWeight:'500', textDecoration:'underline'}}>Example File Format</h4>
          <p>The example file is provided in CSV format and includes the following columns:</p>
          <ul style={{fontSize:'1.7vh'}}>
            <li>FirstName: The first name of the studnet.</li>
            <li>LastName: The last name of the studnet.</li>
            <li>Email: The email address of the studnet.</li>
            <li>Phone: The phone number of the studnet.</li>
            <li>AAMC_ID: The unique AAMC ID for the student.</li>
            <li>Birth_Date: The birth date of the studnet (format: MM/DD/YYYY).</li>
            <li>USMLE_ID: The unique USMLE ID for the student.</li>
            <li>Degree_Date: Student's graduation date (format: MM/DD/YYYY).</li>
          </ul>
          <br />
          <h4 style={{fontWeight:'500', textDecoration:'underline'}}>Tips for a Successful Import</h4>
          <ul>
            <li>Ensure that all required fields are filled out.</li>
            <li>Double-check the format of dates and numbers to match the expected input.</li>
            <li>Avoid using special characters that might cause issues during the import process.</li>
          </ul>
          <p>If you encounter any issues or have questions, please contact our support team.</p>
          <br />
          </>
            )}
        </div>

        {children}
      </div>
    </div>
  );
};


export default ImportFromCSVModal;