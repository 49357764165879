import React,{useState, useContext, useEffect, useRef} from "react";
import {useQuery, useMutation} from '@apollo/client';
import { getUsersQuery, getUsersCountQuery } from './graphql/queries';
import GeneralTable from './generalTable';
import Pagination from './paginationComponent';
import PageSizeSelector from './pageSizeSelector';
import GreenUploadIcon from '../../../../assets/imgs/greenUploadIcon.svg';
import RedUploadIcon from '../../../../assets/imgs/redUploadIcon.svg';
import DocsModal from "./docsModal";

const AdminDocuments  = ({ setPage }) => {
    const [users, setUsers] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [filter, setFilter] = useState({field: 'userType', value: "applicant"});
    const [sort, setSort] = useState({ field: 'lastName', direction: 'desc'});
    const [totalUsers, setTotalUsers] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [searchInput, setSearchInput] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [otherViewOpen, setOtherViewOpen] = useState(false);
    const [uploadDocsModalOpen, setUploadDocsModalOpen] = useState(false);
    const [updateDocsModalOpen, setUpdateDocsModalOpen] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [loadingData, setLoadingData] = useState(false)


    const columns = [
        {
            accessor: 'firstName',
            id: 'firstName',
            Header: <label style={{width:'4vw'}}>First Name</label>,
            Cell: ({ row }) => (
                <div style={{width:'4vw'}}>{row.original.firstName}</div>
            ), 
        },
        {
            accessor: 'lastName',
            id: 'lastName',
            Header: <label style={{width:'4vw'}}>Last Name</label>,
            Cell: ({ row }) => (
                <div style={{width:'4vw'}}>{row.original.lastName}</div>
            ), 
        },
        {
            accessor: 'email',
            id: 'email',
            Header: <label >Email</label>,
            Cell: ({ row }) => (
                <div >{row.original.email}</div>
            ), 
        },
        {
            accessor: 'userType',
            id: 'userType',
            Header: <label style={{width:'4vw'}}>User Type</label>,
            Cell: ({ row }) => (
                <div style={{width:'4vw', textTransform: 'Capitalize'}}>{row.original.userType}</div>
            ), 
        },
        {
            id: 'docs',
            Header: <label>Documents</label>,
            Cell: ({ row }) => (
                <div className="docs-container">
                    <label className="label-docs" style={row.original.mspe === null  
                    || (row.original.mspe !== undefined && row.original.mspe !== null && row.original.mspe.stage !== "Uploaded") ? { color: 'red'} : { color: '#30B776'}}>
                        <img className="docs-icon" src={row.original.mspe === null  
                    || (row.original.mspe !== undefined && row.original.mspe !== null && row.original.mspe.stage !== "Uploaded") ? RedUploadIcon : GreenUploadIcon} alt="upload-icon" style={{ marginRight: '0.3vw'}}/>
                        MSPE</label>
                    <label className="label-docs" style={row.original.transcript === null  
                    || (row.original.transcript !== undefined && row.original.transcript !== null && row.original.transcript.stage !== "Uploaded") ? { color: 'red'} : { color: '#30B776'}}>
                         <img className="docs-icon" src={row.original.transcript === null  
                    || (row.original.transcript !== undefined && row.original.transcript !== null && row.original.transcript.stage !== "Uploaded") ? RedUploadIcon : GreenUploadIcon} alt="upload-icon" style={{ marginRight: '0.3vw'}}/>
                        Transcript</label>
                    <label className="label-docs" style={row.original.scores === null  
                    || (row.original.scores !== undefined && row.original.scores !== null && row.original.scores.stage !== "Uploaded") ? { color: 'red'} : { color: '#30B776'}}>
                         <img className="docs-icon" src={row.original.scores === null  
                    || (row.original.scores !== undefined && row.original.scores !== null && row.original.scores.stage !== "Uploaded") ? RedUploadIcon : GreenUploadIcon} alt="upload-icon" style={{ marginRight: '0.3vw'}}/>
                        Standarized Scores</label>
                </div>
            )
        },
        {
            id: 'actions',
            Header: <label style={{width:'4vw'}}>Actions</label>,
            Cell: ({ row }) => (
                <div className="dropdown-point">
                <div className='toggle-points' onClick={() => {setOtherViewOpen(false); toggleSelected(row.original);}}>
                    <span className='point'/>
                    <span className='point'/>
                    <span className='point'/>
                </div>
                {otherViewOpen === false && selectedUser && row.original.id.toString() === selectedUser.id.toString() && (
                    <div className="dropdown-menu" style={{display:'flex',zIndex: 999, right: '0', flexDirection:'column', padding:'10%',}}>
                            <a href="#" onClick={uploadUpdateMspe}>{ row.original.mspe ? row.original.mspe.stage === "Need to Upload" ? "Upload MSPE" : "Update MSPE": "Upload MSPE"}</a>
                            <a href="#" onClick={uploadUpdateTranscript}>{ row.original.transcript ? row.original.transcript.stage === "Need to Upload" ? "Upload Transcript" : "Update Transcript": "Upload Transcript"}</a>
                            <a href="#" onClick={uploadUpdateScore}>{row.original.scores ? row.original.scores.stage === "Need to Upload" ? "Upload Score" : "Update Score": "Upload Score"}</a>
                    </div>
                )}
                </div>
            ), 
        },
    ]

    const {loading: loadingUsers, error: errorUsers, data: usersData, refetch: refetchUsers } = useQuery(getUsersQuery, { variables: {
        offset,
        limit,
        filter,
        sort
    }})

    const {loading: loadingUsersCount, error: errorUsersCount, data: usersCount, refetch: refetchUsersCount } = useQuery(getUsersCountQuery, { variables: {
        filter,
    }})

    const sortData = (column, direction) => {
        setSort({ field: column, direction: direction === true ? "asc": "desc"})
        refetchUsers()
    } 


    useEffect(() => {
        setLoadingData(true)
        if(!loadingUsers && !errorUsers && usersData) {
            console.log("usersData =>", usersData.getUsersWithPagination)
            setUsers(usersData.getUsersWithPagination)
            if(selectedUser !== null) {
                let selectedInUsers = usersData.getUsersWithPagination.filter(elem => elem.id.toString() === selectedUser.id.toString())
                if(selectedInUsers.length > 0) {
                    setSelectedUser(selectedInUsers[0])
                } else setSelectedUser(null)
            }
            setLoadingData(false)
        }
    }, [loadingUsers, errorUsers, usersData, selectedUser])

    useEffect(() => {
        if(!loadingUsersCount && !errorUsersCount && usersCount) {
            console.log("usersCount =>", usersCount.getUsersCount)
            setTotalUsers(usersCount.getUsersCount)
            setTotalPages(Math.ceil(usersCount.getUsersCount/ Number(limit)))

        }
    }, [loadingUsersCount, errorUsersCount, usersCount, setTotalPages, setTotalUsers, limit])

    
    const uploadUpdateMspe = () => {
        setOtherViewOpen(prevState => !prevState)
        let combined = {...selectedUser.mspe, name: 'mspe', type: 'mspe'}
        setSelectedDoc(combined)
        if(selectedUser.mspe === null || selectedUser.mspe === undefined) setUploadDocsModalOpen(prevState => !prevState)
        if(selectedUser.mspe && selectedUser.mspe.stage === "Need to Upload") setUploadDocsModalOpen(prevState => !prevState)
        else setUpdateDocsModalOpen(prevState => !prevState)
    }

    const uploadUpdateTranscript = () => {
        setOtherViewOpen(prevState => !prevState)
        let combined = {...selectedUser.transcript, name: 'transcript', type: 'transcript'}
        setSelectedDoc(combined)
        if(selectedUser.transcript === null || selectedUser.transcript === undefined) setUploadDocsModalOpen(prevState => !prevState)
        if(selectedUser.transcript && selectedUser.transcript.stage === "Need to Upload") setUploadDocsModalOpen(prevState => !prevState)
        else setUpdateDocsModalOpen(prevState => !prevState)
    }

    const uploadUpdateScore = () => {
        setOtherViewOpen(prevState => !prevState)
        let combined = {...selectedUser.scores, name: 'score', type: 'score'}
        setSelectedDoc(combined)
        console.log("combined =>", combined)
        if(selectedUser.scores === null || selectedUser.scores === undefined) setUploadDocsModalOpen(prevState => !prevState)
        if(selectedUser.scores && selectedUser.scores.stage === "Need to Upload") setUploadDocsModalOpen(prevState => !prevState)
        else setUpdateDocsModalOpen(prevState => !prevState)
    }


    const toggleSelected = (user) => {
        if(selectedUser === null) {
            setSelectedUser(user)
        } else {
            if(selectedUser.id.toString() === user.id.toString()) {
                // close menu
                setSelectedUser(null)
            } else {
                // other user clicked
                setSelectedUser(null)
                setSelectedUser(user)
            }
        }
    }

    const handlePageSizeChange = (event) => {
        setLimit(Number(event.target.value));
        setOffset(0)
        setCurrentPage(1); // Reset to first page when page size changes
        setTotalPages(Math.ceil(totalUsers/ Number(event.target.value)))
        setLoadingData(true)
        refetchUsers()
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setOffset((page - 1) * limit )
        setLoadingData(true)
        refetchUsers()
    };

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchInput(value)
        var searchObj = { ...filter}
        searchObj.value = value
        setLoadingData(true)
        setFilter(searchObj)
        // debouncedSearch(value); // Update the search query after debounce
    }


    return (
        <div className='faculty-home-container'>
            <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop2' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="faculty-home-header">
                <h2 className="faculty-home-header-title">DOCUMENTS</h2>
            </div>
            <seperator className="faculty-home-header-seperator"/>
            <div className="right-header-container" style={{ marginBottom: '2vh'}}>
                <div className="input-container">
                    <i className="fas fa-search"></i>
                    <input
                        className="applicant-top-bar-search-input"
                        placeholder="Search"
                        value={searchInput}
                        onChange={handleSearchChange}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                refetchUsers()
                            }
                        }}
                    />
                </div>
            </div>
            <GeneralTable columns={columns} data={users} sortData={sortData} limit={limit} totalPages={totalPages} currentPage={currentPage} handlePageSizeChange={handlePageSizeChange} handlePageChange={handlePageChange} loadingData={loadingData}/> 
            {/* <div className="faculty-application-table-footer" style={{ height: '4vh'}}>
                <PageSizeSelector itemsPerPage={limit} onPageSizeChange={handlePageSizeChange} />
                <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
            </div> */}

            {
                updateDocsModalOpen && 
                <DocsModal setShouldRefetch={() => {}} isOpen={updateDocsModalOpen} onClose={() => setUpdateDocsModalOpen(prevState => !prevState)} isUpdate={true} doc={selectedDoc} applicant={selectedUser} refetchUsers={refetchUsers} />
            }
            {
                uploadDocsModalOpen && 
                <DocsModal setShouldRefetch={() => {}} isOpen={updateDocsModalOpen} onClose={() => setUpdateDocsModalOpen(prevState => !prevState)} isUpdate={false} doc={selectedDoc} applicant={selectedUser} refetchUsers={refetchUsers}/>
            }
       
        </div>
    )
}

export default AdminDocuments;