import React,{ useState, useEffect, useContext} from "react";
import GeneralTable from './generalTable';
import './applicationView.css';

const Lors = ({ applications, lors }) => {
    const columns = [
        {
            id: 'information',
            Header: <label>Recommender</label>,
            Cell: ({ row }) => (
                <div>{row.original.recommenderName}</div>
            )
        },
        {
            id: 'specialty',
            Header: <label>Specialty</label>,
            Cell: ({ row }) => {
                var selectedApplication = applications.filter(elem => row.original.applicationId.includes(elem.id.toString()))
                if(selectedApplication.length > 0){
                    selectedApplication = selectedApplication[0]
                }
                console.log("selected application =>", selectedApplication)

                return (
                <div>{selectedApplication !== null  && selectedApplication !== undefined && selectedApplication.specialtyId !== null && selectedApplication.specialtyId !== undefined? selectedApplication.specialtyId.name : ""}</div>
            )}
        },
        {
            id: 'status',
            Header: <label>Status</label>,
            Cell: ({ row }) => (
                <div>{row.original.stage}</div>
            )
        },
    ]


    return (
        <div className="personal-container">
         <GeneralTable columns={columns} data={lors} onSelectionChange={() => {}} onSelectedApplication={() => {}} sortData={() => {}} />
        </div>
    )
}

export default Lors;