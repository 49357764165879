import React, { useState, useEffect } from 'react';
import logo from '../../../../assets/imgs/logo.svg';
import Spinner from '../../../common/spinner';
import {updateApplicantAccessMutation} from './graphql/mutations';
import {useMutation} from '@apollo/client';

const ManualVerificationModal = ({ isOpen, onClose, user, refetchUsers}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const [updateApplicantAccess] = useMutation(updateApplicantAccessMutation);
  


    const handleSave = () => {
        
            setLoading(true);
            updateApplicantAccess({
                variables: {
                    id: user.id,
                }
            }).then(() => {
                setLoading(false);
                refetchUsers();
                onClose();
            }).catch((error) => {
                setLoading(false);
                setError(error.message);
            });


    }




    if (!isOpen) return null;

    return (
        <div className="modal-backdrop">
            <div className="add-field-modal">
                <button className='close-icon' onClick={onClose}>X</button>
                <div className='add-field-modal-header' style={{ marginTop: '3vh'}}>
                    <h2>Manual Verification</h2>
                    <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
                </div>
                <div>
                    <h2 className='verification-title' style={{marginTop:'2vh', fontSize:'3vh'}}>Are you sure you want to verify this applicant? Verifying them will grant access to the system.</h2>
                </div>
                <div className='verification-buttons' style={{width:'50%',marginLeft:'25%'}}>
                    <button className='green-button' 
                        onClick={handleSave}>Verify Applicant</button>
                    <button className='green-button' style={{background:'white', border:'1px solid #145388', color:'#145388'}} onClick={()=>onClose()}>Cancel</button>
                </div>
               {error && <div className='error-title'>{error}</div>}
               {loading && <Spinner/>}
           
            </div>
        </div>
    )

}

export default ManualVerificationModal;