import React, { useState, useEffect,useRef, useContext } from 'react';
import { ProgramIdContext } from '../../progIdContext';
import {
  Row,
  Card,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Col
} from 'reactstrap';
import { Colxx, Separator } from './common/CustomBootstrap';
import { useQuill } from 'react-quilljs'; 
import 'quill/dist/quill.snow.css'; 
import {useMutation, useQuery, useApolloClient} from '@apollo/client';
import {
  specificEmailTemplateByProgramQuery,
//   programUpdatedSubs,
//   attachmentAddedSubscription,
//   attachmentRemovedSubscription
} from './graphql/queries';
import {
  editEmailTemplateInProgramMutation,
  addEmailAttachmentMutation,
  removeEmailAttachmentMutation,
  updateProgramMutation
} from './graphql/mutations';
import Dropzone from 'react-dropzone';
import { FileIcon } from 'react-file-icon';
import './styles/emailTemplates.css';
// import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import CustomeEmailSender from '../settings/settings-sub-components/customeEmailSender/index';
import moment from 'moment';
// import { endpoint, serverPort, httpProtocol } from "../../../App";
// import AllowEmails from '../settings/settings-sub-components/allowEmails';
import packageInfo from '../../../../../package.json';
import logo from '../../../../assets/imgs/logo.svg';

const EmailTemplate = ({ selectedTemplate, showFullTemplate, setTemplate, manualEmail, title }) => {
  
  const [templateFeatures, setTemplateFeatures] = useState(null);
  const [startSubject, setStartSubject] = useState('');
  const [startBody, setStartBody] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [navigationTarget, setNavigationTarget] = useState('');
  const [originalTemplate, setOriginalTemplate] = useState(null);
  const [finishUpdate, setFinishUpdate] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [previewBody, setPreviewBody] = useState("");
  const [previewSubject, setPreviewSubject] = useState("");
  const [isChangedDetected, setIsChangedDetected] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAttachmentsOpen, setIsAttachmentsOpen] = useState(false);


  console.log(selectedTemplate, "selectedTemplate")

  const toolbarOptions = [
    [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
    ['bold', 'italic', 'underline'], // toggled buttons
  
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }], // outdent/indent
    [{ 'direction': 'rtl' }], // text direction
  

    [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
  
    ['clean'], // remove formatting button
    ['image', 'video', 'link'], // add media
    [{ 'smartFields': ['@applicant_first_name', '@applicant_last_name', '@applicant_email',
    '@the_interview_date',
    "@program_director's_name",
    "@coordinator's_name",
    "@coordinator's_email",
    '@program_specialty',
    '@hospital_name',
    '@waiting_time',
    '@open_date',
    '@the_previous_interview_date'] }], // Custom dropdown for smart fields
    ['custom-attach'], // Custom button for attachments,
    // [{ 'mentions': []}]
  ];
  

  const {quill:quillBody, quillRef: quillBodyRef} = useQuill({
    modules: {
      toolbar: {
        container: toolbarOptions,
        handlers: {
          'smartFields': value => {
            if (value) {
              insertSmartField(value);
            }
          },
          'link': function(value) {
            if (value) {
              var href = prompt('Enter the URL');
              this.quill.format('link', href);
            } else {
              this.quill.format('link', false);
            }
          },
          'mentions': function () {
                // Create dropdown options
                const dropdown = document.createElement('select');
                const options = [
                  '@applicant_first_name',
                  '@applicant_last_name',
                  '@applicant_email'
                ];

                options.forEach(option => {
                  const optionElement = document.createElement('option');
                  optionElement.value = option;
                  optionElement.innerText = option;
                  dropdown.appendChild(optionElement);
                });

                // Insert dropdown into toolbar
                document.querySelector('.ql-mentions').appendChild(dropdown);

                dropdown.addEventListener('change', () => {
                  const cursorPosition = quillBody.getSelection().index;
                  quillBody.insertText(cursorPosition, dropdown.value, 'mention', true); // Add the mention as text
                  dropdown.value = ''; // Reset dropdown after selection
                });
          },
          theme: 'snow', // Set the theme
        },
      },
    },
  });


  const { quill: quillSubject, quillRef: quillSubjectRef } = useQuill({
    modules: {
      toolbar: false // This disables the toolbar
    }
  });
  // const { quill: quillSubject, quillRef: quillSubjectRef } = useQuill()

  const insertSmartField = (placeholder) => {
    const range = quillBody.getSelection(true);
    quillBody.insertText(range.index, placeholder);
    quillBody.setSelection(range.index + placeholder.length);
  };



  const programId = useContext(ProgramIdContext);

  useEffect(() => {
    setOriginalTemplate(selectedTemplate);
    setTemplateFeatures(getEmailStatus(selectedTemplate.name));
    console.log(getEmailStatus(selectedTemplate.name), "getEmailStatus(template.name)")
    setStartSubject(selectedTemplate.emailSubject);
    setStartBody(selectedTemplate.emailBody);
    setIsLoading(false);
    setAttachments(selectedTemplate.attachments);
  }, [selectedTemplate]);

  function customizeQuill() {
    var toolbar = quillBody.getModule('toolbar');
    toolbar.addHandler('placeholder', value => {
      if (value) {
        insertSmartField(value);
      }
    });
  }


  function handleFileUpload(e) {
    const file = e.target.files[0];
    if (file) {
      // Implement your file upload logic here
      // For example, upload to a server and get a URL back
      handleUpload(file).then(url => {
        // Insert an icon or link into the Quill editor
        const range = quillBody.getSelection();
        const linkText = file.name; // Text to display - could be the file name or something else
        quillBody.insertText(range.index, linkText, { link: url });
      });
    }
  }



  useEffect(() => {
    if (quillSubject) {
      // Initialize Quill for subject with existing content
      quillSubject.setText(selectedTemplate.emailSubject || '');

      // Update state when Quill content changes
      quillSubject.on('text-change', () => {
        setEmailSubject(quillSubject.getText());
      });
    }

    if (quillBody) {
      // Initialize Quill for body with existing content
      customizeQuill();
      var toolbar = quillBody.getModule('toolbar');
      toolbar.addHandler('custom-attach', () => {
        document.getElementById('file-upload').click();
      });
      quillBody.clipboard.dangerouslyPasteHTML(selectedTemplate.emailBody || '');
      quillBody.on('text-change', () => {
        setEmailBody(quillBody.root.innerHTML);
      });
    }
  }, [quillSubject, quillBody, selectedTemplate.emailSubject, selectedTemplate.emailBody]);
  

  useEffect(() => {
    if (quillBody) {
      // Find the toolbar and append the dropdown manually
      const toolbar = quillBody.getModule('toolbar');
      const toolbarContainer = toolbar.container;
      
      // Create a dropdown menu and add options
      const customDropdown = document.createElement('select');
      console.log("customDropdown =>", customDropdown)

      customDropdown.classList.add('ql-mentions'); // Add class for Quill toolbar integration
      customDropdown.style.display = 'inline-block'; // Ensure inline display
      customDropdown.style.width = '7vw';
      customDropdown.style.marginLeft = '-3vw';

      // Add options to dropdown
      const options = ['Add mention', 
      '@applicant_first_name', 
      '@applicant_last_name', 
      '@applicant_email',
      '@the_interview_date',
      "@program_director's_name",
      "@coordinator's_name",
      "@coordinator's_email",
      '@program_specialty',
      '@hospital_name',
      '@waiting_time',
      '@open_date',
      '@the_previous_interview_date'
    ];
      options.forEach((option) => {
        const optionElement = document.createElement('option');
        optionElement.value = option;
        optionElement.innerText = option;
        customDropdown.appendChild(optionElement);
      });

      // Insert the dropdown into the toolbar
      toolbarContainer.appendChild(customDropdown);

      // Handle selection of an option
      customDropdown.addEventListener('change', (event) => {
        const value = event.target.value;
        const cursorPosition = quillBody.getSelection().index;
        quillBody.insertText(cursorPosition, value); // Insert the selected value at cursor position
        quillBody.setSelection(cursorPosition + value.length); // Set the cursor after the inserted value
        customDropdown.selectedIndex = 0; // Reset the dropdown to default after selection
      });
    }
  }, [quillBody]);

  useEffect(() => {
    console.log(emailBody, "emailBody")
  }, [emailBody])


  const [editEmailTemplate] = useMutation(editEmailTemplateInProgramMutation, {
    onCompleted: (data) => {
      const emailTemplate = data.editEmailTemplateInProgram;
      setOriginalTemplate({ ...originalTemplate, emailBody: emailTemplate.emailBody, emailSubject: emailTemplate.emailSubject });
      setShowModal(true);
      setIsChangedDetected(false);
      if (manualEmail) {
        setEmailSubject(emailTemplate.emailSubject);
        setEmailBody(emailTemplate.emailBody);
        setAttachments(emailTemplate.attachments);
      }
    }
  });

  const [addEmailAttachment] = useMutation(addEmailAttachmentMutation);
  const [removeEmailAttachment] = useMutation(removeEmailAttachmentMutation);

//   useSubscription(programUpdatedSubs, {
//     variables: {},
//     onSubscriptionData: ({ subscriptionData }) => {
//       const newProgram = subscriptionData.data.programUpdated;
//       if (newProgram && newProgram._id.toString() === program._id.toString()) {
//         setOriginalTemplate(newProgram);
//       }
//     }
//   });

//   useSubscription(attachmentAddedSubscription, {
//     variables: {},
//     onSubscriptionData: ({ subscriptionData }) => {
//       const newTemplate = subscriptionData.data.attachmentAdded;
//       if (newTemplate && newTemplate.programId === program) {
//         setOriginalTemplate(newTemplate);
//       }
//     }
//   });

//   useSubscription(attachmentRemovedSubscription, {
//     variables: {},
//     onSubscriptionData: ({ subscriptionData }) => {
//       const newTemplate = subscriptionData.data.attachmentRemoved;
//       if (newTemplate && newTemplate.programId === program) {
//         setOriginalTemplate(newTemplate);
//       }
//     }
//   });


const handleRemoveAttachment = (fileName) => {
  console.log('hit remove attachment')
  console.log(fileName, "fileName")
  console.log(selectedTemplate._id, "selectedTemplate._id")

  removeEmailAttachment({
    variables: {
      _id: selectedTemplate._id,
      fileName: fileName
    }
  }).then((data) => {
    setTemplate(data.data.removeEmailAttachment);
  }).catch((error) => {
    console.error("Error removing attachment:", error);
  });

}

  const getEmailStatus = (templateName) => {
    const statuses = {
      'Invite': {
        isManual: false,
        whenSent: 'Use this template to invite a candidate for an interview.'
      },
      'Interview Details': {
        isManual: false,
        whenSent: 'Use this template to send an applicant details for the interview.'
      },
      'Invite': {
        isManual: false,
        whenSent: 'Use this template to invite a candidate for an interview.'
      },
      'Interview Details': {
        isManual: false,
        whenSent: 'Use this template to send an applicant details for the interview.'
      },
      'Cancel Invitation': {
        isManual: false,
        whenSent: 'Use this template to cancel the applicant’s invitation to interview.'
      },
      'Reminder - Week Before': {
        isManual: false,
        whenSent: 'Use this template to remind an applicant of the date and details of an interview.'
      },
      'New Date Available': {
        isManual: false,
        whenSent: 'Use this template to notify an applicant of an open interview slot.'
      },
      'No Dates Available': {
        isManual: false,
        whenSent: 'It is sent when you would like to update the candidate on the wait-list status.'
      },
      'Meeting Set': {
        isManual: false,
        whenSent: ''
      },
      'Meeting Canceled': {
        isManual: false,
        whenSent: 'Use this template to notify an applicant that an interview has been canceled.'
      },
      'Meeting Rescheduled': {
        isManual: false,
        whenSent: 'Use this template to notify an applicant that an interview has been rescheduled.'
      },
      'Thank You': {
        isManual: false,
        whenSent: 'Use this template to thank an applicant for interviewing with your program.'
      },
      'Pre-Interview': {
        isManual: true,
        whenSent: ''
      },
      'Custom Manual 1': {
        isManual: true,
        whenSent: 'Use this template to draft a custom email and save it for reuse.'
      },
      'Custom Manual 2':  {
        isManual: true,
        whenSent: 'Use this template to draft a custom email and save it for reuse.'
      },
      'Custom Manual 3': {
        isManual: true,
        whenSent: 'Use this template to draft a custom email and save it for reuse.'
      }
    };

    return statuses[templateName] || {};
  };

  const handleSubjectTextChanged = (value) => {
    const updatedTemplate = { ...originalTemplate, emailSubject: value };
    setOriginalTemplate(updatedTemplate);

    if (startSubject !== value) {
      // toggle navigation
    }
    if (manualEmail) {
      setEmailSubject(updatedTemplate.emailSubject);
    }
  };

  const handleBodyTextChanged = (value) => {
    const updatedTemplate = { ...originalTemplate, emailBody: value };
    setOriginalTemplate(updatedTemplate);

    if (startBody !== value) {
      // toggle navigation
    }
    if (manualEmail) {
      setEmailBody(updatedTemplate.emailBody);
    }
  };

  const handleUpload = async (fileObject) => {
    try {
      const formData = new FormData(); 
      const timestamp = Date.now();
      const fileType = fileObject.name.split('.').pop();
      const fileName = `${fileObject.name.split('.')[0]}`;
  
      formData.append('file', fileObject);
      formData.append('emailId', selectedTemplate._id);
      formData.append('fileName', fileName);
      formData.append('fileType', fileType);
  
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/upload_email_attachment`, {
        method: 'POST',
        headers: {
          'App-Version': packageInfo.version,
          // Remove 'Content-Type': 'multipart/form-data' if you have it, as it's automatically set by the browser when you pass FormData
        },
        body: formData // Pass formData directly here
      });

      if (response.ok) {
        const data = await response.json(); // Assuming the server responds with JSON containing the updated attachments list
        setAttachments(data); // Update the attachments state with the new list
      } else {
        console.error("Failed to upload file", await response.text());
      }
    } catch (error) {
      console.error("Error uploading file", error);
    }
  };

  const saveChanges = () => {
    editEmailTemplate({
      variables: {
        _id: originalTemplate._id,
        emailSubject: emailSubject,
        emailBody: emailBody
      }
    });
  };

  const discardChanges = () => {
    setFinishUpdate(true);
    setIsChangedDetected(false);
    window.location.href = navigationTarget;
  };

  const toggleSettings = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };

  const showPreview = async () => {
    console.log("inside showPreview")
    const applicant = {
      firstName: 'Dan',
      lastName: 'Avery',
      previousDate: '07/12/2022',
      scheduleDate: null,
      // ...other applicant details
    };

    let subject = originalTemplate.emailSubject;
    let body = originalTemplate.emailBody;

    // Replace placeholders with actual values
    subject = subject.replace(/@applicant_first_name/g, applicant.firstName);
    subject = subject.replace(/@applicant_last_name/g, applicant.lastName);
    subject = subject.replace(/@the_previous_interview_date/g, applicant.previousDate);
    subject = subject.replace(/@the_interview_date/g, applicant.scheduleDate);
    subject = subject.replace(/@applicant_email/g, applicant.email);
    subject = subject.replace(/@program_director's_name/g, "Test Director");
    subject = subject.replace(/@coordinator's_name/g, "Test Coordinator");
    subject = subject.replace(/@coordinator's_email/g, "test_coordinator@gmail.com");
    subject = subject.replace(/@hospital_name/g, "Demo hospital");
    subject = subject.replace(/@waiting_time/g, "20");
    subject = subject.replace(/@program_specialty/g, "Neurology" );
    subject = subject.replace(/@open_date/g, "September 26th 2024");

    setPreviewSubject(subject);

    body = body.replace(/@applicant_first_name/g, applicant.firstName);
    body = body.replace(/@applicant_last_name/g, applicant.lastName);
    body = body.replace(/@the_previous_interview_date/g, applicant.previousDate);
    body = body.replace(/@the_interview_date/g, applicant.scheduleDate);
    body = body.replace(/@applicant_email/g, applicant.email);
    body = body.replace(/@program_director's_name/g, "Test Director");
    body = body.replace(/@coordinator's_name/g, "Test Coordinator");
    body = body.replace(/@coordinator's_email/g, "test_coordinator@gmail.com");
    body = body.replace(/@hospital_name/g, "Demo hospital");
    body = body.replace(/@waiting_time/g, "20");
    body = body.replace(/@program_specialty/g, "Neurology" );
    body = body.replace(/@open_date/g, "September 26th 2024");

    const remoteUrl = `${process.env.REACT_APP_SERVER_URL}/get_template`;
    const result = await fetch(remoteUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        program: programId,
        emailBody: body
      }),
    });
  

    const combined = await result.json();
    setPreviewBody(combined);
    setShowEmail(!showEmail);
  };

  if (isLoading) return <div>Loading...</div>;

  return (
   
    <div className="container-101" style={{width:'67vw', alignSelf:'flex-start'}}>
      {showModal && (
        <div className='modal-backdrop'>
            <div className="modal">
            <div className="modal-header">
            The changes have been saved!
          <span className="close-icon-modal" onClick={() => setShowModal(false)}>x</span>
        </div>
        <div className="modal-body">
        The changes you have made have been successfully updated.
        <button style={{marginRight:'27vw',marginTop:'3vh'}} className="green-button" onClick={() => setShowModal(false)}>Done</button>
        </div>
      </div>
    </div>
)}
      <Row>
        <div className='email-auto-manual'>
          {templateFeatures && showFullTemplate && (
            <Label className='autoManual' style={{color:'#145388', marginBottom:'0'}}>
              This is {templateFeatures.isManual ? 'a Manual' : 'an Automatic'} Email Template - {templateFeatures.whenSent}
            </Label>
          )}
        </div>
      </Row>
      {showFullTemplate && (
        <Row style={{height:'5vh'}}>
          <Colxx xxs='12'>
            <div style={{ display: 'flex', width: '95%', justifyContent: 'space-between', marginBottom: '1%', flexDirection: 'row' }}>
              <div className='email-title-header' style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <i className='iconsminds-mail-settings' style={{ fontSize: '2rem' }} />
                <h4 style={{ fontSize: "1.4rem", marginTop: "2%" }}>{title}</h4>
              </div>
              <div className="left-email-templater-header" style={{ display: "flex", width:'50vw' }}>
                {/* <Button className="emailButtons123" style={{ fontSize:'2vh',height:'2vh',backgroundColor: 'transparent', borderColor: 'transparent', color: '#195286' }} onClick={toggleSettings}>
                  <img className="saveIcon" style={{ height: '5vh', marginTop: '-5%' }} src={require('../../../../assets/imgs/senderIdIcon.svg').default} />
                  Sender address
                </Button> */}

<Button className="emailButtons2" style={{ textAlign:'-webkit-center',fontSize:'2vh',height:'2vh',backgroundColor: 'transparent', borderColor: 'transparent', color: '#145388', fontWeight:'500', width:'17vw' }} onClick={() => setIsAttachmentsOpen(!isAttachmentsOpen)}>
            {isAttachmentsOpen?'Hide Attachments':'Show Attachments'}
          </Button>

               
                <Button className="emailButtons1" style={{textAlign:'-webkit-center',fontSize:'2vh',height:'2vh', backgroundColor: 'transparent', borderColor: 'transparent', color: '#145388', fontWeight:'500', width:'7vw' }} onClick={showPreview}>
                  Preview
                </Button>
               
                <Button className="emailButtons2" style={{ textAlign:'-webkit-center',fontSize:'2vh',height:'2vh',backgroundColor: 'transparent', borderColor: 'transparent', color: '#145388', fontWeight:'500', width:'7vw' }} onClick={saveChanges}>
                  Save
                </Button> 
                {/* {isSettingsOpen && programId !== null && (
                  <div className="open-email-sender">
                    <CustomeEmailSender programId={programId} closeWindow={toggleSettings} />
                  </div>
                )} */}
              </div>
            </div>
          </Colxx>
        </Row>
      )}
    
    

       <div className='attachments-container' style={{display:'flex',zIndex: '5', position:'relative', border:'solid 1px #00808012',background: 'white', justifyContent:'center', overflowX:'scroll'}}>
    {isAttachmentsOpen&&attachments&& attachments.length > 0 && (
        attachments.map((file, i) => {
          return (
            <div className='attachment-item' key={i} onClick={() => window.open(file, '_blank')}>
              <FileIcon  labelColor='#00808099'  extension={file.split('.').pop()} labelUppercase />
              {file.split('/central-app-email-attachments/').pop().split('-')[1]}
              <i className='simple-icon-close remove-icon' style={{color:'red'}} onClick={e => { e.stopPropagation(); handleRemoveAttachment(file); }}>Remove</i>
            </div>
          );
        })
      )
      }
       {isAttachmentsOpen&&attachments&& attachments.length === 0 && (
        <div  style={{color:'#145388', fontSize:'1.5rem', fontWeight:'500', display:'flex', justifyContent:'center', alignItems:'center'}}>
          No Attachments
        </div>
      )}
    </div>
      <Row>
        <Colxx xxs='12'>
          {originalTemplate && (
            <Card className='subject-card' style={{width:'100%', border:'unset', padding:'unset'}}>
              <div className='subjectContainer'>
                <div className='subjectEditor'>
                <div>
      <div ref={quillSubjectRef} />
    </div>
                </div>
              </div>
            </Card>
          )}
        </Colxx>
      </Row>
      <Row>
        <Colxx xxs='12' style={{ height: '70vh' }}>
        <Card className='subject-card' style={{width:'100%', border:'unset', padding:'unset'}}>
            {originalTemplate && (
              <Dropzone onDrop={e => handleUpload(e[e.length - 1])} noClick={true}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <section className='email-container'>
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      <div className='body-container'>
                        {isDragActive && (
                          <div className='dropzoneContainer' style={{ backgroundColor: '#145388', color:'white', fontFamily:'Montserrat', fontWeight:'600', alignItems:'center',padding:'30%' }}>
                            <div className='dropzoneDescription' style={{ fontSize: '1.5rem', marginBottom: '1%' }}>
                              Drop Files Here
                            </div>
                          </div>
                        )}
                        <div>
                        <div ref={quillBodyRef} style={{height:'65vh'}} />
                        <input type="file" id="file-upload" style={{ display: 'none' }} onChange={handleFileUpload} />
                        </div>
                      
  
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
            <Modal isOpen={isChangedDetected} style={{ top: '27vh' }}>
              <ModalHeader>Changes Detected</ModalHeader>
              <ModalBody>
                <Label>Would you like to save changes before leaving the page?</Label>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" outline onClick={discardChanges}>
                  <p>DISCARD CHANGES</p>
                </Button>
                <Button color="primary" type="submit" onClick={saveChanges}>
                <p>ACCEPT CHANGES</p>
                </Button>
              </ModalFooter>
            </Modal>
          </Card>
        </Colxx>
      </Row>

      {
        showEmail &&
        <div className="modal-backdrop">
          <div className="add-field-modal" style={{ height: '80vh', overflow: 'scroll'}}>
            <div className='add-field-modal-header'>
              <h2>E-mail Preview</h2>
            <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
            </div>
              <Label className='subject-prev'>{previewSubject}</Label>
              <div dangerouslySetInnerHTML={{ __html: previewBody }} />
            <div className='button-container' style={{display:'flex', flexDirection:'row', justifyContent:'flex-end', gap:'2vw', marginTop: '2vh'}}>
              <button className='green-button' onClick={() => setShowEmail(prevState => !prevState)}>Close</button>
            </div>
          </div>
        </div>
      }               
    </div>
  );
};

export default EmailTemplate;
