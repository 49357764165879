import React from 'react';
import GuidanceTime from '../../../../../assets/imgs/guidance_time.svg';
import xIconWithCircleGray from '../../../../../assets/imgs/xIconWithCircleGray.svg';
import checkIconWithCircleGreen from '../../../../../assets/imgs/checkIconWithCircleGreen.svg';
import xIconWithCircleRed from '../../../../../assets/imgs/xIconWithCircleRed.svg';
import moment from 'moment';


const StatusIndicator = ({ status, interviewDate }) => {
const pendingStyle ={
    backgroundColor: '#FFF8EA',
    color: '#A5774D',
    border: '1px solid #A5774D'
}
const notInvitedStyle ={
    backgroundColor: '#E9E9E9',
    color: '#655f5f',
    border: '1px solid #655f5f'
}

const scheduledStyle ={
    backgroundColor: '#E8F6EA',
    color: '#49AA53',
    border: '1px solid #49AA53'
}

const declinedStyle ={
    backgroundColor: '#F5E9E9',
    color: '#E93131',
    border: '1px solid #E93131'
}

const canceledStyle ={
    backgroundColor: '#F5E9E9',
    color: '#E93131',
    border: '1px solid #E93131'
}


return (
    <>
    {status === 'Response Pending' &&(
    <div className='response-item' style={pendingStyle}>
    <img src={GuidanceTime} alt="guidanceIcon" style={{marginRight: '0.4vw'}}/>
    <div className='response-title'>Response Pending</div>
</div>
)}
    {status === 'Not Invited' &&(
    <div className='response-item' style={notInvitedStyle}>
    <img src={xIconWithCircleGray} alt="guidanceIcon" style={{marginRight: '0.4vw'}}/>
    <div className='response-title'>Not Invited</div>
</div>
)}
    {status === 'Scheduled' &&(
    <div className='response-item' style={scheduledStyle}>
    <img src={checkIconWithCircleGreen} alt="guidanceIcon" style={{marginRight: '0.4vw'}}/>
    {!interviewDate&&(<div className='response-title'>Scheduled</div>)}
    {interviewDate&&<div className='response-date'>{moment(interviewDate).format('MM/DD/YYYY')}</div>}
</div>
)}
    {status === 'Declined' &&(
    <div className='response-item' style={declinedStyle}>
    <img src={xIconWithCircleRed} alt="guidanceIcon" style={{marginRight: '0.4vw'}}/>
    <div className='response-title'>Declined</div>
</div>
)}
    {status === 'Canceled' &&(
    <div className='response-item' style={declinedStyle}>
    <img src={xIconWithCircleRed} alt="guidanceIcon" style={{marginRight: '0.4vw'}}/>
    <div className='response-title'>Canceled</div>
</div>
)}


</>
)
}

export default StatusIndicator;