import { gql } from "@apollo/client";


export const GET_WORKFLOWS_BY_PROGRAM = gql`
query GetWorkflowsByProgram($programId: ID!) {
  getWorkflowsByProgram(programId: $programId) {
    id
    name
    description
  }
}
`;