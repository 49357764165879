import {gql} from '@apollo/client';

export const CREATE_REVIEWS = gql`
mutation CreateReviews($input: CreateReviewsInput!) {
    createReviews(input: $input) {
      Reviews {
        reviewerId
        applicationId
        programId
        stage
      }
    }
  }
`;


export const DELETE_REVIEW = gql`
  mutation DeleteReview($input: DeleteReviewsInput!) {
    deleteReview(input: $input) {
      Reviews {
        applicationId
      }
    }
  }
`;

export const deleteAllReviewsForReviewer = gql`
  mutation DeleteAllReviewsForReviewer($reviewerId: ID!) {
    deleteAllReviewsForReviewer(reviewerId: $reviewerId) {
      Reviews {
        reviewerId
      }
    }
  }
`;