import React, { useState, useEffect, useContext } from 'react';
import logo from '../../../../../assets/imgs/logo.svg'
import { useMutation } from '@apollo/client';
import {UPDATE_RUBRIC_TEMPLATE} from '../graphql/mutations';

const EditFieldModal = ({ isOpen, onClose, onEditRubric, lastRubricOrder, existingRubric, rubricsNames, program }) => {

    const programId = program.id;
    const [updateRubricTemplate] = useMutation(UPDATE_RUBRIC_TEMPLATE);
    const [label, setLabel] = useState('');
    const [inputType, setInputType] = useState('text');
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [maxScale, setMaxScale] = useState(5); // Default value set to 5
    const [lowestScaleText, setLowestScaleText] = useState('');
    const [highestScaleText, setHighestScaleText] = useState('');
    const [showError, setShowError] = useState(false)

    useEffect(() => {
        setLabel(existingRubric ? existingRubric.label : '');
        setInputType(existingRubric ? existingRubric.inputType : 'text');
        setDropdownOptions(existingRubric ? existingRubric.dropdownOptions : []);
        setMaxScale(existingRubric ? existingRubric.maxScale : 5);
        setLowestScaleText(existingRubric ? existingRubric.lowestScaleText : '');
        setHighestScaleText(existingRubric ? existingRubric.highestScaleText : '');
    }, [existingRubric]);

    if (!isOpen) return null;

    const handleAddOption = (option) => {
        setDropdownOptions([...dropdownOptions, option]);
    };

    const handleRemoveOption = (index) => {
        setDropdownOptions(prevOptions => prevOptions.filter((_, i) => i !== index));
    };

    const handleUpdateRubric = () => {
        // const otherRubricsNames = rubricsNames.filter(elem => elem === label)
        // if(rubricsNames.includes(label)) {
        //     setShowError(true)
        //     return 
        // } else  setShowError(false)


        const newRubric = {
            id: existingRubric.id,
            label: label,
            inputType: inputType,
            dropdownOptions: inputType === 'dropdown' ? dropdownOptions : [],
            maxScale: inputType === 'scale' ? maxScale : undefined,
            lowestScaleText: inputType === 'scale' ? lowestScaleText : undefined,
            highestScaleText: inputType === 'scale' ? highestScaleText : undefined,
            order: existingRubric.order,
        };
        onEditRubric(newRubric);
        console.log("existingRubric =>", existingRubric)
        updateRubricTemplate({ variables: { programId:programId, rubricTemplateId:existingRubric.id, input:newRubric } });
        setLabel('');
        setInputType('text');
        setDropdownOptions([]);
        setMaxScale(5);
        setLowestScaleText('');
        setHighestScaleText('');
        onClose(); // Close the modal
    };

    return (
        <div className="modal-backdrop" style={{zIndex: 999}}>
            <div className="add-field-modal">
                <div className='add-field-modal-header'>
                <h2>EDIT FIELD</h2>
                <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
                </div>
                <form>
                    <div className="form-group">
                        <label style={{
                            fontFamily: 'Montserrat',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '16px',
                            color: '#848484',
                            lineHeight: '24px',
                            marginBottom: '19px',
                            }}>Label</label>
                        <input
                            type="text"
                            className="application-form-standard-input"
                            value={label}
                            onChange={(e) => setLabel(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                    <label style={{
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '16px',
                        color: '#848484',
                        lineHeight: '24px',
                        marginBottom: '19px',
                        }}>Input Type</label>
                        <select
                            value={inputType}
                            onChange={(e) => setInputType(e.target.value)}
                            className="application-form-standard-input"
                        >
                            <option value="text">Text</option>
                            <option value="dropdown">Dropdown</option>
                            <option value="scale">Scale</option>
                        </select>
                    </div>
                    {inputType === 'dropdown' && (
                        <div className="form-group">
                            <label>Dropdown Options</label>
                            <input
                                type="text"
                                className="application-form-standard-input"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleAddOption(e.target.value);
                                        e.target.value = ''; // Clear input after adding
                                    }
                                }}
                                placeholder="Type option and press Enter"
                            />
                            <ul >
                                {dropdownOptions.map((option, index) => (
                                    <li style={{border:'.2vh solid #145388', color:'#145388', fontSize:'3vh', padding:'1vh', borderRadius:'5px', marginTop:'1vh', marginBottom:'1vh'}} key={index} >
                                        {option}
                                        <button onClick={() => handleRemoveOption(index)} style={{marginLeft: '10px', color:'red', fontWeight:'bold', fontSize:'2vh', float:'right'}}>X</button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

{inputType === 'scale' && (
                        <div className="form-group">
                            <label>Number of Items in Scale (Max: 10)</label>
                            <input
                                type="number"
                                className="application-form-standard-input"
                                value={maxScale}
                                onChange={(e) => setMaxScale(Math.min(parseInt(e.target.value), 10))}
                            />
                            <label>Text for Left Side of Scale</label>
                            <input
                                type="text"
                                className="application-form-standard-input"
                                value={lowestScaleText}
                                onChange={(e) => setLowestScaleText(e.target.value)}
                            />
                            <label>Text for Right Side of Scale</label>
                            <input
                                type="text"
                                className="application-form-standard-input"
                                value={highestScaleText}
                                onChange={(e) => setHighestScaleText(e.target.value)}
                            />
                        </div>
                    )}
                    <div className='button-container' style={{display:'flex', flexDirection:'row', justifyContent:'space-between', gap:'2vw'}}>
                    <button type="button" className='gradient-button' 
                    onClick={handleUpdateRubric}
                    disabled={label === '' || (inputType === "dropdown" && dropdownOptions.length === 0) || (inputType === "scale" && (lowestScaleText === '' || highestScaleText === '' || maxScale === '' || lowestScaleText === null || highestScaleText === null || maxScale === null))}
                    >Save</button>
                    <button type="button" className='white-button' onClick={onClose}>Cancel</button>
                    </div>
                   { showError && <label style={{ color: 'red'}}>Label name already exists. Please change it.</label>}
                </form>
            </div>
        </div>
    );
};

export default EditFieldModal;