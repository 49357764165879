import {gql} from '@apollo/client';


export const getAllInstitutionsQuery = gql`
    query getAllInstitutions {
        getAllInstitutions {
            name
            isInternational
            isCa
        }
    }
`


export const getUserByVerificationToken = gql`
    query getUserByVerificationToken($token: String!) {
        getUserByVerificationToken(token: $token) {
            id
            userType
            programId
            institutionId
            institution
            isRecommender
            firstName
            lastName
            profilePhotoUrl
            email
            phone
        }
    }
`

export const getUserByToken = gql`
    query getUserByToken($token: String!) {
        getUserByToken(token: $token) {
            userId
            userType
            token
            programId
            institutionId
            institution
            userPremissions
            isRecommender
            firstName
            lastName
            email
            lastLogin
            userLogins
            graduationYearUpdated
            savedEditView
        }
    }
`