import { gql } from '@apollo/client';

export const updateUserStatusMutation = gql`
  mutation updateUserStatus($applicantId: ID, $status: String) {
    updateUserStatus(applicantId: $applicantId, status: $status) {
      id
    }
  }

`


export const CREATE_SCORE = gql`
  mutation CreateScore($input: ScoreInput!) {
    createScore(input: $input) {
      id
      applicantId
      uploaderId
      stage
      usmleStep1Score
      comlexLevel1Score
      usmleStep2Score
      usmleStep3Score
      comlexLevel2Score
      previousUsmleComlexFails
      usmleComlexDescription
      usmleDocs
      comlexDocs
      scoresVerified
      requestedAt
      submittedAt
      usmleScoresVerified
      comlexScoresVerified
      medicalSchoolEmail
      mspeReqEmailAdd
    }
  }
`;

export const updateScoreMutation = gql`
  mutation updateScore($id: ID!, $input: ScoreInput!) {
    updateScore(id: $id, input: $input) {
      id
    }
  }
`

export const updateApplicantAccessMutation = gql`
  mutation updateApplicantAccess($id: ID!) {
    updateApplicantAccess(id: $id) {
      id
    }
  }
`