import {gql} from '@apollo/client';

export const GET_PROGRAM_REVIEWERS_BY_PROGRAM_ID = gql`
query getProgramReviewersByProgramId($programId: ID!) {
    getProgramReviewersByProgramId(programId: $programId) {
      userId {
        id
        firstName
        lastName
        email
        isPending
      }
      roles
      pendingReviews
      completedReviews
    }
  }
`;



export const getReviewersApplicationsByProgramCount = gql`
query getReviewersApplicationsByProgramCount($reviewerId: ID!, $programId: ID!) {
  getReviewersApplicationsByProgramCount(reviewerId: $reviewerId, programId: $programId)
}
`

export const GET_REVIEWERS_APPLICATIONS_BY_PROGRAM = gql`
query getReviewersApplicationsByProgram($reviewerId: ID!, $programId: ID!, $offset: Int, $limit: Int, $sort: SortInput) {
    getReviewersApplicationsByProgram(reviewerId: $reviewerId, programId: $programId, offset: $offset, limit: $limit, sort: $sort) {
    id
    userId
    firstName
    lastName
    status
    }
  }
`;

export const GET_ALL_APPLICATIONS_FOR_ASSINGMENT = gql`
  query getAllApplicationsForAssingment($programId: ID!) {
    getAllApplicationsForAssingment(programId: $programId) {
      id
      userId
      firstName
      lastName
      reviewDetails
    }
  }
`;
