import React,{useState} from "react";

const ReviewerItem = ({reviewer, selectedReviewer, onReviewerClick}) => {
    const totalApplications = reviewer.pendingReviews + reviewer.completedReviews;
    const pendingApplications = reviewer.pendingReviews;
    const completedApplications = reviewer.completedReviews;

    return (
        <div className={selectedReviewer._id===reviewer._id?'reviewer-item-card-active':'reviewer-item-card'} onClick={() => onReviewerClick(reviewer)}>
            <div className='reviewer-name-title'>
                {reviewer.firstName} {reviewer.lastName}
            </div>
            <seperator className='reviewer-item-seperator'/>
            <div className='reviewer-item-applications-status-cont'>
                <div className='reviewer-item-applications-title' style={{ display:'flex' }}>
                    Assigned <div style={{color:'#323232', marginLeft:'.3vw'}}>{totalApplications} </div>
                </div>
                <div style={{ border: 'solid', borderWidth: '0.5px' }}/>
                <div className='reviewer-item-applications-title' style={{ display:'flex' }}>
                    Pending <div style={{color:'#A5774D', marginLeft:'.3vw'}}>{pendingApplications} </div>
                </div>
                <div style={{ border: 'solid', borderWidth: '0.5px' }}/>
                <div className='reviewer-item-applications-title' style={{ display:'flex' }}>
                    Completed <div style={{color:'#49AA53', marginLeft:'.3vw'}}>{completedApplications} </div>
                </div>
            </div>


        </div>
    );
}

export default ReviewerItem;