import React, { useState, useEffect } from 'react';
import logo from '../../../../assets/imgs/logo.svg';
import PhoneInput from 'react-phone-number-input'
import '../../../../../node_modules/react-phone-number-input/style.css'
import './index.css';
// import medicalSchools from '../../../common/listOfInstitutions'; 
import Select from 'react-select';
import {getAllProgramsQuery, getProgramByMemberIdQuery, getAllInstitutionsQuery} from './graphql/queries';
import { updateUserMutation, updateProgramUserMutation } from './graphql/mutations';
import { useQuery, useMutation } from '@apollo/client';

const EditUserModal = ({ isOpen, onClose, user, onEditUser }) => {
    const [userType, setUserType] = useState(user.userType);
    const [roles, setRoles] = useState(user.roles);
    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [email, setEmail] = useState(user.email);
    const [phone, setPhone] = useState(user.phone ? user.phone.startsWith("+") ? user.phone : "+"+user.phone : "");
    const [isReviewerAutoSelected, setIsReviewerAutoSelected] = useState(false);
    const [institutionName, setInstitutionName] = useState(user.institution);
    const [programs, setPrograms] = useState([]);
    const [programId, setProgramId] = useState('');
    const [medicalSchools, setMedicalSchools] = useState([]);
    const [medicalSchoolsData, setMedicalSchoolsData] = useState([]);

    const { data: institutionsData, error: institutionsError, loading: institutionsLoading} = useQuery(getAllInstitutionsQuery, {
      variables: {},
      fetchPolicy: 'network-only'
    })
  
    useEffect(() => {
      if(institutionsLoading) {
        return;
      } else if(institutionsError) {
        console.error(institutionsError)
        return;
      } else if(institutionsData && institutionsData.getAllInstitutions) {
        console.log("institutionsData =>", institutionsData)
        if(JSON.stringify(institutionsData.getAllInstitutions) !== JSON.stringify(medicalSchools)) {
          const data = institutionsData.getAllInstitutions
          setMedicalSchoolsData(data)
          var schools = []
          data.forEach(elem => schools.push(elem.name))
          console.log("schools =>", schools)
          setMedicalSchools(schools)
        }
      }
  }, [institutionsData, institutionsError, institutionsLoading])



    const [updateUser] = useMutation(updateUserMutation);
    const [updateProgramUser] = useMutation(updateProgramUserMutation);
    const { data: allPrograms, loading: allProgramsLoading, error: allProgramsError } = useQuery(getAllProgramsQuery);
    const {data:specificProgram, loading:specificProgramLoading, error:specificProgramError} = useQuery(getProgramByMemberIdQuery, {
        variables: {
          userId: user.id
        }
    });
   

    useEffect(() => {
        if (!allProgramsLoading && !allProgramsError && allPrograms) {
            setPrograms(allPrograms.getPrograms);
        }
    }, [allPrograms, allProgramsLoading, allProgramsError]);

useEffect(() => {
    if (!specificProgramLoading && !specificProgramError && specificProgram &&specificProgram.getProgramByMemberId) {

        setProgramId(specificProgram.getProgramByMemberId.id);
    }
}, [specificProgram, specificProgramLoading, specificProgramError]);

    useEffect(() => {
      console.log(user, 'user')
        setRoles(user.roles);
    }, [user]);


if (!isOpen) return null;

const handleRoleChange = (e) => {
    const { value, checked } = e.target;
    if (value === 'PD' || value === 'PC') {
        setRoles((prevRoles) => {
          let newRoles = checked ? [...prevRoles, value] : prevRoles.filter((role) => role !== value);
  
          // Automatically add 'Reviewer' if either 'PD' or 'PC' is checked
          if (checked && !newRoles.includes('Reviewer')) {
            newRoles.push('Reviewer');
            setIsReviewerAutoSelected(true); // Mark as auto-selected
          }
  
          // Remove 'Reviewer' only if both 'PD' and 'PC' are unchecked
          if (!checked && newRoles.includes('Reviewer') && !newRoles.includes(value === 'PD' ? 'PC' : 'PD')) {
            newRoles = newRoles.filter((role) => role !== 'Reviewer');
            setIsReviewerAutoSelected(false); // Mark as not auto-selected
          }
  
          return newRoles;
        });
      } else if (value === 'Reviewer') {
        // Only allow 'Reviewer' to be toggled if neither 'PD' nor 'PC' is checked
        if (!roles.includes('PD') && !roles.includes('PC')) {
          setRoles((prevRoles) =>
            checked ? [...prevRoles, value] : prevRoles.filter((role) => role !== value)
          );
          setIsReviewerAutoSelected(false); // Mark as not auto-selected
        }
      } else {
        setRoles((prevRoles) =>
          checked ? [...prevRoles, value] : prevRoles.filter((role) => role !== value)
        );
      }

};


const handleSave = () => {
    onEditUser({ firstName, lastName, email, phone, roles, institution: institutionName, userType, programId});
    setRoles([]); // reset roles
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
};

const handleCancel = () => {
    onClose();
}

return (

<div className="modal-backdrop">
<div className="add-field-modal">
    <div className='add-field-modal-header'>
    <h2>EDIT MEMBER</h2>
    <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
    </div>

    <form style={{maxHeight:'50vh', overflowY:'auto'}}>
        <div className="form-group">
            <label style={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                color: '#848484',
                lineHeight: '24px',
                marginBottom: '19px',
            }}>Edit selected user details.</label>
                       <select 
           className='application-form-standard-input one'
           onChange={(e) => setUserType(e.target.value)}
           value={userType}
           >
                <option value="">Select the User Type</option>
                <option value="faculty">Faculty</option>
                <option value="applicant">Applicant</option>
                <option value="medSchool">Medical Shcool Admin</option>
           </select>

          <input
            style={{ marginBottom: '2vh'}}
            type="text"
            placeholder='First Name'
            className="application-form-standard-input"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
        />
          <input
            style={{ marginBottom: '2vh'}}
            type="text"
            placeholder='Last Name'
            className="application-form-standard-input"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
        />
          <input
            style={{ marginBottom: '2vh'}}
            type="text"
            placeholder='Email'
            className="application-form-standard-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
        />
          <div className='application-form-field-group' style={{ width: '49vw'}}>
          <PhoneInput
          style={{background:'linear-gradient(0deg, #f2f2f2, #f2f2f2), #fff', width: '49vw'}}
          country="US"
          defaultCountry="US"
          value={phone}
          onChange={phone => setPhone(phone)}
          className='application-form-standard-input'
        />
                
            </div>
            {user.userType === 'faculty' && (
              <>
         <Select
      options={programs.map(program => ({ label: program.name+': '+ program.specialtyDetails.name, value: program.id}))}
    //   className='application-form-standard-input one'
    value={programId===''?
    '' :
    programs.find(program => program.id === programId) ? { label: programs.find(program => program.id === programId).name + ': ' + programs.find(program => program.id === programId).specialtyDetails.name, value: programId } : null}
      onChange={option => {
        setProgramId(option.value);
      }}

      noOptionsMessage={() => 'No Programs Found'}
      styles={{
        menu: (provided) => ({
          ...provided,
          zIndex: '10'
          }),
        control: (provided) => ({
          ...provided,
          width: '100%',
          height: '61px',
          boxSizing: 'border-box',
          color: '#414141',
          background: 'linear-gradient(0deg, #f2f2f2, #f2f2f2), #fff',
          border: '1px solid #dbdbdb',
          borderRadius: '8px',
          outline: 'none',
          padding: '11px 23px',  // Note: last padding value used, overriding previous
          fontFamily: 'Montserrat',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '30px',
          zIndex:'1000',
          position:'relative'
          
        }),
        option: (provided) => ({
          ...provided,
          zIndex:'1000',
          position:'relative'
          
        }),
      }}
    />


        <label style={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                color: '#848484',
                lineHeight: '24px',
                marginBottom: '2vh',
                marginTop: '2vh'    
            }}>Select roles</label>
            <div className='role-selection-container' style={{display:'flex', flexDirection:'row'}}>

            <div style={{display:'flex', alignItems: 'center'}}>
                    <input type="checkbox" id="PD" name="PD" value="PD" style={{zIndex:'5'}} checked={roles.includes('PD')} onChange={handleRoleChange} />
                    <label for="role1" style={{ marginBottom: 0}}>Program Director</label>
                </div>
                
                <div style={{display:'flex'}}>
                    <input type="checkbox" id="PC" name="PC" value="PC" style={{zIndex:'5'}} checked={roles.includes('PC')} onChange={handleRoleChange} />
                    <label for="role2" style={{ marginBottom: 0}}>Program Coordinator</label>
                </div>
                
                <div style={{display:'flex'}}>
                    <input type="checkbox" id="Reviewer" name="Reviewer"  value="Reviewer" checked={roles.includes('Reviewer')} onChange={handleRoleChange}   style={{
                      zIndex:'5',
                        accentColor: roles.includes('Reviewer') ? (isReviewerAutoSelected ? '#8f8686' : '#145388') : 'transparent',
                    }}/>
                    <label for="role3" style={{ marginBottom: 0}}>Reviewer</label>
                </div>
                
                <div style={{display:'flex'}}>
                    <input type="checkbox" id="Recommender" name="Recommender" style={{zIndex:'5'}} value="Recommender" checked={roles.includes('Recommender')} onChange={handleRoleChange} />
                    <label for="role4" style={{ marginBottom: 0}}>Recommender</label>
                </div>

            </div>
            </>
            )}
                        {(userType === 'applicant' || userType ==='medSchool') && (
                <>
                 <label style={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            color: '#848484',
            lineHeight: '24px',
            marginBottom: '2vh',
            marginTop: '2vh'
        }}>Select Institution</label>

<Select
      options={medicalSchools.map(medSchool => ({ label: medSchool, value: medSchool}))}
      value={{ label: institutionName, value: institutionName }}
      onChange={option => {
        setInstitutionName(option.value);
      }}
      noOptionsMessage={() => 'No Institutions Found'}
      styles={{
        menu: (provided) => ({
          ...provided,
          zIndex: '10'
          }),
        control: (provided) => ({
          ...provided,
          width: '100%',
          height: '61px',
          boxSizing: 'border-box',
          color: '#414141',
          background: 'linear-gradient(0deg, #f2f2f2, #f2f2f2), #fff',
          border: '1px solid #dbdbdb',
          borderRadius: '8px',
          outline: 'none',
          padding: '11px 23px',  // Note: last padding value used, overriding previous
          fontFamily: 'Montserrat',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '30px',
          zIndex:'1000',
          position:'relative'
          
        }),
        option: (provided) => ({
          ...provided,
          zIndex:'1000',
          position:'relative'
          
        }),
      }}
    />

                </>
            )}



        </div>

    </form>
        <div className='button-container' style={{display:'flex', flexDirection:'row', justifyContent:'space-between', gap:'2vw', marginTop: '4vh'}}>
        <button type="button" className='gradient-button' style={{fontWeight:'500'}} onClick={handleSave}>Update</button>
            <button type="button" className='white-button'  onClick={handleCancel}>Cancel</button>
        </div>
    
</div>
</div>
)
};

export default EditUserModal;