import React,{useEffect, useState} from "react";
import "./styles/applicationManager.css"
import ApplicationItem from "./ApplicationItem";
import ApplicationForm from "./ApplicationForm";
import { getAllMasterApplicationsByUserIdQuery, getApplicationsByApplicantIdQuery } from "./graphql/queries";
import client from "../../../config/apolloClient";
import { GET_SCORE_BY_APPLICANTID } from './graphql/queries';
import ProgramsListTable from "./ProgramsListTable";

const checkStatus = (status) => {
    if(status === 'New' || status === 'Review' || status === 'Phone Screen') {
        return 'Applied'
    }
    if(status === 'Interview') {
        return 'Interview'
    }
    if(status === 'Offer') {
        return 'Offer Extended'
    }
    if(status === 'Accepted') {
        return 'Accepted'
    }
    if(status === 'Rejected') {
        return 'Rejected'
    }
    if(status === 'Lead') {
        return 'Not Started'
    }
    if(status === 'Started') {
        return 'Started'
    }


}


const ApplicationManager = ({ applicant, setPage, getApplicant, refetchUser, masterApplications, calculateDaysLeft, CheckStatus, calculateApplicationProgress, setApplicationData , applicationDataTitle, applicationData, score, applications, getAllMasterApplications, updatePhases, applicationLoading, refreshApplicant, positionId, routeDirectlyToForm}) => {
    const [isApplicationOpen, setIsApplicationOpen] = useState(false)
    const [selectedApplication, setSelectedApplication] = useState(null)
    const [phases, setPhases] = useState(null)

    const dataForTheTable = masterApplications.map((application) => {
        return {
            masterApplicationId: application.id,
            id: application.positionId.id,
            jobTitle: application.positionId.jobTitle,
            summary: application.positionId.summary,
            status: checkStatus(application.status),
           
        }
    })
    
    useEffect(() => {
        if(routeDirectlyToForm) {
            const selectedApp = masterApplications.find(app => app.positionId === positionId)
            
            const phases = {
                firstPhase: selectedApp.firstPhase,
                secondPhase: selectedApp.secondPhase,
                thirdPhase: selectedApp.thirdPhase,
                fourthPhase: selectedApp.fourthPhase,
                fifthPhase: selectedApp.fifthPhase,
                sixthPhase: selectedApp.sixthPhase,
                seventhPhase: selectedApp.seventhPhase
            }

            setPhases(phases)
            setSelectedApplication(selectedApp)
            setApplicationData(selectedApp)
            setIsApplicationOpen(true)
        }
    }, [routeDirectlyToForm])


    const columns = [
        // {
        //     Header: 'id',
        //     accessor: 'id',
        // },
            {
                Header: 'Title',
                accessor: 'jobTitle',
            },
            {
                Header: 'Description',
                accessor: 'summary',
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: 'Action',
                accessor: 'action',
                Cell: ({ row }) => (
                    <div className="dropdown" style={{border:'0',margin:'0',padding:'0'}}>
                    <button className="white-button"  onClick={() => handleApplicationSelect(row.original)}>
                        VIEW
                    </button>
                    </div>
                ),
            },
        ]

        const handleApplicationSelect = async (row) => {

            const selectedApp = masterApplications.find(app => app.id === row.masterApplicationId)
            console.log("selectedApp =>", selectedApp)
            
            const phases = {
                firstPhase: selectedApp.firstPhase,
                secondPhase: selectedApp.secondPhase,
                thirdPhase: selectedApp.thirdPhase,
                fourthPhase: selectedApp.fourthPhase,
                fifthPhase: selectedApp.fifthPhase,
                sixthPhase: selectedApp.sixthPhase,
                seventhPhase: selectedApp.seventhPhase
            }
            setPhases(phases)
            setSelectedApplication(selectedApp)
            setApplicationData(selectedApp)
            setIsApplicationOpen(true)

        }
        




    return (
        <div className='application-manager-container' style={{paddingTop:'0', paddingLeft:'4vw', width:'96vw'}}>
<div className='ellipseDesignTop1 ellipse' />
<div className='ellipseDesignTop2 ellipse' />
<div className='ellipseDesignTop3 ellipse' />
<div className='ellipseDesignTop4 ellipse' />
        {!isApplicationOpen && (
            <>
        <div className="applicant-home-header">
                    <h2 className="applicant-home-header-title" style={{paddingTop:'0'}}>Application Manager</h2>
                    {/* <p className="applicant-home-header-subtitle"> Welcome to your Dashboard! Here you can find all specialties participating in the Smart Recruiting this cycle. Click on their page to learn more about their specific requirements and instructions.</p> */}
                </div>
                <seperator className="applicant-home-header-seperator"/>
                <div className="applicant-home-body">
                    <ProgramsListTable
                                columns={columns}
                                data={dataForTheTable}
                                />
                        {  !masterApplications &&
                        <p className="applicant-home-no-specialties">No applications available</p>
                        }
                    </div>
                    </>

        )}
        {isApplicationOpen && (
            <div>
        <div className="applicant-home-header" style={{
            paddingTop: '5vh',
            position: 'fixed',
            zIndex: '4',
            top: '0',
            background: 'linear-gradient(45deg, #f8fcfc, #2a93d5)',
            width: '100%',
            paddingRight: '0',
            color: '#f3fafa',
            left: '32vh',
            paddingLeft: '0vw',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)'
        }}>
        <h2 className="applicant-home-header-title" style={{paddingLeft:'6vw'}}>{applicationData.positionId.jobTitle}</h2>
        <seperator className="applicant-home-header-seperator" style={{marginBottom:'0'}}/>
        </div>
        <ApplicationForm applicationLoading={applicationLoading} refetchUser={refetchUser} selectedApplication={selectedApplication} applicationData={applicationData} setPage={setPage} applicant={applicant} score={score} setIsApplicationOpen={setIsApplicationOpen} phases={phases} applications={applications} getApplicant={getApplicant} refetchApplications={getAllMasterApplications} updatePhases={updatePhases} setApplicationData={setApplicationData} refreshApplicant={refreshApplicant}/>
    
    </div>
            )}

        </div>

    )
}
export default ApplicationManager;