import React, { useState, useEffect,useRef, useContext } from 'react';
import './invite.css';
import Select from 'react-select';
import magGlass from '../../../../assets/imgs/magGlass.svg';
import GuidanceTime from '../../../../assets/imgs/guidance_time.svg';
import xIconWithCircleGray from '../../../../assets/imgs/xIconWithCircleGray.svg';
import {useQuery, useMutation} from "@apollo/client";
import {
    getAllSelectedApplicationsByProgramQuery,
    getProgramPopulatedDatesQuery,
    getGroupsByProgramId,
    getApplicationByIdQuery,
    getProgramById
} from './graphql/queries';
import {
    inviteApplicantForInterviewMutation,
    cancelApplicantInvitationMutation,
    cancelApplicantInterviewMutation,
} from './graphql/mutations';
import moment from 'moment';
import StatusIndicator from './inviteComponents/statusIndicator';
import Spinner from '../../../../components/common/spinner';
import ManuallyScheduleModal from './inviteComponents/manuallyScheduleModal';
import AddToWaitlistModal from './inviteComponents/addToWaitlistModal';
import ManualEmailModal from './emailTemplate/manualEmailModal';
import { ProgramIdContext } from '../../progIdContext';
import ApplicationView from '../applicationView/index';
import apolloClient from '../../../../config/apolloClient';
import logo from '../../../../assets/imgs/logo.svg';






const InviteApplicant = ({}) => {

    const programId = useContext(ProgramIdContext);
    console.log(programId, 'programId in invite component');
    const [users,setUsers] = useState([]);
    const [selectOptions, setSelectOptions] = useState([
        { label: "All Applications", value: 'all'},
        { label: "Invited", value: 'invited'},
        { label: "Not Invited", value: 'notInvited'},
        { label: "Accepted", value: 'accepted'},
        { label: "Declined", value: 'declined'},
        { label: "Pending", value: 'pending'},

    ])
    const [selectedOption, setSelectedOption] = useState({ label: "All Applications", value: 'all'})
    const [search, setSearch] = useState('');
    const [selectedApplicants, setSelectedApplicants] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isManuallyScheduleModalOpen, setIsManuallyScheduleModalOpen] = useState(false);
    const [isWaitlistModalOpen, setIsWaitlistModalOpen] = useState(false);
    const [isManualEmailModalOpen, setIsManualEmailModalOpen] = useState(false);
    const [selectedApplicant, setSelectedApplicant] = useState(null)
    const [showViewApplication, setShowViewApplication] = useState(false);
    const [categoryType, setCategoryType] = useState('status') // options - status/interview/group
    const [screenedData, setScreenData] = useState({})
    const applicationViewMainRef = useRef();
    const [resError, setResError] = useState(null);

    const { data, loading, error, refetch: refetchData } = useQuery(getAllSelectedApplicationsByProgramQuery, {
        variables: { programId: programId, search, category: selectedOption.value, categoryType },
      });
    const { data: populatedDatesData, loading: populatedDatesLoading, error: populatedDatesError } = useQuery(getProgramPopulatedDatesQuery, {
        variables: { programId: programId },
        });
    const { data: groupsData, loading: groupsLoading, error: groupsError } = useQuery(getGroupsByProgramId, {
        variables: { programId: programId },
        });

    const { data: programData, error: programError, isLoading: programLoading , refetchProgram} = useQuery(getProgramById, {
        variables: {
            id: programId
        },
    });

  

    

    const [inviteApplicantForInterview] = useMutation(inviteApplicantForInterviewMutation);
    const [cancelApplicantInvitation] = useMutation(cancelApplicantInvitationMutation);
    const [cancelApplicantInterview] = useMutation(cancelApplicantInterviewMutation);



    useEffect(() => {
        console.log(selectedApplicants, 'selectedApplicants')
    }, [selectedApplicants])


    useEffect(() => {
        console.log("categoryType changed!", categoryType)
    }, [categoryType])


    useEffect(() => {
        if(data) {
            setUsers(data.getAllSelectedApplicationsByProgram)
        }
    }, [data])

    useEffect(() => {
        console.log("selectedOption changed!", selectedOption)
        refetchData()
    }, [selectedOption])

    useEffect(() => {
        if(populatedDatesData) {
            //take just the data from the response object in the array, and put in a new array
            const justTheDates = populatedDatesData.getProgramPopulatedDates.map((item) => item.date)
            // format the dates to be in the format of MM/DD/YYYY and put in a new array
            const formattedDates =[]
            justTheDates.forEach((date, index) => {
                formattedDates.push(
                    {label: "Interview Day: " + moment(date).format('MM/DD/YYYY'), value: date},
                )
            })
            const newOptionsToSave = [...selectOptions, ...formattedDates]
            setSelectOptions(newOptionsToSave)
        }
    }, [populatedDatesData])

    useEffect(() => {
        if(groupsData) {
            const formattedGroups =[]
            groupsData.getGroupsByProgramId.forEach((group, index) => {
                formattedGroups.push(
                    {label: 'Folder: '+ group.name, value: group.id},
                )
            })
            const newOptionsToSave = [...selectOptions, ...formattedGroups]
            setSelectOptions(newOptionsToSave)
        }
    }, [groupsData])


    useEffect(() => {
        console.log("search changed!")
        refetchData()
    }, [search])


    if (programLoading) return <Spinner />;
    if (programError) return <p>Error :(</p>;
    else {
        if(programData) {
            let screenedDataObj = programData.getProgramById.screenedData
            if(JSON.stringify(screenedData) !== JSON.stringify(screenedDataObj)) {
                setScreenData(screenedDataObj)
                console.log("screenedData =>", screenedDataObj)
            }
        }
    }


    const handleCheckboxChange = (e, user) => {
        if (e.target.checked) {
            setSelectedApplicants([...selectedApplicants, user])
        } else {
            setSelectedApplicants(selectedApplicants.filter(selectedUser => selectedUser.id !== user.id))
        }
    }

    const handleInviteApplicants = () => {
        setIsLoading(true);
        const applicationIds = selectedApplicants.map(user => user.id);
        inviteApplicantForInterview({
            variables: { applicationIds: applicationIds },
            update: (cache, { data }) => {
                // Assuming the mutation returns the updated list of users in `data.inviteApplicantForInterview`
                // And assuming this list should replace the specific users in the current `users` state
                if (data && data.inviteApplicantForInterview) {
                    // Create a map of updated users for quick access
                    const updatedUsersMap = data.inviteApplicantForInterview.reduce((acc, user) => {
                        acc[user.id] = user;
                        return acc;
                    }, {});
    
                    // Merge the updated users with the current users state
                    const mergedUsers = users.map(user => updatedUsersMap[user.id] || user);
    
                    // Update the state with the merged users
                    setUsers(mergedUsers);
                    setSelectedApplicants([]);
                    setIsLoading(false);
                }
            }
        })
        .catch(err => {
            console.log("err =>", err)
            if (err.graphQLErrors && err.graphQLErrors.length > 0) {
                err.graphQLErrors.forEach(({ message, locations, path }) => {
                    setResError(message)
                    setIsLoading(false)
                });
            }
        });
    }


    const handleCancelInterview = (user) => {
        setIsLoading(true);
        const applicationIds = selectedApplicants.map(user => user.id);
        cancelApplicantInterview({
            variables: { applicationIds: applicationIds, programId: programId },
            update: (cache, { data }) => {
                // Assuming the mutation returns the updated user in `data.cancelApplicantInterview`
                // And assuming this user should replace the specific user in the current `users` state
                if (data && data.cancelApplicantInterview) {
                    // Create a map of updated user for quick access
                    const updatedUser = data.cancelApplicantInterview;
                    
                    // Replace the specific user with the updated user in the current users state
                    const updatedUsers = users.map(user => user.id === updatedUser.id ? updatedUser : user);

                    // Update the state with the updated users
                    setUsers(updatedUsers);
                    setSelectedApplicants([]);
                    setIsLoading(false);
                }
            }
        })
        .catch(err => {
            console.log("err =>", err)
            if (err.graphQLErrors && err.graphQLErrors.length > 0) {
                err.graphQLErrors.forEach(({ message, locations, path }) => {
                    setResError(message)
                    setIsLoading(false)
                });
            }
        });
    }
        

    const handleCancelInvitation = (user) => {
        setIsLoading(true);
        const applicationIds = selectedApplicants.map(user => user.id);
        cancelApplicantInvitation({
            variables: { applicationIds: applicationIds},
            update: (cache, { data }) => {
                // Assuming the mutation returns the updated user in `data.cancelApplicantInvitation`
                // And assuming this user should replace the specific user in the current `users` state
                if (data && data.cancelApplicantInvitation) {
                    // Create a map of updated user for quick access
                    const updatedUser = data.cancelApplicantInvitation;
    
                    // Replace the specific user with the updated user in the current users state
                    const updatedUsers = users.map(user => user.id === updatedUser.id ? updatedUser : user);
    
                    // Update the state with the updated users
                    setUsers(updatedUsers);
                    setSelectedApplicants([]);
                    setIsLoading(false);
                }
            }
        })
        .catch(err => {
            console.log("err =>", err)
            if (err.graphQLErrors && err.graphQLErrors.length > 0) {
                err.graphQLErrors.forEach(({ message, locations, path }) => {
                    setResError(message)
                    setIsLoading(false)
                });
            }
        });
    }


    const handleUpdateUsers = (updatedUsers) => {
        //   // Create a map of updated user for quick access
        //   const updatedUsers = data.cancelApplicantInvitation;
    
          // Replace the specific user with the updated user in the current users state
          const updatedUsersToSend = users.map(user => user.id === updatedUsers.id ? updatedUsers : user);

          // Update the state with the updated users
          setUsers(updatedUsersToSend);
          setSelectedApplicants([]);
          setIsManuallyScheduleModalOpen(false);
    }


    const selectOptionToShow = (option) => {
        if(option.label.includes("Interview Day:")) setCategoryType("interview")
        else if(option.label.includes("Folder:")) setCategoryType("group")
        else setCategoryType("status")
        setSelectedOption(option)
    }

    const getApplicationAndShow = (application) => {
        const id = application.id
        // get application data
        apolloClient.query({
            query: getApplicationByIdQuery,
            variables: {
                id
            }
        })
        .then(res => {
            const app = res.data.getApplicationById
            console.log("app =>", app)
            setSelectedApplicant(app); 
            setShowViewApplication(true);
        })
      

    }

    const normalizeApplication = (application) => {
        return {
            ...application,
            id: application.id,
            firstName: application.userId.firstName??'',
            lastName: application.userId.lastName??'',
            email: application.userId.email??'',
            phone: application.userId.phone??'',
            couplesMatch: application.userId.applicationInformation.couplesMatch??'',
            partnerField: application.userId.applicationInformation.partnerField??'',
            misdemeanorOrFelony: application.userId.applicationInformation.misdemeanorOrFelony??'',
            misdemeanorOrFelonyDetails: application.userId.applicationInformation.misdemeanorOrFelonyDetails??'',
            nrmpId: application.userId.applicationInformation.nrmpId??'',
            workAuthorization: application.userId.applicationInformation.workAuthorization === "U.S. Citizen, National, or Legal Permanent Resident (e.g., Refugee, Asylee)" ? "Yes" : "No"??'',
            gender: application.userId.applicationInformation.gender??'',
            otherRaceEthnicity: application.userId.applicationInformation.otherRaceEthnicity??'',
            otherWorkAuthorization: application.userId.applicationInformation.otherWorkAuthorization??'',
            partnerName: application.userId.applicationInformation.partnerName??'',
            dateOfBirth: application.userId.applicationInformation.dateOfBirth??'',
            hometown: application.userId.applicationInformation.homeTown??'',
            address: application.userId.applicationInformation.homeAddress??'',
            ethnicity: application.userId.applicationInformation.ethnicity??'',
            coupleMatch: application.userId.applicationInformation.coupleMatch === true ? "Yes" : "No"??'',
            medicalSchoolName: application.userId.applicationInformation.medicalSchoolName??'',
            aoaStatus: application.userId.applicationInformation.aoaStatus??'',
            goldHumanism: application.userId.applicationInformation.goldHumanism??'',
            firstAuthorPublications: application.userId.applicationInformation.firstAuthorPublications??'',
            peerReviewedPublications: application.userId.applicationInformation.peerReviewedPublications??'',
            posterPresentations: application.userId.applicationInformation.posterPresentations??'',
            podiumPresentations: application.userId.applicationInformation.podiumPresentations??'',
            aamc: application.userId.applicationInformation.aamc??'',
            previouslyAppliedToSpeciality: application.masterApplicationId.previouslyAppliedToSpecialty??'',
            applicationYear: application.applicationYear??'',
            isSignal: application.isSignal === true ? "Yes" : "No"??'',
            usmleStep1Score: application.scoreId.usmleStep1Score??'',
            usmleStep2Score: application.scoreId.usmleStep2Score??'',
            usmleStep3Score: application.scoreId.usmleStep3Score??'',
            comlexLevel1Score: application.scoreId.comlexLevel1Score??'',
            comlexLevel2Score: application.scoreId.comlexLevel2Score??'',
            user: application.userId??'',
            researchExperience: application.masterApplicationId.researchExperience??'',
            carryingOutResponsibilities: application.masterApplicationId.carryingOutResponsibilities??'',
            carryingOutResponsibilitiesElaboration: application.masterApplicationId.carryingOutResponsibilitiesElaboration??'',
            workExperiences: application.masterApplicationId.workExperiences??'',
            meaningfulExperiences: application.masterApplicationId.meaningfulExperiences??'',
            hobbiesAndInterests: application.masterApplicationId.hobbiesAndInterests??'',
            shortAnswerQuestions: application.masterApplicationId.shortAnswerQuestions??'',
            personalStatement: application.masterApplicationId.personalStatement??'',
            signalEssay: application.signalEssay??'',
            applicationRubrics: application.applicationRubrics??'',
            watchUsersIds: application.watchUsersIds??'',
            isLanguages: application.userId.applicationInformation.isLanguages??'',
            fluentLanguages: application.userId.applicationInformation.fluentLanguages??''
        }
    }


    return (
        <>
        {
            !showViewApplication &&
            <div className='invite-container'>
            {isManuallyScheduleModalOpen && <ManuallyScheduleModal programId={programId} selectedApplicants={selectedApplicants} setIsManuallyScheduleModalOpen={setIsManuallyScheduleModalOpen} updateUsers={handleUpdateUsers}/>}
            {isWaitlistModalOpen && <AddToWaitlistModal programId={programId} selectedApplicants={selectedApplicants} setIsWaitlistModalOpen={setIsWaitlistModalOpen} updateUsers={handleUpdateUsers}/>}
            {isManualEmailModalOpen && <ManualEmailModal programId={programId} selectedApplicants={selectedApplicants} setIsManualEmailModalOpen={setIsManualEmailModalOpen} />}
            <div className='left-side-container'>
                <div className='left-container-top'>
                    <label className='container-title'>Invite Applicants</label>
                    <Select 
                        options={selectOptions}
                        onChange={selectOptionToShow}
                        value={selectedOption}
                        styles={{
                            menu: (provided) => ({
                              ...provided,
                              zIndex: '10',
                              }),
                            singleValue: (provided) => ({
                                ...provided,
                                color: '#145388',
                            }),
                            control: (provided) => ({
                              ...provided,
                              width: '20vw',
                              // height: '9vh',
                              fontSize:'1rem',
                              boxSizing: 'border-box',
                            //   color: '#414141',
                            color: '#145388',
                            background: '#E9F4F4',
                            //   background: 'linear-gradient(0deg, #f2f2f2, #f2f2f2), #fff',
                              border: '1px solid #145388',
                              borderRadius: '8px',
                              outline: 'none',
                              fontFamily: 'Montserrat',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '30px',
                              zIndex:'10',
                              position:'relative',
                              overflowY: 'scroll'
                              
                            }),
                            option: (provided) => ({
                              ...provided,
                              zIndex:'10',
                              position:'relative',
                            }),
                            indicatorsContainer: (provided) => ({
                              ...provided,
                              height: '8vh'
                            })
                        }}
                    />
                </div>
                <div className='invite-search-container'>
                    <span className='rt-search-bar' style={{ width: '100%'}}>
                        <img src={magGlass} alt='magnifying glass' />
                        <input
                        style={{width:'30vw',height:'4.7vh',alignSelf:'center'}}
                        value={search}
                        onChange={e => {
                            setSearch(e.target.value || undefined); // Set undefined to remove the filter entirely
                        }}
                        placeholder={`Search Applicant's Name`}
                    />
                    </span>
                </div>
                <div className='invite-scrollable-list'>
                    {
                        users.map(user => (
                            <div className='inner-list-item'>
                                 

                                        <div className='details-left'>
                                        <input 
                                        style={{marginRight: '1.5vw'}}
                                        type="checkbox" 
                                        checked={selectedApplicants.includes(user)}
                                        onChange={(e) => handleCheckboxChange(e,user)} 
                                    />
                                        <img src={user.profilePhotoUrl?user.profilePhotoUrl:`https://ui-avatars.com/api/?name=${user.firstName}${user.lastName}`} alt="user-profile" style={{ width: '53px', borderRadius: '50px'}}/>
                                           <div className='details-item-name'>
                                            <label className='details-bold-title'>{user.firstName} {user.lastName}</label>
                                            <label className='details-normal-title'>{user.institution}</label>
                                           </div>
                                        </div>
                                        <div className='details-right' style={{margin:'0', width:'6vw'}}>
                                            <label className='details-bold-title'>NRMP ID</label>
                                            <label className='details-normal-title'>{user.nrmpId}</label>
                                        </div>
                                    
                                        <StatusIndicator status={user.invitationStatus} interviewDate={user.interviewDate} />
                                    <label className='view-title' onClick={() => getApplicationAndShow(user)}>View</label>
                                   
                            
                            </div>
                        ))
                    }
                </div>
                {/* <button className='green-button' style={{width: '100%', marginTop: '2vh'}}>Send Invitation</button> */}
            </div>
            <div className='right-side-container' >
                {isLoading && <Spinner />}
                {!isLoading &&(
                    <>
                <div style={{textAlign:'center', paddingTop:'3vh'}}>
                <label className='container-title'>Actions</label>
                </div>
                <div className='buttons-actions-container' style={{display:'flex', flexDirection:'column', marginTop:'6vh'}}>
                <button className='green-button' disabled={selectedApplicants.length===0} style={{width: '100%', marginTop: '3vh', padding:'1vh', fontSize:'.9vw', background:'linear-gradient(45deg, #4A9EA0, #547682)'}} onClick={handleInviteApplicants}>Send Invitation</button>
                <button className='green-button' disabled={selectedApplicants.length===0} style={{width: '100%', marginTop: '3vh', padding:'1vh', fontSize:'.9vw', background:'linear-gradient(45deg, #4A9EA0, #547682)'}} onClick={handleCancelInvitation} >Cancel Invitation</button>
                <button className='green-button' disabled={selectedApplicants.length===0} style={{width: '100%', marginTop: '3vh', padding:'1vh', fontSize:'.9vw', background:'linear-gradient(45deg, #4A9EA0, #547682)'}} onClick={()=>setIsManuallyScheduleModalOpen(true)}>Manually Schedule</button>
                <button className='green-button' disabled={selectedApplicants.length===0} style={{width: '100%', marginTop: '3vh', padding:'1vh', fontSize:'.9vw', background:'linear-gradient(45deg, #4A9EA0, #547682)'}} onClick={handleCancelInterview}>Cancel Interview</button>
                <button className='green-button' disabled={selectedApplicants.length===0} style={{width: '100%', marginTop: '3vh', padding:'1vh', fontSize:'.9vw', background:'linear-gradient(45deg, #4A9EA0, #547682)'}} onClick={()=>setIsWaitlistModalOpen(true)}>Add to Waitlist</button>
                <button className='green-button' disabled={selectedApplicants.length===0} style={{width: '100%', marginTop: '3vh', padding:'1vh', fontSize:'.9vw', background:'linear-gradient(45deg, #4A9EA0, #547682)'}} onClick={()=>setIsManualEmailModalOpen(true)}>Email Manually</button>
                </div>
                </>
                )}
            </div>
           
            </div>
        }
        {
            showViewApplication &&
            <div style={{ marginLeft: '-17vw'}}>
                <ApplicationView key={selectedApplicant.id} application={normalizeApplication(selectedApplicant)} onClose={() => setShowViewApplication(prevState => !prevState)} pdfRef={applicationViewMainRef} nextApplicationClicked={() => {}} screenedData={screenedData}/>
            </div>
        }
        {
            resError && 
            <div className="modal-backdrop">
                <div className="add-field-modal" style={{ height: '30vh', overflow: 'scroll', fontFamily: 'Montserrat'}}>
                    <div className='add-field-modal-header'>
                    <h2>Error</h2>
                    <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
                    </div>
                    {resError}
                    <div className='button-container' style={{display:'flex', flexDirection:'row', justifyContent:'flex-end', gap:'2vw', marginTop: '2vh'}}>
                    <button className='green-button' onClick={() => setResError(null)}>Close</button>
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default InviteApplicant;