import React, {useState} from 'react';
import { useTable, useSortBy, useRowSelect } from 'react-table';

const ResponseTable = ({columns, data }) => {


  console.log("data in response table =>", data)
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { selectedRowIds },
    } = useTable({ columns, data }, useSortBy, useRowSelect);

    return (
        <div style={{ overflowX: 'auto', width: '100%' }}>
          <table className='table-programs-list' {...getTableProps()}>
          <thead className='tHead-programs-list'>
              {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th className='table-header-title' {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                const rowStyle = row.values.userName === "Pending Invitation" ? {background: 'gainsboro', fontStyle:'italic', fontWeight:'bold'} : {};
                return (
                  <tr className='programs-list-table-row ' {...row.getRowProps()} style={{...rowStyle, height: '10vh'}}>
                      {row.cells.map(cell => (
                          <td style={{}}className='programs-list-table-row-text' {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );


}

export default ResponseTable;