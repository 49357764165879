import { gql } from '@apollo/client';


export const getLorsByUserQuery = gql`
    query getLorsByUser($userId: ID!){ 
        getLorsByUser(userId: $userId) {
          id
          applicationId
          isSlor
          applicantId {
              id
              firstName
              lastName
              email 
              phone
              profilePhotoUrl
              applicationInformation {
                  nrmpId
                  workAuthorization
                  gender
                  otherRaceEthnicity
                  otherWorkAuthorization
                  partnerName
                  dateOfBirth
                  homeTown
                  ethnicity
                  coupleMatch
                  medicalSchoolName
                  aoaStatus
                  goldHumanism
                  firstAuthorPublications
                  peerReviewedPublications
                  posterPresentations
                  podiumPresentations
                  aamc
                  previousLastName
                    prefferedName
                    pronouns
                    usmleId
                    nbomeId
                    aoaId
                    permanentAddress
                    licensureRevoked
                    licensureRevokedDetails
                    militaryObligation
                    militaryObligationDetails
                    otherServiceObligations
                    medicalTrainingInterrupted
                    medicalTrainingInterruptedDetails
                    sigmaPhi
                  malpractice
                  awardsAndHonors
                  isLanguages
                  misdemeanorOrFelony
                  higherEducation {
                      institution
                      degree
                      date
                  }
                  coreClerkshipGrades {
                      clerkshipEm
                      clerkshipFm
                      im
                      neurology
                      obgyn
                      pediatrics
                      psychiatry
                      surgery
                  }
  
                  awayRotations {
                      institution
                      degree
                      dateCompleted
                  }
                  nonCoreClerkshipGrades {
                      course
                      grade
                  }
                }
          }
          recommenderId {
            firstName
            lastName
            email 
            phone
            profilePhotoUrl
            recommenderPosition
            applicationInformation {
                nrmpId
                workAuthorization
                gender
                otherRaceEthnicity
                otherWorkAuthorization
                partnerName
                dateOfBirth
                homeTown
                ethnicity
                coupleMatch
                medicalSchoolName
                aoaStatus
                goldHumanism
                firstAuthorPublications
                peerReviewedPublications
                posterPresentations
                podiumPresentations
                aamc
                previousLastName
                prefferedName
                pronouns
                usmleId
                nbomeId
                aoaId
                permanentAddress
                licensureRevoked
                licensureRevokedDetails
                militaryObligation
                militaryObligationDetails
                otherServiceObligations
                medicalTrainingInterrupted
                medicalTrainingInterruptedDetails
                sigmaPhi
                malpractice
                awardsAndHonors
                isLanguages
                misdemeanorOrFelony
                higherEducation {
                    institution
                    degree
                    date
                }
                coreClerkshipGrades {
                    clerkshipEm
                    clerkshipFm
                    im
                    neurology
                    obgyn
                    pediatrics
                    psychiatry
                    surgery
                }

                awayRotations {
                    institution
                    degree
                    dateCompleted
                }
                nonCoreClerkshipGrades {
                    course
                    grade
                }
            }
          }
          stage
          docUrl
          requestedAt
          submittedAt 
          slor {
            email
            howLongKnownTheApplicant
            improvementAspect
            natureOfContact
            presentPosition
            radioAcademicSkills
            radioCommunicationSkills
            radioOperativeSkill
            radioOverallComparedToOtherApplicants
            radioPatientCare
            radioProfessionalism
            radioResearchAndTeaching
            radioSelfInitiative
            radioTeamPlayer
            radioWorkEthic
            rank
            strongestPoint
            willingToDiscuss
            writtenComments
          } 
        }
    }
`


export const getProgramWithSpecialtyDataQuery = gql`
query getProgramWithSpecialtyData($id: ID!) {
    getProgramWithSpecialtyData(id: $id) {
        id
        name
        specialtyId {
            id
            name
            description
            importantDates
            applicationRequirements {
                degree
                lors
                mspe
                transcript
                step1
                step2
                cost
            }
            releaseDate
        }       
    }
}
`;

