import React,{useState, useContext, useEffect} from "react";
import "./index.css";
import RecommendationsTable from "./recommendationsTable";
import SpecificLor from "./specificLor";
import { getLorsByUserQuery, getProgramWithSpecialtyDataQuery } from './graphql/queries';
import { useQuery } from "@apollo/client"; 
import { ProgramIdContext } from '../../progIdContext';
import { UserContext } from '../../../../context/userContext';
import Spinner from "../../../common/spinner";


const Recommendations = () => {

    const { user} = useContext(UserContext);
    const [isSpecificLor, setIsSpecificLor] = useState(false);
    const [specialty, setSpecialty] = useState('');
    const [lor, setLor] = useState(null);
    const [lors, setLors] = useState([]);
    const [userId, setUserId] = useState(user.userId);
    const [program, setProgram] = useState(null);
    const programId = useContext(ProgramIdContext);




    const { data: lorData, error: lorError, loading: lorLoading , refetch: refetchLors  } = useQuery(getLorsByUserQuery, {
        variables: {
            userId
           
        },
    });

    const { data: programData, error: programError, loading: programLoading } = useQuery(getProgramWithSpecialtyDataQuery, {
        variables: {
            id: programId
           
        },
    });


    if (lorLoading) return <Spinner />;
    if (lorError) return <p>Error :(</p>;
    else {
        const lorsFromQuery = lorData.getLorsByUser
        if(JSON.stringify(lors) !== JSON.stringify(lorsFromQuery)) {
            setLors(lorsFromQuery)
            console.log("lors by user =>",lorsFromQuery )
        }
    }


   
    if (programLoading) return <Spinner />;
    if (programError) return <p>Error :(</p>;
    else {
        const programFromQuery = programData.getProgramWithSpecialtyData
        if(JSON.stringify(program) !== JSON.stringify(programFromQuery)) {
            setProgram(programFromQuery)
            console.log("program with specialty =>",programFromQuery )
        }
    }




    const columns = [
        {
            Header: 'Applicant Name',
            accessor: data => `${data.applicantId.firstName} ${data.applicantId.lastName}`
        },
        {
            Header: 'Applicant Email',
            accessor: 'applicantId.email',
        },
        // {
        //     Header: 'Specialty',
        //     accessor: data => program ? program.specialtyId.name : "",
        // },
        {
            Header: 'Status',
            accessor: data => data.stage === "Pending" ? "Pending submission" : "Submitted",
        },
        {
            Header: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div style={{display:'flex', flexDirection:'row', gap:'.2vw', marginRight:'.3vw'}}>
                    <button className='green-button' style={{padding:'1vh 1vw', fontSize:'14px', flexGrow:'unset'}} onClick={()=>handleViewLor(program.specialtyId, row.original)}>View</button>
                </div>
            ),
        },
    ]


    const handleViewLor = (specialty, lor) => {
        setSpecialty(specialty);
        setLor(lor);
        setIsSpecificLor(true);
    }

    return (
        <div className='faculty-home-container'>
            <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop2' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="faculty-home-header">
                <h2 className="faculty-home-header-title">RECOMMENDATIONS</h2>               
            </div>
            <seperator style={{marginTop:'3vh'}} className="faculty-home-header-seperator"/>
            {!isSpecificLor&&(<RecommendationsTable data={lors} columns={columns} />)}
            {isSpecificLor&&(<SpecificLor setIsSpecificLor={setIsSpecificLor} specialty={specialty} lor={lor}  refetchLors={refetchLors}/>)}
           
        </div>
    );
}

export default Recommendations;

