import { gql } from '@apollo/client';

export const VERIFY_TOKEN = gql`
query VerifyToken($token: String!) {
    verifyToken(token: $token) {
      email
      userId
    }
  }
`;

export const getUserVerificationTokenByOneTimeTokenQuery = gql`
  query getUserVerificationTokenByOneTimeToken($oneTimeLoginToken: String) {
    getUserVerificationTokenByOneTimeToken(oneTimeLoginToken: $oneTimeLoginToken) {
      userId
      userType
      token
      programId
    }
  } 
`

export const getUserDirectApply = gql`
query getUserDirectApply($oneTimeLoginToken: String!) {
  getUserDirectApply(oneTimeLoginToken: $oneTimeLoginToken) {
    userId
    email
    firstName
    lastName
    userType
    token
    programId
    positionId
  }
}
`;