import React, { useState, useEffect } from 'react';
import logo from '../../../../assets/imgs/logo.svg'


const UpdateRoleModal = ({ isOpen, onClose, user, onEditUser }) => {
    const [roles, setRoles] = useState(user.roles);
    const [isReviewerAutoSelected, setIsReviewerAutoSelected] = useState(false);

    useEffect(() => {
        setRoles(user.roles);
    }, [user]);


if (!isOpen) return null;

const handleRoleChange = (e) => {
    const { value, checked } = e.target;
    if (value === 'PD' || value === 'PC') {
        setRoles((prevRoles) => {
          let newRoles = checked ? [...prevRoles, value] : prevRoles.filter((role) => role !== value);
  
          // Automatically add 'Reviewer' if either 'PD' or 'PC' is checked
          if (checked && !newRoles.includes('Reviewer')) {
            newRoles.push('Reviewer');
            setIsReviewerAutoSelected(true); // Mark as auto-selected
          }
  
          // Remove 'Reviewer' only if both 'PD' and 'PC' are unchecked
          if (!checked && newRoles.includes('Reviewer') && !newRoles.includes(value === 'PD' ? 'PC' : 'PD')) {
            newRoles = newRoles.filter((role) => role !== 'Reviewer');
            setIsReviewerAutoSelected(false); // Mark as not auto-selected
          }
  
          return newRoles;
        });
      } else if (value === 'Reviewer') {
        // Only allow 'Reviewer' to be toggled if neither 'PD' nor 'PC' is checked
        if (!roles.includes('PD') && !roles.includes('PC')) {
          setRoles((prevRoles) =>
            checked ? [...prevRoles, value] : prevRoles.filter((role) => role !== value)
          );
          setIsReviewerAutoSelected(false); // Mark as not auto-selected
        }
      } else {
        setRoles((prevRoles) =>
          checked ? [...prevRoles, value] : prevRoles.filter((role) => role !== value)
        );
      }

};


const handleSave = () => {
    onEditUser({ ...user, roles });
    setRoles([]); // reset roles
};

const handleCancel = () => {
    onClose();
}

return (

<div className="modal-backdrop">
<div className="add-field-modal">
    <div className='add-field-modal-header'>
    <h2>MANAGE ROLES</h2>
    <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
    </div>

    <form>
        <div className="form-group">
         
            <div className='update-role-selection-container' >

                <div style={{display:'flex', alignItems: 'center'}}>
                    <input type="checkbox" id="PD" name="PD" value="PD" checked={roles.includes('PD')} onChange={handleRoleChange} />
                    <label for="role1" style={{ marginBottom: 0}}>Program Director</label>
                </div>
                
                <div style={{display:'flex'}}>
                    <input type="checkbox" id="PC" name="PC" value="PC" checked={roles.includes('PC')} onChange={handleRoleChange} />
                    <label for="role2" style={{ marginBottom: 0}}>Program Coordinator</label>
                </div>
                
                <div style={{display:'flex'}}>
                    <input type="checkbox" id="Reviewer" name="Reviewer" value="Reviewer" checked={roles.includes('Reviewer')} onChange={handleRoleChange}   style={{
                        accentColor: roles.includes('Reviewer') ? (isReviewerAutoSelected ? '#8f8686' : '#145388') : 'transparent',
                    }}/>
                    <label for="role3" style={{ marginBottom: 0}}>Reviewer</label>
                </div>
                
                <div style={{display:'flex'}}>
                    <input type="checkbox" id="Recommender" name="Recommender" value="Recommender" checked={roles.includes('Recommender')} onChange={handleRoleChange} />
                    <label for="role4" style={{ marginBottom: 0}}>Recommender</label>
                </div>

            </div>
        </div>

        <div className='button-container' style={{display:'flex', flexDirection:'row', justifyContent:'space-between', gap:'2vw'}}>
        <button type="button" className='gradient-button' style={{fontWeight:'500'}} onClick={handleSave} disabled={roles.length === 0}>Update</button>
            <button type="button" className='white-button'  onClick={handleCancel}>Cancel</button>
        </div>
    </form>
    
</div>
</div>
)
};

export default UpdateRoleModal;