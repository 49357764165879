import React, { useState, useEffect,useRef, useContext } from 'react';
import './plan.css';
import Select from 'react-select';
import magGlass from '../../../../assets/imgs/magGlass.svg';
import GuidanceTime from '../../../../assets/imgs/guidance_time.svg';
import PlanInterviewCalendar from "./planCalendar";
import {useQuery, useMutation, useLazyQuery} from '@apollo/client';
import {getScheduledApplicantsByProgramAndDateQuery, getWaitlistApplicantsByProgramAndDateQuery} from './graphql/queries';
import {GET_PROGRAM_USERS_BY_PROGRAM_ID} from '../../facultyComponents/myTeam/graphql/queries';
import { useInterviewScheduleSingle } from './SingleInterviewScheduleContext';
import { ProgramIdContext } from '../../progIdContext';
import moment from 'moment';


const Plan = () => {
    const programId = useContext(ProgramIdContext);
    const { selectedDate, setSelectedDate,
        page,setPage,
        step,setStep,
        startTime, setStartTime,
        interviewLength, setInterviewLength,
        breakTime, setBreakTime,
        slots, setSlots,
        interviewersPerInterviewee, setInterviewersPerInterviewee,
        numberOfSlots, setNumberOfSlots,
        dayType, setDayType,
        isLocked, setIsLocked,
        numOfInterviewers, setNumOfInterviewers,
        resetValues,
        } = useInterviewScheduleSingle();
    const [list, setList] = useState('Applicants');  
    const [applicants, setApplicants] = useState([]);
    const [interviewers, setInterviewers] = useState([]);
    const [waitlistApplicants, setWaitlistApplicants] = useState([]);

    const [getApplicans, { data: applicantsData, loading: applicantsLoading, error: applicantsError }] = useLazyQuery(getScheduledApplicantsByProgramAndDateQuery);
    const [getInterviewers, { data: interviewersData, loading: interviewersLoading, error: interviewersError }] = useLazyQuery(GET_PROGRAM_USERS_BY_PROGRAM_ID);
    const [getWaitlistApplicants, { data: waitlistApplicantsData, loading: waitlistApplicantsLoading, error: waitlistApplicantsError }] = useLazyQuery(getWaitlistApplicantsByProgramAndDateQuery);

    useEffect(() => {
        if (selectedDate) {
            getApplicans({ variables: { programId: programId, date: selectedDate } }).then((res) => {
                setApplicants(res.data.getScheduledApplicantsByProgramAndDate);
            }
            );

        }
    }, [selectedDate])

    useEffect(() => {
        if (programId && list === 'Interviewers') {
            getInterviewers({ variables: { programId: programId, sort:{ field: 'lastName', direction: 'asc'} } }).then((res) => {
                setInterviewers(res.data.getProgramMembersByProgramIdWithSort);
            }
            );
        }
        if (programId && list === 'Waitlist') {
            getWaitlistApplicants({ variables: { programId: programId, date: selectedDate } }).then((res) => {
                setWaitlistApplicants(res.data.getWaitlistApplicantsByProgramAndDate);
            }
            );
        }
    }, [list])
    
    const listOptions = [
        'Applicants',
        'Interviewers',
        'Waitlist',
    ]

    const notSelectedStyle = {
        fontWeight:'200',
        cursor: 'pointer'
    }
    const selectedStyle = {
        fontWeight: '500',
        cursor: 'default',
        textDecoration: 'underline',
        color:'#145388'
    }

    const handleDragStart = (e, user) => {
        // Convert the user object to a string to set it as the drag data
        const userData = JSON.stringify(user);
        e.dataTransfer.setData("application/json", userData);
        // reomve the user from the interviewers list
        const updatedInterviewers = interviewers.filter(interviewer => interviewer.userId !== user.userId);
        setInterviewers(updatedInterviewers);
    };

    return (
        <div className='plan-container'>
            <div className='left-side-container' style={{width:'46vw', height:'82vh'}}>
                <div className='left-container-top' style={{padding:'0 5vw'}}>
                    {listOptions.map(option => (
                    <label style={list === option?selectedStyle:notSelectedStyle} className='container-title' onClick={()=>setList(option)}>{option}</label>    
                    ))}
                    
                    {/* // <div className='numbers-container'>
                    //     <label>Accepted<span style={{ color: "#49AA53"}}> 28</span></label>
                    //     <label className='seprator-line'></label>
                    //     <label>Pending<span style={{ color: "#A5774D"}}> 04</span></label>
                    // </div> */}
                </div>
                <div className='invite-scrollable-list' style={{height:'74vh'}}>
                {
                    list==='Applicants'&&selectedDate&&applicants.map(user => (
                        <div className='invited-item' draggable="true" onDragStart={(e) => handleDragStart(e, user)}>
                            <div className='details-left'>
                            <img src={user.profilePhotoUrl?user.profilePhotoUrl:`https://ui-avatars.com/api/?name=${user.firstName}${user.lastName}`} alt="user-profile" style={{ width: '53px', borderRadius: '50px'}}/>
                                <div className='details-item-name'>
                                <label className='details-bold-title'>{user.firstName} {user.lastName}</label>
                                <label className='details-normal-title'>{user.institution}</label>
                                </div>
                            </div>
                        </div>
                    ))
                }
                 {
                    list==='Waitlist'&&selectedDate&&waitlistApplicants.map(user => (
                        <div className='invited-item'>
                            <div className='details-left'>
                            <img src={user.profilePhotoUrl?user.profilePhotoUrl:`https://ui-avatars.com/api/?name=${user.firstName}${user.lastName}`} alt="user-profile" style={{ width: '53px', borderRadius: '50px'}}/>
                                <div className='details-item-name'>
                                <label className='details-bold-title'>{user.firstName} {user.lastName}</label>
                                <label className='details-normal-title'>{user.institution}</label>
                                </div>
                            </div>
                        </div>
                    ))
                }
                 {
                    list==='Interviewers'&&selectedDate&&interviewers.map(user => (
                        <div className='invited-item' draggable="true" onDragStart={(e) => handleDragStart(e, user)}>
                            <div className='details-left'>
                            <img src={user.profilePhotoUrl?user.profilePhotoUrl:`https://ui-avatars.com/api/?name=${user.firstName}${user.lastName}`} alt="user-profile" style={{ width: '53px', borderRadius: '50px'}}/>
                                <div className='details-item-name'>
                                <label className='details-bold-title'>{user.userId.firstName} {user.userId.lastName}</label>
                                <label className='details-normal-title'>{user.userId.email}</label>
                                    </div>
                                    </div>
                                <div className='details-right' style={{flexDirection:'row', gap:'.4vw'}}>
                                    {user.roles.includes('PD') && <label style={{fontWeight:'500', color:'black', textAlign:'right', width:'13vw'}} className='details-normal-title'>Program Director</label>}
                                    {user.roles.includes('PC') && <label style={{fontWeight:'500', color:'black', textAlign:'right', width:'13vw'}} className='details-normal-title'>Program Coordinator</label>}
                                {user.roles.map(role => (
                                    user.roles.includes('PD') || user.roles.includes('PC')?null:
                                <label style={{fontWeight:'500', color:'black', textAlign:'right', width:'13vw'}} className='details-normal-title'>{role}</label>
                                ))}
                            </div>
                        </div>
                    ))
                }
                {!selectedDate  && (
                    <div style={{textAlign:'center', marginTop:'5vh'}}>
                        <label style={{fontWeight:'500', fontSize:'2vh'}}>No date selected</label>
                    </div>
                )}
                {selectedDate && list==='Applicants' && applicants.length === 0 && (
                    <div style={{textAlign:'center', marginTop:'5vh'}}>
                        <label style={{fontWeight:'500', fontSize:'2vh'}}>No applicants have scheduled for {selectedDate.toLocaleString('default', { month: 'long' })}, {selectedDate.getDate()} yet</label>
                    </div>
                )}
                {selectedDate && list==='Waitlist' && waitlistApplicants.length === 0 && (
                    <div style={{textAlign:'center', marginTop:'5vh'}}>
                        <label style={{fontWeight:'500', fontSize:'2vh'}}>No applicants are waitlisted for {selectedDate.toLocaleString('default', { month: 'long' })}, {selectedDate.getDate()} yet</label>
                    </div>
                )}
                </div>
            </div>
            <div className='right-side-container plan'>
                <div className='right-container-top' style={{ marginBottom: '2vh'}}>
                    <label className='container-title'>Your Interview Day</label>
                    <div>
                        {/* <label className='selected-date'>Septemer 1</label>
                        <label className='change-date'>Change</label> */}
                    </div>
                </div>
                <PlanInterviewCalendar />

            </div>
        </div>
    )
}

export default Plan;
