import { gql } from "@apollo/client";



export const getProgramById = gql`
    query getProgramById($id: ID!) {
        getProgramById(id: $id) {
            id
            name
            description
             defaultLegalDisclaimers
            defaultEqualEmploymentOpportunityStatement
            specialtyId
            isRubricLocked
            rubricTemplate{
                id
                label
                inputType
                maxScale
                isPreInterview
                lowestScaleText
                highestScaleText
                dropdownOptions {
                    option
                    score
                }
                text
                order
                weight
            }
            screenedData {
                sections {
                    personalInfo
                    residencyApplication
                    personalStatement
                    documents
                    lors
                }
                personalInfo {
                    profilePicture
                    firstName
                    lastName
                    email
                    phoneNumber
                    genderIdentity
                    nrmpId
                    aamcId
                    hometown
                    raceEthnicity
                    homeAddress
                    workAuthorization
                    preventResponsibilities
                    elaborateResponsibilities
                    misdemeanorOrFelony
                    misdemeanorOrFelonyDetails
                    couplesMatch
                    partnerField
                    previouslyApplied
                    malpracticeLawsuit
                    elaborateMalpractice
                    previousLastName
                    prefferedName
                    pronouns
                    usmleId
                    nbomeId
                    aoaId
                    permanentAddress
                    licensureRevoked
                    militaryObligation
                    otherServiceObligations
                    medicalTrainingInterrupted
                    sigmaPhi
                    dateOfBirth
                }
                residencyApplication {
                    medicalSchoolType
                    medicalSchoolName
                    fluencyInLanguagesBesidesEnglish
                    otherLanguagesFluentIn
                    otherDegreesInstitutionsDates
                    aoaStatus
                    ghhsStatus
                    medicalSchoolAwardsHonors
                    coreClerkshipsGraded
                    specifyClerkshipDetails
                    clerkshipGrades
                    awayRotations
                    nonCoreClerkshipGradedCourses
                    impactfulResearchPublications
                    additionalResearchBibliography
                    firstAuthorPublications
                    totalPeerReviewedPublications
                    posterPresentations
                    podiumPresentations
                    meaningfulExperiences
                    significantWorkExperiences
                    shortQuestions
                    hobbiesInterests
                }
            }
        }
    }
`;

export const getProgramByIdMembers = gql`
    query getProgramByIdMembers($id: ID!) {
        getProgramByIdMembers(id: $id) {
            id
            members {
                userId {
                    id
                    firstName
                    lastName
                }
                roles
            }
        }
    }
`


export const getPrograms = gql`
    query getPrograms {
        getPrograms {
            id
            programName
            location
            learnMore
        }
    }
`;

export const getApplicationsCountQuery = gql`
query getApplicationsCount($userId: ID, $programId:ID, $limit: Int, $offset: Int, $filters: FilterInput, $search:ApplicationSearch, $sort: ApplicationSort, $insideGroups: Boolean, $applicationsIds:[ID]) {
    getApplicationsCount(userId: $userId, programId:$programId limit: $limit, offset: $offset, filters: $filters, search:$search, sort: $sort, insideGroups:$insideGroups, applicationsIds: $applicationsIds ) 
}
`


export const getApplicationsCountsByStatus = gql`
    query getApplicationsCountsByStatus($positionId: ID!) {
        getApplicationsCountsByStatus(positionId: $positionId) {
            allApplications
            newApplications
            leadApplications
            reviewApplications
            phoneApplications
            interviewApplications
            offerApplications
            acceptedApplications
            rejectedApplications
        }
    }
`

export const getApplicationsPaginationQuery = gql`
    query getApplicationsPagination($userId: ID, $programId:ID, $limit: Int, $offset: Int, $filters: FilterInput, $search:ApplicationSearch, $sort: ApplicationSort, $insideGroups: Boolean, $applicationsIds:[ID],$positionId:ID) {
        getApplicationsPagination(userId: $userId, programId:$programId limit: $limit, offset: $offset, filters: $filters, search:$search, sort: $sort, insideGroups:$insideGroups, applicationsIds: $applicationsIds, positionId:$positionId) {
            id
            medicalSchoolState
            region
            signalEssay
            archived
            reviewDetails
            signalType
            watchUsersIds
            createdAt
            applicationRubrics {
                label
                inputType
                maxScale
                lowestScaleText
                highestScaleText
                dropdownOptions {
                    option
                    score
                }
                text
                order
                result 
            }
            userId {
                _id
                firstName
                lastName
                email 
                phone
                profilePhotoUrl
                graduationYear
                graduationYearUpdated
                mspe {
                    docUrl
                }
                transcript {
                    docUrl
                }
                applicationInformation {
                    nrmpId
                    workAuthorization
                    gender
                    otherRaceEthnicity
                    otherWorkAuthorization
                    partnerName
                    partnerFeild
                    dateOfBirth
                    homeTown
                    homeAddress
                    ethnicity
                    coupleMatch
                    medicalSchoolName
                    medicalSchoolType
                    aoaStatus
                    goldHumanism
                    firstAuthorPublications
                    peerReviewedPublications
                    posterPresentations
                    podiumPresentations
                    aamc
                    previousLastName
                    prefferedName
                    pronouns
                    usmleId
                    nbomeId
                    aoaId
                    permanentAddress
                    licensureRevoked
                    licensureRevokedDetails
                    militaryObligation
                    militaryObligationDetails
                    otherServiceObligations
                    medicalTrainingInterrupted
                    medicalTrainingInterruptedDetails
                    sigmaPhi
                    malpractice
                    awardsAndHonors
                    isLanguages
                    fluentLanguages
                    misdemeanorOrFelony
                    misdemeanorOrFelonyDetails
                    higherEducation {
                        institution
                        degree
                        date
                    }
                    coreClerkshipType
                    otherCkMethod
                    coreClerkshipGrades {
                        clerkshipEm
                        clerkshipFm
                        im
                        neurology
                        obgyn
                        pediatrics
                        psychiatry
                        surgery
                    }
    
                    awayRotations {
                        institution
                        degree
                        dateCompleted
                    }
                    nonCoreClerkshipGrades {
                        course
                        grade
                    }
                }
            }
            isSignal
            selectedTracks {
                trackName
            }
            scoreId {
                usmleStep1Score
                comlexLevel1Score
                usmleStep2Score
                usmleStep3Score
                comlexLevel2Score
                previousUsmleComlexFails
            }
            masterApplicationId {
                _id
                previouslyAppliedToSpecialty
                applicationYear
                carryingOutResponsibilities
                carryingOutResponsibilitiesElaboration
                status
                researchExperience {
                    category
                    title
                    authors
                    journal
                    datePublished
                    pubMedId
                    isFirstAuthor
                    significantResearchExperience
                    meeting
                    meetingDate
                    order
                }
                workExperiences {
                    employer
                    jobTitle
                    startDate
                    endDate
                    mentorName
                    mentorEmail
                    description
                }
                meaningfulExperiences {
                    category
                    title
                    startDate
                    endDate
                    description
                }
                hobbiesAndInterests
                shortAnswerQuestions {
                    question
                    answer
                }
                personalStatement
            }
        }
    }
`

export const getGroupsByProgramIdQuery = gql`
    query getGroupsByProgramId($programId: ID, $filter: FilterInput) {
        getGroupsByProgramId(programId: $programId, filter: $filter) {
            id
            name
            description
            membersIds
            applicationIds
            createdAt
        }
    }
`

export const getProgramFiltersByProgramId = gql`
    query getProgramFiltersByProgramId($programId: ID!) {
        getProgramFiltersByProgramId(programId: $programId) {
            id
            filterName
            filterConditions {
            column
            condition
            value
            }
        }
    }
    `


export const getRubricsAnsweredQuery = gql`
    query getRubricsAnswered($programId: ID!) {
        getRubricsAnswered(programId: $programId)
    }
`

