import { gql } from '@apollo/client';

export const getUsersQuery = gql`
    query getProgramMembersByProgramId($programId: ID!) {
        getProgramMembersByProgramId(programId: $programId) {
            userId {
                id
                firstName
                lastName
                email
                isPending
                institutionId
                institution
              }
            roles
        }
    }

`


export const getUserById = gql`
    query getUserById($id: ID!){
        getUserById(id: $id) {
            id
            email
            firstName
            lastName
            phone
            token
            verificationToken
            userType
            isVerified
            status
            programId        
            profilePhotoUrl
            institution
            institutionId
            isPending
            isRecommender
        }
    }
`

export const getUserWithAccountUsersById = gql`
    query getUserWithAccountUsersById($userId: ID!) {
        getUserWithAccountUsersById(userId: $userId) {
            id
            email
            firstName
            lastName
            phone
            token
            userId
            verificationToken
            userType
            isVerified
            status
            programId        
            profilePhotoUrl
            institution
            institutionId
            isPending
            isRecommender
            userPremissions
            accountAccessIds {
                id
                email
                firstName
                lastName
                phone
                token
                userId
                verificationToken
                userType
                isVerified
                status
                programId        
                profilePhotoUrl
                institution
                institutionId
                isPending
                isRecommender
                userPremissions  
            }
        }
    }
`