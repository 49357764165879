const allopathicMedicalSchools = [
    'Albany Medical College',
    'Albert Einstein College of Medicine',
    'Baylor College of Medicine',
    'Boston University School of Medicine',
    'Brody School of Medicine at East Carolina University',
    'California Northstate University College of Medicine',
    'California University of Science and Medicine - School of Medicine',
    'Carle Illinois College of Medicine',
    'Case Western Reserve University School of Medicine',
    'Central Michigan University College of Medicine',
    'Charles E. Schmidt College of Medicine at Florida Atlantic University',
    'Chicago Medical School of Rosalind Franklin University of Medicine & Science',
    'Columbia University Vagelos College of Physicians and Surgeons',
    'Cooper Medical School of Rowan University',
    'Creighton University School of Medicine',
    'CUNY School of Medicine',
    'Dalhouse University Faculty of Medicine',
    'Donald and Barbara Zucker School of Medicine at Hofstra/Northwell',
    'Drexel University College of Medicine',
    'Duke University School of Medicine',
    'East Tennessee State University James H. Quillen College of Medicine',
    'Eastern Virginia Medical School',
    'Emory University School of Medicine',
    'Faculty of Medicine Universite Laval',
    'Western University, Canada',
    'Florida International University Herbert Wertheim College of Medicine',
    'Florida State University College of Medicine',
    'Frank H. Netter MD School of Medicine at Quinnipiac University',
    'Geisel School of Medicine at Dartmouth',
    'Geisinger Commonwealth School of Medicine',
    'George Washington University School of Medicine and Health Sciences',
    'Georgetown University School of Medicine',
    'Hackensack-Meridian School of Medicine',
    'Harvard Medical School',
    'Howard University College of Medicine',
    'Icahn School of Medicine at Mount Sinai',
    'Indiana University School of Medicine',
    'Jacobs School of Medicine and Biomedical Sciences at the University of Buffalo',
    'Johns Hopkins University School of Medicine',
    'Kaiser Permanente Bernard J. Tyson School of Medicine',
    'Keck School of Medicine at the University of Southern California',
    'Lewis Katz School of Medicine at Temple University',
    'Loma Linda University School of Medicine',
    'Louisiana State University School of Medicine in New Orleans',
    'Louisiana State University School of Medicine in Shreveport',
    'Loyola University Chicago Stritch School of Medicine',
    'Marshall University Joan C. Edwards School of Medicine',
    'Max. Ready College of Medicine, Ready Faculty of Health Sciences, University of Manitoba',
    'Mayo Clinic Alix School of Medicine',
    'McGill University Faculty of Medicine',
    'Dalhousie University',
    'McGovern Medical School at the University of Texas Health Science Center at Houston',
    'McMaster University Michael G. DeGroote School of Medicine',
    'Medical College of Georgia at Augusta University',
    'Medical College of Wisconsin',
    'Medical University of South Carolina College of Medicine',
    'Meharry Medical College',
    'Memorial University of Newfoundland Faculty of Medicine',
    'Mercer University School of Medicine',
    'Michigan State University College of Human Medicine',
    'Morehouse School of Medicine',
    'New York Medical College',
    'New York University Grossman School of Medicine',
    'New York University Long Island School of Medicine',
    'Northeast Ohio Medical University',
    'Northern Ontario School of Medicine',
    'Northwestern University Feinberg School of Medicine',
    'Nova Southeastern University Dr. Kiran C. Patel College of Allopathic Medicine',
    'Oakland University William Beaumont School of Medicine',
    'Ohio State University College of Medicine',
    'Oregon Health & Science University School of Medicine',
    'Pennsylvania State University College of Medicine',
    'Perelman School of Medicine at the University of Pennsylvania',
    'Ponce Health Sciences University School of Medicine',
    `Queen's University Faculty of Health Sciences`,
    'Renaissance School of Medicine at Stony Brook University',
    'Robert Larner, MD College of Medicine at the University of Vermont',
    'Rush Medical College of Rush University Medical Center',
    'Rutgers New Jersey Medical School',
    'Rutgers, Robert Wood Johnson Medical School',
    'St. Louis University School of Medicine',
    'San Juan Bautista School of Medicine',
    'Sidney Kimmel Medical College at Thomas Jefferson University',
    'Southern Illinois University School of Medicine',
    'Stanford University School of Medicine',
    'State University of New York Downstate Medical Center College of Medicine',
    'State University of New York Upstate Medical University',
    'Anne Burnett Marion School of Medicine at Texas Christian University',
    'Texas A&M Health Science Center College of Medicine',
    'Texas Tech University Health Sciences Center Paul L. Foster School of Medicine',
    'Texas Tech University Health Sciences Center School of Medicine',
    'The University of Texas Health Science Center at San Antonio Joe R. and Teresa Lozano Long School of Medicine',
    'The University of Toledo College of Medicine and Life Sciences',
    'The University of Western Ontario - Schulich School of Medicine & Dentistry',
    'The Warren Alpert Medical School of Brown University',
    'Tufts University School of Medicine',
    'Tulane University School of Medicine',
    'Uniformed Services University of the Health Sciences F. Edward Hebert School of Medicine',
    'Universidade Central del Caribe School of Medicine',
    'University de Montreal Faculty of Medicine',
    'University de Sherbrooke Faculty of Medicine',
    'University of Alabama School of Medicine',
    'University of Alberta Faculty of Medicine and Dentistry',
    'University of Arizona College of Medicine',
    'University of Arizona College of Medicine - Phoenix',
    'University of Arkansas for Medical Sciences College of Medicine',
    'University of British Columbia Faculty of Medicine',
    'University of Calgary Cumming School of Medicine',
    'University of California Davis School of Medicine',
    'University of California Irvine School of Medicine',
    'University of California Los Angeles David Geffen School of Medicine',
    'University of California Riverside School of Medicine',
    'University of California San Diego School of Medicine',
    'University of California San Francisco School of Medicine',
    'University of Central Florida College of Medicine',
    'University of Chicago Pritzker School of Medicine',
    'University of Cincinnati College of Medicine',
    'University of Colorado School of Medicine',
    'University of Connecticut School of Medicine',
    'University of Florida College of Medicine',
    'University of Hawaii, John A. Burns School of Medicine',
    'University of Houston College of Medicine',
    'University of Illinois College of Medicine - Chicago',
    'University of Illinois College of Medicine - Rockford',
    'University of Illinois College of Medicine - Peoria',
    'University of Iowa Roy J. and Lucille A. Carver College of Medicine',
    'University of Kansas School of Medicine',
    'University of Kentucky College of Medicine',
    'University of Louisville School of Medicine',
    'University of Maryland School of Medicine',
    'University of Massachusetts Medical School',
    'University of Miami Leonard M. Miller School of Medicine',
    'University of Michigan Medical School',
    'University of Minnesota Medical School',
    'University of Mississippi School of Medicine',
    'University of Missouri-Columbia School of Medicine',
    'University of Missouri-Kansas City School of Medicine',
    'University of Nebraska College of Medicine',
    'University of Nevada Las Vegas School of Medicine',
    'University of Nevada Reno School of Medicine',
    'University of New Mexico School of Medicine',
    'University of North Carolina at Chapel Hill School of Medicine',
    'University of North Dakota School of Medicine and Health Sciences',
    'University of Oklahoma College of Medicine',
    'University of Ottawa Faculty of Medicine',
    'University of Pittsburgh School of Medicine',
    'University of Puerto Rico School of Medicine',
    'University of Rochester School Medicine and Dentistry',
    'University of Saskatchewan College of Medicine',
    'University of South Alabama College of Medicine',
    'University of South Carolina College of Medicine',
    'University of South Carolina School of Medicine Greenville',
    'University of South Dakota, Sanford School of Medicine',
    'University of Tennessee Health Science Center College of Medicine',
    'University of Texas at Austin Dell Medical School',
    'University of Texas Medical Branch School of Medicine',
    'University of Texas Rio Grande Valley School of Medicine',
    'University of Texas Southwestern Medical School',
    'University of Toronto Faculty of Medicine',
    'Toronto Metropolitan University',
    'University of Utah School of Medicine',
    'University of Virginia School of Medicine',
    'University of Washington School of Medicine',
    'University of Wisconsin School of Medicine and Public Health',
    'University of South Florida Health Morsani College of Medicine',
    'Vanderbilt University School of Medicine',
    'Virginia Commonwealth University School of Medicine',
    'Virginia Tech Carilion School of Medicine',
    'Wake Forest School of Medicine',
    'Washington State University Elon S. Floyd College of Medicine',
    'Washington University in St. Louis School of Medicine',
    'Wayne State University School of Medicine',
    'Weill Cornell Medicine',
    'West Virginia University School of Medicine',
    'Western Michigan University Homer Stryker MD School of Medicine',
    'Wright State University Boonshoft School of Medicine',
    'Yale School of Medicine',
    'University of Texas at Tyler',
];


const osteopathicMedicalSchools = [
    'Alabama College of Osteopathic Medicine',
    'Arizona College of Osteopathic Medicine of Midwestern University',
    'Arkansas College of Osteopathic Medicine',
    'A.T. Still University of Health Sciences Kirksville College of Osteopathic Medicine',
    'A.T. Still University of Health Sciences School of Osteopathic Medicine in Arizona',
    'Burrell College of Osteopathic Medicine at New Mexico State University',
    'California Health Sciences University College of Osteopathic Medicine',
    'Campbell University Jerry M. Wallace School of Osteopathic Medicine',
    'Chicago College of Osteopathic Medicine of Midwestern University',
    'Des Moines University College of Osteopathic Medicine',
    'Edward Via College of Osteopathic Medicine - Auburn Campus',
    'Edward Via College of Osteopathic Medicine - Carolinas Campus',
    'Edward Via College of Osteopathic Medicine - Louisiana Campus',
    'Edward Via College of Osteopathic Medicine - Virginia Campus',
    'Idaho College of Osteopathic Medicine',
    'Kansas City University College of Osteopathic Medicine',
    'Lake Erie College of Osteopathic Medicine',
    'Liberty University College of Osteopathic Medicine',
    'Lincoln Memorial University - DeBusk College of Osteopathic Medicine',
    'Marian University College of Osteopathic Medicine',
    'Michigan State University College of Osteopathic Medicine',
    'New York Institute of Technology College of Osteopathic Medicine',
    'Noorda College of Osteopathic Medicine',
    'Nova Southeastern University Dr. Kiran C Patel College of Osteopathic Medicine',
    'Ohio University Heritage College of Osteopathic Medicine',
    'Oklahoma State University Center for Health Sciences College of Osteopathic Medicine',
    'Pacific Northwest University of Health Sciences College of Osteopathic Medicine',
    'Philadelphia College of Osteopathic Medicine',
    'Philadelphia College of Osteopathic Medicine Georgia Campus',
    'Rocky Vista University College of Osteopathic Medicine',
    'Rowan University School of Osteopathic Medicine',
    'Sam Houston State University College of Osteopathic Medicine',
    'Touro College of Osteopathic Medicine - Harlem',
    "Touro College of Osteopathic Medicine - Middletown",
    'Touro University College of Osteopathic Medicine - California',
    'Touro University Nevada College of Osteopathic Medicine',
    'University of the Incarnate Word School of Osteopathic Medicine',
    'University of New England College of Osteopathic Medicine',
    'University of North Texas Health Science Center at Fort Worth - Texas College of Osteopathic Medicine',
    'University of Pikeville - Kentucky College of Osteopathic Medicine',
    'West Virginia School of Osteopathic Medicine',
    'Western University of Health Sciences College of Osteopathic Medicine of the Pacific',
    'William Carey University College of Osteopathic Medicine'
];

export { allopathicMedicalSchools, osteopathicMedicalSchools };