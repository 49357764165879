import React, {useState} from 'react';
import { useTable, useSortBy, useRowSelect } from 'react-table';

const GeneralTable = ({columns, data, onSelectionChange, onSelectedApplication, sortData }) => {

        const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { selectedRowIds },
      } = useTable({ columns, data }, useSortBy, useRowSelect);

      const [prevSelectedRows, setPrevSelectedRows] = useState([]);



      console.log("data received in table =>", data)

      React.useEffect(() => {
        const selectedRows = rows.filter(row => selectedRowIds[row.id]);
        const simplifiedSelectedRows = selectedRows.map(row => row.original); // Only keep the original row data
    
        console.log("selectedRows =>", selectedRows, prevSelectedRows)
        // Only call onSelectionChange if the selected rows have actually changed
        if (JSON.stringify(simplifiedSelectedRows) !== JSON.stringify(prevSelectedRows)) {
            onSelectionChange(simplifiedSelectedRows);
            setPrevSelectedRows(simplifiedSelectedRows);
        }
    }, [onSelectionChange, rows, selectedRowIds]);


      const handleClickCell = (row) => {
          onSelectedApplication(row);
          console.log("row clicked",row);
      }

      const sortClicked = (direction, column) => {
        console.log("sorting =>", column.id, direction)
        sortData(column.id, direction)
      }
    

      return (
        <div style={{ overflowX: 'auto', width: '42vw' }}>
          <table className='table-programs-list' {...getTableProps()}>
          <thead className='tHead-programs-list'>
              {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                (column.id !== 'greenDot' && column.id !== 'view' && column.id !=='selection') ?
                <th style={{ lineHeight: '4vh', width:'5vw'}} className='table-header-title'>
                  {column.render('Header')}
                </th>
                :
                <th className='table-header-title' {...column.getHeaderProps()} style={{width:'5vw'}}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                const rowStyle = row.values.userName === "Pending Invitation" ? {background: 'gainsboro', fontStyle:'italic', fontWeight:'bold'} : {height: '4vh', width:'5vw'};
                return (
                  <tr className='programs-list-table-row table-row-height' {...row.getRowProps()} style={rowStyle}>
                      {row.cells.map(cell => (
                          <td style={{}}className='programs-list-table-row-text' onClick={() => handleClickCell(row.original)} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
}

export default GeneralTable;