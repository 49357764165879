import { useState } from 'react';
import logo from '../../../../assets/imgs/logo.svg';
import Spinner from '../../../common/spinner';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import packageInfo from '../../../../../package.json';


const DirectAccessLinkModal = ({ isOpen, onClose, user, refetchUsers}) => {
    const [loadingLink, setLoadingLink] = useState(false);
    const [link, setLink] = useState(null);
    const [copied, setCopied] = useState(false);


    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
    };

    const generateLink = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_onetimelogin_token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'App-Version': packageInfo.version
                },
                body: JSON.stringify({
                   id: user.id,
                }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log(data); // Handle success response
            const linkFromToken = data.oneTimeLoginLink
            setLink(linkFromToken)
        }catch(err) {

        }
    }



    if(!isOpen) return null;
    return (
        <div className="modal-backdrop">
            <div className="add-field-modal">
                <button className='close-icon' onClick={onClose}>X</button>
                <div className='add-field-modal-header' style={{ marginTop: '3vh'}}>
                    <h2>Direct Access Login Link</h2>
                    <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
                </div>
                <div>
                    <button style={{ marginTop:'3vh', marginBottom: '4vh' }} className='green-button' onClick={generateLink}>Generate direct login link</button>
                    { loadingLink && <Spinner />}
                    {
                        link &&
                        <div>
                            <label>{link}</label>
                            <CopyToClipboard text={link} onCopy={handleCopy}>
                                <button className="green-button" style={{ cursor: 'pointer', marginTop: '3vh' }}>Copy to clipboard</button>
                               
                            </CopyToClipboard>
                            {copied && <span style={{ color: '#145388' }}>Link copied!</span>}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default DirectAccessLinkModal;