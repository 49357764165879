import React, { useState, useContext } from 'react';
import { Formik, Form, Field, ErrorMessage,FieldArray } from 'formik';
import { FormContext } from '../ApplicationForm';
import validationSchema from './workExpValidationSchema';


function countWords(input) {
  if (input) {
    const words = input.trim().split(/\s+/);
    return words.length;
  }
  return 0;
}

const AddRefModal = ({isEdit}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null); 
  const { values, setStep, step, shortQuestions, setFieldValue, isSubmitting } = useContext(FormContext);
  const [initialValues, setInitialValues] = useState({name:'',  company:'', role:'', phone:'', email:''})

  console.log('values in da addRef modal', values);


  const removeWorkExp = (index) => {
    let updatedWorkExperiences = values.references.filter((_, i) => i !== index);
    setFieldValue('references', updatedWorkExperiences);
  };


          // workExperiences

          const addOrEditWorkExp = (workExp) => {
            let currentWorkExperiences = [...values.references];
            // Check if there is only one element and if all its values are empty strings
            if (editingIndex !== null) {
              currentWorkExperiences[editingIndex] = workExp; // Replace the element at the editing index
            }else if (currentWorkExperiences.length === 1 && Object.values(currentWorkExperiences[0]).every(value => value === '')) {
              currentWorkExperiences = [workExp]; // Replace the empty element with the new element
            } else {
              currentWorkExperiences.push(workExp); // Add the new element
            }
           
            setFieldValue('references', currentWorkExperiences);
            setIsModalOpen(false);
            setInitialValues({name:'',  company:'', role:'', phone:'', email:''})
            setEditingIndex(null);
          }


          const editWorkExp = (index) => {
            setEditingIndex(index); // Set the current editing index
            setInitialValues(values.references[index])
            setIsModalOpen(true);

          };



  return (
    <>
   {values.references.map((workExperience, index) => (
  <div className='application-form-field-group two' style={{flexDirection:'row', gap:'2vw', alignItems:'baseline'}}>
      <>
      <div style={{display:'flex', width:'100%'}}>
        <p style={{
          fontFamily: 'Montserrat',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '18px',
          color: 'rgb(71, 71, 71)',
          lineHeight: '27px',
          marginBottom: '12px',
          textAlign: 'justify',
          width:'76%'
        }}>
         <div className="application-view-main-education-degrees-section" style={{display:'flex', flexDirection:'row'}}>
                    <div className="institution-and-degree" style={{marginBottom:'2vh', textAlign:'justify', marginRight:'1vw', display:'flex'}}>
                        <div className="institution" style={{marginLeft:'1vw', fontSize:'18px'}}>{workExperience.company}</div>
                        <div className="degree" style={{marginLeft:'1vw', fontWeight:'500', fontSize:'18px'}}>{workExperience.role}</div>
                        <div className="degree" style={{marginLeft:'1vw', fontSize:'18px'}}>Reference: {workExperience.name},  {workExperience.email}</div>
                        <div className="degree" style={{marginLeft:'1vw', fontSize:'18px'}}>{workExperience.phone}</div>
                    </div>
                </div>
        </p>
        <div style={{display:'flex', flexDirection:'row', gap:'1vw'}}>
        <button className='white-button' disabled={!isEdit} style={{flexGrow:'unset', flexBasis:'unset', padding:'8px 30px', alignSelf:'center'}} onClick={() => removeWorkExp(index)}>Remove</button>
        <button className='green-button' disabled={!isEdit} style={{alignSelf:'center'}} onClick={() => editWorkExp(index)}>Edit</button>
        </div>
      </div>
      </>


    </div>
    ))}



    <button className='green-button'  style={{width:'100%', marginBottom:'3vh'}} onClick={()=>setIsModalOpen(true)} disabled={!isEdit}>Add Reference</button>
    {isModalOpen && (
  <div className='modal-backdrop'>
    <div className='add-research-modal' style={{minHeight:'71vh', maxHeight:'99vh'}}>
      <div className='add-research-modal-header'>
        <h3 style={{textAlign:'center'}}>Add Reference</h3>
      </div>
      <div className='add-research-modal-body'>
      <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {formikProps => (
        <Form style={{display:'flex', flexDirection:'column'}}>
          <div style={{ height: '56vh', overflowY: 'auto', gap:'2vh'}}>
          <div className="col" style={{ marginBottom: '2vh'}}>

                    {/* name:'',  company:'', role:'', phone:'', email:'' */}

                     {/* <label className="four-group-inner-header" htmlFor={`workExperiences.${index}.employer`}>Employer</label> */}
                        <Field
                        disabled={!isEdit}
                        name='name'
                        placeholder="Name"
                        type="text"
                        className='application-form-standard-input'
                        style={{width:'100%'}}
                        />
                        <ErrorMessage
                        name={`name`}
                        component="div"
                        className="field-error"
                        style={{width:'23vw'}}
                        />
                 </div>
                 <div className="col" style={{ marginBottom: '2vh'}}>
                        {/* <label className='four-group-inner-header' htmlFor={`workExp.${index}.title`}>Title</label> */}
                            <Field
                            disabled={!isEdit}
                            name={`company`}
                            placeholder="Company"
                            type="text"
                            className='application-form-standard-input'
                            style={{width:'100%'}}
                            />
                            <ErrorMessage
                            name={`company`}
                            component="div"
                            className="field-error"
                            />
                </div>
                <div className="col" style={{ marginBottom: '2vh'}}>
                        {/* <label className='four-group-inner-header' htmlFor={`workExperiences.${index}.mentorName`}>Mentor Name</label> */}
                            <Field
                            disabled={!isEdit}
                            name={`role`}
                            placeholder="Role"
                            type="text"
                            className='application-form-standard-input'
                            style={{width:'100%'}}
                            />
                            <ErrorMessage
                            name={`role`}
                            component="div"
                            className="field-error"
                            />
                </div>
                <div className="col" style={{ marginBottom: '2vh'}}>
                        {/* <label className='four-group-inner-header' htmlFor={`workExperiences.${index}.mentorEmail`}>Mentor Email</label> */}
                            <Field
                            disabled={!isEdit}
                            name={`email`}
                            placeholder="Email"
                            type="email"
                            className='application-form-standard-input'
                            style={{width:'100%'}}
                            />
                            <ErrorMessage
                            name={`email`}
                            component="div"
                            className="field-error"
                            />
                </div>
                <div className="col" style={{ marginBottom: '2vh'}}>
                        {/* <label className='four-group-inner-header' htmlFor={`workExperiences.${index}.mentorEmail`}>Mentor Email</label> */}
                            <Field
                            disabled={!isEdit}
                            name={`phone`}
                            placeholder="Phone Number"
                            type="phone"
                            className='application-form-standard-input'
                            style={{width:'100%'}}
                            />
                            <ErrorMessage
                            name={`phone`}
                            component="div"
                            className="field-error"
                            />
                </div>





          </div>
          <div style={{ display:'flex', gap:'2vw', alignSelf:'center', marginTop: '2vh'}}>
            { console.log("", isEdit, formikProps)}
            <button className='green-button' disabled={!isEdit || formikProps.isValid} onClick={()=>addOrEditWorkExp(formikProps.values)} >Add</button>
            <button className='white-button' disabled={!isEdit} onClick={()=>setIsModalOpen(false)}>Cancel</button>
          </div>
        </Form>
      )}
    </Formik>

{/* { category: '', title: '', authors:'', journal:'', datePublished:'', pubMedId:'', isFirstAuthor:'', significantResearchExperience:false, meeting:'', meetingDate:'', order:0} */}
      </div>
    </div>
  </div>
)}
    </>
)
}

export default AddRefModal;