import React, { useState, useEffect,useContext } from 'react';
import './index.css'; // Assuming styles are in Home.css
import LeftNavBar from './medSchoolComponents/LeftNavBar';
import { useQuery } from '@apollo/client';
import { InstitutionIdContext } from './institutionIdContext';
import { UserContext } from '../../context/userContext';
import Spinner from '../common/spinner';
import Applicants from './applicants';
import Tokens from './tokens';
import Advisors from './advisors';
import Analytics from './analytics';
import Exports from './exports';
import Setup from './setup';
import UserGuide from './userGuide';
import ContactUs from '../contactUs';
import MyTeam from './teams';
import Settings from './settings';
import {getInstitutionByIdQuery} from './queries';
import AccountAccess from '../faculty/facultyComponents/accountAccess';



function MedSchool() {
    const { user } = useContext(UserContext);
    const [page, setPage] = useState('applicants');
    const [userName, setUserName] = useState('');
    const [shouldRefreshApplicants, setShouldRefreshApplicants] = useState(false);
    const [institutionName, setInstitutionName] = useState('');
    const { loading, error, data } = useQuery(getInstitutionByIdQuery, {
        variables: { id: user.institutionId },
    });

    useEffect(() => {
        if (data) {
            console.log("Query data =>", data.getInstitutionById.name);
            setInstitutionName(data.getInstitutionById.name);
        }
    }, [data]);




    return (
        <InstitutionIdContext.Provider value={{ id: user.institutionId, name: institutionName }}>
            <main className="container mx-auto px-6 py-4" style={{paddingTop:'0', paddingBottom:'0', paddingRight:'0'}}>
                <LeftNavBar userName={userName} userRoles={user.userPremissions} page={page} setPage={setPage}/>
                { page === 'applicants' && <Applicants setPage={setPage} shouldRefreshApplicants={shouldRefreshApplicants} setShouldRefreshApplicants={setShouldRefreshApplicants} /> }
                { page === 'tokens' && <Tokens setPage={setPage} setShouldRefreshApplicants={setShouldRefreshApplicants} /> }
                { page === 'team' && <MyTeam setPage={setPage} /> }
                { page === 'advisors' && <Advisors setPage={setPage} />}
                { page === 'analytics' && <Analytics setPage={setPage} /> }
                { page === 'exports' && <Exports setPage={setPage} /> }
                { page === 'setup' && <Setup setPage={setPage} /> }
                {page==='accountAccess'&&(<AccountAccess setPage={setPage}/>)}
                {page==='help'&&(<ContactUs isNested={true} user={user} />)}
                { page === 'userGuide' && <UserGuide setPage={setPage} /> }
                { page === 'settings' && <Settings setPage={setPage} /> }
            </main>
        </InstitutionIdContext.Provider>

    )
}

export default MedSchool;