import React,{useState} from "react";
import "./index.css";
import eyeClosed from "../../../../../assets/imgs/eyeClose.svg";
import openEye from "../../../../../assets/imgs/openEye.svg"
import { handleError } from "@apollo/client/link/http/parseAndCheckHttpResponse";

const ScreenedSectionsBar = ({ setSection, section,data,setData}) => {
    const [currentSection, setCurrentSection] = useState(section);


    // Use setSection to update the current section
    const handleSectionChange = (section) => {
        console.log(section)
        setSection(section);
    }

const selectedDesign = {
    background: '#1453884d',
    border: '2px solid #30a384',
    cursor: 'pointer',
    width: '15vw'
}
const unselectedDesign = {
    cursor: 'pointer',
    width: '15vw'
}

    return (

        <div>
            <div className="screened-sections-bar">


                <div className={data.sections.personalInfo?"screened-sections-bar-item":"screened-sections-bar-item screened"}
                style={currentSection==='personalInfo'?selectedDesign:unselectedDesign}
                onClick={()=>handleSectionChange('personalInfo')}>
                <div className="step-indicator-number">1</div>
                    <label style={{cursor:'pointer'}} onClick={()=>setCurrentSection('personalInfo')}>Personal Information</label>
                    <img src={data.sections.personalInfo ? openEye : eyeClosed} alt="eye icon" onClick={()=>setData({...data, sections: {...data.sections, personalInfo:!data.sections.personalInfo}})}/>
                    </div>


                    <div className={data.sections.residencyApplication?"screened-sections-bar-item":"screened-sections-bar-item screened"} 
                    style={currentSection==='residencyApplication'?selectedDesign:unselectedDesign}
                    onClick={()=>handleSectionChange('residencyApplication')}>
                <div className="step-indicator-number">2</div>
                    <label style={{cursor:'pointer'}} onClick={()=>setCurrentSection('residencyApplication')}>Residncy Application</label>
                    <img src={data.sections.residencyApplication ? openEye : eyeClosed} alt="eye icon" onClick={()=>setData({...data, sections:{...data.sections, residencyApplication:!data.sections.residencyApplication}})}/>
                    </div>



                    <div 
                    className={data.sections.personalStatement?"screened-sections-bar-item":"screened-sections-bar-item screened"} 
                    style={currentSection==='personalStatement'?selectedDesign:unselectedDesign}
                    onClick={()=>handleSectionChange('personalStatement')}>
                <div className="step-indicator-number">3</div>
                    <label style={{cursor:'pointer'}} onClick={()=>setCurrentSection('personalStatement')}>Personal Statement</label>
                    <img src={data.sections.personalStatement ? openEye : eyeClosed} alt="eye icon" onClick={()=>setData({...data,sections:{ ...data.sections,personalStatement:!data.sections.personalStatement}})}/>
                    </div>


                
            


            </div>
            <div className="screened-sections-bar" style={{marginTop: '2vh', height:'10vh'}}>
                <div 
                    className={data.sections.documents?"screened-sections-bar-item":"screened-sections-bar-item screened"} 
                    style={currentSection==='documents'?selectedDesign:unselectedDesign}
                    onClick={()=>handleSectionChange('documents')}>
                <div className="step-indicator-number">4</div>
                    <label style={{cursor:'pointer'}} onClick={()=>setCurrentSection('documents')}>Documents</label>
                    <img src={data.sections.documents ? openEye : eyeClosed} alt="eye icon" onClick={()=>setData({...data, sections:{...data.sections,documents:!data.sections.documents} })}/>
                    </div>


                <div 
                    className={data.sections.lors?"screened-sections-bar-item":"screened-sections-bar-item screened"} 
                    style={currentSection==='lors'?{...selectedDesign, width: '16vw'}:{...unselectedDesign, width: '16vw'}}
                    onClick={()=>handleSectionChange('lors')}>
                <div className="step-indicator-number">5</div>
                    <label style={{cursor:'pointer'}} onClick={()=>setCurrentSection('lors')}>Letters of Recommendation</label>
                    <img src={data.sections.lors ? openEye : eyeClosed} alt="eye icon" onClick={()=>setData({...data,sections:{...data.sections,lors:!data.sections.lors} })}/>
                    </div>
            </div>
        </div>
   

    )};

    export default ScreenedSectionsBar;