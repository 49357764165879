import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import logo from '../../../assets/imgs/logo.svg';
import { useQuery } from "@apollo/client"; 
import {useDropzone} from 'react-dropzone';
import packageInfo from '../../../../package.json';


const MspeUpload = () => {
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop: async (acceptedFiles) => {
            console.log("acceptedFiles", acceptedFiles)

            const file = acceptedFiles[0];
            console.log("file =>", file)
            if(file) {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('mspeId', id);
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/upload_mspe_file`, {
                  method: 'POST',
                  headers: { 'App-Version': packageInfo.version },
                  body: formData
                });
                const data = await response.json();
                
            }
        },
        accept: 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document' ,
        multiple: false  // This ensures that multiple file selection is disabled

    });

    const { id } = useParams();

    console.log("id =>", id)


   return (
    <div className='faculty-home-container' style={{width:'100%',marginLeft:'auto',marginRight:'auto'}}>
           <ellipse className='ellipseDesignTop1' />
        <ellipse className='ellipseDesignTop2' />
        <ellipse className='ellipseDesignTop3' />
        <ellipse className='ellipseDesignTop4' />
        <div className="faculty-home-header">
            <img src={logo} alt="logo" style={{float:'right'}}/>
            <h2 className="faculty-home-header-title">UPLOAD MSPE DOCUMENTS</h2>
            {/* <p className="faculty-home-header-subtitle"> Please fill in you details below to finalize your account creation</p> */}
        </div>
        <seperator className="faculty-home-header-seperator"/>
        <div className="faculty-home-body">

        <div className="faculty-home-body">
            <p className="specific-rec-title">Please upload your MSPE file here</p>

            <div className={ isDragActive ?'file-drop-zone hover':"file-drop-zone"} {...getRootProps()}>
                <input multiple={false}/>
                {
                    isDragActive ?
                    <p>Drop to upload the file</p> :
                    <p>Drag & drop your MSPE file here, or click to select files</p>
                }
            </div>
                <div className="specific-rec-submit">
                    <button className='green-button' style={{padding:'1vh 1vw', width:'76vw'}}>Upload Document</button>
                </div>
        </div>

        </div>
    </div>
   )




}


export default MspeUpload;