import React from "react";


const SaveFailModal = ({setIsSaveFailModalOpen, savingError}) => {


    const handleClose = () => {
        setIsSaveFailModalOpen(false);
    }


    return (
    <div className='modal-backdrop'>
    <div className='add-graduation-modal' style={{width:'40vw', minHeight:'40vh'}}>
        <div style={{fontFamily: 'Montserrat', fontSize: '1.5rem', textAlign:'center'}}>
            <div>Save Failed</div>
        </div>
        <div style={{fontFamily: 'Montserrat', fontSize: '1rem', marginTop: '3vh', textAlign:'center'}}>
            Error: {savingError.message}
        </div>
        <div style={{fontFamily: 'Montserrat', fontSize: '1rem', marginTop: '3vh', textAlign:'center'}}>
           Please try again.
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between', alignSelf:'center', marginTop:'5vh'}}>
            <button className="green-button" style={{ width: '15vw', alignSelf:'center'}} onClick={handleClose}>Confirm</button>
        </div>
    </div>
</div>
    )
}   

export default SaveFailModal;