import React,{useState,useContext, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import {getApplicationsByApplicationIds} from './graphql';
import {useQuery,useLazyQuery} from '@apollo/client';
import ExmPDF from './index';
import Spinner from '../common/spinner';


import ApplicationViewMain from '../faculty/facultyComponents/applicationViewForPrint';
import { UserContext } from '../../context/userContext';

const PdfBackend = () => {
  const {user} = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(true);
    const [application, setApplication] = useState(null);
   


    const { token } = useParams();





const { data } = useQuery(getApplicationsByApplicationIds, {
  variables: { id: token },
  fetchPolicy: 'network-only',
  onCompleted: () => {
      console.log("data =>", data.getApplicationById)
      let appToPush = {
        applicantId: data.getApplicationById.userId._id,
        masterApplicationId: data.getApplicationById.masterApplicationId._id,
        id: data.getApplicationById.id,
        firstName: data.getApplicationById.userId.firstName??'',
        lastName: data.getApplicationById.userId.lastName??'',
        email: data.getApplicationById.userId.email??'',
        phone: data.getApplicationById.userId.phone??'',
        createdAt: data.getApplicationById.createdAt??'',
        dateOfBirth: data.getApplicationById.userId.applicationInformation.dateOfBirth??'',
        coupleMatch: data.getApplicationById.userId.applicationInformation.coupleMatch === 'yes' ? "Yes" : "No",
        partnerField: data.getApplicationById.userId.applicationInformation.partnerFeild??'',
        misdemeanorOrFelony: data.getApplicationById.userId.applicationInformation.misdemeanorOrFelony??'',
        misdemeanorOrFelonyDetails: data.getApplicationById.userId.applicationInformation.misdemeanorOrFelonyDetails??'',
        nrmpId: data.getApplicationById.userId.applicationInformation.nrmpId??'',
        workAuthorization: data.getApplicationById.userId.applicationInformation.workAuthorization === "U.S. Citizen, National, or Legal Permanent Resident (e.g., Refugee, Asylee)" ? "Yes" : "No"??'',
        gender: data.getApplicationById.userId.applicationInformation.gender??'',
        otherRaceEthnicity: data.getApplicationById.userId.applicationInformation.otherRaceEthnicity??'',
        otherWorkAuthorization: data.getApplicationById.userId.applicationInformation.otherWorkAuthorization??'',
        partnerName: data.getApplicationById.userId.applicationInformation.partnerName??'',
        dateOfBirth: data.getApplicationById.userId.applicationInformation.dateOfBirth??'',
        hometown: data.getApplicationById.userId.applicationInformation.homeTown??'',
        address: data.getApplicationById.userId.applicationInformation.homeAddress??'',
        ethnicity: data.getApplicationById.userId.applicationInformation.ethnicity??'',
        medicalSchoolName: data.getApplicationById.userId.applicationInformation.medicalSchoolName??'',
        aoaStatus: data.getApplicationById.userId.applicationInformation.aoaStatus??'',
        goldHumanism: data.getApplicationById.userId.applicationInformation.goldHumanism??'',
        firstAuthorPublications: data.getApplicationById.userId.applicationInformation.firstAuthorPublications??'',
        peerReviewedPublications: data.getApplicationById.userId.applicationInformation.peerReviewedPublications??'',
        posterPresentations: data.getApplicationById.userId.applicationInformation.posterPresentations??'',
        podiumPresentations: data.getApplicationById.userId.applicationInformation.podiumPresentations??'',
        aamc: data.getApplicationById.userId.applicationInformation.aamc??'',
        previouslyAppliedToSpeciality: data.getApplicationById.masterApplicationId.previouslyAppliedToSpecialty??'',
        isSignal: data.getApplicationById.isSignal === true ? "Yes" : "No"??'',
        usmleStep1Score: data.getApplicationById.scoreId.usmleStep1Score,
        usmleStep2Score: data.getApplicationById.scoreId.usmleStep2Score??'',
        usmleStep3Score: data.getApplicationById.scoreId.usmleStep3Score??'',
        comlexLevel1Score: data.getApplicationById.scoreId.comlexLevel1Score,
        comlexLevel2Score: data.getApplicationById.scoreId.comlexLevel2Score??'',
        user: data.getApplicationById.userId??'',
        researchExperience: data.getApplicationById.masterApplicationId.researchExperience??'',
        carryingOutResponsibilities: data.getApplicationById.masterApplicationId.carryingOutResponsibilities??'',
        carryingOutResponsibilitiesElaboration: data.getApplicationById.masterApplicationId.carryingOutResponsibilitiesElaboration??'',
        workExperiences: data.getApplicationById.masterApplicationId.workExperiences??'',
        meaningfulExperiences: data.getApplicationById.masterApplicationId.meaningfulExperiences??'',
        hobbiesAndInterests: data.getApplicationById.masterApplicationId.hobbiesAndInterests??'',
        shortAnswerQuestions: data.getApplicationById.masterApplicationId.shortAnswerQuestions??'',
        personalStatement: data.getApplicationById.masterApplicationId.personalStatement??'',
        signalEssay: data.getApplicationById.signalEssay??'',
        applicationRubrics: data.getApplicationById.applicationRubrics??'',
        watchUsersIds: data.getApplicationById.watchUsersIds??'',
        isLanguages: data.getApplicationById.userId.applicationInformation.isLanguages??'',
        fluentLanguages: data.getApplicationById.userId.applicationInformation.fluentLanguages??'',
        
        previousLastName: data.getApplicationById.userId.applicationInformation.previousLastName??'',
        prefferedName: data.getApplicationById.userId.applicationInformation.prefferedName??'',
        pronouns: data.getApplicationById.userId.applicationInformation.pronouns??'',
        usmleId: data.getApplicationById.userId.applicationInformation.usmleId??'',
        nbomeId: data.getApplicationById.userId.applicationInformation.nbomeId??'',
        aoaId: data.getApplicationById.userId.applicationInformation.aoaId??'',
        permanentAddress: data.getApplicationById.userId.applicationInformation.permanentAddress??'',
        licensureRevoked: data.getApplicationById.userId.applicationInformation.licensureRevoked??'',
        licensureRevokedDetails: data.getApplicationById.userId.applicationInformation.licensureRevokedDetails??'',
        militaryObligation: data.getApplicationById.userId.applicationInformation.militaryObligation??'',
        militaryObligationDetails: data.getApplicationById.userId.applicationInformation.militaryObligationDetails??'',
        otherServiceObligations: data.getApplicationById.userId.applicationInformation.otherServiceObligations??'',
        medicalTrainingInterrupted: data.getApplicationById.userId.applicationInformation.medicalTrainingInterrupted??'',
        medicalTrainingInterruptedDetails: data.getApplicationById.userId.applicationInformation.medicalTrainingInterruptedDetails??'',
        sigmaPhi: data.getApplicationById.userId.applicationInformation.sigmaPhi??'',
    }

setApplication(appToPush);
setIsLoading(false);

  },
  onError: () => {
      setIsLoading(false);
  }
});




return (
  <div>

    {isLoading && <Spinner />}
    {!isLoading && application && <ExmPDF application={application}/>}
  </div>
);
}

export default PdfBackend;