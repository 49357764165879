import React, { useState, useEffect } from 'react';
import { useTable, useSortBy, useGlobalFilter, useRowSelect } from 'react-table';
import magGlass from '../../../../assets/imgs/magGlass.svg';



const ApplicationsTable = ({
  columns,
  data,
  toggleRowSelection,
  toggleSelectAll,
  updateSelectedRows,
  globalFilter,
  setGlobalFilter,
  selectedRowIds // This prop is passed from the parent component
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter: setFilter,
    state: {  sortBy }, // Access globalFilter and sortBy from state
  } = useTable(
    { columns, data,  initialState: { globalFilter },},
    useGlobalFilter, // Add global filter hook for search
    useSortBy, // Enable sorting
    useRowSelect
  );

  useEffect(() => {
    setFilter(globalFilter || undefined);
  }, [globalFilter, setFilter]);

  // Render the select all checkbox in the header
  const renderSelectAllCheckbox = () => (
    <input
      type="checkbox"
      checked={data.every(row => selectedRowIds.includes(row.applicationId))}
      onChange={toggleSelectAll}
      style={{ cursor: 'pointer', accentColor: '#145388' }}
    />
  );

  // Render a checkbox for each row
  const renderRowCheckbox = (row) => (
    <input
      type="checkbox"
      checked={selectedRowIds.includes(row.original.applicationId)}
      onChange={() => toggleRowSelection(row.original.applicationId)}
      style={{ cursor: 'pointer', accentColor: '#145388' }}
    />
  );

  const handleRowClick = (row) => {
    console.log('row:', row);
    updateSelectedRows(row.original);
  };

  return (
    <div style={{ overflowX: 'auto', width: '100%' }}>

      <table {...getTableProps()} className='table-completed-list'>
        <thead className='tHead-completed-list'>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              <th>
                {renderSelectAllCheckbox()}
              </th>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className='table-border-header-title'
                  style={{ width: '15vw', cursor: 'pointer' }}
                >
                  {column.render('Header')}
                  {/* Add sorting indicator */}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ?  ' 🔼'  :' 🔽') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className='completed-list-table-row-text table-row-height'>
                <td>
                  {renderRowCheckbox(row)}
                </td>
                {row.cells.map(cell => {
                  // Check if the column header is 'Applicant Name' and apply the condition
                  return cell.column.id === 'applicantName' ? (
                    <td {...cell.getCellProps()} className='applicant-name-cell' style={{ cursor: 'pointer' }} onClick={() => handleRowClick(row)}>
                      <div style={{ display: 'flex', width: '17vw', alignItems: 'center' }}>
                        <img src={row.original.profilePhotoUrl} alt='magnifying glass' style={{ marginRight: '1vw', height: '7vh', borderRadius: 25 }} />
                        {cell.render('Cell')}
                      </div>
                    </td>
                  ) : (
                    <td {...cell.getCellProps()} className='completed-list-table-row-text'>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ApplicationsTable;
