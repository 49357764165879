import React,{useState, useContext, useEffect, useRef} from "react";
import packageInfo from '../../../../../package.json';
import { UserContext } from '../../../../context/userContext';
import Spinner from "../../../common/spinner";
import ErasTable from './erasTable';

const AdminErasUpload = ({ setPage }) => {
  const { user, setUser } = useContext(UserContext);
  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState([])
  const columns = [
    {
      id: 'fullName',
      Header: "Applicant Name",
      Cell: ({ row }) => (
          <label>{`${row.original.firstName} ${row.original.lastName}`}</label>
      )
    },
    {
      id: 'programName',
      Header: "Program Name",
      Cell: ({ row }) => (
          <label>{`${row.original.program}`}</label>
      )
    },
    {
      id: 'aamc',
      Header: "AAMC Id",
      Cell: ({ row }) => (
          <label>{`${row.original.aamcId}`}</label>
      )
    },
    {
      id: 'usmleId',
      Header: "USMLE Id",
      Cell: ({ row }) => (
          <label>{`${row.original.usmleId}`}</label>
      )
    },
    {
      id: 'dob',
      Header: "Date of Birth",
      Cell: ({ row }) => (
          <label>{`${row.original.dateOfBirth}`}</label>
      )
    },
    {
      id: 'signal',
      Header: "Program Signal",
      Cell: ({ row }) => (
          <label>{`${row.original.programSignal}`}</label>
      )
    },
    {
      id: 'usmleStep1Score',
      Header: "USMLE Step 1 Score",
      Cell: ({ row }) => (
          <label>{`${row.original.usmleStep1Score === undefined ? "" : row.original.usmleStep1Score}`}</label>
      )
    },
    {
      id: 'usmleStep1Status',
      Header: "USMLE Step 1 Status",
      Cell: ({ row }) => (
          <label>{`${row.original.usmleStep1Status === undefined ? "" : row.original.usmleStep1Status}`}</label>
      )
    },
    {
      id: 'usmleStep2Score',
      Header: "USMLE Step 2 Ck Score",
      Cell: ({ row }) => (
          <label>{`${row.original.usmleStep2Score === undefined ? "" :row.original.usmleStep2Score}`}</label>
      )
    },
    {
      id: 'usmleStep3Score',
      Header: "USMLE Step 3 Score",
      Cell: ({ row }) => (
          <label>{`${row.original.usmleStep3Score === undefined ? "": row.original.usmleStep3Score}`}</label>
      )
    },
    {
      id: 'comlexLevel1Score',
      Header: "COMLEX-USA Level 1 Score",
      Cell: ({ row }) => (
          <label>{`${row.original.comlexLevel1Score === undefined ? "" : row.original.comlexLevel1Score}`}</label>
      )
    },
    {
      id: 'comlexLevel2Score',
      Header: "COMLEX-USA Level 2 CE Score",
      Cell: ({ row }) => (
          <label>{`${row.original.comlexLevel2Score === undefined ? "" : row.original.comlexLevel2Score}`}</label>
      )
    },
  ]



    const fileInputRef = useRef(null);
    const uploadFile = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
          console.log('Selected file:', file.name);
          
          const programId = "662a964be8233c2be04275cb"
          // Create a FormData object
          const formData = new FormData();
          console.log("user to send =>", user)
          formData.append('file', file);
          formData.append('programId', programId)
          formData.append('uploaderId', user.userId)
          setLoadingData(true)
          // Upload the file using fetch
          fetch(`${process.env.REACT_APP_SERVER_URL}/upload_eras_file`, {
            method: 'POST',
            headers: {'App-Version': packageInfo.version},
            body: formData,
          })
          .then(response => response.json())
          .then(data => {
            setData(data.erasVerifications)
            setLoadingData(false)
            console.log('File uploaded successfully:', data);
          })
          .catch(error => {
            setLoadingData(false)
            console.error('Error uploading file:', error);
          });
        }
      };


    return (
        <div className='faculty-home-container'>
            <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop2' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="faculty-home-header">
                <h2 className="faculty-home-header-title">ERAS UPLOAD</h2>
            </div>
            <seperator className="faculty-home-header-seperator"/>
            <div style={{ display: 'flex', justifyContent:'flex-end'}}>
                <input style={{ display: 'none'}} type="file" ref={fileInputRef} onChange={handleFileChange} id="fileInput" class="file-input" />
                <button className="green-button" id="uploadButton" onClick={uploadFile}>UPLOAD FILE</button>
            </div>
            {
              loadingData ? 
              <Spinner />
              :
              <ErasTable columns={columns} data={data}/>
            }

        
        </div>
    )
}

export default AdminErasUpload;