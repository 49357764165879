import React, { useState, useContext } from "react";
import {useDropzone} from 'react-dropzone';
import PlasticSurgeryForm from "./plasticSurgeryForm";
import { Document, Page } from 'react-pdf';
import {UserContext} from '../../../../context/userContext';
import packageInfo from '../../../../../package.json';




const SpecificLor = ({ lor, refetchLors, onClose}) => {
    const { user } = useContext(UserContext);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState(null);
    const [numPages, setNumPages] = useState(1);
    const [LoRStage, setLoRStage] = useState(lor.stage);
    const [isSlorEdit, setIsSlorEdit] = useState(lor.stage === 'Submitted'?true:false)
    


    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop: (acceptedFiles) => {
            console.log("acceptedFiles", acceptedFiles)

            const file = acceptedFiles[0];
            console.log("file =>", file)
            if(file) {
                setSelectedFile(file);
            }
        },
        accept: 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document' ,
        multiple: false  // This ensures that multiple file selection is disabled
    });


    const uploadFile = async () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('lorId', lor.id);
            formData.append('userId', user.userId)
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/upload_lor_file_admin`, {
                method: 'POST',
                headers: {'App-Version': packageInfo.version},
                body: formData
            });
            const data = await response.json();
            
            if (response.ok) {
                refetchLors({ fetchPolicy: 'network-only' });
                setUploadStatus('success');
                onClose()
            } else {
                setUploadStatus('error');
                onClose()
            }

        }
    };
    const handleEditSlor = () => {
        setIsSlorEdit(!isSlorEdit)
    }
   

    return (
        <div className="modal-backdrop" >
        <div className="add-field-modal" style={{ height: '90vh', overflowY: 'scroll'}}>
        <div className="faculty-home-header" style={{display:'flex',flexDirection:'row', justifyContent:'space-between'}}>
        <div className='add-field-modal-header'>
            <h2 className="faculty-home-header-title">{`${lor.applicantId.firstName} ${lor.applicantId.lastName}`}</h2>
            </div>
            {LoRStage === 'Submitted' && lor.isSlor !== null && lor.isSlor !== undefined && lor.isSlor === true && <button className="green-button" style={{padding:'.5vh 1vw .5vh 1vw'}} onClick={handleEditSlor}>{isSlorEdit?'Edit SLOR':'Disable Edit SLOR'}</button>}
        </div>
        <div className="document-home-body">

        {LoRStage !== 'Submitted' && 
        <>
          {uploadStatus === 'success' && (
            <>
                {window.scrollTo(0, 0)}
                <p style={{color:'green', textAlign:'center'}}>Recommendation uploaded successfully</p>
            </>
        )}
        {lor.isSlor !== null && lor.isSlor !== undefined && lor.isSlor === true && uploadStatus !=='success' && <PlasticSurgeryForm lor={lor} refetchLors={refetchLors} setUploadStatus={setUploadStatus}/>}
        {uploadStatus!=='success' && ((lor.isSlor !== undefined && lor.isSlor !== null && lor.isSlor === false) || 
          lor.isSlor === undefined 
          || lor.isSlor === null)  && (

        <div className="faculty-home-body">
            <p className="specific-rec-title">Please upload your letter of recommendation here</p>
            {selectedFile && 
            <div>
            {/* Check if the file is an image to display it in an img tag */}
            {selectedFile.type.startsWith('image/') && (
                <img src={URL.createObjectURL(selectedFile)} alt="Preview" style={{maxWidth: '100%', maxHeight: '400px'}} />
            )}
            {/* Check if the file is a PDF to display it in an iframe */}
            {selectedFile.type === 'application/pdf' && (
                <iframe src={URL.createObjectURL(selectedFile)} style={{width: '100%', height: '500px'}} frameBorder="0"></iframe>
            )}
            {/* Optionally, handle other file types here */}
            <p>File Name: {selectedFile.name}</p>
            <p>File Type: {selectedFile.type}</p>
            <p>File Size: {(selectedFile.size / (1024*1024)).toFixed(2)} MB</p>
            </div>
            }
            {!selectedFile && 
            <div className={ isDragActive ?'file-drop-zone hover':"file-drop-zone"} style={{zIndex:'50', width: '50vw'}} {...getRootProps()}>
                 <input {...getInputProps()}  multiple={false}/>
                {
                    isDragActive ?
                    <p>Drop to upload the file</p> :
                    <p style={{position:'absolute', top:'46vh'}}>Drag & drop your letter of recommendation here, or click to select files</p>
                }
            </div>
}
                <div className="specific-rec-submit">
                <button className='green-button' disabled={(!selectedFile&&!lor.docUrl)} style={{marginTop:'2vh'}} type="submit">Submit</button>
                </div>
        
        </div>
        )}
        </>
        }
        {
          LoRStage === 'Submitted' && 
          ((lor.isSlor !== undefined && lor.isSlor !== null && lor.isSlor === false) || 
          lor.isSlor === undefined 
          || lor.isSlor === null)  &&
            <div className="faculty-home-body">
                <p className="specific-rec-title">Your letter of recommendation has been submitted</p>
                <button className='green-button' onClick={()=>setLoRStage('Pending')} style={{padding:'1vh 1vw', width:'50vw'}}>Replace LoR File</button>

                <Document
                  file={lor.docUrl}
                  onLoadSuccess={onDocumentLoadSuccess}
              >
                  {Array.from(new Array(numPages), (el, index) => (
                      <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                  ))}
              </Document>
            </div>
        }
        {
            LoRStage === 'Submitted' && lor.isSlor !== null && lor.isSlor !== undefined && lor.isSlor === true && <PlasticSurgeryForm lor={lor} refetchLors={refetchLors} setUploadStatus={setUploadStatus} isSlorEdit={isSlorEdit}/>
        }
        <button style={{padding:'1vh 1vw', width:'50vw'}} className="white-button" onClick={() => onClose()}>Close</button>
        </div>
        </div>
        </div>

    )
}


export default SpecificLor;