import { gql } from '@apollo/client';

export const LIST_POSITIONS_FOR_APP = gql`
  query listPositionsForApplicant {
    listPositionsForApplicant {
      id
      jobTitle
      summary
      numberOfOpenings
      positionStatus
      programDetails{
        name
        description
      }
    }
  }
`;


export const GET_POSITION = gql`
  query GetPosition($positionId: String!) {
    getPosition(positionId: $positionId) {
      programId
      jobTitle
      department
      hiringManager{
      id
      }
      summary
      detailedDescription
      responsibilities
      dayToDayActivities
      minimumQualifications
      preferredQualifications
      requiredSkills
      preferredSkills
      educationLevel
      certifications
      experienceLevel
      languagesRequired
      licenses
      employmentType
      jobLevel
      salaryRange {
        min
        max
      }
      benefitsOffered
      workSchedule
      workLocation {
        city
        state
        country
      }
      relocationAssistance
      closingDate
      requiredDocuments
      screeningQuestions
      positionStatus
      visibility
      legalDisclaimers
      securityClearanceRequired
      physicalRequirements
      backgroundCheckRequired
      drugTestRequired
      programDetails{
        name
        description
      }
    }
  }
`;


export const getAllInstitutionsQuery = gql`
    query getAllInstitutions {
        getAllInstitutions {
            name
            isInternational
            isCa
        }
    }
`

export const getApplicantByIdQuery = gql`
    query getApplicantByIdQuery($id: ID!) {
        getApplicantByIdQuery(id: $id) {
            id
            email
            firstName
            lastName
            phone
            profilePhotoUrl
            institution
            institutionId
            graduationYearUpdated
            graduationYear
            applicationInformation {
                previousLastName
                prefferedName
                pronouns
                usmleId
                nbomeId
                aoaId
                permanentAddress
                licensureRevoked
                licensureRevokedDetails
                militaryObligation
                militaryObligationDetails
                otherServiceObligations
                medicalTrainingInterrupted
                medicalTrainingInterruptedDetails
                sigmaPhi
                aamc
                nrmpId
                gender
                otherRaceEthnicity
                otherWorkAuthorization
                partnerName
                dateOfBirth
                homeTown
                ethnicity
                homeAddress
                workAuthorization
                misdemeanorOrFelony
                misdemeanorOrFelonyDetails
                coupleMatch
                malpractice
                partnerFeild
                medicalSchoolType
                medicalSchoolName
                isLanguages
                fluentLanguages
                higherEducation {
                    institution
                    degree
                    date
                }
                aoaStatus
                goldHumanism
                awardsAndHonors
                coreClerkshipType
                otherCkMethod
                coreClerkshipGrades {
                    clerkshipEm
                    clerkshipFm
                    im
                    neurology
                    obgyn
                    pediatrics
                    psychiatry
                    surgery
                }

                awayRotations {
                    institution
                    degree
                    dateCompleted
                }
                nonCoreClerkshipGrades {
                    course
                    grade
                }
                firstAuthorPublications
                peerReviewedPublications
                posterPresentations
                podiumPresentations
              
            }
        }
    }
`

export const GetSpecialtiesQuery = gql`
    query getSpecialties {
        getSpecialties {
            id
            name
            description
            importantDates
            applicationRequirements {
                degree
                lors
                mspe
                transcript
                step1
                step2
                cost
            }
            releaseDate
            shortAnswerQuestions
        }
    }
`

export const GetProgramsBySpecialtyIdQuery = gql`
    query getProgramsBySpecialtyId($specialtyId: ID!) {
        getProgramsBySpecialtyId(specialtyId: $specialtyId) {
            id
            name
            url
            programTracks
        }
    }
`

export const getMasterApplicationProgramsQuery = gql`
query getMasterApplicationByUserIdAndSpecialtyId($userId: ID!, $specialtyId: ID!) {
    getMasterApplicationByUserIdAndSpecialtyId(userId: $userId, specialtyId: $specialtyId) {
        id
        showSignalMessage
        pendingPrograms {
            programId {
                id
            }
            isSignal
            signalEssay
            signalType
            name
            selectedTracks {
                trackName
                programName
            }
            allTracks {
                trackName
                programName
            }
        }
        appliedPrograms {
            programId {
                id
            }
            isSignal
            signalEssay
            signalType
            name
            selectedTracks {
                trackName
                programName
            }
            allTracks {
                trackName
                programName
            }
            references{
            name
            company
            role
            phone
            email
            }
            cvUrl
            additionalDocsUrl
            status
        }
    }
}
`

export const getMasterApplicationByUserIdAndSpecialtyIdQuery = gql`
    query getMasterApplicationByUserIdAndSpecialtyId($userId: ID!, $specialtyId: ID!) {
        getMasterApplicationByUserIdAndSpecialtyId(userId: $userId, specialtyId: $specialtyId) {
            id
            userId
            specialtyId
            showSignalMessage
            paymentStatus
            releaseDate
            name
            createdAt
            updatedAt 
            firstPhase
            secondPhase
            thirdPhase
            fourthPhase
            fifthPhase
            sixthPhase
            seventhPhase
            carryingOutResponsibilities
            carryingOutResponsibilitiesElaboration
            previouslyAppliedToSpecialty
            applicationYear
            personalStatement
            researchExperience {
                category
                title
                authors
                journal
                datePublished
                pubMedId
                isFirstAuthor
                significantResearchExperience
                meeting
                meetingDate
                order
            }
            additionalResearchUrl
            meaningfulExperiences {
                category
                title
                startDate
                endDate
                description
            }
            workExperiences {
                employer
                jobTitle
                startDate
                endDate
                mentorName
                mentorEmail
                description
            }
            shortAnswerQuestions {
                question
                answer
            }
            hobbiesAndInterests
            approvedCodeOfEthics
            shortAnswerQuestions {
                question
                answer
            }
            hobbiesAndInterests
            references{
            name
            company
            role
            phone
            email
            }
            cvUrl
            additionalDocsUrl
            status
        }
    }
`

export const getAllMasterApplicationsByUserIdQuery = gql`
    query getAllMasterApplicationsByUserId($userId: ID!) {
        getAllMasterApplicationsByUserId(userId: $userId) {
            id
            userId
            positionId{
            id
            jobTitle
            department
            summary
            programId
            }
            specialtyId
            showSignalMessage
            paymentStatus
            releaseDate
            name
            createdAt
            updatedAt 
            firstPhase
            secondPhase
            thirdPhase
            fourthPhase
            fifthPhase
            sixthPhase
            seventhPhase
            carryingOutResponsibilities
            carryingOutResponsibilitiesElaboration
            previouslyAppliedToSpecialty
            applicationYear
            personalStatement
            researchExperience {
                category
                title
                authors
                journal
                datePublished
                pubMedId
                isFirstAuthor
                significantResearchExperience
                meeting
                meetingDate
                order
            }
            additionalResearchUrl
            meaningfulExperiences {
                category
                title
                startDate
                endDate
                description
            }
            workExperiences {
                employer
                jobTitle
                startDate
                endDate
                mentorName
                mentorEmail
                description
            }
            shortAnswerQuestions {
                question
                answer
            }
            hobbiesAndInterests
            approvedCodeOfEthics
            shortAnswerQuestions {
                question
                answer
            }
            hobbiesAndInterests
            appliedPrograms{
                programId
            }
            references{
            name
            company
            role
            phone
            email
            }
            cvUrl
            additionalDocsUrl
            status
        }
    }
`

export const GET_SCORE_BY_APPLICANTID = gql`
query GetScoreByApplicantId($applicantId: ID!) {
    getScoreByApplicantId(applicantId: $applicantId) {
            id
            applicantId
            uploaderId
            stage
            usmleStep1Score
            comlexLevel1Score
            usmleStep2Score
            usmleStep3Score
            comlexLevel2Score
            previousUsmleComlexFails
            usmleComlexDescription
            usmleDocs
            comlexDocs
            scoresVerified
            requestedAt
            submittedAt
            usmleScoresVerified
            comlexScoresVerified
            medicalSchoolEmail
            mspeReqEmailAdd
        }
    }
`

export const getAllLorsByUserIdQuery = gql`
    query getAllLorsByUserId($applicantId: ID!){ 
        getAllLorsByUserId(applicantId: $applicantId) {
            id
            recommenderEmail
            recommenderName
            stage
            applicationId
            
        }
    }
`

export const getApplicationsByApplicantIdQuery = gql`
    query getApplicationsByApplicantId($applicantId: ID!) {
        getApplicationsByApplicantId(applicantId: $applicantId) {
            id
            userId
            scoreId
            programId {
                id
                name

            }
            specialtyId {
                id
                name
            }
            masterApplicationId
            applicationStatus
            reviewDetails
            documents
            lors
            personalStatement
            paymentStatus
            createdAt
            updatedAt
            isSignal
            signalEssay
            signalType
        }
    }
`

export const getSpecialtyByIdQuery = gql`
    query getSpecialtyById($id: ID!) {
        getSpecialtyById(id: $id) {
            id
            name
            shortAnswerQuestions
            applicationRequirements {
                cost
            }
        }
    }
`

export const getLegalDisclaimers = gql`
    query getLegalDisclaimers($programId: ID!) {
        getLegalDisclaimers(programId: $programId) {
            defaultLegalDisclaimers
            defaultEqualEmploymentOpportunityStatement
        }
    }
`
