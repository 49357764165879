const {gql} = require('@apollo/client');

export const getApplicationsByApplicationIds = gql`
    query getApplicationById($id: ID!) {
        getApplicationById(id: $id) {
            id
            signalEssay
            watchUsersIds
            createdAt
            applicationRubrics {
                label
                inputType
                maxScale
                lowestScaleText
                highestScaleText
                dropdownOptions {
                    option
                    score
                }
                text
                order
                result 
            }
            userId {
                _id
                firstName
                lastName
                email 
                phone
                profilePhotoUrl
                graduationYear
                graduationYearUpdated
                mspe {
                    docUrl
                }
                transcript {
                    docUrl
                }
                applicationInformation {
                    nrmpId
                    workAuthorization
                    gender
                    otherRaceEthnicity
                    otherWorkAuthorization
                    partnerName
                    partnerFeild
                    dateOfBirth
                    homeTown
                    homeAddress
                    ethnicity
                    coupleMatch
                    medicalSchoolName
                    aoaStatus
                    goldHumanism
                    firstAuthorPublications
                    peerReviewedPublications
                    posterPresentations
                    podiumPresentations
                    aamc
                    previousLastName
                    prefferedName
                    pronouns
                    usmleId
                    nbomeId
                    aoaId
                    permanentAddress
                    licensureRevoked
                    licensureRevokedDetails
                    militaryObligation
                    militaryObligationDetails
                    otherServiceObligations
                    medicalTrainingInterrupted
                    medicalTrainingInterruptedDetails
                    sigmaPhi
                    malpractice
                    awardsAndHonors
                    isLanguages
                    fluentLanguages
                    misdemeanorOrFelony
                    misdemeanorOrFelonyDetails
                    higherEducation {
                        institution
                        degree
                        date
                    }
                    coreClerkshipType
                    otherCkMethod
                    coreClerkshipGrades {
                        clerkshipEm
                        clerkshipFm
                        im
                        neurology
                        obgyn
                        pediatrics
                        psychiatry
                        surgery
                    }
            
                    awayRotations {
                        institution
                        degree
                        dateCompleted
                    }
                    nonCoreClerkshipGrades {
                        course
                        grade
                    }
                }
            }
            isSignal
            selectedTracks {
                trackName
            }
            scoreId {
                usmleStep1Score
                comlexLevel1Score
                usmleStep2Score
                usmleStep3Score
                comlexLevel2Score
                previousUsmleComlexFails
            }
            masterApplicationId {
                _id
                previouslyAppliedToSpecialty
                applicationYear
                carryingOutResponsibilities
                carryingOutResponsibilitiesElaboration
                researchExperience {
                    category
                    title
                    authors
                    journal
                    datePublished
                    pubMedId
                    isFirstAuthor
                    significantResearchExperience
                    meeting
                    meetingDate
                    order
                }
                workExperiences {
                    employer
                    jobTitle
                    startDate
                    endDate
                    mentorName
                    mentorEmail
                    description
                }
                meaningfulExperiences {
                    category
                    title
                    startDate
                    endDate
                    description
                }
                hobbiesAndInterests
                shortAnswerQuestions {
                    question
                    answer
                }
                personalStatement
            }
        }
}`;


