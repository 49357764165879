 
    const mockedFields = [
        "First Name",
        "Last Name",
        "Email",
        "Phone Number",
        "NRMP ID",
        "US Citizen",
        "Gender Identity",
        "Hometown",
        "Race/Ethnicity",
        "Couple's Match",
        "Medical School Name",
        "AOA Status",
        "Submission Date",
    ]

    export default mockedFields;