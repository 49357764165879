import React, { useEffect } from 'react';

const ReviewerCard = ({ reviewer, selectedRows, updateSelectedRows }) => {

    const rowExistsInSelected = selectedRows && selectedRows.filter(elem => elem._id.toString() === reviewer._id.toString()).length > 0
    return (
        <div className={rowExistsInSelected ? 'reviewer-card-active' : 'reviewer-card'} onClick={() => updateSelectedRows(reviewer)}>
            <div className='reviewer-header'>{reviewer.firstName} {reviewer.lastName}</div>
            <div>Status: {reviewer.totalReviews}</div>
        </div>
    )
}

export default ReviewerCard;