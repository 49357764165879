import React from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';

const ReviewerApplicationsTable = ({ columns, data, sortData }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page, // Use 'page' instead of 'rows'
        prepareRow,
        state: { pageIndex, pageSize, sortBy }, // Add 'sortBy' to state
    } = useTable(
        { columns, data },
        useSortBy,
        usePagination // Use 'usePagination' hook
    );

    let minRows = 5;
    let finalPageSize = page.length < minRows ? minRows : pageSize;

    const customRowStyle = {
        maxHeight: '7vh', // Maximum height for each row
        height: '7vh',
        overflow: 'hidden',
    };

    const sortClicked = (columnId) => {
        const columnSort = sortBy.find(sort => sort.id === columnId);
        const direction = columnSort ? (columnSort.desc ? 'desc' : 'asc') : 'asc';
        console.log("sorting =>", columnId, direction);
        sortData(columnId, direction);
    };

    return (
        <table className='reviewer-application-table' {...getTableProps()}>
            <thead className='tHead-programs-list'>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th
                                className='table-header-title'
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                onClick={(e) => {
                                    console.log("column =>", column);
                                    column.getSortByToggleProps().onClick(e);
                                    if(column.id !== 'status' && column.id !== 'view' && column.id !== 'unassign') sortClicked(column.id);
                                }}
                            >
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {page.map(row => {
                    prepareRow(row);
                    return (
                        <tr className='programs-list-table-row table-row-height' {...row.getRowProps()} style={customRowStyle}>
                            {row.cells.map(cell => (
                                <td className='programs-list-table-row-text' {...cell.getCellProps()}>
                                    {cell.value === 'Pending' ?
                                        <span className='reviewer-application-table-pending-title'>Pending Review</span>
                                        : cell.value === 'Completed' ?
                                            <span className='reviewer-application-table-completed-title'>Completed</span>
                                            :
                                            cell.render('Cell')
                                    }
                                </td>
                            ))}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default ReviewerApplicationsTable;
