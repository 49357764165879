import React, { useState, useEffect } from 'react';
import logo from '../../../../assets/imgs/logo.svg';
import PhoneInputComponent from './phoneComponent';
import Select from 'react-select';
import './index.css';
// import medicalSchools from '../../../common/listOfInstitutions'; 
import {getAllProgramsQuery, getAllInstitutionsQuery } from './graphql/queries';
import {inviteUserMutation, inviteApplicantMutation, inviteUserToPortalMutation} from './graphql/mutations';
import { useQuery, useMutation } from '@apollo/client';


const AddUserModal = ({ isOpen, onClose, onCreateUser }) => {
    const [userType, setUserType] = useState('');
    const [roles, setRoles] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [programId, setProgramId] = useState('');
    const [institutionName, setInstitutionName] = useState('');
    const [isReviewerAutoSelected, setIsReviewerAutoSelected] = useState(false);
    const [programs, setPrograms] = useState([]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [schools, setSchools] = useState([]);
    const [medicalSchools, setMedicalSchools] = useState([]);
    const [medicalSchoolsData, setMedicalSchoolsData] = useState([]);

    const [inviteFacultyUser] = useMutation(inviteUserMutation);
    const [inviteApplicant] = useMutation(inviteApplicantMutation);
    const [inviteMedSchoolUser] = useMutation(inviteUserToPortalMutation);

  




    const { data: allPrograms, loading: allProgramsLoading, error: allProgramsError } = useQuery(getAllProgramsQuery);


    const { data: institutionsData, error: institutionsError, loading: institutionsLoading} = useQuery(getAllInstitutionsQuery, {
      variables: {},
      fetchPolicy: 'network-only'
    })



    useEffect(() => {
        if (!allProgramsLoading && !allProgramsError && allPrograms) {
            setPrograms(allPrograms.getPrograms);
        }
    }, [allPrograms, allProgramsLoading, allProgramsError]);

    useEffect(() => {
      if(institutionsLoading) {
        return;
      } else if(institutionsError) {
        console.error(institutionsError)
        return;
      } else if(institutionsData && institutionsData.getAllInstitutions) {
        console.log("institutionsData =>", institutionsData)
        if(JSON.stringify(institutionsData.getAllInstitutions) !== JSON.stringify(medicalSchools)) {
          const data = institutionsData.getAllInstitutions
          setMedicalSchoolsData(data)
          var schools = []
          data.forEach(elem => schools.push(elem.name))
          console.log("schools =>", schools)
          setMedicalSchools(schools)
          setSchools(schools)
        }
      }
  }, [institutionsData, institutionsError, institutionsLoading])




if (!isOpen) return null;

const handleRoleChange = (e) => {
    const { value, checked } = e.target;
    if (value === 'PD' || value === 'PC') {
        setRoles((prevRoles) => {
          let newRoles = checked ? [...prevRoles, value] : prevRoles.filter((role) => role !== value);
  
          // Automatically add 'Reviewer' if either 'PD' or 'PC' is checked
          if (checked && !newRoles.includes('Reviewer')) {
            newRoles.push('Reviewer');
            setIsReviewerAutoSelected(true); // Mark as auto-selected
          }
  
          // Remove 'Reviewer' only if both 'PD' and 'PC' are unchecked
          if (!checked && newRoles.includes('Reviewer') && !newRoles.includes(value === 'PD' ? 'PC' : 'PD')) {
            newRoles = newRoles.filter((role) => role !== 'Reviewer');
            setIsReviewerAutoSelected(false); // Mark as not auto-selected
          }
  
          return newRoles;
        });
      } else if (value === 'Reviewer') {
        // Only allow 'Reviewer' to be toggled if neither 'PD' nor 'PC' is checked
        if (!roles.includes('PD') && !roles.includes('PC')) {
          setRoles((prevRoles) =>
            checked ? [...prevRoles, value] : prevRoles.filter((role) => role !== value)
          );
          setIsReviewerAutoSelected(false); // Mark as not auto-selected
        }
      } else {
        setRoles((prevRoles) =>
          checked ? [...prevRoles, value] : prevRoles.filter((role) => role !== value)
        );
      }

};


const handleSave = () => {

    if (userType === 'medSchool') {
        inviteMedSchoolUser({
            variables: {
                email: email,
                institution: institutionName,
                firstName: firstName,
                lastName: lastName,
                phone: phone
            }
        }).then(res => {
                setRoles([]); // reset roles
                setUserType('');
                setFirstName('');
                setLastName('');
                setEmail('');
                setPhone('');
                setProgramId('');
                setInstitutionName('');
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false);
                    onClose();
                }, 2000); 
        }).catch(error => {
            setError(error.message);
        })

    }
    if (userType==='faculty') {
        inviteFacultyUser({
            variables: {
                input: {
                    email: email,
                    programId: programId,
                    roles: roles,
                    phone: phone,
                    firstName: firstName,
                    lastName: lastName
                }
            }
        }).then(res => {
            setRoles([]); // reset roles
            setUserType('');
            setFirstName('');
            setLastName('');
            setEmail('');
            setPhone('');
            setProgramId('');
            setInstitutionName('');
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
                onClose();
            }, 2000); 
        }).catch(error => {
            setError(error.message);
        })
    }

    if (userType === 'applicant') {
        inviteApplicant({
            variables: {
                input: {
                    email: email,
                    firstName: firstName,
                    lastName: lastName,
                    phone: phone,
                    institution: institutionName,
                }
            }
        }).then(res => {
            setRoles([]); // reset roles
            setUserType('');
            setFirstName('');
            setLastName('');
            setEmail('');
            setPhone('');
            setProgramId('');
            setInstitutionName('');
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
                onClose();
            }, 2000); 
        }).catch(error => {
            setError(error.message);
        })
    }

};

const handleCancel = () => {
    setRoles([]); // reset roles
    setUserType('');
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
    setProgramId('');
    setInstitutionName('');
    onClose();
}

return (

<div className="modal-backdrop">
<div className="add-field-modal">
    <div className='add-field-modal-header' style={{marginBottom:'3vh'}}>
    <h2>CREATE NEW USER</h2>
    <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
    </div>
    {success && (
        <div className='success-message'>
            <p style={{fontSize:'3vh', color:'green'}}>User Created Successfully</p>
            </div>
            )}
{!success && (
    <form style={{maxHeight:'50vh', overflowY:'auto'}}>
        <div className="form-group">
           <select 
           className='application-form-standard-input one'
           onChange={(e) => setUserType(e.target.value)}
           >
                <option value="">Select the User Type</option>
                <option value="faculty">Faculty</option>
                <option value="applicant">Applicant</option>
                <option value="medSchool">Medical School Admin</option>
           </select>
          <input
            style={{ marginBottom: '2vh'}}
            type="text"
            placeholder='First Name'
            className="application-form-standard-input"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
        />
          <input
            style={{ marginBottom: '2vh'}}
            type="text"
            placeholder='Last Name'
            className="application-form-standard-input"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
        />
          <input
            style={{ marginBottom: '2vh'}}
            type="text"
            placeholder='Email'
            className="application-form-standard-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
        />
          <div className='application-form-field-group' style={{ width: '49vw'}}>
                <PhoneInputComponent phone={phone} handleOnChange={(val) => { setPhone(val)}}/>
            </div>
            {(userType === 'applicant' || userType ==='medSchool') && (
                <>
                 <label style={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            color: '#848484',
            lineHeight: '24px',
            marginBottom: '2vh',
            marginTop: '2vh'
        }}>Select Institution</label>
           <div style={{ marginBottom: '7vh'}}>
              <Select
                  options={schools.map(medSchool => ({ label: medSchool, value: medSchool}))}
                  onChange={option => {
                    setInstitutionName(option.value);
                  }}
                  noOptionsMessage={() => 'No Institutions Found'}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: '10'
                      }),
                    control: (provided) => ({
                      ...provided,
                      width: '100%',
                      height: '61px',
                      boxSizing: 'border-box',
                      color: '#414141',
                      background: 'linear-gradient(0deg, #f2f2f2, #f2f2f2), #fff',
                      border: '1px solid #dbdbdb',
                      borderRadius: '8px',
                      outline: 'none',
                      padding: '11px 23px',  // Note: last padding value used, overriding previous
                      fontFamily: 'Montserrat',
                      fontSize: '20px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '30px',
                      zIndex:'1000',
                      position:'relative'
                      
                    }),
                    option: (provided) => ({
                      ...provided,
                      zIndex:'1000',
                      position:'relative'
                      
                    }),
                  }}
                />
          </div> 
                </>
            )}

            {userType === 'faculty' && (
                <>
        <label style={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            color: '#848484',
            lineHeight: '24px',
            marginBottom: '2vh',
            marginTop: '2vh'
        }}>Select Program</label>
 <Select
      options={programs.map(program => ({ label: program.name+': '+ program.specialtyDetails.name, value: program.id}))}
    //   className='application-form-standard-input one'
      onChange={option => {
        setProgramId(option.value);
      }}

      noOptionsMessage={() => 'No Programs Found'}
      styles={{
        menu: (provided) => ({
          ...provided,
          zIndex: '10'
          }),
        control: (provided) => ({
          ...provided,
          width: '100%',
          height: '61px',
          boxSizing: 'border-box',
          color: '#414141',
          background: 'linear-gradient(0deg, #f2f2f2, #f2f2f2), #fff',
          border: '1px solid #dbdbdb',
          borderRadius: '8px',
          outline: 'none',
          padding: '11px 23px',  // Note: last padding value used, overriding previous
          fontFamily: 'Montserrat',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '30px',
          zIndex:'1000',
          position:'relative'
          
        }),
        option: (provided) => ({
          ...provided,
          zIndex:'1000',
          position:'relative'
          
        }),
      }}
    />
  

        <label style={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                color: '#848484',
                lineHeight: '24px',
                marginBottom: '2vh',
                marginTop: '2vh'    
            }}>Select roles</label>
            <div className='role-selection-container' style={{display:'flex', flexDirection:'row'}}>

            <div style={{display:'flex', alignItems: 'center'}}>
                    <input type="checkbox" style={{zIndex:'5'}} id="PD" name="PD" value="PD" checked={roles.includes('PD')} onChange={handleRoleChange} />
                    <label for="role1" style={{ marginBottom: 0}}>Program Director</label>
                </div>
                
                <div style={{display:'flex'}}>
                    <input type="checkbox" style={{zIndex:'5'}} id="PC" name="PC" value="PC" checked={roles.includes('PC')} onChange={handleRoleChange} />
                    <label for="role2" style={{ marginBottom: 0, }}>Program Coordinator</label>
                </div>
                
                <div style={{display:'flex'}}>
                    <input type="checkbox"  id="Reviewer" name="Reviewer" value="Reviewer" checked={roles.includes('Reviewer')} onChange={handleRoleChange}   style={{zIndex:'5', 
                        accentColor: roles.includes('Reviewer') ? (isReviewerAutoSelected ? '#8f8686' : '#145388') : 'transparent',
                    }}/>
                    <label for="role3" style={{ marginBottom: 0}}>Reviewer</label>
                </div>
                
                <div style={{display:'flex'}}>
                    <input type="checkbox" style={{zIndex:'5'}} id="Recommender" name="Recommender" value="Recommender" checked={roles.includes('Recommender')} onChange={handleRoleChange} />
                    <label for="role4" style={{ marginBottom: 0}}>Recommender</label>
                </div>

            </div>
            </>
            )}
        </div>

    </form>
)}
            {error && (
                <p style={{color:'red', fontSize:'3vh'}}>{error}</p>
            )}
        <div className='button-container' style={{display:'flex', flexDirection:'row', justifyContent:'space-between', gap:'2vw', marginTop: '4vh'}}>

            {!success && (
                <>
                <button type="button" className='gradient-button' style={{fontWeight:'500'}} 
                disabled={email === "" || ((userType === 'applicant' || userType ==='medSchool') && institutionName === "")}
                onClick={handleSave}>Save New User</button>
            <button type="button" className='white-button' onClick={handleCancel}>Cancel</button>
            </>
            )}
        </div>
    
</div>
</div>
)
};

export default AddUserModal;