import React, {useState} from "react";
import forwardGrayArrow from "../../../../assets/imgs/forwardGrayArrow.svg";


const GroupItem = ({group, onEdit, onDelete, onSelectGroup, onEditGroup}) => {

    const handleDelete = (group) => {
        onDelete(group);
    }

    const handleSelectGroup = (group) => {
        onSelectGroup(group);
    }
    const handleEdit = (group) => {
        onEditGroup(group);
    }

    return (
        <div className='group-item-card'>
            <div className="group-item-cont" onClick={()=> handleSelectGroup(group)} style={{cursor:'pointer'}}>
                <h3 className="group-item-title">{group.name}</h3>
                <p className="group-item-description" style={{height:'9vh', width:'22vw', overflowY:'scroll'}}>{group.description}</p>
                <p className="group-item-applications-title">{group.applicationIds.length} Applications</p>
            </div>
            <div style={{display:'flex', justifyContent:'space-between'}}>
                <button className="green-button" style={{padding:'1vh 1vw'}} onClick={() => handleEdit(group)}>Edit</button>
                {(group.name !== 'Rejected'&&group.name !== 'Selected for Interview'&& group.name !== 'On Hold') && <button className="green-button" style={{padding:'1vh 1vw'}} onClick={() => handleDelete(group)}>Delete</button>}
                {/* <button className="group-item-forward-button-cont" onClick={()=> handleSelectGroup(group)}><img style={{  transform: 'rotate(180deg)', width:'23%'}} src={forwardGrayArrow}/></button> */}
            </div>
        </div>
    );
}

export default GroupItem;