import React, { useState, useContext, useEffect } from 'react';
import logo from '../../../../assets/imgs/logo.svg'

import { useMutation } from '@apollo/client';
import {ProgramIdContext} from '../../progIdContext'
import Spinner from '../../../common/spinner';

const CategoriesValuesModal = ({ isOpen, onClose, onUpdateWeight, lastRubricOrder, existingRubric, activeTab, rubrics }) => {
    const programId = useContext(ProgramIdContext);
    const [isLoading, setIsLoading] = useState(false);
    const [label, setLabel] = useState(existingRubric?existingRubric.label:'');
    const [inputType, setInputType] = useState(existingRubric?existingRubric.inputType:'text');
    const [dropdownOptions, setDropdownOptions] = useState(existingRubric?existingRubric.dropdownOptions:[]);
    const [maxScale, setMaxScale] = useState(existingRubric?existingRubric.maxScale:5); // Default value set to 5
    const [lowestScaleText, setLowestScaleText] = useState(existingRubric?existingRubric.lowestScaleText:'');
    const [highestScaleText, setHighestScaleText] = useState(existingRubric?existingRubric.highestScaleText:'');
    // const [createRubricTemplate, { data }] = useMutation(CREATE_RUBRIC_TEMPLATE);

    const [relevantRubrics, setRelevantRubrics] = useState([]);



    

    useEffect(() => {
        if (rubrics&& rubrics.length > 0) {
// remove all rubrics that are not relevant to the current tab and are not inputType 'scale'
            const RubricsToHandle =rubrics.filter(rubric => rubric.isPreInterview === (activeTab === 'screening') && (rubric.inputType === 'scale' || rubric.inputType === 'dropdown'));
            const rubricsWithNormalizedWeight = RubricsToHandle.map(rubric => {
                if (rubric.weight === null || rubric.weight === undefined) {
                    return { ...rubric, weight: 1 };
                }
                return rubric;
            });
            setRelevantRubrics(rubricsWithNormalizedWeight);
        }
    }, [rubrics, activeTab]);


    const hadnleSetRubricsWeight = async () => {
        onUpdateWeight(relevantRubrics);
    
    };



const handleAddOption = (rubricId, value) => {
    setRelevantRubrics(prevRubrics =>
        prevRubrics.map(rubric =>
            rubric.id === rubricId ? { ...rubric, weight: value } : rubric
        )
    );
};


        if (!isOpen) return null;
        if (isLoading) return <Spinner />;
    return (
        <div className="modal-backdrop">
            <div className="add-field-modal" style={{height:'97vh'}}>
                <div className='add-field-modal-header'>
                <h2>Categories Weight</h2>
                <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
                </div>
                {relevantRubrics && relevantRubrics.length > 0 && (
                    <div className='relevant-rubrics' style={{height:'71vh', maxHeight:'71vh', overflowY:'scroll', display:'flex',flexDirection:'column',gap:'2vh'}}>
                            {relevantRubrics.map(rubric => (
                                <div className='category-weight-cont'  style={{display:'flex', gap: '2vw'}}>
                                      <label style={{
                            fontFamily: 'Montserrat',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '22px',
                            color: '#145388',
                            lineHeight: '24px',
                            marginBottom: '19px',
                            width:'20vw'
                            }}>{rubric.label}</label>
                             <input
                             style={{
                                fontFamily: 'Montserrat',
                                lineHeight: '24px',
                                height: '4vh',
                             }}
                              value={rubric.weight}
                                type="text"
                                min='1'
                                max='100'
                                className="application-form-standard-input"
                                onChange={(e) => {
                                    // Only call handleAddOption if the input is not empty
                                    if (e.target.value !== '') {
                                        const value = parseInt(e.target.value, 10);
                                        handleAddOption(rubric.id, isNaN(value) ? 0 : value); // Use 0 as a temporary value
                                    }else{
                                     handleAddOption(rubric.id, '');
                                    }
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        // If the input is empty, set the weight to 1
                                        if (e.target.value === '') {
                                            handleAddOption(rubric.id, 1);
                                        }
                                        e.target.blur();
                                    }
                                }}
                                placeholder="Type Your Value and Press Enter"
                            />
                                </div>
                            ))}
                    </div>
                )}
                {!relevantRubrics || relevantRubrics.length === 0 && (
                    <div className='add-field-modal-content'>
                        <div className='add-field-modal-content-row'>
                        No relevant rubrics found
                        </div>
                    </div>
                )}
                
                    <div className='button-container' style={{display:'flex', flexDirection:'row', justifyContent:'space-between', gap:'2vw'}}>
                    <button type="button" className='gradient-button' onClick={hadnleSetRubricsWeight}
                    disabled={relevantRubrics && relevantRubrics.length === 0}
                    >Save</button>
                    <button type="button" className='white-button'  onClick={onClose}>Cancel</button>
                    </div>

            </div>
        </div>
    );
};

export default CategoriesValuesModal;