import React, { useState, useEffect } from 'react';
import './home.css'; // Assuming styles are in Home.css
import Header from './components/header'; // Import the Header component
// import computerScreen from '../../assets/imgs/computerScreen.svg'; // Import the computerScreen image
import computerScreen from '../../assets/imgs/computerScreen.png'; // Import the computerScreen image
import HomePageTitle from './components/homePageTitle'; // Import the HomePageTitle component
import RoleSelector from './components/roleSelector'; // Import the roleSelector component
import SignInModal from './components/signInModal';
import Footer from './components/footer'; // Import the Footer component

function Home({isReset, isVerify}) {
  const [isModalVisible, setIsModalVisible] = useState((isReset===true||isVerify===true)?true:false);
  const [type, setType] = useState('');
  // console.log(isVerifyBoolean, isResetBoolean, isModalVisible)
  console.log( isVerify, 'isVerify')

  useEffect(() => {
    console.log("Current type:", type); // This will log the current type whenever it changes
  }, [type]); // Dependency array, re-run this effect when `type` changes



  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  return (
    <main >
      <ellipse style={{ position: 'absolute', width: '29vw', height: '56vh', left: '55vw', top: '28vh', background: '#2a93d5', filter: 'blur(248px)', opacity:'.2' }} />
      <Header style={{ padding: '2vw'}}/>
    <div style={{ display: 'flex', flexDirection: 'row'}}>
      <img src={computerScreen} alt='Computer Screen' className='computerScreenImage' style={{height:'100%'}}  width="600"
  height="200"
  loading="lazy"/>
     
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      {/* <HomePageTitle /> */}
      {/* <RoleSelector  onSignInClick={handleOpenModal} setType={setType}/> */}
      <SignInModal type={type} isVisible={true} onClose={handleCloseModal} isReset={isReset} isVerify={isVerify} />
    </div>
    </div>
    {/* <Footer /> */}
    </main>
  );
}

export default Home;