import React, { useEffect } from 'react';
import { useTable, useGlobalFilter, useRowSelect } from 'react-table';
import magGlass from '../../../../assets/imgs/magGlass.svg';

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) {
    const count = preGlobalFilteredRows.length;
  
    return (
      <span className='rt-search-bar'>
        <img src={magGlass} alt='magnifying glass' />
        <input
        style={{width:'30vw',height:'4.7vh',alignSelf:'center'}}
          value={globalFilter || ''}
          onChange={e => {
            setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
          }}
          placeholder={`Search ${count} records...`}
        />
      </span>
    );
  }


const ReviewersTable = ({columns, data, setSelectedRows}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { selectedRowIds, globalFilter },
      } = useTable({ columns, data }, useGlobalFilter, useRowSelect);

    
      useEffect(() => {
        if(setSelectedRows){
            setSelectedRows(rows.filter((row) => selectedRowIds[row.id]));
            console.log('selectedRowIds =>', selectedRowIds);
        }
    }, [selectedRowIds, setSelectedRows, rows]);


      return (
        <>
      
          <table className='table-programs-list' style={{overflowY:'scroll'}} {...getTableProps()}>

          <thead className='tHead-programs-list'>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
{headerGroup.headers.filter(column => (column.Header !== 'isPending'&&column.Header!=='id' && column.Header!=='applicationsIds')).map(column => (
    <th className='table-header-title' {...column.getHeaderProps()}>{column.render('Header')}</th>
))}
<th>
<div style={{ position: 'relative' }}>
<GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        />
  </div>
</th>
                </tr>
              ))}

            </thead>

        
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                const rowStyle = row.values.isPending === true ? {background: 'gainsboro', fontStyle:'italic', fontWeight:'bold', height:'5vh', minHeight:'5vh', maxHeight:'5vh'} : {height:'5vh', minHeight:'5vh', maxHeight:'5vh'};
                return (
                  <tr className='programs-list-table-row table-row-height' {...row.getRowProps()} style={rowStyle}>
                      {row.cells.filter(cell => (cell.column.Header !== 'isPending'&&cell.column.Header!=='id'&&cell.column.Header!=='applicationsIds')).map(cell => (
                          <td className='programs-list-table-row-text' {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      );
  }
  
    
    export default ReviewersTable;