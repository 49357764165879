import React,{useState,useContext, useEffect} from "react";
import { useQuery, useApolloClient } from '@apollo/client';
import { UserContext } from '../../../../context/userContext';
import AccountsTable from '../settings/accountsTable';
import { getUserWithAccountUsersById} from '../settings/graphql/queries';
import Spinner from "../../../common/spinner";


const AccountAccess = ({ setPage }) => {
    const { user, setUser, setTopUser } = useContext(UserContext);
    const [userData, setUserData] = useState({})
    const [accountUsers, setAccountUsers] = useState([])
    const { data, loading, error, refetch } = useQuery(getUserWithAccountUsersById, {
        variables: { userId: user.userId },
    });


    const columns = [
        {
            Header: 'Name',
            id: 'name',
            accessor: 'name',
            Cell: ({ row }) => {
                console.log("row =>", row)
                return (
                <label>{row.original.firstName} {row.original.lastName}</label>
            )}
        },
        {
            Header: 'Email',
            id: 'email',
            accessor: 'email',
            Cell: ({ row }) => (
                <label>{row.original.email}</label>
            )
        },
        {
            Header: 'Institution',
            id: 'institution',
            accessor: 'institution',
            Cell: ({ row }) => (
                <label>{row.original.institution}</label>
            )
        },
        {
            Header: 'Login',
            id: 'btn',
            accessor: 'btn',
            Cell: ({ row }) => (
                <button className="white-button" onClick={() => updateTopUser(row.original)}>Login</button>
            )
        }
    ]


    useEffect(() => {
        if (!loading && !error && data) {
            console.log("user in settings =>", data.getUserWithAccountUsersById)
            const user = data.getUserWithAccountUsersById
            if(JSON.stringify(user) !== JSON.stringify(userData)) {
                setUserData(user);
                console.log("user =>", user)
                setAccountUsers(user.accountAccessIds)
            }
           
        }
    }, [data]);

    const updateTopUser = async (userToUpdate) => {
        const existingUser = user
        setTopUser(existingUser)
        setUser(userToUpdate)
        setPage("home")
    }

    return (
        <div className='applicant-home-container'>
            <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop2' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="applicant-home-header">
                <h2 className="applicant-home-header-title">ACCOUNT ACCESS</h2>
                <seperator className="applicant-home-header-seperator"/>
            </div>
            {loading && <Spinner />}
            {error && <p>Error: {error.message}</p>}
            {accountUsers.length === 0 && <p>No account access found</p>}
            {accountUsers.length > 0 &&(
            <row className='settings-row'>
                <AccountsTable columns={columns} data={accountUsers} />
            </row>
            )}
        </div>

    )

}

export default AccountAccess;
