import React, { useState, useEffect,useRef, useContext } from 'react';
import { ProgramIdContext } from '../../../progIdContext';
import {
  Row,
  Card,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Col
} from 'reactstrap';
import { Colxx, Separator } from '../common/CustomBootstrap';
import { useQuill } from 'react-quilljs'; 
import 'quill/dist/quill.snow.css'; 
import {useMutation, useQuery, useApolloClient} from '@apollo/client';
import {
  specificEmailTemplateByProgramQuery,
//   programUpdatedSubs,
//   attachmentAddedSubscription,
//   attachmentRemovedSubscription
} from '../graphql/queries';
import {
  editEmailTemplateInProgramMutation,
  addEmailAttachmentMutation,
  removeEmailAttachmentMutation,
  updateProgramMutation
} from '../graphql/mutations';
import Dropzone from 'react-dropzone';
import { FileIcon } from 'react-file-icon';
import '../styles/emailTemplates.css';
// import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import CustomeEmailSender from '../settings/settings-sub-components/customeEmailSender/index';
import moment from 'moment';
// import { endpoint, serverPort, httpProtocol } from "../../../App";
// import AllowEmails from '../settings/settings-sub-components/allowEmails';


const ManualEmailModal = ({ selectedTemplate, showFullTemplate, manualEmail, title }) => {
  
  const [templateFeatures, setTemplateFeatures] = useState(null);
  const [startSubject, setStartSubject] = useState('');
  const [startBody, setStartBody] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [navigationTarget, setNavigationTarget] = useState('');
  const [originalTemplate, setOriginalTemplate] = useState(null);
  const [finishUpdate, setFinishUpdate] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [previewBody, setPreviewBody] = useState("");
  const [previewSubject, setPreviewSubject] = useState("");
  const [isChangedDetected, setIsChangedDetected] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  const {quill:quillBody, quillRef: quillBodyRef} = useQuill()
  const { quill: quillSubject, quillRef: quillSubjectRef } = useQuill({
    modules: {
      toolbar: false // This disables the toolbar
    }
  });
  // const { quill: quillSubject, quillRef: quillSubjectRef } = useQuill()

  const programId = useContext(ProgramIdContext);

  useEffect(() => {
    setOriginalTemplate(selectedTemplate);
    setTemplateFeatures(getEmailStatus(selectedTemplate.name));
    console.log(getEmailStatus(selectedTemplate.name), "getEmailStatus(template.name)")
    setStartSubject(selectedTemplate.emailSubject);
    setStartBody(selectedTemplate.emailBody);
    setIsLoading(false);
  }, [selectedTemplate]);


  useEffect(() => {
    if (quillSubject) {
      // Initialize Quill for subject with existing content
      quillSubject.setText(selectedTemplate.emailSubject || '');

      // Update state when Quill content changes
      quillSubject.on('text-change', () => {
        setEmailSubject(quillSubject.getText());
      });
    }

    if (quillBody) {
      // Initialize Quill for body with existing content
      quillBody.clipboard.dangerouslyPasteHTML(selectedTemplate.emailBody || '');
      quillBody.on('text-change', () => {
        setEmailBody(quillBody.root.innerHTML);
      });
    }
  }, [quillSubject, quillBody, selectedTemplate.emailSubject, selectedTemplate.emailBody]);
  

  useEffect(() => {
    console.log(emailBody, "emailBody")
  }, [emailBody])


  const [editEmailTemplate] = useMutation(editEmailTemplateInProgramMutation, {
    onCompleted: (data) => {
      const emailTemplate = data.editEmailTemplateInProgram;
      setOriginalTemplate({ ...originalTemplate, emailBody: emailTemplate.emailBody, emailSubject: emailTemplate.emailSubject });
      setShowModal(true);
      setIsChangedDetected(false);
      if (manualEmail) {
        setEmailSubject(emailTemplate.emailSubject);
        setEmailBody(emailTemplate.emailBody);
        setAttachments(emailTemplate.attachments);
      }
    }
  });

  const [addEmailAttachment] = useMutation(addEmailAttachmentMutation);
  const [removeEmailAttachment] = useMutation(removeEmailAttachmentMutation);

//   useSubscription(programUpdatedSubs, {
//     variables: {},
//     onSubscriptionData: ({ subscriptionData }) => {
//       const newProgram = subscriptionData.data.programUpdated;
//       if (newProgram && newProgram._id.toString() === program._id.toString()) {
//         setOriginalTemplate(newProgram);
//       }
//     }
//   });

//   useSubscription(attachmentAddedSubscription, {
//     variables: {},
//     onSubscriptionData: ({ subscriptionData }) => {
//       const newTemplate = subscriptionData.data.attachmentAdded;
//       if (newTemplate && newTemplate.programId === program) {
//         setOriginalTemplate(newTemplate);
//       }
//     }
//   });

//   useSubscription(attachmentRemovedSubscription, {
//     variables: {},
//     onSubscriptionData: ({ subscriptionData }) => {
//       const newTemplate = subscriptionData.data.attachmentRemoved;
//       if (newTemplate && newTemplate.programId === program) {
//         setOriginalTemplate(newTemplate);
//       }
//     }
//   });


  const getEmailStatus = (templateName) => {
    const statuses = {
      'Invite': {
        isManual: false,
        whenSent: 'Use this template to invite a candidate for an interview.'
      },
      'Interview Details': {
        isManual: false,
        whenSent: 'Use this template to send an applicant details for the interview.'
      },
      'Invite': {
        isManual: false,
        whenSent: 'Use this template to invite a candidate for an interview.'
      },
      'Interview Details': {
        isManual: false,
        whenSent: 'Use this template to send an applicant details for the interview.'
      },
      'Cancel Invitation': {
        isManual: false,
        whenSent: 'Use this template to cancel the applicant’s invitation to interview.'
      },
      'Reminder - Week Before': {
        isManual: false,
        whenSent: 'Use this template to remind an applicant of the date and details of an interview.'
      },
      'New Date Available': {
        isManual: false,
        whenSent: 'Use this template to notify an applicant of an open interview slot.'
      },
      'No Dates Available': {
        isManual: false,
        whenSent: 'It is sent when you would like to update the candidate on the wait-list status.'
      },
      'Meeting Set': {
        isManual: false,
        whenSent: ''
      },
      'Meeting Canceled': {
        isManual: false,
        whenSent: 'Use this template to notify an applicant that an interview has been canceled.'
      },
      'Meeting Rescheduled': {
        isManual: false,
        whenSent: 'Use this template to notify an applicant that an interview has been rescheduled.'
      },
      'Thank You': {
        isManual: false,
        whenSent: 'Use this template to thank an applicant for interviewing with your program.'
      },
      'Pre-Interview': {
        isManual: true,
        whenSent: ''
      },
      'Custom Manual 1': {
        isManual: true,
        whenSent: 'Use this template to draft a custom email and save it for reuse.'
      },
      'Custom Manual 2':  {
        isManual: true,
        whenSent: 'Use this template to draft a custom email and save it for reuse.'
      },
      'Custom Manual 3': {
        isManual: true,
        whenSent: 'Use this template to draft a custom email and save it for reuse.'
      }
    };

    return statuses[templateName] || {};
  };

  const handleSubjectTextChanged = (value) => {
    const updatedTemplate = { ...originalTemplate, emailSubject: value };
    setOriginalTemplate(updatedTemplate);

    if (startSubject !== value) {
      // toggle navigation
    }
    if (manualEmail) {
      setEmailSubject(updatedTemplate.emailSubject);
    }
  };

  const handleBodyTextChanged = (value) => {
    const updatedTemplate = { ...originalTemplate, emailBody: value };
    setOriginalTemplate(updatedTemplate);

    if (startBody !== value) {
      // toggle navigation
    }
    if (manualEmail) {
      setEmailBody(updatedTemplate.emailBody);
    }
  };

  const handleUpload = async (fileObject) => {
    try {
      const timestamp = Date.now();
      const fileType = fileObject.name.split('.').pop();
      const fileName = `${fileObject.name.split('.')[0]}-${timestamp}.${fileType}`;

     

      await addEmailAttachment({
        variables: {
          _id: originalTemplate._id,
          fileName: fileName
        }
      });
    } catch (error) {
      console.error("Error uploading file", error);
    }
  };

  const saveChanges = () => {
    editEmailTemplate({
      variables: {
        _id: originalTemplate._id,
        emailSubject: emailSubject,
        emailBody: emailBody
      }
    });
  };

  const discardChanges = () => {
    setFinishUpdate(true);
    setIsChangedDetected(false);
    window.location.href = navigationTarget;
  };

  const toggleSettings = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };

  const showPreview = async () => {
    const applicant = {
      firstName: 'Dan',
      lastName: 'Avery',
      previousDate: '07/12/2022',
      scheduleDate: null,
      // ...other applicant details
    };

    let subject = originalTemplate.emailSubject;
    let body = originalTemplate.emailBody;

    // Replace placeholders with actual values
    subject = subject.replace(/@applicant_first_name/g, applicant.firstName);
    subject = subject.replace(/@applicant_last_name/g, applicant.lastName);
    subject = subject.replace(/@the_previous_interview_date/g, applicant.previousDate);
    subject = subject.replace(/@the_interview_date/g, applicant.scheduleDate);
    // ...additional replacements

    setPreviewSubject(subject);

    const remoteUrl = `${process.env.REACT_APP_SERVER_URL}/get_template`;
    const result = await fetch(remoteUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        program: programId,
        emailBody: body
      }),
    });

    const combined = await result.json();
    setPreviewBody(combined);
    setShowEmail(!showEmail);
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="container-101" style={{width:'75vw', alignSelf:'flex-start'}}>
      {showModal && (
        <div className='modal-backdrop'>
            <div className="modal">
            <div className="modal-header">
            The changes have been saved!
          <span className="close-icon-modal" onClick={() => setShowModal(false)}>x</span>
        </div>
        <div className="modal-body">
        The changes you have made have been successfully updated.
        <button style={{marginRight:'27vw',marginTop:'3vh'}} className="green-button" onClick={() => setShowModal(false)}>Done</button>
        </div>
      </div>
    </div>
)}
      <Row>
        <div className='email-auto-manual'>
          {templateFeatures && showFullTemplate && (
            <Label className='autoManual' style={{color:'#145388', marginBottom:'0'}}>
              This is {templateFeatures.isManual ? 'a Manual' : 'an Automatic'} Email Template - {templateFeatures.whenSent}
            </Label>
          )}
        </div>
      </Row>
      {showFullTemplate && (
        <Row style={{height:'5vh'}}>
          <Colxx xxs='12'>
            <div style={{ display: 'flex', width: '95%', justifyContent: 'space-between', marginBottom: '1%', flexDirection: 'row' }}>
              <div className='email-title-header' style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <i className='iconsminds-mail-settings' style={{ fontSize: '2rem' }} />
                <h4 style={{ fontSize: "1.4rem", marginTop: "2%" }}>{title}</h4>
              </div>
              <div className="left-email-templater-header" style={{ display: "flex" }}>
                {/* <Button className="emailButtons123" style={{ fontSize:'2vh',height:'2vh',backgroundColor: 'transparent', borderColor: 'transparent', color: '#195286' }} onClick={toggleSettings}>
                  <img className="saveIcon" style={{ height: '5vh', marginTop: '-5%' }} src={require('../../../../assets/imgs/senderIdIcon.svg').default} />
                  Sender address
                </Button> */}
                <Separator className='mb-550' />
                <Button className="emailButtons1" style={{marginRight:'1vw',textAlign:'-webkit-center',fontSize:'2vh',height:'2vh', backgroundColor: 'transparent', borderColor: 'transparent', color: '#195286' }} onClick={showPreview}>
                  <img className="saveIcon" style={{ height: '2vh', verticalAlign: 'top' }} src={require('../../../../../assets/imgs/emailPreviewIcon.svg').default} />
                  Preview
                </Button>
                <Separator className='mb-550' />
                <Button className="emailButtons2" style={{ textAlign:'-webkit-center',fontSize:'2vh',height:'2vh',backgroundColor: 'transparent', borderColor: 'transparent', color: '#195286' }} onClick={saveChanges}>
                  <img className="saveIcon" style={{ height: '2vh', verticalAlign: 'top', marginTop: '2%' }} src={require('../../../../../assets/imgs/noun-save.svg').default} />
                  Save
                </Button> 
                {/* {isSettingsOpen && programId !== null && (
                  <div className="open-email-sender">
                    <CustomeEmailSender programId={programId} closeWindow={toggleSettings} />
                  </div>
                )} */}
              </div>
            </div>
          </Colxx>
        </Row>
      )}
      <Row>
        <Colxx xxs='12'>
          {originalTemplate && (
            <Card className='subject-card' style={{width:'100%', border:'unset', padding:'unset'}}>
              <div className='subjectContainer'>
                <div className='subjectEditor'>
                <div>
      <div ref={quillSubjectRef} />
    </div>
                </div>
              </div>
            </Card>
          )}
        </Colxx>
      </Row>
      <Row>
        <Colxx xxs='12' style={{ height: '70vh' }}>
        <Card className='subject-card' style={{width:'100%', border:'unset', padding:'unset'}}>
            {originalTemplate && (
              <Dropzone onDrop={e => handleUpload(e[e.length - 1])} noClick={true}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <section className='email-container'>
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      <div className='body-container'>
                        {isDragActive && (
                          <div className='dropzoneContainer' style={{ backgroundColor: '#145388', color:'white', fontFamily:'Montserrat', fontWeight:'600', alignItems:'center',padding:'30%' }}>
                            <div className='dropzoneDescription' style={{ fontSize: '1.5rem', marginBottom: '1%' }}>
                              Drop Files Here
                            </div>
                          </div>
                        )}
                        <div className='attachments-container'>
                          {/* {originalTemplate.attachments.map((file, i) => (
                            <div className='attachment-item' key={i} onClick={() => window.open(file, '_blank')}>
                              <FileIcon extension={file.split('.').pop()} labelUppercase />
                              {file.split('/email-attachments/').pop().split('-')[0]}
                              <i className='simple-icon-close remove-icon' onClick={e => { e.stopPropagation(); removeEmailAttachment({ variables: { _id: originalTemplate._id, fileName: file } }); }}></i>
                            </div>
                          ))} */}
                        </div>
                        <div>
                        <div ref={quillBodyRef} style={{height:'65vh'}} />
    </div>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
            <Modal isOpen={isChangedDetected} style={{ top: '27vh' }}>
              <ModalHeader>Changes Detected</ModalHeader>
              <ModalBody>
                <Label>Would you like to save changes before leaving the page?</Label>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" outline onClick={discardChanges}>
                  <p>DISCARD CHANGES</p>
                </Button>
                <Button color="primary" type="submit" onClick={saveChanges}>
                <p>ACCEPT CHANGES</p>
                </Button>
              </ModalFooter>
            </Modal>
          </Card>
        </Colxx>
      </Row>



      <Modal isOpen={showEmail} size="lg" toggle={() => setShowEmail(!showEmail)}>
        <ModalHeader className='modal-header -previewModalHeader'>
          <h1 style={{ textAlign: 'center', paddingTop: '1%', marginInlineStart: 'auto' }}>E-mail Preview</h1>
          <button type="button" className="close" aria-label="Close" onClick={() => setShowEmail(!showEmail)} style={{ marginRight: '0' }}>
            <span style={{ color: 'white', fontSize: '5vh' }}>X</span>
          </button>
        </ModalHeader>
        <ModalBody>
          <Label className='subject-prev'>{previewSubject}</Label>
          <div dangerouslySetInnerHTML={{ __html: previewBody }} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ManualEmailModal;
