import { Form, Formik, Field, ErrorMessage } from 'formik';
import React, { useState, useEffect } from 'react';
import logo from '../../../../assets/imgs/logo.svg';
import * as Yup from "yup";
import { getProgramMembersByProgramIdQuery } from './graphql/queries';
import {useQuery, useMutation} from '@apollo/client';
import TeamMembersTable from './teamMembersTable';
import { REMOVE_PROGRAM_MEMBER, UPDATE_PROGRAM } from "./graphql/mutations";
import UpdateRoleModal from "./updateRoleModal";
import InviteUserModal from './inviteUserModal';
import Rubric from './rubric/index';
import CustomizeApplication from './customizeApplication/index';
import packageInfo from '../../../../../package.json';

const validationSchema = Yup.object({
})

const roleDisplayNames = {
    'PD': 'Program Director',
    'PC': 'Program Coordinator',
    'Reviewer': 'Reviewer',
    'Recommender': 'Recommender'
};

const EditProgramModal = ({ isOpen, onClose, program, onEditProgram }) => {
    const [programMembers, setProgramMembers] = useState([])
    const [openMenuRowId, setOpenMenuRowId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editingUser, setEditingUser] = useState(null);
    const [isRubricModalOpen, setIsRubricModalOpen] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false);
    const [successText, setSuccessText] = useState('');

    const [removeProgramMember] = useMutation(REMOVE_PROGRAM_MEMBER);
    const [updateProgram] = useMutation(UPDATE_PROGRAM);


    const { loading: loadingProgramMembers, error: errorProgramMembers, data: programMembersData, refetch: refetchProgramMembers} = useQuery(getProgramMembersByProgramIdQuery, {
        variables: {
          programId: program.id
        }
    })

    useEffect(() => {
        if(!loadingProgramMembers && !errorProgramMembers && programMembersData) {
            console.log("programMembersData =>", programMembersData.getProgramMembersByProgramId)
            setProgramMembers(programMembersData.getProgramMembersByProgramId)
        }
    }, [loadingProgramMembers, errorProgramMembers, programMembersData, setProgramMembers])

    const handleMenuToggle = (rowId) => {
        setOpenMenuRowId((prev) => (prev === rowId ? null : rowId));
    };

    const handleEdit = (rowData, id) => {
        setEditingUser(rowData);
        handleMenuToggle(id)
        setIsEditModalOpen(true);
    }

    const handleDelete = async (rowData, id) => {
        console.log('Deleting user:', rowData);
        // Execute the mutation
        try {
            const response = await removeProgramMember({ variables: { programId:program.id, userId:rowData.userId.id} });
            refetchProgramMembers();
            handleMenuToggle(id)
        } catch (error) {
            console.error('Failed to delete program member:', error);
        }
    }

     const handleEditUser = async (editedUser) => {
        setProgramMembers(prevData => {
            const updatedData = prevData.map(user => {
                if (user.email === editingUser.email) {
                    return {
                        ...user,
                        ...editedUser,
                        roleDisplayNames: editedUser.roles.map(role => roleDisplayNames[role]).join(', ')
                    };
                } else {
                    return user;
                }
            });
        
            // Transform the updated data into the desired format
            const members = updatedData.map(user => ({
                userId: user.id,
                roles: user.roles
            }));
        
            // Call the mutation here with the updated members
            updateProgram({ variables: { id: program.id, input: { members } } })
            .then(response => {
                console.log('Update program response:', response);
                setEditingUser(null);
                setIsEditModalOpen(false);
            })
            .catch(error => {
                console.error('Failed to update program:', error);
            });
        
            return updatedData;
        });

    };

    const handleAddUser = () => {
        console.log('inside handleAddUser performing refetch')
        refetchProgramMembers();
    };


  

    const handleRemind = (user, id) => {
        console.log("send reminder!", user)
       
        fetch(`${process.env.REACT_APP_SERVER_URL}/send_invitation_reminder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'App-Version': packageInfo.version
            },
            body: JSON.stringify({
              user
            })
        })
        handleMenuToggle(id)
        setShowSuccess(true)
        setSuccessText("Reminder sent successfully.")
        setTimeout(() => {
            setShowSuccess(false)
            setSuccessText("")
        }, 5000);
    }

    const columns = [
        {
            Header: 'id',
            accessor: 'id',
        },
        {
            Header: 'Name',
            Cell: ({ row }) => (
                <div style={{ width: '7vw'}}>{`${row.original.userId.firstName} ${row.original.userId.lastName}`}</div>
            ),
        },
        {
            Header: 'Email Address',
            accessor: 'userId.email',
        },
        {
            Header: 'Roles',
            Cell: ({ row }) => (
                <div>{row.original.roles.map(role => roleDisplayNames[role]).join(', ')}</div>
            )
        },
        {
            Header: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div className="dropdown" style={{border:'0',margin:'0',padding:'0'}}>
                <button className="white-button dropdown-toggle"  onClick={() => handleMenuToggle(row.id)}>
                    Manage 
                </button>
                {openMenuRowId === row.id && (
                    <div className="dropdown-menu team" style={{zIndex:'3', right:'-.5vw', textAlign: 'center'}}>
                        {row.original.userId.isPending === true && <div className="dropdown-item" style={{ cursor: "pointer", padding:'1vh 2vw' }} onClick={() => handleRemind(row.original, row.id)}>Remind</div> } 
                        <div className="dropdown-item team" style={{ cursor: "pointer" }} onClick={() => handleEdit(row.original, row.id)}>Edit</div>
                        <div className="dropdown-item team" style={{ cursor: "pointer"}} onClick={() => handleDelete(row.original, row.id)}>Delete</div>
                    </div>
                )}
                </div>
            ),
        }
    ]

    if(!isOpen) return null
    return (
        <div className="modal-backdrop">
            <div className="add-field-modal">
                <div style={{float: 'right', cursor: 'pointer'}} onClick={onClose}>X</div>
               {
                   showSuccess && <label style={{float: 'right', marginRight: '2vw', color: "#145388", fontSize: '1rem'}}>{successText}</label>
               }
               
               
                <div className='add-field-modal-header' style={{marginTop: '4vh', marginBottom:'2vh'}}>
                    <h2>EDIT PROGRAM</h2>
                    <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
                </div>
                <Formik 
                    initialValues={{
                        name: program.name ? program.name : "",
                        description: program.description ? program.description : "",
                        url: program.url ? program.url : "",
                        formalName: program.formalName ? program.formalName: "",
                        ACGME_ID: program.ACGME_ID ? program.ACGME_ID : "",
                        sql_id: program.sql_id ? program.sql_id : "",
                    }}
                    onSubmit={values => {
                        console.log("values =>", values)
                        if (values.isRubricLocked === "true") {
                            values.isRubricLocked = true
                        } else if (values.isRubricLocked === "false") {
                            values.isRubricLocked = false
                        }
                        onEditProgram(values)
                        // onClose()
                    }}
                    validationSchema={validationSchema}
                >
                 {({ handleSubmit, values }) => (
                    <Form style={{ height: '55vh', overflowY: 'auto'}}>
                        <div className='application-form-field-group'>
                            <label htmlFor="name" className='applicant-form-field-label'>Name</label>
                            <Field name="name" placeholder="" className='application-form-standard-input' style={{width:'100%'}} />
                            <div className='application-form-error-message'> 
                                <ErrorMessage name="name" component="div" />
                            </div>
                        </div>
                        <div className='application-form-field-group'>
                            <label htmlFor="description" className='applicant-form-field-label'>Description</label>
                            <Field name="description" placeholder="" className='application-form-standard-input' style={{width:'100%'}} />
                            <div className='application-form-error-message'> 
                                <ErrorMessage name="description" component="div" />
                            </div>
                        </div>
                        <div className='application-form-field-group'>
                            <label htmlFor="url" className='applicant-form-field-label'>URL</label>
                            <Field name="url" placeholder="" className='application-form-standard-input' style={{width:'100%'}} />
                            <div className='application-form-error-message'> 
                                <ErrorMessage name="url" component="div" />
                            </div>
                        </div>
                        <div className='application-form-field-group'>
                            <label htmlFor="formalName" className='applicant-form-field-label'>Formal Name</label>
                            <Field name="formalName" placeholder="" className='application-form-standard-input' style={{width:'100%'}} />
                            <div className='application-form-error-message'> 
                                <ErrorMessage name="formalName" component="div" />
                            </div>
                        </div>
                        <div className='application-form-field-group'>
                            <label htmlFor="ACGME_ID" className='applicant-form-field-label'>ACGME ID</label>
                            <Field name="ACGME_ID" placeholder="" className='application-form-standard-input' style={{width:'100%'}} />
                            <div className='application-form-error-message'> 
                                <ErrorMessage name="ACGME_ID" component="div" />
                            </div>
                        </div>
                        <div className='application-form-field-group'>
                            <label htmlFor="isRubricLocked" className='applicant-form-field-label'>Is Rubric Locked</label>
                            <Field as="select" name="isRubricLocked" className='application-form-standard-input' style={{width:'100%'}}>
                                <option value={true} selected={program.isRubricLocked === true}>Yes</option>
                                <option value={false} selected={program.isRubricLocked === false}>No</option>
                            </Field>
                            <div className='application-form-error-message'> 
                                <ErrorMessage name="isRubricLocked" component="div" />
                            </div>
                        </div>

                        <button className='green-button' type="submit"  
                        disabled={values.name === "" || values.url === "" ||
                           values.ACGME_ID === "" }
                        style={{ marginTop: '2vh', marginLeft: '15vw'}}>Update Program Data</button>
                        
                        <seperator className="faculty-home-header-seperator"/>

                        {/* program Members */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2vh', marginBottom: '2vh'}}>
                            <label htmlFor="members" className='applicant-form-field-label'>Program Members</label>
                            <button style={{float:'right'}} className="green-button" onClick={() => setIsModalOpen(!isModalOpen)}>Add Program Member</button>
                        </div>
                        <TeamMembersTable columns={columns} data={programMembers} />
                        <InviteUserModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onAddUser={handleAddUser} program={program}/>
                        {editingUser && <UpdateRoleModal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} user={editingUser} onEditUser={handleEditUser}/>}

                        <seperator className="faculty-home-header-seperator"/>

                        {/* rubricTemplate */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2vh', marginBottom: '2vh'}}>
                            <label htmlFor="members" className='applicant-form-field-label'>Program Rubrics</label>
                        </div>
                        <Rubric programRubrics={program.rubricTemplate} program={program}/>
                        
                        <seperator className="faculty-home-header-seperator"/>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2vh', marginBottom: '2vh'}}>
                            <label htmlFor="members" className='applicant-form-field-label'>Program Screened Data</label>
                        </div>
                        <CustomizeApplication screenedData={program.screenedData} program={program} />

                    </Form>
                 )}
                </Formik>
            </div>
        </div>
    )
}

export default EditProgramModal;