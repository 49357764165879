import React,{useState, useEffect, useContext, useRef} from "react";
import { UserContext } from '../../../context/userContext';
import './index.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PhoneInputComponent from './phoneComponent';
import { useMutation } from '@apollo/client';
import { inviteApplicantMutation } from './graphql/queries';
import { InstitutionIdContext } from '../institutionIdContext';
import ImportFromCSVModal from "./importFromCSVModal";
import Spinner from "../../common/spinner";
import {formattedSchools} from './internationalSchools';
import Select from 'react-select';
// jacob+medschoolApp101@3rdfriday.com

const Tokens = ({ setShouldRefreshApplicants}) => {
    const { user } = useContext(UserContext);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [inviteApplicant, { data, loading, error }] = useMutation(inviteApplicantMutation);
    const institution = useContext(InstitutionIdContext);
    const institutionId = institution.id;
    const fileInputRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const [uploadProgress, setUploadProgress] = useState(0);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [loadingInvite, setLoadingInvite] = useState(false);
    const [inviteSuccess, setInviteSuccess] = useState(null);
    const [errorInvite, setErrorInvite] = useState("");


    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required('First name is required'),
        last_name: Yup.string().required('Last name is required'),
        email: Yup.string().email('Invalid email format').required('Email is required'),
        // phone_number: Yup.string().required('Phone is required'),
        // dateOfBirth:  Yup.string().required('Date of birth is required'),
        // aamc:  Yup.string().required('AAMC is required'),
        // usmleId:  Yup.string().required('USMLE ID is required'),
    });

    const handleClick = () => {
        setIsModalOpen(true);
    };


    const submitForm = async (values) => {
        console.log("values =>", values)
        setLoadingInvite(true)
        const newUser = {
            firstName: values.first_name,
            lastName: values.last_name,
            email: values.email,
            phone: '+'+values.phone_number,
            institution: values.institution,
            institutionId,
            applicationInformation: {
                dateOfBirth: values.dateOfBirth,
                aamc: values.aamc,
                usmleId: values.usmleId,
            }
        }
        try {
            const result = await inviteApplicant({ variables: { input: newUser } , fetchPolicy: 'network-only'});
            console.log('Mutation result:', result);
            setLoadingInvite(false)
            setInviteSuccess(true)

        }catch(err) {
            console.error('Error executing mutation:', err);
            setLoadingInvite(false)
            setInviteSuccess(false)
            setErrorInvite(err)


        }
    

    }

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('institutionId', institutionId);
    
            const xhr = new XMLHttpRequest();
            xhr.open('POST', `${process.env.REACT_APP_SERVER_URL}/upload_applicants_from_file`, true);
    
            // Progress listener
            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable) {
                    const percentComplete = Math.round((event.loaded / event.total) * 100);
                    setUploadProgress(percentComplete);
                }
            };
    
            // Load listener for completion
            xhr.onload = () => {
                if (xhr.status === 200) {
                    const response = JSON.parse(xhr.responseText);
                    setFeedbackMessage('File uploaded successfully');
                    console.log('File uploaded successfully:', response.imageUrl);
                } else {
                    setFeedbackMessage('Upload failed');
                }
            };
    
            // Error listener
            xhr.onerror = () => {
                setFeedbackMessage('Upload error');
            };
    
            xhr.send(formData);
        }
    };

    useEffect(() => {
        if (uploadProgress === 100) {
            setShouldRefreshApplicants(true);
            const timer = setTimeout(() => {
                setIsModalOpen(false); 
                setUploadProgress(0); 
                setFeedbackMessage('');
            }, 3000); 
    
            
            return () => clearTimeout(timer);
        }
    }, [uploadProgress]); // This effect depends on changes to uploadProgress
    


    return (
        <div className='faculty-home-container'>

                <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="faculty-home-header" style={{display:'flex', justifyContent:'space-between'}}>
                <h2 className="faculty-home-header-title">Tokens</h2>
                <button  className="green-button" onClick={handleClick}>Import from CSV</button>
            <ImportFromCSVModal  uploadProgress={uploadProgress} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
                {uploadProgress ===  0 && (
                            <button
                className="custom-file-upload-button" // Add your custom class for styling
                onClick={() => fileInputRef.current.click()}
            >
                Upload File
            </button>
            )}
            <div>
            {uploadProgress > 0 && (
    <div className="upload-progress-container">
        <p className="upload-progress-text">Uploading: {uploadProgress}%</p>
        <progress value={uploadProgress} max="100" className="upload-progress-bar"></progress>
    </div>
)}
    {feedbackMessage && <p>{feedbackMessage}</p>}
</div>
            </ImportFromCSVModal>
            </div>
            <seperator className="faculty-home-header-seperator"/>
            
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileUpload}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <div className="faculty-home-body">
            <h2 className="faculty-home-header-title" style={{marginRight:'auto', color:'#145388', fontSize:'3vh'}}>Invite New Student</h2>
            {!isSubmitted&&    
                <Formik
                    validationSchema = {validationSchema}
                    onSubmit={(values, actions) => submitForm(values)}
                    initialValues = {{
                        first_name: '',
                        last_name: '',
                        email: '',
                        phone_number: '',
                        dateOfBirth: '',
                        aamc: '',
                        usmleId: '',
                        degreeYear: '',
                        institution: null
                    }}
                >
                {({ handleSubmit, values, setFieldValue, submitForm, resetForm, setFieldTouched,  errors, isValid, isSubmitting, validateForm }) => (
                       
                        inviteSuccess === null || (inviteSuccess !== null && inviteSuccess === false)?
                        <Form onSubmit={handleSubmit} style={{width: '100%', marginTop:'-5vh'}}>
                        <div className='tokens-upper-right-inner-container' style={{  width: '89vw'}}>
                            <div className='application-form-field-group'>
                            <Field name="first_name" type="name" placeholder="First Name (required)" className='token-form-standard-input' />
                            <div className='application-form-error-message'> 
                            <ErrorMessage name="first_name" component="div" />
                            </div>
                            </div>
                        </div>
                        <div className='step1-upper-right-inner-container' style={{ marginTop: '2vh', width: '89vw'}}>
                            <div className='application-form-field-group'>
                            <Field name="last_name" type="name" placeholder="Last Name (required)" className='token-form-standard-input' />
                            <div className='application-form-error-message'> 
                            <ErrorMessage name="last_name" component="div" />
                            </div>
                            </div>
                        </div>
                        
                        <div className='application-form-field-group' style={{ marginTop: '2vh', width: '100%'}}>
                                <div className='application-form-field-group'>
                                <Field name="email" type="email" placeholder="Email (required)" className='token-form-standard-input' />
                                <div className='application-form-error-message'> 
                                <ErrorMessage name="email" component="div" />
                                </div>
                                </div>
                        </div>
                        <div className='application-form-field-group' style={{ marginTop: '2vh', width: '60vw'}}>
                            <PhoneInputComponent phone={values.phone_number} handleOnChange={(val) => { setFieldValue("phone_number", val)}}/>
                        </div>
                        <div className='application-form-field-group' style={{ marginTop: '2vh', width: '100%'}}>
                                <div className='application-form-field-group'>
                                <Field name="dateOfBirth" type="dateOfBirth" placeholder="Date of birth (MM/DD/YYYY)" className='token-form-standard-input' />
                                <div className='application-form-error-message'> 
                                {/* <ErrorMessage name="dateOfBirth" component="div" /> */}
                                </div>
                                </div>
                        </div>
                        <div className='application-form-field-group' style={{ marginTop: '2vh', width: '100%'}}>
                                <div className='application-form-field-group'>
                                <Field name="aamc" type="aamc" placeholder="AAMC" className='token-form-standard-input' />
                                <div className='application-form-error-message'> 
                                <ErrorMessage name="aamc" component="div" />
                                </div>
                                </div>
                        </div>
                        <div className='application-form-field-group' style={{ marginTop: '2vh', width: '100%'}}>
                                <div className='application-form-field-group'>
                                <Field name="usmleId" type="usmleId" placeholder="USMLE ID" className='token-form-standard-input' />
                                <div className='application-form-error-message'> 
                                <ErrorMessage name="usmleId" component="div" />
                                </div>
                                </div>
                        </div>
                        {
                            user && (user.institutionId.toString() === "6672c992b4519d1feba815e0" || user.institutionId.toString() === "66a2208e402dff1c3646d582" )  &&
                            <div className='application-form-field-group' style={{ marginTop: '2vh', width: '100%'}}>
                            <Select 
                                name="institution"
                                options={formattedSchools}
                                placeholder="Select institution"
                                onChange={option => {
                                    setFieldValue("institution", option.value)
                                }}
                                value={formattedSchools.filter(elem => elem.value === values.institution)[0]}
                                styles={{
                                    menu: (provided) => ({
                                    ...provided,
                                    width: '60vw',
                                    zIndex: '10'
                                    }),
                                    control: (provided) => ({
                                    ...provided,
                                    width: '60vw',
                                    // height: '9vh',
                                    boxSizing: 'border-box',
                                    color: '#414141',
                                    background: 'linear-gradient(0deg, #f2f2f2, #f2f2f2), #fff',
                                    border: '1px solid #dbdbdb',
                                    borderRadius: '8px',
                                    outline: 'none',
                                    fontFamily: 'Montserrat',
                                    fontSize: '20px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '30px',
                                    zIndex:'15',
                                    position:'relative',
                                    overflowY: 'scroll'
                                    
                                    }),
                                    option: (provided) => ({
                                    ...provided,
                                    zIndex:'1000',
                                    position:'relative'
                                    
                                    }),
                                    indicatorsContainer: (provided) => ({
                                    ...provided,
                                    height: '8vh'
                                    })
                                }}
                                />

                        </div>
                        }
                        {/* <div className='application-form-field-group' style={{ marginTop: '2vh', width: '100%'}}>
                                <div className='application-form-field-group'>
                                <Field name="degreeYear" type="degreeYear" placeholder="YYYY (required)" className='token-form-standard-input' />
                                <div className='application-form-error-message'> 
                                <ErrorMessage name="degreeYear" component="div" />
                                </div>
                                </div>
                        </div> */}
                        {
                            inviteSuccess !== null && inviteSuccess === false &&
                            <label style={{ fontSize: '1.2rem', color: 'red'}}>Invitation Failed: {errorInvite.message}</label>
                        }
                        <button disabled={loadingInvite || (values.email === "" || values.lastName === "" || values.email === "")
                        } className='green-button' type="submit" style={{ marginTop: '3vh', float: 'right', marginRight: '4.9vw'}}>Invite User</button>
                        {
                            loadingInvite && <div style={{ marginTop: '3vh'}}><Spinner /></div>
                        }
                        </Form>
                        :
                        inviteSuccess !== null && inviteSuccess === true && 
                        <div>
                            <div style={{ fontSize: '1.3rem', marginBottom: '2vh', marginLeft: '-1vw'}}>Token was sent successfuly</div>
                            <button className="green-button"  onClick={() => {setInviteSuccess(null); resetForm({ values: {
                        first_name: '',
                        last_name: '',
                        email: '',
                        phone_number: '',
                        dateOfBirth: '',
                        aamc: '',
                        usmleId: '',
                        degreeYear: '',
                    } })}}>Send another Token</button>
                        </div>

                    
                       
                      
                )}
                </Formik>
            }
           
            </div>
            
        </div>
    )
}

export default Tokens;