import React, {useState} from "react";
import Applicant from "../../../../applicant";
import greenCheck from '../../../../../assets/imgs/greenCheck.svg';
import redX from '../../../../../assets/imgs/redX.svg';
import {coreClerkshipDataValues} from "./dataValuesConvert";


function convertKeyToLabel(key) {
    switch(key) {
case 'clerkshipEm':
    return 'Emergency Medicine'
case 'clerkshipFm':
    return 'Family Medicine'
case 'im':
    return 'Internal Medicine'
case 'neurology':
    return 'Neurology'
case 'obgyn':
    return 'Obstetrics & Gynecology'
case 'pediatrics':
    return 'Pediatrics'
case 'psychiatry':
    return 'Psychiatry'
case 'surgery':
    return 'Surgery'
case '__typename':
    return
default:
    return key
    }
}

const Education = ({application, screenedData}) => {

console.log("application in the education section of ApplicationView =>", application)

const coreClerkshipType = application.coreClerkshipType
const coreClerkshipTypeItem = coreClerkshipDataValues.find(item => item.value === coreClerkshipType);
const coreClerkshipTypeLabel = coreClerkshipTypeItem ? coreClerkshipTypeItem.label : coreClerkshipType;


const noDisplay  = <div className='no-display' style={{textAlign:'right', marginLeft:'5vw'}}><img className="img-no" src={redX} alt="Yes" />Failed</div> ;
const yesDisplay = (grade) => {
    switch(grade) {
        case 'Pass':
            return <div className='yes-display'><img className="img-yes" src={greenCheck} alt="Yes" />Pass</div>
        case 'Honors':
            return <div className='yes-display'><img className="img-yes" src={greenCheck} alt="Yes" />Honors</div>
        case 'High Pass':
            return <div className='yes-display'><img className="img-yes" src={greenCheck} alt="Yes" />High Pass</div>
        default:
            return <div className='no-display' style={{textAlign:'right', marginLeft:'5vw'}}><img className="img-no" src={redX} alt="Yes" /></div>
    }
    }



// run a loop through the awayRotations and render the values
const awayRotationsToRender = application.awayRotations && application.user.applicationInformation.awayRotations.map((rotation, index) => {
    return (
        <div className="application-view-main-education-core-clerkship-grades" style={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                <h2 className="clerkship-label">{rotation.institution}</h2>
                <div style={{display:'flex',flexDirection:'row',  width:'100%', justifyContent:'space-between'}}>
                <h2 className="clerkship-value-label">{rotation.degree}</h2>
                <h2 className="clerkship-value-label">{rotation.dateCompleted}</h2>
                </div>
        </div>
    )
})


// run a loop through the nonCoreClerkshipGrades and render the values
const nonCoreClerkshipGradesToRender = application.nonCoreClerkshipGrades && application.nonCoreClerkshipGrades.map((grade, index) => {
    return (
        <div className="application-view-main-education-core-clerkship-grades" style={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'29vw', marginBottom:'2vh'}}>
                <h2 className="clerkship-label">{grade.course}</h2>
                <h2 className="clerkship-value-label">{grade.grade}</h2>
        </div>
    )
})

// run a loop through the coreClerkshipGrades and render the values
let coreClerkshipGradesToRender = []
console.log(application.applicationInformation)
if (
  application &&
  application.user &&
  application.user.applicationInformation &&
  application.user.applicationInformation.coreClerkshipGrades
) {
  const coreClerkshipGrades = application.user.applicationInformation.coreClerkshipGrades;
  if (coreClerkshipGrades && typeof coreClerkshipGrades === 'object' && coreClerkshipGrades !== null) {
    coreClerkshipGradesToRender = Object.keys(coreClerkshipGrades).map((key, index) => {
        if (coreClerkshipGrades[key].toLowerCase() !== 'coreclerkshipgrades') {
      return (
          <div className="application-view-main-education-core-clerkship-grades" style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                  <h2 className="clerkship-label">{convertKeyToLabel(key)}</h2>
                  <h2 className="clerkship-value-label">{coreClerkshipGrades[key]==='gradedDiff'?'graded differently'.toUpperCase():coreClerkshipGrades[key].toUpperCase()}</h2>
          </div>
        
      );
        }
    });
  }
}


// sort by date, most recent last, you need to break the date by / and then compare the year, then month
let higherEducationObj = application.higherEducation?  [...application.higherEducation] : []
const sortedHigherEducation = higherEducationObj.sort((a, b) => {
    const aDate = parseInt(a.date.split('/'))
    const bDate = parseInt(b.date.split('/'))
    console.log("aDate =>", aDate)
    console.log("bDate =>", bDate)
    if (aDate[2] === bDate[2]) {
        if (aDate[0] === bDate[0]) {
            return aDate[1] - bDate[1]
        }
        return aDate[0] - bDate[0]
    }
    return aDate[2] - bDate[2]
}
)

const higherEducationToRender = sortedHigherEducation.map((degree, index) => {
    return (
        <div className="application-view-main-education-degrees-section" style={{display:'flex', flexDirection:'row', paddingTop:'1.5vh', marginBottom:'2vh'}}>
            <div>
        <div className="date" style={{marginLeft:'3vw', fontSize:'12px'}}>{degree.date}
            </div>
            {/* <div className="ball"/> */}
            </div>
            <div className="institution-and-degree">
            <div className="institution" style={{marginLeft:'1vw', fontSize:'14px', fontWeight:'500'}}>{degree.institution}</div>
            <div className="degree" style={{marginLeft:'1vw', fontSize:'12px'}}>{degree.degree}</div>
            </div>

        </div>
    )
    })



return (
        <div className="application-view-main-demographics">
        <div className="application-view-main-demographics-header">
            <h2 className="application-view-main-title" style={{marginTop:'1vh'}}>EDUCATION</h2>
            <seperator className="application-view-main-demographics-seperator"/>
        </div>
        <h2 className="application-view-sub-title">Higher Education</h2>
        {higherEducationToRender}

        <div className="application-view-main-education-core-clerkship-grades">
            <div className="clerkship-grades-sub-cont" style={{display:'flex', gap:'2vw'}}>
       
        {/* {
            screenedData !== null && screenedData !== undefined && screenedData.residencyApplication !== null && screenedData.residencyApplication !== undefined &&
            screenedData.residencyApplication.coreClerkshipsGraded !== null && screenedData.residencyApplication.coreClerkshipsGraded !== undefined && screenedData.residencyApplication.coreClerkshipsGraded === true &&
            <div className="application-view-main-demographics-header" style={{width:'48%'}}>
                <h2 className="application-view-sub-title">Clerkship Grades</h2>
                <seperator className="application-view-main-demographics-seperator"/>
                {coreClerkshipGradesToRender}
                <div className="label" style={{fontSize:'14px', fontStyle:'italic', marginBottom:'0', marginTop:'2vh'}}>How were your core clerkships graded?</div>
                <div className="clerkship-value-label" style={{textAlign:'left',fontSize:'14px'}}>{coreClerkshipTypeLabel}</div>
            </div>
        } */}
        {
            screenedData !== null && screenedData !== undefined && screenedData.residencyApplication !== null && screenedData.residencyApplication !== undefined &&
            screenedData.residencyApplication.nonCoreClerkshipGradedCourses !== null && screenedData.residencyApplication.nonCoreClerkshipGradedCourses !== undefined && screenedData.residencyApplication.nonCoreClerkshipGradedCourses === true &&
            <div className="application-view-main-demographics-header" style={{width:'100%'}}>
                <h2 className="application-view-sub-title">Additional Courses</h2>
                <seperator className="application-view-main-demographics-seperator"/>
                {nonCoreClerkshipGradesToRender}
            </div>
        }


        
       
        </div>
        </div>

        

</div>
)
}

export default Education;