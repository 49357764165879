let languages = [ 
  "A-Pucikwar",
  "A’ou",
  "Aari",
  "Aasáx",
  "Abadi",
  "Abai Sungai",
  "Abanglekuo",
  "Abanyom",
  "Abau",
  "Abaza",
  "Abé",
  "Abenaki, Eastern",
  "Abenaki, Western",
  "Abidji",
  "Abinomn",
  "Abipon",
  "Abishi",
  "Abkhaz",
  "Abom",
  "Abon",
  "Abron",
  "Abu",
  "Abu’",
  "Abua",
  "Abui",
  "Abun",
  "Abure",
  "Abureni",
  "Aceh",
  "Achagua",
  "Achang",
  "Ache",
  "Aché",
  "Acheron",
  "Achi",
  "Acholi",
  "Achterhoeks",
  "Achuar-Shiwiar",
  "Achumawi",
  "Acipa, Eastern",
  "Acroá",
  "Adai",
  "Adamorobe Sign Language",
  "Adang",
  "Adangbe",
  "Adara",
  "Adasen",
  "Adele",
  "Adi",
  "Adi, Galo",
  "Adioukrou",
  "Adithinngithigh",
  "Adnyamathanha",
  "Adonara",
  "Aduge",
  "Adyghe",
  "Adzera",
  "Aeka",
  "Aekyom",
  "Aer",
  "Afade",
  "Afar",
  "Afghan Sign Language",
  "Afitti",
  "Afrikaans",
  "Afro-Seminole Creole",
  "Agarabi",
  "Agariya",
  "Agatu",
  "Agavotaguerra",
  "Aghem",
  "Aghu",
  "Aghu-Tharnggala",
  "Aghul",
  "Agi",
  "Agob",
  "Agta, Casiguran Dumagat",
  "Agta, Central Cagayan",
  "Agta, Dicamay",
  "Agta, Dupaninan",
  "Agta, Katubung",
  "Agta, Mt. Iraya",
  "Agta, Mt. Iriga",
  "Agta, Pahanan",
  "Agta, Umiray Dumaget",
  "Agta, Villa Viciosa",
  "Aguano",
  "Aguna",
  "Agutaynen",
  "Agwagwune",
  "Ahanta",
  "Aheu",
  "Ahirani",
  "Ahom",
  "Ahtena",
  "Ahwai",
  "Ai-Cham",
  "Aighon",
  "Aikanã",
  "Aiklep",
  "Aimaq",
  "Aimol",
  "Ainbai",
  "Ainu",
  "Aiome",
  "Airoran",
  "Aisi",
  "Aiton",
  "Aizi, Aproumu",
  "Aizi, Mobumrin",
  "Aizi, Tiagbamrin",
  "Aja",
  "Ajawa",
  "Ajië",
  "Ajiri",
  "Ajiya",
  "Ajumbu",
  "Ajyíninka Apurucayali",
  "Ak",
  "Aka",
  "Aka-Bea",
  "Aka-Bo",
  "Aka-Cari",
  "Aka-Jeru",
  "Aka-Kede",
  "Aka-Kol",
  "Aka-Kora",
  "Akan",
  "Akar-Bale",
  "Akaselem",
  "Akateko",
  "Akawaio",
  "Akebu",
  "Akei",
  "Akeu",
  "Akha",
  "Akhvakh",
  "Aklanon",
  "Akolet",
  "Akoose",
  "Akoye",
  "Akpes",
  "Akukem",
  "Akuku",
  "Akum",
  "Akuntsu",
  "Akurio",
  "Akwa",
  "Akweya",
  "Akye",
  "Al-Sayyid Bedouin Sign Language",
  "Alaba-K’abeena",
  "Alabama",
  "Alago",
  "Alagwa",
  "Alak",
  "Alamblak",
  "Alangan",
  "Alapmunte",
  "Alawa",
  "Albanian",
  "Albanian Sign Language",
  "Albanian, Arbëreshë",
  "Albanian, Arvanitika",
  "Albanian, Gheg",
  "Albanian, Tosk",
  "Albarradas Sign Language",
  "Ale",
  "Alekano",
  "Aleut",
  "Aleut, Mednyj",
  "Algerian Jewish Sign Language",
  "Algerian Sign Language",
  "Algonquin",
  "Ali",
  "Alladian",
  "Allar",
  "Alngith",
  "Alor",
  "Alsea",
  "Alta, Northern",
  "Alta, Southern",
  "Altai, Northern",
  "Altai, Southern",
  "Alugu",
  "Alune",
  "Aluo",
  "Alur",
  "Alutor",
  "Alviri-Vidari",
  "Alyawarr",
  "Ama",
  "Amahai",
  "Amahuaca",
  "Amaimon",
  "Amako",
  "Amal",
  "Amam",
  "Amami Koniya Sign Language",
  "Amami-Oshima, Northern",
  "Amami-Oshima, Southern",
  "Amanab",
  "Amanayé",
  "Amara",
  "Amarakaeri",
  "Amarasi",
  "Amba",
  "Ambae, East",
  "Ambae, West",
  "Ambai",
  "Ambakich",
  "Ambel",
  "Ambelau",
  "Ambele",
  "Amblong",
  "Ambo",
  "Ambrak",
  "Ambrym, North",
  "Ambrym, Southeast",
  "Ambul",
  "Ambulas",
  "Amdang",
  "Amele",
  "American Sign Language",
  "Amharic",
  "Ami",
  "Amio-Gelimi",
  "Amis",
  "Amo",
  "Ampanang",
  "Amri Karbi",
  "Amto",
  "Amundava",
  "Amurdak",
  "Amuzgo, Guerrero",
  "Amuzgo, Ipalapa",
  "Amuzgo, San Pedro Amuzgos",
  "Anaang",
  "Anakalangu",
  "Anal",
  "Anam",
  "Anambé",
  "Anamuxra",
  "Anasi",
  "Andaandi",
  "Andai",
  "Andajin",
  "Andaman Hindi Creole",
  "Andaqui",
  "Andarum",
  "Andegerebinha",
  "Andh",
  "Andi",
  "Andio",
  "Andoa",
  "Andoque",
  "Andra-Hus",
  "Aneityum",
  "Anem",
  "Aneme Wake",
  "Anfillo",
  "Angaataha",
  "Angaité",
  "Angal",
  "Angal Enen",
  "Angal Heneng",
  "Angika",
  "Angkamuthi",
  "Angloromani",
  "Angolar",
  "Angor",
  "Angoram",
  "Anguthimri",
  "Anii",
  "Animere",
  "Anindilyakwa",
  "Aninka",
  "Anjam",
  "Ankave",
  "Anmatyerr",
  "Anong",
  "Anor",
  "Anserma",
  "Ansus",
  "Antikarinya",
  "Anuak",
  "Anufo",
  "Anuki",
  "Anus",
  "Anuta",
  "Anyin",
  "Anyin Morofo",
  "Aoheng",
  "Aore",
  "Ap Ma",
  "Apache, Jicarilla",
  "Apache, Kiowa",
  "Apache, Lipan",
  "Apache, Mescalero-Chiricahua",
  "Apache, Western",
  "Apal",
  "Apalachee",
  "Apalaí",
  "Apatani",
  "Apiaká",
  "Apinagé",
  "Apma",
  "Apurinã",
  "Aputai",
  "Arabana",
  "Arabela",
  "Arabic",
  "Arabic, Algerian",
  "Arabic, Algerian Saharan",
  "Arabic, Baharna",
  "Arabic, Chadian",
  "Arabic, Cypriot",
  "Arabic, Dhofari",
  "Arabic, Eastern Egyptian Bedawi",
  "Arabic, Egyptian",
  "Arabic, Gulf",
  "Arabic, Hadrami",
  "Arabic, Hijazi",
  "Arabic, Juba",
  "Arabic, Judeo-Iraqi",
  "Arabic, Judeo-Moroccan",
  "Arabic, Judeo-Tripolitanian",
  "Arabic, Judeo-Yemeni",
  "Arabic, Levantine",
  "Arabic, Libyan",
  "Arabic, Mesopotamian",
  "Arabic, Moroccan",
  "Arabic, Najdi",
  "Arabic, North Mesopotamian",
  "Arabic, Omani",
  "Arabic, Sa’idi",
  "Arabic, Sanaani",
  "Arabic, Shihhi",
  "Arabic, Standard",
  "Arabic, Sudanese",
  "Arabic, Ta’izzi-Adeni",
  "Arabic, Tajiki",
  "Arabic, Tunisian",
  "Arabic, Uzbeki",
  "Aragonese",
  "Arakwal",
  "Aralle-Tabulahan",
  "Aramba",
  "Aranadan",
  "Aranama-Tamique",
  "Aranda, Lower Southern",
  "Arandai",
  "Araona",
  "Arapaho",
  "Arapaso",
  "Arára, Mato Grosso",
  "Arara, Pará",
  "Ararandewára",
  "Arawak",
  "Araweté",
  "Arbore",
  "Archi",
  "Are",
  "Areba",
  "Arem",
  "Argentine Sign Language",
  "Argobba",
  "Arguni",
  "Arhâ",
  "Arhö",
  "Arhuaco",
  "Ari",
  "Aribwatsa",
  "Aribwaung",
  "Arigidi",
  "Arikapú",
  "Arikara",
  "Arikem",
  "Arin",
  "Aringa",
  "Armenian",
  "Armenian Sign Language",
  "Armenian, Western",
  "Aro",
  "Aromanian",
  "Arop-Lokep",
  "Arosi",
  "Arpitan",
  "Arrarnta, Western",
  "Arrernte, Eastern",
  "Arritinngithigh",
  "Arta",
  "Aruá",
  "Aruamu",
  "Aruek",
  "Arum",
  "Aruop",
  "Arutani",
  "As",
  "Asa’a",
  "Asaba",
  "Asaro’o",
  "Asháninka",
  "Ashe",
  "Ashéninka, Pajonal",
  "Ashéninka, Perené",
  "Ashéninka, Pichis",
  "Ashéninka, South Ucayali",
  "Ashéninka, Ucayali-Yurúa",
  "Ashkun",
  "Ashtiani",
  "Asilulu",
  "Askopan",
  "Asmat, Casuarina Coast",
  "Asmat, Central",
  "Asmat, North",
  "Asmat, Yaosakor",
  "Asoa",
  "Assamese",
  "Assangori",
  "Assiniboine",
  "Assyrian Neo-Aramaic",
  "Asturian",
  "Asu",
  "Asumboa",
  "Asuri",
  "Asurini of Xingú",
  "Asurini, Tocantins",
  "Ata",
  "Atakapa",
  "Atampaya",
  "Atauran",
  "Atayal",
  "Ateso",
  "Athpariya",
  "Ati",
  "Atikamekw",
  "Atin",
  "Atohwaim",
  "Atong",
  "Atorada",
  "Atoro",
  "Atsahuaca",
  "Atsam",
  "Atsugewi",
  "Atta, Faire",
  "Atta, Pamplona",
  "Atta, Pudtol",
  "Attié",
  "Au",
  "Aukan",
  "Aulua",
  "Aurá",
  "Aushi",
  "Aushiri",
  "Auslan",
  "Austral",
  "Australian Aborigines Sign Language",
  "Austrian Sign Language",
  "Auwe",
  "Auye",
  "Auyokawa",
  "Avá-Canoeiro",
  "Avar",
  "Avatime",
  "Avau",
  "Avava",
  "Avestan",
  "Avikam",
  "Avokaya",
  "Awa",
  "Awa-Cuaiquer",
  "Awabakal",
  "Awad Bing",
  "Awadhi",
  "Awajún",
  "Awak",
  "Awakateko",
  "Awar",
  "Awara",
  "Awbono",
  "Aweer",
  "Awera",
  "Awetí",
  "Awing",
  "Awishira",
  "Awiyaana",
  "Awjilah",
  "Awngi",
  "Awngthim",
  "Awtuw",
  "Awu",
  "Awun",
  "Awutu",
  "Awyi",
  "Awyu, Asue",
  "Awyu, Central",
  "Awyu, Edera",
  "Awyu, Jair",
  "Awyu, North",
  "Awyu, South",
  "Axamb",
  "Axi",
  "Ayabadhu",
  "Ayere",
  "Ayerrerenge",
  "Ayi",
  "Ayizi",
  "Aymara",
  "Aymara, Central",
  "Aymara, Southern",
  "Ayoreo",
  "Ayta, Abellen",
  "Ayta, Ambala",
  "Ayta, Mag-antsi",
  "Ayta, Mag-Indi",
  "Ayta, Magbukun",
  "Ayta, Sorsogon",
  "Ayu",
  "Azerbaijani",
  "Azerbaijani, North",
  "Azerbaijani, South",
  "Azha",
  "Azhe",
  "Aziana",
  "Baan",
  "Baangi",
  "Baatonum",
  "Babango",
  "Babanki",
  "Babar, North",
  "Babar, Southeast",
  "Babatana",
  "Babine",
  "Babuza",
  "Bacama",
  "Bada",
  "Badaga",
  "Bade",
  "Badeshi",
  "Badimaya",
  "Badjiri",
  "Badui",
  "Badyara",
  "Baeggu",
  "Baelelea",
  "Baetora",
  "Bafaw-Balong",
  "Bafia",
  "Bafut",
  "Baga Kaloum",
  "Baga Koga",
  "Baga Manduri",
  "Baga Pokur",
  "Baga Sitemu",
  "Baga Sobané",
  "Bagheli",
  "Bagirmi",
  "Bago-Kusuntu",
  "Bagobo-Klata",
  "Bagri",
  "Bagupi",
  "Bagusa",
  "Bagvalal",
  "Baham",
  "Bahamas English Creole",
  "Bahau",
  "Bahinemo",
  "Bahing",
  "Bahnar",
  "Bahonsuai",
  "Bai",
  "Bai, Central",
  "Bai, Lama",
  "Bai, Panyi",
  "Bai, Southern",
  "Baibai",
  "Baikeno",
  "Baima",
  "Baimak",
  "Bainouk-Gunyaamolo",
  "Bainouk-Gunyuño",
  "Bainouk-Samik",
  "Baiso",
  "Baiyamo",
  "Bajan",
  "Bajau, Indonesian",
  "Bajau, West Coast",
  "Bajelani",
  "Bajjika",
  "Baka",
  "Bakairí",
  "Bakaka",
  "Bakati’",
  "Bakati’, Rara",
  "Bakati’, Sara",
  "Bakhtiâri",
  "Baki",
  "Bakoko",
  "Bakole",
  "Bakumpai",
  "Bakwé",
  "Balaesang",
  "Balangao",
  "Balanta",
  "Balanta-Ganja",
  "Balantak",
  "Baldemu",
  "Balen",
  "Bali",
  "Balkan Gagauz Turkish",
  "Balo",
  "Balochi, Eastern",
  "Balochi, Southern",
  "Balochi, Western",
  "Baloi",
  "Balti",
  "Baluchi",
  "Bamanankan",
  "Bambam",
  "Bambili-Bambui",
  "Bamu",
  "Bamun",
  "Bamwe",
  "Ban Khor Sign Language",
  "Bana",
  "Banam Bay",
  "Banda",
  "Banda-Bambari",
  "Banda-Banda",
  "Banda-Mbrès",
  "Banda-Ndélé",
  "Banda-Yangere",
  "Banda, Mid-Southern",
  "Banda, South Central",
  "Banda, Togbo-Vara",
  "Banda, West Central",
  "Bandi",
  "Bandial",
  "Bandjalang",
  "Bangala",
  "Bangandu",
  "Bangba",
  "Banggai",
  "Bangi",
  "Bangime",
  "Bangka",
  "Bangon",
  "Bangubangu",
  "Bangwinji",
  "Baniva",
  "Baniwa",
  "Banjar",
  "Bankagooma",
  "Bankal",
  "Bankon",
  "Bannoni",
  "Bantawa",
  "Bantayanon",
  "Bantik",
  "Bantoanon",
  "Banyjima",
  "Baoulé",
  "Barababaraba",
  "Barai",
  "Barakai",
  "Baram",
  "Barama",
  "Barambu",
  "Baramu",
  "Barapasi",
  "Baras",
  "Barasana-Eduria",
  "Barbaram",
  "Barbareño",
  "Bardi",
  "Baré",
  "Barein",
  "Bareli, Palya",
  "Bareli, Pauri",
  "Bareli, Rathwi",
  "Bargam",
  "Bari",
  "Barí",
  "Bariai",
  "Bariji",
  "Barikanchi",
  "Barikewa",
  "Barngarla",
  "Barok",
  "Barombi",
  "Barrow Point",
  "Baruga",
  "Barwe",
  "Barzani-Sandu Jewish Neo-Aramaic",
  "Basa",
  "Basa-Gumna",
  "Basa-Gurmana",
  "Basaa",
  "Basap",
  "Basay",
  "Bashkardi",
  "Bashkort",
  "Basketo",
  "Basque",
  "Bassa",
  "Bassa-Kontagora",
  "Bassossi",
  "Bata",
  "Batak",
  "Batak Alas-Kluet",
  "Batak Angkola",
  "Batak Dairi",
  "Batak Karo",
  "Batak Mandailing",
  "Batak Simalungun",
  "Batak Toba",
  "Batanga",
  "Batek",
  "Bateri",
  "Bathari",
  "Bati",
  "Batjala",
  "Bats",
  "Batu",
  "Batui",
  "Bauchi",
  "Bauni",
  "Baure",
  "Bauria",
  "Bauro",
  "Bauwaki",
  "Bauzi",
  "Bavarian",
  "Bay Miwok",
  "Bayali",
  "Baybayanon",
  "Baygo",
  "Bayono",
  "Bayot",
  "Bayungu",
  "Bazigar",
  "Beaver",
  "Beba",
  "Bebele",
  "Bebeli",
  "Bebil",
  "Bedamuni",
  "Bedawiyet",
  "Bedjond",
  "Bedoanas",
  "Beeke",
  "Beele",
  "Beembe",
  "Beezen",
  "Befang",
  "Beginci",
  "Behoa",
  "Bekwarra",
  "Bekwel",
  "Belait",
  "Belanda Bor",
  "Belanda Viri",
  "Belarusian",
  "Belhariya",
  "Beli",
  "Belize English Creole",
  "Bella Coola",
  "Bellari",
  "Belning",
  "Bemba",
  "Bembe",
  "Bena",
  "Benabena",
  "Benamanga",
  "Bench",
  "Bende",
  "Bendi",
  "Beneraf",
  "Beng",
  "Benga",
  "Bengali",
  "Benggoi",
  "Bentong",
  "Benyadu’",
  "Beothuk",
  "Bepour",
  "Berakou",
  "Berawan, Central",
  "Berawan, East",
  "Berawan, West",
  "Berbice Dutch Creole",
  "Berik",
  "Berinomo",
  "Bermejo Wichí",
  "Berom",
  "Berta",
  "Berti",
  "Besme",
  "Betaf",
  "Betawi",
  "Bete",
  "Bete-Bendi",
  "Bété, Daloa",
  "Bété, Gagnoa",
  "Bété, Guiberoua",
  "Beti",
  "Bezhta",
  "Bhadrawahi",
  "Bhalay",
  "Bharia",
  "Bhatri",
  "Bhattiyali",
  "Bhaya",
  "Bhele",
  "Bhilali",
  "Bhili",
  "Bhogoto",
  "Bhojpuri",
  "Bhujel",
  "Bhunjia",
  "Biafada",
  "Biage",
  "Biak",
  "Biali",
  "Biangai",
  "Biao",
  "Biao Mon",
  "Biao-Jiao Mien",
  "Biate",
  "Bibbulman",
  "Bidayuh Serian",
  "Bidayuh, Bau",
  "Bidayuh, Biatah",
  "Bidayuh, Tringgus-Sembaan",
  "Bidhawal",
  "Bidiyo",
  "Bidjara",
  "Biem",
  "Bierebo",
  "Bieria",
  "Biga",
  "Bigambal",
  "Bih",
  "Bijagó",
  "Bijim",
  "Bijori",
  "Bikol",
  "Bikol, Buhi’non",
  "Bikol, Central",
  "Bikol, Libon",
  "Bikol, Miraya",
  "Bikol, Northern Catanduanes",
  "Bikol, Rinconada",
  "Bikol, Southern Catanduanes",
  "Bikol, West Albay",
  "Bikya",
  "Bila",
  "Bilaspuri",
  "Bilba",
  "Bilbil",
  "Bilen",
  "Bille",
  "Biloxi",
  "Bilua",
  "Bima",
  "Bimin",
  "Bimoba",
  "Bina",
  "Binahari",
  "Binandere",
  "Bindal",
  "Bindji",
  "Bine",
  "Bintauna",
  "Binukid",
  "Binukidnon, Northern",
  "Binukidnon, Southern",
  "Binumarien",
  "Bipi",
  "Bira",
  "Birao",
  "Birgit",
  "Birhor",
  "Biri",
  "Birifor, Malba",
  "Birifor, Southern",
  "Biritai",
  "Birked",
  "Birri",
  "Birrpayi",
  "Birwa",
  "Bisa",
  "Bisã",
  "Bisaya, Brunei",
  "Bisaya, Sabah",
  "Biseni",
  "Bishnupuriya",
  "Bishuo",
  "Bisis",
  "Bislama",
  "Bisorio",
  "Bisu",
  "Bit",
  "Bitare",
  "Bitur",
  "Biwat",
  "Biyo",
  "Biyom",
  "Blaan, Koronadal",
  "Blaan, Sarangani",
  "Blablanga",
  "Blackfoot",
  "Blafe",
  "Blagar",
  "Blang",
  "Bo",
  "Bo-Rukul",
  "Bo-Ung",
  "Boano",
  "Bobo Madaré, Southern",
  "Bobongko",
  "Bobot",
  "Bodo",
  "Bodo Parja",
  "Bofi",
  "Boga",
  "Bogaya",
  "Boghom",
  "Bogkalot",
  "Boguru",
  "Bohtan Neo-Aramaic",
  "Boikin",
  "Bokha",
  "Boko",
  "Bokobaru",
  "Bokyi",
  "Bola",
  "Bolango",
  "Bole",
  "Bolgo",
  "Bolia",
  "Bolinao",
  "Bolivian Sign Language",
  "Boloki",
  "Bolon",
  "Bolondo",
  "Bolyu",
  "Bom-Kim",
  "Boma",
  "Bomboli",
  "Bomboma",
  "Bomitaba",
  "Bomu",
  "Bomwali",
  "Bon Gula",
  "Bonan",
  "Bondei",
  "Bondo",
  "Bonerate",
  "Bonggi",
  "Bonggo",
  "Bongili",
  "Bongo",
  "Bongu",
  "Bonjo",
  "Bonkeng",
  "Bonkiman",
  "Bontok",
  "Bontok, Central",
  "Bontok, Eastern",
  "Bontok, Northern",
  "Bontok, Southern",
  "Bontok, Southwestern",
  "Boon",
  "Boor",
  "Bora",
  "Borna",
  "Boro",
  "Borong",
  "Borôro",
  "Boruca",
  "Boselewa",
  "Bosmun",
  "Bosnian",
  "Bote",
  "Botlikh",
  "Bouni",
  "Bouyei",
  "Bozaba",
  "Bozo, Jenaama",
  "Bozo, Kelengaxo",
  "Bozo, Tiemacèwè",
  "Bozo, Tieyaxo",
  "Bragat",
  "Brahui",
  "Braj Bhasha",
  "Brao",
  "Brazilian Sign Language",
  "Brem",
  "Breri",
  "Breton",
  "Bribri",
  "Bribri Sign Language",
  "British Sign Language",
  "Brokkat",
  "Brokpake",
  "Brokskat",
  "Broome Pearling Lugger Pidgin",
  "Bru, Eastern",
  "Bru, Western",
  "Brunca Sign Language",
  "Brunei",
  "Bu",
  "Bua",
  "Buamu",
  "Buang, Central",
  "Buang, Mangga",
  "Bube",
  "Bubi",
  "Bubia",
  "Budibud",
  "Budja",
  "Budong-Budong",
  "Budu",
  "Budukh",
  "Buduma",
  "Bugan",
  "Bugawac",
  "Bughotu",
  "Bugis",
  "Buglere",
  "Bugun",
  "Buhid",
  "Buhutu",
  "Bukat",
  "Bukharic",
  "Bukitan",
  "Bukiyip",
  "Bukpe",
  "Buksa",
  "Bukusu",
  "Bukwen",
  "Bulgarian",
  "Bulgarian Sign Language",
  "Buli",
  "Bullom So",
  "Bulu",
  "Bulungan",
  "Bum",
  "Bumaji",
  "Bumang",
  "Bumthangkha",
  "Bun",
  "Buna",
  "Bunak",
  "Bunama",
  "Bundeli",
  "Bung",
  "Bungain",
  "Bunganditj",
  "Bungku",
  "Bungu",
  "Bunu, Bu-Nao",
  "Bunu, Jiongnai",
  "Bunu, Wunai",
  "Bunu, Younuo",
  "Bunuba",
  "Bunun",
  "Buol",
  "Bura-Pabir",
  "Burak",
  "Buraka",
  "Burarra",
  "Burate",
  "Burduna",
  "Bure",
  "Buriat",
  "Buriat, China",
  "Buriat, Mongolia",
  "Buriat, Russia",
  "Burji",
  "Burku",
  "Burmese",
  "Burmeso",
  "Buru",
  "Burui",
  "Burumakok",
  "Burun",
  "Burundian Sign Language",
  "Burunge",
  "Burushaski",
  "Burusu",
  "Buruwai",
  "Busam",
  "Busami",
  "Bushi",
  "Bushoong",
  "Buso",
  "Busoa",
  "Busuu",
  "Butmas-Tur",
  "Butuanon",
  "Buwal",
  "Buyang, Baha",
  "Buyang, E’ma",
  "Buyang, Langnian",
  "Buyu",
  "Bwa",
  "Bwaidoka",
  "Bwamu, Cwi",
  "Bwamu, Láá Láá",
  "Bwanabwana",
  "Bwatoo",
  "Bwela",
  "Bwile",
  "Bwisi",
  "Byangsi",
  "Byep",
  "C’Lela",
  "Caac",
  "Cabécar",
  "Cabiyarí",
  "Cacaopera",
  "Cacua",
  "Caddo",
  "Cafundo Creole",
  "Cahuarano",
  "Cahuilla",
  "Cahungwarya",
  "Caka",
  "Cakfem-Mushere",
  "Callawalla",
  "Caló",
  "Caluyanun",
  "Cambodian Sign Language",
  "Camsá",
  "Camtho",
  "Canela",
  "Canichana",
  "Cao Lan",
  "Cao Miao",
  "Capanahua",
  "Capiznon",
  "Cappadocian Greek",
  "Caquinte",
  "Cara",
  "Carabayo",
  "Caramanta",
  "Carapana",
  "Carib",
  "Carijona",
  "Carolina Algonquian",
  "Carolinian",
  "Carrier",
  "Carrier, Southern",
  "Catalan",
  "Catalan Sign Language",
  "Catawba",
  "Cavineña",
  "Cayubaba",
  "Cayuga",
  "Cayuse",
  "Cebuano",
  "Cemuhî",
  "Centúúm",
  "Cerma",
  "Ch’orti’",
  "Cha’ari",
  "Chachi",
  "Chácobo",
  "Chadian Sign Language",
  "Chadong",
  "Chagatai",
  "Chaima",
  "Chak",
  "Chakali",
  "Chakavian",
  "Chakma",
  "Chala",
  "Chaldean Neo-Aramaic",
  "Chalikha",
  "Cham",
  "Cham, Eastern",
  "Cham, Western",
  "Chamacoco",
  "Chamalal",
  "Chambeali",
  "Chambri",
  "Chamicuro",
  "Chamling",
  "Chamorro",
  "Chané",
  "Changriwa",
  "Changthang",
  "Chantyal",
  "Chatino, Eastern Highland",
  "Chatino, Nopala",
  "Chatino, Tataltepec",
  "Chatino, Western Highland",
  "Chatino, Zacatepec",
  "Chatino, Zenzontepec",
  "Chaudangsi",
  "Chaura",
  "Chavacano",
  "Chechen",
  "Chehalis, Lower",
  "Chehalis, Upper",
  "Cheke Holo",
  "Chemakum",
  "Chenapian",
  "Chenchu",
  "Chenoua",
  "Chepang",
  "Chepya",
  "Cheq Wong",
  "Cherepon",
  "Cherokee",
  "Chesu",
  "Chetco",
  "Chetti, Moundadan",
  "Chetti, Wayanad",
  "Cheyenne",
  "Chhattisgarhi",
  "Chhiling",
  "Chhintang",
  "Chiangmai Sign Language",
  "Chiapanec",
  "Chibcha",
  "Chichewa",
  "Chichimeco-Jonaz",
  "Chichonyi-Chidzihana-Chikauma",
  "Chickasaw",
  "Chicomuceltec",
  "Chidigo",
  "Chiduruma",
  "Chiga",
  "Chilcotin",
  "Chilean Sign Language",
  "Chilisso",
  "Chimariko",
  "Chimila",
  "Chin, Anu-Khongso",
  "Chin, Asho",
  "Chin, Bawm",
  "Chin, Bualkhaw",
  "Chin, Daai",
  "Chin, Eastern Khumi",
  "Chin, Falam",
  "Chin, Hakha",
  "Chin, Kaang",
  "Chin, Khumi",
  "Chin, Laitu",
  "Chin, Läoktü",
  "Chin, Lautu",
  "Chin, Matu",
  "Chin, Müün",
  "Chin, Ngawn",
  "Chin, Paite",
  "Chin, Rawngtu",
  "Chin, Rungtu",
  "Chin, Senthang",
  "Chin, Siyin",
  "Chin, Songlai",
  "Chin, Sumtu",
  "Chin, Tawr",
  "Chin, Tedim",
  "Chin, Thado",
  "Chin, Thaiphum",
  "Chin, Uppu",
  "Chin, Zotung",
  "Chin, Zyphe",
  "Chinali",
  "Chinantec, Chiltepec",
  "Chinantec, Comaltepec",
  "Chinantec, Lalana",
  "Chinantec, Lealao",
  "Chinantec, Ojitlán",
  "Chinantec, Ozumacín",
  "Chinantec, Palantla",
  "Chinantec, Quiotepec",
  "Chinantec, Sochiapam",
  "Chinantec, Tepetotutla",
  "Chinantec, Tepinapa",
  "Chinantec, Tlacoatzintepec",
  "Chinantec, Usila",
  "Chinantec, Valle Nacional",
  "Chinese",
  "Chinese Pidgin English",
  "Chinese Sign Language",
  "Chinese, Classical",
  "Chinese, Gan",
  "Chinese, Hakka",
  "Chinese, Huizhou",
  "Chinese, Jinyu",
  "Chinese, Mandarin",
  "Chinese, Min Bei",
  "Chinese, Min Dong",
  "Chinese, Min Nan",
  "Chinese, Min Zhong",
  "Chinese, Pu-Xian",
  "Chinese, Wu",
  "Chinese, Xiang",
  "Chinese, Yue",
  "Chingoni",
  "Chini",
  "Chinook",
  "Chinook Wawa",
  "Chipaya",
  "Chippewa",
  "Chiquitano",
  "Chiru",
  "Chitimacha",
  "Chittagonian",
  "Chocangacakha",
  "Chocholtec",
  "Choctaw",
  "Chodri",
  "Chokwe",
  "Chol",
  "Cholón",
  "Chong",
  "Choni",
  "Chontal, Highland Oaxaca",
  "Chontal, Lowland Oaxaca",
  "Chontal, Tabasco",
  "Choogen",
  "Chopechop",
  "Chopi",
  "Chorote, Iyo’wujwa",
  "Chorote, Iyojwa’ja",
  "Chrambo",
  "Chrau",
  "Chru",
  "Chuave",
  "Chufie’",
  "Chug",
  "Chuj",
  "Chukchi",
  "Chukwa",
  "Chulym",
  "Chumburung",
  "Chung",
  "Churahi",
  "Chut",
  "Chuukese",
  "Chuvantsy",
  "Chuvash",
  "Chuwabu",
  "Cia-Cia",
  "Cicipu",
  "Cimbrian",
  "Cineni",
  "Cinta Larga",
  "Cishingini",
  "Citak",
  "Citak, Tamnim",
  "Clallam",
  "Coahuilteco",
  "Cochimi",
  "Cocopa",
  "Coeur d’Alene",
  "Cofán",
  "Col",
  "Colombian Sign Language",
  "Columbia-Wenatchi",
  "Comanche",
  "Comecrudo",
  "Comorian, Maore",
  "Comorian, Mwali",
  "Comorian, Ndzwani",
  "Comorian, Ngazidja",
  "Comox",
  "Con",
  "Cook Islands Maori",
  "Côông",
  "Coos",
  "Coptic",
  "Coquille",
  "Cora, El Nayar",
  "Cora, Santa Teresa",
  "Cornish",
  "Corsican",
  "Costa Rican Sign Language",
  "Cotoname",
  "Cowlitz",
  "Cree",
  "Cree, Moose",
  "Cree, Northern East",
  "Cree, Plains",
  "Cree, Southern East",
  "Cree, Swampy",
  "Cree, Woods",
  "Crimean Tatar",
  "Croatian",
  "Croatian Sign Language",
  "Crow",
  "Cruzeño",
  "Cua",
  "Cuban Sign Language",
  "Cubeo",
  "Cuiba",
  "Cuicatec, Tepeuxila",
  "Cuicatec, Teutila",
  "Cuitlatec",
  "Cumanagoto",
  "Cun",
  "Cupeño",
  "Curripaco",
  "Cutchi-Swahili",
  "Cuvok",
  "Cuyonon",
  "Czech",
  "Czech Sign Language",
  "Daakaka",
  "Daantanai’",
  "Daasanach",
  "Daatsʼíin",
  "Daba",
  "Dabarre",
  "Dabe",
  "Dadi Dadi",
  "Dadibi",
  "Dadiya",
  "Daga",
  "Dagaare, Southern",
  "Dagaari Dioula",
  "Dagara, Northern",
  "Dagba",
  "Dagbani",
  "Dagik",
  "Dagoman",
  "Dahalik",
  "Dahalo",
  "Daho-Doo",
  "Dai",
  "Dair",
  "Daju, Dar Daju",
  "Daju, Dar Fur",
  "Daju, Dar Sila",
  "Dakka",
  "Dakota",
  "Dakpakha",
  "Dalabon",
  "Dalmatian",
  "Dama",
  "Damakawa",
  "Damal",
  "Damar, East",
  "Damar, West",
  "Dambi",
  "Dameli",
  "Dampelas",
  "Dan",
  "Danaru",
  "Danau",
  "Dangaléat",
  "Dangi",
  "Dangme",
  "Dani, Lower Grand Valley",
  "Dani, Mid Grand Valley",
  "Dani, Upper Grand Valley",
  "Dani, Western",
  "Danish",
  "Danish Sign Language",
  "Dano",
  "Danu",
  "Danuwar",
  "Daonda",
  "Darai",
  "Dargwa",
  "Dari",
  "Dari, Zoroastrian",
  "Darkinyung",
  "Darlong",
  "Darmiya",
  "Dass",
  "Datooga",
  "Daungwurrung",
  "Daur",
  "Davawenyo",
  "Dâw",
  "Dawawa",
  "Dawera-Daweloor",
  "Dawida",
  "Dawoodi",
  "Dawro",
  "Day",
  "Dayi",
  "Daza",
  "Dazaga",
  "Deccan",
  "Dedua",
  "Defaka",
  "Deg",
  "Deg Xinag",
  "Degano",
  "Degema",
  "Degenang",
  "Dehwari",
  "Dek",
  "Deko-Dusanga",
  "Dela-Oenale",
  "Delaware",
  "Delaware, Pidgin",
  "Delo",
  "Dem",
  "Dema",
  "Demisa",
  "Dendi",
  "Dene",
  "Dengese",
  "Dengka",
  "Dení",
  "Deno",
  "Denya",
  "Deori",
  "Dera",
  "Desano",
  "Desiya",
  "Dewas Rai",
  "Dewoin",
  "Dezfuli",
  "Dghwede",
  "Dhaiso",
  "Dhalandji",
  "Dhangu-Djangu",
  "Dhao",
  "Dharawal",
  "Dhargari",
  "Dharuk",
  "Dharumbal",
  "Dhatki",
  "Dhimal",
  "Dhimba",
  "Dhodia",
  "Dholuo",
  "Dhudhuroa",
  "Dhundari",
  "Dhungaloo",
  "Dhurga",
  "Dhuwal",
  "Dhuwaya",
  "Dia",
  "Dibiyaso",
  "Dibo",
  "Dibole",
  "Dida, Lakota",
  "Dida, Yocoboué",
  "Didinga",
  "Dido",
  "Digaro-Mishmi",
  "Dii",
  "Dilling",
  "Dima",
  "Dimasa",
  "Dimbong",
  "Dime",
  "Dineor",
  "Ding",
  "Dinka",
  "Dinka, Northeastern",
  "Dinka, Northwestern",
  "Dinka, South Central",
  "Dinka, Southeastern",
  "Dinka, Southwestern",
  "Dir-Nyamzak-Mbarimi",
  "Dirasha",
  "Dirim",
  "Dirya",
  "Disa",
  "Ditammari",
  "Ditidaht",
  "Diuwe",
  "Dixon Reef",
  "Diyari",
  "Dizin",
  "Djabugay",
  "Djabwurrung",
  "Djadjawurrung",
  "Djambarrpuyngu",
  "Djamindjung",
  "Djangun",
  "Djauan",
  "Djawi",
  "Djeebbana",
  "Djinang",
  "Djinba",
  "Djiwarli",
  "Dla",
  "Dobel",
  "Dobu",
  "Doe",
  "Doga",
  "Doghoro",
  "Dogon, Ampari",
  "Dogon, Ana Tinga",
  "Dogon, Bankan Tey",
  "Dogon, Ben Tey",
  "Dogon, Bondum Dom",
  "Dogon, Bunoge",
  "Dogon, Dogul Dom",
  "Dogon, Donno So",
  "Dogon, Jamsay",
  "Dogon, Mombo",
  "Dogon, Nanga Dama",
  "Dogon, Tebul Ure",
  "Dogon, Tene Kan",
  "Dogon, Tiranige Diga",
  "Dogon, Tommo So",
  "Dogon, Tomo Kan",
  "Dogon, Toro So",
  "Dogon, Toro Tegu",
  "Dogon, Yanda Dom",
  "Dogosé",
  "Dogoso",
  "Dogri",
  "Dokshi",
  "Dolgan",
  "Doloman",
  "Dolpo",
  "Dom",
  "Domari",
  "Dombe",
  "Dominican Sign Language",
  "Dompo",
  "Domu",
  "Domung",
  "Dondo",
  "Dong",
  "Dong, Northern",
  "Dong, Southern",
  "Dongo",
  "Dongotono",
  "Dongxiang",
  "Doondo",
  "Dori’o",
  "Dorig",
  "Doromu-Koki",
  "Dorze",
  "Doso",
  "Dotyali",
  "Doutai",
  "Doyayo",
  "Drehu",
  "Drents",
  "Drubea",
  "Drung",
  "Duala",
  "Duano",
  "Duau",
  "Dubli",
  "Dubuporo",
  "Dughore",
  "Dugun",
  "Duguri",
  "Dugwor",
  "Duhwa",
  "Duli-Gey",
  "Dulubu",
  "Duma",
  "Dumagat, Remontado",
  "Dumi",
  "Dumo",
  "Dumpas",
  "Dumun",
  "Duna",
  "Dungan",
  "Dungmali",
  "Dungra Bhil",
  "Dungu",
  "Duoluo",
  "Dura",
  "Duri",
  "Duriankere",
  "Durop",
  "Duruwa",
  "Dusner",
  "Dusun Deyah",
  "Dusun Malang",
  "Dusun Witu",
  "Dutch",
  "Duungooma",
  "Duupa",
  "Duvle",
  "Duwai",
  "Duwet",
  "Duya",
  "Dwang",
  "Dyaberdyaber",
  "Dyan",
  "Dyangadi",
  "Dyarim",
  "Dyirbal",
  "Dyugun",
  "Dza",
  "Dzalakha",
  "Dzando",
  "Dzao Min",
  "Dzhidi",
  "Dzongkha",
  "Dzùùngoo",
  "E’ñapa Woromaipu",
  "Eastern Franconian",
  "Ebira",
  "Ebughu",
  "Ecuadorian Sign Language",
  "Ede Cabe",
  "Ede Ica",
  "Ede Idaca",
  "Ede Ije",
  "Ede Nago, Kura",
  "Edo",
  "Edolo",
  "Edopi",
  "Efai",
  "Efate, North",
  "Efate, South",
  "Efe",
  "Efik",
  "Efutop",
  "Ega",
  "Eggon",
  "Egyptian Sign Language",
  "Ehueun",
  "Eibela",
  "Eitiep",
  "Ejagham",
  "Ejuele",
  "Ekajuk",
  "Ekari",
  "Ekegusii",
  "Eki",
  "Ekit",
  "Ekpeye",
  "El Hugeirat",
  "El Molo",
  "Elege",
  "Eleme",
  "Elepi",
  "Elkei",
  "Elseng",
  "Elu",
  "Emae",
  "Emai-Iuleha-Ora",
  "Eman",
  "Embaloh",
  "Embera Baudó",
  "Embera Catío",
  "Embera Chamí",
  "Embera Tadó",
  "Emberá, Northern",
  "Emem",
  "Emilian",
  "Emplawas",
  "En",
  "Enawené-Nawé",
  "Ende",
  "Enets, Forest",
  "Enets, Tundra",
  "Enga",
  "Engdewu",
  "Engenni",
  "Enggano",
  "English",
  "Enlhet",
  "Enrekang",
  "Enu",
  "Enwan",
  "Enxet",
  "Enya",
  "Epena",
  "Epie",
  "Equatorial Guinean Pidgin",
  "Eravallan",
  "Ere",
  "Eritai",
  "Erokwanas",
  "Erre",
  "Erromintxela",
  "Ersu",
  "Eruwa",
  "Erzya",
  "Esahie",
  "Esan",
  "Ese",
  "Ese Ejja",
  "Eshtehardi",
  "Esimbi",
  "Eskayan",
  "Esperanto",
  "Esselen",
  "Essono",
  "Estonian",
  "Estonian Sign Language",
  "Estonian, Standard",
  "Esuma",
  "Etchemin",
  "Etebi",
  "Ethiopian Sign Language",
  "Etkywan",
  "Eton",
  "Etsako",
  "Etulo",
  "Eudeve",
  "Evant",
  "Even",
  "Evenki",
  "Eviya",
  "Ewage-Notu",
  "Ewondo",
  "Extremaduran",
  "Eyak",
  "Ezaa",
  "Fa d’Ambu",
  "Fagani",
  "Fagauvea",
  "Faifi",
  "Faiwol",
  "Fala",
  "Fali Muchella",
  "Fali of Baissa",
  "Fali, North",
  "Fali, South",
  "Fam",
  "Fanamaket",
  "Fanbak",
  "Fang",
  "Fania",
  "Farefare",
  "Faroese",
  "Fars, Northwestern",
  "Fars, Southwestern",
  "Fasu",
  "Fataleka",
  "Fataluku",
  "Fayu",
  "Fe’fe’",
  "Fedan",
  "Fembe",
  "Feroge",
  "Fiji Hindi",
  "Fijian",
  "Fijian, Western",
  "Filipino",
  "Filipino Sign Language",
  "Finland-Swedish Sign Language",
  "Finnish",
  "Finnish Sign Language",
  "Finnish, Kven",
  "Finongan",
  "Fipa",
  "Firan",
  "Fiwaga",
  "Flaaitaal",
  "Flemish Sign Language",
  "Flinders Island",
  "Foau",
  "Foi",
  "Foia Foia",
  "Folopa",
  "Foma",
  "Fon",
  "Fongoro",
  "Foodo",
  "Forak",
  "Fordata",
  "Fore",
  "French",
  "French Belgian Sign Language",
  "French Sign Language",
  "French, Cajun",
  "Frisian",
  "Frisian, Northern",
  "Friulian",
  "Fulah",
  "Fulfulde, Adamawa",
  "Fulfulde, Bagirmi",
  "Fulfulde, Borgu",
  "Fulfulde, Central-Eastern Niger",
  "Fulfulde, Maasina",
  "Fulfulde, Nigerian",
  "Fulfulde, Western Niger",
  "Fuliiru",
  "Fulu",
  "Fulumu",
  "Fum",
  "Fungwa",
  "Fur",
  "Futuna-Aniwa",
  "Futuna, East",
  "Fuyug",
  "Fwâi",
  "Fwe",
  "Fyer",
  "Ga",
  "Ga’anda",
  "Gaa",
  "Gaam",
  "Gabi-Gabi",
  "Gabri",
  "Gabrielino-Fernandeño",
  "Gadaba, Bodo",
  "Gadaba, Mudhili",
  "Gadaba, Pottangi Ollar",
  "Gadang",
  "Gaddang",
  "Gaddi",
  "Gade",
  "Gadjerawang",
  "Gadsup",
  "Gafat",
  "Gagadu",
  "Gagauz",
  "Gahri",
  "Gaikundi",
  "Gail",
  "Gaina",
  "Gal",
  "Gala",
  "Galambi",
  "Galela",
  "Galeya",
  "Galice",
  "Galician",
  "Galolen",
  "Gamane",
  "Gambera",
  "Gamilaraay",
  "Gamit",
  "Gamkonora",
  "Gamo",
  "Gamo-Ningi",
  "Gana",
  "Ganang",
  "Ganda",
  "Gane",
  "Ganggalida",
  "Ganglau",
  "Gangte",
  "Gangulu",
  "Gants",
  "Ganza",
  "Ganzi",
  "Gao",
  "Gapapaiwa",
  "Garasia, Adiwasi",
  "Garasia, Rajput",
  "Garhwali",
  "Garifuna",
  "Garig-Ilgar",
  "Garingbal",
  "Garlali",
  "Garo",
  "Garre",
  "Garrwa",
  "Garus",
  "Garza",
  "Gata’",
  "Gavak",
  "Gavar",
  "Gavião do Jiparaná",
  "Gavião, Pará",
  "Gawar-Bati",
  "Gawri",
  "Gayil",
  "Gayo",
  "Gazi",
  "Gaꞌdang",
  "Gbagyi",
  "Gban",
  "Gbanu",
  "Gbanziri",
  "Gbari",
  "Gbaya",
  "Gbaya-Bossangoa",
  "Gbaya-Bozoum",
  "Gbaya-Mbodomo",
  "Gbaya, Northwest",
  "Gbaya, Southwest",
  "Gbayi",
  "Gbe, Ayizo",
  "Gbe, Ci",
  "Gbe, Defi",
  "Gbe, Eastern Xwla",
  "Gbe, Gbesi",
  "Gbe, Kotafon",
  "Gbe, Maxi",
  "Gbe, Saxwe",
  "Gbe, Tofin",
  "Gbe, Waci",
  "Gbe, Weme",
  "Gbe, Western Xwla",
  "Gbe, Xwela",
  "Gbii",
  "Gbin",
  "Gciriku",
  "Ge",
  "Gebe",
  "Gebusi",
  "Gedaged",
  "Gedeo",
  "Geez",
  "Gela",
  "Gelao, Green",
  "Gelao, Red",
  "Geme",
  "Gen",
  "Gende",
  "Gengle",
  "Georgian",
  "Gepo",
  "Gera",
  "Gerai",
  "German Sign Language",
  "German, Colonia Tovar",
  "German, Pennsylvania",
  "German, Standard",
  "German, Swiss",
  "Geruma",
  "Geser-Gorom",
  "Ghadamès",
  "Ghale, Northern",
  "Ghale, Southern",
  "Ghanaian Pidgin English",
  "Ghanaian Sign Language",
  "Ghandruk Sign Language",
  "Ghanongga",
  "Ghari",
  "Ghayavi",
  "Ghera",
  "Ghodoberi",
  "Ghomálá’",
  "Ghomara",
  "Ghotuo",
  "Ghulfan",
  "Gibanawa",
  "Gichuka",
  "Gidar",
  "Giiwo",
  "Gikuyu",
  "Gikyode",
  "Gilaki",
  "Gilima",
  "Gilyak",
  "Gimi",
  "Gimme",
  "Gimnime",
  "Ginuman",
  "Ginyanga",
  "Girawa",
  "Girirra",
  "Githabul",
  "Gitua",
  "Gitxsan",
  "Giyug",
  "Giziga",
  "Giziga, North",
  "Gizrra",
  "Glaro-Twabo",
  "Glavda",
  "Glio-Oubi",
  "Gnau",
  "Goaria",
  "Gobu",
  "Godié",
  "Godwari",
  "Goemai",
  "Gofa",
  "Gogo",
  "Gogodala",
  "Gokana",
  "Gola",
  "Golin",
  "Golpa",
  "Gondi",
  "Gondi, Adilabad",
  "Gondi, Aheri",
  "Gondi, Northern",
  "Gone Dau",
  "Gongduk",
  "Gonja",
  "Goo",
  "Goodenough, West",
  "Gooniyandi",
  "Gor",
  "Gorakor",
  "Gorap",
  "Goreng",
  "Gorontalo",
  "Gorovu",
  "Gorowa",
  "Goundo",
  "Gourmanchéma",
  "Gowlan",
  "Gowli",
  "Gowro",
  "Gozarkhani",
  "Grangali",
  "Great Andamanese, Mixed",
  "Grebo",
  "Grebo, Barclayville",
  "Grebo, Central",
  "Grebo, Gboloo",
  "Grebo, Northern",
  "Grebo, Southern",
  "Greek",
  "Greek Sign Language",
  "Greek, Ancient",
  "Greenlandic",
  "Grenadian English Creole",
  "Gresi",
  "Groma",
  "Gronings",
  "Gros Ventre",
  "Gua",
  "Guadeloupean French Creole",
  "Guahibo",
  "Guajá",
  "Guajajára",
  "Guana",
  "Guanche",
  "Guarani",
  "Guaraní, Ava",
  "Guaraní, Eastern Bolivian",
  "Guaraní, Mbyá",
  "Guaraní, Paraguayan",
  "Guaraní, Western Bolivian",
  "Guarayu",
  "Guarequena",
  "Guatemalan Sign Language",
  "Guató",
  "Guayabero",
  "Gudang",
  "Gudanji",
  "Gude",
  "Gudu",
  "Guduf-Gava",
  "Guébie",
  "Guernésiais",
  "Gugadj",
  "Gugu Badhun",
  "Gugu Warra",
  "Guhu-Samane",
  "Guianese French Creole",
  "Guinea-Bissau Creole",
  "Guinea-Bissau Sign Language",
  "Guinean Sign Language",
  "Guiqiong",
  "Gujarati",
  "Gujari",
  "Gula",
  "Gula Iro",
  "Gula’alaa",
  "Gulay",
  "Gule",
  "Gumalu",
  "Gumatj",
  "Gumawana",
  "Gumuz",
  "Gun",
  "Gundi",
  "Gunditjmara",
  "Gundungurra",
  "Gungabula",
  "Gungu",
  "Gunwinggu",
  "Gunya",
  "Gupa-Abawa",
  "Gupapuyngu",
  "Guramalum",
  "Gurani",
  "Gurdjar",
  "Gureng Gureng",
  "Gurgula",
  "Gurindji",
  "Gurindji Kriol",
  "Gurmana",
  "Guro",
  "Gurr-goni",
  "Gurung",
  "Guruntum-Mbaaru",
  "Gusilay",
  "Guugu Yimidhirr",
  "Guwa",
  "Guwamu",
  "Guya",
  "Guyanese English Creole",
  "Guyani",
  "Gvoko",
  "Gwa",
  "Gwahatike",
  "Gwak",
  "Gwama",
  "Gwamhi-Wuri",
  "Gwandara",
  "Gwatlelir",
  "Gweda",
  "Gweno",
  "Gwere",
  "Gwich’in",
  "Gyaazi",
  "Gyalsumdo",
  "Gyele",
  "Gyem",
  "Gyong",
  "Ha",
  "Habun",
  "Hadiyya",
  "Hadza",
  "Haeke",
  "Hahon",
  "Hai|ǁom",
  "Haida",
  "Haida, Northern",
  "Haida, Southern",
  "Haigwai",
  "Haiphong Sign Language",
  "Haisla",
  "Haitian Creole",
  "Haitian Vodoun Culture Language",
  "Haji",
  "Hajong",
  "Hakö",
  "Halang",
  "Halang Doan",
  "Halbi",
  "Halia",
  "Halkomelem",
  "Hamap",
  "Hamba",
  "Hamer-Banna",
  "Hamtai",
  "Han",
  "Hanga",
  "Hanga Hundi",
  "Hangaza",
  "Hani",
  "Hano",
  "Hanoi Sign Language",
  "Hanunoo",
  "Harari",
  "Haroi",
  "Haroti",
  "Harsusi",
  "Haruai",
  "Haruku",
  "Haryanvi",
  "Harzani",
  "Hasha",
  "Hassaniyya",
  "Hatam",
  "Hausa",
  "Hausa Sign Language",
  "Havasupai-Walapai-Yavapai",
  "Haveke",
  "Havu",
  "Hawai‘i Sign Language",
  "Hawaii Pidgin",
  "Hawaiian",
  "Hawu",
  "Haya",
  "Hazaragi",
  "Hdi",
  "Hebrew",
  "Hebrew, Ancient",
  "Hehe",
  "Heiban",
  "Heiltsuk",
  "Heiyoho",
  "Helong",
  "Hema",
  "Hemba",
  "Hember Avu",
  "Herdé",
  "Herero",
  "Hermit",
  "Hértevin",
  "Hewa",
  "Hibito",
  "Hidatsa",
  "Higaonon",
  "Hijuk",
  "Hiligaynon",
  "Himarimã",
  "Hindi",
  "Hindko, Northern",
  "Hindko, Southern",
  "Hinduri",
  "Hindustani, Sarnami",
  "Hinukh",
  "Hitu",
  "Hiw",
  "Hixkaryána",
  "Hlai",
  "Hlersu",
  "Hmar",
  "Hmong",
  "Hmong Daw",
  "Hmong Dô",
  "Hmong Don",
  "Hmong Njua",
  "Hmwaveke",
  "Ho",
  "Ho Chi Minh City Sign Language",
  "Ho-Chunk",
  "Hoava",
  "Hobyót",
  "Hodï",
  "Hohumono",
  "Hoia Hoia",
  "Holikachuk",
  "Holiya",
  "Holma",
  "Holoholo",
  "Holu",
  "Homa",
  "Honduran Sign Language",
  "Hõne",
  "Hong Kong Sign Language",
  "Honi",
  "Hopi",
  "Horo",
  "Horom",
  "Horpa",
  "Hoti",
  "Hovongan",
  "Hoyahoya",
  "Hozo",
  "Hpon",
  "Hrangkhol",
  "Hre",
  "Hruso",
  "Hu",
  "Huachipaeri",
  "Huarijío",
  "Huastec",
  "Huaulu",
  "Huave, San Dionisio del Mar",
  "Huave, San Francisco del Mar",
  "Huave, San Mateo del Mar",
  "Huave, Santa María del Mar",
  "Huichol",
  "Huilliche",
  "Hukumina",
  "Hulaulá",
  "Huli",
  "Hulung",
  "Humene",
  "Humla",
  "Hunde",
  "Hung",
  "Hungana",
  "Hungarian",
  "Hungarian Sign Language",
  "Hungu",
  "Hunjara-Kaina Ke",
  "Hunsrik",
  "Hunzib",
  "Hupa",
  "Hupdë",
  "Hupla",
  "Hutterisch",
  "Hwana",
  "Hya",
  "Hyam",
  "Hyolmo",
  "I-wak",
  "I’saka",
  "Iaai",
  "Iamalele",
  "Iatê",
  "Iatmul",
  "Iau",
  "Ibaas",
  "Ibaloi",
  "Iban",
  "Ibanag",
  "Ibani",
  "Ibatan",
  "Ibibio",
  "Ibino",
  "Ibu",
  "Ibuoro",
  "Icelandic",
  "Icelandic Sign Language",
  "Iceve-Maci",
  "Ida’an",
  "Idaté",
  "Idere",
  "Idesa",
  "Idi",
  "Idio",
  "Idoma",
  "Idu-Mishmi",
  "Iduna",
  "Ifè",
  "Ifo",
  "Ifugao, Amganad",
  "Ifugao, Batad",
  "Ifugao, Mayoyao",
  "Ifugao, Tuwali",
  "Igala",
  "Igana",
  "Igbo",
  "Igede",
  "Ignaciano",
  "Igo",
  "Iguta",
  "Igwe",
  "Iha",
  "Iha Based Pidgin",
  "Ihievbe",
  "Ijo, Southeast",
  "Ik",
  "Ika",
  "Ikaranggal",
  "Ikhin-Aokho",
  "Ikizu",
  "Iko",
  "Ikobi",
  "Ikoma-Nata-Isenye",
  "Ikpeng",
  "Ikpeshi",
  "Ikposo",
  "Iku-Gora-Ankwa",
  "Ikulu",
  "Ikwere",
  "Ikwo",
  "Ila",
  "Ile Ape",
  "Ili Turki",
  "Ili’uun",
  "Ilocano",
  "Ilue",
  "Imbongu",
  "Imonda",
  "Imotong",
  "Imroing",
  "Inabaknon",
  "Inagta Alabat",
  "Inapang",
  "Iñapari",
  "Indian Sign Language",
  "Indo-Portuguese",
  "Indonesian",
  "Indonesian Sign Language",
  "Indonesian, Makassar",
  "Indonesian, Peranakan",
  "Indri",
  "Ineseño",
  "Inga",
  "Inga, Jungle",
  "Ingrian",
  "Ingush",
  "Inku",
  "Innu",
  "Inoke-Yate",
  "Inonhan",
  "Inor",
  "Inter-Zab Jewish Neo-Aramaic",
  "International Sign",
  "Intha",
  "Inuinnaqtun",
  "Inuit Sign Language",
  "Inuktitut",
  "Inuktitut, Eastern Canadian",
  "Inupiaq",
  "Inupiatun, North Alaskan",
  "Inupiatun, Northwest Alaska",
  "Iowa-Oto",
  "Ipiko",
  "Ipili",
  "Ipulo",
  "Iquitu",
  "Ir",
  "Iranian Sign Language",
  "Irántxe",
  "Iranun",
  "Iraqw",
  "Irarutu",
  "Iraya",
  "Irish",
  "Irish Sign Language",
  "Irula",
  "Isabi",
  "Isanzu",
  "Isconahua",
  "Isebe",
  "Isekiri",
  "Ishkashimi",
  "Isinay",
  "Isirawa",
  "Island Carib",
  "Islander English Creole",
  "Isnag",
  "Isoko",
  "Israeli Sign Language",
  "Istriot",
  "Isu",
  "Isubu",
  "Italian",
  "Italian Sign Language",
  "Itang",
  "Itawit",
  "Itelmen",
  "Iten",
  "Itene",
  "Itik",
  "Itneg, Binongan",
  "Itneg, Inlaud",
  "Itneg, Maeng",
  "Itneg, Masadiit",
  "Itneg, Moyadan",
  "Ito",
  "Itonama",
  "Itu Mbon Uzo",
  "Itza’",
  "Iu Mien",
  "Ivatan",
  "Ivbie North-Okpela-Arhe",
  "Iwaidja",
  "Iwal",
  "Iwam",
  "Iwam, Sepik",
  "Ixcatec",
  "Ixil",
  "Iyansi",
  "Iyasa",
  "Iyayu",
  "Iyive",
  "Iyo",
  "Iyongiyong",
  "Izere",
  "Izii",
  "Izon",
  "Izora",
  "Jabutí",
  "Jad",
  "Jadgali",
  "Jah Hut",
  "Jahanka",
  "Jaitmatang",
  "Jakalteko",
  "Jakattoe",
  "Jakun",
  "Jalkunan",
  "Jamaican English Creole",
  "Jamaican Sign Language",
  "Jamamadí",
  "Jandai",
  "Jandavra",
  "Jangkang",
  "Jangshung",
  "Janji",
  "Japanese",
  "Japanese Sign Language",
  "Japreria",
  "Jaqaru",
  "Jara",
  "Jarai",
  "Jarawa",
  "Jaru",
  "Jaunsari",
  "Javanese",
  "Javanese, New Caledonian",
  "Javanese, Suriname",
  "Javindo",
  "Jawe",
  "Jaya",
  "Jebero",
  "Jeh",
  "Jehai",
  "Jejueo",
  "Jemez",
  "Jere",
  "Jeri Kuo",
  "Jerung",
  "Jewish Babylonian Aramaic",
  "Jhyankot Sign Language",
  "Jiamao",
  "Jiarong",
  "Jiba",
  "Jibu",
  "Jiiddu",
  "Jilbe",
  "Jilim",
  "Jimi",
  "Jimjimen",
  "Jina",
  "Jingpho",
  "Jingulu",
  "Jinuo, Buyuan",
  "Jinuo, Youle",
  "Jirel",
  "Jiru",
  "Jita",
  "Jju",
  "Jo",
  "Jofotek-Bromnya",
  "Jogi",
  "Jola-Felupe",
  "Jola-Fonyi",
  "Jola-Kasa",
  "Jonkor Bourmataguil",
  "Jopadhola",
  "Jorá",
  "Jordanian Sign Language",
  "Jowulu",
  "Juang",
  "Judeo-Arabic",
  "Judeo-Berber",
  "Judeo-Georgian",
  "Judeo-Italian",
  "Judeo-Tat",
  "Jukun Takum",
  "Jula",
  "Júma",
  "Jumjum",
  "Jumli",
  "Jumli Sign Language",
  "Jur Modo",
  "Juray",
  "Jurchen",
  "Jurúna",
  "Juu",
  "Juwar",
  "Juǀ’hoansi",
  "Jwira-Pepesa",
  "K’iche’",
  "Kaamba",
  "Kaan",
  "Kaansa",
  "Kaapor",
  "Kaapor Sign Language",
  "Kaba Démé, Sara",
  "Kaba Naa, Sara",
  "Kabalai",
  "Kabardian",
  "Kabatei",
  "Kabba",
  "Kabiyè",
  "Kabola",
  "Kaburi",
  "Kabutra",
  "Kabuverdianu",
  "Kabwa",
  "Kabwari",
  "Kabyle",
  "Kacchi",
  "Kachama-Ganjule",
  "Kachari",
  "Kachok",
  "Kadai",
  "Kadar",
  "Kadaru",
  "Kadazan Dusun",
  "Kadazan, Klias River",
  "Kadazan, Labuk-Kinabatangan",
  "Kadiwéu",
  "Kadu",
  "Kadung",
  "Kaduo",
  "Kaera",
  "Kafa",
  "Kafoa",
  "Kagayanen",
  "Kagoro",
  "Kagulu",
  "Kahe",
  "Kahua",
  "Kaibobo",
  "Kaidipang",
  "Kaiep",
  "Kaikadi",
  "Kaike",
  "Kaiku",
  "Kaili, Da’a",
  "Kaili, Ledo",
  "Kaili, Unde",
  "Kaimbé",
  "Kaimbulawa",
  "Kaingang",
  "Kaingáng, São Paulo",
  "Kairak",
  "Kairiru",
  "Kairui-Midiki",
  "Kais",
  "Kaitag",
  "Kaivi",
  "Kaiwá",
  "Kaiy",
  "Kajakse",
  "Kajali",
  "Kajaman",
  "Kakabai",
  "Kakabe",
  "Kakanda",
  "Kakataibo-Kashibo",
  "Kaki Ae",
  "Kako",
  "Kakwa",
  "Kala",
  "Kala Lagaw Ya",
  "Kalaamaya",
  "Kalabari",
  "Kalabra",
  "Kalagan",
  "Kalagan, Kagan",
  "Kalam",
  "Kalamsé",
  "Kalanadi",
  "Kalanga",
  "Kalanguya",
  "Kalao",
  "Kalapuya",
  "Kalarko",
  "Kalasha",
  "Kalenjin",
  "Kalinga, Butbut",
  "Kalinga, Limos",
  "Kalinga, Lubuagan",
  "Kalinga, Mabaka Valley",
  "Kalinga, Majukayang",
  "Kalinga, Southern",
  "Kalinga, Tanudan",
  "Kalinga, Vanaw",
  "Kalispel-Pend d’Oreille",
  "Kalkoti",
  "Kalkutung",
  "Kallahan, Keley-i",
  "Kalmyk-Oirat",
  "Kalou",
  "Kaluli",
  "Kalumpang",
  "Kam",
  "Kamakan",
  "Kamang",
  "Kamano",
  "Kamantan",
  "Kamar",
  "Kamara",
  "Kamarian",
  "Kamaru",
  "Kamas",
  "Kamasa",
  "Kamasau",
  "Kamayo",
  "Kamayurá",
  "Kamba",
  "Kambaata",
  "Kambera",
  "Kamberau",
  "Kambiwá",
  "Kami",
  "Kamo",
  "Kamoro",
  "Kamu",
  "Kamuku",
  "Kamula",
  "Kamwe",
  "Kanakanabu",
  "Kanamarí",
  "Kanan",
  "Kanashi",
  "Kanasi",
  "Kanauji",
  "Kandas",
  "Kandawo",
  "Kande",
  "Kandozi-Chapra",
  "Kanembu",
  "Kang",
  "Kanga",
  "Kangean",
  "Kanggape",
  "Kangjia",
  "Kango",
  "Kangri",
  "Kaniet",
  "Kanikkaran",
  "Kaningi",
  "Kaningra",
  "Kaninuwa",
  "Kanite",
  "Kanja",
  "Kanjari",
  "Kanju",
  "Kankanaey",
  "Kankanay, Northern",
  "Kannada",
  "Kanoé",
  "Kansa",
  "Kantosi",
  "Kanu",
  "Kanufi",
  "Kanum, Bädi",
  "Kanum, Ngkâlmpw",
  "Kanum, Smärky",
  "Kanum, Sota",
  "Kanuri",
  "Kanuri, Bilma",
  "Kanuri, Manga",
  "Kanuri, Tumari",
  "Kanuri, Yerwa",
  "Kanyok",
  "Kao",
  "Kaonde",
  "Kap",
  "Kapampangan",
  "Kapauri",
  "Kapin",
  "Kapinawá",
  "Kapingamarangi",
  "Kapriman",
  "Kaptiau",
  "Kapya",
  "Kaqchikel",
  "Kaqchikel-K’iche’ Mixed Language",
  "Kara",
  "Karaboro, Eastern",
  "Karaboro, Western",
  "Karachay-Balkar",
  "Karagas",
  "Karaim",
  "Karajá",
  "Karajarri",
  "Karakalpak",
  "Karami",
  "Karang",
  "Karanga",
  "Karankawa",
  "Karao",
  "Karas",
  "Karata",
  "Karawa",
  "Karawari",
  "Karbi",
  "Kare",
  "Karekare",
  "Karelian",
  "Karen, Bwe",
  "Karen, Geba",
  "Karen, Geko",
  "Karen, Mobwa",
  "Karen, Paku",
  "Karen, Phrae Pwo",
  "Karen, Pwo Eastern",
  "Karen, Pwo Northern",
  "Karen, Pwo Western",
  "Karen, S’gaw",
  "Karenggapa",
  "Karey",
  "Kari",
  "Karian",
  "Karimjo",
  "Karingani",
  "Karipuna",
  "Karipuna French Creole",
  "Karirí-Xocó",
  "Karitiâna",
  "Kariya",
  "Kariyarra",
  "Karkar-Yuri",
  "Karkin",
  "Karko",
  "Karnai",
  "Karo",
  "Karok",
  "Karon",
  "Karon Dori",
  "Karore",
  "Karuwali",
  "Kasabe",
  "Kasanga",
  "Kasem",
  "Kashaya",
  "Kashinawa",
  "Kashkay",
  "Kashmiri",
  "Kashubian",
  "Kasiguranin",
  "Kaska",
  "Kasua",
  "Kata Kolok",
  "Katabagan",
  "Katang, Northern",
  "Katang, Southern",
  "Katawixi",
  "Katcha-Kadugli-Miri",
  "Kâte",
  "Kateviri",
  "Kathu",
  "Katkari",
  "Katla",
  "Kato",
  "Katso",
  "Katu, Eastern",
  "Katu, Western",
  "Katua",
  "Katukína",
  "Katukína, Panoan",
  "Kaulong",
  "Kaur",
  "Kaure",
  "Kaurna",
  "Kauwera",
  "Kavalan",
  "Kavet",
  "Kawacha",
  "Kawaiisu",
  "Kawe",
  "Kawyaw",
  "Kaxararí",
  "Kaxuiâna",
  "Kayabí",
  "Kayagar",
  "Kayah, Eastern",
  "Kayah, Western",
  "Kayan",
  "Kayan Mahakam",
  "Kayan, Baram",
  "Kayan, Busang",
  "Kayan, Kayan River",
  "Kayan, Mendalam",
  "Kayan, Rejang",
  "Kayan, Wahau",
  "Kayapó",
  "Kayardild",
  "Kayaw",
  "Kayeli",
  "Kayong",
  "Kaytetye",
  "Kayupulau",
  "Kazakh",
  "Kazukuru",
  "Ke’o",
  "Keapara",
  "Kebu",
  "Kedang",
  "Keerray-Woorroong",
  "Kehu",
  "Kei",
  "Keiga",
  "Keijar",
  "Kein",
  "Keiyo",
  "Kela",
  "Kelabit",
  "Kele",
  "Kélé",
  "Keliko",
  "Kelo",
  "Kemak",
  "Kemberano",
  "Kembra",
  "Kemedzung",
  "Kemiehua",
  "Kemtuik",
  "Kenaboi",
  "Kendayan",
  "Kendeje",
  "Kendem",
  "Kenga",
  "Keninjal",
  "Kensiu",
  "Kenswei Nsei",
  "Kenyah, Mainstream",
  "Kenyah, Wahau",
  "Kenyan Sign Language",
  "Kenyang",
  "Kenye",
  "Keoru-Ahia",
  "Kepkiriwát",
  "Kepo’",
  "Kera",
  "Kerak",
  "Kereho",
  "Kerek",
  "Keres, Eastern",
  "Keres, Western",
  "Kerewe",
  "Kerewo",
  "Kerinci",
  "Ket",
  "Ketangalan",
  "Kete",
  "Ketengban",
  "Ketum",
  "Kewapi, East",
  "Kewapi, West",
  "Kewat",
  "Keyagana",
  "Kgalagadi",
  "Khah",
  "Khakas",
  "Khalaj",
  "Khaling",
  "Kham, Eastern Parbate",
  "Kham, Gamal",
  "Kham, Sheshi",
  "Kham, Western Parbate",
  "Khamba",
  "Khamnigan Mongol",
  "Khamti",
  "Khamyang",
  "Khana",
  "Khandesi",
  "Kháng",
  "Khanty",
  "Khao",
  "Kharia",
  "Kharia Thar",
  "Khasi",
  "Khe",
  "Khehek",
  "Khengkha",
  "Khetrani",
  "Khinalugh",
  "Khirwar",
  "Khisa",
  "Khlula",
  "Khmer",
  "Khmer, Northern",
  "Khmu",
  "Kho’ini",
  "Khoekhoe",
  "Kholok",
  "Khorasani Turkish",
  "Khowar",
  "Khua",
  "Khuen",
  "Khün",
  "Khunsari",
  "Khvarshi",
  "Khwedam",
  "Kiae",
  "Kibaku",
  "Kibala",
  "Kibet",
  "Kibiri",
  "Kickapoo",
  "Kiembu",
  "Kigiryama",
  "Kija",
  "Kikai",
  "Kikongo",
  "Kilivila",
  "Kiliwa",
  "Kilmeri",
  "Kim",
  "Kim Mun",
  "Kimaghima",
  "Kimaragang",
  "Kimbu",
  "Kimbundu",
  "Kimîîru",
  "Kimki",
  "Kimré",
  "Kinabalian",
  "Kinabatangan, Upper",
  "Kinalakna",
  "Kinaray-a",
  "Kinga",
  "Kinnauri",
  "Kinnauri, Bhoti",
  "Kinnauri, Chhoyul",
  "Kinnauri, Chitkuli",
  "Kinnauri, Pahari",
  "Kintaq",
  "Kinuku",
  "Kinyarwanda",
  "Kioko",
  "Kiong",
  "Kiorr",
  "Kiowa",
  "Kipfokomu",
  "Kipsigis",
  "Kiput",
  "Kir-Balar",
  "Kire",
  "Kiribati",
  "Kirike",
  "Kirikiri",
  "Kirya-Konzel",
  "Kis",
  "Kisan",
  "Kisankasa",
  "Kisar",
  "Kisi",
  "Kisi, Southern",
  "Kissi, Northern",
  "Kistane",
  "Kitharaka",
  "Kitsai",
  "Kituba",
  "Kiunum",
  "Kiviila",
  "Kiwai, Northeast",
  "Kiwai, Southern",
  "Kiwilwana",
  "Kiyombe",
  "Kizamani",
  "Kla-Dan",
  "Klamath-Modoc",
  "Klao",
  "Klon",
  "Knaanic",
  "Ko",
  "Koalib",
  "Koasati",
  "Koba",
  "Kobiana",
  "Kobo",
  "Kobon",
  "Kobuka",
  "Koch",
  "Koda",
  "Kodaku",
  "Kodava",
  "Kodeoha",
  "Kodi",
  "Kodia",
  "Kodut, Middle",
  "Kodut, North",
  "Kodut, South",
  "Koenoem",
  "Kofa",
  "Kofei",
  "Kofyar",
  "Kogi",
  "Kohin",
  "Kohistani, Indus",
  "Koho",
  "Koiali, Mountain",
  "Koiari",
  "Koireng",
  "Koita",
  "Koiwat",
  "Kok Borok",
  "Kok-Nar",
  "Kokata",
  "Koke",
  "Koko Babangk",
  "Koko-Bera",
  "Kokoda",
  "Kokola",
  "Kokota",
  "Kol",
  "Kola",
  "Kolami, Northwestern",
  "Kolami, Southeastern",
  "Kolbila",
  "Koli, Kachi",
  "Koli, Parkari",
  "Koli, Wadiyari",
  "Koluwawa",
  "Kom",
  "Koma",
  "Komba",
  "Kombai",
  "Kombe",
  "Kombio",
  "Komering",
  "Komi",
  "Komi-Permyak",
  "Komi-Zyrian",
  "Kominimung",
  "Komo",
  "Komodo",
  "Kompane",
  "Komviri",
  "Komyandaret",
  "Kon Keu",
  "Konabéré",
  "Konai",
  "Konchri Sain",
  "Konda",
  "Konda-Dora",
  "Koneq-koneq",
  "Koneraw",
  "Kongo",
  "Konjo, Coastal",
  "Konjo, Highland",
  "Konkani",
  "Konkani, Goan",
  "Konkomba",
  "Konni",
  "Kono",
  "Konomala",
  "Konongo",
  "Konso",
  "Konyanka",
  "Konzo",
  "Koongo",
  "Koonzime",
  "Koorete",
  "Kopar",
  "Kopkaka",
  "Korafe-Yegha",
  "Koraga, Korra",
  "Koraga, Mudu",
  "Korana",
  "Korandje",
  "Korean",
  "Korean Sign Language",
  "Koreguaje",
  "Koresh-e Rostam",
  "Korku",
  "Korlai Portuguese Creole",
  "Koro",
  "Koro Nulu",
  "Koro Wachi",
  "Koro Zuba",
  "Koromfé",
  "Koromira",
  "Koromu",
  "Koroni",
  "Koropó",
  "Koroshi",
  "Korowai",
  "Korubo",
  "Korupun-Sela",
  "Korwa",
  "Koryak",
  "Kosare",
  "Kosena",
  "Koshin",
  "Kosraean",
  "Kota",
  "Kota Marudu Talantang",
  "Koti",
  "Kott",
  "Kou",
  "Kouya",
  "Kovai",
  "Kove",
  "Kovol",
  "Kowaki",
  "Kowiai",
  "Koy Sanjaq Surat",
  "Koya",
  "Koyaga",
  "Koyee",
  "Koyo",
  "Koyukon",
  "Kpagua",
  "Kpala",
  "Kpan",
  "Kpasham",
  "Kpati",
  "Kpatili",
  "Kpeego",
  "Kpelle",
  "Kpelle, Guinea",
  "Kpelle, Liberia",
  "Kpessi",
  "Kplang",
  "Krache",
  "Krahn, Eastern",
  "Krahn, Western",
  "Krahô",
  "Kraol",
  "Krenak",
  "Krevinian",
  "Kreye",
  "Kriang",
  "Krikati-Timbira",
  "Krimchak",
  "Krio",
  "Kriol",
  "Krobu",
  "Krongo",
  "Krumen, Plapo",
  "Krumen, Pye",
  "Krumen, Tepo",
  "Krung",
  "Kryts",
  "Kua",
  "Kua-nsi",
  "Kuamasi",
  "Kuan",
  "Kuanhua",
  "Kuanua",
  "Kuay",
  "Kubachi",
  "Kube",
  "Kubi",
  "Kubo",
  "Kubu",
  "Kuce",
  "Kucong",
  "Kudiya",
  "Kudmali",
  "Kudu-Camo",
  "Kufr Qassem Sign Language",
  "Kugama",
  "Kugbo",
  "Kugu-Muminh",
  "Kuhane",
  "Kui",
  "Kui, Dawik",
  "Kuijau",
  "Kuikúro-Kalapálo",
  "Kujarge",
  "Kuk",
  "Kukama-Kukamiria",
  "Kukatja",
  "Kuke",
  "Kukele",
  "Kukna",
  "Kuku",
  "Kuku-Mangk",
  "Kuku-Mu’inh",
  "Kuku-Ugbanh",
  "Kuku-Uwanh",
  "Kuku-Yalanji",
  "Kula",
  "Kulango, Bondoukou",
  "Kulango, Bouna",
  "Kulere",
  "Kulfa",
  "Kulina",
  "Kulina Pano",
  "Kulisusu",
  "Kulon",
  "Kulung",
  "Kumal",
  "Kumalu",
  "Kumam",
  "Kuman",
  "Kumaoni",
  "Kumarbhag Paharia",
  "Kumba",
  "Kumbainggar",
  "Kumbaran",
  "Kumbewaha",
  "Kumiai",
  "Kumukio",
  "Kumyk",
  "Kumzari",
  "Kuna, Border",
  "Kuna, San Blas",
  "Kunama",
  "Kunbarlang",
  "Kunda",
  "Kundal Shahi",
  "Kunduvadi",
  "Kung",
  "Kung-Ekoka",
  "Kungarakany",
  "Kungardutyi",
  "Kunggari",
  "Kungkari",
  "Kuni",
  "Kuni-Boazi",
  "Kunigami",
  "Kunimaipa",
  "Kunjen",
  "Kunyi",
  "Kunza",
  "Kuo",
  "Kuot",
  "Kupa",
  "Kupia",
  "Kupsapiiny",
  "Kur",
  "Kurama",
  "Kuranko",
  "Kurdish",
  "Kurdish, Central",
  "Kurdish, Northern",
  "Kurdish, Southern",
  "Kuri",
  "Kuria",
  "Kurichiya",
  "Kurmukar",
  "Kurnai",
  "Kurrama",
  "Kursav",
  "Kurti",
  "Kurtokha",
  "Kuruáya",
  "Kurudu",
  "Kurumba, Alu",
  "Kurumba, Attapady",
  "Kurumba, Betta",
  "Kurumba, Jennu",
  "Kurumba, Kannada",
  "Kurumba, Mullu",
  "Kurux",
  "Kusaal",
  "Kusaghe",
  "Kushi",
  "Kuskokwim, Upper",
  "Kusu",
  "Kusunda",
  "Kusur-Myet",
  "Kutenai",
  "Kutep",
  "Kuthant",
  "Kutong",
  "Kutto",
  "Kutu",
  "Kuturmi",
  "Kuuk Thayorre",
  "Kuuk-Yak",
  "Kuuku-Ya’u",
  "Kuvale",
  "Kuvi",
  "Kuwaa",
  "Kuwaataay",
  "Kuwema",
  "Kw’adza",
  "Kwa",
  "Kwa’",
  "Kwaami",
  "Kwadi",
  "Kwaio",
  "Kwaja",
  "Kwakum",
  "Kwakwala",
  "Kwalhioqua-Tlatskanai",
  "Kwambi",
  "Kwamera",
  "Kwami",
  "Kwandu",
  "Kwang",
  "Kwanga",
  "Kwangali",
  "Kwanja",
  "Kwara’ae",
  "Kwasio",
  "Kwaya",
  "Kwaza",
  "Kwegu",
  "Kwer",
  "Kwerba",
  "Kwerba Mamberamo",
  "Kwere",
  "Kwerisa",
  "Kwese",
  "Kwesten",
  "Kwini",
  "Kwinsu",
  "Kwinti",
  "Kwoma",
  "Kwomtari",
  "Kyak",
  "Kyaka",
  "Kyanga",
  "Kyenele",
  "Kyerung",
  "Kyoli",
  "Kyrgyz",
  "La’bi",
  "Laal",
  "Laalaa",
  "Laari",
  "Laarim",
  "Laba",
  "Labalekan-Mingar",
  "Label",
  "Labir",
  "Labu",
  "Lacandon",
  "Lachi",
  "Lachi, White",
  "Lacid",
  "Ladakhi",
  "Ladin",
  "Ladino",
  "Ladji Ladji",
  "Laeko-Libuat",
  "Lafofa",
  "Laghu",
  "Laghuu",
  "Lagwan",
  "Laha",
  "Lahanan",
  "Lahnda",
  "Lahta",
  "Lahu",
  "Lahu Shi",
  "Laimbue",
  "Laiyolo",
  "Lak",
  "Laka",
  "Lakalei",
  "Lakha",
  "Laki",
  "Lakkia",
  "Lakon",
  "Lakondê",
  "Lakota",
  "Lakurumau",
  "Lala",
  "Lala-Bisa",
  "Lala-Roba",
  "Lalia",
  "Lalo, Central",
  "Lalo, Dongshanba",
  "Lalu, Eastern",
  "Lalu, Western",
  "Lama",
  "Lamaholot",
  "Lamalama",
  "Lamalera",
  "Lamang",
  "Lamatuka",
  "Lamba",
  "Lambadi",
  "Lamboya",
  "Lambya",
  "Lame",
  "Lamenu",
  "Lamja-Dengsa-Tola",
  "Lamkang",
  "Lamnsoʼ",
  "Lamogai",
  "Lampung Api",
  "Lampung Nyo",
  "Lamu",
  "Landoma",
  "Lang’e",
  "Langbashe",
  "Lango",
  "Lanima",
  "Lanoh",
  "Lao",
  "Laomian",
  "Laopang",
  "Laos Sign Language",
  "Laragia",
  "Lardil",
  "Larevat",
  "Lari",
  "Larike-Wakasihu",
  "Laro",
  "Larteh",
  "Laru",
  "Lasalimu",
  "Lasgerdi",
  "Lasi",
  "Latgalian",
  "Latin",
  "Latu",
  "Latundê",
  "Latvian",
  "Latvian Sign Language",
  "Latvian, Standard",
  "Lau",
  "Laua",
  "Lauan",
  "Lauje",
  "Laurentian",
  "Lavatbura-Lamusong",
  "Laven",
  "Lavi",
  "Lavrung",
  "Lavukaleve",
  "Lawa, Eastern",
  "Lawa, Western",
  "Lawangan",
  "Lawu",
  "Lawunuia",
  "Layakha",
  "Laz",
  "Leco",
  "Leelau",
  "Leeward Caribbean English Creole",
  "Lefa",
  "Lega-Mwenga",
  "Lega-Shabunda",
  "Legbo",
  "Legenyem",
  "Lehali",
  "Leipon",
  "Lelak",
  "Lele",
  "Lelemi",
  "Lelepa",
  "Lembena",
  "Lemerig",
  "Lemio",
  "Lemoro",
  "Lenakel",
  "Lenca",
  "Lendu",
  "Lengilu",
  "Lengo",
  "Lengola",
  "Leningitij",
  "Lenje",
  "Lenkau",
  "Lenyima",
  "Lepcha",
  "Lepki",
  "Lere",
  "Lese",
  "Lesser Antillean French Creole",
  "Letemboi",
  "Leti",
  "Levuka",
  "Lewo",
  "Lewoeleng",
  "Lewotobi",
  "Leyigha",
  "Lezgi",
  "Lhao Vo",
  "Lhokpu",
  "Lhomi",
  "Lhowa",
  "Li’o",
  "Liabuku",
  "Liana-Seti",
  "Liberian English",
  "Libido",
  "Libinza",
  "Libyan Sign Language",
  "Lidzonka",
  "Ligbi",
  "Ligenza",
  "Ligurian",
  "Lihir",
  "Lik",
  "Lika",
  "Liki",
  "Likila",
  "Likuba",
  "Likum",
  "Likwala",
  "Lilau",
  "Lillooet",
  "Limassa",
  "Limba, East",
  "Limba, West-Central",
  "Limbu",
  "Limbum",
  "Limburgish",
  "Limi",
  "Limilngan",
  "Limola",
  "Lingala",
  "Lingao",
  "Lingua Franca",
  "Lipo",
  "Lisabata-Nuniali",
  "Lisela",
  "Lish",
  "Lishán Noshan",
  "Lishana Deni",
  "Lisu",
  "Lithuanian",
  "Lithuanian Sign Language",
  "Litzlitz",
  "Liv",
  "Livvi-Karelian",
  "Lo-Toga",
  "Loarki",
  "Lobala",
  "Lobi",
  "Lobu, Lanas",
  "Lobu, Tampias",
  "Lodhi",
  "Logba",
  "Logir",
  "Logo",
  "Logol",
  "Logorik",
  "Lohar, Gade",
  "Lohar, Lahul",
  "Lohorung",
  "Lokaa",
  "Loko",
  "Lokoya",
  "Lola",
  "Lolak",
  "Lole",
  "Lolo",
  "Loloda",
  "Lolopo",
  "Lolopo, Southern",
  "Loma",
  "Lomaiviti",
  "Lomavren",
  "Lombard",
  "Lombi",
  "Lombo",
  "Lomwe",
  "Lomwe, Malawi",
  "Long Wat",
  "Longgu",
  "Longto",
  "Longuda",
  "Loniu",
  "Lonwolwol",
  "Lonzo",
  "Loo",
  "Lopa",
  "Lopi",
  "Lopit",
  "Lorang",
  "Lorediakarkar",
  "Lote",
  "Lotud",
  "Lou",
  "Louisiana Creole",
  "Loun",
  "Loup A",
  "Loup B",
  "Loura",
  "Lovono",
  "Lower Burdekin",
  "Lower Nossob",
  "Löyöp",
  "Lozi",
  "Lü",
  "Luang",
  "Luba-Kasai",
  "Luba-Katanga",
  "Lubila",
  "Lubu",
  "Luchazi",
  "Lucumi",
  "Ludian",
  "Lufu",
  "Lugbara",
  "Luguru",
  "Luhu",
  "Luidakho-Luisukha-Lutirichi",
  "Luimbi",
  "Luiseño",
  "Lukabaras",
  "Lukpa",
  "Lule",
  "Lulogooli",
  "Lumbu",
  "Lumun",
  "Luna",
  "Lunanakha",
  "Lunda",
  "Lundayeh",
  "Lungga",
  "Luri",
  "Luri, Northern",
  "Luri, Southern",
  "Lusengo",
  "Lushootseed",
  "Lusi",
  "Lutachoni",
  "Luto",
  "Luvale",
  "Luwati",
  "Luwo",
  "Luxembourgish",
  "Luyana",
  "Lwalu",
  "Lwel",
  "Lyélé",
  "Lyngngam",
  "Ma",
  "Ma Manda",
  "Ma’anyan",
  "Ma’di",
  "Ma’di, Southern",
  "Ma’ya",
  "Maa",
  "Maaka",
  "Maan",
  "Maasai",
  "Maay",
  "Maba",
  "Mabaale",
  "Mabaan",
  "Mabire",
  "Macaguaje",
  "Macaguán",
  "Macanese",
  "Macedonian",
  "Machame",
  "Machinere",
  "Machinga",
  "Maco",
  "Macuna",
  "Macushi",
  "Mada",
  "Madak",
  "Madhi Madhi",
  "Madi",
  "Madura",
  "Mae",
  "Maewo, Central",
  "Mafa",
  "Mafea",
  "Magahi",
  "Magar, Eastern",
  "Magar, Western",
  "Mághdì",
  "Magi",
  "Magiyi",
  "Magɨ",
  "Magoma",
  "Magori",
  "Maguindanaon",
  "Mah Meri",
  "Mahali",
  "Mahican",
  "Mahongwe",
  "Mahou",
  "Mai Brat",
  "Maia",
  "Maiadomu",
  "Maiani",
  "Maidu, Northeast",
  "Maidu, Northwest",
  "Maidu, Valley",
  "Maii",
  "Maijuna",
  "Maindo",
  "Mairasi",
  "Maisin",
  "Maithili",
  "Maiwa",
  "Maiwala",
  "Majang",
  "Majera",
  "Majhi",
  "Majhwar",
  "Mak",
  "Maka",
  "Makaa",
  "Makah",
  "Makalero",
  "Makasae",
  "Makasar",
  "Makayam",
  "Makhuwa",
  "Makhuwa-Marrevone",
  "Makhuwa-Meetto",
  "Makhuwa-Moniga",
  "Makhuwa-Saka",
  "Makhuwa-Shirima",
  "Makian, East",
  "Makian, West",
  "Maklew",
  "Makolkol",
  "Makonde",
  "Máku",
  "Makuráp",
  "Makuva",
  "Makwe",
  "Mal",
  "Mal Paharia",
  "Mala",
  "Mala Malasar",
  "Malaccan Malay Creole",
  "Malaccan Portuguese Creole",
  "Malagasy",
  "Malagasy Sign Language",
  "Malagasy, Antankarana",
  "Malagasy, Bara",
  "Malagasy, Masikoro",
  "Malagasy, Merina",
  "Malagasy, Northern Betsimisaraka",
  "Malagasy, Sakalava",
  "Malagasy, Southern Betsimisaraka",
  "Malagasy, Tandroy-Mahafaly",
  "Malagasy, Tanosy",
  "Malagasy, Tesaka",
  "Malagasy, Tsimihety",
  "Malak Malak",
  "Malango",
  "Malankuravan",
  "Malapandaram",
  "Malaryan",
  "Malasar",
  "Malavedan",
  "Malawian Sign Language",
  "Malay",
  "Malay, Ambonese",
  "Malay, Baba",
  "Malay, Bacanese",
  "Malay, Balinese",
  "Malay, Banda",
  "Malay, Berau",
  "Malay, Bukit",
  "Malay, Central",
  "Malay, Cocos Islands",
  "Malay, Jambi",
  "Malay, Kedah",
  "Malay, Kota Bangun Kutai",
  "Malay, Kupang",
  "Malay, Larantuka",
  "Malay, Manado",
  "Malay, North Moluccan",
  "Malay, Papuan",
  "Malay, Pattani",
  "Malay, Sabah",
  "Malay, Standard",
  "Malay, Tenggarong Kutai",
  "Malayalam",
  "Malayic Dayak",
  "Malaynon",
  "Malaysian Sign Language",
  "Maldivian",
  "Male",
  "Malecite-Passamaquoddy",
  "Malei",
  "Maléku Jaíka",
  "Maleng",
  "Maleu-Kilenge",
  "Malgana",
  "Malgbe",
  "Mali",
  "Malian Sign Language",
  "Malila",
  "Malimpung",
  "Malo",
  "Malol",
  "Maltese",
  "Maltese Sign Language",
  "Malua Bay",
  "Malvi",
  "Malyangapa",
  "Mam",
  "Mama",
  "Mamaindê",
  "Mamasa",
  "Mambae",
  "Mambai",
  "Mambila, Cameroon",
  "Mambila, Nigeria",
  "Mamboru",
  "Mambwe-Lungu",
  "Mampruli",
  "Mamuju",
  "Mamulique",
  "Mamusi",
  "Mamvu",
  "Man Met",
  "Manam",
  "Manambu",
  "Manangkari",
  "Manat",
  "Manchu",
  "Mand",
  "Manda",
  "Mandahuaca",
  "Mandaic, Classical",
  "Mandan",
  "Mandandanyi",
  "Mandar",
  "Mandara",
  "Mandari",
  "Mandaya",
  "Mandeali",
  "Mander",
  "Mandingo",
  "Mandinka",
  "Mandja",
  "Mandjak",
  "Mandobo Atas",
  "Mandobo Bawah",
  "Manem",
  "Manep",
  "Mang",
  "Mangala",
  "Mangareva",
  "Mangarrayi",
  "Mangayat",
  "Mangbetu",
  "Mangbutu",
  "Mangerr",
  "Manggarai",
  "Mango",
  "Mangole",
  "Mangseng",
  "Mangue",
  "Manide",
  "Maninka, Sankaran",
  "Maninkakan, Eastern",
  "Maninkakan, Kita",
  "Maninkakan, Western",
  "Manipa",
  "Mankanya",
  "Mankiyali",
  "Mankong",
  "Manna-Dora",
  "Mannan",
  "Manobo, Agusan",
  "Manobo, Ata",
  "Manobo, Cotabato",
  "Manobo, Dibabawon",
  "Manobo, Ilianen",
  "Manobo, Kinamiging",
  "Manobo, Matigsalug",
  "Manobo, Obo",
  "Manobo, Rajah Kabunsuwan",
  "Manobo, Sarangani",
  "Manobo, Western Bukidnon",
  "Manombai",
  "Mansaka",
  "Mansi",
  "Mansoanka",
  "Manta",
  "Mantsi",
  "Manx",
  "Manya",
  "Manyawa",
  "Manyika",
  "Maonan",
  "Maori",
  "Mape",
  "Mapena",
  "Mapia",
  "Mapidian",
  "Mapoyo",
  "Mapudungun",
  "Mapun",
  "Maquiritari",
  "Mara",
  "Maraghei",
  "Maragus",
  "Maranao",
  "Marangis",
  "Maranunggu",
  "Mararit",
  "Marathi",
  "Marau",
  "Marba",
  "Mardin Sign Language",
  "Marenje",
  "Marfa",
  "Margany",
  "Marghi Central",
  "Marghi South",
  "Margu",
  "Mari",
  "Mari, Hill",
  "Mari, Meadow",
  "Maria",
  "Maria, Dandami",
  "Maricopa",
  "Maridan",
  "Maridjabin",
  "Marik",
  "Marimanindji",
  "Marind",
  "Marind, Bian",
  "Maring",
  "Maringarr",
  "Marino",
  "Mariri",
  "Maritime Sign Language",
  "Maritsauá",
  "Mariyedi",
  "Marka",
  "Markweeta",
  "Marma",
  "Marovo",
  "Marquesan, North",
  "Marquesan, South",
  "Marra",
  "Marriammu",
  "Marrithiyel",
  "Marshallese",
  "Martha’s Vineyard Sign Language",
  "Marti Ke",
  "Martu Wangka",
  "Martuyhunira",
  "Marubo",
  "Marwari",
  "Masaaba",
  "Masalit",
  "Masana",
  "Masbatenyo",
  "Masela, Central",
  "Masela, East",
  "Masela, West",
  "Mashco Piro",
  "Mashi",
  "Masimasi",
  "Masiwang",
  "Maskelynes",
  "Maslam",
  "Masmaje",
  "Massalat",
  "Massep",
  "Matagalpa",
  "Matal",
  "Matambwe",
  "Matbat",
  "Matengo",
  "Matepi",
  "Mateq",
  "Matipuhy",
  "Matís",
  "Matlatzinca, Atzingo",
  "Matlatzinca, San Francisco",
  "Matngala",
  "Mato",
  "Mator",
  "Matses",
  "Matsigenka",
  "Mattokki",
  "Mattole",
  "Matukar Panau",
  "Matumbi",
  "Maung",
  "Mauritian Sign Language",
  "Mauwake",
  "Mawa",
  "Mawak",
  "Mawan",
  "Mawayana",
  "Mawchi",
  "Mawes",
  "Mawes Aasse",
  "Maxakalí",
  "Maya, Mopán",
  "Maya, Yucatec",
  "Mayaguduna",
  "Mayangna",
  "Mayawali",
  "Mayeka",
  "Mayi-Kulan",
  "Mayi-Thakurti",
  "Mayi-Yapi",
  "Mayo",
  "Mayogo",
  "Mazagway-Hidi",
  "Mazahua, Central",
  "Mazahua, Michoacán",
  "Mazandarani",
  "Mazatec, Ayautla",
  "Mazatec, Chiquihuitlán",
  "Mazatec, Huautla",
  "Mazatec, Ixcatlán",
  "Mazatec, Jalapa de Díaz",
  "Mazatec, Mazatlán",
  "Mazatec, Puebla and Northeastern",
  "Mazatec, San Jerónimo Tecóatl",
  "Mazatec, Soyaltepec",
  "Mba",
  "Mbala",
  "Mbalanhu",
  "Mbandja",
  "Mbangala",
  "Mbangi",
  "Mbangwe",
  "Mbara",
  "Mbariman-Gudhinma",
  "Mbat",
  "Mbati",
  "Mbato",
  "Mbay",
  "Mbe",
  "Mbelime",
  "Mbembe, Cross River",
  "Mbembe, Tigon",
  "Mbere",
  "Mbesa",
  "Mbessa",
  "Mbo",
  "Mbo’",
  "Mboi",
  "Mboko",
  "Mbole",
  "Mbonga",
  "Mbongno",
  "Mbore",
  "Mbosi",
  "Mbowe",
  "Mbre",
  "Mbudum",
  "Mbugu",
  "Mbugwe",
  "Mbuk",
  "Mbuko",
  "Mbukushu",
  "Mbula",
  "Mbula-Bwazza",
  "Mbule",
  "Mbulungish",
  "Mbum",
  "Mbunda",
  "Mbunga",
  "Mbuun",
  "Mbwela",
  "Me’en",
  "Me’phaa, Acatepec",
  "Me’phaa, Azoyú",
  "Me’phaa, Malinaltepec",
  "Me’phaa, Tlacoapa",
  "Meänkieli",
  "Medebur",
  "Media Lengua",
  "Medumba",
  "Mefele",
  "Megam",
  "Mehek",
  "Mehináku",
  "Mehri",
  "Meitei",
  "Mekeo",
  "Mekmek",
  "Mekwei",
  "Mel-Khaonh",
  "Melanau, Central",
  "Melanau, Daro-Matu",
  "Melanau, Kanowit-Tanjong",
  "Melanau, Sibu",
  "Mele-Fila",
  "Melo",
  "Melpa",
  "Memoni",
  "Mendankwe-Nkwen",
  "Mende",
  "Mendenkye",
  "Mengaka",
  "Mengambo",
  "Mengen",
  "Mengisa",
  "Ménik",
  "Menka",
  "Menominee",
  "Mentawai",
  "Menya",
  "Meoswar",
  "Mer",
  "Meramera",
  "Mercheros",
  "Merei",
  "Merey",
  "Meriam Mir",
  "Merwari",
  "Mesaka",
  "Mesem",
  "Meskwaki",
  "Mesme",
  "Mesmes",
  "Mesqan",
  "Meta’",
  "Mevembet",
  "Mewahang, Eastern",
  "Mewahang, Western",
  "Mewari",
  "Mewati",
  "Mexican Sign Language",
  "Meyah",
  "Mfinu",
  "Mfumte",
  "Mgbolizhia",
  "Mi’kmaq",
  "Miami",
  "Mian",
  "Miani",
  "Miao, Central Huishui",
  "Miao, Central Mashan",
  "Miao, Chuanqiandian Cluster",
  "Miao, Eastern Huishui",
  "Miao, Eastern Qiandong",
  "Miao, Eastern Xiangxi",
  "Miao, Horned",
  "Miao, Large Flowery",
  "Miao, Luopohe",
  "Miao, Northern Guiyang",
  "Miao, Northern Huishui",
  "Miao, Northern Mashan",
  "Miao, Northern Qiandong",
  "Miao, Small Flowery",
  "Miao, Southern Guiyang",
  "Miao, Southern Mashan",
  "Miao, Southern Qiandong",
  "Miao, Southwestern Guiyang",
  "Miao, Southwestern Huishui",
  "Miao, Western Mashan",
  "Miao, Western Xiangxi",
  "Michif",
  "Michigamea",
  "Midob",
  "Migaama",
  "Migabac",
  "Migili",
  "Migum",
  "Miji",
  "Miju-Mishmi",
  "Mikasuki",
  "Mili",
  "Miltu",
  "Miluk",
  "Mina",
  "Minamanwa",
  "Minang",
  "Minangkabau",
  "Minanibai",
  "Minaveha",
  "Mindat",
  "Minderico",
  "Mindiri",
  "Mingang Doso",
  "Mingrelian",
  "Miniafia Oyan",
  "Minidien",
  "Minigir",
  "Minjungbal",
  "Minkin",
  "Minokok",
  "Minriq",
  "Mintil",
  "Minyag, Eastern",
  "Minyag, Western",
  "Miqie",
  "Mirandese",
  "Mirgan",
  "Miriti",
  "Miriwoong",
  "Miriwoong Sign Language",
  "Mirning",
  "Misak",
  "Miship",
  "Misima-Panaeati",
  "Mising",
  "Mískito",
  "Mittu",
  "Mituku",
  "Miu",
  "Miwa",
  "Miwok, Central Sierra",
  "Miwok, Coast",
  "Miwok, Lake",
  "Miwok, Northern Sierra",
  "Miwok, Plains",
  "Miwok, Southern Sierra",
  "Mixe, Coatlán",
  "Mixe, Isthmus",
  "Mixe, Juquila",
  "Mixe, Mazatlán",
  "Mixe, North Central",
  "Mixe, Quetzaltepec",
  "Mixe, Tlahuitoltepec",
  "Mixe, Totontepec",
  "Mixtec, Alacatlatzala",
  "Mixtec, Alcozauca",
  "Mixtec, Amoltepec",
  "Mixtec, Apasco-Apoala",
  "Mixtec, Atatlahuca",
  "Mixtec, Ayutla",
  "Mixtec, Cacaloxtepec",
  "Mixtec, Chayuco",
  "Mixtec, Chazumba",
  "Mixtec, Chigmecatitlán",
  "Mixtec, Coatzospan",
  "Mixtec, Cuyamecalco",
  "Mixtec, Diuxi-Tilantongo",
  "Mixtec, Huitepec",
  "Mixtec, Itundujia",
  "Mixtec, Ixtayutla",
  "Mixtec, Jamiltepec",
  "Mixtec, Juxtlahuaca",
  "Mixtec, Magdalena Peñasco",
  "Mixtec, Metlatónoc",
  "Mixtec, Mitlatongo",
  "Mixtec, Mixtepec",
  "Mixtec, Northern Tlaxiaco",
  "Mixtec, Northwest Oaxaca",
  "Mixtec, Ocotepec",
  "Mixtec, Peñoles",
  "Mixtec, Pinotepa Nacional",
  "Mixtec, San Juan Colorado",
  "Mixtec, San Juan Teita",
  "Mixtec, San Miguel el Grande",
  "Mixtec, San Miguel Piedras",
  "Mixtec, Santa Lucía Monteverde",
  "Mixtec, Santa María Zacatepec",
  "Mixtec, Silacayoapan",
  "Mixtec, Sindihui",
  "Mixtec, Sinicahua",
  "Mixtec, Southeastern Nochixtlán",
  "Mixtec, Southern Puebla",
  "Mixtec, Southwestern Tlaxiaco",
  "Mixtec, Soyaltepec",
  "Mixtec, Tacahua",
  "Mixtec, Tamazola",
  "Mixtec, Tezoatlán",
  "Mixtec, Tidaá",
  "Mixtec, Tijaltepec",
  "Mixtec, Tlazoyaltepec",
  "Mixtec, Tututepec",
  "Mixtec, Western Juxtlahuaca",
  "Mixtec, Yoloxóchitl",
  "Mixtec, Yosondúa",
  "Mixtec, Yucuañe",
  "Mixtec, Yutanduchi",
  "Miyako",
  "Miyakubo Sign Language",
  "Miyobe",
  "Mizo",
  "Mlabri",
  "Mlahsö",
  "Mlap",
  "Mlomp",
  "Mmen",
  "Mnong, Central",
  "Mnong, Eastern",
  "Mnong, Southern",
  "Mo",
  "Mo’da",
  "Moba",
  "Mobilian",
  "Mòcheno",
  "Mochi",
  "Mochica",
  "Mocho",
  "Mocoví",
  "Modang",
  "Modole",
  "Moere",
  "Mofu-Gudur",
  "Mofu, North",
  "Mogholi",
  "Mogofin",
  "Mogum",
  "Mohave",
  "Mohawk",
  "Mohegan-Pequot",
  "Moi",
  "Moi Kelim",
  "Moi Lemas",
  "Moikodi",
  "Moingi",
  "Moiso",
  "Moji",
  "Mok",
  "Mokati",
  "Moken",
  "Mokilese",
  "Moklen",
  "Mokole",
  "Mokpwe",
  "Moksela",
  "Moksha",
  "Mol",
  "Molale",
  "Molbog",
  "Moldova Sign Language",
  "Molengue",
  "Molima",
  "Molo",
  "Molof",
  "Moloko",
  "Moma",
  "Momare",
  "Mombum",
  "Momina",
  "Momu",
  "Momuna",
  "Mon",
  "Mondé",
  "Mondropolon",
  "Mongo-Nkundu",
  "Mongolian",
  "Mongolian Sign Language",
  "Mongolian, Halh",
  "Mongolian, Peripheral",
  "Mongondow",
  "Moni",
  "Mono",
  "Monom",
  "Monpa, Kalaktang",
  "Monpa, Tawang",
  "Montenegrin",
  "Monumbo",
  "Monzombo",
  "Moo",
  "Moore",
  "Mor",
  "Moraid",
  "Morawa",
  "Morerebi",
  "Mores",
  "Moresada",
  "Mori Atas",
  "Mori Bawah",
  "Morigi",
  "Moriori",
  "Morisyen",
  "Moro",
  "Moroccan Sign Language",
  "Morokodo",
  "Morom",
  "Moronene",
  "Morop",
  "Morori",
  "Morouas",
  "Morrobalama",
  "Mortlockese",
  "Moru",
  "Mosimo",
  "Mositacha",
  "Moskona",
  "Mota",
  "Motu",
  "Motu, Hiri",
  "Motuna",
  "Mouk-Aria",
  "Mouwase",
  "Movima",
  "Mozambican Sign Language",
  "Mpade",
  "Mpalitjanh",
  "Mpi",
  "Mpiemo",
  "Mpinda",
  "Mpoto",
  "Mpotovoro",
  "Mpumpong",
  "Mpur",
  "Mro-Khimi",
  "Mru",
  "Mser",
  "Muak Sa-aak",
  "Mualang",
  "Mubako",
  "Mubami",
  "Mubi",
  "Muda",
  "Mudburra",
  "Muduga",
  "Mufian",
  "Mugali",
  "Mugom-Karmarong",
  "Muinane",
  "Muji, Northern",
  "Muji, Qila",
  "Muji, Southern",
  "Mukha-Dora",
  "Mukulu",
  "Mulaha",
  "Mulam",
  "Mulao",
  "Mulgi",
  "Mulimba",
  "Muluridyi",
  "Mum",
  "Mumuye",
  "Muna",
  "Munda",
  "Mundabli",
  "Mundang",
  "Mundani",
  "Mundari",
  "Mündü",
  "Mundurukú",
  "Mungaka",
  "Mungbam",
  "Munggui",
  "Mungkip",
  "Muniche",
  "Munit",
  "Munji",
  "Muno",
  "Munsee",
  "Muong",
  "Mur Pano",
  "Muratayak",
  "Muria, Eastern",
  "Muria, Far Western",
  "Muria, Western",
  "Murik",
  "Murkim",
  "Murle",
  "Murrinh-Patha",
  "Mursi",
  "Murupi",
  "Murut, Bookan",
  "Murut, Kalabakan",
  "Murut, Keningau",
  "Murut, Paluan",
  "Murut, Selungai",
  "Murut, Sembakung",
  "Murut, Serudung",
  "Murut, Tahol",
  "Murut, Timugon",
  "Muruwari",
  "Musasa",
  "Musey",
  "Musgu",
  "Mushungulu",
  "Musi",
  "Muskogee",
  "Muskum",
  "Musom",
  "Mussau-Emira",
  "Muthuvan",
  "Mutu",
  "Muyang",
  "Muyu, North",
  "Muyu, South",
  "Muyuw",
  "Muzi",
  "Mvanip",
  "Mvuba",
  "Mwaghavul",
  "Mwakai",
  "Mwan",
  "Mwani",
  "Mwatebu",
  "Mwera",
  "Mwerlap",
  "Mwimbi-Muthambi",
  "Mwotlap",
  "Myanmar Sign Language",
  "Myene",
  "N’kep",
  "N’Ko",
  "Na",
  "Na-kara",
  "Ná-Meo",
  "Na’ahai",
  "Naaba",
  "Naami",
  "Naasioi",
  "Naba",
  "Nabak",
  "Nabi",
  "Nachiring",
  "Nadëb",
  "Nafaanra",
  "Nafi",
  "Nafri",
  "Nafusi",
  "Naga, Akyaung Ari",
  "Naga, Angami",
  "Naga, Ao",
  "Naga, Chang",
  "Naga, Chen-Kayu",
  "Naga, Chokri",
  "Naga, Chothe",
  "Naga, Inpui",
  "Naga, Jejara",
  "Naga, Kharam",
  "Naga, Khezha",
  "Naga, Khiamniungan",
  "Naga, Khoibu",
  "Naga, Kokak",
  "Naga, Konyak",
  "Naga, Lainong",
  "Naga, Lao",
  "Naga, Liangmai",
  "Naga, Long Phuri",
  "Naga, Lotha",
  "Naga, Makuri",
  "Naga, Mao",
  "Naga, Maram",
  "Naga, Maring",
  "Naga, Monsang",
  "Naga, Moyon",
  "Naga, Mzieme",
  "Naga, Nocte",
  "Naga, Northern Rengma",
  "Naga, Paungnyuan",
  "Naga, Phom",
  "Naga, Pochuri",
  "Naga, Ponyo-Gongwang",
  "Naga, Poumai",
  "Naga, Puimei",
  "Naga, Rongmei",
  "Naga, Sangtam",
  "Naga, Southern Rengma",
  "Naga, Sumi",
  "Naga, Tangkhul",
  "Naga, Tangshang",
  "Naga, Tarao",
  "Naga, Thangal",
  "Naga, Tutsa",
  "Naga, Wancho",
  "Naga, Yimchungru",
  "Naga, Zeme",
  "Nagamese",
  "Nagarchal",
  "Nage",
  "Nago, Northern",
  "Nago, Southern",
  "Nagumi",
  "Nahali",
  "Nahari",
  "Nahavaq",
  "Nahuat",
  "Nahuatl, Central",
  "Nahuatl, Central Huasteca",
  "Nahuatl, Central Puebla",
  "Nahuatl, Coatepec",
  "Nahuatl, Eastern Durango",
  "Nahuatl, Eastern Huasteca",
  "Nahuatl, Guerrero",
  "Nahuatl, Highland Puebla",
  "Nahuatl, Huaxcaleca",
  "Nahuatl, Isthmus-Cosoleacaque",
  "Nahuatl, Isthmus-Mecayapan",
  "Nahuatl, Isthmus-Pajapan",
  "Nahuatl, Michoacán",
  "Nahuatl, Morelos",
  "Nahuatl, Northern Oaxaca",
  "Nahuatl, Northern Puebla",
  "Nahuatl, Ometepec",
  "Nahuatl, Orizaba",
  "Nahuatl, Santa María la Alta",
  "Nahuatl, Sierra Negra",
  "Nahuatl, Southeastern Puebla",
  "Nahuatl, Tabasco",
  "Nahuatl, Temascaltepec",
  "Nahuatl, Tetelcingo",
  "Nahuatl, Tlamacazapa",
  "Nahuatl, Western Durango",
  "Nahuatl, Western Huasteca",
  "Nahuatl, Zacatlán-Ahuacatlán-Tepetzintla",
  "Nai",
  "Naka’ela",
  "Nakai",
  "Nakame",
  "Nakanai",
  "Nake",
  "Naki",
  "Nakwi",
  "Nalca",
  "Nali",
  "Nalik",
  "Nalögo",
  "Nalu",
  "Naluo",
  "Nama",
  "Namakura",
  "Namat",
  "Nambikuára, Southern",
  "Nambo",
  "Nambya",
  "Namia",
  "Namiae",
  "Namibian Sign Language",
  "Namla",
  "Namo",
  "Namonuito",
  "Namosi-Naitasiri-Serua",
  "Namuyi",
  "Nanai",
  "Nancere",
  "Nande",
  "Nandi",
  "Nankina",
  "Nanti",
  "Nanticoke",
  "Nanubae",
  "Napoletano",
  "Napu",
  "Nar Phu",
  "Nara",
  "Narak",
  "Nari Nari",
  "Naro",
  "Narom",
  "Narragansett",
  "Narua",
  "Narungga",
  "Nasa",
  "Nasal",
  "Nasarian",
  "Naskapi",
  "Nasu, Wumeng",
  "Nasu, Wusa",
  "Natanzi",
  "Natchez",
  "Nateni",
  "Nathembo",
  "Natioro",
  "Natügu",
  "Nauete",
  "Nauo",
  "Nauruan",
  "Navajo",
  "Nawaru",
  "Nawathinehena",
  "Nawdm",
  "Nawuri",
  "Naxi",
  "Nayi",
  "Nayini",
  "Nchane",
  "Nchumbulu",
  "Nda’nda’",
  "Ndai",
  "Ndaka",
  "Ndali",
  "Ndam",
  "Ndamba",
  "Ndambomo",
  "Ndasa",
  "Ndau",
  "Nde-Gbite",
  "Nde-Nsele-Nta",
  "Ndebele",
  "Ndemli",
  "Ndendeule",
  "Ndengereko",
  "Nding",
  "Ndobo",
  "Ndoe",
  "Ndogo",
  "Ndolo",
  "Ndom",
  "Ndombe",
  "Ndonde Hamba",
  "Ndonga",
  "Ndoola",
  "Ndra’ngith",
  "Ndrulo",
  "Nduga",
  "Ndumu",
  "Ndunda",
  "Ndunga",
  "Ndut",
  "Ndwewe",
  "Ndyuka-Trio Pidgin",
  "Nedebang",
  "Nefamese",
  "Negerhollands",
  "Negeri Sembilan Malay",
  "Negidal",
  "Nehan",
  "Neherneh",
  "Nek",
  "Nekgini",
  "Neko",
  "Neku",
  "Nêlêmwa-Nixumwak",
  "Nema",
  "Neme",
  "Nemi",
  "Nen",
  "Nend",
  "Nenets",
  "Nengone",
  "Neo-Mandaic",
  "Nepalese Sign Language",
  "Nepali",
  "Nete",
  "Neve’ei",
  "Neverver",
  "New Zealand Sign Language",
  "Newar",
  "Neyo",
  "Nez Perce",
  "Ng’akarimojong",
  "Ngaanyatjarra",
  "Ngäbere",
  "Ngad’a",
  "Ngad’a, Eastern",
  "Ngadjunmaya",
  "Ngadjuri",
  "Ngaing",
  "Ngaju",
  "Ngalakgan",
  "Ngalum",
  "Ngam",
  "Ngamambo",
  "Ngambay",
  "Ngamini",
  "Ngamo",
  "Ngan’gityemerri",
  "Nganakarti",
  "Nganasan",
  "Ngandi",
  "Ngandjera",
  "Ngando",
  "Ngangam",
  "Ngantangarra",
  "Nganyaywana",
  "Ngardi",
  "Ngarigu",
  "Ngarinyin",
  "Ngarinyman",
  "Ngarla",
  "Ngarluma",
  "Ngarrindjeri",
  "Ngas",
  "Ngasa",
  "Ngatik Men’s Creole",
  "Ngawun",
  "Ngayawung",
  "Ngbaka",
  "Ngbaka Ma’bo",
  "Ngbaka Manza",
  "Ngbandi, Northern",
  "Ngbandi, Southern",
  "Ngbee",
  "Ngbinda",
  "Ngbundu",
  "Ngelima",
  "Ngemba",
  "Ngen",
  "Ngendelengo",
  "Ngete",
  "Nggem",
  "Nggwahyi",
  "Ngie",
  "Ngiemboon",
  "Ngiemekohke",
  "Ngile",
  "Ngindo",
  "Ngiti",
  "Ngizim",
  "Ngom",
  "Ngomba",
  "Ngombale",
  "Ngombe",
  "Ngongo",
  "Ngoreme",
  "Ngoshie",
  "Ngulu",
  "Nguluwan",
  "Ngumbarl",
  "Ngunawal",
  "Ngundi",
  "Ngundu",
  "Ngungwel",
  "Nguôn",
  "Ngurmbur",
  "Ngwaba",
  "Ngwe",
  "Ngwii",
  "Ngwo",
  "Nhanda",
  "Nhengatu",
  "Nhirrpi",
  "Nhuwala",
  "Nias",
  "Nicaragua English Creole",
  "Nicaraguan Sign Language",
  "Nicobarese, Car",
  "Nicobarese, Central",
  "Nicobarese, Southern",
  "Niellim",
  "Nigerian Sign Language",
  "Nihali",
  "Nii",
  "Niksek",
  "Nikyob-Nindem",
  "Nila",
  "Nilamba",
  "Nimadi",
  "Nimanbur",
  "Nimbari",
  "Nimboran",
  "Nimo",
  "Ninam",
  "Ninde",
  "Nindi",
  "Ningera",
  "Ninggerum",
  "Ningil",
  "Ninzo",
  "Nipsan",
  "Nisa",
  "Nisenan",
  "Nisga’a",
  "Nisi",
  "Nisu, Eastern",
  "Nisu, Northern",
  "Nisu, Northwestern",
  "Nisu, Southern",
  "Nisu, Southwestern",
  "Niuafo’ou",
  "Niuatoputapu",
  "Niue",
  "Nivaclé",
  "Niwer Mil",
  "Nizaa",
  "Njalgulgule",
  "Njebi",
  "Njen",
  "Njerep",
  "Njyem",
  "Nkami",
  "Nkangala",
  "Nkari",
  "Nkem-Nkum",
  "Nkongho",
  "Nkonya",
  "Nkoroo",
  "Nkoya",
  "Nkukoli",
  "Nkumbi",
  "Nkutu",
  "Nnam",
  "Nobiin",
  "Nobonob",
  "Nogai",
  "Noipä",
  "Noiri",
  "Nomaande",
  "Nomane",
  "Nomatsigenga",
  "Nomlaki",
  "Nomu",
  "Nonuya",
  "Nooksack",
  "Noon",
  "Noone",
  "Nora",
  "Norn",
  "Northern Kalapuya",
  "Northern Straits Salish",
  "Northwestern !Kung",
  "Norwegian",
  "Norwegian Sign Language",
  "Norwegian, Traveller",
  "Notre",
  "Notsi",
  "Nottoway",
  "Nottoway-Meherrin",
  "Noy",
  "Nsenga",
  "Nshi",
  "Ntcham",
  "Nteng",
  "Ntomba",
  "Nuaulu, North",
  "Nuaulu, South",
  "Nubaca",
  "Nubi",
  "Nubri",
  "Nuer",
  "Nugunu",
  "Nuk",
  "Nukak Makú",
  "Nukeria",
  "Nukna",
  "Nukuini",
  "Nukumanu",
  "Nukuoro",
  "Nulibie",
  "Numala",
  "Numana",
  "Numanggang",
  "Numbami",
  "Nume",
  "Numèè",
  "Nung",
  "Nungali",
  "Nunggubuyu",
  "Nungu",
  "Nuni, Northern",
  "Nuni, Southern",
  "Nunukul",
  "Nuosu",
  "Nupbikha",
  "Nupe-Nupe-Tako",
  "Nusa Laut",
  "Nusu",
  "Nuu-chah-nulth",
  "Nya Huba",
  "Nyabwa",
  "Nyagbo",
  "Nyaheun",
  "Nyahkur",
  "Nyakyusa-Ngonde",
  "Nyala",
  "Nyali",
  "Nyam",
  "Nyamal",
  "Nyambo",
  "Nyamusa-Molo",
  "Nyamwanga",
  "Nyamwezi",
  "Nyaneka",
  "Nyang’i",
  "Nyanga",
  "Nyanga-li",
  "Nyangatom",
  "Nyangga",
  "Nyangumarta",
  "Nyankore",
  "Nyankpa",
  "Nyaturu",
  "Nyaw",
  "Nyawaygi",
  "Nyelâyu",
  "Nyemba",
  "Nyengo",
  "Nyenkha",
  "Nyeshangte",
  "Nyeu",
  "Nyiha, Malawi",
  "Nyiha, Tanzania",
  "Nyika",
  "Nyika, Tanzania",
  "Nyikina",
  "Nyindrou",
  "Nyindu",
  "Nyishi",
  "Nyiyaparli",
  "Nyokon",
  "Nyole",
  "Nyoro",
  "Nyulnyul",
  "Nyungar",
  "Nyungwe",
  "Nzadi",
  "Nzakambay",
  "Nzakara",
  "Nzanyi",
  "Nzema",
  "Nǁng",
  "O’chi’chi’",
  "O’du",
  "Oa",
  "Oadki",
  "Obispeño",
  "Oblo",
  "Obokuitai",
  "Obolo",
  "Obulom",
  "Ocaina",
  "Occitan",
  "Odia",
  "Odiai",
  "Odoodee",
  "Odual",
  "Odut",
  "Ofayé",
  "Ofo",
  "Ogbah",
  "Ogbia",
  "Ogbogolo",
  "Ogbronuagum",
  "Ogea",
  "Ohendo",
  "Ohlone, Northern",
  "Ohlone, Southern",
  "Oirata",
  "Oji-Cree",
  "Ojibwa",
  "Ojibwa, Central",
  "Ojibwa, Eastern",
  "Ojibwa, Northwestern",
  "Ojibwa, Western",
  "Okanagan",
  "Oki-No-Erabu",
  "Okiek",
  "Okinawan, Central",
  "Oko-Eni-Osayen",
  "Oko-Juwoi",
  "Okobo",
  "Okodia",
  "Okolie",
  "Okolod",
  "Okpamheri",
  "Okpe",
  "Oksapmin",
  "Oku",
  "Old Kentish Sign Language",
  "Olekha",
  "Olkol",
  "Olo",
  "Oloma",
  "Olrat",
  "Olu’bo",
  "Olukhayo",
  "Olulumo-Ikom",
  "Oluluyia",
  "Olumarachi",
  "Olumarama",
  "Olunyole",
  "Olushisa",
  "Olutsotso",
  "Oluwanga",
  "Omagua",
  "Omaha-Ponca",
  "Ombamba",
  "Ombo",
  "Omi",
  "Omok",
  "Omotik",
  "Omurano",
  "Ona",
  "One, Inebu",
  "One, Kabore",
  "One, Kwamtim",
  "One, Molmo",
  "One, Northern",
  "One, Southern",
  "Oneida",
  "Ong",
  "Ongota",
  "Onin",
  "Onin Based Pidgin",
  "Oniyan",
  "Onjob",
  "Ono",
  "Onobasulu",
  "Onondaga",
  "Ontenu",
  "Ontong Java",
  "Oorlams",
  "Opao",
  "Opata",
  "Opo",
  "Orang Kanaq",
  "Orang Seletar",
  "Oring",
  "Oriya",
  "Oriya, Adivasi",
  "Orma",
  "Ormu",
  "Ormuri",
  "Oro",
  "Oro Win",
  "Oroch",
  "Oroha",
  "Orok",
  "Orokaiva",
  "Oroko",
  "Orokolo",
  "Oromo",
  "Oromo, Borana-Arsi-Guji",
  "Oromo, Eastern",
  "Oromo, West Central",
  "Orop",
  "Oroqen",
  "Orowe",
  "Oruma",
  "Orya",
  "Osage",
  "Osatu",
  "Oshiwambo",
  "Osing",
  "Ososo",
  "Ossetic",
  "Ot Danum",
  "Oti",
  "Otomi, Eastern Highland",
  "Otomí, Estado de México",
  "Otomi, Ixtenco",
  "Otomi, Mezquital",
  "Otomi, Querétaro",
  "Otomi, Temoaya",
  "Otomi, Tenango",
  "Otomi, Texcatepec",
  "Otomi, Tilapa",
  "Otoro",
  "Ottawa",
  "Otuho",
  "Otuke",
  "Ouma",
  "Oune",
  "Owa",
  "Owenia",
  "Owiniga",
  "Oy",
  "Oya’oya",
  "Oyda",
  "Pa",
  "Pa Di",
  "Pa-Hng",
  "Pa’anci",
  "Pa’o",
  "Pááfang",
  "Paakantyi",
  "Paama",
  "Paasaal",
  "Pacahuara",
  "Pacoh",
  "Padoe",
  "Pagi",
  "Pagibete",
  "Pagu",
  "Pahari",
  "Pahari-Potwari",
  "Pahari, Kullu",
  "Pahari, Mahasu",
  "Pahi",
  "Pahlavani",
  "Pai Tavytera",
  "Paicî",
  "Paicoca",
  "Painara",
  "Paipai",
  "Paiute, Northern",
  "Paiwan",
  "Pak-Tong",
  "Pakaásnovos",
  "Pakanha",
  "Pakistan Sign Language",
  "Paku",
  "Pal",
  "Palatinate Franconian",
  "Palauan",
  "Palaung, Ruching",
  "Palaung, Rumai",
  "Palaung, Shwe",
  "Palawano, Brooke’s Point",
  "Palawano, Central",
  "Palawano, Southwest",
  "Paleni",
  "Palenquero",
  "Pali",
  "Palikúr",
  "Paliyan",
  "Pallanganmiddang",
  "Paloor",
  "Palu’e",
  "Paluai",
  "Palula",
  "Pam",
  "Pambia",
  "Pame, Central",
  "Pame, Northern",
  "Pame, Southern",
  "Pamona",
  "Pamosu",
  "Pana",
  "Panamanian Sign Language",
  "Panará",
  "Panasuan",
  "Panawa",
  "Pancana",
  "Panchpargania",
  "Pande",
  "Pangasinan",
  "Pangkhua",
  "Pangseng",
  "Pangu",
  "Pangwa",
  "Pangwali",
  "Panim",
  "Paniya",
  "Pankararé",
  "Pankararú",
  "Pannei",
  "Pano",
  "Panobo",
  "Pantar, Western",
  "Papapana",
  "Papar",
  "Papasena",
  "Papel",
  "Papiamentu",
  "Papora-Hoanya",
  "Papua New Guinean Sign Language",
  "Papuma",
  "Parachi",
  "Paraguayan Sign Language",
  "Parakanã",
  "Paranan",
  "Paranawát",
  "Paraujano",
  "Parawen",
  "Pardhan",
  "Pardhi",
  "Parecís",
  "Parenga",
  "Päri",
  "Parkwa",
  "Parsi-Dari",
  "Parya",
  "Pashai, Northeast",
  "Pashai, Northwest",
  "Pashai, Southeast",
  "Pashai, Southwest",
  "Pashto, Central",
  "Pashto, Northern",
  "Pashto, Southern",
  "Pasi",
  "Patamona",
  "Patani",
  "Pataxó Hã-Ha-Hãe",
  "Patep",
  "Pathiya",
  "Patpatar",
  "Pattani",
  "Pattapu",
  "Patwin",
  "Paulohi",
  "Paumarí",
  "Paunaka",
  "Pauserna",
  "Pawaia",
  "Pawnee",
  "Pazeh",
  "Pear",
  "Pech",
  "Pefiyahe",
  "Pekal",
  "Pela",
  "Pele-Ata",
  "Pelende",
  "Pemon",
  "Pémono",
  "Penan, Eastern",
  "Penan, Western",
  "Penang Sign Language",
  "Penchal",
  "Pendau",
  "Pengo",
  "Penrhyn",
  "Pentlatch",
  "Perai",
  "Pere",
  "Pero",
  "Persian",
  "Persian, Iranian",
  "Peruvian Sign Language",
  "Pesse",
  "Petats",
  "Petjo",
  "Pévé",
  "Phake",
  "Phala",
  "Phana’",
  "Phangduwali",
  "Phende",
  "Phimbi",
  "Phola",
  "Phola, Alo",
  "Pholo",
  "Phong-Kniang",
  "Phowa, Ani",
  "Phowa, Hlepho",
  "Phowa, Labo",
  "Phu Thai",
  "Phuan",
  "Phudagi",
  "Phuie",
  "Phula",
  "Phuma",
  "Phunoi",
  "Phuong",
  "Phupa",
  "Phupha",
  "Phuza",
  "Piame",
  "Piapoco",
  "Piapung",
  "Piaroa",
  "Picard",
  "Pidgin Bantu",
  "Pidgin, Cameroon",
  "Pidgin, Nigerian",
  "Pidgin, Timor",
  "Piedmontese",
  "Pijao",
  "Pije",
  "Pijin",
  "Pilagá",
  "Pilcomayo Wichí",
  "Pima Bajo",
  "Pimbwe",
  "Pin",
  "Pinai-Hagahai",
  "Pingelapese",
  "Pinghua, Northern",
  "Pinghua, Southern",
  "Pinigura",
  "Pinjarup",
  "Pinji",
  "Pintiini",
  "Pintupi-Luritja",
  "Pinyin",
  "Pirahã",
  "Piratapuyo",
  "Pirlatapa",
  "Piro",
  "Pirriya",
  "Pisabo",
  "Piscataway",
  "Pitcairn-Norfolk",
  "Pitjantjatjara",
  "Pitta Pitta",
  "Piu",
  "Piya-Kwonci",
  "Plains Indian Sign Language",
  "Plautdietsch",
  "Playero",
  "Pnar",
  "Pochutec",
  "Pogolo",
  "Pohnpeian",
  "Pokangá",
  "Poke",
  "Pökoot",
  "Pol",
  "Polabian",
  "Polari",
  "Pole",
  "Polish",
  "Polish Sign Language",
  "Pom",
  "Pomo, Central",
  "Pomo, Eastern",
  "Pomo, Northeastern",
  "Pomo, Northern",
  "Pomo, Southeastern",
  "Pomo, Southern",
  "Ponam",
  "Pondi",
  "Ponosakan",
  "Pontic",
  "Popoloca, Coyotepec",
  "Popoloca, Mezontla",
  "Popoloca, San Felipe Otlaltepec",
  "Popoloca, San Juan Atzingo",
  "Popoloca, San Luís Temalacayuca",
  "Popoloca, San Marcos Tlacoyalco",
  "Popoloca, Santa Inés Ahuatempan",
  "Popoluca, Highland",
  "Popoluca, Oluta",
  "Popoluca, Sayula",
  "Popoluca, Texistepec",
  "Poqomam",
  "Poqomchi’",
  "Porohanon",
  "Port Sandwich",
  "Port Vato",
  "Portuguese",
  "Portuguese Sign Language",
  "Potawatomi",
  "Potiguára",
  "Pouye",
  "Powari",
  "Powhatan",
  "Poyanáwa",
  "Prai",
  "Prasuni",
  "Principense",
  "Providencia Sign Language",
  "Prussian",
  "Psikye",
  "Puare",
  "Puelche",
  "Puerto Rican Sign Language",
  "Puinave",
  "Pukapuka",
  "Pulaar",
  "Pulabu",
  "Pular",
  "Puluwatese",
  "Puma",
  "Pumé",
  "Pumi, Northern",
  "Pumi, Southern",
  "Pumpokol",
  "Punan Aput",
  "Punan Bah-Biau",
  "Punan Batu",
  "Punan Merah",
  "Punan Merap",
  "Punan Tubu",
  "Punjabi, Eastern",
  "Punjabi, Western",
  "Punthamara",
  "Punu",
  "Puoc",
  "Puquina",
  "Puragi",
  "Purari",
  "Purepecha",
  "Purepecha, Western Highland",
  "Puri",
  "Purig",
  "Purisimeño",
  "Puroik",
  "Puruborá",
  "Purum",
  "Pushto",
  "Putai",
  "Putoh",
  "Puyuma",
  "Pwaamei",
  "Pwapwâ",
  "Pyam",
  "Pye",
  "Pyen",
  "Pyu",
  "Q’anjob’al",
  "Qabiao",
  "Qaqet",
  "Qau",
  "Qawasqar",
  "Qiang, Northern",
  "Qiang, Southern",
  "Qimant",
  "Quapaw",
  "Quebec Sign Language",
  "Quechan",
  "Quechua",
  "Quechua, Ambo-Pasco",
  "Quechua, Arequipa-La Unión",
  "Quechua, Ayacucho",
  "Quechua, Cajamarca",
  "Quechua, Cajatambo North Lima",
  "Quechua, Chachapoyas",
  "Quechua, Chaupihuaranga",
  "Quechua, Chincha",
  "Quechua, Chiquián",
  "Quechua, Corongo Ancash",
  "Quechua, Cusco",
  "Quechua, Eastern Apurímac",
  "Quechua, Huallaga",
  "Quechua, Huamalíes-Dos de Mayo",
  "Quechua, Huaylas Ancash",
  "Quechua, Huaylla Wanca",
  "Quechua, Jauja Wanca",
  "Quechua, Lambayeque",
  "Quechua, Margos-Yarowilca-Lauricocha",
  "Quechua, North Bolivian",
  "Quechua, North Junín",
  "Quechua, Northern Conchucos",
  "Quechua, Pacaraos",
  "Quechua, Panao",
  "Quechua, Puno",
  "Quechua, San Martín",
  "Quechua, Santa Ana de Tusi Pasco",
  "Quechua, Sihuas Ancash",
  "Quechua, South Bolivian",
  "Quechua, Southern Conchucos",
  "Quechua, Southern Pastaza",
  "Quechua, Yauyos",
  "Queyu",
  "Quichua, Calderón Highland",
  "Quichua, Cañar Highland",
  "Quichua, Chimborazo Highland",
  "Quichua, Imbabura Highland",
  "Quichua, Loja Highland",
  "Quichua, Napo",
  "Quichua, Northern Pastaza",
  "Quichua, Salasaca Highland",
  "Quichua, Santiago del Estero",
  "Quichua, Tena Lowland",
  "Quileute",
  "Quinault",
  "Quiripi",
  "Qꞌeqchiꞌ",
  "Rabha",
  "Rade",
  "Rahambuu",
  "Rajasthani",
  "Rajbanshi",
  "Raji",
  "Rajong",
  "Rakahanga-Manihiki",
  "Rakhine",
  "Ralte",
  "Rama",
  "Ramoaaina",
  "Ramopa",
  "Rampi",
  "Rang",
  "Rangi",
  "Rangkas",
  "Ranglong",
  "Rangpuri",
  "Rao",
  "Rapa",
  "Rapa Nui",
  "Rapoisi",
  "Rapting",
  "Rasawa",
  "Ratagnon",
  "Ratahan",
  "Rathawi",
  "Raute",
  "Ravula",
  "Rawa",
  "Rawang",
  "Rawat",
  "Rawo",
  "Razajerdi",
  "Rāziḥī",
  "Reel",
  "Rejang",
  "Reli",
  "Rema",
  "Rembarrnga",
  "Rembong",
  "Remo",
  "Rempi",
  "Remun",
  "Rendille",
  "Rengao",
  "Rennell-Bellona",
  "Repanbitip",
  "Rer Bare",
  "Rerau",
  "Rerep",
  "Reshe",
  "Resígaro",
  "Reta",
  "Réunion French Creole",
  "Reyesano",
  "Riang",
  "Riang Lai",
  "Riang Lang",
  "Riantana",
  "Ribun",
  "Rifao",
  "Rigwe",
  "Rikbaktsa",
  "Rikou",
  "Ripuarian",
  "Ririo",
  "Rishiwa",
  "Ritharrngu",
  "Riung",
  "Rjili",
  "Rmeet",
  "Robambami",
  "Roglai, Cacgia",
  "Roglai, Northern",
  "Roglai, Southern",
  "Rogo",
  "Rohingya",
  "Rom",
  "Roma",
  "Romagnol",
  "Romam",
  "Romani, Balkan",
  "Romani, Baltic",
  "Romani, Carpathian",
  "Romani, Kalo Finnish",
  "Romani, Sinte",
  "Romani, Tavringer",
  "Romani, Vlax",
  "Romani, Welsh",
  "Romanian",
  "Romanian Sign Language",
  "Romanian, Istro",
  "Romanian, Megleno",
  "Romano-Greek",
  "Romano-Serbian",
  "Romansh",
  "Romany",
  "Romblomanon",
  "Rombo",
  "Romkun",
  "Ron",
  "Ronga",
  "Rongga",
  "Rongpo",
  "Roon",
  "Rotokas",
  "Rotuman",
  "Roviana",
  "Rudbari",
  "Rufiji",
  "Ruga",
  "Rukai",
  "Ruma",
  "Rumu",
  "Rundi",
  "Runga",
  "Rungus",
  "Rungwa",
  "Ruruuli-Runyala",
  "Russian",
  "Russian Sign Language",
  "Rusyn",
  "Ruthenian",
  "Rutul",
  "Ruund",
  "Ruwila",
  "Rwa",
  "Rwandan Sign Language",
  "Sa",
  "Sa’ban",
  "Saafi-Saafi",
  "Saam",
  "Saami, Akkala",
  "Saami, Inari",
  "Saami, Kemi",
  "Saami, Kildin",
  "Saami, Lule",
  "Saami, North",
  "Saami, Pite",
  "Saami, Skolt",
  "Saami, South",
  "Saami, Ter",
  "Saami, Ume",
  "Saamya-Gwe",
  "Saaroa",
  "Saba",
  "Sabanê",
  "Sabaot",
  "Sabüm",
  "Sadri",
  "Sadri, Oraon",
  "Saek",
  "Saep",
  "Safaliba",
  "Safeyoka",
  "Safwa",
  "Sagala",
  "Sagalla",
  "Saho",
  "Sahu",
  "Saisiyat",
  "Sajau Basap",
  "Sakachep",
  "Sakapulteko",
  "Sakata",
  "Sake",
  "Sakirabiá",
  "Sakizaya",
  "Sala",
  "Salampasu",
  "Salar",
  "Salas",
  "Salawati",
  "Saleman",
  "Saliba",
  "Sáliba",
  "Salinan",
  "Sallands",
  "Saluan",
  "Salumá",
  "Salvadoran Sign Language",
  "Sam",
  "Sama",
  "Sama, Balangingih",
  "Sama, Central",
  "Sama, Pangutaran",
  "Sama, Southern",
  "Samaritan Aramaic",
  "Samaritan Hebrew",
  "Samarokena",
  "Samatao",
  "Samay",
  "Samba",
  "Samba Daka",
  "Samba Leko",
  "Sambal",
  "Sambal, Botolan",
  "Sambalpuri",
  "Sambe",
  "Samberigi",
  "Samburu",
  "Samei",
  "Samo",
  "Samo, Matya",
  "Samo, Maya",
  "Samo, Southern",
  "Samoan",
  "Samogitian",
  "Samosa",
  "Sampang",
  "Samre",
  "Samtao",
  "Samvedi",
  "San Miguel French Creole",
  "Sanapaná",
  "Sandawe",
  "Sanga",
  "Sanggau",
  "Sangil",
  "Sangir",
  "Sangisari",
  "Sangkong",
  "Sanglechi",
  "Sango",
  "Sango, Riverain",
  "Sangu",
  "Sani",
  "Sanie",
  "Saniyo-Hiyewe",
  "Sanka",
  "Sansi",
  "Sanskrit",
  "Santhali",
  "Sanumá",
  "Sãotomense",
  "Saparua",
  "Sapé",
  "Sapo",
  "Saponi",
  "Saposa",
  "Sapuan",
  "Sar",
  "Sara Kaba",
  "Saraiki",
  "Saramaccan",
  "Sarasira",
  "Saraveca",
  "Sardinian",
  "Sardinian, Campidanese",
  "Sardinian, Gallurese",
  "Sardinian, Logudorese",
  "Sardinian, Sassarese",
  "Sari",
  "Sarikoli",
  "Sarli",
  "Sarsi",
  "Sartang",
  "Sarua",
  "Sarudu",
  "Saruga",
  "Sasak",
  "Sasaru",
  "Satawalese",
  "Sateré-Mawé",
  "Saterfriesisch",
  "Saudi Arabian Sign Language",
  "Sauri",
  "Sauria Paharia",
  "Sause",
  "Savi",
  "Savosavo",
  "Sawai",
  "Saweru",
  "Sawi",
  "Sawila",
  "Sawiyanu",
  "Sawknah",
  "Saxon, East Frisian Low",
  "Saxon, Low",
  "Saxon, Upper",
  "Saya",
  "Saꞌa",
  "Scots",
  "Scottish Gaelic",
  "Sea Island English Creole",
  "Seba",
  "Sebat Bet Gurage",
  "Seberuang",
  "Sebop",
  "Sechelt",
  "Sedang",
  "Sediq",
  "Sedoa",
  "Seenku",
  "Segai",
  "Segeju",
  "Seimat",
  "Seit-Kaitetu",
  "Sekak",
  "Sekani",
  "Sekapan",
  "Sekar",
  "Seke",
  "Seki",
  "Seko Padang",
  "Seko Tengah",
  "Sekpele",
  "Selangor Sign Language",
  "Selaru",
  "Selayar",
  "Selee",
  "Selepet",
  "Selkup",
  "Seluwasan",
  "Semai",
  "Semandang",
  "Semaq Beri",
  "Semelai",
  "Semimi",
  "Semnam",
  "Semnani",
  "Sempan",
  "Sena",
  "Sena, Malawi",
  "Senaya",
  "Sene",
  "Seneca",
  "Sened",
  "Sengele",
  "Sengo",
  "Sengseng",
  "Senhaja Berber",
  "Sénoufo, Cebaara",
  "Sénoufo, Djimini",
  "Sénoufo, Mamara",
  "Sénoufo, Nanerigé",
  "Sénoufo, Nyarafolo",
  "Sénoufo, Palaka",
  "Sénoufo, Senara",
  "Sénoufo, Shempire",
  "Sénoufo, Sìcìté",
  "Sénoufo, Supyire",
  "Sénoufo, Syenara",
  "Sénoufo, Tagwana",
  "Sensi",
  "Sentani",
  "Sentinel",
  "Sepa",
  "Sera",
  "Serbian",
  "Serbo-Croatian",
  "Sere",
  "Serer-Sine",
  "Seri",
  "Serili",
  "Seroa",
  "Serrano",
  "Serua",
  "Serui-Laut",
  "Seta",
  "Setaman",
  "Seti",
  "Setswana",
  "Settla",
  "Sewa Bay",
  "Seychelles French Creole",
  "Seychelles Sign Language",
  "Seze",
  "Shabak",
  "Shabo",
  "Shahmirzadi",
  "Shahrudi",
  "Shall-Zwall",
  "Shama-Sambuga",
  "Shamang",
  "Shambala",
  "Shan",
  "Shanenawa",
  "Shanga",
  "Sharanahua",
  "Sharwa",
  "Shasta",
  "Shatt",
  "Shau",
  "Shawi",
  "Shawnee",
  "She",
  "Shehri",
  "Shekhawati",
  "Shekkacho",
  "Sheko",
  "Shelta",
  "Shendu",
  "Sheni",
  "Sherbro",
  "Sherdukpen",
  "Sherpa",
  "Shi",
  "Shiki",
  "Shilluk",
  "Shina",
  "Shina, Kohistani",
  "Shipibo-Conibo",
  "Sholaga",
  "Shom Peng",
  "Shona",
  "Shoo-Minda-Nye",
  "Shor",
  "Shoshoni",
  "Shua",
  "Shuadit",
  "Shuar",
  "Shubi",
  "Shughni",
  "Shuhi",
  "Shumashti",
  "Shumcho",
  "Shuswap",
  "Shwai",
  "Sialum",
  "Siamou",
  "Siane",
  "Siang",
  "Siar-Lak",
  "Siawi",
  "Sibe",
  "Siberian Tatar",
  "Sicilian",
  "Sidamo",
  "Sie",
  "Sierra Leone Sign Language",
  "Sighu",
  "Sign Language of the Netherlands",
  "Sihan",
  "Sika",
  "Sikaiana",
  "Sikaritai",
  "Sikiana",
  "Sikkimese",
  "Sikule",
  "Sila",
  "Silesian",
  "Silesian, Lower",
  "Silimo",
  "Siliput",
  "Silopi",
  "Silt’e",
  "Simaa",
  "Simba",
  "Simbali",
  "Simbari",
  "Simbo",
  "Simeku",
  "Simeulue",
  "Simte",
  "Sinagen",
  "Sinasina",
  "Sinaugoro",
  "Sindhi",
  "Sindhi Bhil",
  "Singa",
  "Singapore Sign Language",
  "Singpho",
  "Sinhala",
  "Sinicized Miao",
  "Sininkere",
  "Sinyar",
  "Sio",
  "Siona",
  "Sipakapense",
  "Sira",
  "Siraya",
  "Siri",
  "Siriano",
  "Sirionó",
  "Sirmauri",
  "Siroi",
  "Sirva",
  "Sisaala, Tumulung",
  "Sisaala, Western",
  "Sîshëë",
  "Sissala",
  "Siuslaw",
  "Sivandi",
  "Sivia Sign Language",
  "Siwi",
  "Siwu",
  "Skagit",
  "Ske",
  "Skepi Dutch Creole",
  "Skou",
  "Slave",
  "Slavey, North",
  "Slavey, South",
  "Slavomolisano",
  "Slavonic, Church",
  "Slovak",
  "Slovakian Sign Language",
  "Slovene",
  "Slovenian Sign Language",
  "Snohomish",
  "So",
  "So’a",
  "Sob",
  "Sobei",
  "Soga",
  "Soi",
  "Sokoro",
  "Solano",
  "Soli",
  "Solomon Islands Sign Language",
  "Solong",
  "Solos",
  "Somali",
  "Somba-Siawari",
  "Somray",
  "Somyev",
  "Sonaga",
  "Sonde",
  "Songe",
  "Songhay, Humburi Senni",
  "Songhay, Koyra Chiini",
  "Songhay, Koyraboro Senni",
  "Songo",
  "Songoora",
  "Sonha",
  "Sonia",
  "Soninke",
  "Sonsorolese",
  "Soo",
  "Soq",
  "Soqotri",
  "Sora",
  "Sorbian, Lower",
  "Sorbian, Upper",
  "Sori-Harengan",
  "Sorkhei",
  "Soro-n Raki",
  "Sorsoganon, Northern",
  "Sorsoganon, Southern",
  "Sos Kundi",
  "Sotho, Northern",
  "Sotho, Southern",
  "Sou",
  "Sou Nama",
  "Sou Upaa",
  "Sougb",
  "Soumraye",
  "Sourashtra",
  "South African Sign Language",
  "Southern Kalapuya",
  "Southern Lushootseed",
  "Sowa",
  "Sowanda",
  "Sowari",
  "Spanish",
  "Spanish Sign Language",
  "Spanish, Charapa",
  "Spiti Bhoti",
  "Spokane",
  "Squamish",
  "Sranan Tongo",
  "Sri Lankan Malay Creole",
  "Sri Lankan Sign Language",
  "Stellingwerfs",
  "Stieng, Budeh",
  "Stieng, Bulo",
  "Stod Bhoti",
  "Stoney",
  "Su’ung",
  "Suabo",
  "Suau",
  "Suba",
  "Suba-Simbiti",
  "Subanen, Central",
  "Subanen, Eastern",
  "Subanen, Northern",
  "Subanen, Southern",
  "Subanon, Kolibugan",
  "Subanon, Western",
  "Subi",
  "Subtiaba",
  "Sudest",
  "Sudovian",
  "Suena",
  "Suganga",
  "Sugut Dusun",
  "Sui",
  "Suki",
  "Suku",
  "Sukuma",
  "Sukur",
  "Sukurum",
  "Sula",
  "Sulka",
  "Sulod",
  "Suma",
  "Sumariup",
  "Sumau",
  "Sumbawa",
  "Sumbwa",
  "Sunam",
  "Sunda",
  "Sunum",
  "Sunwar",
  "Supapya",
  "Surbakhal",
  "Surgujia",
  "Suri, Kacipo-Bale",
  "Suri, Tirmaga-Chai",
  "Surigaonon",
  "Surjapuri",
  "Sursurunga",
  "Suruahá",
  "Suruí",
  "Suruí do Pará",
  "Susquehannock",
  "Susu",
  "Susuami",
  "Suundi",
  "Suwawa",
  "Suyá",
  "Svan",
  "Swabian",
  "Swahili",
  "Swahili, Congo",
  "Swati",
  "Swedish",
  "Swedish Sign Language",
  "Swiss-French Sign Language",
  "Swiss-German Sign Language",
  "Swiss-Italian Sign Language",
  "Swo",
  "Sya",
  "Sylheti",
  "Syriac",
  "Syuba",
  "T’en",
  "Ta’oih, Lower",
  "Ta’oih, Upper",
  "Taa",
  "Taabwa",
  "Tabaru",
  "Tabasaran",
  "Tabla",
  "Tabo",
  "Taburta",
  "Tacana",
  "Tachawit",
  "Tachelhit",
  "Tadaksahak",
  "Tado",
  "Tadyawan",
  "Tae’",
  "Tafi",
  "Tagabawa",
  "Tagakolu",
  "Tagalaka",
  "Tagalog",
  "Tagargrent",
  "Tagbanwa",
  "Tagbanwa, Calamian",
  "Tagbanwa, Central",
  "Tagbu",
  "Tagdal",
  "Tagin",
  "Tagish",
  "Tagoi",
  "Tahitian",
  "Tahltan",
  "Tai Daeng",
  "Tai Dam",
  "Tai Dón",
  "Tai Hongjin",
  "Tai Khang",
  "Tai Laing",
  "Tai Loi",
  "Tai Long",
  "Tai Nüa",
  "Tai Pao",
  "Tai Thanh",
  "Tai Ya",
  "Tai Yo",
  "Taikat",
  "Tainae",
  "Taíno",
  "Tairora, North",
  "Tairora, South",
  "Tairuma",
  "Taivoan",
  "Taiwan Sign Language",
  "Taje",
  "Tajik",
  "Tajio",
  "Tajuasohn",
  "Takelma",
  "Takestani",
  "Takia",
  "Takua",
  "Takuu",
  "Takwane",
  "Tal",
  "Tala",
  "Talaud",
  "Taliabu",
  "Talieng",
  "Talinga-Bwisi",
  "Talise",
  "Talodi",
  "Taloki",
  "Talondo’",
  "Talu",
  "Talysh",
  "Tama",
  "Tamagario",
  "Tamahaq, Tahaggart",
  "Tamajaq, Tawallammat",
  "Tamajeq, Tayart",
  "Taman",
  "Tamanaku",
  "Tamang, Eastern",
  "Tamang, Eastern Gorkha",
  "Tamang, Western",
  "Tamashek",
  "Tamasheq",
  "Tamazight, Central Atlas",
  "Tamazight, Standard Moroccan",
  "Tamazight, Temacine",
  "Tamazight, Tidikelt",
  "Tambora",
  "Tambotalo",
  "Tami",
  "Tamil",
  "Tamki",
  "Tampuan",
  "Tampulma",
  "Tanacross",
  "Tanahmerah",
  "Tanaina",
  "Tanana, Lower",
  "Tanana, Upper",
  "Tanapag",
  "Tandaganon",
  "Tandia",
  "Tandɨ",
  "Tanema",
  "Tangale",
  "Tangchangya",
  "Tanggu",
  "Tangko",
  "Tanglang",
  "Tangoa",
  "Tanguat",
  "Tanibili",
  "Tanimuca-Letuama",
  "Tanna, North",
  "Tanna, Southwest",
  "Tanokuku",
  "Tanzanian Sign Language",
  "Tapei",
  "Tapesena",
  "Tapiafaru",
  "Tapirapé",
  "Tarahumara, Central",
  "Tarahumara, Northern",
  "Tarahumara, Southeastern",
  "Tarahumara, Southwestern",
  "Tarahumara, Western",
  "Tarangan, East",
  "Tarangan, West",
  "Tareng",
  "Tariana",
  "Tarifit",
  "Tarjumo",
  "Tarok",
  "Taromi, Upper",
  "Tarpia",
  "Taruma",
  "Tasawaq",
  "Tasmanian, Bruny Island",
  "Tasmanian, North Midlands",
  "Tasmanian, Northeastern",
  "Tasmanian, Northern",
  "Tasmanian, Northwestern",
  "Tasmanian, Oyster Bay",
  "Tasmanian, Port Sorell",
  "Tasmanian, Southeast",
  "Tasmanian, Southwestern",
  "Tat, Muslim",
  "Tatana",
  "Tatar",
  "Tatuyo",
  "Tauade",
  "Taungyo",
  "Taupi",
  "Taupota",
  "Tause",
  "Taushiro",
  "Tausug",
  "Tauya",
  "Tavanlav",
  "Taveta",
  "Tavoyan",
  "Tawala",
  "Tawandê",
  "Tawara",
  "Tawbuid",
  "Tawoyan",
  "Tay",
  "Tày",
  "Tay Boi",
  "Tày Sa Pa",
  "Tày Tac",
  "Tayap",
  "Tayatuk",
  "Tayo",
  "Taznatit",
  "Tboli",
  "Tchaman",
  "Tchitchege",
  "Tchumbuli",
  "Te’un",
  "Teanu",
  "Tebi",
  "Tebul Sign Language",
  "Tedaga",
  "Teduray",
  "Tèẹ̀ ̣",
  "Téén",
  "Tefaro",
  "Tegali",
  "Tehit",
  "Tehl",
  "Tehuelche",
  "Teiwa",
  "Teke-Eboo",
  "Teke-Kukuya",
  "Teke-Laali",
  "Teke-Nzikou",
  "Teke-Tege",
  "Teke-Tsaayi",
  "Teke-Tyee",
  "Teke-Wuumu",
  "Teke, Ibali",
  "Tekó",
  "Tektiteko",
  "Tela-Masbuar",
  "Telefol",
  "Telugu",
  "Tem",
  "Tembé",
  "Tembo",
  "Teme",
  "Temein",
  "Temi",
  "Temiar",
  "Temoq",
  "Temuan",
  "Ten’edn",
  "Tengger",
  "Tenharim",
  "Tenino",
  "Tenis",
  "Tennet",
  "Teop",
  "Teor",
  "Tepecano",
  "Tepehua, Huehuetla",
  "Tepehua, Pisaflores",
  "Tepehua, Tlachichilco",
  "Tepehuan, Northern",
  "Tepehuan, Southeastern",
  "Tepehuan, Southwestern",
  "Tera",
  "Terebu",
  "Terei",
  "Terêna",
  "Teressa",
  "Tereweng",
  "Teribe",
  "Terik",
  "Termanu",
  "Ternate",
  "Ternateño",
  "Tese",
  "Teshenawa",
  "Tetela",
  "Tetete",
  "Tetserret",
  "Tetun",
  "Tetun Dili",
  "Tewa",
  "Tewe",
  "Tha",
  "Thachanadan",
  "Thai",
  "Thai Sign Language",
  "Thai Song",
  "Thai, Northeastern",
  "Thai, Northern",
  "Thai, Southern",
  "Thakali",
  "Thangmi",
  "Thao",
  "Tharu, Central",
  "Tharu, Dangaura",
  "Tharu, Kathariya",
  "Tharu, Mid-Eastern",
  "Tharu, Rana",
  "Thawa",
  "Thaypan",
  "Themne",
  "Thiin",
  "Tho",
  "Thompson",
  "Thopho",
  "Thu Lao",
  "Thulung",
  "Thur",
  "Thuri",
  "Tiale",
  "Tiang",
  "Tibea",
  "Tibetan Sign Language",
  "Tibetan, Amdo",
  "Tibetan, Central",
  "Tibetan, Khams",
  "Tichurong",
  "Ticuna",
  "Tidore",
  "Tidung, Northern",
  "Tidung, Southern",
  "Tiéfo",
  "Tiene",
  "Tifal",
  "Tigak",
  "Tigré",
  "Tigrigna",
  "Tii",
  "Tikar",
  "Tikopia",
  "Tillamook",
  "Tilung",
  "Tima",
  "Timbe",
  "Timbisha",
  "Timucua",
  "Tinani",
  "Tindi",
  "Tingui-Boto",
  "Tinigua",
  "Tinputz",
  "Tippera",
  "Tira",
  "Tirahi",
  "Tîrî",
  "Tita",
  "Titan",
  "Tiv",
  "Tiwa",
  "Tiwa, Northern",
  "Tiwa, Southern",
  "Tiwi",
  "Tiyaa",
  "Tjungundji",
  "Tjupany",
  "Tjurruru",
  "Tlicho",
  "Tlingit",
  "To",
  "To’abaita",
  "Toaripi",
  "Toba",
  "Toba-Maskoy",
  "Tobagonian English Creole",
  "Tobanga",
  "Tobati",
  "Tobelo",
  "Tobian",
  "Tobilung",
  "Tobo",
  "Tocho",
  "Toda",
  "Todrah",
  "Tofanma",
  "Togoyo",
  "Tohono O’odham",
  "Tojolabal",
  "Tok Pisin",
  "Tokano",
  "Tokelauan",
  "Toku-No-Shima",
  "Tol",
  "Tolaki",
  "Tolomako",
  "Tolowa",
  "Toma",
  "Tomadino",
  "Tombelala",
  "Tombonuo",
  "Tombulu",
  "Tomini",
  "Tomoip",
  "Tondano",
  "Tondi Songway Kiini",
  "Tonga",
  "Tongan",
  "Tongwe",
  "Tonjon",
  "Tonkawa",
  "Tonsawang",
  "Tonsea",
  "Tontemboan",
  "Tooro",
  "Topoiyo",
  "Toposa",
  "Torá",
  "Toraja-Sa’dan",
  "Toram",
  "Torau",
  "Toromono",
  "Torona",
  "Torres Strait Creole",
  "Torwali",
  "Totela",
  "Toto",
  "Totoli",
  "Totonac, Coyutla",
  "Totonac, Filomena Mata-Coahuitlán",
  "Totonac, Highland",
  "Totonac, Papantla",
  "Totonac, Tecpatlán",
  "Totonac, Upper Necaxa",
  "Totonac, Xicotepec de Juárez",
  "Totonac, Yecuatla",
  "Totonaco del cerro Xinolatépetl",
  "Totoro",
  "Touo",
  "Toura",
  "Toussian, Northern",
  "Toussian, Southern",
  "Towei",
  "Traveller Danish",
  "Traveller Scottish",
  "Tregami",
  "Tremembé",
  "Trieng",
  "Trimuris",
  "Tring",
  "Trinidad and Tobago Sign Language",
  "Trinidadian English Creole",
  "Trinitario",
  "Trió",
  "Triqui, Chicahuaxtla",
  "Triqui, Copala",
  "Triqui, San Martín Itunyoso",
  "Truká",
  "Trumai",
  "Ts’ün-Lao",
  "Tsaangi",
  "Tsaara",
  "Tsafiki",
  "Tsakhur",
  "Tsakonian",
  "Tsamai",
  "Tsat",
  "Tsaukambo",
  "Tseku",
  "Tsetsaut",
  "Tshangla",
  "Tshuwau",
  "Tsikimba",
  "Tsimané",
  "Tsimshian",
  "Tsishingini",
  "Tso",
  "Tsogo",
  "Tsonga",
  "Tsou",
  "Tsucuba",
  "Tsum",
  "Tsuvadi",
  "Tsuvan",
  "Tswa",
  "Tswapong",
  "Tu",
  "Tuamotuan",
  "Tubar",
  "Tübatulabal",
  "Tucano",
  "Tugbiri-Niragu",
  "Tugen",
  "Tugun",
  "Tugutil",
  "Tujia, Northern",
  "Tujia, Southern",
  "Tukang Besi North",
  "Tukang Besi South",
  "Tuki",
  "Tukudede",
  "Tukumanféd",
  "Tula",
  "Tulai",
  "Tulehu",
  "Tulil",
  "Tulishi",
  "Tulu",
  "Tulu-Bohuai",
  "Tuma-Irumu",
  "Tumak",
  "Tumbuka",
  "Tumi",
  "Tumleo",
  "Tumtum",
  "Tumzabt",
  "Tunebo, Angosturas",
  "Tunebo, Barro Negro",
  "Tunebo, Central",
  "Tunebo, Western",
  "Tunen",
  "Tungag",
  "Tunggare",
  "Tunia",
  "Tunica",
  "Tunisian Sign Language",
  "Tunjung",
  "Tunni",
  "Tunzuii",
  "Tuotomb",
  "Tuparí",
  "Tupinambá",
  "Tupinikin",
  "Tupuri",
  "Turaka",
  "Turi",
  "Turiwára",
  "Turka",
  "Turkana",
  "Turkish",
  "Turkish Sign Language",
  "Turkmen",
  "Turks and Caicos English Creole",
  "Turoyo",
  "Turumsa",
  "Turung",
  "Tuscarora",
  "Tutchone, Northern",
  "Tutchone, Southern",
  "Tutelo",
  "Tutong",
  "Tututni",
  "Tuvaluan",
  "Tuvan",
  "Tuwari",
  "Tuwuli",
  "Tuxá",
  "Tuxináwa",
  "Tuyuca",
  "Twana",
  "Twendi",
  "Twents",
  "Tyap",
  "Tz’utujil",
  "Tzeltal",
  "Tzotzil",
  "Uab Meto",
  "Uamué",
  "Uare",
  "Ubaghara",
  "Ubang",
  "Ubi",
  "Ubir",
  "Ubykh",
  "Uda",
  "Udi",
  "Udihe",
  "Udmurt",
  "Uduk",
  "Ufim",
  "Ugandan Sign Language",
  "Ughele",
  "Ugong",
  "Uhami",
  "Uisai",
  "Ujir",
  "Ukaan",
  "Ukhwejo",
  "Ukit",
  "Ukpet-Ehom",
  "Ukrainian",
  "Ukrainian Sign Language",
  "Ukue",
  "Ukuriguma",
  "Ukwa",
  "Ukwuani-Aboh-Ndoni",
  "Ulau-Suain",
  "Ulch",
  "Ulithian",
  "Ullatan",
  "Ulukwumi",
  "Ulumanda’",
  "Ulwa",
  "Uma",
  "Uma’ Lasan",
  "Uma’ Lung",
  "Umanakaina",
  "Umatilla",
  "Umbindhamu",
  "Umbu-Ungu",
  "Umbugarla",
  "Umbundu",
  "Umeda",
  "Umiida",
  "Umon",
  "Umotína",
  "Umpila",
  "Una",
  "Unami",
  "Uneapa",
  "Uneme",
  "Unggaranggu",
  "Unggumi",
  "Uni",
  "Unserdeutsch",
  "Unua",
  "Unubahe",
  "Upper Umpqua",
  "Ura",
  "Uradhi",
  "Urak Lawoi’",
  "Urali",
  "Urapmin",
  "Urarina",
  "Urat",
  "Urdu",
  "Urhobo",
  "Uri",
  "Urim",
  "Urimo",
  "Uripiv-Wala-Rano-Atchin",
  "Urningangg",
  "Uru",
  "Uru-Eu-Wau-Wau",
  "Uru-Pa-In",
  "Uruangnirin",
  "Uruava",
  "Uruguayan Sign Language",
  "Urum",
  "Urumi",
  "Usaghade",
  "Usan",
  "Usarufa",
  "Ushojo",
  "Usku",
  "Usoi",
  "Uspanteko",
  "Utarmbung",
  "Ute-Southern Paiute",
  "Utu",
  "Utugwang-Irungene-Afrike",
  "Uvbie",
  "Uya",
  "Uyajitaya",
  "Uyghur",
  "Uzbek",
  "Uzbek, Northern",
  "Uzbek, Southern",
  "Uzekwe",
  "V’ënen Taut",
  "Vaagri Booli",
  "Vaeakau-Taumako",
  "Vafsi",
  "Vaghri",
  "Vaghua",
  "Vagla",
  "Vai",
  "Vaie",
  "Vaiphei",
  "Vale",
  "Valencian Sign Language",
  "Vamale",
  "Vame",
  "Vangunu",
  "Vanuma",
  "Vao",
  "Varhadi-Nagpuri",
  "Varisi",
  "Varli",
  "Varsaf",
  "Vasavi",
  "Veddah",
  "Vehes",
  "Veluws",
  "Vemgo-Mabas",
  "Venda",
  "Venetian",
  "Venezuelan Sign Language",
  "Vengo",
  "Ventureño",
  "Veps",
  "Vera’a",
  "Verre",
  "Vidunda",
  "Viemo",
  "Vietnamese",
  "Viid",
  "Vilela",
  "Vili",
  "Vincentian English Creole",
  "Vinekula",
  "Vinitiri",
  "Vinza",
  "Virgin Islands English Creole",
  "Vishavan",
  "Viti",
  "Vitou",
  "Vitu",
  "Vod",
  "Vono",
  "Vori",
  "Voro",
  "Võro",
  "Vula’a",
  "Vumbu",
  "Vune mi",
  "Vunjo",
  "Vurës",
  "Vute",
  "Vwanji",
  "Wa, Parauk",
  "Wa, Vo",
  "Wa’ema",
  "Waama",
  "Waamwang",
  "Waanyi",
  "Waata",
  "Wabo",
  "Waboda",
  "Wadaginam",
  "Waddar",
  "Wadi Wadi",
  "Wadikali",
  "Wadjabangayi",
  "Wadjiginy",
  "Wadjigu",
  "Wae Rana",
  "Waffa",
  "Wagama",
  "Wagawaga",
  "Wagaya",
  "Wagdi",
  "Wagi",
  "Wagiman",
  "Wahgi",
  "Waigali",
  "Wailaki",
  "Waima",
  "Waima’a",
  "Waimaha",
  "Waimiri-Atroarí",
  "Waioli",
  "Waiwai",
  "Waja",
  "Wajarri",
  "Wajiara",
  "Wajuk",
  "Waka",
  "Wakabunga",
  "Wakawaka",
  "Wakhi",
  "Wakoná",
  "Wala",
  "Walak",
  "Walangama",
  "Wali",
  "Walio",
  "Walla Walla",
  "Wallisian",
  "Walloon",
  "Walmajarri",
  "Walman",
  "Walser",
  "Walungge",
  "Waluwarra",
  "Wamas",
  "Wambaya",
  "Wambon",
  "Wambule",
  "Wamesa",
  "Wamey",
  "Wamin",
  "Wampanoag",
  "Wampar",
  "Wampís",
  "Wampur",
  "Wan",
  "Wanano",
  "Wanap",
  "Wanda",
  "Wandala",
  "Wandarang",
  "Wandji",
  "Wané",
  "Waneci",
  "Wangaaybuwan-Ngiyambaa",
  "Wanggamala",
  "Wanggom",
  "Wangkangurru",
  "Wangkayutyuru",
  "Wangkumara",
  "Wannu",
  "Wano",
  "Wantoat",
  "Wanukaka",
  "Waorani",
  "Wapan",
  "Wãpha",
  "Wapishana",
  "Wappo",
  "War-Jaintia",
  "Wara",
  "Wára",
  "Waran",
  "Warao",
  "Waray",
  "Waray-Waray",
  "Wardaman",
  "Wardandi",
  "Warembori",
  "Wares",
  "Waris",
  "Waritai",
  "Wariyangga",
  "Warji",
  "Warkay-Bipim",
  "Warlmanpa",
  "Warlpiri",
  "Warnang",
  "Warnman",
  "Waropen",
  "Warrgamay",
  "Warrwa",
  "Wartha Thuntai",
  "Waru",
  "Warumungu",
  "Waruna",
  "Warungu",
  "Warwar Feni",
  "Wasa",
  "Wasco-Wishram",
  "Wasembo",
  "Washo",
  "Waskia",
  "Wasu",
  "Watakataui",
  "Wathawurrung",
  "Watiwa",
  "Watubela",
  "Waube",
  "Waurá",
  "Wauyai",
  "Wawa",
  "Wawonii",
  "Waxianghua",
  "Wayampi",
  "Wayana",
  "Wayoró",
  "Wayu",
  "Wayuu",
  "Wè Northern",
  "Wè Southern",
  "Wè Western",
  "Wedau",
  "Weenhayek",
  "Weh",
  "Wejewa",
  "Welaun",
  "Weliki",
  "Welsh",
  "Wemale",
  "Wemba Wemba",
  "Wendat",
  "Wergaia",
  "Weri",
  "Wersing",
  "West Bengal Sign Language",
  "West Flemish",
  "Western Neo-Aramaic",
  "Westphalien",
  "Wewaw",
  "Weyto",
  "Whitesands",
  "Wia",
  "Wiarumus",
  "Wichita",
  "Wik Ngathan",
  "Wik-Epa",
  "Wik-Iiyanh",
  "Wik-Keyangan",
  "Wik-Me’anha",
  "Wik-Mungkan",
  "Wikalkan",
  "Wikngenchera",
  "Wilawila",
  "Wintu",
  "Winyé",
  "Wipi",
  "Wiradjuri",
  "Wiraféd",
  "Wirangu",
  "Witoto, Minika",
  "Witoto, Murui",
  "Witoto, Nipode",
  "Witu",
  "Wiyot",
  "Woccon",
  "Wogamusin",
  "Wogeo",
  "Woi",
  "Woiwurrung",
  "Wojenaka",
  "Wolane",
  "Wolani",
  "Wolaytta",
  "Woleaian",
  "Wolio",
  "Wolof",
  "Wolof, Gambian",
  "Wom",
  "Wongo",
  "Woria",
  "Worimi",
  "Worodougou",
  "Worrorra",
  "Wotjobaluk",
  "Wotu",
  "Woun Meu",
  "Wudu",
  "Wuliwuli",
  "Wulna",
  "Wumboko",
  "Wumbvu",
  "Wunambal",
  "Wunumara",
  "Wushi",
  "Wutung",
  "Wutunhua",
  "Wuvulu-Aua",
  "Wuzlam",
  "Wyandot",
  "Wymysorys",
  "Xaasongaxango",
  "Xakriabá",
  "Xamtanga",
  "Xârâcùù",
  "Xârâgurè",
  "Xavánte",
  "Xerénte",
  "Xetá",
  "Xhosa",
  "Xibe",
  "Xinca",
  "Xingoni",
  "Xipaya",
  "Xiri",
  "Xiriâna",
  "Xokleng",
  "Xukurú",
  "Yaaku",
  "Yabaâna",
  "Yabarana",
  "Yabem",
  "Yaben",
  "Yabong",
  "Yabula Yabula",
  "Yace",
  "Yadgha",
  "Yaeyama",
  "Yagara",
  "Yagaria",
  "Yagnobi",
  "Yagua",
  "Yagwoia",
  "Yahadian",
  "Yahang",
  "Yahuna",
  "Yaka",
  "Yakaikeke",
  "Yakama",
  "Yakan",
  "Yakkha",
  "Yakkha, Chhathare",
  "Yakoma",
  "Yakut",
  "Yala",
  "Yalahatan",
  "Yalakalore",
  "Yalaku",
  "Yalarnnga",
  "Yale",
  "Yale, Kosarek",
  "Yaleba",
  "Yali, Angguruk",
  "Yali, Ninia",
  "Yali, Pass Valley",
  "Yalunka",
  "Yámana",
  "Yamap",
  "Yamba",
  "Yambes",
  "Yambeta",
  "Yamdena",
  "Yameo",
  "Yami",
  "Yaminahua",
  "Yamongeri",
  "Yamphu",
  "Yamphu, Southern",
  "Yan-nhangu",
  "Yan-nhangu Sign Language",
  "Yana",
  "Yanda",
  "Yandjibara",
  "Yandruwandha",
  "Yanesha’",
  "Yangben",
  "Yangkam",
  "Yangman",
  "Yango",
  "Yangum Dey",
  "Yangum Gel",
  "Yangum Mon",
  "Yankunytjatjara",
  "Yanomámi",
  "Yanomamö",
  "Yanyuwa",
  "Yao",
  "Yaouré",
  "Yapese",
  "Yaqay",
  "Yaqui",
  "Yarawata",
  "Yardliyawarra",
  "Yareba",
  "Yarluyandi",
  "Yaroamë",
  "Yarsun",
  "Yassic",
  "Yatay",
  "Yau",
  "Yauma",
  "Yaur",
  "Yavitero",
  "Yawa",
  "Yawalapití",
  "Yawanawa",
  "Yawarawarga",
  "Yaweyuha",
  "Yawijibaya",
  "Yawiyo",
  "Yawuno Teneyo",
  "Yawuru",
  "Yaygir",
  "Yazghulami",
  "Yei",
  "Yekora",
  "Yela",
  "Yélî Dnye",
  "Yelmek",
  "Yemba",
  "Yemsa",
  "Yendang",
  "Yeni",
  "Yeniche",
  "Yerakai",
  "Yeresiam",
  "Yeretuar",
  "Yeri",
  "Yerong",
  "Yerukula",
  "Yessan-Mayo",
  "Yetfa",
  "Yevanic",
  "Yeyi",
  "Yi, Wuding-Luquan",
  "Yiddish",
  "Yiddish, Eastern",
  "Yiddish, Western",
  "Yidiny",
  "Yil",
  "Yilan Creole",
  "Yimas",
  "Yinbaw",
  "Yindjibarndi",
  "Yindjilandji",
  "Yine",
  "Yinggarda",
  "Yinhawangka",
  "Yiningayi",
  "Yintale",
  "Yinwum",
  "Yipma",
  "Yir-Yoront",
  "Yirandali",
  "Yirrk-Mel",
  "Yis",
  "Yitha Yitha",
  "Yoba",
  "Yogad",
  "Yoidik",
  "Yoke",
  "Yokuts",
  "Yola",
  "Yolngu Sign Language",
  "Yom",
  "Yonaguni",
  "Yong",
  "Yongkom",
  "Yopno",
  "Yora",
  "Yoron",
  "Yorta Yorta",
  "Yoruba",
  "Yote",
  "Yotti",
  "Yout Wam",
  "Yoy",
  "Yuanga",
  "Yucatec Maya Sign Language",
  "Yuchi",
  "Yucuna",
  "Yug",
  "Yugambal",
  "Yugul",
  "Yugur, East",
  "Yugur, West",
  "Yuhup",
  "Yui",
  "Yukaghir, Northern",
  "Yukaghir, Southern",
  "Yuki",
  "Yukpa",
  "Yukuben",
  "Yulu",
  "Yupik, Central",
  "Yupik, Naukan",
  "Yupik, Pacific Gulf",
  "Yupik, Saint Lawrence Island",
  "Yupik, Sirenik",
  "Yuqui",
  "Yuracare",
  "Yurats",
  "Yurok",
  "Yuru",
  "Yuwei",
  "Yuyu",
  "Ywom",
  "Zabana",
  "Zaghawa",
  "Zaiwa",
  "Zakhring",
  "Zambian Sign Language",
  "Zamwal",
  "Zan Gula",
  "Zanaki",
  "Zande",
  "Zangskari",
  "Záparo",
  "Zapotec",
  "Zapotec, Aloápam",
  "Zapotec, Amatlán",
  "Zapotec, Asunción Mixtepec",
  "Zapotec, Ayoquesco",
  "Zapotec, Cajonos",
  "Zapotec, Chichicapan",
  "Zapotec, Choapan",
  "Zapotec, Coatecas Altas",
  "Zapotec, Coatlán",
  "Zapotec, El Alto",
  "Zapotec, Elotepec",
  "Zapotec, Guevea de Humboldt",
  "Zapotec, Güilá",
  "Zapotec, Isthmus",
  "Zapotec, Lachiguiri",
  "Zapotec, Lachixío",
  "Zapotec, Lapaguía-Guivini",
  "Zapotec, Las Delicias",
  "Zapotec, Loxicha",
  "Zapotec, Mazaltepec",
  "Zapotec, Miahuatlán",
  "Zapotec, Mitla",
  "Zapotec, Mixtepec",
  "Zapotec, Ocotlán",
  "Zapotec, Ozolotepec",
  "Zapotec, Petapa",
  "Zapotec, Quiavicuzas",
  "Zapotec, Quioquitani-Quierí",
  "Zapotec, Rincón",
  "Zapotec, San Agustín Mixtepec",
  "Zapotec, San Baltazar Loxicha",
  "Zapotec, San Pedro Quiatoni",
  "Zapotec, San Vicente Coatlán",
  "Zapotec, Santa Catarina Albarradas",
  "Zapotec, Santa Inés Yatzechi",
  "Zapotec, Santa María Quiegolani",
  "Zapotec, Santiago Xanica",
  "Zapotec, Santo Domingo Albarradas",
  "Zapotec, Sierra de Juárez",
  "Zapotec, Southeastern Ixtlán",
  "Zapotec, Southern Rincon",
  "Zapotec, Tabaa",
  "Zapotec, Tejalapan",
  "Zapotec, Texmelucan",
  "Zapotec, Tilquiapan",
  "Zapotec, Tlacolulita",
  "Zapotec, Totomachapan",
  "Zapotec, Western Tlacolula Valley",
  "Zapotec, Xadani",
  "Zapotec, Xanaguía",
  "Zapotec, Yalálag",
  "Zapotec, Yareni",
  "Zapotec, Yatee",
  "Zapotec, Yatzachi",
  "Zapotec, Yautepec",
  "Zapotec, Zaachila",
  "Zapotec, Zaniza",
  "Zapotec, Zoogocho",
  "Zaramo",
  "Zari",
  "Zarma",
  "Zauzou",
  "Zay",
  "Zayein",
  "Zayse",
  "Zaza",
  "Zazaki, Northern",
  "Zazaki, Southern",
  "Zazao",
  "Zeem",
  "Zeeuws",
  "Zemgalian",
  "Zenag",
  "Zenaga",
  "Zerenkel",
  "Zhaba",
  "Zhire",
  "Zhoa",
  "Zhuang",
  "Zhuang, Central Hongshuihe",
  "Zhuang, Dai",
  "Zhuang, Eastern Hongshuihe",
  "Zhuang, Guibei",
  "Zhuang, Guibian",
  "Zhuang, Lianshan",
  "Zhuang, Liujiang",
  "Zhuang, Liuqian",
  "Zhuang, Minz",
  "Zhuang, Nong",
  "Zhuang, Qiubei",
  "Zhuang, Yang",
  "Zhuang, Yongbei",
  "Zhuang, Yongnan",
  "Zhuang, Youjiang",
  "Zhuang, Zuojiang",
  "Zia",
  "Zialo",
  "Zibinju",
  "Zigula",
  "Zimakani",
  "Zimba",
  "Zimbabwe Sign Language",
  "Zinza",
  "Zizilivakan",
  "Zo",
  "Zo’é",
  "Zokhuo",
  "Zoque, Chimalapa",
  "Zoque, Copainalá",
  "Zoque, Francisco León",
  "Zoque, Rayón",
  "Zoque, Tabasco",
  "Zorop",
  "Zul",
  "Zula",
  "Zulgo-Gemzek",
  "Zulu",
  "Zumaya",
  "Zuni",
  ];

  export default languages;