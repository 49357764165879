const mockedData = [
    {
        _id: '1',
        "firstName": "Emily",
        "lastName": "Williams",
        "email": "nicolestone@green.info",
        "phone": "737.394.5749x770",
        "nrmpId": 76026,
        "workAuthorization": "Not Authorized",
        "gender": "Male",
        "hometown": "Silvachester",
        "ethnicity": "White",
        "couplesMatch": "Yes",
        "medicalSchoolName": "Yale School of Medicine",
        "aoaStatus": "yes",
        "goldHumanism": "yes",
        "firstAuthorPublications": 2,
        "peerReviewedPublications": 9,
        "posterPresentations": 4,
        "podiumPresentations": 6,
        "aamc": "d114084a27a180f057f91dfabc6fc5cf",
        "previouslyAppliedToSpecialty": "No",
        "isSignal": true,
        "usmleStep1Score": 208,
        "usmleStep2Score": 276,
        "comlexLevel1Score": 663,
        "comlexLevel2Score": 554
    },
    {
        _id: '2',
        "firstName": "Keith",
        "lastName": "Mills",
        "email": "davidjones@yahoo.com",
        "phone": "+1-825-583-4600x880",
        "nrmpId": 13357,
        "workAuthorization": "usCitizenNatPermRefAsy",
        "gender": "Non-binary",
        "hometown": "Port Melissa",
        "ethnicity": "Mixed",
        "couplesMatch": "No",
        "medicalSchoolName": "Johns Hopkins University School of Medicine",
        "aoaStatus": "noChapter",
        "goldHumanism": "no",
        "firstAuthorPublications": 2,
        "peerReviewedPublications": 14,
        "posterPresentations": 10,
        "podiumPresentations": 8,
        "aamc": "dc0f6abfde20ac6f8f36314b5769138b",
        "previouslyAppliedToSpecialty": "Yes",
        "isSignal": false,
        "usmleStep1Score": 272,
        "usmleStep2Score": 271,
        "comlexLevel1Score": 669,
        "comlexLevel2Score": 665
    },
    {
        _id: '3',
        "firstName": "David",
        "lastName": "Zavala",
        "email": "yjordan@allen.com",
        "phone": "(027)524-3288x3299",
        "nrmpId": 42765,
        "workAuthorization": "usCitizenNatPermRefAsy",
        "gender": "Male",
        "hometown": "Lake Tiffany",
        "ethnicity": "Other",
        "couplesMatch": "Yes",
        "medicalSchoolName": "University of California, San Francisco, School of Medicine",
        "aoaStatus": "Member",
        "goldHumanism": "Awarded",
        "firstAuthorPublications": 4,
        "peerReviewedPublications": 0,
        "posterPresentations": 7,
        "podiumPresentations": 9,
        "aamc": "1fbb06c7d7c2b77f8ef106a3476a60fc",
        "previouslyAppliedToSpecialty": "No",
        "isSignal": false,
        "usmleStep1Score": 199,
        "usmleStep2Score": 228,
        "comlexLevel1Score": 749,
        "comlexLevel2Score": 573
    },
    {
        _id: '4',
        "firstName": "Jonathan",
        "lastName": "Harris",
        "email": "johnsonbrenda@yahoo.com",
        "phone": "426.072.2654x39512",
        "nrmpId": 84432,
        "workAuthorization": "usCitizenNatPermRefAsy",
        "gender": "Female",
        "hometown": "Joechester",
        "ethnicity": "Asian",
        "couplesMatch": "No",
        "medicalSchoolName": "Stanford University School of Medicine",
        "aoaStatus": "Member",
        "goldHumanism": "Not Awarded",
        "firstAuthorPublications": 4,
        "peerReviewedPublications": 9,
        "posterPresentations": 20,
        "podiumPresentations": 2,
        "aamc": "58cb1f2d89a905d39f644495d18ae0b6",
        "previouslyAppliedToSpecialty": "No",
        "isSignal": true,
        "usmleStep1Score": 216,
        "usmleStep2Score": 209,
        "comlexLevel1Score": 770,
        "comlexLevel2Score": 603
    },
    {
        _id: '5',
        "firstName": "Sarah",
        "lastName": "Brown",
        "email": "freyandrea@gmail.com",
        "phone": "008.179.3074x50158",
        "nrmpId": 46891,
        "workAuthorization": "Not Authorized",
        "gender": "Male",
        "hometown": "South Linda",
        "ethnicity": "Mixed",
        "couplesMatch": "No",
        "medicalSchoolName": "Yale School of Medicine",
        "aoaStatus": "Member",
        "goldHumanism": "Awarded",
        "firstAuthorPublications": 9,
        "peerReviewedPublications": 2,
        "posterPresentations": 6,
        "podiumPresentations": 5,
        "aamc": "8d223822fb5a2688c648c7e79969affe",
        "previouslyAppliedToSpecialty": "Yes",
        "isSignal": true,
        "usmleStep1Score": 209,
        "usmleStep2Score": 266,
        "comlexLevel1Score": 388,
        "comlexLevel2Score": 370
    },
    {
        _id: '6',
        "firstName": "Ryan",
        "lastName": "Harvey",
        "email": "pcooper@miller-chaney.com",
        "phone": "518-132-5732x47882",
        "nrmpId": 92669,
        "workAuthorization": "Permanent Resident",
        "gender": "Non-binary",
        "hometown": "Kerrfort",
        "ethnicity": "Hispanic",
        "couplesMatch": "Yes",
        "medicalSchoolName": "Stanford University School of Medicine",
        "aoaStatus": "Member",
        "goldHumanism": "Not Awarded",
        "firstAuthorPublications": 0,
        "peerReviewedPublications": 13,
        "posterPresentations": 8,
        "podiumPresentations": 10,
        "aamc": "d51606d35a0e7d1806525dce1176c4da",
        "previouslyAppliedToSpecialty": "No",
        "isSignal": true,
        "usmleStep1Score": 247,
        "usmleStep2Score": 218,
        "comlexLevel1Score": 708,
        "comlexLevel2Score": 631
    },
    {
        _id: '7',
        "firstName": "Rachel",
        "lastName": "Jones",
        "email": "darrell78@hotmail.com",
        "phone": "1-337-235-9628x20406",
        "nrmpId": 47325,
        "workAuthorization": "Work Visa",
        "gender": "Female",
        "hometown": "Maryview",
        "ethnicity": "White",
        "couplesMatch": "No",
        "medicalSchoolName": "Yale School of Medicine",
        "aoaStatus": "Not Member",
        "goldHumanism": "Not Awarded",
        "firstAuthorPublications": 6,
        "peerReviewedPublications": 5,
        "posterPresentations": 14,
        "podiumPresentations": 3,
        "aamc": "13e1a8cb6e444f4daeeef8b83f307a1e",
        "previouslyAppliedToSpecialty": "Yes",
        "isSignal": false,
        "usmleStep1Score": 244,
        "usmleStep2Score": 262,
        "comlexLevel1Score": 423,
        "comlexLevel2Score": 390
    },
    {
        _id: '8',
        "firstName": "Nancy",
        "lastName": "Lee",
        "email": "rachel73@yahoo.com",
        "phone": "(235)599-8193x542",
        "nrmpId": 10819,
        "workAuthorization": "usCitizenNatPermRefAsy",
        "gender": "Female",
        "hometown": "Lake Melissafurt",
        "ethnicity": "Hispanic",
        "couplesMatch": "Yes",
        "medicalSchoolName": "University of California, San Francisco, School of Medicine",
        "aoaStatus": "Member",
        "goldHumanism": "Not Awarded",
        "firstAuthorPublications": 5,
        "peerReviewedPublications": 8,
        "posterPresentations": 16,
        "podiumPresentations": 0,
        "aamc": "1165cb9210abf79e0d9b27dfb8b2b132",
        "previouslyAppliedToSpecialty": "No",
        "isSignal": false,
        "usmleStep1Score": 258,
        "usmleStep2Score": 245,
        "comlexLevel1Score": 676,
        "comlexLevel2Score": 518
    },
    {
        _id: '9',
        "firstName": "Christina",
        "lastName": "Hernandez",
        "email": "ubradley@hotmail.com",
        "phone": "+1-097-437-2071x91124",
        "nrmpId": 50769,
        "workAuthorization": "US Citizen",
        "gender": "Non-binary",
        "hometown": "Jasonmouth",
        "ethnicity": "Black",
        "couplesMatch": "No",
        "medicalSchoolName": "Johns Hopkins University School of Medicine",
        "aoaStatus": "Not Member",
        "goldHumanism": "Awarded",
        "firstAuthorPublications": 7,
        "peerReviewedPublications": 11,
        "posterPresentations": 9,
        "podiumPresentations": 4,
        "aamc": "a0e4d959b7c3c9639fa6c77f7db2b69e",
        "previouslyAppliedToSpecialty": "Yes",
        "isSignal": true,
        "usmleStep1Score": 203,
        "usmleStep2Score": 251,
        "comlexLevel1Score": 360,
        "comlexLevel2Score": 471
    },
    {
        _id: '10',
        "firstName": "Jeffrey",
        "lastName": "King",
        "email": "lorrainemitchell@hotmail.com",
        "phone": "(613)887-5457",
        "nrmpId": 20805,
        "workAuthorization": "Permanent Resident",
        "gender": "Male",
        "hometown": "West Jennifer",
        "ethnicity": "Asian",
        "couplesMatch": "Yes",
        "medicalSchoolName": "Stanford University School of Medicine",
        "aoaStatus": "Member",
        "goldHumanism": "Not Awarded",
        "firstAuthorPublications": 3,
        "peerReviewedPublications": 10,
        "posterPresentations": 5,
        "podiumPresentations": 7,
        "aamc": "ff48c2418afdfa4ffbdea4c01a52bef7",
        "previouslyAppliedToSpecialty": "No",
        "isSignal": false,
        "usmleStep1Score": 270,
        "usmleStep2Score": 280,
        "comlexLevel1Score": 785,
        "comlexLevel2Score": 598
    },
    {
        _id: '11',
        "firstName": "Anthony",
        "lastName": "Walker",
        "email": "stephen69@ross-mitchell.com",
        "phone": "(805)408-8332x487",
        "nrmpId": 19123,
        "workAuthorization": "Work Visa",
        "gender": "Male",
        "hometown": "Lake Joseph",
        "ethnicity": "White",
        "couplesMatch": "No",
        "medicalSchoolName": "Yale School of Medicine",
        "aoaStatus": "Not Member",
        "goldHumanism": "Awarded",
        "firstAuthorPublications": 1,
        "peerReviewedPublications": 7,
        "posterPresentations": 19,
        "podiumPresentations": 1,
        "aamc": "7a43ccf8382dbf25ee6cd45a12b8d9c1",
        "previouslyAppliedToSpecialty": "Yes",
        "isSignal": true,
        "usmleStep1Score": 190,
        "usmleStep2Score": 230,
        "comlexLevel1Score": 350,
        "comlexLevel2Score": 370
    },
    {
        _id: '12',
        "firstName": "Michael",
        "lastName": "Allen",
        "email": "denisehernandez@gmail.com",
        "phone": "+1-207-800-8568x643",
        "nrmpId": 58934,
        "workAuthorization": "US Citizen",
        "gender": "Male",
        "hometown": "North Joshua",
        "ethnicity": "Other",
        "couplesMatch": "Yes",
        "medicalSchoolName": "University of California, San Francisco, School of Medicine",
        "aoaStatus": "Member",
        "goldHumanism": "Not Awarded",
        "firstAuthorPublications": 0,
        "peerReviewedPublications": 1,
        "posterPresentations": 18,
        "podiumPresentations": 0,
        "aamc": "2a3d2f5f5b292df33213d073acea9c8a",
        "previouslyAppliedToSpecialty": "No",
        "isSignal": false,
        "usmleStep1Score": 195,
        "usmleStep2Score": 275,
        "comlexLevel1Score": 490,
        "comlexLevel2Score": 720
    },
    {
        _id: '13',
        "firstName": "Samantha",
        "lastName": "Smith",
        "email": "anthony77@gmail.com",
        "phone": "+1-268-962-9874x7987",
        "nrmpId": 32840,
        "workAuthorization": "Not Authorized",
        "gender": "Female",
        "hometown": "New Jeffrey",
        "ethnicity": "Black",
        "couplesMatch": "No",
        "medicalSchoolName": "Johns Hopkins University School of Medicine",
        "aoaStatus": "Not Member",
        "goldHumanism": "Awarded",
        "firstAuthorPublications": 8,
        "peerReviewedPublications": 4,
        "posterPresentations": 3,
        "podiumPresentations": 10,
        "aamc": "f143c0a23e6d88b3aaf0eb8f4722a9d6",
        "previouslyAppliedToSpecialty": "Yes",
        "isSignal": false,
        "usmleStep1Score": 260,
        "usmleStep2Score": 204,
        "comlexLevel1Score": 375,
        "comlexLevel2Score": 675
    },
    {
        _id: '14',
        "firstName": "Jessica",
        "lastName": "Garcia",
        "email": "karen59@yahoo.com",
        "phone": "(416)377-4988x688",
        "nrmpId": 34221,
        "workAuthorization": "Permanent Resident",
        "gender": "Female",
        "hometown": "Lake Stephanie",
        "ethnicity": "Hispanic",
        "couplesMatch": "Yes",
        "medicalSchoolName": "Stanford University School of Medicine",
        "aoaStatus": "Member",
        "goldHumanism": "Awarded",
        "firstAuthorPublications": 10,
        "peerReviewedPublications": 12,
        "posterPresentations": 12,
        "podiumPresentations": 6,
        "aamc": "c50b6bb26f6e337d95e49800b8f8c3c6",
        "previouslyAppliedToSpecialty": "No",
        "isSignal": true,
        "usmleStep1Score": 280,
        "usmleStep2Score": 250,
        "comlexLevel1Score": 500,
        "comlexLevel2Score": 650
    },
    {
        _id: '15',
        "firstName": "Elizabeth",
        "lastName": "Martinez",
        "email": "cunninghamjennifer@hotmail.com",
        "phone": "291.914.7382x994",
        "nrmpId": 73415,
        "workAuthorization": "US Citizen",
        "gender": "Female",
        "hometown": "Port Brittany",
        "ethnicity": "Mixed",
        "couplesMatch": "No",
        "medicalSchoolName": "Yale School of Medicine",
        "aoaStatus": "Member",
        "goldHumanism": "Not Awarded",
        "firstAuthorPublications": 3,
        "peerReviewedPublications": 6,
        "posterPresentations": 1,
        "podiumPresentations": 9,
        "aamc": "9cf0221ddfd1c1f6076d83f2cbf99f9d",
        "previouslyAppliedToSpecialty": "Yes",
        "isSignal": false,
        "usmleStep1Score": 215,
        "usmleStep2Score": 205,
        "comlexLevel1Score": 600,
        "comlexLevel2Score": 700
    },
    {
        _id: '16',
        "firstName": "Daniel",
        "lastName": "Clark",
        "email": "stevenssandra@yahoo.com",
        "phone": "(732)993-0846x5618",
        "nrmpId": 97229,
        "workAuthorization": "Work Visa",
        "gender": "Male",
        "hometown": "East Jenniferburgh",
        "ethnicity": "Asian",
        "couplesMatch": "Yes",
        "medicalSchoolName": "University of California, San Francisco, School of Medicine",
        "aoaStatus": "Not Member",
        "goldHumanism": "Awarded",
        "firstAuthorPublications": 2,
        "peerReviewedPublications": 3,
        "posterPresentations": 17,
        "podiumPresentations": 4,
        "aamc": "6f21613f9d68db1f1d2dcb4e6c6266df",
        "previouslyAppliedToSpecialty": "No",
        "isSignal": true,
        "usmleStep1Score": 240,
        "usmleStep2Score": 290,
        "comlexLevel1Score": 400,
        "comlexLevel2Score": 790
    },
    {
        _id: '17',
        "firstName": "Charles",
        "lastName": "Wilson",
        "email": "rachel82@gmail.com",
        "phone": "1-556-634-3390x6908",
        "nrmpId": 14859,
        "workAuthorization": "Not Authorized",
        "gender": "Male",
        "hometown": "New Elizabeth",
        "ethnicity": "White",
        "couplesMatch": "No",
        "medicalSchoolName": "Johns Hopkins University School of Medicine",
        "aoaStatus": "Member",
        "goldHumanism": "Not Awarded",
        "firstAuthorPublications": 5,
        "peerReviewedPublications": 15,
        "posterPresentations": 2,
        "podiumPresentations": 10,
        "aamc": "76f40b701d32ee77b1f6e25564a2d35e",
        "previouslyAppliedToSpecialty": "Yes",
        "isSignal": false,
        "usmleStep1Score": 260,
        "usmleStep2Score": 200,
        "comlexLevel1Score": 395,
        "comlexLevel2Score": 610
    },
    {
        _id: '18',
        "firstName": "Jennifer",
        "lastName": "Anderson",
        "email": "amandamoore@thompson.com",
        "phone": "895-974-6745x9024",
        "nrmpId": 17860,
        "workAuthorization": "Permanent Resident",
        "gender": "Female",
        "hometown": "West Michael",
        "ethnicity": "Black",
        "couplesMatch": "Yes",
        "medicalSchoolName": "Stanford University School of Medicine",
        "aoaStatus": "Not Member",
        "goldHumanism": "Awarded",
        "firstAuthorPublications": 8,
        "peerReviewedPublications": 10,
        "posterPresentations": 15,
        "podiumPresentations": 8,
        "aamc": "54302d3d0cf24648b0f5956c3f8c1d76",
        "previouslyAppliedToSpecialty": "No",
        "isSignal": false,
        "usmleStep1Score": 276,
        "usmleStep2Score": 219,
        "comlexLevel1Score": 505,
        "comlexLevel2Score": 685
    },
    {
        _id: '19',
        "firstName": "Thomas",
        "lastName": "Thompson",
        "email": "mariabaker@gmail.com",
        "phone": "1-803-435-3745",
        "nrmpId": 27559,
        "workAuthorization": "US Citizen",
        "gender": "Male",
        "hometown": "North Michaelchester",
        "ethnicity": "Mixed",
        "couplesMatch": "No",
        "medicalSchoolName": "Yale School of Medicine",
        "aoaStatus": "Member",
        "goldHumanism": "Not Awarded",
        "firstAuthorPublications": 7,
        "peerReviewedPublications": 13,
        "posterPresentations": 10,
        "podiumPresentations": 6,
        "aamc": "2fbb54c702f5f5e26d71c4beb88c3ae8",
        "previouslyAppliedToSpecialty": "Yes",
        "isSignal": true,
        "usmleStep1Score": 250,
        "usmleStep2Score": 240,
        "comlexLevel1Score": 720,
        "comlexLevel2Score": 560
    }
];


export default mockedData;