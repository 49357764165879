import React,{useState, useContext, useEffect} from "react";
import { useParams } from "react-router-dom";
import "./index.css";
import SpecificLor from "./specificLor";
import { getLorByIdQuery } from './graphql/queries';
import { useQuery } from "@apollo/client"; 
import { ProgramIdContext } from '../../faculty/progIdContext';
import { UserContext } from '../../../context/userContext';
import Spinner from "../../common/spinner";


const DirectLor = () => {


    const [lor, setLor] = useState(null);
    const [lors, setLors] = useState([]);
    const { id } = useParams();
    const cleanId = id.split(":")[1];

 
    const { data, error: lorError, loading: lorLoading, refetch: refetchLors } = useQuery(getLorByIdQuery, {
        variables: {
            id: cleanId
        },
    });
    
    useEffect(() => {
        console.log("useEffect ran");
        console.log("Query Data:", data);
        console.log("Query Loading:", lorLoading);
        console.log("Query Error:", lorError);
    
        if (data) {
            console.log("Data exists, setting lor");
            console.log(data.getLorById)
            setLor(data.getLorById);
        }
    }, [data, lorLoading, lorError]); 
    



    if (!lor) return <Spinner />;
    if (lorError) return <p>Error loading the LoR</p>;



    return (
        <div className='faculty-home-container'>
            <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop2' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="faculty-home-header">
                <h2 className="faculty-home-header-title">RECOMMENDATIONS</h2>               
            </div>
            <seperator style={{marginTop:'3vh'}} className="faculty-home-header-seperator"/>
            <SpecificLor lor={lor}  refetchLors={refetchLors}/>
           
        </div>
    );
}

export default DirectLor;

