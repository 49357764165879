import React,{useState, useContext, useEffect} from "react";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import AddFieldModal from "./addFieldModal";
import RubricItem from "./rubricItem";
import "./index.css";
import { useMutation, useQuery } from '@apollo/client';
import {DELETE_RUBRIC_TEMPLATE} from '../graphql/mutations';
import { getRubricsAnsweredQuery } from '../graphql/queries';
import Spinner from "../../../../common/spinner";

const Rubric = ({programRubrics, program}) => {
    const programId = program.id;

    const [deleteRubricTemplate, { data: deleteData }] = useMutation(DELETE_RUBRIC_TEMPLATE, {
        onCompleted: (data) => {
            // Assuming the mutation returns the updated list of rubrics
            setRubrics(data.deleteRubricTemplate.rubricTemplate);
        }
    });


    const { data: rubricsData, error: robricsError, isLoading: robricsLoading } = useQuery(getRubricsAnsweredQuery, {
        variables: {
            programId
        },
    });



    const [isModalOpen, setIsModalOpen] = useState(false);
    const [rubrics, setRubrics] = useState(programRubrics);
    const [rubricsExists, setRubricsExists] = useState([]);
    const [rubricsNames, setRubricsNames] = useState([])

    useEffect(() => {
        if(programRubrics !== null && programRubrics !== undefined) {
            const names = programRubrics.reduce((acc, item) => {
                if (item.label) acc.push(item.label);
                return acc;
            }, []);
            console.log("names =>", names)
            setRubricsNames(names)
        }
    }, [programRubrics])



    if (robricsLoading) return <Spinner />;
    if (robricsError) return <p>Error :(</p>;
    else {
        console.log("rubricsData =>", rubricsData)
        if(rubricsData) {
            let rubricsFromQuery = rubricsData.getRubricsAnswered
            console.log("rubricsFromQuery =>", rubricsFromQuery)
            if(JSON.stringify(rubricsFromQuery) !== JSON.stringify(rubricsExists)) {
                setRubricsExists(rubricsFromQuery)
            }
        }
       
    }



    const addRubric = (newRubric) => {
        // newRubric._id = nextId++; // Assign unique ID to new rubric
        newRubric._id = 4; // Assign unique ID to new rubric
        console.log(newRubric)
        const updatedRubrics = [...rubrics, newRubric];
        setRubrics(updatedRubrics);
    };

    const updateRubric = (updatedRubric) => {
        const updatedRubrics = rubrics.map(rubric => {
            if (rubric.id === updatedRubric.id) {
                return updatedRubric;
            }
            return rubric;
        });
        setRubrics(updatedRubrics);
    }

    const deleteRubric = (_id) => {
        deleteRubricTemplate({ variables: { programId: programId, rubricTemplateId: _id } });

    }
   
    const moveRubric = (dragIndex, hoverIndex) => {
        const dragItem = rubrics[dragIndex];
        const newRubrics = [...rubrics];
        newRubrics.splice(dragIndex, 1);
        newRubrics.splice(hoverIndex, 0, dragItem);
    
        // Update the order based on the new index
        const updatedRubrics = newRubrics.map((rubric, index) => {
            return { ...rubric, order: index + 1 };
        });
        console.log(updatedRubrics);
    
        setRubrics(updatedRubrics);
    };


    const checkIfCanEdit = (rubric) => {
        let result = rubricsExists.includes(rubric.label)
        console.log("rubric =>", rubric, rubricsExists, result)
        return result
        // if (rubricsExists.length > 0) {
        //     return false;
        // }else {
        //     return true;
        // }
    }




 
    return (
        <div >
            <DndProvider backend={HTML5Backend}>
            <div className="faculty-home-body">

            {rubrics && rubrics.length > 0 && (
    [...rubrics].sort((a, b) => a.order - b.order).map((rubric) => (
        <RubricItem key={rubric._id} rubric={rubric} deleteRubric={deleteRubric} updateRubric={updateRubric} moveRubric={moveRubric} index={rubric.order-1} canEditDelete={checkIfCanEdit(rubric)} rubricsNames={rubricsNames} program={program}/>
    ))
)}

{rubrics && rubrics.length === 0 &&(
    <p>Your Evaluation Form Is Empty</p>
)}


            </div>
            <div className="faculty-home-card-footer" style={{textAlign:'center', display:'flex', justifyContent:'center'}}>
            {/* <button className={checkIfCanEdit?"green-button":"green-button disabled"} disabled={checkIfCanEdit} onClick={() => setIsModalOpen(true)}>Add New Field</button> */}
            <button className="green-button" onClick={() => setIsModalOpen(true)}>Add New Field</button>
            <AddFieldModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}  onAddRubric={addRubric} lastRubricOrder={rubrics.length===0?0:rubrics[rubrics.length - 1].order} program={program}/>
            


                </div>
                </DndProvider>
        </div>
    );
}

export default Rubric;

