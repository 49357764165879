import React,{useEffect, useState} from "react";
import "./styles/pdf.css";
import { Document, Page } from 'react-pdf';
import logo from "../../assets/imgs/logo.svg";
import signalStar from "../../assets/imgs/signalStar.svg"
import scoreIconGreen from '../../assets/imgs/scoreIconGreen.svg';
import greenCheck from '../../assets/imgs/greenCheck.svg';
import redX from '../../assets/imgs/redX.svg';
import genderIcon from '../../assets/imgs/genderIcon.svg';
import usCitIcon from '../../assets/imgs/usCitIcon.svg';
import couplesIcon from '../../assets/imgs/couplesIcon.svg';
import previousApplicant from '../../assets/imgs/previousApplicant.svg';
import misdemeanourIcon from '../../assets/imgs/misdemeanourIcon.svg';
import malpracticeIcon from '../../assets/imgs/malpracticeIcon.svg';
import languagesIcon from '../../assets/imgs/languagesIcon.svg';
import {genderDataValues} from '../faculty/facultyComponents/applicationView/sections/dataValuesConvert';
import ResearchTable from '../faculty/facultyComponents/applicationView/sections/researchTable';
import QATable from '../faculty/facultyComponents/applicationView/sections/qaTable';
import Spinner from '../common/spinner';  
import filledStarIcon from '../../assets/imgs/filledStarIcon.svg';
import starIcon from '../../assets/imgs/starIcon.svg';
import { getLorsByApplicationAndUserQuery,  getMspeByApplicantAndApplicationQuery, getTranscriptsByApplicantAndApplicationQuery} from '../faculty/facultyComponents/applicationView/graphql/queries';
import { useLazyQuery } from "@apollo/client";

function convertKeyToLabel(key) {
  switch(key) {
case 'clerkshipEm':
  return 'Emergency Medicine'
case 'clerkshipFm':
  return 'Family Medicine'
case 'im':
  return 'Internal Medicine'
case 'neurology':
  return 'Neurology'
case 'obgyn':
  return 'Obstetrics & Gynecology'
case 'pediatrics':
  return 'Pediatrics'
case 'psychiatry':
  return 'Psychiatry'
case 'surgery':
  return 'Surgery'
case '__typename':
  return
default:
  return key
  }
}


function formatAnswers(answers) {
  if (answers === null || answers === undefined || answers === "null") {
    return "";
  }
  switch (answers) {
  case "n1":
    return "Number 1";
  case "n2to5":
    return "2-5";
  case "n5to10":
    return "5-10";
  case "n10to20":
    return "10-20";
  case "wouldNotRank":
    return "would not rank";
  case "NA":
    return "Unable to evaluate this trait";
  case "advisor":
    return "Advisor";
  case "generalSurgeryResidencyProgramDirector":
    return "General Surgery Residency Program Director";
  case "rotatedOnMyService":
    return "The applicant has rotated on my service";
  case "plasticSurgeryRotation":
    return "On a plastic surgery rotation";
  case "differentRotation":
    return "On a different rotation";
  case "writtenPaperWithTheApplicant":
    return "I have a written paper with the applicant";
  case "performedResearch":
    return "The applicant has performed research";
  case "notWorkedDirectly":
    return "I have not worked directly with the applicant; based on others' evaluations";
  case "other":
    return "Other";
    default:
    return answers;

  }
}


const ExmPDF = ({ application }) => {
  const [researchExperienceState, setResearchExperienceState] = useState([]);
  const [workExperienceState, setWorkExperienceState] = useState([]);
  const [isComlexLevel1ScoreNumeric, setIsComlexLevel1ScoreNumeric] = useState(null);
  const [isUSMLEStep1ScoreNumeric, setIsUSMLEStep1ScoreNumeric] = useState(null);
  const [lorNumPages, setLorNumPages] = useState({});
  const [mspeNumPages, setMspeNumPages] = useState({});
  const [transcriptNumPages, setTranscriptNumPages] = useState({});
  const [lors, setLors] = useState([]);
  const [mspe, setMspe] = useState([]);
  const [transcript, setTranscript] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const [numPages, setNumPages] = useState(null);
const [allPagesFullyLoaded, setAllPagesFullyLoaded] = useState(false);


console.log('signalEssay =>', application.signalEssay)

const [pdfLoadStatus, setPdfLoadStatus] = useState({
  lorsLoaded: false,
  mspeLoaded: false,
  transcriptLoaded: false,
});

    const [loadedStatus, setLoadedStatus] = useState({
    lors: new Array(lors.length).fill(false),
    mspe: new Array(mspe.length).fill(false),
    transcript: new Array(transcript.length).fill(false),
  });
  const allDocumentsLoaded = Object.values(loadedStatus).every(statusArray => statusArray.every(status => status));


  const [getLors, { loading: lorLoading, data: lorData }] = useLazyQuery(getLorsByApplicationAndUserQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      
        console.log('Lor Extention!!!!',lorData.getLorsByApplicationAndUser[0].docUrl.split('.').pop() );
      
      setLors(lorData.getLorsByApplicationAndUser);
    },
    onError: (error) => {
      console.error("Error fetching LORs:", error);
      setIsLoading(false);
    }
  });

  const [getMspe, { loading: mspeLoading, data: mspeData }] = useLazyQuery(getMspeByApplicantAndApplicationQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setMspe(mspeData.getMspeByApplicantAndApplication);
    },
    onError: (error) => {
      console.error("Error fetching MSPE:", error);
      setIsLoading(false);
    }
  });

  const [getTranscript, { loading: transcriptLoading, data: transcriptData }] = useLazyQuery(getTranscriptsByApplicantAndApplicationQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setTranscript(transcriptData.getTranscriptsByApplicantAndApplication);
    },
    onError: (error) => {
      console.error("Error fetching Transcript:", error);
      setIsLoading(false);
    }
  });


  useEffect(() => {
    const allLoaded = pdfLoadStatus.lorsLoaded && pdfLoadStatus.mspeLoaded && pdfLoadStatus.transcriptLoaded;
    setAllPagesFullyLoaded(allLoaded);
  }, [pdfLoadStatus]);

  useEffect(() => {
    if (allDocumentsLoaded) {
      setAllPagesFullyLoaded(true);
    }
  }, [allDocumentsLoaded]);

  useEffect(() => {
    console.log("application =>", application)
    if (application && application.applicantId && application.masterApplicationId) {
      getLors({
        variables: { applicationId: application.masterApplicationId, applicantId: application.applicantId }
      });
      getMspe({
        variables: { applicationId: application.masterApplicationId, applicantId: application.applicantId }
      });
      getTranscript({
        variables: { applicationId: application.masterApplicationId, applicantId: application.applicantId }
      });
    }
  }, [application]);


  useEffect(() => {
    if (application.researchExperience) {
      const researchToUpdate = [...application.researchExperience];
      const sortedResearchData = researchToUpdate.sort((a, b) => {
        if (a.significantResearchExperience === b.significantResearchExperience) {
            return a.isFirstAuthor ? -1 : 1;
        }
        return a.significantResearchExperience ? -1 : 1;
    }
    );
    setResearchExperienceState(sortedResearchData);
    }
  }, [application.researchExperience]);


  useEffect(() => {
    if (application.workExperiences) {
      const workExperienceToUpdate = [...application.workExperiences];
const sortedWorkExperiences = workExperienceToUpdate.sort((a, b) => {
  const aDate = a.startDate.split('/')
  const bDate = b.startDate.split('/')
  if (aDate[2] === bDate[2]) {
      if (aDate[0] === bDate[0]) {
          return aDate[1] - bDate[1]
      }
      return aDate[0] - bDate[0]
  }
  return aDate[2] - bDate[2]
})
setWorkExperienceState(sortedWorkExperiences);
    }
  }, [application.workExperiences]);
  

useEffect(() => {
  if (application.comlexLevel1Score) {
    const isComlexLevel1ScoreNumeric = !isNaN(parseFloat(application.comlexLevel1Score)) && isFinite(application.comlexLevel1Score);
    setIsComlexLevel1ScoreNumeric(isComlexLevel1ScoreNumeric);
  }
}, [application.comlexLevel1Score]);

useEffect(() => {
  if (application.usmleStep1Score) {
    const isUSMLEStep1ScoreNumeric = !isNaN(parseFloat(application.usmleStep1Score)) && isFinite(application.usmleStep1Score);
    setIsUSMLEStep1ScoreNumeric(isUSMLEStep1ScoreNumeric);
  }
}, [application.usmleStep1Score]);


  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
};

const genderItem = genderDataValues.find(item => item.value === application.gender);
const genderLabel = genderItem ? genderItem.label : application.gender;
const yesDisplay = <div className='yes-display' style={{display: 'flex', justifyContent: 'space-between', width: '60px', fontSize: '16px'}}><img  style={{height:'1.5vh', padding:'.4vh'}} className="img-yes" src={greenCheck} alt="Yes" />YES</div> ;
const passDisplay = <div className='yes-display' style={{display: 'flex', justifyContent: 'space-between', width: '60px', fontSize: '16px'}}><img  style={{height:'1.5vh', padding:'.4vh'}} className="img-yes" src={greenCheck} alt="Yes"/>Pass</div> ;
const failDisplay = <div className='no-display' style={{ display: 'flex', justifyContent: 'space-between', width: '60px', fontSize: '16px'}}><img  style={{height:'1.5vh'}} className="img-no" src={redX} alt="No" />Fail</div> ;
const noDisplay = <div className='no-display' style={{ display: 'flex', justifyContent: 'space-between', width: '60px', fontSize: '16px'}}><img  style={{height:'1.5vh'}} className="img-no" src={redX} alt="No" />NO</div> ;
const noDisplayAdv = (aoaStatus) => {
  switch(aoaStatus) {
      case 'no':
          return <div className='no-display' style={{textAlign:'right', width:'max-content', fontSize: '15px'}}><img className="img-no" src={redX} alt="No" style={{ width: '20px' , height: '20px',  marginRight: '10px'}}/>NOT INDUCTED</div>
      case 'notYet':
          return <div className='no-display' style={{textAlign:'right', width:'max-content', fontSize: '15px'}}><img className="img-no" src={redX} alt="No" style={{ width: '20px' , height: '20px',  marginRight: '10px'}}/>Inductions have not yet taken place at my school</div>
      case 'noChapter':
          return <div className='no-display' style={{width:'max-content', textAlign:'right', fontSize: '15px'}}><img className="img-no" src={redX} alt="No" style={{ width: '20px' , height: '20px', marginRight: '10px'}}/>My school does not have a chapter</div>
      default:
          return <div className='no-display' style={{textAlign:'right', marginLeft:'5vw', width:'max-content', fontSize: '15px'}}><img className="img-no" src={redX} alt="No"  style={{ width: '20px', height: '20px',  marginRight: '10px'}}/>NOT INDUCTED</div>
  }
  }
let higherEducationObj = application.user.applicationInformation.higherEducation?  [...application.user.applicationInformation.higherEducation] : []
const sortedHigherEducation = higherEducationObj.sort((a, b) => {
    const aDate = parseInt(a.date.split('/'))
    const bDate = parseInt(b.date.split('/'))
    console.log("aDate =>", aDate)
    console.log("bDate =>", bDate)
    if (aDate[2] === bDate[2]) {
        if (aDate[0] === bDate[0]) {
            return aDate[1] - bDate[1]
        }
        return aDate[0] - bDate[0]
    }
    return aDate[2] - bDate[2]
}
)

let coreClerkshipGradesToRender = []
console.log(application.applicationInformation)
if (
  application &&
  application.user &&
  application.user.applicationInformation &&
  application.user.applicationInformation.coreClerkshipGrades
) {
  const coreClerkshipGrades = application.user.applicationInformation.coreClerkshipGrades;
  if (coreClerkshipGrades && typeof coreClerkshipGrades === 'object' && coreClerkshipGrades !== null) {
    coreClerkshipGradesToRender = Object.keys(coreClerkshipGrades).map((key, index) => {
        if (coreClerkshipGrades[key].toLowerCase() !== 'coreclerkshipgrades') {
      return (
          <div className="application-view-main-education-core-clerkship-grades" style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom: '7px'}}>
                  <h2 className="clerkship-label">{convertKeyToLabel(key)}</h2>
                  <h2 className="clerkship-value-label">{coreClerkshipGrades[key]==='gradedDiff'?'graded differently'.toUpperCase():coreClerkshipGrades[key].toUpperCase()}</h2>
          </div>
        
      );
        }
    });
  }
}

const nonCoreClerkshipGradesToRender = application.user.applicationInformation.nonCoreClerkshipGrades && application.user.applicationInformation.nonCoreClerkshipGrades.map((grade, index) => {
  return (
      <div className="application-view-main-education-core-clerkship-grades" style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom: '7px'}}>
              <h2 className="clerkship-label">{grade.course}</h2>
              <h2 className="clerkship-value-label">{grade.grade}</h2>
      </div>
  )
})
const awayRotationsToRender = application.user.applicationInformation.awayRotations && application.user.applicationInformation.awayRotations.map((rotation, index) => {
  return (
      <div className="application-view-main-education-core-clerkship-grades" style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom: '7px', width:'100%'}}>
              <h2 className="clerkship-label" style={{width:'17vw'}}>{rotation.institution}</h2>
              <h2 className="clerkship-value-label" style={{width:'4vw'}}>{rotation.degree}</h2>
              <h2 className="clerkship-value-label" style={{width:'4vw'}}>{rotation.dateCompleted}</h2>
      </div>
  )
})




const shortQAData = application.shortAnswerQuestions.map((elem, index) => {
  return {
      question: elem.question,
      answer: elem.answer,
  };
  });

application.shortAnswerQuestions = shortQAData;

const initials = application.firstName.charAt(0) + application.lastName.charAt(0)
const profilePicUrl =`https://ui-avatars.com/api/?name=${initials}`





function onLorDocumentLoadSuccess(index, { numPages }) {
  setLorNumPages(prevState => ({ ...prevState, [index]: numPages }));
  // Update the specific LOR load status
  setPdfLoadStatus(prevStatus => ({ ...prevStatus, lorsLoaded: true }));
}

function onDocumentLoadSuccess({ numPages }) {
  setNumPages(numPages);
}
function onMSPEDocumentLoadSuccess(index, { numPages }) {
  console.log("index =>", index, numPages);
  setMspeNumPages(prevState => ({ ...prevState, [index]: numPages }));
  setLoadedStatus(prevStatus => ({
    ...prevStatus,
    mspe: prevStatus.mspe.map((status, i) => i === index ? true : status)
  }));
}
function onTranscriptDocumentLoadSuccess(index, { numPages }) {
  setTranscriptNumPages(prevState => ({ ...prevState, [index]: numPages }));
  setLoadedStatus(prevStatus => ({
    ...prevStatus,
    transcript: prevStatus.transcript.map((status, i) => i === index ? true : status)
  }));
}



  const OfficeViewer = ({ docUrl }) => {
    const officeUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(docUrl)}`;
    return <iframe src={officeUrl} width="100%" height="1200px" frameBorder="0"></iframe>;
  };

  


  return (

    <div>
      <div className='ellipseDesignTop1 ellipse' />
<div className='ellipseDesignTop2 ellipse' />
<div className='ellipseDesignTop3 ellipse' />
<div className='ellipseDesignTop4 ellipse' />
      <div className="a4-container page">
        <div className="a4-header-cont" >
          <div className="pp-cont">
            <img
              className="a4-profile-pic"
              src={application.user.profilePhotoUrl ? application.user.profilePhotoUrl : profilePicUrl}
              alt="profile-pic"
            />
          </div>
          <div className="a4-header">
            <h1 style={{ fontWeight: '500', textTransform: 'uppercase'}}>{application.user.firstName} {application.user.lastName}</h1>
            <p style={{ fontWeight: '200', marginBottom: '10px', marginTop: '20px'}}>{application.user.email}</p>
            <p style={{ fontWeight: '200', marginBottom: '10px'}}>{application.user.phone}</p>
            <p style={{ fontWeight: '200', marginBottom: '10px'}}>{application.user.address}</p>
            <p style={{ fontWeight: '200', marginBottom: '10px'}}>{application.medicalSchoolName}</p>
            <p style={{ fontWeight: '200', marginBottom: '10px'}}>{application.address}</p>
            <p style={{ fontWeight: '200', marginBottom: '10px'}}>{application.user.applicationInformation.dateOfBirth}</p>
          </div> 
          <div className="a4-signal">
              { application.isSignal === 'Yes'&& (
                  <>
                    <img style={{borderRadius:'0', width: '6vw', marginBottom: '4vh'}} src={logo} alt="logo" />
                    <div className="application-view-main-header-signal" style={{ display: 'flex', alignItems: 'center', height: '30px'}}>
                      <img style={{ width: "30px" }} src={signalStar} alt=""/>
                      <label style={{ fontWeight: '500', fontSize:'2vh'}}>SIGNALED</label>
                    </div>
                  </>
              )}

          </div>
        </div>
        {/* Demographics */}
        <div className="demo-container">
          <label style={{ fontWeight: '600', fontSize: '22px'}}>DEMOGRAPHICS</label>
          <seperator className="faculty-home-header-seperator"/>
          <div className="demo-data-container" style={{display:'flex',flexDirection:'row',gap:'5vw', padding:'0 10vw'}}>
            <div className="demo-left" style={{width:'33vw'}}>
              <div className="demographics-item" style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', gap:'2vw'}}>
              <div style={{display: 'flex', width:'26vw'}}>
                <img src={genderIcon} alt="Icon" style={{ width: '20px', height: '20px', }} />
                <div className="demographics-label" style={{ fontSize: '15px'}}>Gender:</div>
                </div>
                <div className="demographics-value" style={{fontSize: '15px', textAlign: 'left', width: '180px'}}>{genderLabel}</div>
              </div>
              <div className="demographics-item" style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
              <div style={{display: 'flex', width:'26vw'}}>
                <img src={genderIcon} alt="Icon" style={{ width: '20px', height: '20px'}} />
                <div className="demographics-label" style={{ fontSize: '15px', marginLeft: '10px'}}>Ethnicity:</div>
              </div>
              <div className="demographics-value" style={{fontSize: '15px', textAlign: 'left', width: '180px'}}>{application.ethnicity.join(', ')}</div>
            </div>
            <div className="demographics-item" style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{display: 'flex', width:'26vw'}}>
              <img src={usCitIcon} alt="Icon" style={{ width: '20px', height: '20px'}} />
              <div className="demographics-label" style={{ fontSize: '15px', marginLeft: '10px'}}>US Citizen:</div>
            </div>
            <div className="demographics-value" style={{fontSize: '15px', textAlign: 'left', width: '180px'}}>{application.workAuthorization === 'Yes' ? yesDisplay : noDisplay}</div>
          </div>
          
       
          <div className="demographics-item" style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
          <div style={{display: 'flex', width:'26vw'}}>
            <img src={couplesIcon} alt="Icon" style={{ width: '20px', height: '20px'}} />
            <div className="demographics-label" style={{ fontSize: '15px', marginLeft: '10px'}}>Couple's Match:</div>
        </div>
            <div className="demographics-value" style={{fontSize: '15px', textAlign: 'left', width: '180px'}}>{application.coupleMatch==='Yes' ? yesDisplay : noDisplay}</div>
        </div>
        {application.coupleMatch==='Yes' && (
    <>
        <div className="demographics-item" style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', gap:'2vw'}}>
              <div style={{display: 'flex', width:'26vw'}}>
            <img src={couplesIcon} alt="Icon" width="20" />
            <div className="demographics-label" style={{ fontSize: '15px', marginLeft: '10px'}}>Partner's Field:</div>
            </div>
            <div className="demographics-value" style={{fontSize: '15px', textAlign: 'left', width: '180px'}}>{application.partnerField}</div>
        </div>
    
 
        <div className="demographics-item" style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', gap:'2vw'}}>
              <div style={{display: 'flex', width:'26vw'}}>
            <img src={couplesIcon} alt="Icon" width="20" />
            <div className="demographics-label" style={{ fontSize: '15px', marginLeft: '10px'}}>Partner's Name:</div>
            </div>
            <div className="demographics-value" style={{fontSize: '15px', textAlign: 'left', width: '180px'}}>{application.partnerName}</div>
        </div>
        </>
        )}
            <div className="demographics-item" style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{display: 'flex', width:'26vw'}}>
            <img src={previousApplicant} alt="Icon" style={{ width: '20px', height: '20px'}} />
            <div className="demographics-label" style={{ fontSize: '15px', marginLeft: '10px'}}>Previous Applicant:</div>
          </div>
          <div className="demographics-value" style={{fontSize: '15px', textAlign: 'left', width: '180px'}}>{application.previouslyAppliedToSpecialty === 'Yes' ? yesDisplay : noDisplay}</div>
        </div>

        <div className="demographics-item" style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{display: 'flex', width:'26vw'}}>
            <img src={scoreIconGreen} alt="Icon" style={{ width: '20px', height: '20px'}} />
            <div className="demographics-label" style={{ fontSize: '15px', marginLeft: '10px'}}>AAMC ID:</div>
          </div>
          <div className="demographics-value" style={{fontSize: '15px', textAlign: 'left', width: '180px'}}>{application.aamc}</div>
        </div>
        <div className="demographics-item" style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{display: 'flex', width:'26vw'}}>
            <img src={scoreIconGreen} alt="Icon" style={{ width: '20px', height: '20px'}} />
            <div className="demographics-label" style={{ fontSize: '15px', marginLeft: '10px'}}>Preferred Name:</div>
          </div>
          <div className="demographics-value" style={{fontSize: '15px', textAlign: 'left', width: '180px'}}>{application.prefferedName}</div>
        </div>

        <div className="demographics-item" style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{display: 'flex', width:'26vw'}}>
            <img src={scoreIconGreen} alt="Icon" style={{ width: '20px', height: '20px'}} />
            <div className="demographics-label" style={{ fontSize: '15px', marginLeft: '10px'}}>USMLE ID:</div>
          </div>
          <div className="demographics-value" style={{fontSize: '15px', textAlign: 'left', width: '180px'}}>{application.usmleId}</div>
        </div>
        <div className="demographics-item" style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{display: 'flex', width:'26vw'}}>
            <img src={scoreIconGreen} alt="Icon" style={{ width: '20px', height: '20px'}} />
            <div className="demographics-label" style={{ fontSize: '15px', marginLeft: '10px'}}>Other Service Obligations:</div>
          </div>
          <div className="demographics-value" style={{fontSize: '15px', textAlign: 'left', width: '180px'}}>{application.otherServiceObligations}</div>
        </div>






            </div>
            <div className="demo-right" style={{width:'33vw'}}>
                <div className="demographics-item" style={{ display: 'flex', width: '370px', justifyContent: 'space-between', alignItems: 'center'}}>
                    <img src={scoreIconGreen} alt="Icon" style={{ width: '20px', height: '20px'}} />
                    <div className="demographics-label" style={{ fontSize: '15px', width: '650px'}}>Ability to carry out responsibilities:</div>
                    <div className="demographics-value" style={{ width: '180px'}}>{!application.carryingOutResponsibilities ? yesDisplay: noDisplay}</div>
                </div>
                <div className="demographics-item" style={{ display: 'flex', width: '370px', justifyContent: 'space-between', alignItems: 'center'}}>
                    <img src={misdemeanourIcon} alt="Icon" style={{ width: '20px', height: '20px'}} />
                    <div className="demographics-label" style={{ fontSize: '15px', width: '650px'}}>Misdemeanor or Felony:</div>
                    <div className="demographics-value" style={{ width: '180px'}}>{application.misdemeanorOrFelony ? yesDisplay: noDisplay}</div>
                </div>
                <div className="demographics-item" style={{ display: 'flex', width: '370px', justifyContent: 'space-between', alignItems: 'center'}}>
                    <img src={misdemeanourIcon} alt="Icon" style={{ width: '20px', height: '20px'}} />
                    <div className="demographics-label" style={{ fontSize: '15px', width: '650px'}}>Hometown:</div>
                    <div className="demographics-value" style={{fontSize: '15px', textAlign: 'left', width: '180px'}}>{application.hometown}</div>
                </div>
                <div className="demographics-item" style={{ display: 'flex', width: '370px', justifyContent: 'space-between', alignItems: 'center'}}>
                    <img src={misdemeanourIcon} alt="Icon" style={{ width: '20px', height: '20px'}} />
                    <div className="demographics-label" style={{ fontSize: '15px', width: '650px'}}>NRMP ID:</div>
                    <div className="demographics-value" style={{fontSize: '15px', textAlign: 'left', width: '180px'}}>{application.nrmpId}</div>
                </div>
                <div className="demographics-item" style={{ display: 'flex', width: '370px', justifyContent: 'space-between', alignItems: 'center'}}>
                    <img src={misdemeanourIcon} alt="Icon" style={{ width: '20px', height: '20px'}} />
                    <div className="demographics-label" style={{ fontSize: '15px', width: '650px'}}>Pronouns::</div>
                    <div className="demographics-value" style={{fontSize: '15px', textAlign: 'left', width: '180px'}}>{application.pronouns}</div>
                </div>
                <div className="demographics-item" style={{ display: 'flex', width: '370px', justifyContent: 'space-between', alignItems: 'center'}}>
                    <img src={malpracticeIcon} alt="Icon" style={{ width: '20px', height: '20px'}} />
                    <div className="demographics-label" style={{ fontSize: '15px', width: '650px'}}>Malpractice Lawsuit:</div>
                    <div className="demographics-value" style={{ width: '180px'}}>{application.user.malpractice ? yesDisplay: noDisplay}</div>
                </div>
                <div className="demographics-item" style={{ display: 'flex', width: '370px', justifyContent: 'space-between', alignItems: 'center'}}>
                    <img src={languagesIcon} alt="Icon" style={{ width: '20px', height: '20px'}} />
                    <div className="demographics-label" style={{ fontSize: '15px', width: '650px'}}>Fluent in additional languages:</div>
                    <div className="demographics-value" style={{ width: '180px'}}>{application.isLanguages ? yesDisplay: noDisplay}</div>
                </div>
            </div>
          </div>
        </div>

        {/* Education */}
        <div className="demo-container">
          <label style={{ fontWeight: '600', fontSize: '22px'}}>EDUCATION</label>
          <seperator className="faculty-home-header-seperator" style={{margin:'1vh 0'}}/>

          {
            sortedHigherEducation && sortedHigherEducation.map((degree, index) => {
              return (
                  <div className="application-view-main-education-degrees-section" style={{display:'flex', flexDirection:'row', margin:'0 6vw', padding:'2vh 0'}}>
                      <div>
                  <div className="date" style={{marginLeft:'3vw'}}>{degree.date}
                      </div>
                      <div className="ball"/>
                      </div>
                      <div className="institution-and-degree">
                      <div className="institution" style={{marginLeft:'1vw'}}>{degree.institution}</div>
                      <div className="degree" style={{marginLeft:'1vw'}}>{degree.degree}</div>
                      </div>
          
                  </div>
              )
              })
          }

          <div className="educ-cont">
            <div className="left-educ">
              <h2 className="application-view-sub-title">Clerkship Grades</h2>
              {coreClerkshipGradesToRender}
            </div>
            <div className="right-educ">
                  <h2 className="application-view-sub-title">Additional Course Work</h2>
                  <seperator className="application-view-main-demographics-seperator"/>
                  {nonCoreClerkshipGradesToRender}
            </div>
          </div>
          <div className="educ-cont">
            <div className="left-educ">
              <h2 className="application-view-sub-title">Standard Scores</h2>
              <div className="application-view-main-education-core-clerkship-grades" style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom: '7px'}}>
                  <h2 className="clerkship-label">USMLE Step 1</h2>
                  {application.usmleStep1Score &&(
                  <h2 className="clerkship-value-label"> {application.usmleStep1Score.toLowerCase() === "pass"  ? passDisplay:application.usmleStep1Score.toLowerCase() === "fail"?failDisplay:application.usmleStep1Score}</h2>
                  )}
              </div>
              
              <div className="application-view-main-education-core-clerkship-grades" style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom: '7px'}}>
                <h2 className="clerkship-label">USMLE Step 2 CK</h2>
                <h2 className="clerkship-value-label">{application.usmleStep2Score}</h2>
              </div>
              <div className="application-view-main-education-core-clerkship-grades" style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom: '7px'}}>
                <h2 className="clerkship-label">USMLE Step 3</h2>
                <h2 className="clerkship-value-label">{application.usmleStep3Score}</h2>
              </div>
              <div className="application-view-main-education-core-clerkship-grades" style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom: '7px'}}>
                <h2 className="clerkship-label">COMLEX-USA Level 1</h2>
                <h2 className="clerkship-value-label"> {application.comlexLevel1Score===null?'':isComlexLevel1ScoreNumeric? application.comlexLevel1Score: application.comlexLevel1Score === "Pass"  ? passDisplay : failDisplay }</h2>
              </div>
              <div className="application-view-main-education-core-clerkship-grades" style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom: '7px'}}>
                <h2 className="clerkship-label">COMLEX-USA Level 2 CE</h2>
                <h2 className="clerkship-value-label">{application.comlexLevel2Score}</h2>
              </div>

            </div>
            <div className="right-educ">
              <h2 className="application-view-sub-title">Away Rotations</h2>
              <seperator className="application-view-main-demographics-seperator"/>
              {awayRotationsToRender}
            </div>
          </div>


          
        </div>
      {/* </div>
      <div className="a4-container page"> */}

        {/* HONORS */}
        <div className="demo-container">
          <label style={{ fontWeight: '600', fontSize: '22px'}}>HONORS AND AWARDS</label>
          <seperator className="faculty-home-header-seperator" style={{margin:'1vh 0'}}/>
          <div className="educ-cont">
            <div className="left-educ" style={{margin:'0', width:'33vw'}}>
              <div className="application-view-main-education-core-clerkship-grades" style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom: '7px'}}>
                <h2 className="clerkship-label">AOS</h2>
                <h2 className="clerkship-value-label">{application.aoaStatus==='Inducted' ? yesDisplay : noDisplayAdv(application.aoaStatus)}</h2>
              </div>
            </div>
            <div className="right-educ" style={{margin:'0', width:'33vw'}}>
              <div className="application-view-main-education-core-clerkship-grades" style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom: '7px'}}>
                <h2 className="clerkship-label">GHHS</h2>
                <h2 className="clerkship-value-label">{application.goldHumanism==='Inducted' ? yesDisplay : noDisplayAdv(application.goldHumanism)}</h2>
              </div>
            </div>
          </div>
          <div className="honors-and-awards" style={{textAlign:'justify', marginTop:"1vh", padding:'0 5vw'}}>{application.user.applicationInformation.awardsAndHonors}</div>

        </div>

        {/* RESERACH */}
        <div className="demo-container">
          <label style={{ fontWeight: '600', fontSize: '22px'}}>RESEARCH</label>
          <seperator className="faculty-home-header-seperator" style={{margin:'1vh 0'}}/>
          <div className="educ-cont" style={{marginTop:'2vh'}}>
<div className="edu-left">
        <div className="demographics-item" style={{width:'40vw'}}>
            <img src={scoreIconGreen} alt="Icon" width="20" />
            <div className="demographics-label" style={{ fontSize: '15px', width: '650px'}}>Number of first author publications:</div>
            <div className="demographics-value" style={{fontSize: '15px', textAlign: 'left', width: '180px'}}>{application.user.applicationInformation.firstAuthorPublications}</div>
        </div>

  

        <div className="demographics-item" style={{width:'40vw'}}>
            <img src={scoreIconGreen} alt="Icon" width="20" />
            <div className="demographics-label" style={{ fontSize: '15px', width: '650px'}}>Number of total peer-reviewed publications:</div>
            <div className="demographics-value" style={{fontSize: '15px', textAlign: 'left', width: '180px'}}>{application.user.applicationInformation.peerReviewedPublications}</div>
        </div>

</div>

<div className="edu-right">
<div className="demographics-item" style={{width:'40vw'}}>
            <img src={scoreIconGreen} alt="Icon" width="20" />
            <div className="demographics-label" style={{ fontSize: '15px', width: '650px'}}>Number of podium presentations:</div>
            <div className="demographics-value" style={{fontSize: '15px', textAlign: 'left', width: '180px'}}>{application.user.applicationInformation.podiumPresentations}</div>
        </div>



        <div className="demographics-item" style={{width:'40vw'}}>
            <img src={scoreIconGreen} alt="Icon" width="20" />
            <div className="demographics-label" style={{ fontSize: '15px', width: '650px'}}>Number of poster presentations:</div>
            <div className="demographics-value" style={{fontSize: '15px', textAlign: 'left', width: '180px'}}>{application.user.applicationInformation.posterPresentations}</div>
        </div>
</div>





    </div>
    <seperator className="faculty-home-header-seperator" style={{margin:'1vh 0'}}/>
    {researchExperienceState.map((researchExperience, index) => (
       <div className='application-form-field-group two' style={{flexDirection:'row', gap:'2vw', padding:'1vh 4vw'}}>
       {(researchExperience.category === 'Publication' || researchExperience.category === 'Abstract') && (
         <>
         <div style={{display:'flex', width:'100%'}}>
           <img src={researchExperience.significantResearchExperience ? filledStarIcon: starIcon}  alt='star' style={{width:'20px', height:'20px', alignSelf:'center', marginRight:'1vw'}}/>
           <p style={{
             fontFamily: 'Montserrat',
             fontStyle: 'normal',
             fontWeight: 400,
             fontSize: '18px',
             color: 'rgb(71, 71, 71)',
             lineHeight: '27px',
             // marginBottom: '12px',
             textAlign: 'justify',
             width:'100',
             alignSelf:'center'          
           }}>
             <strong style={{color:'#145388'}}>{researchExperience.category}:</strong> {researchExperience.title}, {researchExperience.authors}, {researchExperience.journal}, {researchExperience.datePublished}, PubMed ID: {researchExperience.pubMedId}, First Author: {researchExperience.isFirstAuthor ? 'Yes' : 'No'}
           </p>
           </div>
         </>
       )}
   
   {(researchExperience.category === 'Book Chapter' ) && (
         <>
         <div style={{display:'flex', width:'100%'}}>
           <img src={researchExperience.significantResearchExperience ? filledStarIcon: starIcon}  alt='star' style={{width:'20px', height:'20px', alignSelf:'center', marginRight:'1vw'}}/>
           <p style={{
             fontFamily: 'Montserrat',
             fontStyle: 'normal',
             fontWeight: 400,
             fontSize: '18px',
             color: 'rgb(71, 71, 71)',
             lineHeight: '27px',
             // marginBottom: '12px',
             textAlign: 'justify',
             width:'100',
             alignSelf:'center'          
           }}>
             <strong style={{color:'#145388'}}>{researchExperience.category}:</strong> {researchExperience.title}, {researchExperience.authors}, {researchExperience.journal}, {researchExperience.datePublished}, First Author: {researchExperience.isFirstAuthor ? 'Yes' : 'No'}
           </p>
           </div>
         </>
       )}
   
   
   {(researchExperience.category === 'Podium Presentation' || researchExperience.category === 'Poster Presentation') && (
         <>
        <div style={{display:'flex', width:'100%'}}>
        <img src={researchExperience.significantResearchExperience ? filledStarIcon: starIcon} alt='star' style={{width:'20px', height:'20px', alignSelf:'center', marginRight:'1vw'}}/>
           <p style={{
             fontFamily: 'Montserrat',
             fontStyle: 'normal',
             fontWeight: 400,
             fontSize: '18px',
             color: 'rgb(71, 71, 71)',
             lineHeight: '27px',
             // marginBottom: '12px',
             textAlign: 'justify',
             width:'100',
             alignSelf:'center'          
           }}>
             <strong style={{color:'#145388'}}>{researchExperience.category}:</strong> {researchExperience.title}, {researchExperience.authors}, {researchExperience.meeting}, {researchExperience.meetingDate},  Presenter: {researchExperience.isFirstAuthor ? 'Yes' : 'No'}
           </p>
           </div>
         </>
       )}
   
   
       </div>
       ))}

        </div>


        {/* WORK EXPERIENCE */}
        <div className="demo-container">
          <label style={{ fontWeight: '600', fontSize: '22px'}}>WORK EXPERIENCE</label>
          <seperator className="faculty-home-header-seperator" style={{margin:'1vh 0'}}/>
          {workExperienceState.map((workExp, index) => {
            return (
                <div className="application-view-main-education-degrees-section" style={{display:'flex', flexDirection:'row', zIndex: 9, margin:'0 6vw', padding:'2vh 0'}}>
                    <div>
                        <div className="date" style={{marginLeft:'30px', width:'17vw', fontSize: '15px'}}>{workExp.startDate} - {workExp.endDate}</div>
                        <div className="ball" style={{ left: '0px', zIndex: '999', top: 0}}/>
                    </div>
                    <div className="institution-and-degree" style={{marginBottom:'2vh', textAlign:'justify'}}>
                        <div className="institution" style={{marginLeft:'1vw'}}>{workExp.title}</div>
                        <div className="degree" style={{marginLeft:'1vw', fontWeight:'500'}}>{workExp.employer}</div>
                        <div className="degree" style={{marginLeft:'1vw'}}>Mentor: {workExp.mentorName},  {workExp.mentorEmail}</div>
                        <div className="degree" style={{marginLeft:'1vw'}}>{workExp.description}</div>
                    </div>
                </div>
            )
        })}
          

        </div>
      {/* </div>

      <div className="a4-container page"> */}
        <div className="demo-container">
          <label style={{ fontWeight: '600', fontSize: '22px'}}>SIGNIFICANT EXPERIENCES</label>
          <seperator className="faculty-home-header-seperator" style={{margin:'1vh 0'}}/>
          {application.meaningfulExperiences.map((segExp, index) => {
            return (
                <div className="application-view-main-education-degrees-section" style={{display:'flex', flexDirection:'row', margin:'0 6vw', padding:'2vh 0'}}>
                    <div>
                      <div className="ball" style={{ left: '0px', zIndex: '999', top: 0}}/>
                    </div>
                    <div className="institution-and-degree" style={{marginBottom:'2vh', textAlign:'justify'}}>
                        <div className="institution" style={{marginLeft:'1vw'}}>{segExp.category}</div>
                        <div className="degree" style={{marginLeft:'1vw'}}>{segExp.description}</div>
                    </div>
                </div>
            )
        })}
        
        </div>
      {/* </div>
      <div className="a4-container page"> */}
        <div className="demo-container">
          <label style={{ fontWeight: '600', fontSize: '22px'}}>SHORT ANSWER QUESTIONS</label>
          <seperator className="faculty-home-header-seperator" style={{margin:'1vh 0'}}/>
          <div style={{padding:'0 6vw'}}>
          <QATable data={application.shortAnswerQuestions}/>
          </div>
        </div>


        <div className="demo-container">
          <label style={{ fontWeight: '600', fontSize: '22px'}}>PERSONAL STATEMENT</label>
          <seperator className="faculty-home-header-seperator" style={{margin:'1vh 0'}}/>
          <p style={{ textAlign: "justify", whiteSpace: "pre-wrap", padding: '0 6vw'}}>
            {application.personalStatement}
          </p>
        </div>

        {application.isSignal && application.isSignal === "Yes" && (
        <div className="demo-container">
          <label style={{ fontWeight: '600', fontSize: '22px'}}>SIGNAL STATEMENT</label>
          <seperator className="faculty-home-header-seperator" style={{margin:'1vh 0'}}/>
          <p style={{ textAlign: "justify", whiteSpace: "pre-wrap", padding: '0 6vw'}}>
            {application.signalEssay}
          </p>
        </div>
        )}



        <div className="application-view-main-demographics">

        {lors.map((lor, index) => 
  lor.isSlor ? (
          <div className="application-view-main-lor-slor" style={{display:'flex', flexDirection:'column', marginBottom:'3vh'}}>
              <p style={{fontWeight:'500', textAlign:'center', textDecoration:'underline'}}> {lor.recommenderName}'s Standardized Letter of Recommendation</p>
              
              <div style={{display:'flex',flexDirection:'column'}}>
                <div className="slor-item-present">
  <label style={{width:"36vw"}} >Recommender Email:</label>
  <p style={{width:"54vw"}}>{formatAnswers(lor.slor.email)}</p>
</div>
<div className="slor-item-present">
  <label style={{width:"36vw"}}>Recommender Phone:</label>
  <p style={{width:"54vw"}}>{formatAnswers(lor.slor.phoneNumber)}</p>
</div>
<div className="slor-item-present">
  <label style={{width:"36vw"}}>How long have you known the applicant?</label>
  <p style={{width:"54vw"}}>{formatAnswers(lor.slor.howLongKnownTheApplicant)}</p>
</div>
<div className="slor-item-present">
  <label style={{width:"36vw"}}>Improvement aspect:</label>
  <p style={{width:"54vw"}}>{formatAnswers(lor.slor.improvementAspect)}</p>
</div>
<div className="slor-item-present">
  <label style={{width:"36vw"}}>Nature of contact:</label>
  <p style={{width:"54vw"}}>{formatAnswers(lor.slor.natureOfContact)}</p>
</div>
<div className="slor-item-present">
  <label style={{width:"36vw"}}>Present position:</label>
  <p style={{width:"54vw"}}>{formatAnswers(lor.slor.presentPosition)}</p>
</div>
<div className="slor-item-present">
  <label style={{width:"36vw"}}>Academic skills:</label>
  <p style={{width:"54vw"}}>{formatAnswers(lor.slor.radioAcademicSkills) === '0' ? 'N/A' : `${formatAnswers(lor.slor.radioAcademicSkills) * 10}th`}</p>
</div>
<div className="slor-item-present">
  <label style={{width:"36vw"}}>Communication skills:</label>
  <p style={{width:"54vw"}}>{formatAnswers(lor.slor.radioCommunicationSkills) === 0 ? 'N/A' : `${formatAnswers(lor.slor.radioCommunicationSkills) * 10}th`}</p>
</div>
<div className="slor-item-present">
  <label style={{width:"36vw"}}>Operative skill:</label>
  <p style={{width:"54vw"}}>{formatAnswers(lor.slor.radioOperativeSkill) === 0 ? 'N/A' : `${formatAnswers(lor.slor.radioOperativeSkill) * 10}th`}</p>
</div>
<div className="slor-item-present">
  <label style={{width:"36vw"}}>Overall compared to other applicants:</label>
  <p style={{width:"54vw"}}>{formatAnswers(lor.slor.radioOverallComparedToOtherApplicants) === 0 ? 'N/A' : `${formatAnswers(lor.slor.radioOverallComparedToOtherApplicants) * 10}th`}</p>
</div>
<div className="slor-item-present">
  <label style={{width:"36vw"}}>Patient care:</label>
  <p style={{width:"54vw"}}>{formatAnswers(lor.slor.radioPatientCare) === 0 ? 'N/A' : `${formatAnswers(lor.slor.radioPatientCare) * 10}th`}</p>
</div>
<div className="slor-item-present">
  <label style={{width:"36vw"}}>Professionalism:</label>
  <p style={{width:"54vw"}}>{formatAnswers(lor.slor.radioProfessionalism) === 0 ? 'N/A' : `${formatAnswers(lor.slor.radioProfessionalism) * 10}th`}</p>
</div>
<div className="slor-item-present">
  <label style={{width:"36vw"}}>Research and teaching:</label>
  <p style={{width:"54vw"}}>{formatAnswers(lor.slor.radioResearchAndTeaching) === 0 ? 'N/A' : `${formatAnswers(lor.slor.radioResearchAndTeaching) * 10}th`}</p>
</div>
<div className="slor-item-present">
  <label style={{width:"36vw"}}>Self initiative:</label>
  <p  style={{width:"54vw"}}>{formatAnswers(lor.slor.radioSelfInitiative) === 0 ? 'N/A' : `${formatAnswers(lor.slor.radioSelfInitiative) * 10}th`}</p>
</div>
<div className="slor-item-present">
  <label style={{width:"36vw"}}>Team player:</label>
  <p  style={{width:"54vw"}}>{formatAnswers(lor.slor.radioTeamPlayer) === 0 ? 'N/A' : `${formatAnswers(lor.slor.radioTeamPlayer) * 10}th`}</p>
</div>
<div className="slor-item-present">
  <label style={{width:"36vw"}}>Work ethic:</label>
  <p  style={{width:"54vw"}}>{formatAnswers(lor.slor.radioWorkEthic) === 0 ? 'N/A' : `${formatAnswers(lor.slor.radioWorkEthic) * 10}th`}</p>
</div>
<div className="slor-item-present">
  <label style={{width:"36vw"}}>Rank:</label>
  <p  style={{width:"54vw"}}>{formatAnswers(lor.slor.rank)}</p>
</div>
<div className="slor-item-present" style={{textAlign:'justify', gap:'2vw'}}>
  <label style={{width:"36vw"}} >Strongest point:</label>
  <p  style={{width:"54vw"}}>{formatAnswers(lor.slor.strongestPoint)}</p>
</div>
<div className="slor-item-present">
  <label style={{width:"36vw"}}>Willing to discuss:</label>
  <p style={{width:"54vw"}}>{formatAnswers(lor.slor.willingToDiscuss)}</p>
</div>
<div className="slor-item-present" style={{textAlign:'justify', gap:'2vw'}}>
  <label style={{width:"36vw"}}>Written comments:</label>
  <p  style={{width:"54vw"}}>{formatAnswers(lor.slor.writtenComments)}</p>
</div>
</div>
</div>
    ): null
)}
</div>
</div>
</div>

    
  );
};

export default ExmPDF;
