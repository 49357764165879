import * as Yup from 'yup';
import moment from 'moment';

const shortDateRegExp = /^(0[1-9]|1[0-2])\/\d{4}$/; // Regular expression for MM/YYYY
const birthDateRegExp = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}$/;

function countWords(input) {
    if (input) {
      const words = input.trim().split(/\s+/);
      return words.length;
    }
    return 0;
  }

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    // previousLastName: Yup.string().required('Previous Last name is required'),
    prefferedName: Yup.string(),
    startDate: Yup.string()
    .required('Start Date is required')
    .matches(shortDateRegExp, 'Start Date must be in the format MM/YYYY'),
  endDate: Yup.string()
    .required('End Date is required')
    .matches(shortDateRegExp, 'End Date must be in the format MM/YYYY'),
    // pronouns: Yup.string().required('Pronouns is required'),
    // usmleId: Yup.string().required('USMLE Id is required'),
    // nbomeId: Yup.string().required('NBOME Id is required'),
    // aoaId: Yup.string().required('AOA Id is required'),
    // permanentAddress:Yup.string().required('Permanent address is required'),
    sigmaPhi: Yup.string().required('Sigma sigma phi is required'),
    otherServiceObligations: Yup.string().required('Other service obligations is required'),
    dateOfBirth: Yup.string()
    .required('Date of birth is required')
    .matches(birthDateRegExp, 'Date of birth must be in the format MM/DD/YYYY'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    genderIdentity: Yup.string().required('Please select your gender identity'),
    nrmpId: Yup.string()
  .matches(/^[A-Z]\d{7}$/, 'NRMP ID must start with a letter followed by 7 digits'),
  aamcId: Yup.string()
  .matches(/^\d{8}$/, 'AAMC ID must be an 8-digit number')
  .required('AAMC ID is required'),
    hometown: Yup.string().required('Hometown is required'),
    homeAddress: Yup.string().required('Mailing address is required'),
    workAuthorization: Yup.string().required('Please select your work authorization'),
    carryingOutResponsibilities: Yup.string().required('Please Fill'),
    carryingOutResponsibilitiesElaboration: Yup.string().when('carryingOutResponsibilities', {
      is: 'yes',  
      then: (val) => val.required('This is a required field'),
      otherwise: (val) => val
    }),
    licensureRevoked: Yup.string().required('Please Fill'),
    licensureRevokedDetails: Yup.string().when('licensureRevoked', {
      is: 'yes',
      then: (val) => val.required('This is a required field'),
      otherwise: (val) => val
    }),
    militaryObligation: Yup.string().required('Please Fill'),
    militaryObligationDetails: Yup.string().when('militaryObligation', {
      is: 'yes',
      then: (val) => val.required('This is a required field'),
      otherwise: (val) => val
    }),
    medicalTrainingInterrupted: Yup.string().required('Please Fill'),
    medicalTrainingInterruptedDetails: Yup.string().when('medicalTrainingInterrupted', {
      is: 'yes',
      then: (val) => val.required('This is a required field'),
      otherwise: (val) => val
    }),
    misdemeanorOrFelonyDetails: Yup.string().when('misdemeanorOrFelony', {
      is: 'yes',
      then: (val) => val.required('This is a required field'),
      otherwise: (val) => val
    }),
    partnerName: Yup.string().when('couplesMatch', {
      is: 'yes',
      then: (val) => val.required('This is a required field'),
      otherwise: (val) => val
    }),
    partnerField: Yup.string().when('couplesMatch', {
      is: 'yes',
      then: (val) => val.required('This is a required field'),
      otherwise: (val) => val
    }),
    previouslyApplied: Yup.boolean(),
    elaboration: Yup.string().when('malpracticeLawsuit', {
      is: 'yes',
      then: (val) => val.required('This is a required field'),
      otherwise: (val) => val
    }),
    medicalSchoolType: Yup.string().required('Select your medical school type.'),
    medicalSchool: Yup.string().required('Medical School Name is required.'),
    fluentLanguages: Yup.array(),
    higherEducation: Yup.array().of(
      Yup.object().shape({
        date: Yup.date()
        .transform((value, originalValue) => {
          const date = moment(originalValue, 'MM/YYYY', true);
          return date.isValid() ? date.toDate() : new Date('');
        })
        .required('Date is required.')
        .nullable(),
        institution: Yup.string().required('Institution name is required.'),
        degree: Yup.string().required('Degree is required.'),
      })
    ),
    aoaStatus: Yup.string().required('Select your AOA status.'),
    ghhsStatus: Yup.string().required('Select your GHHS status.'),
    researchPublications: Yup.array().of(
      Yup.object().shape({
        journal: Yup.string().required('Journal or meeting name is required.'),
        title: Yup.string().required('Title is required.'),
        authors: Yup.string().required('Authors are required.'),
        datePublished: Yup.date().required('Date published is required.').nullable()
      })
    ),
    awayRotations: Yup.array().of(
      Yup.object().shape({
        degree: Yup.mixed(),
        dateCompleted: Yup.string()
        .matches(shortDateRegExp, 'Date must be in the format MM/YYYY'),
        
          // .required('Required'), 
      })
    ),
    nonCoreClerkshipGrades:Yup.array().of(
      Yup.object().shape({
        // grade: Yup.number()
          // .typeError('Must be a number')
          // .required('Required'),
      })
    ),
    firstAuthorPublications: Yup.number().min(0, 'Value must be at least 0.'),
    totalPublications: Yup.number().min(0, 'Value must be at least 0.'),
    posterPresentations: Yup.number().min(0, 'Value must be at least 0.'),
    podiumPresentations: Yup.number().min(0, 'Value must be at least 0.'),
    meaningfulExperiences: Yup.array().of(
      Yup.object().shape({
        category: Yup.string().required('Category is required.'),
        startDate: Yup.string()
        .required('Start Date is required')
        .matches(shortDateRegExp, 'Start Date must be in the format MM/YYYY'),
      endDate: Yup.string()
        .required('End Date is required')
        .matches(shortDateRegExp, 'End Date must be in the format MM/YYYY'),
        description: Yup.string()
        .required('Description is required')
        .test(
          'wordCount',
          'Must be 100 words or less.',
          value => {
            if (!value) {
              return true;
            }
            const words = value.split(/\s+/);
            return words.length <= 100;
          }
        ),
      })
    ),
    shortA1: Yup.string().test(
      'wordCount',
      'Must be 100 words or less.',
      value => {
        if (!value) {
          return true;
        }
        const words = value.split(/\s+/);
        return words.length <= 100;
      }
    ),
    shortA2: Yup.string().test(
      'wordCount',
      'Must be 100 words or less.',
      value => {
        if (!value) {
          return true;
        }
        const words = value.split(/\s+/);
        return words.length <= 100;
      }
    ),
    shortA3: Yup.string().test(
      'wordCount',
      'Must be 100 words or less.',
      value => {
        if (!value) {
          return true;
        }
        const words = value.split(/\s+/);
        return words.length <= 100;
      }
    ),
    obbiesInterests: Yup.string().test(
      'wordCount',
      'Must be 100 words or less.',
      value => {
        if (!value) {
          return true;
        }
        const words = value.split(/\s+/);
        return words.length <= 100;
      }
    ),
    personalStatement: Yup.string().test(
        'wordCount',
        'Personal statement must not exceed 750 words',
        value => countWords(value) <= 750
      ),
  });

  
  export default validationSchema;