import React, { useState,useEffect, useContext } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../styles/applicantsCalendar.css'; 
import Spinner from '../../../common/spinner';


const ApplicantCalendar = ({applications, setSelectedDate, selectedDate}) => {
  const [populatedDates, setPopulatedDates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  
  useEffect(() => {
    if (applications) {
        console.log('applications', applications);
        const datesToSend = applications.map(application => application.datesToSend).flat();
        
        const uniqueDatesToSend = Array.from(new Set(datesToSend.map(date => date.date))).map(date => {
        const status = datesToSend.find(d => d.date === date).status;
        // Ensure date is converted to Date object
        return { date: new Date(date), status };
        });
        console.log('saved dates', uniqueDatesToSend);
        setPopulatedDates(uniqueDatesToSend);
    }
}, [applications]);



  const month = selectedDate && selectedDate.toLocaleString('default', { month: 'long' }); // 'long' for full month name
  const day = selectedDate && selectedDate.getDate()

  return (
    <div>
       <Calendar
       className='applicant-calendar'
  onChange={(e)=>setSelectedDate(e)}
  selectRange={false}
  minDetail="month"
  prev2Label={null}
  next2Label={null}
  tileClassName={({ date, view }) => {
    // Normalize the comparison date to the start of the day
    const normalizedDate = new Date(date);
    normalizedDate.setHours(0, 0, 0, 0);

    const foundDate = populatedDates.find(populatedDate => {
      if (populatedDate.status === 2) {
        return null;
      }
        const populatedDateDate = new Date(populatedDate.date);
        populatedDateDate.setHours(0, 0, 0, 0);
        return populatedDateDate.getTime() === normalizedDate.getTime();
        });
        

        if (foundDate) {
          switch (foundDate.status) {
            case 0:
              return 'date-full';
            case 1:
              return 'date-available';
            default:
              return '';
          }
        }
    }
}
/>
    </div>
  );
}

export default ApplicantCalendar;