import React, { useContext, useState } from 'react';
import { Formik, Form, Field, ErrorMessage,FieldArray } from 'formik';
import Select from 'react-select';
import { FormContext } from '../ApplicationForm';
import Card from '../../documents/generalCard'
import ScoresCard from '../../documents/scoresCard';
import UploadDocs from './uploadDocs';
import { UserContext } from '../../../../context/userContext';


const ApplicationFormStep4 = ({isNested, applicant, applicationData, updateUserData, score, updatePhase, phases, setPage, viewOnly }) => {
  const { values, setStep, step, setFieldValue  } = useContext(FormContext);
  const [isEdit, setIsEdit] = useState(!viewOnly ? phases.fourthPhase? false : true: false);
  const [isTranscript, setIsTranscript] = useState(false);
  const [isMSPE, setIsMSPE] = useState(false);
  const [isScore, setIsScore] = useState(false);
  const { user } = useContext(UserContext);
  const isDo = user.schoolType === 'US Osteopathic Medical School (DO)'? true : false;


// console.log('application data in step 4', applicationData.id)

const checkDisabled = () => {
  if (!score){
    return !score
  }
  if (isDo && score.comlexLevel1Score) {
    return !score.comlexLevel1Score 
  }
  if (!isDo && score.usmleStep1Score ) {
    return !score.usmleStep1Score 
  }
}


const updateIsScore = (value) => {
  setIsScore(value);
};

const updateIsTranscript = (value) => {
  setIsTranscript(value);
}
const updateIsMSPE = (value) => {
  setIsMSPE(value);
}

return (
<>
            <div className="applicant-home-header">
                {/* <p className="applicant-home-header-subtitle"> If you are applying through the Independent Plastic Surgery pathway please do not complete this section, you do not need to request a transcript, MSPE or score verification.</p> */}
            </div>
            <div className="documents-body" style={{width:'100%', justifyContent:'center'}}>
            
              <UploadDocs masterAppId={applicationData.id}/>
            {/* <Card isEdit={isEdit} inAppForm={true} header='Transcript Submission Request' cardInfo='transcript' updateIsTranscript={updateIsTranscript} updateIsMSPE={updateIsMSPE}/> */}
            {/* <Card isEdit={isEdit} inAppForm={true} header='MSPE Submission Request' cardInfo='mspe' updateIsTranscript={updateIsTranscript} updateIsMSPE={updateIsMSPE}/> */}
            {/* <Card header='Score Verification Request' body='scoreValidation'/> */}
            {/* <ScoresCard inAppForm={true} updateIsScore={updateIsScore} isNested={true}/> */}
            </div>
            {/* {!isAllVerified && !viewOnly && 
              // <p className="applicant-home-header-subtitle" style={{margin:'1vh', color:'red', textAlign:'justify'}}>In order to complete this step you will need to submit and verify your scores, MSPE and Transcript, if you haven't sent your requests, please do so from the Documents section on the left Navigation Bar, if you have requested your Documents please contact the relevant person in your Medical School to complete the required upload/verification </p>
              // <p className="applicant-home-header-subtitle" style={{margin:'1vh', color:'red', textAlign:'justify'}}>In order to complete this step Open Application needs to receive and verify your scores, MSPE and Transcript. </p>
            } */}

 
 {/* {
   !viewOnly &&     
  <button onClick={() => setPage("documents")} className='green-button' style={{marginTop: '2vh', float: 'right'}} disabled={isAllVerified}>Update Documents</button>

 } */}

 {/* {checkDisabled() && 
  <p className="applicant-home-header-subtitle" style={{margin:'1vh', color:'red', textAlign:'justify'}}>
  Please enter your standardized test scores on the Documents page on the left-hand toolbar.
  </p>
 } */}

    {!isNested && isEdit&& (
  <button className="mark-as-complete" type="button" 
  // disabled={checkDisabled()}
  // style={!isAllVerified?{opacity:'.5',cursor:'not-allowed'}:{}}
  onClick={(event) => {
    event.preventDefault()
    // updateUserData(values,4)
    updatePhase("fourthPhase")
    setStep(prevStep => prevStep + 1);

  }}>
  Next
  </button>
)}
{!isNested && !isEdit &&(
  <button className="white-button"
  style={{width:'100%'}}
  onClick={() => {
 setIsEdit(true)
 updatePhase("fourthPhase")
    }}>
    Reopen Task
  </button>
)}

</>
)}

export default ApplicationFormStep4;