import React, { useState, useRef, useEffect, useMemo } from "react";
import "./styles/pdf.css";
import logo from "../../assets/imgs/logo2.svg";
import { Field } from 'formik';

const RubricsPDF = ({ rubrics }) => {

  const screeningRubrics = useMemo(() => {
    return rubrics
        ? rubrics
            .filter(elem => elem.isPreInterview === true)
            .sort((a, b) => a.order - b.order) // Sort by 'order' field
        : [];
}, [rubrics]); // Dependency on 'rubrics' array

const interviewRubrics = useMemo(() => {
    return rubrics
        ? rubrics
            .filter(elem => elem.isPreInterview === false)
            .sort((a, b) => a.order - b.order) // Sort by 'order' field
        : [];
}, [rubrics]); // Dependency on 'rubrics' array
    const pageHeight = 1122; // A4 page height in pixels (adjust if necessary)
    const [pages, setPages] = useState([]); // Array to hold split pages of rubrics
    const [interviewPages, setInterviewPages] = useState([]);
    const containerRef = useRef(null);
    const interviewContainerRef = useRef(null);

    console.log("rubrics =>", rubrics)
    console.log("screeningRubrics =>", screeningRubrics)
    console.log("interviewRubrics =>", interviewRubrics)

    useEffect(() => {
        const createPages = () => {
            console.log("containerRef =>", containerRef)
            if (screeningRubrics) {
                console.log("inside crreate")
                let currentPage = [];
                let accumulatedHeight = 0;
                const newPages = [];
                const elementHeights = [];
        
                // Create a temporary container to measure height of each rubric
                screeningRubrics.forEach((rubric, index) => {
                console.log("inside rubric loop =>", rubric)
                const tempDiv = document.createElement('div');
                tempDiv.className = 'single-rubric'; // Add any styles as necessary to match the layout
                tempDiv.innerHTML = `<label>${rubric.label}</label>`;
                document.body.appendChild(tempDiv);
        
                const elementHeight = tempDiv.offsetHeight + 150; // Get height of rubric element
                console.log("elementHeight =>", elementHeight)
                document.body.removeChild(tempDiv); // Remove temporary element
        
                elementHeights.push({ rubric, height: elementHeight });
                });
        
                elementHeights.forEach(({ rubric, height }) => {
                accumulatedHeight += height;
                console.log("accumulatedHeight =>", accumulatedHeight, pageHeight)
                // If adding this element exceeds page height, start a new page
                if (accumulatedHeight > pageHeight) {
                    newPages.push(currentPage);
                    currentPage = []; // Start a new page
                    accumulatedHeight = height; // Reset the height with current element's height
                }
        
                currentPage.push(rubric); // Add rubric to the current page
                });
        
                if (currentPage.length > 0) {
                newPages.push(currentPage);
                }
                console.log("newPages =>", newPages)
                setPages(newPages); // Set the pages to be rendered
            }
            if(interviewRubrics) {

            }
        };

        const createInterviewPages = () => {
            console.log("interviewContainerRef =>", interviewContainerRef)
            if (interviewRubrics) {
                console.log("inside createInterviewPages")
                let currentPage = [];
                let accumulatedHeight = 0;
                const newPages = [];
                const elementHeights = [];
        
                // Create a temporary container to measure height of each rubric
                interviewRubrics.forEach((rubric, index) => {
                console.log("inside interview rubric loop =>", rubric)
                const tempDiv = document.createElement('div');
                tempDiv.className = 'single-rubric'; // Add any styles as necessary to match the layout
                tempDiv.innerHTML = `<label>${rubric.label}</label>`;
                document.body.appendChild(tempDiv);
        
                const elementHeight = tempDiv.offsetHeight + 120; // Get height of rubric element
                console.log("elementHeight =>", elementHeight)
                document.body.removeChild(tempDiv); // Remove temporary element
        
                elementHeights.push({ rubric, height: elementHeight });
                });
        
                elementHeights.forEach(({ rubric, height }) => {
                accumulatedHeight += height;
                console.log("accumulatedHeight =>", accumulatedHeight, pageHeight)
                // If adding this element exceeds page height, start a new page
                if (accumulatedHeight > pageHeight) {
                    newPages.push(currentPage);
                    currentPage = []; // Start a new page
                    accumulatedHeight = height; // Reset the height with current element's height
                }
        
                currentPage.push(rubric); // Add rubric to the current page
                });
        
                if (currentPage.length > 0) {
                newPages.push(currentPage);
                }
                console.log("interviewPages =>", newPages)
                setInterviewPages(newPages); // Set the pages to be rendered
            }
          
        };
        console.log("createPages called!")
        createPages(); // Call the function after the component is mounted
        createInterviewPages()
      }, [screeningRubrics, interviewRubrics]);


      useEffect(() => {
          console.log('pages updated!', pages)
      }, [pages])


    console.log("pages =>", pages)
    return (
        <div>
        {pages.map((rubricPage, pageIndex) => (
          <div className="a4-rubrics-container page" key={pageIndex} ref={containerRef} style={{ position: 'relative', width: '243mm', height: '420mm' }}>
            {pageIndex === 0 && ( // Render only on the first page
              <>
                <div className="a4-header-cont">
                  <div className="pp-cont" style={{ display: 'flex', justifyContent: 'center', width: '700px' }}>
                    <label style={{ fontSize: '2rem', fontWeight: 500, marginLeft: '100px' }}>EVALUATION FORMS</label>
                  </div>
                  <img src={logo} alt="logo" style={{ position: 'absolute', top: '1px', right: '0px', height: '180px' }} />
                </div>
                <label className="rub-title">Screening Evaluation Form</label>
              </>
            )}
  
            <div className="rubrics-container" style={{ marginTop: '20px'}}>
              {rubricPage.map((rubric, index) => (
                <div className="single-rubric" key={index}>
                  <label style={{ marginBottom: '5px' }}>
                      {
                          console.log('rubricPage.findIndex(elem => elem.id === rubric.id) =>', screeningRubrics.findIndex(elem => elem.id === rubric.id))
                      }
                    {screeningRubrics.findIndex(elem => elem.id === rubric.id) < 10 ? "0" + (screeningRubrics.findIndex(elem => elem.id === rubric.id) + 1) : screeningRubrics.findIndex(elem => elem.id === rubric.id) + 1} {rubric.label}
                  </label>
                  {rubric.inputType === 'dropdown' && (
                    <div role="group" aria-labelledby="radio-group" className='application-form-group-radio' style={{ marginLeft: '27px' }}>
                      {rubric.dropdownOptions.map((option, optIndex) => (
                        <label className={`radio-option`} key={optIndex}  style={{ marginTop: '7px'}}>
                          <input type="radio" value={option.option}/>
                          <span className="custom-radio" style={{ marginTop: '15px'}}></span>
                          {option.option}- Score: {option.score}
                        </label>
                      ))}
                    </div>
                  )}
                  {rubric.inputType === 'scale' && (
                    <div className="rating-scale-container">
                      <div className="scale-container">
                        <span className="rating-text">{rubric.lowestScaleText}</span>
                        <input type="range" min="1" max={rubric.maxScale} className="slider" />
                        <span className="rating-text">{rubric.highestScaleText}</span>
                      </div>
                      <div className="scale-labels">
                        {Array.from({ length: rubric.maxScale + 1 }, (_, i) => i).map((value) => (
                          <span key={value} className="scale-label">
                             {value < 10 ? `0${value}` : value}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                  {rubric.inputType === 'text' && (
                    <div className="rating-scale-container">
                      <textarea className="textarea-rubric" />
                    </div>
                  )}
                  <seperator className="rubric-seperator" />
                </div>
              ))}
            </div>
          </div>
        ))}
        {interviewPages.map((rubricPage, interviewPageIndex) => (
          <div className="a4-rubrics-container page" key={interviewPageIndex} ref={interviewContainerRef} style={{ position: 'relative', width: '243mm', height: '400mm' }}>
            {interviewPageIndex === 0 && ( // Render only on the first page
              <>
                <label className="rub-title">Interview Evaluation Form</label>
              </>
            )}
  
            <div className="rubrics-container">
              {rubricPage.map((rubric, index) => (
                <div className="single-rubric" key={index}>
                  <label style={{ marginBottom: '5px' }}>
                      {
                          console.log('interviewRubrics.findIndex(elem => elem.id === rubric.id) =>', interviewRubrics.findIndex(elem => elem.id === rubric.id))
                      }
                    {interviewRubrics.findIndex(elem => elem.id === rubric.id) < 10 ? "0" + (interviewRubrics.findIndex(elem => elem.id === rubric.id) + 1) : interviewRubrics.findIndex(elem => elem.id === rubric.id) + 1} {rubric.label}
                  </label>
                  {rubric.inputType === 'dropdown' && (
                    <div role="group" aria-labelledby="radio-group" className='application-form-group-radio' style={{ marginLeft: '27px' }}>
                      {rubric.dropdownOptions.map((option, optIndex) => (
                        <label className={`radio-option`} key={optIndex}  style={{ marginTop: '7px'}}>
                          <input type="radio" value={option.option}/>
                          <span className="custom-radio" style={{ marginTop: '15px'}}></span>
                          {option.option}- Score: {option.score}
                        </label>
                      ))}
                    </div>
                  )}
                  {rubric.inputType === 'scale' && (
                    <div className="rating-scale-container">
                      <div className="scale-container">
                        <span className="rating-text">{rubric.lowestScaleText}</span>
                        <input type="range" min="1" max={rubric.maxScale} className="slider" />
                        <span className="rating-text">{rubric.highestScaleText}</span>
                      </div>
                      <div className="scale-labels">
                        {Array.from({ length: rubric.maxScale + 1 }, (_, i) => i).map((value) => (
                          <span key={value} className="scale-label">
                             {value < 10 ? `0${value}` : value}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                  {rubric.inputType === 'text' && (
                    <div className="rating-scale-container">
                      <textarea className="textarea-rubric" />
                    </div>
                  )}
                  <seperator className="rubric-seperator" />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
}

export default RubricsPDF;