import React,{useState} from "react";

const ApplicationViewNavBar = ({onNavClick, currentSection}) => {
    const [isApplicationOpen, setIsApplicationOpen] = useState(false)
    

    const applicationSections = [
        {section: "Cover Letter", id: "personalStatement"},
        {section: "Personal Information", id: "demographics"},
        {section: "Education", id: "education"},
        // {section: "Honors and Awards", id: "honorsAndAwards"},
        // {section: "Research", id: "research"},
        {section: "Work Experience", id: "workExperience"},
        // {section: "Significant Experiences", id: "significantExperiences"},
        // {section: "Hobbies and Interests", id: "hobbies"},
        // {section: "Short Answer Questions", id: "shortQA"},
        // {section: "Signal Statement", id: "signalStatement"},
        // {section: "Additional Research File", id: "additionalResearchFile"},
        {section: "CV", id: "lettersOfRecommendation"},
        // {section: "MSPE", id: "mspe"},
        // {section: "Transcript", id: "transcript"}
        
      ];
      
      const condClassName = (sectionId) => {
        return sectionId === currentSection ? "application-view-navbar-section-text-active" : "application-view-navbar-section-text";
    }


return (
    <div className="application-view-navbar">

{applicationSections.map((section, index) => (
    <div 
        className="application-view-navbar-item" 
        key={section.id} 
        onClick={() => {
            onNavClick(section.id)
            // setCurrentSection(section.id) // Add this line
        }}
    >
        <div className={condClassName(section.id)}>{section.section}</div>
    </div>
))}

    </div>
)
}

export default ApplicationViewNavBar;