import React, { useState, useEffect } from 'react';
import logo from '../../../../assets/imgs/logo.svg';
import Spinner from '../../../common/spinner';
import {updateBypassSMSMutation} from './graphql/mutations';
import {useMutation} from '@apollo/client';

const DirectAccessModal = ({ isOpen, onClose, user, refetchUsers}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [bypassSMS, setBypassSMS] = useState(user.bypassSMS);
    const [permanentBypass, setPermanentBypass] = useState(user.permanentBypassSMS);
    const [initialBypass, setInitialBypass] = useState(
        {
            bypassSMS: user.bypassSMS,
            permanentBypass: user.permanentBypassSMS
        }
    );

    const [updateBypassSMS] = useMutation(updateBypassSMSMutation);

    const handle2FABypassChange = (event) => {
        setBypassSMS(event.target.value === 'true');
    };

    const handle2FABypassPermChange = (event) => {
        setPermanentBypass(event.target.value === 'true');
    };

  


    const handleSave = () => {
        if (bypassSMS === initialBypass.bypassSMS && permanentBypass === initialBypass.permanentBypass) {
            onClose();
            return;
        }else{
            setLoading(true);
            updateBypassSMS({
                variables: {
                    id: user.id,
                    bypassSMS: bypassSMS,
                    permanentBypassSMS: permanentBypass,
                }
            }).then(() => {
                setLoading(false);
                refetchUsers();
                onClose();
            }).catch((error) => {
                setLoading(false);
                setError(error.message);
            });
        }

    }




    if (!isOpen) return null;

    return (
        <div className="modal-backdrop">
            <div className="add-field-modal">
                <button className='close-icon' onClick={onClose}>X</button>
                <div className='add-field-modal-header' style={{ marginTop: '3vh'}}>
                    <h2>Direct Access Login</h2>
                    <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
                </div>
                <div>
                    <div className='verification-title'>Bypass 2FA Verification?</div>
                    <div style={{width:'8vw', display:'flex', justifyContent:'space-between'}} onChange={handle2FABypassChange}>
                        <div>
                        <input
                        style={{display:'unset', accentColor:'#145388'}}
                            type="radio"
                            value="true"
                            name="bypass2FA"
                            checked={bypassSMS === true}
                        /> Yes
                        </div>
                        <div>
                        <input
                        style={{display:'unset', accentColor:'#145388'}}
                            type="radio"
                            value="false"
                            name="bypass2FA"
                            checked={bypassSMS === false}
                        /> No
                        </div>
                    </div>

                    {bypassSMS === true &&(
                        <>
                    <div className='verification-title'>Permanent Bypass?</div>
                    <div style={{width:'8vw', display:'flex', justifyContent:'space-between'}} onChange={handle2FABypassPermChange}>
                        <div>
                        <input
                        style={{display:'unset', accentColor:'#145388'}}
                            type="radio"
                            value="true"
                            name="permanentBypass"
                            checked={permanentBypass === true}
                        /> Yes
                        </div>
                        <div>
                        <input
                        style={{display:'unset', accentColor:'#145388'}}
                            type="radio"
                            value="false"
                            name="permanentBypass"
                            checked={permanentBypass === false}
                        /> No
                        </div>
                        </div>
                        </>
                    )}

                </div>
                <div className='verification-buttons' style={{width:'50%',marginLeft:'25%'}}>
                    <button className='green-button' 
                        onClick={handleSave}>Save</button>
                    <button className='green-button' style={{background:'white', border:'1px solid #145388', color:'#145388'}} onClick={()=>onClose()}>Cancel</button>
                </div>
               {error && <div className='error-title'>{error}</div>}
               {loading && <Spinner/>}
           
            </div>
        </div>
    )

}

export default DirectAccessModal;