import React,{useState,useEffect} from "react";
import "../styles/dateDetails.css";
import moment from 'moment';
import StatusIndicator from "../../../faculty/facultyComponents/interviewManager/inviteComponents/statusIndicator";
import Spinner from "../../../common/spinner";
import inPersonInterviewIcon from '../../../../assets/imgs/in_person_interview_icon.svg';
import virtualInterviewIcon from '../../../../assets/imgs/virtual_interview_icon.svg';



const DateDetails = ({selectedDate, applications, handleScheduleInterview, handleCancelInterview, isLoading}) => {
const [dateToDisplay, setDateToDisplay] = useState([]);


useEffect(() => {
    if (applications && selectedDate) {
        const matchingApplications = applications.filter(application => 
            application.datesToSend.some(dateObj => 
                new Date(dateObj.date).getTime() === new Date(selectedDate).getTime()
            )
        );
        setDateToDisplay(matchingApplications);
    }
}, [selectedDate, applications]);

useEffect(() => {
    console.log('dateToDisplay', dateToDisplay);
}, [dateToDisplay]);

if(isLoading){
    return <Spinner />
}

    return (
        <div className='date-details'>
            {!selectedDate && (
                    <h2>No date selected</h2>
            )}
            {selectedDate && dateToDisplay.length === 0 && (
                <h2>No interviews for {moment(selectedDate).format('MMMM D, YYYY')}</h2>
            )}
            {selectedDate && dateToDisplay.length > 0 && (
                <h2 style={{fontWeight:'500', textAlign:'center', marginBottom:'2vh', overflowY:'scroll'}}>{moment(selectedDate).format('MMMM D, YYYY')}</h2>
            )}
            {selectedDate && dateToDisplay.length > 0 && (
                dateToDisplay.map((application, index) => (
                    <div key={index} className='date-details-item'>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <p>{application.programName}, {application.specialty}</p>
                        <StatusIndicator status={application.status} interviewDate={application.interviewDate}/>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        {application.status === 'Scheduled' && new Date(application.interviewDate).getTime() !== new Date(selectedDate).getTime() &&(
                            <p style={{color:'#145388', cursor:'pointer'}} onClick={()=>handleScheduleInterview(application.id, selectedDate, application.programId)}>Reschedule</p>
                        )}
                         {application.status === 'Scheduled' && new Date(application.interviewDate).getTime() === new Date(selectedDate).getTime() &&(
                            <p style={{color:'#145388', cursor:'pointer'}} onClick={()=>handleCancelInterview(application.id,application.programId)}>Cancel</p>
                        )}
                        {(application.status === 'Declined'|| application.status ==='Canceled' || application.status === 'Response Pending') && (
                            <p style={{color:'#145388', cursor:'pointer'}} onClick={()=>handleScheduleInterview(application.id, selectedDate, application.programId)}>Schedule</p>
                        )}
                        </div>
                    </div>
                ))
            )}
            </div>
    );
}

export default DateDetails;