import {gql} from '@apollo/client';

export const getApplicantInterviewsQuery = gql`
    query getApplicantInterviews($userId: ID!) {
        getApplicantInterviews(userId: $userId) {
            id
            programId
            programName
            status
            interviewDate
            specialty
            datesToSend {
                date
                status
                isRegularDay
                startTime
                totalAvailableSlots
                currentAvailableSlots
              }
        }
    }
`;

export const getdatesToSendQuery = gql`
    query getdatesToSend($applicationId: ID!) {
        getdatesToSend(applicationId: $applicationId) {
            date
            status
            isRegularDay
            startTime
            totalAvailableSlots
            currentAvailableSlots
        }
    }
`;