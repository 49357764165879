import React, { useState, useContext } from 'react';
import logo from '../../../../assets/imgs/logo.svg'
import {CREATE_RUBRIC_TEMPLATE} from '../graphql/mutations';
import { useMutation } from '@apollo/client';
import {ProgramIdContext} from '../../progIdContext'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const AddFieldModal = ({ isOpen, onClose, onAddRubric, lastRubricOrder, existingRubric, activeTab }) => {
    const [label, setLabel] = useState(existingRubric?existingRubric.label:'');
    const [inputType, setInputType] = useState(existingRubric?existingRubric.inputType:'text');
    const [dropdownOptions, setDropdownOptions] = useState(existingRubric?existingRubric.dropdownOptions:[]);
    const [maxScale, setMaxScale] = useState(existingRubric?existingRubric.maxScale:5); // Default value set to 5
    const [lowestScaleText, setLowestScaleText] = useState(existingRubric?existingRubric.lowestScaleText:'');
    const [highestScaleText, setHighestScaleText] = useState(existingRubric?existingRubric.highestScaleText:'');
    const [finishOption, setFinishOption] = useState(false)
    const [currentValue, setCurrentValue] = useState(0)
    const [createRubricTemplate, { data }] = useMutation(CREATE_RUBRIC_TEMPLATE);
    const [editableIndex, setEditableIndex] = useState(null);
    const [editableOption, setEditableOption] = useState('');
    const [editableScore, setEditableScore] = useState('');

    const programId = useContext(ProgramIdContext);

    if (!isOpen) return null;


    // rubricTemplate:[{
//     maxScale:Number,
//     lowestScaleText: { type: String },
//     highestScaleText: { type: String },
//     dropdownOptions: { type: [String] },
//     text: { type: String },
//     order: { type: Number },


const handleAddOption = (option) => {
    console.log("option =>", option)
    setCurrentValue(option)
    setFinishOption(true)
};

const handleScoreChanged = (score) => {
    console.log("currentValue =>", currentValue)
    setDropdownOptions([...dropdownOptions, {option: currentValue, score: score !== "" ? parseInt(score) : 0}]);
}

const handleRemoveOption = (index) => {
    setDropdownOptions(prevOptions => prevOptions.filter((_, i) => i !== index));
};

const handleAddRubric = async () => {
    const newRubric = {
        label: label,
        inputType: inputType,
        isPreInterview: activeTab === 'screening' ? true : false,
        dropdownOptions: inputType === 'dropdown' ? dropdownOptions : [],
        maxScale: inputType === 'scale' ? maxScale : undefined,
        lowestScaleText: inputType === 'scale' ? lowestScaleText : undefined,
        highestScaleText: inputType === 'scale' ? highestScaleText : undefined,
        order:lastRubricOrder+1,
        

    };

    console.log("newRubric =>", newRubric)

    onAddRubric(newRubric);
    setLabel('');
    setInputType('text');
    setDropdownOptions([]);
    setMaxScale(5);
    setLowestScaleText('');
    setHighestScaleText('');
    onClose(); // Close the modal
};

const handleSaveScore = (index) => {
    const updatedDrop = [...dropdownOptions]
    updatedDrop[index].score = parseInt(editableScore)
    setDropdownOptions(updatedDrop)
    setEditableIndex(null)
}

const handleSaveOption = (index) => {
    const updatedOptions = [...dropdownOptions];
    updatedOptions[index].option = editableOption;
    setDropdownOptions(updatedOptions);
    setEditableIndex(null);
};

const handleEditOption = (index, option) => {
    console.log("inside handleEditOption", index)
    setEditableIndex(index);
    setEditableOption(option.option);
};

const handleEditScore = (index, option) => {
    console.log("inside handleEditScore")
    setEditableIndex(index);
    setEditableScore(option.score);
}

const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(dropdownOptions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setDropdownOptions(items);
};

    return (
        <div className="modal-backdrop">
            <div className="add-field-modal">
                <div className='add-field-modal-header'>
                <h2>ADD FIELD</h2>
                <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
                </div>
                <form>
                    <div className="form-group">
                        <label style={{
                            fontFamily: 'Montserrat',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '16px',
                            color: '#848484',
                            lineHeight: '24px',
                            marginBottom: '19px',
                            }}>Label</label>
                        <input
                            type="text"
                            className="application-form-standard-input"
                            value={label}
                            onChange={(e) => setLabel(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                    <label style={{
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  color: '#848484',
  lineHeight: '24px',
  marginBottom: '19px',
}}>Input Type</label>
                        <select
                            value={inputType}
                            onChange={(e) => setInputType(e.target.value)}
                            className="application-form-standard-input"
                        >
                            <option value="text">Text</option>
                            <option value="dropdown">Dropdown</option>
                            <option value="scale">Scale</option>
                        </select>
                    </div>
                    {inputType === 'dropdown' && (
                        <div className="form-group">
                            <label>Dropdown Options</label>
                            <div style={{ display: 'flex',}}>
                                <input
                                    type="text"
                                    id="option-input"
                                    // value={currentValue}
                                    className="application-form-standard-input"
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            handleAddOption(e.target.value);
                                            // e.target.value = ''; // Clear input after adding
                                        }
                                    }}
                                    placeholder="Type option and press Enter"
                                />
                                {
                                    finishOption &&
                                    <input 
                                    id="score-input"
                                    type="number"
                                    className="application-form-standard-input"
                                    style={{ marginLeft: '0.5vw'}}
                                    placeholder="Type score and press Enter"
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            handleScoreChanged(e.target.value);
                                            document.getElementById('option-input').value = ''
                                            document.getElementById('score-input').value = ''
                                            setFinishOption(false)
                                            // e.target.value = ''; // Clear input after adding
                                        }
                                    }}
                                />
                                }
                               
                            </div>
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId="dropdownOptions">
                                    {(provided) => (
                                        <ul {...provided.droppableProps} ref={provided.innerRef}>
                                            {dropdownOptions.map((option, index) => (
                                                <Draggable key={option.option} draggableId={option.option} index={index}>
                                                    {(provided) => (
                                                        <li 
                                                            ref={provided.innerRef} 
                                                            {...provided.draggableProps} 
                                                            {...provided.dragHandleProps}
                                                            style={{ ...provided.draggableProps.style, border: '.2vh solid #145388', color: '#145388', fontSize: '3vh', padding: '1vh', borderRadius: '5px', marginTop: '1vh', marginBottom: '1vh' }}
                                                        >
                                                            {editableIndex === index ? (
                                                                <div style={{ display: 'flex', width: '20vw' }}>
                                                                <input
                                                                    type="text"
                                                                    value={editableOption}
                                                                    onChange={(e) => {
                                                                        console.log("editableOption =>", editableOption)
                                                                        setEditableOption(e.target.value)}}
                                                                    onBlur={() => handleSaveOption(index)}
                                                                    onKeyPress={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            handleSaveOption(index);
                                                                        }
                                                                    }}
                                                                    // autoFocus
                                                                />
                                                                    -
                                                                    <input
                                                                    type="text"
                                                                    value={editableScore}
                                                                    onChange={(e) => {
                                                                        console.log("editableScore =>", editableScore)
                                                                        setEditableScore(e.target.value)}}
                                                                    onBlur={() => handleSaveScore(index)}
                                                                    onKeyPress={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            handleSaveScore(index);
                                                                        }
                                                                    }}
                                                                    // autoFocus
                                                                />
                                                                </div>
                                                              
                                                            ) : (
                                                                <div>
                                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                                                       <div style={{ width:'6vw', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1vh'}}>
                                                                            <label onClick={() => handleEditOption(index, option)} style={{color: "#145388", fontSize: '1.4rem', cursor: 'pointer'}}>{option.option} - </label>
                                                                            <label onClick={() => handleEditScore(index, option)} style={{color: "#145388", fontSize: '1.4rem', cursor: 'pointer'}}>{option.score}</label>
                                                                        </div>

                                                                        <button type="button" onClick={() => handleRemoveOption(index)} style={{marginLeft: '10px', color:'red', fontWeight:'bold', fontSize:'2vh', float:'right'}}>X</button>
                                                                    </div>

                                                                </div>
                                                            )}
                                                        </li>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </ul>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    )}

{inputType === 'scale' && (
                        <div className="form-group">
                            <label>Number of Items in Scale (Max: 10)</label>
                            <input
                                type="number"
                                className="application-form-standard-input"
                                value={maxScale}
                                onChange={(e) => setMaxScale(Math.min(parseInt(e.target.value), 10))}
                            />
                            <label>Text for Left Side of Scale</label>
                            <input
                                type="text"
                                className="application-form-standard-input"
                                value={lowestScaleText}
                                onChange={(e) => setLowestScaleText(e.target.value)}
                            />
                            <label>Text for Right Side of Scale</label>
                            <input
                                type="text"
                                className="application-form-standard-input"
                                value={highestScaleText}
                                onChange={(e) => setHighestScaleText(e.target.value)}
                            />
                        </div>
                    )}
                    <div className='button-container' style={{display:'flex', flexDirection:'row', justifyContent:'space-between', gap:'2vw'}}>
                    <button type="button" className='gradient-button' onClick={handleAddRubric}
                    disabled={label === '' || (inputType === "dropdown" && dropdownOptions.length === 0) || (inputType === "scale" && (lowestScaleText === '' || highestScaleText === '' || maxScale === '' || lowestScaleText === null || highestScaleText === null || maxScale === null))}
                    >Add</button>
                    <button type="button" className='white-button'  onClick={onClose}>Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddFieldModal;