import React, {useEffect,useState} from "react";
import {getApplicationSharedNotesQuery} from './graphql/queries';
import {addSharedNoteToApplicationMutation} from './graphql/mutation';
import {useQuery, useMutation} from '@apollo/client';
import Spinner from '../../../common/spinner'

const SharedComments = ({applicationId, userId}) => {
const [comments, setComments] = useState([]);
const [newComment, setNewComment] = useState('');
const [isLoading, setIsLoading] = useState(false);

const {data, loading, error, refetch} = useQuery(getApplicationSharedNotesQuery, {
    variables: {
        applicationId: applicationId
    }
});
const [addSharedNoteToApplication] = useMutation(addSharedNoteToApplicationMutation);

useEffect(() => {
    if (!loading && !error && data) {
        console.log("get comments =>", data)
        setComments(data.getApplicationSharedNotes);
    }
}, [loading, error, data]);
    

const handleSave = () => {
    setIsLoading(true);
    addSharedNoteToApplication({
        variables: {
            applicationId: applicationId,
            note: newComment,
            userId: userId,
            timestamp: new Date().getTime().toString()
        }
    }).then(res => {
        console.log(res);
        setNewComment('');
        refetch();
        setIsLoading(false);
    }).catch(err => {
        console.log(err);
        setIsLoading(false);
    });
}

if (loading) return <Spinner />

    return (
        <div className="application-view-rubric-cont" style={{minHeight:'unset', height:'fit-content', marginTop:'1vh'}}>
        <div className="application-view-rubric-header" style={{marginTop:'0'}}>
            <h2 className="application-view-rubric-header-title"
            style={{
                fontFamily: 'Montserrat',
                fontWeight: 500,
                fontSize: '3vh',
                color: "#4A4A4A",
                lineHeight: "25px",
                borderBottom: '2px solid #145388',
                textAlign: 'center',
                padding:'2vh',
            }}
            >Shared Comments</h2>
            <seperator className="application-view-rubric-header-seperator"/>
            </div>
            <div className="application-view-rubric-content" style={{marginTop:'1vh'}}>
                { comments.length === 0 && <label style={{fontFamily: 'Montserrat', textAlign: 'center'}}>No shared comments available.</label>}

                
                {comments.map((comment, index) => {
                     const date = new Date(parseInt(comment.timestamp));
                    //  const formattedTimestamp = `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear().toString().slice(-2)} ${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
                     const formattedTimestamp = `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear().toString().slice(-2)} ${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
                    return (
                        <div className="notes-internal-cont">
                        <div key={index} className="application-view-rubric-content-item" style={{marginBottom:'2vh'}}>
                            <div className="application-view-rubric-content-item-title" style={{display:'flex', justifyContent:'space-between'}}>
                                <label style={{fontFamily: 'Montserrat', fontWeight: 500, fontSize: '2vh', color: "#4A4A4A"}}> {comment.userId.firstName} {comment.userId.lastName}</label>
                                <label style={{fontFamily: 'Montserrat', fontWeight: 500, fontSize: '2vh', color: "#4A4A4A"}}>{formattedTimestamp} </label>
                            </div>
                            <div className="application-view-rubric-content-item-content">
                                <label style={{fontFamily: 'Montserrat', fontSize: '2vh', color: "#4A4A4A"}}>{comment.note}</label>
                            </div>
                        </div>
                        </div>
                    )
                }
                )}
                
                </div>

{isLoading && <Spinner />}
{!isLoading&&(
    <>
                <textarea 
    className='application-form-standard-input textBox' 
    name="newComment" 
    value={newComment} 
    onChange={(e) => setNewComment(e.target.value)} 
    style={{
        width: '100%', 
        height: '15vh', 
        marginTop: '1vh',
        marginBottom: '0',
        resize: 'none', // Optional: Prevents resizing
    }}
/>
                
                <button 
                disabled={newComment===''}
                className="green-button" style={{width:'100%'}} onClick={handleSave}>Save</button>

        </>
)
}
        </div>
    )
}

export default SharedComments;