import React, { useState,useEffect, useContext } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './styles/calendar.css'; 
import { useInterviewScheduleSingle } from './SingleInterviewScheduleContext';
import { ProgramIdContext } from '../../progIdContext';
import {getProgramPopulatedDatesQuery, getSpecificProgramPopulatedDateQuery} from "./graphql/queries";
import {useMutation, useLazyQuery, useQuery, useApolloClient} from '@apollo/client';
import ProgressBar from './dayCustomize/ProgressBar';
import {updateProgramPopulatedDates} from "./graphql/mutations";
import thumbsUp from '../../../../assets/imgs/thumbsUp.svg';
import thumbsDown from '../../../../assets/imgs/thumbsDown.svg';
import SlotForm from './dayCustomize/slotsForm';
import inPersonInterviewIcon from '../../../../assets/imgs/in_person_interview_icon.svg';
import virtualInterviewIcon from '../../../../assets/imgs/virtual_interview_icon.svg';
import interviewDayDoneIcon from '../../../../assets/imgs/inteviewDayDone.svg';
import Spinner from '../../../common/spinner';
import InterviewScheduleTable from "./interviewScheduleTable";
import {AddMinutes, sanitizeTimeFormat} from "./dayCustomize/addMinutes";




const PlanInterviewCalendar = ({setInterviewers}) => {
  const programId = useContext(ProgramIdContext);
  const [breakBool, setBreakBool] = useState(false);
  const [slotsBool, setSlotsBool] = useState(false);
  const { selectedDate, setSelectedDate,
     page,setPage,
     step,setStep,
     startTime, setStartTime,
     interviewLength, setInterviewLength,
     breakTime, setBreakTime,
     slots, setSlots,
     interviewersPerInterviewee, setInterviewersPerInterviewee,
     numberOfSlots, setNumberOfSlots,
     dayType, setDayType,
     isLocked, setIsLocked,
     numOfInterviewers, setNumOfInterviewers,
     resetValues,
     } = useInterviewScheduleSingle();
  const [populatedDatesOverview, setPopulatedDatesOverview] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [calData, setCalData] = useState([]);
  const [calColumns, setCalColumns] = useState([]);


  console.log("selectedDate =>", selectedDate)
  const client = useApolloClient();
  const [updateProgramPopulatedDatesMutation] = useMutation(updateProgramPopulatedDates);
  const { data: scheduleOverview, loading: scheduleOverviewLoading, error: scheduleOverviewError } = useQuery(getProgramPopulatedDatesQuery, {
    variables: { programId: programId },
  });
  const [getScheduleOverview, { data: specificDayData, loading: specificDayLoading, error: specificDayError }] = useLazyQuery(getSpecificProgramPopulatedDateQuery);


useEffect(() => {
  if (scheduleOverview && scheduleOverview.getProgramPopulatedDates) {
    console.log(scheduleOverview.getProgramPopulatedDates, 'YEAAAAAAAH');
    const populatedDatesToUpdate = scheduleOverview?.getProgramPopulatedDates?.map(({ date, status }) => ({
      date: new Date(date),
      status
    })) || [];
    
    console.log(populatedDatesToUpdate, 'YEAAAAAAAH222');

    setPopulatedDatesOverview(populatedDatesToUpdate);
  }
}, [scheduleOverview]);

const removeTypenames = (value) => {
  if (Array.isArray(value)) {
    return value.map(item => removeTypenames(item));
  } else if (value !== null && typeof value === 'object') {
    const newObj = {};
    for (const [key, subValue] of Object.entries(value)) {
      if (key !== '__typename') {
        newObj[key] = removeTypenames(subValue);
      }
    }
    return newObj;
  }
  return value;
};

  const handleDateChange =  async (date) => {
    setIsLoading(true);
    getScheduleOverview({
      variables: { programId: programId, date: date },
    }).then( async () => {

    // remove _typename from all the fields and subfields
   
   const cleanedData = removeTypenames(specificDayData.getSpecificProgramPopulatedDate)

   setSelectedDate(date);
   setStartTime(cleanedData.startTime);
   setInterviewLength(cleanedData.interviewLength);
   setBreakTime(cleanedData.breakTime);
   setSlots(cleanedData.slots);
   setSlotsBool(cleanedData.slots.length!==0);
   setBreakBool(cleanedData.breakTime!==0);
   setInterviewersPerInterviewee(cleanedData.interviewersPerInterviewee);
   setNumberOfSlots(cleanedData.totalAvailableSlots[0].slotsNumber);
   setDayType(cleanedData.isRegularDay);
   setIsLocked(cleanedData.status===2);
   setNumOfInterviewers(cleanedData.numOfInterviewers);
  setStep(7);

   setCalColumns(generateStep3Columns(numOfInterviewers));
   setCalData(generateStep6Data(startTime, interviewLength, calColumns.length - 1, slots));
    }).catch((error) => {
      console.error(error);
      setError(error);
    }).finally(() => {
      console.log('calColumns', calColumns);
      console.log('calData', calData);


   setIsLoading(false);
    }
    );

  };

  const handleClicked = async () => {
  if (step === 0) {
    // check if the selected dates are already populated
    // const foundDates = populatedDatesOverview.filter(populatedDate => selectedDates.some(selectedDate => selectedDate.getTime() === populatedDate.date.getTime()));
    // if (foundDates.length > 0) {
      
    //   const {data: specificDateData, loading: specificDateLoading, error: specificDateError } = await client.query({
    //     query: getSpecificProgramPopulatedDateQuery,
    //     variables: { programId: programId, date: selectedDate.toISOString() },
    //   });
    //   if (specificDateData && specificDateData.getSpecificProgramPopulatedDate) {
    //     const specificDate = specificDateData.getSpecificProgramPopulatedDate;
    //     // clean up the slots array from typename and other unnecessary fields
    //     const cleanedSlots = specificDate.slots.map(slot => {
    //       const { __typename, ...rest } = slot;
    //       return rest;
    //     });
    //     setStartTime(specificDate.startTime);
    //     setInterviewLength(specificDate.interviewLength);
    //     setBreakTime(specificDate.breakTime);
    //     setSlots(specificDate.slots===null?[]:cleanedSlots);
    //     setSlotsBool(specificDate.slots.length!==0);
    //     setBreakBool(specificDate.breakTime!==0);
    //     setInterviewersPerInterviewee(specificDate.interviewersPerInterviewee);
    //     setNumOfInterviewers(specificDate.numOfInterviewers);
    //     setNumberOfSlots(specificDate.totalAvailableSlots[0].slotsNumber);
    //     setDayType(specificDate.isRegularDay);
    //     setIsLocked(specificDate.status===0);
    //   }

    //   setStep(1);
    //   setPage('progress');
    // }    
  }
  if(step!==8 && step!==9) {
    setStep(step+1);
    setPage('progress');
  }
  else {
    setIsLoading(true);
    // const input = {
    //   startTime: startTime,
    //   interviewLength: interviewLength,
    //   breakTime: breakBool?breakTime:0,
    //   slots: slotsBool?slots:[],
    //   interviewersPerInterviewee: interviewersPerInterviewee,
    //   totalAvailableSlots: [{level:1,slotsNumber:numberOfSlots}],
    //   currentAvailableSlots: [{level:1,slotsNumber:numberOfSlots}],
    //   isRegularDay: dayType,
    //   status: isLocked?0:1,
    //   dates: selectedDates,
    //   numOfInterviewers: numOfInterviewers,
    //   interviewersPerInterviewee: interviewersPerInterviewee,
    // };
    //   await sendUpdateMutation(input);
  }
  if (step===9) {
    resetValues();
  }
}



const sendUpdateMutation = async (input) => {
  try {
    const res = await updateProgramPopulatedDatesMutation({
      variables: {
        programId: programId,
        input: {
          populatedDates: input
        }
      }
    });
    if (res.data.updateProgramPopulatedDates) {
      setIsLoading(false);
      setStep(9);
      setPage('progress');
    }
  } catch (error) {
    console.error(error);
    setError(error);
  }
};

  
  const handleTimeClick = (time) => {
    setStartTime(time);
  };

  const handleInterviewersPerIntervieweeClick = (time) => {
    setInterviewersPerInterviewee(Math.max(0, time)); 
  }

  const handleNumberOfInterviewersClick = (time) => {
    setNumOfInterviewers(Math.max(0, time)); 
  }

  const handleNumberOfSlotsClick = (time) => {
    setNumberOfSlots(Math.max(0, time)); 
  }

  const handleInterviewTimeClick = (time) => {
    setInterviewLength(Math.max(0, time)); 
  }

  const handleBreakTimeChange = (e) => {
    setBreakTime(Math.max(0, parseInt(e.target.value)));
  }

const handleInterviewersPerIntervieweeChange = (e) => {
  setInterviewersPerInterviewee(Math.max(0, parseInt(e.target.value)));
}
const handleNumberOfInterviewersChange = (e) => {
  setNumOfInterviewers(Math.max(0, parseInt(e.target.value)));
}
const handleNumberOfSlotsChange = (e) => {
  setNumberOfSlots(Math.max(0, parseInt(e.target.value)));
}
const handleInterviewTimeChange = (e) => {
  setInterviewLength(Math.max(0, parseInt(e.target.value)));
}

const generateStep6Data = (startTime, interviewLength, interviewersCount, slots) => {
  const data = [];
  let currentTime = startTime;
  let currentIndex = 0;

  while (currentIndex < 10) { // Assuming you want to generate data for 10 slots by default
    let slotFound = slots.find(slot => 
      currentTime >= slot.startTime && currentTime < slot.endTime
    );

    if (slotFound) {
      // If the current time is within a special slot, add that slot to the data
      data.push({
        isFullWidth: true, // This will be used to span the row across all columns
        cellColor: slotFound.itemColor, // Use the color specified in the slot
        fontStyle: { fontWeight: "bold",
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '18px',
        letterSpacing: '0.51em',
        textAlign: 'center',
        textTransform: 'uppercase'
       }, // Example style, adjust as needed
        fullWidthContent: slotFound.item, // The content of the slot
        name: currentTime, // Keeping the first column with the start time
      });
      // Skip to the end time of the slot
      currentTime = sanitizeTimeFormat(slotFound.endTime);
      currentIndex++; // Move to the next index
    } else {
      // Generate a regular time slot
      const row = { time: currentTime };
      for (let j = 1; j <= interviewersCount; j++) {
        row[`R${j}`] = ''; // Initialize R keys with empty string
      }
      data.push(row);
      console.log(`Adding minutes to: ${currentTime}, ${interviewLength + breakTime}`);
      currentTime = AddMinutes(currentTime, interviewLength + breakTime); // Increment time for the next row
      currentIndex++;
    }
  }

  return data;
};

const generateStep3Columns = (interviewersCount) => {
  const columns = [
    {
      Header: '',
      accessor: 'time',
      id: ''
    }
  ];

  // Dynamically add "R" columns based on interviewersPerInterviewee
  for (let i = 1; i <= interviewersCount; i++) {
    columns.push({
      Header: `R${i}`,
      accessor: `R${i}`,
      id: ''
    });
  }

  return columns;
};



const handleDragOver = (e) => {
  e.preventDefault(); // This allows us to drop.
};

const handleDrop = (e) => {
  e.preventDefault();
  const userData = JSON.parse(e.dataTransfer.getData("application/json"));
  const existingColumnIndex = calColumns.findIndex(column => column.id === userData.userId.id);
  if (existingColumnIndex === -1) {
    let columnUpdated = false;
    const newColumns = calColumns.map((column, index) => {
      if (!columnUpdated && column.id === '' && index > 0) {
        columnUpdated = true; // Ensure only one column is updated
        return {
          Header: `${userData.userId.firstName} ${userData.userId.lastName}`,
          accessor: userData.userId.id,
          id: userData.userId.id
        };
      }
      return column;
    });
    setCalColumns(newColumns);
  }
  
};

    const month = selectedDate && selectedDate.toLocaleString('default', { month: 'long' }); // 'long' for full month name
    const day = selectedDate && selectedDate.getDate()
  
    return (
      <div onDragOver={handleDragOver} onDrop={handleDrop} style={{width: '100%', height: '100%'}}>
    <>
  {scheduleOverviewLoading && (
    <Spinner style={{alignSelf:'center'}}/>
  )}
  
{/* {step===0 && scheduleOverview && !scheduleOverviewError && !scheduleOverviewLoading&& ( */}
   { selectedDate === null ?
  <div className="calendar-container">
 
  <Calendar
  onChange={handleDateChange}
  value={null} // Not using selectedDates here since react-calendar doesn't support array of dates for value
  selectRange={false}
  minDetail="month"
  prev2Label={null}
  next2Label={null}
  tileClassName={({ date, view }) => {
    // Logic to gray out dates not in the current month
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    if (date.getTime() < today.getTime()) {
      return 'past-date'; 
    }
  
    // Existing logic to highlight selected dates
    // if (selectedDates.some(selectedDate => selectedDate.getTime() === date.getTime())) {
    //   return 'highlight'; // This class will highlight selected dates
    // }
    const foundDate = populatedDatesOverview.find(populatedDate => populatedDate.date.getTime() === date.getTime());
  if (foundDate) {
    // Apply different classes based on the status
    switch (foundDate.status) {
      case 0:
        return 'date-full';
      case 1:
        return 'date-available';
      case 2:
        return 'date-locked';
      default:
        return '';
    }
  }
  }}
/>
  {/* <button className="plan-button" disabled={selectedDates.length===0} style={{cursor:selectedDates.length===0?'not-allowed':'pointer', background: selectedDates.length===0 ? 'gray' : ''}} onClick={handleClicked}>Plan Interview Day</button> */}
  </div>
    :
    <div>
        <div style={{display:'flex', flexDirection:'row', float: 'right', marginRight:'1vw', marginBottom: '1vh'}}>
            <h2 style={{marginRight:'1vw'}}>
                <span style={{ color: '#145388', cursor: 'pointer', marginRight: '1vw', fontSize: '1rem'}}>{month} {day}</span> 
            </h2>
            <button style={{color:'#0032E1', textDecoration:'underline', fontSize: '1rem', cursor: 'pointer'}} onClick={()=>{setSelectedDate(null); setStep(0);}} >change</button>
            <button style={{color:'#0032E1', textDecoration:'underline', fontSize: '1rem', cursor: 'pointer'}} onClick={()=>console.log('locked',month,day)}>lock</button>
        </div>
        {/* <InterviewScheduleTable columns={step2Columns} data={step2Data}/> */}
    </div>
}

   {step===7 && (
    <>
  {isLoading && (
    <Spinner style={{alignSelf:'center'}}/>
  )}
  {!isLoading && (
    
  <InterviewScheduleTable setInterviewers={setInterviewers} columns={calColumns} data={calData} setCalColumns={setCalColumns} setCalData={setCalData}/>
  )}

  </>
)}
   
  
  
  
  




</>
</div>
  );
};

export default PlanInterviewCalendar;
