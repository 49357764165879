import React, { useState, useContext, useEffect } from 'react';
import logo from '../../../../assets/imgs/logo.svg'
import { GetProgramsBySpecialtyIdQuery, getMasterApplicationProgramsQuery } from '../graphql/queries';
import { updatePendingProgramsInMasterApplicationMutation, createApplicationsFromPendingProgramsMutation, updateShowSignalMessageMutation } from '../graphql/mutations';
import {useQuery, useMutation} from '@apollo/client';
import ProgramsListTable from '../ProgramsListTable';
import { Field, ErrorMessage } from 'formik';
import { FormContext } from '../ApplicationForm';
import Spinner from '../../../common/spinner';
import Select from 'react-select';


const ApplicationFormStep6 = ({ specialty, applicationData, applicant, setPage, setIsApplicationOpen, updatePhase, score, lorIdsToAdd, finishApplication, updateSelectedPrograms, selectedProgramsData, viewOnly }) => {
    const { setStep } = useContext(FormContext);
    const [programSelectionHeader, setProgramSelectionHeader] = useState('Program Selection')
    const [appliedPrograms, setAppliedPrograms] = useState(null)
    const [programSelectionButton, setProgramSelectionButton] = useState(finishApplication ? (appliedPrograms && appliedPrograms.length>0)?'Update and Submit Applications':'Submit Applications' : 'Continue For Program Signaling')
    const [selectionPhase, setSelectionPhase] = useState(0)
    const [allPrograms, setAllPrograms] = useState([]) //array for all optional programs
    const [allTracks, setAllTracks] = useState([]);
    const [pendingPrograms, setPendingPrograms] = useState(null)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [loadingCreateApplications, setLoadingCreateApplications] = useState(false);
    const [loadingData, setLoadingData] = useState(false)
    const [success, setSuccess] = useState(false);
    const [tracksData, setTracksData] = useState(null);
    const [showSignalModal, setShowSignalModal] = useState(false);
    const [signalTypeOptions, setSignalTypeOptions] = useState([
        { value: 'Gold', label: 'Gold' },
        { value: 'Silver', label: 'Silver' },
        { value: '', label: 'Reset' },
    ]);
    const [originalSignalTypeOptions, setOriginalSignalTypeOptions] = useState([
        { value: 'Gold', label: 'Gold' },
        { value: 'Silver', label: 'Silver' },
        { value: '', label: 'Reset' },
    ]);

    const signalGoldMaxCount = 5
    const signalSilverMaxCount = 10

    // mutations
    const [updatePendingPrograms] = useMutation(updatePendingProgramsInMasterApplicationMutation);
    const [createApplications] = useMutation(createApplicationsFromPendingProgramsMutation);
    const [updateShowSignalMessage] = useMutation(updateShowSignalMessageMutation)


    // get all optional program to choose from
    const {loading: loadingAllPrograms, error: errorAllProgram, data: allProgramsData, refetch: refetchAllPrograms } = useQuery(GetProgramsBySpecialtyIdQuery, { variables: {
        specialtyId: applicationData.specialtyId
    }, fetchPolicy: 'network-only'
}) 

    // get pending and applied applications from masterApplication
    const {loading: loadingMasterAppPrograms, error: errorMasterAppPrograms, data: masterAppProgramsData, refetch: refetchMasterAppPrograms } = useQuery(getMasterApplicationProgramsQuery, { variables: {
        userId: applicationData.userId,
        specialtyId: applicationData.specialtyId
    }, fetchPolicy: 'network-only'
})


    useEffect(() => {
        if(!loadingAllPrograms && !errorAllProgram && allProgramsData) {
            const data = allProgramsData.getProgramsBySpecialtyId.map(elem => {
                return {
                  ...elem,
                  isChecked: false
                };
            });
            console.log("data => ", data)
            setAllPrograms(data)
            // add tracks to array
            let programTracks = []
            data.forEach(program => {
                if(program.programTracks !== null && program.programTracks !== undefined) {
                    programTracks = [...programTracks, ...program.programTracks]
                }
            })
            // programTracks = [...new Set(programTracks)]
            console.log("programTracks =>", programTracks)
            setAllTracks(programTracks)
        }
    }, [loadingAllPrograms, errorAllProgram, allProgramsData])




    useEffect(() => {
        if(!loadingMasterAppPrograms && !errorMasterAppPrograms && masterAppProgramsData) {
            const masterApplicationProgramsData = masterAppProgramsData.getMasterApplicationByUserIdAndSpecialtyId
            console.log("masterApplicationProgramsData => ", masterApplicationProgramsData)
            setLoadingData(true)
            if(pendingPrograms === null) {
                setPendingPrograms(masterApplicationProgramsData.pendingPrograms)
                getTracksFromPrograms(masterApplicationProgramsData.pendingPrograms, masterApplicationProgramsData.appliedPrograms)
            }
            if(appliedPrograms === null) { 
                setAppliedPrograms(masterApplicationProgramsData.appliedPrograms)
                getTracksFromPrograms(masterApplicationProgramsData.pendingPrograms, masterApplicationProgramsData.appliedPrograms)
            }

            if(pendingPrograms === null || appliedPrograms === null) {
                const combined = [...masterApplicationProgramsData.pendingPrograms, ...masterApplicationProgramsData.appliedPrograms]
                const goldCount = [...combined].filter(elem => elem.signalType === 'Gold').length
                const silverCount = [...combined].filter(elem => elem.signalType === 'Silver').length
                console.log("type count =>", goldCount, silverCount)
                var signalTypeOptionsCpy = [...signalTypeOptions]
                if(goldCount >= signalGoldMaxCount) signalTypeOptionsCpy = signalTypeOptionsCpy.filter(elem => elem.value !== 'Gold')
                if(silverCount >= signalSilverMaxCount) signalTypeOptionsCpy = signalTypeOptionsCpy.filter(elem => elem.value !== 'Silver')
                console.log("signalTypeOptionsCpy =>", signalTypeOptionsCpy)
                setSignalTypeOptions(signalTypeOptionsCpy)
            }


          
           


            var allProgramsCpy = [...allPrograms]
            console.log("allProgramsCpy =>", allProgramsCpy.length)
            console.log('masterApplicationProgramsData.pendingPrograms.length =>', masterApplicationProgramsData.pendingPrograms.length)
            if(allProgramsCpy.length > 0){
                const updatedPrograms = allProgramsCpy.map(elem => {
                    const existsInPending = pendingPrograms && pendingPrograms.some(program => program.programId.id.toString() === elem.id.toString());
                    const existsInApplied = appliedPrograms && appliedPrograms.some(program => program.programId.id.toString() === elem.id.toString());
                    // remove the following programs:
                    
                    
                
                    console.log("exists =>", elem, existsInPending, existsInApplied);
                
                    const obj = {
                      ...elem,
                      isChecked: existsInPending || existsInApplied ? true : false
                    };

                    
                    console.log("obj =>", obj)
                    return obj
                });
                const hcaPrograms =[
                    '66b92660ecb46a0d2f23ef78',
                    '66b9268decb46a0d2f2413cc',
                    '66b926b219c913b4357f8dc6',
                    '66b926d46c2cf7887839a03c',
                    '66b926f519c913b4357fdd81',
                    '66b927186c2cf788783a2004',
                    '66b9272fecb46a0d2f2499a1',
                    '66b927445ec92576f96129a6',
                    '66b927b36c2cf788783b90f0',
                ]
                const updatedProgramsFiltered = updatedPrograms.filter(elem => !hcaPrograms.includes(elem.id))

                if(JSON.stringify(updatedPrograms) !== JSON.stringify(allPrograms)) setAllPrograms(updatedProgramsFiltered)
                setLoadingData(false)
            }
          

            
        }
    }, [loadingMasterAppPrograms, errorMasterAppPrograms, masterAppProgramsData, allPrograms, pendingPrograms, appliedPrograms])


    useEffect(() => {
        console.log("allPrograms changed!", allPrograms)
    }, [allPrograms])



    useEffect(() => {
        if (selectionPhase===0 && !finishApplication){
            setProgramSelectionHeader('Program Selection');
            setProgramSelectionButton('Continue to Program Signaling');
        }
        if (selectionPhase===1 && !finishApplication){
            if(allTracks.length === 0) {
                setProgramSelectionHeader('Program Tracks');
                setProgramSelectionButton('Continue to Signal Statements');
            } else {
                setProgramSelectionHeader('Program Signaling');
                setProgramSelectionButton('Continue to Signal Statements');
            }
        }
        if (selectionPhase===2 && !finishApplication){
            if(allTracks.length === 0) {
                setProgramSelectionHeader('Signal Statements');
                setProgramSelectionButton('Continue to Payment');
            } else {
                setProgramSelectionHeader('Program Signaling');
                setProgramSelectionButton('Continue to Signal Statements');
            }
        }
    }, [selectionPhase, finishApplication])



    // on check in all programs table
    const handleCheck = (program) => {
        const pendingIdx = pendingPrograms.findIndex(elem => elem.programId.id.toString() === program.id.toString())
        if(pendingIdx > -1) {
            // remove from pendingPrograms
            console.log("pendingIdx =>", pendingIdx)
            var pendingProgramsCpy = [...pendingPrograms]
            pendingProgramsCpy.splice(pendingIdx,1)
            console.log("pendingProgramsCpy => ", pendingProgramsCpy)
            setPendingPrograms(pendingProgramsCpy)
        }  else {
            const programToAdd = allPrograms.filter(elem => elem.id.toString() === program.id.toString())[0]
            var pendingProgramsCpy = [...pendingPrograms]
            // build allTracks object
            var allTracks = []
            programToAdd.programTracks.forEach(elem => allTracks.push({ programName: program.name, trackName: elem }))
            console.log("allTracks =>", allTracks)

            console.log("programToAdd =>", programToAdd)
            pendingProgramsCpy.push({
                programId: { id: programToAdd.id },
                isSignal: false,
                signalEssay: "",
                signalType: "",
                name: programToAdd.name,
                allTracks,
                selectedTracks: []
            })

            setPendingPrograms(pendingProgramsCpy)

        }
    }

    // on check in signal table
    const handleSignalCheck = (program) => {
        const programIdx = pendingPrograms.findIndex(elem => elem.programId.id.toString() === program.programId.id.toString())
        const pendingProgramsCpy = [...pendingPrograms]

        if(specialty.name.includes("Plastic Surgery") && program.isSignal === false){
            // limit to 10 for signaling phase
            const selectedSignals = pendingPrograms.filter(elem => elem.isSignal === true).length;
            console.log("selectedSignals =>", selectedSignals)
            if(selectedSignals >= 10 ) return;
        } 

        console.log("pendingProgramsCpy[programIdx] =>", pendingProgramsCpy[programIdx])
        pendingProgramsCpy[programIdx] = {
            ...pendingProgramsCpy[programIdx],
            isSignal: !pendingProgramsCpy[programIdx].isSignal
        };
        setPendingPrograms(pendingProgramsCpy)
    }

   
    

    const handleTrackCheck = (track) => {
        console.log("track =>", track, tracksData)

        // edit track in tracks array
        const trackIdx = tracksData.findIndex(elem => elem.name === track.name && elem.programName === track.programName)
        console.log("trackIdx =>", trackIdx)
        var tracksCpy = [...tracksData]
        const trackOldStatus = tracksCpy[trackIdx].isChecked
        tracksCpy[trackIdx].isChecked = !tracksCpy[trackIdx].isChecked
        setTracksData(tracksCpy)

        console.log("trackOldStatus =>", trackOldStatus)

        // edit program in pendingPrograms

        var programWithTrack = pendingPrograms.filter(program => {
            return program.allTracks.filter(elem => track.programName === program.name && elem.trackName === track.name).length > 0
        })

        console.log("programWithTrack =>", programWithTrack)
        if(programWithTrack.length === 0) return;
        else {
            programWithTrack = programWithTrack[0]

            // check if track already exists in selectedTracks
            const selectedTracks = [...programWithTrack.selectedTracks]
            console.log("selectedTracks =>", selectedTracks)
            const itemExists = selectedTracks.filter(elem => elem.trackName === track.name).length > 0
            if(itemExists) {
                // remove from selectedTracks
                selectedTracks.splice(selectedTracks.findIndex(elem => elem.trackName === track.name), 1)
            } else {
                // add to selectedTracks
                selectedTracks.push({ trackName: track.name, programName: programWithTrack.name })
            }

            programWithTrack.selectedTracks = selectedTracks

            // update selecetdProgram in pending programs
            const idx = pendingPrograms.findIndex(program => program.name === track.programName)
            console.log("idx of pending program =>", idx)
            var pendingProgramsCpy = [...pendingPrograms]
            pendingProgramsCpy[idx] = programWithTrack
            console.log("pendingProgramsCpy =>", pendingProgramsCpy)
            setPendingPrograms(pendingProgramsCpy)
        }







        // programsWithSpecificTrack.forEach(program => {
        //     var selectedTracks = [...program.selectedTracks]
        //     if(selectedTracks === null || selectedTracks === undefined) {
        //         var selectedTracks = [track.name]
        //         program.selectedTracks = selectedTracks
        //         return program 
        //     } else {
        //         const trackExists = selectedTracks.findIndex(elem => elem === track.name) > -1
        //         if(trackExists) {
        //             // track exists  - pull from array
        //             selectedTracks.splice(selectedTracks.findIndex(elem => elem === track.name), 1)
        //         } else {
        //             // track not exists - push to array
        //             selectedTracks.push(track.name)
        //         }
        //         program.selectedTracks = selectedTracks
        //         return program 
        //     }
        // })

        // console.log("programsWithSpecificTrack =>", programsWithSpecificTrack)
        // setPendingPrograms(programsWithSpecificTrack)


    }


    const updateEssayInProgram = (event, program) => {
        const value = event.target.value;
        console.log("value to update =>", value);
      
        // Create a new copy of the pending programs array
        var pendingProgramsCpy = pendingPrograms.map(p => ({ ...p }));
      
        // Find the index of the program to update
        const programIdx = pendingProgramsCpy.findIndex(
          elem => elem.programId.id.toString() === program.programId.id.toString()
        );
        console.log("programIdx =>", pendingProgramsCpy, programIdx);
      
        // Update the signalEssay property of the found program
        if (programIdx !== -1) {
          pendingProgramsCpy[programIdx].signalEssay = value;
        }
      
        console.log("pendingProgramsCpy after change =>", pendingProgramsCpy);
      
        // Update the state with the new array
        setPendingPrograms(pendingProgramsCpy);
      };

      const updateSignalTypeInProgram = (option, program) => {
          console.log("update signal type in program =>", option, program)

        // Create a new copy of the pending programs array
        var pendingProgramsCpy = pendingPrograms.map(p => ({ ...p }));

      
        // Find the index of the program to update
        const programIdx = pendingProgramsCpy.findIndex(
          elem => elem.programId.id.toString() === program.programId.id.toString()
        );
        console.log("programIdx =>", pendingProgramsCpy, programIdx);
      
        // Update the signalType property of the found program
        if (programIdx !== -1) {
          pendingProgramsCpy[programIdx].signalType = option.value;
        }
      
        console.log("pendingProgramsCpy after change =>", pendingProgramsCpy);
      
        // Update the state with the new array
        setPendingPrograms(pendingProgramsCpy);


        // // check if arrive to limit - gold = 5, silver = 10. if arrive to limit - remove from options 
        const goldCount = pendingProgramsCpy.filter(elem => elem.signalType === 'Gold').length
        const silverCount = pendingProgramsCpy.filter(elem => elem.signalType === 'Silver').length
        console.log("type count =>", goldCount, silverCount)
        var signalTypeOptionsCpy = [...signalTypeOptions]
        if(goldCount >= signalGoldMaxCount) signalTypeOptionsCpy = signalTypeOptionsCpy.filter(elem => elem.value !== 'Gold')
        else {
            if(option.label === "Reset") {
                // check if gold exists - if not add it 
                const goldExists = signalTypeOptionsCpy.filter(elem => elem.value === 'Gold').length > 0
                if(!goldExists) {
                    signalTypeOptionsCpy.push({ value: 'Gold', label: 'Gold'})
                }
            }
        }
        if(silverCount >= signalSilverMaxCount) signalTypeOptionsCpy = signalTypeOptionsCpy.filter(elem => elem.value !== 'Silver')
        else {
            if(option.label === "Reset") {
                // check if silver exists - if not add it 
                const silverExists = signalTypeOptionsCpy.filter(elem => elem.value === 'Silver').length > 0
                console.log("silverExists =>", silverExists)
                if(!silverExists) {
                    signalTypeOptionsCpy.push({ value: 'Silver', label: 'Silver'})
                }
            } 
        }
        console.log("signalTypeOptionsCpy =>", signalTypeOptionsCpy)
        setSignalTypeOptions(signalTypeOptionsCpy)

      }


    // click on first table button
    const continueToSignaling = () => {
        if(specialty.name === "Anesthesiology") {
            if(applicationData.showSignalMessage === null || applicationData.showSignalMessage === undefined || (
                applicationData.showSignalMessage !== null && applicationData.showSignalMessage !== undefined &&
                applicationData.showSignalMessage === true
            )) {
                setShowSignalModal(true)
            }
        }
            if(allTracks.length > 0) setSelectionPhase(2)
            else setSelectionPhase(1)
            var selectedPrograms = allPrograms.filter(elem => elem.isChecked === true )
            var pendingProgramsNew = []
            // remove from selectedPrograms applied programs
            console.log("appliedPrograms =>", appliedPrograms, selectedPrograms)
            selectedPrograms = selectedPrograms.filter(elem => appliedPrograms.filter(elemSecond => elem.id.toString() === elemSecond.programId.id.toString()).length === 0)
    
    
            selectedPrograms.forEach(program => {
    
                // build allTracks object
                var allTracks = []
                program.programTracks.forEach(elem => allTracks.push({ programName: program.name, trackName: elem }))
                console.log("allTracks =>", allTracks)
    
    
    
                pendingProgramsNew.push({
                    programId: {id: program.id},
                    name: program.name,
                    isSignal: pendingPrograms.filter(elem => elem.programId.id.toString() === program.id.toString()).length > 0 ? pendingPrograms.filter(elem => elem.programId.id.toString() === program.id.toString())[0].isSignal : false,
                    signalEssay: pendingPrograms.filter(elem => elem.programId.id.toString() === program.id.toString()).length > 0 ? pendingPrograms.filter(elem => elem.programId.id.toString() === program.id.toString())[0].signalEssay : "",
                    signalType: pendingPrograms.filter(elem => elem.programId.id.toString() === program.id.toString()).length > 0 ? pendingPrograms.filter(elem => elem.programId.id.toString() === program.id.toString())[0].signalType: "",
                    selectedTracks: pendingPrograms.filter(elem => elem.programId.id.toString() === program.id.toString()).length > 0 && pendingPrograms.filter(elem => elem.programId.id.toString() === program.id.toString())[0].selectedTracks ? pendingPrograms.filter(elem => elem.programId.id.toString() === program.id.toString())[0].selectedTracks :  [],
                    allTracks
                })
            })
            setPendingPrograms(pendingProgramsNew)
    }


    // click on first table button when tracks exists
    const continueToTracks = () => {
        setSelectionPhase(1)
        var selectedPrograms = allPrograms.filter(elem => elem.isChecked === true )
        var pendingProgramsNew = []
        // remove from selectedPrograms applied programs
        console.log("appliedPrograms =>", appliedPrograms, selectedPrograms)
        selectedPrograms = selectedPrograms.filter(elem => appliedPrograms.filter(elemSecond => elem.id.toString() === elemSecond.programId.id.toString()).length === 0)

        selectedPrograms.forEach(program => {
            console.log("program.programTracks =>", program.programTracks)
            // build allTracks object
            var allTracks = []
            program.programTracks.forEach(elem => allTracks.push({ programName: program.name, trackName: elem }))
            console.log("allTracks =>", allTracks)




            pendingProgramsNew.push({
                programId: {id: program.id},
                name: program.name,
                isSignal: pendingPrograms.filter(elem => elem.programId.id.toString() === program.id.toString()).length > 0 ? pendingPrograms.filter(elem => elem.programId.id.toString() === program.id.toString())[0].isSignal : false,
                signalEssay: pendingPrograms.filter(elem => elem.programId.id.toString() === program.id.toString()).length > 0 ? pendingPrograms.filter(elem => elem.programId.id.toString() === program.id.toString())[0].signalEssay : "",
                signalType: pendingPrograms.filter(elem => elem.programId.id.toString() === program.id.toString()).length > 0 ? pendingPrograms.filter(elem => elem.programId.id.toString() === program.id.toString())[0].signalType: "",
                selectedTracks: pendingPrograms.filter(elem => elem.programId.id.toString() === program.id.toString()).length > 0 && pendingPrograms.filter(elem => elem.programId.id.toString() === program.id.toString())[0].selectedTracks ? pendingPrograms.filter(elem => elem.programId.id.toString() === program.id.toString())[0].selectedTracks :  [],
                allTracks
            })
        })
        console.log("pendingProgramsNew =>", pendingProgramsNew)
        setPendingPrograms(pendingProgramsNew)
        getTracksFromPrograms(pendingPrograms, appliedPrograms)
    }





    const continueToStatement = () => {
        console.log("applicationData.showSignalMessage= >", applicationData.showSignalMessage)
        if(allTracks.length > 0) setSelectionPhase(3)
        else setSelectionPhase(2)
        console.log("pendingPrograms =>", pendingPrograms)
    }

    const countWords = (input) =>  {
        if (input) {
          const words = input.trim().split(/\s+/);
          return words.length;
        }
        return 0;
    }

    const continuePayment = () => {
        console.log("updatePendingPrograms =>", pendingPrograms)
        updatePendingPrograms({
            variables:{
                id: applicationData.id,
                pendingPrograms: JSON.stringify(pendingPrograms)
            }
        })
        .then(res => {
            console.log("pending applications updated!")
            refetchMasterAppPrograms()
            setStep(8);
            if(applicationData.sixthPhase === false) updatePhase("sixthPhase")

        })
    }

    const savePendingApplications = () => {
        updatePendingPrograms({
            variables:{
                id: applicationData.id,
                pendingPrograms: JSON.stringify(pendingPrograms)
            }
        })
        .then(res => {
            console.log("pending applications updated!")
            refetchMasterAppPrograms()
            setSuccess(true)
            setTimeout(() => {
                setSuccess(false)
            } , [5000])
        })
    }

    const submitApplications = () => {
        setLoadingCreateApplications(true)
        const id = applicationData.id
        console.log("data to send mutation =>", id, pendingPrograms, score.id, lorIdsToAdd)
        createApplications({
            variables: {
                id: applicationData.id,
                pendingPrograms: JSON.stringify(pendingPrograms),
                scoreId: score.id,
                lorIdsToAdd
            }
        })
        .then(res => {
            setConfirmModalOpen(true)
            setLoadingCreateApplications(false)
            console.log("applicationData.seventhPhase =>",applicationData, applicationData.seventhPhase) 
            if(applicationData.seventhPhase === false) updatePhase("seventhPhase")
        })
    }


    const getTracksFromPrograms = (pendingProgramsData, appliedProgramsData) => {
        console.log("pendingProgramsData, appliedProgramsData", pendingProgramsData, appliedProgramsData)
        var tracksOptions = []
        var tracksWithProgramNames = []
        const allSelectedPrograms = [...pendingProgramsData, ...appliedProgramsData]

        if(allSelectedPrograms.length  === 0) {
            return;
        } else {
            allSelectedPrograms.forEach(program => {
                console.log("program inside getTracks =>", program.allTracks)
                if(program.allTracks === undefined || program.allTracks === null || (program.allTracks !== undefined && program.allTracks !== null && program.allTracks.length === 0)) {
                    // tracks not exists in pending program - get tracks from allPrograms (Program instance)
                    const id = program.programId.id
                    console.log("id =>", id)
                    const selectedProgram = allPrograms.filter(elem => elem.id.toString() === id.toString()).length > 0 ? allPrograms.filter(elem => elem.id.toString() === id.toString())[0] : null
                    
                    console.log("selectedProgram in getTrack =>", selectedProgram)
                    // add program data to tracksWithProgramNames array
                    if(selectedProgram) {
                        const programTracksNames = selectedProgram.programTracks
                        programTracksNames.forEach(trackName => tracksWithProgramNames.push({
                            trackName,
                            programName: selectedProgram.name
                        }))
                    } else return //program not exists in allPrograms array
                } else {
                    // tracks exists in pendingProgram
                    const first = program.allTracks[0]

                    if(typeof first === 'string') {
                        // modify tracks
                        program.allTracks.forEach(track => tracksWithProgramNames.push({
                            trackName: track,
                            programName: program.name
                        }))
                    } else {
                        // object form
                        tracksWithProgramNames = [...tracksWithProgramNames, ...program.allTracks]
                    }
                }
            })
            console.log("tracksWithProgramNames =>", tracksWithProgramNames)

            console.log("pendingProgramsData =>", pendingProgramsData)
            tracksWithProgramNames.forEach(track => {
                // check if track already exists in selectedTracks in program
                const programWithSpecificTrack = pendingProgramsData.filter(pendingProgram => {
                    const selectedTracks = pendingProgram.selectedTracks
                    console.log("selectedTracks =>", selectedTracks)
                    const existsInSelectedTracks = selectedTracks.filter(elem => elem.programName === track.programName && elem.trackName === track.trackName).length > 0
                    console.log("existsInSelectedTracks =>", existsInSelectedTracks)
                    return existsInSelectedTracks
                })

                const programWithSpecificTrackApplied = appliedProgramsData.filter(appliedProgram => {
                    const selectedTracks = appliedProgram.selectedTracks
                    console.log("selectedTracks =>", selectedTracks)
                    const existsInSelectedTracks = selectedTracks.filter(elem => elem.programName === track.programName && elem.trackName === track.trackName).length > 0
                    console.log("existsInSelectedTracks =>", existsInSelectedTracks)
                    return existsInSelectedTracks
                })

                console.log("programWithSpecificTrackApplied =>", programWithSpecificTrackApplied)

                if(programWithSpecificTrack.length > 0 || programWithSpecificTrackApplied.length > 0) {
                    tracksOptions.push({
                        name: track.trackName,
                        programName: track.programName,
                        isChecked: true
                    })
                } else {
                    tracksOptions.push({
                        name: track.trackName,
                        programName: track.programName,
                        isChecked: false
                    })
                }



            })




            console.log("tracksOptions =>", tracksOptions)
            setTracksData(tracksOptions)


    
        }









        


    }





    // all programs columns
    const columns = [
        {
            Header: 'Select',
            accessor: 'select',
            Cell: ({ row }) => (
                <input 
                    type="checkbox" 
                    disabled={appliedPrograms && appliedPrograms.filter(elem => elem.programId.id.toString() === row.original.id).length > 0}
                    checked={row.original.isChecked} 
                    onChange={() => handleCheck(row.original)}
                    style={{accentColor:'#145388'}}
                />
            ),
        },
        {
            Header: 'Program Name',
            accessor: 'name',
        },
        {
            Header: 'Learn More',
            accessor: 'learnMore',
            Cell: ({ cell: { value }, row }) => (
                appliedPrograms && appliedPrograms.filter(elem => elem.programId.id.toString() === row.original.id).length === 0 ?
                pendingPrograms && pendingPrograms.filter(elem => elem.programId.id.toString() === row.original.id).length > 0 ? 
                (
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                    <p style={{color:'black',marginRight:'6vw'}}> Already selected</p>
                    <button style={{color:'#145388', position:'relative', fontWeight:'800', marginRight:'6vw'}}>Withdraw</button>
                    </div>
    
                )
                :
                (
                
              <a href={value} target="_blank" rel="noopener noreferrer" style={{color:'#145388',float:'right',marginRight:'6vw'}}>
                Learn More
              </a>
                ):(
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                    <p style={{color:'black',marginRight:'6vw'}}> Already applied</p>
                    <button style={{color:'#145388', position:'relative', fontWeight:'800', marginRight:'6vw'}}>Withdraw</button>
                    </div>
    
                )
            ),
          }
    ]

    // tracks columns
    const tracksColumns = [
        {
            Header: 'Select',
            accessor: 'select',
            Cell: ({ row }) => (
                <input 
                    type="checkbox" 
                    checked={row.original.isChecked}                      
                    disabled={appliedPrograms.filter(program =>  {
                        console.log("program.selectedTracks ->", program.selectedTracks, row.original)
                        return program.selectedTracks.filter(elem => elem.programName === row.original.programName && elem.trackName ===  row.original.name).length > 0}).length >0 }
                    style={{accentColor: appliedPrograms.filter(program => program.selectedTracks.filter(elem => elem.programName === row.original.programName && elem.trackName ===  row.original.name).length > 0).length >0 ? '#8f8686' : '#145388'}}
                    onChange={() => handleTrackCheck(row.original)}
                />
            ),
        }, 
        {
            Header: 'Track Name',
            accessor: 'name',
            Cell: ({ row }) => (<label>{row.original.programName} - {row.original.name}</label>)
        }
    ]


    // signal anes programs columns
    const signalAnesColumns  = [
        {
            Header: 'Select',
            accessor: 'select',
            Cell: ({ row }) => (
                <input 
                    type="checkbox" 
                    checked={
                        [...pendingPrograms, ...appliedPrograms].filter(elem => {
                            return elem.programId.id.toString() === row.original.programId.id.toString()}).length > 0 ? 
                        [...pendingPrograms, ...appliedPrograms].filter(elem => elem.programId.id.toString() === row.original.programId.id.toString())[0].isSignal 
                        : false
                     } 
                    disabled={appliedPrograms.length > 0}
                    style={{accentColor: appliedPrograms.filter(elem => elem.programId.id.toString() === row.original.programId.id.toString()).length > 0 ? '#8f8686' : '#145388'}}
                    onChange={() => handleSignalCheck(row.original)}
                />
            ),
        },
        {
            Header: 'Program Name',
            accessor: 'name',
        },
        {
            Header: 'Signal Type',
            accessor: 'signalType',
            Cell: ({ row }) => (
                <Select 
                    placeholder="Select Signal Type"
                    name="signalType"
                    value={row.original.signalType === undefined || row.original.signalType === null || (row.original.signalType !== undefined && row.original.signalType !== null && row.original.signalType === "") ? "" : originalSignalTypeOptions.filter(elem => elem.value === row.original.signalType)}
                    isDisabled={!row.original.isSignal || appliedPrograms.filter(elem => elem.programId.id.toString() === row.original.programId.id.toString()).length > 0 }
                    options={signalTypeOptions}
                    onChange={(option) => {
                        updateSignalTypeInProgram(option, row.original)
                    }}
                    styles={{
                        menu: (provided) => ({
                            ...provided,
                            zIndex: '20'
                            }),
                          control: (provided) => ({
                            ...provided,
                            width: '100%',
                            // height: '9vh',
                            boxSizing: 'border-box',
                            color: '#414141',
                            background: 'linear-gradient(0deg, #f2f2f2, #f2f2f2), #fff',
                            border: '1px solid #dbdbdb',
                            borderRadius: '8px',
                            outline: 'none',
                            fontFamily: 'Montserrat',
                            fontSize: '15px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '30px',
                            zIndex:'10',
                            position:'relative',
                            overflowY: 'scroll',
                            margin: '0.5rem',
                            marginLeft: '0'
                            
                          }),
                          option: (provided) => ({
                            ...provided,
                            zIndex:'10',
                            position:'relative'
                            
                          }),
                          indicatorsContainer: (provided) => ({
                            ...provided,
                            height: '8vh'
                          })
                        }}
                />
            )
        },
        {
            Header: 'Learn More',
            accessor: 'learnMore',
            Cell: ({ cell: { value }, row }) => (
                !row.original.isSignal?(
              <a href={value} target="_blank" rel="noopener noreferrer" style={{color:'#145388',float:'right',marginRight:'6vw'}}>
                Learn More
              </a>
                ):(
                    <p style={{color:'#145388',marginRight:'6vw', fontWeight:'700', fontSize:'2vh', float:'right'}}>Signaled</p>
                )
            ),
          },
    ]

    // signal programs columns
    const signalColumns = [
        {
            Header: 'Select',
            accessor: 'select',
            Cell: ({ row }) => (
                <input 
                    type="checkbox" 
                    checked={
                        [...pendingPrograms, ...appliedPrograms].filter(elem => {
                            return elem.programId.id.toString() === row.original.programId.id.toString()}).length > 0 ? 
                        [...pendingPrograms, ...appliedPrograms].filter(elem => elem.programId.id.toString() === row.original.programId.id.toString())[0].isSignal 
                        : false
                     } 
                    disabled={appliedPrograms.length > 0}
                    style={{accentColor: appliedPrograms.filter(elem => elem.programId.id.toString() === row.original.programId.id.toString()).length > 0 ? '#8f8686' : '#145388'}}
                    onChange={() => handleSignalCheck(row.original)}
                />
            ),
        },
        {
            Header: 'Program Name',
            accessor: 'name',
        },
        {
            Header: 'Learn More',
            accessor: 'learnMore',
            Cell: ({ cell: { value }, row }) => (
                !row.original.isSignal?(
              <a href={value} target="_blank" rel="noopener noreferrer" style={{color:'#145388',float:'right',marginRight:'6vw'}}>
                Learn More
              </a>
                ):(
                    <p style={{color:'#145388',marginRight:'6vw', fontWeight:'700', fontSize:'2vh', float:'right'}}>Signaled</p>
                )
            ),
          },
    ]

   

    const updateAcceptSignal = () => {
        setShowSignalModal(false)
        updateShowSignalMessage({
            variables: {
                id: applicationData.id
            }
        })
        .then(res => {
            refetchMasterAppPrograms()
            if(allTracks.length > 0) setSelectionPhase(3)
            else setSelectionPhase(2)
            console.log("pendingPrograms =>", pendingPrograms)

        })

    }






    return(
        <div>
            <div className="program-selection-header" style={{marginBottom:'0'}}>
                { success && <div style={{display: 'flex', justifyContent: 'flex-end', color: "#145388"}}><label>Program selection saved!</label></div>}
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '2vh'}}>
                { !viewOnly && <h2 style={{fontFamily:'Montserrat', fontSize:'4vh', marginBottom: '2vh'}}>{programSelectionHeader} </h2>}
                { finishApplication === false && <button className='green-button' onClick={savePendingApplications}>Save</button>}
                </div>
                { loadingData &&  <Spinner/>}
                {
                    specialty && specialty.name === "Anesthesiology" ?
                    <div style={{fontFamily:'Montserrat', textAlign:'justify', marginBottom:'3vh'}}>
                        <label>Here you can select programs you would like to signal.  You have 15 signals -- 5 gold and 10 silver. <span style={{fontWeight: 'bold', textDecoration: 'underline'}}>These signals must match with ERAS exactly.</span> On the following page, you will also be asked to provide a 100 word signal statement to each of those programs describing your interest.</label>
                        <br/>
                        <br/>
                        <label style={{fontWeight:'600', color:'#145388'}}>Please note that once you submit your application, you will not be able to add signals, or change pre-existing signals</label>
                        <br/>
                        <br/>
                        <label><span style={{fontWeight: 'bold', textDecoration: 'underline'}}>Signalling Honor Code:</span> As an applicant for anesthesiology residency programs using CentralApp, I hereby commit to the following honor code regarding the signaling process:</label>
                        <br/>
                        <label><span style={{fontWeight: 'bold', textDecoration: 'underline'}}>1. Concordant Signaling Requirement:</span> I understand that I must signal my interest in specific anesthesiology programs in both CentralApp and ERAS. I acknowledge that any program I signal in CentralApp must also be signaled in ERAS, and vice versa. I will ensure that the signals I submit through both platforms are identical and consistent.</label>
                        <br/>
                        <label><span style={{fontWeight: 'bold', textDecoration: 'underline'}}>2. Honor Code Commitment:</span> I recognize that the signaling process is based on an honor code, and I pledge to act with honesty and integrity. I commit to accurately reporting my signals and not engaging in any actions that would result in discordant signaling between CentralApp and ERAS.</label>
                        <br/>
                        <label><span style={{fontWeight: 'bold', textDecoration: 'underline'}}>3. Verification and Compliance:</span> I am aware that CentralApp will automatically cross-reference the signals submitted through CentralApp and ERAS. I understand that any discrepancies between my signals in CentralApp and ERAS will be considered a violation of this honor code.</label>
                        <br/>
                        <label><span style={{fontWeight: 'bold', textDecoration: 'underline'}}>4. Consequences of Violation:</span> I accept that any discordant signaling will result in my being reported to all participating programs in CentralApp. I acknowledge that, if found in violation of this honor code, I may forfeit further review by the program(s) I falsely signaled. I am aware that violations of this honor code may have serious implications for my residency application process and professional reputation.</label>
                        <br/>
                    </div>
                    :
                    <p style={{fontFamily:'Montserrat', textAlign:'justify', marginBottom:'3vh'}}>Here you can select which programs you would like to send your plastic surgery application to. On the second screen, you may choose 10 of those programs to signal. You will also be asked to provide a 100 word signal statement to each of those programs describing your interest.</p>

                }
                
                {/* top header for select tables */}
                <div className="step-indicators-container" 
                    style={{
                        justifyContent:'center',
                        gap:'8vw',
                        background:'linear-gradient(96.75deg, #2a93d5 0%, #596173 133.3%)',
                        marginBottom:'0',
                        height:'5vh',
                        paddingBottom:'1vh',
                        }}>
                    <div className={selectionPhase === 0 ? "step-indicator-group" : "step-indicator-group-inactive"}
                        style={{margin:'0'}} 
                        onClick={()=>{setSelectionPhase(0)}}>
                            <div className="step-indicator-text"
                            style={{
                                color:'inherit',
                                margin:'0',
                                fontSize:'large',
                                fontWeight:'bold'
                            }}>Program Selection</div>
                        </div>
                        
                        {
                            allTracks.length > 0 && specialty && specialty.name === "Anesthesiology" ?
                            <div className={(selectionPhase === 1) ? "step-indicator-group" : "step-indicator-group-inactive"}
                            style={{margin:'0'}} 
                            onClick={()=>{ continueToTracks() }}>
                                <div className="step-indicator-text"
                                style={{
                                    color:'inherit',
                                    margin:'0',
                                    fontSize:'large',
                                    fontWeight:'bold'
                                }}>Program Tracks</div>
                            </div>
                            :
                            <div className={(selectionPhase === 1) ? "step-indicator-group" : "step-indicator-group-inactive"}
                            style={{margin:'0'}} 
                            onClick={()=>{setSelectionPhase(1)}}>
                                <div className="step-indicator-text"
                                style={{
                                    color:'inherit',
                                    margin:'0',
                                    fontSize:'large',
                                    fontWeight:'bold'
                                }}>Program Signaling</div>
                            </div>
                        }
                     
                        {
                            allTracks.length > 0 ?
                            <div className={selectionPhase === 2 ? "step-indicator-group" : "step-indicator-group-inactive"} 
                            style={{margin:'0'}} 
                            onClick={()=>{setSelectionPhase(2)}}>
                                <div className="step-indicator-text"
                                style={{
                                    color:'inherit',
                                    margin:'0',
                                    fontSize:'large',
                                    fontWeight:'bold'
                                }}>Program Signaling</div>
                            </div> 
                            :
                            <div className={selectionPhase === 2 ? "step-indicator-group" : "step-indicator-group-inactive"} 
                            style={{margin:'0'}} 
                            onClick={()=>{setSelectionPhase(2)}}>
                                <div className="step-indicator-text"
                                style={{
                                    color:'inherit',
                                    margin:'0',
                                    fontSize:'large',
                                    fontWeight:'bold'
                                }}>Signal Statements</div>
                            </div> 
                        }
                        {
                            allTracks.length > 0 &&
                            <div className={selectionPhase === 3 ? "step-indicator-group" : "step-indicator-group-inactive"} 
                            style={{margin:'0'}} 
                            onClick={()=>{setSelectionPhase(3)}}>
                                <div className="step-indicator-text"
                                style={{
                                    color:'inherit',
                                    margin:'0',
                                    fontSize:'large',
                                    fontWeight:'bold'
                                }}>Signal Statements</div>
                            </div> 
                            
                        }
                        
                </div>

                {/* render tables by condition */}
                {
                    selectionPhase === 0 &&
                    <ProgramsListTable columns={columns} data={allPrograms} />
                }
            
                {
                    selectionPhase === 1 && 
                    allTracks.length > 0 &&
                    tracksData &&
                    <ProgramsListTable columns={tracksColumns} data={tracksData} />
                }

                {
                    selectionPhase === 1 && 
                    allTracks.length === 0 &&
                    <ProgramsListTable columns={specialty && specialty.name === "Anesthesiology" ? signalAnesColumns :  signalColumns} data={[...appliedPrograms, ...pendingPrograms]} />
                }

                {
                    selectionPhase === 2 && 
                    allTracks.length > 0 &&
                    <ProgramsListTable columns={specialty && specialty.name === "Anesthesiology" ? signalAnesColumns :  signalColumns} data={[...appliedPrograms, ...pendingPrograms]} />
                }



                {
                    ((selectionPhase === 2 &&  allTracks.length === 0) || 
                    (selectionPhase === 3 &&  allTracks.length > 0)) &&
                    <div>
                    {
                        [...appliedPrograms, ...pendingPrograms].filter(elem => elem.isSignal === true).map((program, index) => (
                            <div className='application-form-field-group single' style={{marginBottom:'0', marginTop:'3vh'}}>
                                <label key={index} className='applicant-form-field-label'>
                                    Please provide your personal statement for {program.name}. The limit is 100 words.
                                </label>
                                <Field 
                                as="textarea" 
                                value={program.signalEssay} 
                                disabled={appliedPrograms.filter(elem => elem.programId.id.toString() === program.programId.id.toString()).length > 0}
                                onChange={(event) => updateEssayInProgram(event, program)} placeholder='Signal Statement' className='application-form-standard-input textBox signalStatement'  style={{marginBottom:'0'}} />
                                <div className='application-form-word-count' style={{marginBottom:'1vh'}}>
                                    {countWords(program.signalEssay)}/100
                                    <span style={{color: countWords(program.signalEssay) > 100 ? 'red' : 'black'}}></span>
                                </div>
                            
                            </div>
                        ))
                    }

                    </div>
                }
                <label style={{fontFamily:'Montserrat', textAlign:'justify', marginTop:'2vh'}}>{allPrograms.filter(elem => elem.isChecked === true).length} Selected Programs out of {allPrograms.length} </label>


                {/* No Tracks option */}


                {
                    selectionPhase === 0 &&
                    allTracks.length === 0 &&
                    <button 
                    className={allPrograms.filter(elem => elem.isChecked === true).length === 0 ? "program-selection-button disabled" : "program-selection-button"}
                    onClick={continueToSignaling}
                    disabled={allPrograms.filter(elem => elem.isChecked === true).length === 0}
                    >Continue to Program Signaling</button> 
                }

                {
                    selectionPhase === 1 &&
                    allTracks.length === 0 &&
                    <button 
                    className={[...pendingPrograms, ...appliedPrograms].filter(elem => elem.isSignal === true).length === 0 ? "program-selection-button disabled": "program-selection-button"}
                    onClick={continueToStatement}
                    disabled={[...pendingPrograms, ...appliedPrograms].filter(elem => elem.isSignal === true).length === 0}
                    >Continue to Signal Statements</button> 
                }  

                {
                    selectionPhase === 2 && finishApplication === false && allTracks.length === 0 &&
                    <button 
                        className={pendingPrograms.filter(elem => elem.isSignal === true && elem.signalEssay === "").length > 0  ? "program-selection-button disabled": "program-selection-button"}
                        onClick={continuePayment}
                        disabled={pendingPrograms.filter(elem => elem.isSignal === true && elem.signalEssay === "").length > 0}
                    >Save Programs and Continue to Payment</button> 
                } 

                {
                    selectionPhase === 2 && finishApplication === true && allTracks.length === 0 &&
                    <button 
                    className={pendingPrograms.filter(elem => elem.isSignal === true && elem.signalEssay === "").length > 0  || loadingCreateApplications === true ? "program-selection-button disabled": "program-selection-button"}
                    onClick={submitApplications}
                    disabled={pendingPrograms.filter(elem => elem.isSignal === true && elem.signalEssay === "").length > 0 || loadingCreateApplications === true}
                >{appliedPrograms.length>0?'Update and Submit Applications':'Submit Applications'}</button> 
                }



                {/* Tracks options */}

                {
                    selectionPhase === 0 &&
                    allTracks.length > 0 &&
                    <button 
                    className={allPrograms.filter(elem => elem.isChecked === true).length === 0 ? "program-selection-button disabled" : "program-selection-button"}
                    onClick={continueToTracks}
                    disabled={allPrograms.filter(elem => elem.isChecked === true).length === 0}
                    >Continue to Program Tracks</button> 
                }

                {
                    selectionPhase === 1 &&
                    allTracks.length > 0 &&
                    <button 
                    className={allPrograms.filter(elem => elem.isChecked === true).length === 0 ? "program-selection-button disabled" : "program-selection-button"}
                    onClick={continueToSignaling}
                    disabled={allPrograms.filter(elem => elem.isChecked === true).length === 0}
                    >Continue to Program Signaling</button> 
                }

                {
                    selectionPhase === 2 &&
                    allTracks.length > 0 &&
                    <button 
                    className={pendingPrograms.filter(elem => elem.isSignal === true).length === 0 ? "program-selection-button disabled": "program-selection-button"}
                    onClick={continueToStatement}
                    disabled={pendingPrograms.filter(elem => elem.isSignal === true).length === 0}
                    >Continue to Signal Statements</button> 
                }  

                {
                    selectionPhase === 3 && finishApplication === false && allTracks.length > 0 &&
                    <button 
                        className={pendingPrograms.filter(elem => elem.isSignal === true && elem.signalEssay === "").length > 0 ? "program-selection-button disabled": "program-selection-button"}
                        onClick={continuePayment}
                        disabled={pendingPrograms.filter(elem => elem.isSignal === true && elem.signalEssay === "").length > 0}
                    >Save Programs and Continue to Payment</button> 
                } 

                {
                    selectionPhase === 3 && finishApplication === true && allTracks.length > 0 &&
                    <button 
                    className={pendingPrograms.filter(elem => elem.isSignal === true && elem.signalEssay === "").length > 0  || loadingCreateApplications === true ? "program-selection-button disabled": "program-selection-button"}
                    onClick={submitApplications}
                    disabled={pendingPrograms.filter(elem => elem.isSignal === true && elem.signalEssay === "").length > 0 || loadingCreateApplications === true}
                    >{appliedPrograms.length>0?'Update and Submit Applications':'Submit Applications'}</button> 
                }





                {
                    confirmModalOpen &&
                    <div className="modal-backdrop">
                        <div className="add-field-modal">
                            <div className='add-field-modal-header'>
                                <h2>APPLICATION SUBMITTED</h2>
                                <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
                            </div>
                            <div style={{ fontFamily: 'Montserrat'}}>
                            Congratulations on submitting your Smart Recruiting! Your application will be securely delivered to your selected programs on the listed deadline. Best of luck this cycle!
                            </div>
                            <button type="button" className='white-button' style={{ float: 'right'}} onClick={() => {
                                setConfirmModalOpen(prevState => !prevState);
                                setPage('appManager');
                                setIsApplicationOpen(false);
                            }}>Close</button>

                        </div>
                    </div>
                }

                {
                    showSignalModal && 
                        <div className='modal-backdrop'>
                          <div className='add-research-modal' style={{ height: '10vh', overflow: 'scroll'}}>
                            <div style={{fontFamily: 'Montserrat', fontSize: '0.8rem'}}>
                                <div><span style={{fontWeight: 'bold'}}>Honor Code Statement:</span> As an applicant for anesthesiology residency programs using CentralApp, I hereby commit to adhering to the following honor code regarding the signaling process:</div>
                                <br/>
                                <div style={{ marginLeft: '2vw'}}>
                                    <div style={{ fontWeight: 'bold'}}>1. Concordant Signaling Requirement:</div>
                                    <div style={{ marginLeft: '2vw'}}>• I understand that I must signal my interest in specific anesthesiology programs in both CentralApp and ERAS.</div>
                                    <div style={{ marginLeft: '2vw'}}>• I acknowledge that any program I signal in CentralApp must also be signaled in ERAS, and vice versa.</div>
                                    <div style={{ marginLeft: '2vw'}}>• I will ensure that the signals I submit through both platforms are identical and consistent.</div>
                                </div>
                                <br/>
                                <div style={{ marginLeft: '2vw'}}>
                                    <div style={{ fontWeight: 'bold'}}>2. Honor Code Commitment:</div>
                                    <div style={{ marginLeft: '2vw'}}>• I recognize that the signaling process is based on an honor code, and I pledge to act with honesty and integrity.</div>
                                    <div style={{ marginLeft: '2vw'}}>• I commit to accurately reporting my signals and not engaging in any actions that would result in discordant signaling between CentralApp and ERAS.</div>
                                </div>
                                <br/>
                                <div style={{ marginLeft: '2vw'}}>
                                    <div style={{ fontWeight: 'bold'}}>3. Verification and Compliance:</div>
                                    <div style={{ marginLeft: '2vw'}}>• I am aware that CentralApp will automatically cross-reference the signals submitted through CentralApp and ERAS.</div>
                                    <div style={{ marginLeft: '2vw'}}>• I understand that any discrepancies between my signals in CentralApp and ERAS will be considered a violation of this honor code.</div>
                                </div>
                                <br/>
                                <div style={{ marginLeft: '2vw'}}>
                                    <div style={{ fontWeight: 'bold'}}>4. Consequences of Violation:</div>
                                    <div style={{ marginLeft: '2vw'}}>• I accept that any discordant signaling will result in my being reported to all participating programs in CentralApp.</div>
                                    <div style={{ marginLeft: '2vw'}}>• I acknowledge that, if found in violation of this honor code, I will forfeit further review by the program(s) I falsely signaled.</div>
                                    <div style={{ marginLeft: '2vw'}}>• I am aware that violations of this honor code may have serious implications for my residency application process and professional reputation.</div>
                                </div>
                                <br/>
                            </div>
                            <div><span style={{fontWeight: 'bold'}}>Acknowledgment:</span> By submitting my signals through CentralApp, I affirm that I have read, understood, and agree to abide by this honor code. I understand the importance of maintaining integrity in the residency application process and commit to upholding the highest standards of honesty and transparency.</div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '25vw', marginLeft: '10vw', marginTop: '3vh'}}>
                                <button className='green-button' onClick={() => setShowSignalModal(false)}>CANCEL</button>
                                <button className='green-button' onClick={updateAcceptSignal}>ACCEPT</button>
                            </div>
                          </div>
                        </div>
                }
               



            </div>
        </div>
    )
}

export default ApplicationFormStep6;