import React,{useState, useContext, useEffect, useRef} from "react";
import {useQuery, useMutation} from '@apollo/client';
import moment from 'moment-timezone';
import { getUsersQuery, getUsersCountQuery } from './graphql/queries';
import { updateUserMutation, UPDATE_PROGRAM, updateUserSuspendMutation, updateProgramUserMutation } from './graphql/mutations';
import GeneralTable from './generalTable';
import Pagination from './paginationComponent';
import PageSizeSelector from './pageSizeSelector';
import EditUserModal from './editUserModal';
import AddUserModal from './addUserModal';
import VerificationLinkView from "./verificationLinkView";
import DirectAccessModal from "./directAccessModal";
import DirectAccessLinkModal from "./directAccessLinkModal";
import ManualVerificationModal from "./manualVerification";
import ResetPasswordModal from "./resetPasswordModal";

const AdminUsers  = ({ setPage }) => {
    const [users, setUsers] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(20);
    const [filter, setFilter] = useState({field: 'firstName', value: null});
    const [sort, setSort] = useState({ field: 'lastName', direction: 'desc'});
    const [totalUsers, setTotalUsers] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [searchInput, setSearchInput] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [editUserOpen, setEditUserOpen] = useState(false);
    const [addUserOpen, setAddUserOpen] = useState(false);
    const [verificationLinkOpen, setVerificationLinkOpen] = useState(false);
    const [otherViewOpen, setOtherViewOpen] = useState(false);
    const [accountAccessOpen, setAccountAccessOpen] = useState(false);
    const [isDirectAccessOpen, setIsDirectAccessOpen] = useState(false);
    const [isDirectAccessLinkOpen, setIsDirectAccessLinkOpen] = useState(false);
    const [isResetPasswordOpen, setIsResetPasswordOpen] = useState(false);
    const [manualVerificationOpen, setManualVerificationOpen] = useState(false);
    const [loadingData, setLoadingData] = useState(false)
    
    
    const [updateProgarmUser] = useMutation(updateProgramUserMutation);
    const [updateProgram] = useMutation(UPDATE_PROGRAM);
    const [updateUser] = useMutation(updateUserMutation);
    const [updateUserSuspend] = useMutation(updateUserSuspendMutation);

    const {loading: loadingUsers, error: errorUsers, data: usersData, refetch: refetchUsers } = useQuery(getUsersQuery, { variables: {
        offset,
        limit,
        filter,
        sort
    }})

    const {loading: loadingUsersCount, error: errorUsersCount, data: usersCount, refetch: refetchUsersCount } = useQuery(getUsersCountQuery, { variables: {
        filter,
    }})


    useEffect(() => {
        setLoadingData(true)
        if(!loadingUsers && !errorUsers && usersData) {
            console.log("usersData =>", usersData.getUsersWithPagination)
            setUsers(usersData.getUsersWithPagination)
            if(selectedUser !== null) {
                let selectedInUsers = usersData.getUsersWithPagination.filter(elem => elem.id.toString() === selectedUser.id.toString())
                if(selectedInUsers.length > 0) {
                    setSelectedUser(selectedInUsers[0])
                } else setSelectedUser(null)
            }
            setLoadingData(false)
        }
    }, [loadingUsers, errorUsers, usersData, selectedUser])

    useEffect(() => {
        if(!loadingUsersCount && !errorUsersCount && usersCount) {
            console.log("usersCount =>", usersCount.getUsersCount)
            setTotalUsers(usersCount.getUsersCount)
            setTotalPages(Math.ceil(usersCount.getUsersCount/ Number(limit)))

        }
    }, [loadingUsersCount, errorUsersCount, usersCount, setTotalPages, setTotalUsers, limit])

    const toggleSelected = (user) => {
        if(selectedUser === null) {
            setSelectedUser(user)
        } else {
            if(selectedUser.id.toString() === user.id.toString()) {
                // close menu
                setSelectedUser(null)
            } else {
                // other user clicked
                setSelectedUser(null)
                setSelectedUser(user)
            }
        }
    }

    const suspendAccount = () => {
        if(selectedUser) {
            const suspendStatus = selectedUser.isSuspend
            var newSuspend = false
            console.log("suspendStatus =>", suspendStatus)
            if(suspendStatus == null || suspendStatus === undefined || 
                (suspendStatus !== null && suspendStatus !== undefined && suspendStatus === false)) {
                    newSuspend = true
                } else newSuspend = false
            updateUserSuspend({
                variables: {
                    id: selectedUser.id,
                    suspend: newSuspend
                }
            })
            .then(res => {
                console.log("suspend updated!")
                refetchUsers()
            })
        }

    }

    const columns = [
        {
            accessor: 'firstName',
            id: 'firstName',
            Header: <label style={{width:'4vw'}}>First Name</label>,
            Cell: ({ row }) => (
                <div style={{width:'4vw'}}>{row.original.firstName}</div>
            ), 
        },
        {
            accessor: 'lastName',
            id: 'lastName',
            Header: <label style={{width:'4vw'}}>Last Name</label>,
            Cell: ({ row }) => (
                <div style={{width:'4vw'}}>{row.original.lastName}</div>
            ), 
        },
        {
            accessor: 'email',
            id: 'email',
            Header: <label >Email</label>,
            Cell: ({ row }) => (
                <div >{row.original.email}</div>
            ), 
        },
        {
            accessor: 'lastLogin',
            id: 'lastLogin',
            Header: <label style={{width:'4vw'}}>Last Login (Eastern time)</label>,
            Cell: ({ row }) => (
                row.original.lastLogin !== null && row.original.lastLogin !== undefined ? (
                    <div style={{width:'4vw'}}>
                         {moment.unix(row.original.lastLogin / 1000).tz('America/New_York').format('DD/MM/YY HH:mm:ss')}                              
                    </div>
                ) : (
                    <div style={{width:'4vw'}}>No Record</div>
                )
            ),
        },
        {
            accessor: 'userType',
            id: 'userType',
            Header: <label style={{width:'4vw'}}>User Type</label>,
            Cell: ({ row }) => (
                <div style={{width:'4vw', textTransform: 'Capitalize'}}>{row.original.userType}</div>
            ), 
        },
        {
            id: 'actions',
            Header: <label style={{width:'4vw'}}>Actions</label>,
            Cell: ({ row }) => (
                <div className="dropdown-point">
                <div className='toggle-points' onClick={() => toggleSelected(row.original)}>
                    <span className='point'/>
                    <span className='point'/>
                    <span className='point'/>
                </div>
                {otherViewOpen === false && selectedUser && row.original.id.toString() === selectedUser.id.toString() && (
                    <div className="dropdown-menu" style={{display:'flex', position:'',zIndex: 999, right: '3vw', flexDirection:'column', padding:'10%',}}>
                            <a href="#" onClick={() => { setOtherViewOpen(true); setEditUserOpen(prevState => !prevState);}}>Edit User</a>
                            {selectedUser.isVerified !== null && selectedUser.isVerified !== undefined && selectedUser.isVerified === false && selectedUser.verificationToken && <a href="#" onClick={() => { setOtherViewOpen(true); setVerificationLinkOpen(prevState => !prevState)}}>Create Account (token) Email Link</a>}
                            <a href="#" onClick={() => {setIsDirectAccessOpen(true); setOtherViewOpen(true)}}>Bypass 2FA</a>
                            <a href="#" onClick={() => {setIsDirectAccessLinkOpen(true); setOtherViewOpen(true)}}>Direct Access Link</a>
                            <a href="#" onClick={() => {setIsResetPasswordOpen(true); setOtherViewOpen(true)}}>Reset Password Link</a>
                            <a href="#" onClick={suspendAccount}>{selectedUser.isSuspend === null || selectedUser.isSuspend === undefined || (selectedUser.isSuspend !== null && selectedUser.isSuspend !== undefined && selectedUser.isSuspend === false) ? "Suspend Account": "Cancel Account Suspension"}</a>
                            {selectedUser.userType === 'faculty' && <a href="#" onClick={() => { setOtherViewOpen(true); setAccountAccessOpen(true)}}>Account Access</a>}
                            {selectedUser.userType ==='applicant' && selectedUser.isVerified===false&& <a href="#" onClick={() => { setOtherViewOpen(true); setManualVerificationOpen(true)}}>Manual Verification</a>}
                    </div>
                )}
                </div>
            ), 
        },
    ]

    const handlePageSizeChange = (event) => {
        setLimit(Number(event.target.value));
        setOffset(0)
        setCurrentPage(1); // Reset to first page when page size changes
        setTotalPages(Math.ceil(totalUsers/ Number(event.target.value)))
        setLoadingData(true)
        refetchUsers()
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setOffset((page - 1) * limit )
        setLoadingData(true)
        refetchUsers()
    };

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchInput(value)
        var searchObj = { ...filter}
        searchObj.value = value
        setLoadingData(true)
        setFilter(searchObj)
        // debouncedSearch(value); // Update the search query after debounce
    }

    const editUser = (data) => {

        console.log('data that is in the editUser function =>', data)
        const { roles, programId, ...rest } = data
        // if data has program Id, remove it from the rest object
        setOtherViewOpen(false)
        // update user data
        updateUser({
            variables: {
                id: selectedUser.id,
                input: rest
            }
        })
        .then(res => {
            console.log("user updated!");
            setEditUserOpen(prevState => !prevState)
            refetchUsers()
        })
        // update user roles
        if ((data.roles !== undefined 
            && data.roles !== null 
            && data.roles !== selectedUser.roles )
            ||
             (data.programId !== undefined
            && data.programId !== null)) {

            updateProgarmUser({
                variables: {
                    userId: selectedUser.id,
                    roles: data.roles,
                    programId: data.programId
                }
            })
            .then(res => {
                console.log("roles updated!")
            })

        }

    }

    const resendLink = (link) => {
        console.log("link =>", link)
    }


    const sortUsers = (column, direction) => {
        setSort({ field: column, direction: direction === true ? "asc": "desc"})
        refetchUsers()
    }


    return (
        <div className='faculty-home-container'>
            <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop2' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="faculty-home-header">
                <h2 className="faculty-home-header-title">USERS</h2>
            </div>
            <seperator className="faculty-home-header-seperator"/>
            <div className="right-header-container" style={{ marginBottom: '2vh'}}>
                
                <div className="input-container">
                    <i className="fas fa-search"></i>
                    <input
                        className="applicant-top-bar-search-input"
                        placeholder="Search"
                        value={searchInput}
                        onChange={handleSearchChange}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                refetchUsers()
                            }
                        }}
                    />
                </div>
                    <button className="green-button" onClick={() => setAddUserOpen(true)}>Create New User</button>
            </div>
            <GeneralTable columns={columns} data={users} limit={limit} totalPages={totalPages} currentPage={currentPage} handlePageSizeChange={handlePageSizeChange} handlePageChange={handlePageChange} loadingData={loadingData} sortData={sortUsers}/>
            {/* <div className="faculty-application-table-footer" style={{ height: '4vh'}}>
                <PageSizeSelector itemsPerPage={limit} onPageSizeChange={handlePageSizeChange} />
                <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
            </div> */}
            { editUserOpen && <EditUserModal isOpen={editUserOpen} onClose={() =>{ setEditUserOpen(false); setOtherViewOpen(false);}} user={selectedUser} onEditUser={editUser} /> }
            {addUserOpen && <AddUserModal isOpen={addUserOpen} onClose={() => setAddUserOpen(false)}  onEditUser={editUser} />}
            { verificationLinkOpen && <VerificationLinkView isOpen={verificationLinkOpen} onClose={() => { setVerificationLinkOpen(false); setOtherViewOpen(false);}} resendLink={resendLink} user={selectedUser}/> }
            {isDirectAccessOpen && <DirectAccessModal isOpen={isDirectAccessOpen} onClose={() => {setIsDirectAccessOpen(false); setOtherViewOpen(false)}} user={selectedUser} refetchUsers={refetchUsers} />}
            { isDirectAccessLinkOpen && <DirectAccessLinkModal isOpen={isDirectAccessLinkOpen} onClose={() => {setIsDirectAccessLinkOpen(prevState => !prevState); setOtherViewOpen(false);}} user={selectedUser} refetchUsers={refetchUsers}/>}
            { isResetPasswordOpen && <ResetPasswordModal isOpen={isResetPasswordOpen} onClose={() => {setIsResetPasswordOpen(prevState => !prevState); setOtherViewOpen(false);}} user={selectedUser} refetchUsers={refetchUsers}/>}
            {manualVerificationOpen && <ManualVerificationModal isOpen={manualVerificationOpen} onClose={() => {setManualVerificationOpen(false); setOtherViewOpen(false)}} user={selectedUser} refetchUsers={refetchUsers} />}
            
        </div>
    )
}

export default AdminUsers;