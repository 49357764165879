import React,{useState, useContext, useEffect} from "react";
import "./index.css";
import mockedGroups from '../applications/mockData/mockedGroups.js'
import GroupItem from "./groupItem";
import AddNewGroupModal from "./addNewGroupModal";
import Applications from "../applications";
import { ProgramIdContext } from '../../progIdContext';
import client from '../../../../config/apolloClient';
import { getProgramByIdMembers, getGroupsByProgramIdQuery } from '../graphql/queries';
import { createGroupMutation, deleteGroupMutation, updateGroupMutation } from '../graphql/mutations';
import GeneralTable from "./generalTable";

const myId = '1553';

const Groups = ({isApplicationsClicked, setIsApplicationsClicked, refetchProgram}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [groupsFilter, setGroupsFilter] = useState('all');
    const [groups, setGroups] = useState([]);
    const [isGroupOpen, setIsGroupOpen] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState({});
    const [selectedGroupTable, setSelectedGroupTable] = useState(null);
    const [groupToEdit, setGroupToEdit] = useState(null);
    const [programMembers, setProgramMembers] = useState([])
    const [searchInput, setSearchInput] = useState('');
    const [filter, setFilter] = useState({field: 'name', value: null});
    const [viewState, setViewState] = useState("grid")  //grid or table
    const [otherViewOpen, setOtherViewOpen] = useState(false);

   


    const columns = [
        {
            accessor: 'name',
            id: 'name',
            Header: <label style={{width:'4vw'}}>Folder Name</label>,
            Cell: ({ row }) => (
                <div style={{width:'4vw'}}>{row.original.name}</div>
            ), 
        }, 
        {
            accessor: 'description',
            id: 'description',
            Header: <label style={{width:'4vw'}}>Description</label>,
            Cell: ({ row }) => (
                <div style={{width:'4vw'}}>{row.original.description}</div>
            ), 
        }, 
        {
            accessor: 'applicationIds',
            id: 'applicationIds',
            Header: <label style={{width:'4vw'}}>Applicants</label>,
            Cell: ({ row }) => (
                <div style={{width:'4vw'}}>{row.original.applicationIds.length} Applicants</div>
            ), 
        }, 
        {
            id: 'actions',
            Header: <label style={{width:'4vw'}}>Actions</label>,
            Cell: ({ row }) => (
                <div className="dropdown-point">
                <div className='toggle-points' onClick={() => toggleSelected(row.original)}>
                    <span className='point'/>
                    <span className='point'/>
                    <span className='point'/>
                </div>
                {otherViewOpen === false && selectedGroupTable && row.original.id.toString() === selectedGroupTable.id.toString() && (
                    <div className="dropdown-menu" style={{display:'flex', position:'',zIndex: 999, right: '3vw', flexDirection:'column', padding:'10%',}}>
                        <a href="#" onClick={() => { setOtherViewOpen(true);  onEdit(selectedGroupTable);}}>Edit Folder</a>
                      {row.original.name !=='Rejected'&&  <a href="#" onClick={() => { setOtherViewOpen(true);  onDelGroup(selectedGroupTable);}}>Delete Folder</a>}
                        <a href="#"  onClick={() => { setOtherViewOpen(true);  onGroupClick(selectedGroupTable);}}>View Applicants</a>
                          
                    </div>
                )}
                </div>
            ), 
        },
    ]


    const toggleSelected = (group) => {
        console.log("toggleSelected =>", group)
        if(selectedGroupTable === null) {
            setSelectedGroupTable(group)
        } else {
            if(selectedGroupTable.id.toString() === group.id.toString()) {
                // close menu
                setSelectedGroupTable(null)
            } else {
                // other group clicked
                setSelectedGroupTable(null)
                setSelectedGroupTable(selectedGroupTable)
            }
        }
    }


    const programId = useContext(ProgramIdContext);
    console.log("programId from context => ", programId)

    const getProgramMembers = () => {
        try {
        client.query({
            query: getProgramByIdMembers,
            variables: {
                id: programId
            },
            fetchPolicy: 'network-only'

        })
        .then(res => {
            let program = res.data.getProgramByIdMembers
            console.log("program members =>", program)
            setProgramMembers(program.members)
        })
    }catch(err) {
        console.log("error =>", err)
    }
    }

    const getGroupsByProgramId = () => {
        client.query({
            query: getGroupsByProgramIdQuery,
            variables: {
                programId,
                filter
            },
            fetchPolicy: 'network-only'
        })
        .then(res => {
            let groups = res.data.getGroupsByProgramId
            setGroups(groups)
        })
    }


    useEffect(() => {
        getGroupsByProgramId()
        getProgramMembers()
    }, [])

    const onAddGroup = (newGroup) => {
        setGroups([...groups, newGroup]);
    };

    const onDelGroup = (group) => {
        console.log("group =>", group)
        let groupId = group.id
        client.mutate({ 
            mutation: deleteGroupMutation,
            variables: {
                id: groupId
            }
        }).then(res => {
            const newGroups = groups.filter(group => group.id !== groupId);
            setGroups(newGroups);
        })
        setOtherViewOpen(false)

        // setGroups(groups.filter(group => group.name !== groupName));
    }

    const onGroupClick = (group) => {
        setIsGroupOpen(true);
        setSelectedGroup(group);
        setOtherViewOpen(false)
    }

    const onBackClick = () => {
        setIsGroupOpen(false);
    }

    const onEdit = (group) => {
        setGroupToEdit(group);
        setIsModalOpen(true);
        setOtherViewOpen(false)
    }

    const saveEditedGroup = (editedGroup, name, description, selectedMembers) => {
        const membersIds = selectedMembers.map(member => member.value);
        console.log("groupToEdit =>", groupToEdit)
        client.mutate({
            mutation: updateGroupMutation,
            variables: {
                id: groupToEdit.id,
                input: { 
                    id: groupToEdit.id,
                    programId,
                    name,
                    description,
                    membersIds
                }
            }
        })
        .then(res => {
            console.log("group edited!")
        })

        setGroupToEdit(null);
        setIsModalOpen(false);
    }

    const createGroup = (name, description, selectedMembers) => {
        const membersIds = selectedMembers.map(member => member.value);
        console.log("inside create group =>", name, description, selectedMembers)
        client.mutate({
            mutation: createGroupMutation,
            variables: {
                input: {
                    programId,
                    name,
                    description,
                    membersIds
                }
            }
        })
    }

    const generateOptions = (programMembers) => {
        let options = []

        programMembers.forEach(member => {
            options.push({ label: `${member.userId.firstName} ${member.userId.lastName}`, value: member.userId.id })
        })
        return options
    }

    const handleSearchChange = (event) => {
        const value = event.target.value
        setSearchInput(value)
        var searchObj = { ...filter}
        searchObj.value = value
        setFilter(searchObj)
    }
    return (
        <>
        {!isGroupOpen&&(  
        <div className='faculty-home-container'>
            <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop2' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />

            <div className="faculty-home-header">
                <h2 className="faculty-home-header-title">Folders</h2>
            </div>
            <div className="faculty-groups-top-bar">
            <div claaName="faculty-groups-top-bar-buttons-cont" style={{display:'flex', textAlign:'center', padding:'1vh'}}>

            <div className={groupsFilter==='all'?"faculty-application-top-bar-link":"faculty-application-top-bar-link-inactive"} style={{width:'fit-content',marginRight:'1vw' }} onClick={()=>setGroupsFilter('all')}>All</div>
            <div className={groupsFilter==='my'?"faculty-application-top-bar-link":"faculty-application-top-bar-link-inactive"} style={{width:'fit-content',marginRight:'1vw' }} onClick={()=>setGroupsFilter('my')}>My Folders</div>
            <div className={groupsFilter==='shared'?"faculty-application-top-bar-link":"faculty-application-top-bar-link-inactive"} style={{width:'fit-content',marginRight:'1vw' }} onClick={()=>setGroupsFilter('shared')}>Shared Folders</div>
            
            </div>
            <button className="green-button" onClick={() => setIsModalOpen(!isModalOpen)}>Create New Folder</button>
            </div>
            <seperator style={{marginTop:'2.5vh'}} className="faculty-home-header-seperator"/>

            <div style={{display: 'flex', width: '98%', justifyContent: 'space-between', marginBottom: '3vh', alignItems: 'center'}}>
                <div className="input-container" style={{ marginBottom: '2vh' }}>
                    <i className="fas fa-search"></i>
                    <input 
                        className="applicant-top-bar-search-input"
                        placeholder="Search"
                        value={searchInput}
                        onChange={handleSearchChange}
                        onKeyDown={(e) => {
                            if(e.key === 'Enter') {
                                e.preventDefault();
                                // refetchGroups
                                getGroupsByProgramId()
                            }
                        }}
                    />
                
                </div>
                <button className="green-button" onClick={() => setViewState(prevState => prevState === "grid"? "table": "grid")}>{viewState === "grid"? "Table View": "Grid View"}</button>
            </div>





        <div >
        
        <AddNewGroupModal isOpen={isModalOpen} onClose={() => {setIsModalOpen(false); setGroupToEdit(null);}}   onAddGroup={onAddGroup} onEditGroup={saveEditedGroup} groupToEdit={groupToEdit} programMembers={programMembers} programMembersOptions={generateOptions(programMembers)} createGroup={createGroup}/>
        
        {
            viewState === "grid" &&
            <div className="faculty-groups-body">
                {!isGroupOpen && groupsFilter === 'all' && groups.map((group) => (
                        <GroupItem key={group._id} group={group} onDelete={onDelGroup}  onSelectGroup={onGroupClick} onEditGroup={onEdit}/>
                    ))
                    }

                {!isGroupOpen && groupsFilter === 'my' && groups.filter(group => group.owner === myId).map((group) => (
                        <GroupItem key={group._id} group={group} onDelete={onDelGroup} onSelectGroup={onGroupClick} onEditGroup={onEdit} />
                    ))
                    }

                {!isGroupOpen && groupsFilter === 'shared' && groups.filter(group => group.owner !== myId).map((group) => (
                        <GroupItem key={group._id} group={group} onDelete={onDelGroup} onSelectGroup={onGroupClick} onEditGroup={onEdit}/>
                    ))
            }
            </div>
        }
        {
            viewState === "table" && 
            <GeneralTable columns={columns} data={groups} />
        }
        
        
        
    

        </div>


         
           
        </div>
                    
        )}
        {isGroupOpen && <Applications selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup}  onBack={onBackClick} isApplicationsClicked={isApplicationsClicked} setIsApplicationsClicked={setIsApplicationsClicked} refetchGroupsApps={getGroupsByProgramId} refetchProgram={refetchProgram}/>}
        </>
                            
    );
}

export default Groups;

