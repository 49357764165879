import React,{ useState, useEffect, useContext} from "react";
import logo from "../../../../assets/imgs/logo.svg"
import signalStar from "../../../../assets/imgs/signalStar.svg"
import greenCheck from "../../../../assets/imgs/greenCheck.svg";
import redX from "../../../../assets/imgs/redX.svg";
import scoreIconGreen from "../../../../assets/imgs/scoreIconGreen.svg";
import ApplicationViewMainDemographics from "./sections/demographics";
import Education from "./sections/education";
import HonorsAndAwards from "./sections/honorsAndAwards";
import Research from "./sections/research";
import SignificantExperience from "./sections/significantExp";
import WorkExperience from "./sections/workExp";
import ShortQA from "./sections/shortQA";
import dummyPDFFile from "../../../../assets/dummyFiles/exampleApplication.pdf"
import onePager from "../../../../assets/dummyFiles/onePager.pdf"
import { useQuery } from '@apollo/client';
import { getLorsByApplicationAndUserQuery,  getMspeByApplicantAndApplicationQuery, getTranscriptsByApplicantAndApplicationQuery} from './graphql/queries';
import { ProgramIdContext } from '../../progIdContext';
import { Separator } from "../interviewManager/common/CustomBootstrap";
import SlorSummaryTable from "./sections/slorSummaryTable";



const calculatePreSeason = (programId) => {
  if (process.env.REACT_APP_ENV === 'production') {
      const currentDate = new Date();
      const preSeasonDate = new Date('2024-09-16');
      if (currentDate < preSeasonDate) {
          return(true);
      }else{
          return(false);
      }
    }else{
      return(false);
    }
  }

function formatAnswers(answers) {
  if (answers === null || answers === undefined || answers === "null") {
    return "";
  }
  switch (answers) {
  case "n1":
    return "Number 1";
  case "n2to5":
    return "2-5";
  case "n5to10":
    return "5-10";
  case "n10to20":
    return "10-20";
  case "wouldNotRank":
    return "would not rank";
  case "NA":
    return "Unable to evaluate this trait";
  case "advisor":
    return "Advisor";
  case "generalSurgeryResidencyProgramDirector":
    return "General Surgery Residency Program Director";
  case "rotatedOnMyService":
    return "The applicant has rotated on my service";
  case "plasticSurgeryRotation":
    return "On a plastic surgery rotation";
  case "differentRotation":
    return "On a different rotation";
  case "writtenPaperWithTheApplicant":
    return "I have a written paper with the applicant";
  case "performedResearch":
    return "The applicant has performed research";
  case "notWorkedDirectly":
    return "I have not worked directly with the applicant; based on others' evaluations";
  case "other":
    return "Other";
    default:
    return answers;

  }
}

const ApplicationViewMain = ({application, sectionRefs, pdfRef, screenedData}) => {

  console.log(application, 'application in applicationViewMain')
  
  const programId = useContext(ProgramIdContext);
    const [numPages, setNumPages] = useState(null);
    const [lorNumPages, setLorNumPages] = useState({});
    const [mspeNumPages, setMspeNumPages] = useState({});
    const [transcriptNumPages, setTranscriptNumPages] = useState({});
    const [lors, setLors] = useState([]);
    const [mspe, setMspe] = useState([]);
    const [transcript, setTranscript] = useState([]);
    const [workExperienceState, setWorkExperienceState] = useState([]);
    const [isPreSeason, setIsPreSeason] = useState(calculatePreSeason(programId));
    const [slorSumData, setSlorSumData] = useState([]);

  
    const { data: lorsData, error: lorsError, isLoading: lorsLoading } = useQuery(getLorsByApplicationAndUserQuery, {
      variables: {
          applicationId: application.masterApplicationId,
          applicantId: application.user._id
      },
    });
    const { data: mspeDocs, error: mspeError, isLoading: mspeLoading } = useQuery(getMspeByApplicantAndApplicationQuery, {
      variables: {
          applicationId: application.id,
          applicantId: application.user._id
      },
    });
    const { data: transcriptDocs, error: transcriptError, isLoading: transcriptLoading } = useQuery(getTranscriptsByApplicantAndApplicationQuery, {
      variables: {
          applicationId: application.id,
          applicantId: application.user._id
      },
    });

   

  useEffect(() => {
    if (application.workExperiences) {
      const workExperienceToUpdate = [...application.workExperiences];
const sortedWorkExperiences = workExperienceToUpdate.sort((a, b) => {
  const aDate = a.startDate.split('/')
  const bDate = b.startDate.split('/')
  if (aDate[2] === bDate[2]) {
      if (aDate[0] === bDate[0]) {
          return aDate[1] - bDate[1]
      }
      return aDate[0] - bDate[0]
  }
  return aDate[2] - bDate[2]
})
setWorkExperienceState(sortedWorkExperiences);
    }
  }, [application.workExperiences]);

  useEffect(() => {
    if (lorsLoading) {
      return; // Or set a loading state if necessary
    } else if (lorsError) {
      console.error(lorsError);
      return; // Handle the error appropriately
    } else if (lorsData && lorsData.getLorsByApplicationAndUser) {
      let lorsRes = lorsData.getLorsByApplicationAndUser;
      // Only update if lors has actually changed to prevent infinite loop
      if (JSON.stringify(lors) !== JSON.stringify(lorsRes)) {
        setLors(lorsRes);
        console.log("lorsRes =>", lorsRes);
  
        // check if the Lor is a slor and update slorSumData accordingly
        const slorData = lorsRes.map((lor) => {
          return lor.isSlor ? {
            ...lor.slor,
            recommenderName: lor.recommenderName,
          } : null;
        }).filter(Boolean); // Remove any null entries (non-slor)
  
        if (slorData.length > 0) {
          setSlorSumData(slorData);
        }
      }
    }
  }, [lorsData]); 

    useEffect(() => {
      if (mspeLoading) {
        return; // Or set a loading state if necessary
      } else if (mspeError) {
        console.error(mspeError);
        return; // Handle the error appropriately
      } else if (mspeDocs && mspeDocs.getMspeByApplicantAndApplication) {
        console.log("mspeDocs =>", mspeDocs)
        let mspeRes = mspeDocs.getMspeByApplicantAndApplication;
        if (JSON.stringify(mspe) !== JSON.stringify(mspeRes)) {
          setMspe(mspeRes);
          console.log("mspeRes =>", mspeRes);
        }
      }
    }, [mspeDocs, mspeError, mspeLoading, mspe, setMspe]);


    useEffect(() => {
      if (transcriptLoading) {
        return; // Or set a loading state if necessary
      } else if (transcriptError) {
        console.error(transcriptError);
        return; // Handle the error appropriately
      } else if (transcriptDocs && transcriptDocs.getTranscriptsByApplicantAndApplication) {
        console.log("transcriptDocs =>", transcriptDocs)
        let transcriptRes = transcriptDocs.getTranscriptsByApplicantAndApplication;
        if (JSON.stringify(transcript) !== JSON.stringify(transcriptRes)) {
          setTranscript(transcriptRes);
          console.log("transcriptRes =>", transcriptRes);
        }
      }
    }, [transcriptDocs, transcriptError, transcriptLoading, transcript, setTranscript]);
   
  

    function onLorDocumentLoadSuccess(index, { numPages }) {
        setLorNumPages(prevState => ({ ...prevState, [index]: numPages }));
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    function onMSPEDocumentLoadSuccess(index, { numPages }) {
      console.log("index =>", index, numPages)
        setMspeNumPages(prevState => ({ ...prevState, [index]: numPages }));
    }
    function onTranscriptDocumentLoadSuccess(index, { numPages }) {
        setTranscriptNumPages(prevState => ({ ...prevState, [index]: numPages }));
    }
    
    console.log("application inside view =>", application)
    console.log("screenedData inside view =>", screenedData)

    

    const initials = application.firstName.charAt(0) + application.lastName.charAt(0)
    const profilePicUrl =`https://ui-avatars.com/api/?name=${initials}`



  const OfficeViewer = ({ docUrl }) => {
    const officeUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(docUrl)}`;
    return <iframe src={officeUrl} width="100%" height="1200px" frameBorder="0"></iframe>;
  };


    return (
      <div ref={pdfRef}  className="application-view-main" style={{height: '153vh', overflow: 'scroll', width: '64vw', minWidth: '47vw' }}>




        {
          screenedData !== null && screenedData !== undefined && screenedData.sections !== null && screenedData.sections !== undefined &&
          screenedData.sections.personalInfo !== null && screenedData.sections.personalInfo !== undefined && screenedData.sections.personalInfo === true &&
          <section id="demographics" ref={(el) => (sectionRefs.current['demographics'] = el)}>
          <div
            className="application-view-main-header"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div className="application-view-main-header-left">
            {
                screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
                screenedData.personalInfo.profilePicture !== null && screenedData.personalInfo.profilePicture !== undefined && screenedData.personalInfo.profilePicture === true &&
                <img
                  style={application.user.profilePhotoUrl ? { height: "22vh", paddingRight: "1vw" }:{paddingRight: "1vw", height:'16vh'}}
                  src={application.user.profilePhotoUrl ? application.user.profilePhotoUrl : profilePicUrl}
                  alt="Profile Picture"
                  className="application-view-main-header-profile-pic"
                />
            }
           
            </div>
            <div
              className="application-view-main-header-center"
              style={{ marginRight: "auto" }}
            >
              <div
                className="application-view-main-header-text"
                style={{ textAlign: "left" }}
              >
                <div style={{display:'flex' , gap:'.8vw'}}>
                {/* {
                  screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
                  screenedData.personalInfo.firstName !== null && screenedData.personalInfo.firstName !== undefined && screenedData.personalInfo.firstName === true &&
                  screenedData.personalInfo.lastName !== null && screenedData.personalInfo.lastName !== undefined && screenedData.personalInfo.lastName === true &&
                  <h2
                    style={{
                      color: "#145388",
                      fontSize: "4.2vh",
                      fontWeight: "600",
                    }}
                  >
                    {application.firstName} {application.lastName}
                  </h2>
                } */}
                {
                  screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
                  screenedData.personalInfo.firstName !== null && screenedData.personalInfo.firstName !== undefined && screenedData.personalInfo.firstName === true &&
                  <h2
                    style={{
                      color: "#145388",
                      fontSize: "4.2vh",
                      fontWeight: "600",
                    }}
                  >
                    {application.firstName}
                  </h2>
                }
                {
                  screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
                  screenedData.personalInfo.lastName !== null && screenedData.personalInfo.lastName !== undefined && screenedData.personalInfo.lastName === true &&
                  <h2
                    style={{
                      color: "#145388",
                      fontSize: "4.2vh",
                      fontWeight: "600",
                    }}
                  >
                    {application.lastName}
                  </h2>
                }
                </div>
                {/* {
                  screenedData !== null && screenedData !== undefined && screenedData.residencyApplication !== null && screenedData.residencyApplication !== undefined &&
                  screenedData.residencyApplication.medicalSchoolName !== null && screenedData.residencyApplication.medicalSchoolName !== undefined && screenedData.residencyApplication.medicalSchoolName === true &&
                  <p style={{ color: "black", fontWeight:'500', fontStyle:'italic' }}>
                    {application.medicalSchoolName}
                  </p>        
                } */}
                {
                  screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
                  screenedData.personalInfo.email !== null && screenedData.personalInfo.email !== undefined && screenedData.personalInfo.email === true &&
                  <p style={{  fontSize:'16px', marginBottom:'0' }}>{application.email}</p>
                  
                  
                }
                {
                  screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
                  screenedData.personalInfo.phoneNumber !== null && screenedData.personalInfo.phoneNumber !== undefined && screenedData.personalInfo.phoneNumber === true &&
                  <p style={{  fontSize:'16px', marginBottom:'0' }}>{application.phone}</p>
                }
                {
                  screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
                  screenedData.personalInfo.homeAddress !== null && screenedData.personalInfo.homeAddress !== undefined && screenedData.personalInfo.homeAddress === true &&
                  <p style={{  fontSize:'16px', marginBottom:'0' }}>{application.address}</p>
                }


    <p style={{ fontSize: '16px', marginBottom: '0' }}>
      {application.dateOfBirth}
    </p>

              
               
              </div>
            </div>
            <div className="application-view-main-header-right">
              <img
                style={{ width:'5vw' }}
                src={logo}
                alt="Logo"
                className="application-view-main-header-logo"
              />
              {/* {application.isSignal && application.isSignal === "Yes" && application.signalType!=='Gold' && application.signalType!=='Silver' && (
                <div className="application-view-main-header-signal" style={{background:application.signalType === 'Gold'?'linear-gradient(to right, #FFD700, #F8DE7E, #FFD700)':'linear-gradient(to right, #C0C0C0, #F1F1F1, #C0C0C0)', color:application.signalType === 'Gold'?'#333':'#333'}}>
                  <img style={{ width: "2vw" }} src={signalStar} />
                  SIGNALED
                </div>
              )}
              {application.isSignal && application.isSignal === "Yes" && application.signalType==='Gold' && (
                <div className="application-view-main-header-signal" style={{background:'linear-gradient(to right, #FFD700, #F8DE7E, #FFD700)', color:'#333'}}>
                  <img style={{ width: "2vw" }} src={signalStar} />
                  GOLD
                </div>
              )}
              {application.isSignal && application.isSignal === "Yes" && application.signalType==='Silver' && (
                <div className="application-view-main-header-signal" style={{background:'linear-gradient(to right, #C0C0C0, #F1F1F1, #C0C0C0)', color:'#333'}}>
                  <img style={{ width: "2vw" }} src={signalStar} />
                  SILVER
                </div>
              )} */}
             
                
            </div>
          </div>


{/* 
            {application.selectedTracksRaw && application.selectedTracksRaw.length > 0 && (
          <div style={{display:'flex', flexDirection:'row', justifyContent:'right', gap:'1vw'}}>
                {application.selectedTracksRaw.some(track => track.trackName === 'Categorical') && (
                  <div className="application-view-main-header-signal" style={{background:'#1a546b', fontSize:'12px', color:'white', fontWeight:'200', borderRadius:'4px'}}>
                    Categorical
                  </div>
                )}
                {application.selectedTracksRaw.some(track => track.trackName === 'Research') && (
                  <div className="application-view-main-header-signal" style={{background:'#b6ccd7', fontSize:'12px', color:'#1a546b', fontWeight:'200', borderRadius:'4px'}}>
                    Research
                  </div>
                )}
                {application.selectedTracksRaw.some(track => track.trackName === 'Physician Only') && (
                  <div className="application-view-main-header-signal" style={{background:'#657179', fontSize:'12px', color:'white', fontWeight:'200', borderRadius:'4px', width:'fit-content'}}>
                    Physician Only
                  </div>
                )}
                {application.selectedTracksRaw.some(track => track.trackName === 'Advanced') && (
                  <div className="application-view-main-header-signal" style={{background:'#8cb89f', fontSize:'12px', color:'white' , fontWeight:'200', borderRadius:'4px', width:'fit-content'}}>
                    Advanced
                  </div>
                )}
                {application.selectedTracksRaw.some(track => track.trackName === 'Critical Care') && (
                  <div className="application-view-main-header-signal" style={{background:'#8d77ab', fontSize:'12px', color:'white', fontWeight:'200', borderRadius:'4px', width:'fit-content'}}>
                    Critical Care
                  </div>
                )}
                {application.selectedTracksRaw.some(track => track.trackName === 'Peds/Anesthesiology') && (
                  <div className="application-view-main-header-signal" style={{background:'#d5c0c9', fontSize:'12px', color:'#1a546b', fontWeight:'200', borderRadius:'4px', width:'fit-content'}}>
                    Peds/Anesthesiology
                  </div>
                )}

          </div>  
          )} */}

{/* 
{application.selectedTracksRaw && application.selectedTracksRaw.length > 0 && (
          <div style={{display:'flex', flexDirection:'row', justifyContent:'right', gap:'1vw', marginTop:application.isSignal?'':'1vh'}}>
                {application.selectedTracksRaw.some(track => track.trackName === 'Categorical') && (
                  <div className="application-view-main-header-signal" style={{background:'#1a546b', fontSize:'12px', color:'white', borderRadius:'15px'}}>
                    Categorical
                  </div>
                )}
                {application.selectedTracksRaw.some(track => track.trackName === 'Research') && (
                  <div className="application-view-main-header-signal" style={{background:'#b6ccd7', fontSize:'12px', color:'#1a546b', borderRadius:'15px'}}>
                    Research
                  </div>
                )}
                {application.selectedTracksRaw.some(track => track.trackName === 'Physician Only') && (
                  <div className="application-view-main-header-signal" style={{background:'#657179', fontSize:'12px', color:'white',  borderRadius:'15px', width:'fit-content'}}>
                    Physician Only
                  </div>
                )}
                {application.selectedTracksRaw.some(track => track.trackName === 'Advanced') && (
                  <div className="application-view-main-header-signal" style={{background:'#8cb89f', fontSize:'12px', color:'white' , borderRadius:'15px', width:'fit-content'}}>
                    Advanced
                  </div>
                )}
                {application.selectedTracksRaw.some(track => track.trackName === 'Critical Care') && (
                  <div className="application-view-main-header-signal" style={{background:'#8d77ab', fontSize:'12px', color:'white',  borderRadius:'15px', width:'fit-content'}}>
                    Critical Care
                  </div>
                )}
                {application.selectedTracksRaw.some(track => track.trackName === 'Peds/Anesthesiology') && (
                  <div className="application-view-main-header-signal" style={{background:'#d5c0c9', fontSize:'12px', color:'#1a546b', borderRadius:'15px', width:'fit-content'}}>
                    Peds/Anesthesiology
                  </div>
                )}

          </div>  
          )} */}


{
          screenedData !== null && screenedData !== undefined && screenedData.sections !== null && screenedData.sections !== undefined &&
          screenedData.sections.personalStatement !== null && screenedData.sections.personalStatement !== undefined && screenedData.sections.personalStatement === true &&
          <section id="personalStatement" ref={(el) => (sectionRefs.current['personalStatement'] = el)}>
            <div className="application-view-main-demographics">
              <div
                className="application-view-main-demographics-header"
                style={{ marginBottom: "0" }}
              >
                <h2 className="application-view-main-title" s>COVER LETTER</h2>
                <seperator className="application-view-main-demographics-seperator" />
              </div>
              <p style={{ textAlign: "justify", whiteSpace: "pre-wrap", fontSize:'16px', width:'47vw'}}>
                {application.personalStatement}
              </p>
            </div>
          </section>
        }


          <ApplicationViewMainDemographics application={application} screenedData={screenedData}/>
          </section>
        }



        {
           screenedData !== null && screenedData !== undefined && screenedData.sections !== null && screenedData.sections !== undefined &&
           screenedData.sections.residencyApplication !== null && screenedData.sections.residencyApplication !== undefined && screenedData.sections.residencyApplication === true &&
           <div>
              <section id="education" ref={(el) => (sectionRefs.current['education'] = el)}>
              <Education application={application} screenedData={screenedData}/>
              </section>
              {/* <section id="honorsAndAwards" ref={(el) => (sectionRefs.current['honorsAndAwards'] = el)}>
              <HonorsAndAwards application={application} screenedData={screenedData}/>
              </section>
              <section id="research" ref={(el) => (sectionRefs.current['research'] = el)}>
              <Research application={application} screenedData={screenedData}/>
              </section> */}
              <section id="workExperience" ref={(el) => (sectionRefs.current['workExperience'] = el)}>
              <WorkExperience workExperiences={workExperienceState} screenedData={screenedData}/>
              </section>
           </div>
        }




    

        {/* {
          screenedData !== null && screenedData !== undefined && screenedData.residencyApplication !== null && screenedData.residencyApplication !== undefined &&
          screenedData.residencyApplication.significantWorkExperiences !== null && screenedData.residencyApplication.significantWorkExperiences !== undefined && screenedData.residencyApplication.significantWorkExperiences === true &&
          <section id="significantExperiences" ref={(el) => (sectionRefs.current['significantExperiences'] = el)}>
           <SignificantExperience application={application} screenedData={screenedData}/>
          </section>
        } */}

        {/* {
          screenedData !== null && screenedData !== undefined && screenedData.residencyApplication !== null && screenedData.residencyApplication !== undefined &&
          screenedData.residencyApplication.hobbiesInterests !== null && screenedData.residencyApplication.hobbiesInterests !== undefined && screenedData.residencyApplication.hobbiesInterests === true &&
          <section id="hobbies" ref={(el) => (sectionRefs.current['hobbies'] = el)}>
            <div className="application-view-main-demographics" style={{marginBottom:'3vh'}}>
              <div
                className="application-view-main-demographics-header"
                style={{ marginBottom: "2vh" }}
              >
                <h2 className="application-view-main-title">
                  HOBBIES AND INTERESTS
                </h2>
                <seperator className="application-view-main-demographics-seperator" />
              </div>
              <p style={{ textAlign: "justify", fontSize:'13px', fontWeight:'300', width:'47vw' }}>{application.hobbiesAndInterests}</p>
            </div>
          </section>
        } */}

     
        {/* {
          screenedData !== null && screenedData !== undefined && screenedData.residencyApplication !== null && screenedData.residencyApplication !== undefined &&
          screenedData.residencyApplication.shortQuestions !== null && screenedData.residencyApplication.shortQuestions !== undefined && screenedData.residencyApplication.shortQuestions === true &&
          <section id="shortQA" ref={(el) => (sectionRefs.current['shortQA'] = el)}>
            <ShortQA application={application} />
          </section>
        } */}

       
       
      
       



{/* 

        <section id="signalStatement" ref={(el) => (sectionRefs.current['signalStatement'] = el)}>

        {application.isSignal && application.isSignal === "Yes" && (
        <div className="application-view-main-demographics">
          <div
            className="application-view-main-demographics-header"
            style={{ marginBottom: "2vh" }}
          >
            <h2 className="application-view-main-title">SIGNAL STATEMENT</h2>
            <seperator className="application-view-main-demographics-seperator" />
          </div>
          <p style={{ textAlign: "justify",fontSize: '13px' }}>{application.signalEssay}</p>
        </div>
        )}
        </section>
         */}


        {
          screenedData !== null && screenedData !== undefined && screenedData.sections !== null && screenedData.sections !== undefined &&
          screenedData.sections.lors !== null && screenedData.sections.lors !== undefined && screenedData.sections.lors === true &&
          <section id="lettersOfRecommendation" ref={(el) => (sectionRefs.current['lettersOfRecommendation'] = el)}>
             <div className="application-view-main-demographics">
            <div
                className="application-view-main-demographics-header"
                style={{ marginBottom: "2vh" }}
            >
                <h2 className="application-view-main-title">CV</h2>
                <seperator className="application-view-main-demographics-seperator" />
            </div>
            </div>


            <div className="application-view-main-demographics">
    


{application.cvUrl && application.cvUrl.split('.').pop() === 'pdf' && (
  <iframe
    src={application.cvUrl}
    style={{ width: '100%', height: '500px' }} // Adjust width and height as needed
    frameBorder="0"
  ></iframe>
)}
    
{application.cvUrl && application.cvUrl.split('.').pop() !== 'pdf' && (
  <OfficeViewer docUrl={application.cvUrl} />
  )}

        

            </div>
          </section>
        }
       

      </div>
    );
}

export default ApplicationViewMain;