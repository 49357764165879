import React from "react";
import "./styles/leftNavBar.css";
import navBarLogo from "../../../assets/imgs/logo.svg";
import { useNavigate } from 'react-router-dom';
import packageInfo from '../../../../package.json';

const LeftNavBar = ({ page, setPage, applicant, resetApplication }) => {
    const navigate = useNavigate();
    const initials = (applicant?.firstName.charAt(0) + applicant?.lastName.charAt(0)) ?? '';

    const handleLogout = () => {
        localStorage.removeItem('login_token');
        navigate('/');
    }
    return (
        <div className='left-navBar-container'>
            <div className="left-navBar-header">
                <div >
                <img className="left-navBar-profile-photo" src={applicant && applicant.profilePhotoUrl ?applicant.profilePhotoUrl:`https://ui-avatars.com/api/?name=${initials}`} alt="profile" />
                    {/* <img className="left-navBar-profile-photo" src={applicant && applicant.profilePhotoUrl ?applicant.profilePhotoUrl :  "https://i.pravatar.cc/150?img=18"} alt="profile" /> */}
                    </div>
                <div >
                    <h2 className="left-navBar-header-profile-name">{applicant && applicant.firstName + " " + applicant.lastName}</h2>
                </div>
            </div>
            <ul>
                <li className={`left-navBar-item ${page === 'home' ? 'active' : ''}`} onClick={() => {setPage('home'); resetApplication(); }}>Home</li>
                <li className={`left-navBar-item ${page === 'appManager' ? 'active' : ''}`} onClick={() => setPage('appManager')}>Application Manager</li>
                {/* <li className={`left-navBar-item ${page === 'documents' ? 'active' : ''}`} onClick={() => setPage('documents')}>Documents</li> */}
                {/* <li className={`left-navBar-item ${page === 'lors' ? 'active' : ''}`} onClick={() => setPage('lors')}>Letters of Recommendation</li> */}
                {/* <li className={`left-navBar-item ${page === 'interviewManager' ? 'active' : ''}`} onClick={() => setPage('interviewManager')}>Interview Manager</li> */}
                {/* <li className={`left-navBar-item ${page === 'archive' ? 'active' : ''}`} onClick={() => setPage('archive')}>Archive</li> */}
            </ul>
            <separator className="left-navBar-separator"/>
            <ul>
                <li className={`left-navBar-item ${page === 'settings' ? 'active' : ''}`} onClick={() => setPage('settings')}>Settings</li>
                <li className={`left-navBar-item ${page === 'help' ? 'active' : ''}`} onClick={() => setPage('help')}>Help</li>
                <li className="left-navBar-item" onClick={handleLogout}>Log Out</li>
            </ul>
            <div className="left-navBar-footer" style={{marginTop:'15vh'}}>
                {/* <img src={navBarLogo} alt="logo" /> */}
                <p className="left-navBar-footer-text">{packageInfo.version}</p>
                <p className="left-navBar-footer-text">{new Date().getFullYear()} DDH | All Rights Reserved</p>
            </div>
        </div>
    );
}

export default LeftNavBar;