import React,{ useState, useEffect, useContext} from "react";
import GeneralTable from './generalTable';
import './applicationView.css';

const PersonalStatement = ({ applications }) => {
    const columns = [
        {
            id: 'title',
            Header: <label>Title</label>,
            Cell: ({ row }) => (
                <div>{row.original.name}</div>
            )
        }
    ]


    return (
        <div className="personal-container">
         <GeneralTable columns={columns} data={applications} onSelectionChange={() => {}} onSelectedApplication={() => {}} sortData={() => {}} />
        </div>
    )
}

export default PersonalStatement;