import React from 'react';
import './styles/homePageTitle.css'; // Assuming styles are in CentralApplicationHeader.css

const homePageTitle = () => {
  return (
    <header style={{ textAlign: 'center', padding: '1vh', backgroundColor: "#fff"}}>
      {/* <h3 style={{ width: '100%', height: '24px', textAlign: 'left', fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: 600, fontSize: '20px', color: '#8A8A8A', flex: 'none', order: 0, flexGrow: 0 }}>Welcome to the </h3> */}
      {/* <h1 style={{ textAlign:'center', width: '100%', height: '87px', fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: 500, fontSize: '4vw', lineHeight: '120.5%', color: '#171717', flex: 'none', order: 1, flexGrow: 1}}>Smart Recruiting</h1> */}
    {/* <p style={{marginBottom:'2vh'}}>
    Welcome Back! <br/>
To log in to OAS, please enter your email or cellphone, you will then recive a verification code to your email/phone, type in the authentication code to log in.
    </p> */}
    </header>
  );
};

export default homePageTitle;
