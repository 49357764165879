import React,{useState, useContext, useEffect, useRef} from "react";
import { UserContext } from '../../../context/userContext';
import gearIcon from '../../../assets/imgs/gearIcon.svg';
import GreenUploadIcon from '../../../assets/imgs/greenUploadIcon.svg';
import RedUploadIcon from '../../../assets/imgs/redUploadIcon.svg';
import { InstitutionIdContext } from '../institutionIdContext';
import { getApplicantsByInstitutionId, getApplicantCountByInstitutionId } from './graphql/queries';
import { useQuery } from '@apollo/client';
import ApplicantsTable from './applicantsTable';
import './index.css';
import Spinner from "../../common/spinner";
import ApplicationView from './applicationView';
import Pagination from './paginationComponent';
import PageSizeSelector from './pageSizeSelector';
import MenuModal from "./menuModal";

const Applicants = ({ shouldRefreshApplicants, setShouldRefreshApplicants }) => {
    const { user } = useContext(UserContext);
    const institution = useContext(InstitutionIdContext);
    const institutionId = institution.id;
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [applicants, setApplicants] = useState([]);
    const [search, setSearch] = useState({field: 'firstName', value: null});
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [sort, setSort] = useState({ field: 'lastName', direction: 'asc'});
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedApplicant, setSelectedApplicant] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [applicationViewOpen, setApplicationViewOpen] = useState(false);
    const [totalApplications, setTotalApplications] = useState(0);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const buttonRef = useRef(null);
    const [shouldRefetch, setShouldRefetch] = useState(false);


    const indeterminateRef = React.useRef(null);

    const getStatusField = (applicant) => {
        console.log("applicant in status =>", applicant)
        if(applicant.status === "Pending Verification") return "Pending Verification"
    }

    const getStatusColor = (applicant) => {
        switch(applicant.status) {
            case "Verified": 
            case "Application Started":
            return "black"
            case "Pending Verification": 
            return "#B7A930"
            case "Application Submitted":
            return "#30B776"
            case "Application Suspended":
            case "Flagged":
            return "red"
            default: 
            return "black"
        }


      

    }


    const columns = [
        {
            accessor: 'id',
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                 <input type="checkbox" ref={indeterminateRef} {...getToggleAllRowsSelectedProps()} style={{ accentColor: "#145388"}}/>
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <input type="checkbox" {...row.getToggleRowSelectedProps()} checked={isRowSelected(row.original.id)}   onChange={() => handleCheckboxChange(row)} style={{ accentColor: "#145388"}} />
              </div>
            )
        },
        {
            accessor: 'firstName',
            id: 'firstName',
            Header: <label>Full Name</label>,
            Cell: ({ row }) => (
                <div className="clickable-cell" onClick={() => { setSelectedApplicant(row); setApplicationViewOpen(prevState => !prevState)}}>{row.original.firstName} {row.original.lastName}</div>
            )
        },
        {
            accessor: 'status',
            id: 'status',
            Header: <label>Status</label>,
            Cell: ({ row }) => (
                <div style={{ color: getStatusColor(row.original) }}>{row.original.status}</div>
            )
        },
        // {
        //     id: 'ps',
        //     Header: <label>Personal Statement</label>,
        //     Cell: ({ row }) => (
        //         <div></div>
        //     )
        // },
        {
            id: 'docs',
            Header: <label>Documents</label>,
            Cell: ({ row }) => (
                <div className="docs-container">
                    <label className="label-docs" style={row.original.mspe === null  
                    || (row.original.mspe !== undefined && row.original.mspe !== null && row.original.mspe.stage !== "Uploaded") ? { color: 'red'} : { color: '#30B776'}}>
                        <img className="docs-icon" src={row.original.mspe === null  
                    || (row.original.mspe !== undefined && row.original.mspe !== null && row.original.mspe.stage !== "Uploaded") ? RedUploadIcon : GreenUploadIcon} alt="upload-icon" style={{ marginRight: '0.3vw'}}/>
                        MSPE</label>
                    <label className="label-docs" style={row.original.transcript === null  
                    || (row.original.transcript !== undefined && row.original.transcript !== null && row.original.transcript.stage !== "Uploaded") ? { color: 'red'} : { color: '#30B776'}}>
                         <img className="docs-icon" src={row.original.transcript === null  
                    || (row.original.transcript !== undefined && row.original.transcript !== null && row.original.transcript.stage !== "Uploaded") ? RedUploadIcon : GreenUploadIcon} alt="upload-icon" style={{ marginRight: '0.3vw'}}/>
                        Transcript</label>
                    <label className="label-docs" style={row.original.scores === null  
                    || (row.original.scores !== undefined && row.original.scores !== null && row.original.scores.stage !== "Uploaded") ? { color: 'red'} : { color: '#30B776'}}>
                         <img className="docs-icon" src={row.original.scores === null  
                    || (row.original.scores !== undefined && row.original.scores !== null && row.original.scores.stage !== "Uploaded") ? RedUploadIcon : GreenUploadIcon} alt="upload-icon" style={{ marginRight: '0.3vw'}}/>
                        Standarized Scores</label>
                    {
                        institution.id === "6672c992b4519d1feba815e0" &&
                         <label className="label-docs" style={row.original.ecfmgStatusReport === null  
                        || (row.original.ecfmgStatusReport !== undefined && row.original.ecfmgStatusReport !== null && row.original.ecfmgStatusReport.stage !== "Uploaded") ? { color: 'red'} : { color: '#30B776'}}>
                            <img className="docs-icon" src={row.original.ecfmgStatusReport === null  
                        || (row.original.ecfmgStatusReport !== undefined && row.original.ecfmgStatusReport !== null && row.original.ecfmgStatusReport.stage !== "Uploaded") ? RedUploadIcon : GreenUploadIcon} alt="upload-icon" style={{ marginRight: '0.3vw'}}/>
                            Ecfmg Status Report</label>
                    }
                  
                </div>
            )
        },
        {
            id: 'lor',
            Header: <label>Letters Of Recommendation</label>,
            Cell: ({ row }) => (
                <div>{row.original.lors.length === 0 ? '0 Requested' : `${row.original.lors.filter(elem => elem.stage === "Submitted").length}/${row.original.lors.length} Submitted`}</div>
            ),
        },
        // {
        //     id: 'Advisor',
        //     Header: <label>Advisor</label>,
        //     Cell: ({ row }) => (
        //         <div></div>
        //     )
        // },
        // {
        //     Header: '',
        //     accessor: 'action',
        //     Cell: ({ row }) => (
        //         <div className="dropdown-point">
        //         <div className='toggle-points' onClick={toggleOpen}>
        //             <span className='point'/>
        //             <span className='point'/>
        //             <span className='point'/>
        //         </div>
        //         {isOpen && (
        //             <div className="dropdown-menu" style={{display:'flex',flexDirection:'column', padding:'10%',}}>
        //                 {/* <a href="#" onClick={() => handleSendReminder(original)}>Remind</a>
        //                 <a href="#" onClick={() => cancelLor(original)}>Cancel</a> */}
        //             </div>
        //         )}
        //     </div>
        //     ),
        // },
    ]

    const { data: applicantsData, error: applicantsError, isLoading: applicantsLoading , refetch: refetchApplicants} = useQuery(getApplicantsByInstitutionId, {
        variables: {
            institutionId,
            search,
            first: offset,
            limit,
            sort
        },
        fetchPolicy: 'network-only'
    });

    const { data: applicantsCountData, error: applicantsCountError, isLoading: applicantsCountLoading , refetch: refetchCountApplicants} = useQuery(getApplicantCountByInstitutionId, {
        variables: {
            institutionId,
            search,
        },
        fetchPolicy: 'network-only'
    });

    useEffect(() => {
        console.log("shouldRefetch =>", shouldRefetch)
        if(shouldRefetch) {
            refetchApplicants()
            console.log('in da useEffect in da applicants shouldRefetch =>', shouldRefetch)
            setShouldRefetch(false)
            if (selectedApplicant && applicants.length > 0) {
                // Find the updated applicant data from the applicants list
                const updatedApplicant = applicants.find(applicant => applicant.id === selectedApplicant.id);
                // Check if the updatedApplicant exists and is different from the current selectedApplicant
                    setSelectedApplicant(updatedApplicant);
                    console.log('updatedApplicant  in da refetch in applicants=>', updatedApplicant)
            }
        }
    }, [shouldRefetch,setShouldRefetch, refetchApplicants])  

    useEffect(() => {
        console.log('in da useEffect in da applicants shouldRefreshApplicants =>', shouldRefreshApplicants)
        if(shouldRefreshApplicants) {
            refetchApplicants()
            setShouldRefreshApplicants(false)
        }
    }, [shouldRefreshApplicants, setShouldRefreshApplicants, refetchApplicants])

    useEffect(() => {
        if(applicantsLoading) {
            return;
        } else if(applicantsError) {
            console.error(applicantsError)
            return;
        } else if(applicantsData && applicantsData.getApplicantsByInstitutionId) {
            console.log("applicantsData =>", applicantsData)
            let apps = applicantsData.getApplicantsByInstitutionId
            if(JSON.stringify(apps) !== JSON.stringify(applicants)) {
                setApplicants(apps)
            }

            if (selectedApplicant && apps.length > 0) {
                // Find the updated applicant data from the applicants list
                var updatedApplicant = apps.filter(applicant => applicant.id === selectedApplicant.id);
                if(updatedApplicant.length > 0) {
                    updatedApplicant = updatedApplicant[0]
                    // Check if the updatedApplicant exists and is different from the current selectedApplicant
                    setSelectedApplicant(updatedApplicant);
                    console.log('updatedApplicant  in da refetch in applicants=>', updatedApplicant)
                    }
                }
               
        }
    }, [applicantsLoading, applicantsError, applicantsData, applicants, setApplicants, selectedApplicant])
   
    useEffect(() => {
        if(applicantsCountLoading) {
            return;
        } else if(applicantsCountError) {
            console.error(applicantsCountError)
            return;
        } else if(applicantsCountData && applicantsCountData.getApplicantCountByInstitutionId) {
            console.log("applicantsCountData =>", applicantsCountData)
            let appsCount = applicantsCountData.getApplicantCountByInstitutionId
            if (JSON.stringify(totalApplications) !== JSON.stringify(appsCount)) {
                setTotalApplications(appsCount);
                console.log("countRes =>", appsCount);
                console.log("total pages =>", Math.ceil(appsCount/ limit))
                setTotalPages(Math.ceil(appsCount/ limit))

             
            }
        }
    }, [applicantsCountLoading, applicantsCountError, applicantsCountData, setTotalApplications, setTotalPages, totalApplications, totalPages, limit])


    const isRowSelected = (id) => {
        const isSelected = selectedRows.some(row => row.id === id);
        return isSelected
    }

    const handleButtonClick = () => {
        setMenuOpen((prev) => !prev);
      };

    const handlePageSizeChange = (event) => {
        setLimit(Number(event.target.value));
        setOffset(0)
        setCurrentPage(1); // Reset to first page when page size changes
        setTotalPages(Math.ceil(totalApplications/ Number(event.target.value)))
        refetchApplicants()
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setOffset((page - 1) * limit )
        refetchApplicants()

    };

    console.log("institutionId inside applicants =>", institutionId)

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchInput(value)
       
        // debouncedSearch(value); // Update the search query after debounce
    }

    const updateSelectedRows = (rows) => {
        setSelectedRows(rows)
    }

    const handleSelectedApplicant = (row) => {
        setSelectedApplicant(row)
    }

    const sortApplicants = (column, direction) => {
        console.log("column in the sortApplications function=>", column, direction)
        setSort({ field: column, direction: direction === true ? "asc": "desc"})
        refetchApplicants()
    }

    const handleCheckboxChange = (row) => {

        let selectedRowId = row.original.id
        let filtered = selectedRows.filter(elem => elem.id.toString() === selectedRowId.toString())
        if(filtered.length > 0) {
            setSelectedRows(prevSelected => prevSelected.filter(selected => selected.id.toString() !== row.original.id.toString()))
        } else {
            setSelectedRows(prevSelected => [...prevSelected, row.original])
        }

    }

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        console.log("search changed =>", search)
    }, [search])

    const updateSearchField = (field) => {
        var searchObj = { ...search}
        searchObj.field = field
        setSearch(searchObj)
    }

    return (
        <div className='faculty-home-container'>
                <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="med-home-header">
                <div className="left-header-container">
                    <h2 className="faculty-home-header-title">Students</h2>
                    {/* <p>Temp text</p> */}
                </div>
                <div className="right-top-container">
                    <div className="right-header-container">
                        <div className="input-container">
                        <i className="fas fa-search"></i>
                        <input
                            className="applicant-top-bar-search-input"
                            placeholder="Search"
                            value={searchInput}
                            onChange={handleSearchChange}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    var searchObj = { ...search}
                                    searchObj.value = searchInput
                                    setSearch(searchObj)
                                    refetchApplicants()
                                }
                            }}
                        />
                        </div>
                        {isMenuOpen && (
                            <MenuModal
                            search={search}
                            isOpen={isMenuOpen}
                            updateSearchField={updateSearchField}
                            onClose={() => setMenuOpen(false)}
                            style={{
                                top: buttonRef.current?.getBoundingClientRect().bottom + window.scrollY + 'px',
                                left: buttonRef.current?.getBoundingClientRect().left + window.scrollX + 'px'
                            }}
                            />
                        )}
                        {/* <div className="filter-menu">
                            FirstName
                        </div> */}

<button className="filter-button" style={{marginLeft:'10.8vw'}} onClick={handleButtonClick} ref={buttonRef}>
                            <img  className="applicant-filter-icon" style={{width:'44px', padding:'1vh'}} src={gearIcon} alt="search" />
                        </button>
                    </div>
                    <div className="right-button-container">
                        <button className="green-button" style={{display:'none'}}>Bulk Print requests</button>
                        <button className="green-button" style={{display:'none', marginLeft: '1vw'}}>Change Season</button>
                    </div>
                </div>
            </div>
            <seperator className="faculty-home-header-seperator"/>
            <div className="faculty-home-body" style={{ position: 'absolute', width: '78%', gap: 0}}>
            {
                applicantsLoading ? <Spinner />
                :
                <ApplicantsTable columns={columns} data={applicants} onSelectionChange={updateSelectedRows} onSelectedApplication={handleSelectedApplicant} sortData={sortApplicants} />
            }
             <div className="faculty-application-table-footer" style={{ height: '4vh'}}>
                <PageSizeSelector itemsPerPage={limit} onPageSizeChange={handlePageSizeChange} />
                <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
                </div>
            </div>
            {
                applicationViewOpen && <ApplicationView shouldRefetch={shouldRefetch} institutionId={institutionId} setShouldRefetch={setShouldRefetch} selectedApplicant={selectedApplicant} onClose={() => {setApplicationViewOpen(prevState => !prevState); refetchApplicants()}} />
            }
        </div>
    )
}

export default Applicants;