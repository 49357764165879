import React from 'react';
import './styles/footer.css'; // Assuming styles are in Header.css

const Footer = () => {
  return (
   <footer className="footer-container">
    <p>Powered by Dualiti Digital Health, Inc.</p>
   </footer>
  );
};

export default Footer;
