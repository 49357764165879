import React,{useState, useContext, useEffect, useRef} from "react";
import { getInstitutionsWithPaginationQuery, getInstitutionsWithPaginationCountQuery } from './graphql/queries';
import GeneralTable from './generalTable';
import {useQuery, useMutation} from '@apollo/client';
import AddInstitutionModal from "./addInstitutionModal";
import EditInstitutionModal from "./EditInstitutionModal";
import { createInstitutionMutation, updateInstitutionMutation, deleteInstitutionMutation } from './graphql/mutations';

const AdminInstitutions = ({ setPage }) => {
    const [institutions, setInstitutions] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(20);
    const [filter, setFilter] = useState({field: 'name', value: null});
    const [sort, setSort] = useState({ field: 'name', direction: 'desc'});
    const [totalInstitutions, setTotalInstitutions] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [searchInput, setSearchInput] = useState('');
    const [selectedInstitution, setSelectedInstitution] = useState(null);
    const [otherViewOpen, setOtherViewOpen] = useState(false);
    const [editReqOpen, setEditReqOpen] = useState(false);
    const [editTimelineOpen, setEditTimelineOpen] = useState(false);
    const [addInstitutionOpen, setAddInstitutionOpen] = useState(false);
    const [updateInstitutionOpen, setUpdateInstitutionOpen] = useState(false);
    const [loadingData, setLoadingData] = useState(false)


    const [ createInstitution ] = useMutation(createInstitutionMutation);
    const [ updateInstitution ] = useMutation(updateInstitutionMutation);
    const [ deleteInstitution ] = useMutation(deleteInstitutionMutation); 


    const { loading: loadingInstitutions, error: errorInstitutions, data: InstitutionsData, refetch: refetchInstitutions } = useQuery(getInstitutionsWithPaginationQuery, {
        variables: {
            offset,
            limit,
            filter,
            sort
        }
    })

    const { loading: loadingInstitutionsCount, error: errorInstitutionsCount, data: institutionsCount, refetch: refetchInstitutionsCount } = useQuery(getInstitutionsWithPaginationCountQuery, {
        variables: {
            filter,
        }
    })


    useEffect(() => {
        setLoadingData(true)
        if(!loadingInstitutions && !errorInstitutions && InstitutionsData) {
            console.log("InstitutionsData =>", InstitutionsData.getInstitutionsWithPagination)
            setInstitutions(InstitutionsData.getInstitutionsWithPagination)
        }
        setLoadingData(false)
    }, [loadingInstitutions, errorInstitutions, InstitutionsData, setInstitutions])


    useEffect(() => {
        if(!loadingInstitutionsCount && !errorInstitutionsCount && institutionsCount) {
            console.log("InstitutionsCount =>", institutionsCount.getInstitutionsWithPaginationCount)
            setTotalInstitutions(institutionsCount.getInstitutionsWithPaginationCount)
            setTotalPages(Math.ceil(institutionsCount.getInstitutionsWithPaginationCount/ Number(limit)))
        }
    }, [loadingInstitutionsCount, errorInstitutionsCount, institutionsCount,setTotalPages, setTotalInstitutions, limit])


    const columns = [
        {
            accessor: 'name',
            id: 'name',
            Header: <label style={{width:'18vw'}}>Institution Name</label>,
            Cell: ({ row }) => (
                <div style={{width:'21vw'}}>{row.original.name}</div>
            ),  
        },
        {
            accessor: 'isInternational',
            id: 'isInternational',
            Header: <label style={{width:'4vw'}}>International</label>,
            Cell: ({ row }) => (
                <div style={{width:'4vw'}}>{row.original.isInternational === null || row.original.isInternational === undefined ? "" : row.original.isInternational === true ? "Yes" : "No"}</div>
            ),  
        },
        {
            accessor: 'isCa',
            id: 'isCa',
            Header: <label style={{width:'4vw'}}>Canadian School</label>,
            Cell: ({ row }) => (
                <div style={{width:'4vw'}}>{row.original.isCa === null || row.original.isCa === undefined ? "" : row.original.isCa === true ? "Yes" : "No"}</div>
            ),  
        },
        {
            id: 'actions',
            Header: <label style={{width:'4vw'}}>Actions</label>,
            Cell: ({ row }) => (
                <div className="dropdown-point">
                    <div className='toggle-points' onClick={() => toggleSelected(row.original)}>
                        <span className='point'/>
                        <span className='point'/>
                        <span className='point'/>
                    </div>
                    {
                        otherViewOpen === false && selectedInstitution && row.original.id.toString() === selectedInstitution.id.toString() && (
                        <div className="dropdown-menu" style={{display:'flex',zIndex: 999, right: '6vw', flexDirection:'column', padding:'10%',}}>
                            <a href="#" onClick={() => { setOtherViewOpen(true); setUpdateInstitutionOpen(prevState => !prevState);}}>Edit Institution</a>
                            <a href="#" onClick={() => { setOtherViewOpen(true); removeInstitution(row.original); }}>Delete Institution</a>
                        </div>)
                    }
                </div> 
            ),  
        }, 
    ]

    const toggleSelected = (institution) => {
        console.log("toggleSelected =>", institution)
        if(selectedInstitution === null) {
            setSelectedInstitution(institution)
        } else {
            if(selectedInstitution.id.toString() === institution.id.toString()) {
                // close menu
                setSelectedInstitution(null)
            } else  {
                setSelectedInstitution(null)
                setSelectedInstitution(institution)
            }
        }
    }

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchInput(value)
        var searchObj = { ...filter}
        searchObj.value = value
        setLoadingData(true)
        setFilter(searchObj)
    }

    const handlePageSizeChange = (event) => {
        setLimit(Number(event.target.value));
        setOffset(0)
        setCurrentPage(1); // Reset to first page when page size changes
        setTotalPages(Math.ceil(totalInstitutions/ Number(event.target.value)))
        setLoadingData(true)
        refetchInstitutions()
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setOffset((page - 1) * limit )
        setLoadingData(true)
        refetchInstitutions()
    };

    const saveInstitution = (input) => {
        const newInstitution = {
            name: input.name,
            isCa: input.isCa === "yes" ? true: false,
            isInternational: input.isInternational === "yes" ? true: false,
            isDo: input.isDo === "yes" ? true: false,
        }

        createInstitution({
            variables: {
                input: newInstitution
            }
        })
        .then(res => {
            refetchInstitutions()
            setOtherViewOpen(false)
        })


    }

    const sortData = (column, direction) => {
        setSort({ field: column, direction: direction === true ? "asc": "desc"})
        refetchInstitutions()
    }

    const removeInstitution = (institution) => {
        deleteInstitution({
            variables: {
                id: institution.id
            }
        })
        .then(res => {
            refetchInstitutions()
            setSelectedInstitution(null)
            setOtherViewOpen(false)
        })
    }

    const updateExistingInstitution = (institution) => {
        updateInstitution({
            variables: {
                id: selectedInstitution.id,
                input: {
                    name: institution.name,
                    isCa: institution.isCa === "yes" ? true: false,
                    isInternational: institution.isInternational === "yes" ? true: false,
                    isDo: institution.isDo === "yes" ? true: false,
                }
            }
        })
        .then(res => {
            refetchInstitutions()
            setSelectedInstitution(null)
            setOtherViewOpen(false)
        })
    }


    return (
        <div className='faculty-home-container'>
            <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop2' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="faculty-home-header">
                <h2 className="faculty-home-header-title">INSTITUTIONS</h2>
            </div>
            <seperator className="faculty-home-header-seperator"/>
            <div className="right-header-container" style={{ marginBottom: '2vh'}}>
                <div className="input-container">
                    <i className="fas fa-search"></i>
                    <input
                        className="applicant-top-bar-search-input"
                        placeholder="Search"
                        value={searchInput}
                        onChange={handleSearchChange}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                refetchInstitutions()
                            }
                        }}
                    />
                </div>
                <button className="green-button" onClick={() => setAddInstitutionOpen(prevState => !prevState)}>Create New Institution</button>
            </div>
            <GeneralTable columns={columns} data={institutions} limit={limit} totalPages={totalPages} currentPage={currentPage} handlePageSizeChange={handlePageSizeChange} handlePageChange={handlePageChange} loadingData={loadingData} sortData={sortData}/>
            { 
                addInstitutionOpen &&
                <AddInstitutionModal isOpen={addInstitutionOpen} onClose={() => setAddInstitutionOpen(prevState =>  !prevState)} saveInstitution={saveInstitution}/>
            }
            {
                updateInstitutionOpen && 
                <EditInstitutionModal isOpen={updateInstitutionOpen} onClose={() => setUpdateInstitutionOpen(prevState => !prevState)} updateInstitution={updateExistingInstitution} selectedInstitution={selectedInstitution}/>
            }
        </div>
    )
}

export default AdminInstitutions;