import React, { useContext, useEffect, useState } from "react";
import "./styles/leftNavBar.css";
import navBarLogo from "../../../assets/imgs/logo.svg";
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/userContext';
import packageInfo from '../../../../package.json';


const LeftNavBar = ({ userRoles, page, setPage, isPreSeason, setIsApplicationsClicked }) => {
    const [adminMenuOpen, setIsAdminMenuOpen] = useState(false)
    const { user, topUser, setUser, setTopUser } = useContext(UserContext);
    const [initials, setInitials] = useState('');

    const navigate = useNavigate();

useEffect(() => {
    if (user.firstName && user.lastName){
    setInitials(user.firstName.charAt(0) + user.lastName.charAt(0));
}
}, [user])

    useEffect(() => {
        if (userRoles.includes('Recommender')&&userRoles.length===1) {
            setPage('recommendations');
        }
    }, [userRoles])

    const handleLogout = () => {
        localStorage.removeItem('login_token');
        navigate('/');
    }

    const accountAccessClicked = () => {
        if(topUser !== null && topUser !== undefined) {
            setUser(topUser)
            setTopUser(null)
            setPage('home')
        } else {
            setPage('accountAccess')
        }
       
    }

    return (
        <div className='left-navBar-container' style={{ overflowY: 'auto'}}>
            <div className="left-navBar-header">
                <div >
                    <img className="left-navBar-profile-photo" src={user.profilePhotoUrl?user.profilePhotoUrl:`https://ui-avatars.com/api/?name=${initials}`} alt="profile" />
                    </div>
                <div >
                    <h2 className="left-navBar-header-profile-name">{user.firstName} {user.lastName}</h2>
                </div>
            </div>
            <ul>
                <li className={`left-navBar-item ${page === 'home' ? 'active' : ''}`} onClick={() => setPage('home')}>Home</li>
                {/* <li className={`left-navBar-item ${page === 'workFlows' ? 'active' : ''}`} onClick={() => setPage('workFlows')}>Work Flows</li> */}
                {/* {(userRoles.includes('PC')||userRoles.includes('PD'))&&  <li className={`left-navBar-item ${page === 'rubric' ? 'active' : ''}`} onClick={() => setPage('rubric')}>Rubric</li>} */}
                {/* {(userRoles.includes('PC')||userRoles.includes('PD'))&&<li className={`left-navBar-item ${page === 'customizeApplication' ? 'active' : ''}`} onClick={() => setPage('customizeApplication')}>Customize Application</li>} */}
                {/* {(userRoles.includes('PC')||userRoles.includes('PD'))&&<li className={`left-navBar-item ${page === 'myTeam' ? 'active' : ''}`} onClick={() => setPage('myTeam')}>My Team</li>} */}
                <li className={`left-navBar-item ${page === 'positions' ? 'active' : ''}`} onClick={() => setPage('positions')}>Positions</li>
                {/* {(userRoles.includes('PC')||userRoles.includes('PD'))&&!isPreSeason&&<li className={`left-navBar-item ${page === 'manageReviews' ? 'active' : ''}`} onClick={() => setPage('manageReviews')}>Reviews</li>} */}
                <li className={`left-navBar-item ${page === 'applications' ? 'active' : ''}`} onClick={() => {setPage('applications'); setIsApplicationsClicked(true);}}>Applicants</li>
                {/* {(userRoles.includes('PC')||userRoles.includes('PD')||userRoles.includes('Reviewer'))&&!isPreSeason&&<li className={`left-navBar-item ${page === 'groups' ? 'active' : ''}`} onClick={() => setPage('groups')}>Folders</li>} */}
                {/* {(userRoles.includes('PC')||userRoles.includes('PD')) &&!isPreSeason&&<li className={`left-navBar-item ${page === 'completedReviews' ? 'active' : ''}`} onClick={() => setPage('completedReviews')}>Completed Reviews</li>} */}
                <li className={`left-navBar-item ${page === 'interviewManager' ? 'active' : ''}`} onClick={() => setPage('interviewManager')}>Interview Manager</li>
                <li className={`left-navBar-item ${page === 'emailTemplates' ? 'active' : ''}`} onClick={() => setPage('emailTemplates')}>Communications</li>

                
                {/* {userRoles.includes('Recommender')&&<li className={`left-navBar-item ${page === 'recommendations' ? 'active' : ''}`} onClick={() => setPage('recommendations')}>Recommendations</li>} */}
            </ul>
            <separator className="left-navBar-separator"/>
            <ul>
                {user.userType === 'admin' && (
                    <>
                <li className={`left-navBar-item ${page === 'adminPortal' ? 'active' : ''}`} onClick={() => setIsAdminMenuOpen(prevState => !prevState)}>Admin</li>
                {
                    adminMenuOpen &&
                    <div>
                        <li className={`left-navBar-item ${page === 'adminUsers' ? 'active' : ''}`} style={{marginLeft: '1vw'}} onClick={() => setPage('adminUsers')}>Users</li>
                        <li className={`left-navBar-item ${page === 'adminSpecialties' ? 'active' : ''}`} style={{marginLeft: '1vw'}} onClick={() => setPage('adminSpecialties')}>Specialties</li>
                        <li className={`left-navBar-item ${page === 'adminPrograms' ? 'active' : ''}`} style={{marginLeft: '1vw'}} onClick={() => setPage('adminPrograms')}>Programs</li>
                        <li className={`left-navBar-item ${page === 'adminMasterApplications' ? 'active' : ''}`} style={{marginLeft: '1vw'}} onClick={() => setPage('adminMasterApplications')}>Applications</li>
                        <li className={`left-navBar-item ${page === 'adminLors' ? 'active' : ''}`} style={{marginLeft: '1vw'}} onClick={() => setPage('adminLors')}>LoRs</li>
                        <li className={`left-navBar-item ${page === 'adminDocuments' ? 'active' : ''}`} style={{marginLeft: '1vw'}} onClick={() => setPage('adminDocuments')}>Documents</li>
                        <li className={`left-navBar-item ${page === 'adminEras' ? 'active' : ''}`} style={{marginLeft: '1vw'}} onClick={() => setPage('adminEras')}>ERAS Upload</li>
                        <li className={`left-navBar-item ${page === 'adminInstitutions' ? 'active' : ''}`} style={{marginLeft: '1vw'}} onClick={() => setPage('adminInstitutions')}>Institutions</li>

                    </div>
                }
                </>
                )}
                <li className={`left-navBar-item ${page === 'settings' ? 'active' : ''}`} onClick={() => setPage('settings')}>User Settings</li>
                <li className={`left-navBar-item ${page === 'programSettings' ? 'active' : ''}`} onClick={() => setPage('programSettings')}>Company Settings</li>
                <li className={`left-navBar-item ${page === 'contactUs' ? 'active' : ''}`} onClick={() => setPage('contactUs')}>Contact Us</li>
                {/* {(userRoles.includes('PC')||userRoles.includes('PD'))&&  <li className={`left-navBar-item ${page === 'tutorials' ? 'active' : ''}`} onClick={() => setPage('tutorials')}>Tutorials</li>} */}
                {/* <li className={`left-navBar-item ${page === 'accountAccess' ? 'active' : ''}`} onClick={accountAccessClicked}>{topUser !== null && topUser !== undefined? "Back to my account" : "Account Access"}</li> */}
                <li className="left-navBar-item" onClick={handleLogout}>Log Out</li>
            </ul>
            <div className="left-navBar-footer">
                {/* <img src={navBarLogo} alt="logo" /> */}
                <p className="left-navBar-footer-text">{packageInfo.version}</p>
                <p className="left-navBar-footer-text">{new Date().getFullYear() } DDH | All Rights Reserved</p>
            </div>
        </div>
    );
}

export default LeftNavBar;