import { gql } from "@apollo/client";

export const GET_PROGRAM_USERS_BY_PROGRAM_ID = gql`
query getProgramMembersByProgramId($programId: ID!) {
    getProgramMembersByProgramId(programId: $programId) {
      userId {
        id
        firstName
        lastName
        email
        isPending
      }
      roles
    }
  }
`;


export const getFacultyByInstitutionIdQuery = gql`
query getFacultyByInstitutionId($institutionId: ID!) {
    getFacultyByInstitutionId(institutionId: $institutionId) {
    id
    firstName
    lastName
    email
    isPending
    }
  }
`;