import React,{useState} from "react";

const ApplicantViewNavBar = ({onNavClick, currentSection}) => {
    const [isApplicationOpen, setIsApplicationOpen] = useState(false)
    

    const applicationSections = [
        {section: "Documents", id: "documents"},
        {section: "Letters of Recommendation", id: "lors"},
        {section: "Personal Statement", id: "personalStatment"},
        {section: "Programs", id: "programs"},
        // {section: "Interviews", id: "interviews"}
      ];
      
      const condClassName = (sectionId) => {
        return sectionId === currentSection ? "applicant-view-navbar-section-text-active" : "application-view-navbar-section-text";
    }


return (
    <div className="applicant-view-navbar">

{applicationSections.map((section, index) => (
    <div 
        className="applicant-view-navbar-item" 
        key={section.id} 
        onClick={() => {
            onNavClick(section.id)
            // setCurrentSection(section.id) // Add this line
        }}
    >
        <div className={condClassName(section.id)}>{section.section}</div>
    </div>
))}

    </div>
)
}

export default ApplicantViewNavBar;