import React,{useState, useContext, useEffect, useRef} from "react";
import {useQuery, useMutation} from '@apollo/client';
import { getLorsWithPaginationQuery, getLorsWithPaginationCountQuery } from './graphql/queries';
import GeneralTable from './generalTable';
import Pagination from './paginationComponent';
import PageSizeSelector from './pageSizeSelector';
import moment from 'moment';
import SpecificLor from "./specificLor";
import packageInfo from '../../../../../package.json';


const AdminLors  = ({ setPage }) => {
    const [lors, setLors] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(20);
    const [filter, setFilter] = useState({field: 'name', value: null});
    const [sort, setSort] = useState({ field: 'name', direction: 'desc'});
    const [totalLors, setTotalLors] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [searchInput, setSearchInput] = useState('');
    const [selectedLor, setSelectedLor] = useState(null);
    const [otherViewOpen, setOtherViewOpen] = useState(false);
    const [lorModalOpen, setLorModalOpen] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [loadingData, setLoadingData] = useState(false)

    const columns = [
        {
            accessor: 'applicantId.firstName',
            id: 'name',
            Header: <label style={{width:'4vw'}}>Applicant Name</label>,
            Cell: ({ row }) => (
                <div style={{width:'4vw'}}>{`${row.original.applicantId.firstName} ${row.original.applicantId.lastName}`}</div>
            ),  
        }, 
        {
            accessor: 'recommenderId.firstName',
            id: 'recommenderName',
            Header: <label style={{width:'4vw'}}>Recommender Name</label>,
            Cell: ({ row }) => (
                <div style={{width:'4vw'}}>{row.original.recommenderId ? `${row.original.recommenderId.firstName} ${row.original.recommenderId.lastName}` : ""}</div>
            ),  
        }, 
        {
            accessor: 'stage',
            id: 'stage',
            Header: <label style={{width:'4vw'}}>Status</label>,
            Cell: ({ row }) => (
                <div style={{width:'4vw'}}>{row.original.stage}</div>
            ),  
        }, 
        {
            accessor: 'docUrl',
            id: 'docUrl',
            Header: <label style={{width:'4vw'}}>Url</label>,
            Cell: ({ row }) => (
                <a style={{width:'4vw', color: 'blue'}} target="_blank" href={row.original.docUrl ? row.original.docUrl : ""}>{row.original.docUrl ? "Link" : ""}</a>
            ),  
        }, 
        {
            accessor: 'requestedAt',
            id: 'requestedAt',
            Header: <label style={{width:'4vw'}}>Requested At</label>,
            Cell: ({ row }) => (
                <div style={{width:'4vw'}}>{row.original.requestedAt ? moment(new Date(parseInt(row.original.requestedAt))).format('MM/DD/YYYY HH:mm'): ""}</div>
            ),  
        },
        {
            accessor: 'submittedAt',
            id: 'submittedAt',
            Header: <label style={{width:'4vw'}}>Submitted At</label>,
            Cell: ({ row }) => (
                <div style={{width:'4vw'}}>{row.original.submittedAt ? moment(new Date(parseInt(row.original.submittedAt))).format('MM/DD/YYYY HH:mm'): ""}</div>
            ),  
        },
        {
            id: 'actions',
            Header: <label style={{width:'4vw'}}>Actions</label>,
            Cell: ({ row }) => (
                <div className="dropdown-point">
                    <div className='toggle-points' onClick={() => toggleSelected(row.original)}>
                        <span className='point'/>
                        <span className='point'/>
                        <span className='point'/>
                    </div>
                    {
                        otherViewOpen === false && selectedLor && row.original.id.toString() === selectedLor.id.toString() && (
                        <div className="dropdown-menu" style={{display:'flex',zIndex: 999, right: '0', flexDirection:'column', padding:'10%',}}>
                            <a href="#" onClick={() => { setOtherViewOpen(true); setLorModalOpen(prevState => !prevState); }}>{ selectedLor && selectedLor.stage === "Submitted" ? "Update Lor" : "Upload Lor"}</a>
                            { selectedLor.stage === "Submitted" && <a href="#" onClick={() => { setOtherViewOpen(true); resendLorLink();}}>Resend Lor Link</a>}
                        </div>)
                    }
                </div> 
            ),  
        }, 
    ]

    const resendLorLink = () => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/resend_direct_lor`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
                'App-Version': packageInfo.version
            },
            body: JSON.stringify({ lorId: selectedLor.id }),
        })
        .then(res => {
            setShowSuccess(true)
            setOtherViewOpen(prevState => !prevState)
            setSelectedLor(null)
            setTimeout(() => {
                setShowSuccess(false)
            }, 5000);
        })

    }

    const toggleSelected = (lor) => {
        if(selectedLor === null) {
            setSelectedLor(lor)
        } else {
            if(selectedLor.id.toString() === lor.id.toString()) {
                // close menu
                setSelectedLor(null)
            } else {
                // other user clicked
                setSelectedLor(null)
                setSelectedLor(lor)
            }
        }
    }


    const { loading: loadingLors, error: errorLors, data: lorsData, refetch: refetchLors} = useQuery(getLorsWithPaginationQuery, {
        variables: {
            offset,
            limit,
            filter,
            sort
        }
    })

    const { loading: loadingLorsCount, error: errorLorsCount, data: lorsCount, refetch: refetchLorsCount } = useQuery(getLorsWithPaginationCountQuery, {
        variables: {
            filter,
        }
    })


    const sortData = (column, direction) => {
        setSort({ field: column, direction: direction === true ? "asc": "desc"})
        refetchLors()
    }

    useEffect(() => {
        setLoadingData(true)
        if(!loadingLors && !errorLors && lorsData) {
            console.log("lorsData =>", lorsData.getLorsWithPagination)
            setLors(lorsData.getLorsWithPagination)
            setLoadingData(false)
        }
    }, [loadingLors, errorLors, lorsData, setLors])

    useEffect(() => {
        if(!loadingLorsCount && !errorLorsCount && lorsCount) {
            console.log("lorsCount =>", lorsCount.getLorsWithPaginationCount)
            setTotalLors(lorsCount.getLorsWithPaginationCount)
            setTotalPages(Math.ceil(lorsCount.getLorsWithPaginationCount/ Number(limit)))
        }
    }, [loadingLorsCount, errorLorsCount, lorsCount, setTotalPages, setTotalLors, limit])


    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchInput(value)
        var searchObj = { ...filter}
        searchObj.value = value
        setLoadingData(true)
        setFilter(searchObj)
    }

    const handlePageSizeChange = (event) => {
        setLimit(Number(event.target.value));
        setOffset(0)
        setCurrentPage(1); // Reset to first page when page size changes
        setTotalPages(Math.ceil(totalLors/ Number(event.target.value)))
        setLoadingData(true)
        refetchLors()
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setOffset((page - 1) * limit )
        setLoadingData(true)
        refetchLors()
    };



    return (
        <div className='faculty-home-container'>
            <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop2' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="faculty-home-header">
                <h2 className="faculty-home-header-title">LORs</h2>
            </div>
            <seperator className="faculty-home-header-seperator"/>
            <div className="right-header-container" style={{ marginBottom: '2vh'}}>
                <div className="input-container">
                    <i className="fas fa-search"></i>
                    <input
                        className="applicant-top-bar-search-input"
                        placeholder="Search"
                        value={searchInput}
                        onChange={handleSearchChange}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                refetchLors()
                            }
                        }}
                    />
                </div>
                { showSuccess && <label style={{ color: "#145388", fontFamily: 'Montserrat'}}>Link sent successfully.</label>}
            </div>
            <GeneralTable columns={columns} data={lors} sortData={sortData} limit={limit} totalPages={totalPages} currentPage={currentPage} handlePageSizeChange={handlePageSizeChange} handlePageChange={handlePageChange} loadingData={loadingData}/>
            {/* <div className="faculty-application-table-footer" style={{ height: '4vh'}}>
                <PageSizeSelector itemsPerPage={limit} onPageSizeChange={handlePageSizeChange} />
                <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
            </div> */}
            {
                lorModalOpen && 
                <SpecificLor lor={selectedLor} refetchLors={refetchLors} onClose={() => { setLorModalOpen(prevState => !prevState); setOtherViewOpen(prevState => !prevState)}}/>
            }
        </div>
    )
}

export default AdminLors;