const mockedReviews = [
    {
        _id: '1',
        applicationId: '1',
        reviewerId: '1',
        programId: '1',
        stage: 'Completed',
        rubrics: [
            {
                label: 'rubric 3',
                inputType: 'scale',
                maxScale: 10,
                lowestScaleText: 'low',
                highestScaleText: 'high',
                text: '',
                order: 3,
                _id: '1',
            },
            {
                label: 'Rubric Name',
                inputType: 'text',
                maxScale: 5,
                lowestScaleText: 'Poor',
                highestScaleText: 'Excellent',
                text: 'This is a rubric for the application process',
                order: 1,
                _id: '2',
            },
            {
                label: 'Rubric 2',
                inputType: 'dropdown',
                maxScale: 5,
                lowestScaleText: 'poor ',
                highestScaleText: 'good',
                text: 'onl',
                order: 2,
                dropdownOptions: [
                    'Yes',
                    'No',
                    'Waitlist'
                ],
                _id: '3',
            }
        ],
        reviewedAt: '',
    },
    {
        _id: '2',
        applicationId: '2',
        reviewerId: '2',
        programId: '1',
        stage: 'Pending',
        rubrics: [
            {
                label: 'rubric 3',
                inputType: 'scale',
                maxScale: 10,
                lowestScaleText: 'low',
                highestScaleText: 'high',
                text: '',
                order: 3,
                _id: '1',
            },
            {
                label: 'Rubric Name',
                inputType: 'text',
                maxScale: 5,
                lowestScaleText: 'Poor',
                highestScaleText: 'Excellent',
                text: 'This is a rubric for the application process',
                order: 1,
                _id: '2',
            },
            {
                label: 'Rubric 2',
                inputType: 'dropdown',
                maxScale: 5,
                lowestScaleText: 'poor ',
                highestScaleText: 'good',
                text: 'onl',
                order: 2,
                dropdownOptions: [
                    'cool',
                    'medium',
                    'poor'
                ],
                _id: '3',
            }
        ],
        reviewedAt: '',
    },
    {
        _id: '3',
        applicationId: '3',
        reviewerId: '3',
        programId: '1',
        stage: 'Pending',
        rubrics: [
            {
                label: 'rubric 3',
                inputType: 'scale',
                maxScale: 10,
                lowestScaleText: 'low',
                highestScaleText: 'high',
                text: '',
                order: 3,
                _id: '1',
            },
            {
                label: 'Rubric Name',
                inputType: 'text',
                maxScale: 5,
                lowestScaleText: 'Poor',
                highestScaleText: 'Excellent',
                text: 'This is a rubric for the application process',
                order: 1,
                _id: '2',
            },
            {
                label: 'Rubric 2',
                inputType: 'dropdown',
                maxScale: 5,
                lowestScaleText: 'poor ',
                highestScaleText: 'good',
                text: 'onl',
                order: 2,
                dropdownOptions: [
                    'cool',
                    'medium',
                    'poor'
                ],
                _id: '3',
            }
        ],
        reviewedAt: '',
    },
]

export default mockedReviews;