import {React, useContext, useState} from 'react';
import './styles/facultyHomeCard.css';


const FacultyHomeCard = ({card, setPage, calculations}) => {

    return (
<div className="faculty-home-card">
{card === 'applicantsOverview' && (

<div className="faculty-home-card-header">
    <h2 className="faculty-home-card-header-title">Metrics</h2>
    <seperator className="faculty-home-header-seperator"/>
    <div className="faculty-home-card-body">
        <div className='facultyStatisticEntry'>
            <h3 className='facultyStatisticEntryTitle'>Applicants</h3>
            <p className='facultyStatisticEntryValue'>{calculations.newApplications}</p>
            </div>
            {/* <div className='facultyStatisticEntry'>
            <h3 className='facultyStatisticEntryTitle'>Pending Invitations</h3>
            <p className='facultyStatisticEntryValue'>{calculations.pendingInvitations}</p>
            </div> */}
            <div className='facultyStatisticEntry'>
            <h3 className='facultyStatisticEntryTitle'>To Review</h3>
            <p className='facultyStatisticEntryValue'>{calculations.pendingReviews}</p>
            </div>
            <div className='facultyStatisticEntry'>
            <h3 className='facultyStatisticEntryTitle'>Reviewed</h3>
            <p className='facultyStatisticEntryValue'>{calculations.completedReviews}</p>
            </div>
            {/* <div className='facultyStatisticEntry'>
            <h3 className='facultyStatisticEntryTitle'>Pending Recommendations</h3>
            <p className='facultyStatisticEntryValue'>{calculations.pendingRecommendations}</p>
            </div> */}
    </div>

    {/* <div className="faculty-home-card-footer">
        <p>Stay up to date on your applicant statistics here. For more specific information, view or review all applications using the menu on the left.</p>
        </div> */}

</div>
)}
      
        {card === 'applicationCustomization' && (
            <>
                <div className="faculty-home-card-header" style={{display:'flex',flexDirection:'column'}}>
                    <h2 className="faculty-home-card-header-title">Application Customization</h2>
                    <seperator className="faculty-home-header-seperator"/>
                    <div className="faculty-home-card-footer">
                        <p>Utilize this feature to customize your application experience. Here, you can edit the rubric that your team is utilizing for review, customize your view of the application, and PDF download settings!</p>
                        </div>
    
                </div>
                <div style={{display:'flex',flexDirection:'row',marginTop:'1vh'}}> 
                <button className='green-background-button' style={{marginRight:'.5vw'}} onClick={() => setPage('rubric')}>Edit Evaluation Form</button>
                <button className='green-background-button' onClick={() => setPage('customizeApplication')}>Edit Screened Items</button>
                
                </div>
                </>
        )}

{card === 'myTeam' && (
    <>
                <div className="faculty-home-card-header" style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                    <h2 className="faculty-home-card-header-title">My Team</h2>
                    {/* <button className='view-team-button' onClick={() => setPage('myTeam')}>View Team</button> */}
                    </div>
                    <seperator className="faculty-home-header-seperator"/>


                    <div className="faculty-home-card-footer">
                        <p>Manage your review team here. Invite your reviewers, recommenders, and coordinators. Anyone invited to your team will become a Verified User. Learn more about user verification using the question mark at the bottom right.</p>
                        </div>
                    <button className='green-background-button' onClick={() => setPage('myTeam')}>Edit My Team</button>
                        </>

        )}





</div>
    )};

export default FacultyHomeCard;