import React,{useState, useContext, useEffect} from "react";
import "./index.css";
import InviteUserModal from "./inviteUserModal";
import TeamMembersTable from "./teamMembersTable";
import UpdateRoleModal from "./updateRoleModal";
import { ProgramIdContext } from "../../faculty/progIdContext";
import {useQuery, useMutation} from '@apollo/client';
import { getFacultyByInstitutionIdQuery } from './graphql/queries';
import { REMOVE_PROGRAM_MEMBER, UPDATE_PROGRAM, deleteUserMutation } from "./graphql/mutations";
import dropdownArrowIcon from '../../../assets/imgs/DropdownArrowIcon.svg'
import Spinner from "../../common/spinner";
import { InstitutionIdContext } from '../institutionIdContext';
import packageInfo from '../../../../package.json';



const MyTeam = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editingUser, setEditingUser] = useState(null);
    const [openMenuRowId, setOpenMenuRowId] = useState(null);
    const [sort, setSort] = useState(JSON.parse(sessionStorage.getItem('sort')) || { field: 'lastName', direction: 'asc'});
    const [removeUser] = useMutation(deleteUserMutation);
    const [updateProgram] = useMutation(UPDATE_PROGRAM);
    const institution = useContext(InstitutionIdContext);
    
    const institutionId = institution.id;
    const [data, setData] = useState([]);

    const { loading, error, data: rawData, refetch } = useQuery(getFacultyByInstitutionIdQuery, {
        variables: { institutionId },
    });
    
    useEffect(() => {
        if (!loading && !error && rawData) {
            console.log("rawData.getFacultyByInstitutionIdQuery =>", rawData.getFacultyByInstitutionId)
            setData(rawData.getFacultyByInstitutionId);
        }
    }, [loading, error, rawData]);


    console.log("rawData =>", rawData)


    const handleRemind = (user, id) => {
        console.log("send reminder!", user)
        fetch(`${process.env.REACT_APP_SERVER_URL}/send_invitation_reminder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'App-Version': packageInfo.version
            },
            body: JSON.stringify({
              user
            })
        })
        handleMenuToggle(id)
    }

    const sortTeam = (column, direction) => {
        console.log("column in the sortApplications function=>", column)
        setSort({ field: column, direction: direction === true ? "asc": "desc"})
        refetch()
    }

    const handleMenuToggle = (rowId) => {
        setOpenMenuRowId((prev) => (prev === rowId ? null : rowId));
      };

    const columns = [
    {
        Header: 'id',
        accessor: 'id',
    },
    {
        accessor: 'firstName',
        Header: 'Name',
        Cell: ({ row }) => (
            <div className="dropdown" style={{border:'0',margin:'0',padding:'0'}}>{row.original.firstName} {row.original.lastName}</div>
        )
    },
    {
        Header: 'Email Address',
        accessor: 'email',
    },
        {
            Header: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div className="dropdown" style={{border:'0',margin:'0',padding:'0'}}>
                <button className="white-button dropdown-toggle"  onClick={() => handleDelete(row.original, row.id)}>
                    Delete 
                </button>
                </div>
                // <div style={{display:'flex', flexDirection:'row', gap:'.2vw', marginRight:'.3vw'}}>
              
                // </div>
            ),
        },
        // {
        //     Header:'isPending',
        //     accessor:'isPending',   
        // }
    ]

    const handleEdit = (rowData, id) => {
        setEditingUser(rowData);
        handleMenuToggle(id)
        setIsEditModalOpen(true);
    }
    
    const handleDelete = async (rowData, id) => {
        console.log('Deleting user:', rowData);
        // Execute the mutation
        try {
            const response = await removeUser({ variables: { id:rowData.id} });
            refetch();
            handleMenuToggle(id)
        } catch (error) {
            console.error('Failed to delete program member:', error);
        }
    }

    
    const handleAddUser = () => {
        console.log('inside handleAddUser performing refetch')
        refetch();
    };

    const handleEditUser = async (editedUser) => {
        // setData(prevData => {
        //     const updatedData = prevData.map(user => {
        //         if (user.email === editingUser.email) {
        //             return {
        //                 ...user,
        //                 ...editedUser,
        //                 roleDisplayNames: editedUser.roles.map(role => roleDisplayNames[role]).join(', ')
        //             };
        //         } else {
        //             return user;
        //         }
        //     });
        
        //     // Transform the updated data into the desired format
        //     // const members = updatedData.map(user => ({
        //     //     userId: user.id,
        //     //     roles: user.roles
        //     // }));
        
        //     // Call the mutation here with the updated members
        //     // updateProgram({ variables: { id: programId, input: { members } } })
        //     // .then(response => {
        //     //     console.log('Update program response:', response);
        //     //     setEditingUser(null);
        //     //     setIsEditModalOpen(false);
        //     // })
        //     // .catch(error => {
        //     //     console.error('Failed to update program:', error);
        //     // });
        
        //     return updatedData;
        // });

    };

    return (
        <div className='faculty-home-container'>
            <ellipse className='ellipseDesignTop1' />
            {/* <ellipse className='ellipseDesignTop2' /> */}
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="faculty-home-header" style={{display:'flex', justifyContent:'space-between'}}>
                <h2 className="faculty-home-header-title">MY TEAM</h2>
                {/* <p className="faculty-home-header-description">Manage your team here. Invite new members, manage roles, and create verified recommenders!</p> */}
                <button  className="green-button" onClick={() => setIsModalOpen(!isModalOpen)}>Invite a Member +</button>
            </div>
            <seperator style={{marginTop:'6.5vh'}} className="faculty-home-header-seperator"/>

            {loading && <Spinner />}
            {error && <p>Error: {error.message}</p>}
            <TeamMembersTable columns={columns} data={data} sortData={sortTeam}/>
            <InviteUserModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onAddUser={handleAddUser}/>
            {/* {editingUser && <UpdateRoleModal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} user={editingUser} onEditUser={handleEditUser}/>} */}
           
        </div>
    );
}

export default MyTeam;

