import React, { useState, useContext } from 'react';
import { Formik, Form, Field, ErrorMessage,FieldArray } from 'formik';
import { FormContext } from '../ApplicationForm';
import starIcon from '../../../../assets/imgs/star.svg';
import filledStarIcon from '../../../../assets/imgs/filledStarIcon.svg';
import validationSchema from './meaningfulExpValidationSchema';

function countWords(input) {
  if (input) {
    const words = input.trim().split(/\s+/);
    return words.length;
  }
  return 0;
}


const SignificantExp = ({isEdit}) => {
  // console.log('isEdit in research',isEdit.isEdit)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null); 
  const { values, setStep, step, shortQuestions, setFieldValue, isSubmitting } = useContext(FormContext);
  const [initialValues, setInitialValues] = useState({ category: '', title:'', startDate:'', endDate:'', description: ''})


  const removeSignificantExp = (index) => {
    let updatedMeaningfulExperiences = values.meaningfulExperiences.filter((_, i) => i !== index);
    setFieldValue('meaningfulExperiences', updatedMeaningfulExperiences);
  };

          // { category: '', title:'', startDate:'', endDate:'', description: ''}
          // meaningfulExperiences

          const addOrEditSignificantExp = (significantExp) => {
            let currentMeaningfulExperiences = [...values.meaningfulExperiences];
            // Check if there is only one element and if all its values are empty strings
            if (editingIndex !== null) {
              currentMeaningfulExperiences[editingIndex] = significantExp; // Replace the element at the editing index
            }else if (currentMeaningfulExperiences.length === 1 && Object.values(currentMeaningfulExperiences[0]).every(value => value === '')) {
              currentMeaningfulExperiences = [significantExp]; // Replace the empty element with the new element
            } else {
              currentMeaningfulExperiences.push(significantExp); // Add the new element
            }
            // calculate the other fields - count all the first author publications in the currentResearchExperience array
           
            setFieldValue('meaningfulExperiences', currentMeaningfulExperiences);
            setIsModalOpen(false);
            setInitialValues({ category: '', title:'', startDate:'', endDate:'', description: ''})
            setEditingIndex(null);
          }


          const editSignificantExp = (index) => {
            setEditingIndex(index); // Set the current editing index
            setInitialValues(values.meaningfulExperiences[index])
            setIsModalOpen(true);

          };


          const handleCancel = () => {
            setIsModalOpen(false);
            setInitialValues({ category: '', title:'', startDate:'', endDate:'', description: ''})
            setEditingIndex(null);
          }

          const shortDateRegExp = /^(0[1-9]|1[0-2])\/\d{4}$/; // Regular expression for MM/YYYY


  return (
    <>
   {values.meaningfulExperiences.map((meaningfulExperience, index) => (
  <div className='application-form-field-group two' style={{flexDirection:'row', gap:'2vw', alignItems:'baseline'}}>
      <>
      <div style={{display:'flex', width:'100%'}}>
        <p style={{
          fontFamily: 'Montserrat',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '18px',
          color: 'rgb(71, 71, 71)',
          lineHeight: '27px',
          marginBottom: '12px',
          textAlign: 'justify',
          width:'76%'
        }}>
         <div className="application-view-main-education-degrees-section" style={{display:'flex', flexDirection:'row', marginRight:'1vw'}}>
                    <div>
                        <div className="date" style={{marginLeft:'3vw', width:'13vw'}}>{meaningfulExperience.startDate} - {meaningfulExperience.endDate}</div>
                        <div className="ball"/>
                    </div>
                    <div className="institution-and-degree" style={{marginBottom:'2vh', textAlign:'justify'}}>
                        <div className="institution" style={{marginLeft:'1vw'}}>{meaningfulExperience.category}: {meaningfulExperience.title}</div>
                        <div className="degree" style={{marginLeft:'1vw'}}>{meaningfulExperience.description}</div>
                    </div>
                </div>
        </p>
        <div style={{display:'flex', flexDirection:'row', gap:'1vw'}}>
        <button className='white-button' disabled={!isEdit} style={{flexGrow:'unset', flexBasis:'unset', padding:'8px 30px', alignSelf:'center'}} onClick={() => removeSignificantExp(index)}>Remove</button>
        <button className='green-button' disabled={!isEdit} style={{alignSelf:'center'}} onClick={() => editSignificantExp(index)}>Edit</button>
        </div>
      </div>
      </>


    </div>
    ))}



    <button className='green-button' disabled={!isEdit || values.meaningfulExperiences.length>=5} style={{width:'100%', marginBottom:'3vh'}} onClick={()=>setIsModalOpen(true)}>Add Significant Experience</button>
    {isModalOpen && (
  <div className='modal-backdrop'>
    <div className='add-research-modal' style={{minHeight:'61vh'}}>
      <div className='add-research-modal-header'>
        <h3 style={{textAlign:'center'}}>Add Significant Experience</h3>
      </div>
      <div className='add-research-modal-body'>
      <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
    >
      {formikProps => (
        <Form style={{display:'flex', flexDirection:'column', gap:'2vh'}}>
          <Field name="category" as="select" className='application-form-standard-input' style={{alignSelf:'center', width:'100%'}}>
            <option value="">Select Category</option>
            <option value="Leadership">Leadership</option>
            <option value="Research">Research</option>
            <option value="Service">Service</option>
            {/* <option value="Work">Work</option> */}
          </Field>
          <ErrorMessage name="category" component="div" className='application-form-error-message' />
          <Field name="title" type="text" placeholder="Title"  style={{ width:'100%'}} className='application-form-standard-input' />
          <ErrorMessage name="title" component="div" className='application-form-error-message' />
          <Field name="startDate" type="text" placeholder="Start Date (MM/YYYY)" style={{ width:'100%'}} className='application-form-standard-input' />
          <ErrorMessage name="startDate" component="div" className='application-form-error-message' />
          <Field name="endDate" type="text" placeholder="End Date (MM/YYYY) - leave blank if ongoing"  style={{ width:'100%'}} className='application-form-standard-input' />
          <ErrorMessage name="endDate" component="div" className='application-form-error-message' />
          <label style={{fontFamily:'Montserrat', fontWeight:'500'}}>Description</label>
          <Field as="textarea" name={`description`} 
            className='application-form-standard-input textBox'
            onChange={(e) => {
              const words = countWords(e.target.value)
              console.log("words =>", words)
              if (words <= 100) {
                formikProps.setFieldValue("description", e.target.value);
              }
            }}
            style={{height:'24vh',width:'100%', marginBottom:'0'}}/>
            <div>Word count: {countWords(formikProps.values.description)}/100</div>

          <div style={{position:'relative', display:'flex', gap:'2vw', alignSelf:'center'}}>
          <button className='green-button'  onClick={()=>addOrEditSignificantExp(formikProps.values)} disabled={!formikProps.dirty || !formikProps.isValid}>Add</button>
          <button className='white-button' onClick={()=>handleCancel()}>Cancel</button>
          </div>
        </Form>
      )}
    </Formik>

{/* { category: '', title: '', authors:'', journal:'', datePublished:'', pubMedId:'', isFirstAuthor:'', significantResearchExperience:false, meeting:'', meetingDate:'', order:0} */}
      </div>
    </div>
  </div>
)}
    </>
)
}

export default SignificantExp;