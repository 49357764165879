import React from "react";

const PageSizeSelector = ({ itemsPerPage, onPageSizeChange }) => (
    <div className="page-size-selector" style={{paddingTop:'0.4vh',paddingBottom:'1vh',alignSelf:'start',paddingLeft:'2vw', width:'14vw', fontSize: '14px'}}>
      <label style={{color:'white', justifyContent:'space-between', display:'flex'}}>
        Items per page:
        <select value={itemsPerPage} onChange={onPageSizeChange} style={{color:'black', borderRadius:'4px', height: '3vh', fontSize: '14px'}}>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
          <option value={500}>500</option>
        </select>
      </label>
    </div>
);

export default PageSizeSelector;