import {gql} from '@apollo/client';

export const UPDATE_PROGRAM = gql`
  mutation UpdateProgram($id: ID!, $input: UpdateProgramInput!) {
    updateProgram(id: $id, input: $input) {
        id
    }
  }
`;

export const REMOVE_PROGRAM_MEMBER = gql`
mutation RemoveProgramMember($programId: ID!, $userId: ID!) {
  removeProgramMember(programId: $programId, userId: $userId) {
    userId {
        id
        firstName
        lastName
        email
        isPending
      }
      roles
  }
}
`;

export const deleteUserMutation = gql`
mutation deleteUser($id: ID!) {
  deleteUser(id: $id) {
    id
  }
}

`


export const INVITE_USER = gql`
  mutation InviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      userId {
        id
        firstName
        lastName
        email
        isPending
      }
      roles
    }
  }
`;


export const inviteUserToPortalMutation = gql`
  mutation inviteUserToPortal($email: String!, $institutionId: ID) {
    inviteUserToPortal(email: $email, institutionId: $institutionId) {
      id
    firstName
    lastName
    email
  }
}
`