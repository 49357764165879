import { Form, Formik, Field, ErrorMessage } from 'formik';
import React, { useState, useEffect } from 'react';
import logo from '../../../../assets/imgs/logo.svg';
import * as Yup from "yup";
import { GetSpecialtiesQuery } from './graphql/queries';
import {useQuery, useMutation} from '@apollo/client';
import Select from 'react-select';


const validationSchema = Yup.object({
})



const AddProgramModal = ({ isOpen, onClose, onSaveProgram }) => {
    const [specialties, setSpecialties] = useState([])
    const [selectedSpecialty, setSelectedSpecialty] = useState(null)


    const { loading: loadingSpecialties, error: errorSpecialties, data: specialtiesData, refetch: refetchSpecialties} = useQuery(GetSpecialtiesQuery, {
        variables: {
        }
    })

    useEffect(() => {
        if(!loadingSpecialties && !errorSpecialties && specialtiesData) {
            const specialtiesFromQuery = specialtiesData.getSpecialties
            let combined = []
            if(specialtiesFromQuery) {
                specialtiesFromQuery.map(specialty => combined.push({ label: specialty.name, value: specialty.id}))
            }
            console.log("combined =>", combined)
            setSpecialties(combined)
        }
    }, [loadingSpecialties, errorSpecialties, specialtiesData, setSpecialties])

   
    if(!isOpen) return null
    return (
        <div className="modal-backdrop">
            <div className="add-field-modal">
                <div className='add-field-modal-header'>
                    <h2>ADD PROGRAM</h2>
                    <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
                </div>
                <Formik 
                    initialValues={{
                        name: "",
                        description:  "",
                        url:  "",
                        members:  "",
                        formalName:  "",
                        ACGME_ID:  "",
                        sql_id:  "",
                    }}
                    onSubmit={values => {
                        console.log("values =>", values)
                        onSaveProgram({...values, specialtyId: selectedSpecialty.value })
                        onClose()
                    }}
                    validationSchema={validationSchema}
                >
                 {({ handleSubmit, values }) => (
                    <Form style={{ height: '55vh', overflowY: 'auto'}}>
                        <div className='application-form-field-group'>
                            <label htmlFor="name" className='applicant-form-field-label'>Name</label>
                            <Field name="name" placeholder="" className='application-form-standard-input' style={{width:'100%'}} />
                            <div className='application-form-error-message'> 
                                <ErrorMessage name="name" component="div" />
                            </div>
                        </div>

                        <div className='application-form-field-group'>
                            <label htmlFor="name" className='applicant-form-field-label'>Specialty</label>
                        </div>
                        <Select 
                            options={specialties}
                            onChange={ option => {
                                setSelectedSpecialty(option)
                            }}
                            value={selectedSpecialty}
                            placeholder="Select specialty"
                            styles={{
                                menu: (provided) => ({
                                ...provided,
                                zIndex: '10'
                                }),
                                control: (provided) => ({
                                ...provided,
                                width: '100%',
                                // height: '9vh',
                                boxSizing: 'border-box',
                                color: '#414141',
                                background: 'linear-gradient(0deg, #f2f2f2, #f2f2f2), #fff',
                                border: '1px solid #dbdbdb',
                                borderRadius: '8px',
                                outline: 'none',
                                fontFamily: 'Montserrat',
                                fontSize: '20px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '30px',
                                zIndex:'10',
                                position:'relative',
                                overflowY: 'scroll'
                                
                                }),
                                option: (provided) => ({
                                ...provided,
                                zIndex:'10',
                                position:'relative'
                                
                                }),
                                indicatorsContainer: (provided) => ({
                                ...provided,
                                height: '8vh'
                                })
                            }}
                        />







                        <div className='application-form-field-group'>
                            <label htmlFor="description" className='applicant-form-field-label'>Description</label>
                            <Field name="description" placeholder="" className='application-form-standard-input' style={{width:'100%'}} />
                            <div className='application-form-error-message'> 
                                <ErrorMessage name="description" component="div" />
                            </div>
                        </div>
                        <div className='application-form-field-group'>
                            <label htmlFor="url" className='applicant-form-field-label'>URL</label>
                            <Field name="url" placeholder="" className='application-form-standard-input' style={{width:'100%'}} />
                            <div className='application-form-error-message'> 
                                <ErrorMessage name="url" component="div" />
                            </div>
                        </div>
                        {/* <div className='application-form-field-group'>
                            <label htmlFor="formalName" className='applicant-form-field-label'>Formal Name</label>
                            <Field name="formalName" placeholder="" className='application-form-standard-input' style={{width:'100%'}} />
                            <div className='application-form-error-message'> 
                                <ErrorMessage name="formalName" component="div" />
                            </div>
                        </div> */}
                        <div className='application-form-field-group'>
                            <label htmlFor="ACGME_ID" className='applicant-form-field-label'>ACGME ID</label>
                            <Field name="ACGME_ID" placeholder="" className='application-form-standard-input' style={{width:'100%'}} />
                            <div className='application-form-error-message'> 
                                <ErrorMessage name="ACGME_ID" component="div" />
                            </div>
                        </div>
                        {/* <div className='application-form-field-group'>
                            <label htmlFor="sql_id" className='applicant-form-field-label'>SQL ID</label>
                            <Field name="sql_id" placeholder="" className='application-form-standard-input' style={{width:'100%'}} />
                            <div className='application-form-error-message'> 
                                <ErrorMessage name="sql_id" component="div" />
                            </div>
                        </div> */}

                        <div style={{ marginTop: '2vh', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <button type="submit" className='green-button' style={{
                            border: 'none',
                            outline: 'none',
                            cursor: 'pointer',
                            background: 'linear-gradient(96.75deg, #2a93d5 0%, #596173 133.3%)',
                            borderRadius: '7px',
                            padding: '12px 30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#FFFFFF',
                            fontWeight:'700',
                            width: '20vw'
                        }}
                        disabled={values.name === "" || values.description ===  "" || values.url === ""  || values.ACGME_ID === "" }
                        >
                        Add Program
                        </button>
                        <button className='lor-modal-submit-button' onClick={onClose} style={{
                            outline: 'none',
                            cursor: 'pointer',
                            background: '#FFFFFF',
                            border: '1px solid #145388',
                            borderRadius: '7px',
                            padding: '12px 30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#145388',
                            fontWeight:'700',
                            width: '20vw'

                        }}>Cancel</button>
                        </div>


                    </Form>
                 )}
                </Formik>
            </div>
        </div>
    )
}

export default AddProgramModal;