import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import { getApplicantByIdQuery, getMasterApplicationByUserIdAndSpecialtyIdQuery} from '../../applicantComponents/graphql/queries.jsx';
import { createMasterApplicationMutation } from "../graphql/mutations.jsx";
import { UserContext } from '../../../../context/userContext.js';
import { useNavigate } from 'react-router-dom';
import logo from "../../../../assets/imgs/logo.svg";
import ApplicationForm from "../../../applicant/applicantComponents/ApplicationForm.jsx";
import SignInModal from "./signInModalInApplyRoute";
import client from '../../../../config/apolloClient.js';
import Spinner from "../../../common/spinner.jsx";




function removeTypename(obj) {
    if (obj === null || obj === undefined) {
        return obj;
    }
  
    if (Array.isArray(obj)) {
        return obj.map(removeTypename);
    }
  
    if (typeof obj === 'object') {
        const newObj = {};
        for (let property in obj) {
            if (property !== '__typename') {
                newObj[property] = removeTypename(obj[property]);
            }
        }
        return newObj;
    }
  
    return obj;
  }

  




const ApplicantApplyRoute = () => {
    // treat token as positionId
    const { token } = useParams();
    console.log("positionIdToken =>", token)
    const { user, setUser } = useContext(UserContext);
    const [userFromQuery, setUserFromQuery] = useState(null);
    const [selectedApplication, setSelectedApplication] = useState(null)
    const [applicationData, setApplicationData] = useState({})
    const [masterApplications, setMasterApplications] = useState(null)
    const [phases, setPhases] = useState({
        firstPhase: false,
        secondPhase: false,
        thirdPhase: false,
        fourthPhase: false,
        fifthPhase: false,
        sixthPhase: false,
        seventhPhase: false})
    const [score, setScore] = useState(null)
    const [applications, setApplications] = useState(null)
    const [applicationDataTitle, setApplicationDataTitle] = useState(null)
    const [applicationLoading, setApplicationLoading] = useState(true)
    const [finishedSignUp, setFinishedSignUp] = useState(false)
    
    const navigate = useNavigate();

    const [applicant, setApplicant] = useState(null)

    const { data, loading, error } = useQuery(getApplicantByIdQuery, {
        variables: { id: user?.userId }, // Use optional chaining to safely access userId
        skip: !user?.userId, // Skip the query if user or user.userId is null
    });

    const [createMasterApplication] = useMutation(createMasterApplicationMutation);

    useEffect(() => {
        if (!loading && !error && data) {
            let applicant = data.getApplicantByIdQuery
            const cleanData = removeTypename(applicant)
            setApplicant(cleanData)
            setApplicationLoading(false)
            createMasterApplicationFunction()
        }
    }, [loading, error, data]);


    const refreshApplicant = (refreshedData) => {
        setApplicationLoading(true)
        const cleanData = removeTypename(refreshedData)
        setApplicant(cleanData)
        setApplicationLoading(false)
      }


      const createMasterApplicationFunction = () => {
        if (selectedApplication === null) {
            createMasterApplication({
                variables: {
                    input: {
                        userId: user.userId,
                        positionId: token,
                        specialtyId: '6684ef3116be303c9813d2a7',
                        firstPhase: false,
                        secondPhase: false,
                        thirdPhase: false,
                        fourthPhase: false,
                        fifthPhase: false,
                        sixthPhase: false,
                        seventhPhase: false,
                        status: 'Started',
                    }}
            }).then((response) => {
                setSelectedApplication(response.data.createMasterApplication)
                setApplicationData(response.data.createMasterApplication)
                setApplicationLoading(false)
            })
            .catch((error) => {
                console.log(error)
            })                        
        }
      }

      const getApplicant = () => {
        setApplicationLoading(true)
        client.query({
          query: getApplicantByIdQuery,
          variables: {
            id: user.userId
          },
          fetchPolicy: 'network-only'
        })
        .then(res => {
          let applicant = res.data.getApplicantByIdQuery
          const cleanData = removeTypename(applicant)
          setApplicant(cleanData)
          setApplicationLoading(false)
        })
      }


  const updatePhases = (newPhases) => {
    setPhases(newPhases);
  }


    return (
        <div>

               <div
               className="faculty-home-container"
               style={{ width: "100%", marginLeft: "auto", marginRight: "auto", overflowY: "hidden" }}
             >
               <ellipse className="ellipseDesignTop1" />
               <ellipse className="ellipseDesignTop2" />
               <ellipse className="ellipseDesignTop3" />
               <ellipse className="ellipseDesignTop4" />
               <div className="faculty-home-header">

                 <h2 className="faculty-home-header-title">
                   WELCOME TO Smart Recruiting
                 </h2>

               </div>
               <seperator className="faculty-home-header-seperator" />
               <div className="faculty-home-body">

{!finishedSignUp&&(
               <SignInModal type={'applicant'} isVisible={true}  setFinishedSignUp={setFinishedSignUp}/>
)}

{finishedSignUp&& (applicationLoading ||!selectedApplication||!applicationData)&&(
    <Spinner />)}


{finishedSignUp&& !applicationLoading&& selectedApplication && applicationData &&(
               <ApplicationForm
                applicationLoading={applicationLoading}
                refetchUser={getApplicant}
                selectedApplication={selectedApplication}
                applicationData={applicationData}
                // setPage={setPage}
                applicant={applicant}
                score={score}
                // setIsApplicationOpen={setIsApplicationOpen}
                phases={phases}
                applications={applications}
                getApplicant={getApplicant}
                // refetchApplications={getAllMasterApplications}
                updatePhases={updatePhases}
                setApplicationData={setApplicationData}
                refreshApplicant={refreshApplicant}/> 

        )}
               </div>
             </div>


        </div>
    )

}

export default ApplicantApplyRoute;