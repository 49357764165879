import React,{useState, useContext, useEffect} from "react";
import "./index.css";
// import mockedUsers from '../applications/mockData/mockedUsers.js'
import mockedReviewes from '../applications/mockData/mockedReviews.js'
import mockedApplications from '../applications/mockData/mockedApplicants.js'
import ReviewerItem from "./reviewerItem";
import ReviewerApplicationsTable from "./reviewerApplicationsTable";
import AssignReviewsModal from "./assignReviewsModal";
import { ProgramIdContext } from "../../progIdContext";
import {useQuery, useMutation} from '@apollo/client';
import {GET_PROGRAM_REVIEWERS_BY_PROGRAM_ID, GET_REVIEWERS_APPLICATIONS_BY_PROGRAM, getReviewersApplicationsByProgramCount } from './graphql/queries';
import { getProgramById,getApplicationsPaginationQuery } from "../graphql/queries.jsx";
import {DELETE_REVIEW, deleteAllReviewsForReviewer} from './graphql/mutations';
import Spinner from '../../../common/spinner';
import Pagination from '../applications/paginationComponent';
import ApplicationView from '../applicationView';
import dropdownArrowIcon from '../../../../assets/imgs/DropdownArrowIcon.svg'
import GroupsDropdown from '../applications/groupsDropdown';
import client from "../../../../config/apolloClient";
import { updateGroupsApplicationIds } from '../graphql/mutations';
import { getGroupsByProgramIdQuery } from '../graphql/queries';
import packageInfo from '../../../../../package.json';



function formatSelectedTracks(selectedTracks) {
    const justTheTrackNamesArray = selectedTracks.map(track => track.trackName)
    // Cat, Adv, Phys, CC, Rsrch, Peds
    const formattedNames = justTheTrackNamesArray.map(name => {
        if (name === 'Categorical') {
            return 'Cat'
        } else if (name === 'Advanced') {
            return 'Adv'
        } else if (name === 'Physician Only') {
            return 'Phys'
        } else if (name === 'Critical Care') {
            return 'CC'
        } else if (name === 'Research') {
            return 'Rsrch'
        } else if (name === 'Peds/Anesthesiology') {
            return 'Peds'
      }
    })
    return formattedNames.join(', ')
    }



const ManageReviews = ({isRubricLocked, setPage}) => {
  const programId = useContext(ProgramIdContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteAllModalOpen, setIsDeleteAllModalOpen] = useState(false);
  const [reviewers, setReviewers] = useState([]);
  const [applications, setApplications] = useState(mockedReviewes);
  const [selectedReviewer, setSelectedReviewer] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState({ field: 'reviewerId', direction: 'desc'})
  const [totalApplications, setTotalApplications] = useState(0);
  const [totalApplicationsForUser, setTotalApplicationsForUser] = useState(0);
  const [selectedReviewerApplications, setSelectedReviewerApplications] = useState([]);
  const [deleteReview, { loading: deleteLoading, error: deleteError }] = useMutation(DELETE_REVIEW);
  const [deleteAllReviewsForReviewerMutation, { loading: deleteAllReviewsForReviewerLoading, error: deleteAllReviewsForReviewerError }] = useMutation(deleteAllReviewsForReviewer);
  const [screenedData, setScreenData] = useState({})
  const [selectedAppId, setSelectedAppId] = useState('')
  const [applicationToView, setApplicationToView] = useState({})
  const [isViewApplicationOpen, setIsViewApplicationOpen] = useState(false);
  const [isGroupsModalOpen, setIsGroupsModalOpen] = useState(false);
  const [groups, setGroups] = useState([]);
  const [checkedGroups, setCheckedGroups] = useState([]);
  const [success, setSuccess] = useState(false);
  const [loadingApplicationId, setLoadingApplicationId] = useState(null);
  const [deleteAllError, setDeleteAllError] = useState(null);
  const [isLockedRubricModalOpen, setIsLockedRubricModalOpen] = useState(!isRubricLocked);
  const [isApplicationLoading, setIsApplicationLoading] = useState(false);

  const { data: applicationData, error: applicationError, loading: applicationLoading, refetch: refetchApplications } = useQuery(getApplicationsPaginationQuery, {
    variables: {
        programId: programId,
        applicationsIds: [selectedAppId],
        filters: { filterConditions: [] },
    },
    fetchPolicy: 'network-only',
    skip: !selectedAppId, applicationToView
});

const { data: groupsData, error: groupsError, isLoading: groupsLoading , refetch} = useQuery(getGroupsByProgramIdQuery, {
    variables: {
        programId,
        filter: { field: 'name', value: null }
    },
    fetchPolicy:'network-only'
});


useEffect(() => {
    if (selectedAppId) {
      const variables = {
        programId: programId,
        applicationIds: [selectedAppId],
      };
      console.log("Refetching applications with variables:", variables);
      refetchApplications(variables);
    }
  }, [selectedAppId, refetchApplications, programId]);


useEffect(() => {
    if (applicationData && applicationData.getApplicationsPagination && applicationData.getApplicationsPagination.length > 0) {
        const application = applicationData.getApplicationsPagination[0];
        let appToPush = {
            id: application.id,
            region: application.region??'',
            medicalSchoolState: application.medicalSchoolState??'',
            firstName: application.userId.firstName??'',
            lastName: application.userId.lastName??'',
            email: application.userId.email??'',
            phone: application.userId.phone??'',
            createdAt: application.createdAt??'',
            dateOfBirth: application.userId.applicationInformation.dateOfBirth??'',
            coupleMatch: application.userId.applicationInformation.coupleMatch === 'yes' ? "Yes" : "No",
            partnerField: application.userId.applicationInformation.partnerFeild??'',
            misdemeanorOrFelony: application.userId.applicationInformation.misdemeanorOrFelony??'',
            misdemeanorOrFelonyDetails: application.userId.applicationInformation.misdemeanorOrFelonyDetails??'',
            nrmpId: application.userId.applicationInformation.nrmpId??'',
            workAuthorization: application.userId.applicationInformation.workAuthorization??'',
            usCitizen: application.userId.applicationInformation.workAuthorization === "U.S. Citizen, National, or Legal Permanent Resident (e.g., Refugee, Asylee)" ? "Yes" : "No",
            gender: application.userId.applicationInformation.gender??'',
            otherRaceEthnicity: application.userId.applicationInformation.otherRaceEthnicity??'',
            otherWorkAuthorization: application.userId.applicationInformation.otherWorkAuthorization??'',
            partnerName: application.userId.applicationInformation.partnerName??'',
            dateOfBirth: application.userId.applicationInformation.dateOfBirth??'',
            hometown: application.userId.applicationInformation.homeTown??'',
            address: application.userId.applicationInformation.homeAddress??'',
            ethnicity: application.userId.applicationInformation.ethnicity??'',
            medicalSchoolName: application.userId.applicationInformation.medicalSchoolName??'',
            aoaStatus: application.userId.applicationInformation.aoaStatus??'',
            goldHumanism: application.userId.applicationInformation.goldHumanism??'',
            firstAuthorPublications: application.userId.applicationInformation.firstAuthorPublications??'',
            peerReviewedPublications: application.userId.applicationInformation.peerReviewedPublications??'',
            posterPresentations: application.userId.applicationInformation.posterPresentations??'',
            podiumPresentations: application.userId.applicationInformation.podiumPresentations??'',
            aamc: application.userId.applicationInformation.aamc??'',
            previouslyAppliedToSpeciality: application.masterApplicationId.previouslyAppliedToSpecialty=== true? "Yes" : "No",
            isSignal: application.isSignal === true ? "Yes" : "No"??'',
            signalType: application.signalType??'',
            usmleStep1Score: application.scoreId.usmleStep1Score??'',
            usmleStep2Score: application.scoreId.usmleStep2Score??'',
            usmleStep3Score: application.scoreId.usmleStep3Score??'',
            comlexLevel1Score: application.scoreId.comlexLevel1Score??'',
            comlexLevel2Score: application.scoreId.comlexLevel2Score??'',
            user: application.userId??'',
            masterApplicationId: application.masterApplicationId._id??'',
            researchExperience: application.masterApplicationId.researchExperience??'',
            carryingOutResponsibilities: application.masterApplicationId.carryingOutResponsibilities??'',
            carryingOutResponsibilitiesElaboration: application.masterApplicationId.carryingOutResponsibilitiesElaboration??'',
            workExperiences: application.masterApplicationId.workExperiences??'',
            meaningfulExperiences: application.masterApplicationId.meaningfulExperiences??'',
            hobbiesAndInterests: application.masterApplicationId.hobbiesAndInterests??'',
            shortAnswerQuestions: application.masterApplicationId.shortAnswerQuestions??'',
            personalStatement: application.masterApplicationId.personalStatement??'',
            signalEssay: application.signalEssay??'',
            applicationRubrics: application.applicationRubrics??'',
            watchUsersIds: application.watchUsersIds??'',
            isLanguages: application.userId.applicationInformation.isLanguages??'',
            fluentLanguages: application.userId.applicationInformation.fluentLanguages??'',
            medicalSchoolType: application.userId.applicationInformation.medicalSchoolType??'',
            
            previousLastName: application.userId.applicationInformation.previousLastName??'',
            prefferedName: application.userId.applicationInformation.prefferedName??'',
            pronouns: application.userId.applicationInformation.pronouns??'',
            usmleId: application.userId.applicationInformation.usmleId??'',
            nbomeId: application.userId.applicationInformation.nbomeId??'',
            aoaId: application.userId.applicationInformation.aoaId??'',
            permanentAddress: application.userId.applicationInformation.permanentAddress??'',
            licensureRevoked: application.userId.applicationInformation.licensureRevoked??'',
            licensureRevokedDetails: application.userId.applicationInformation.licensureRevokedDetails??'',
            militaryObligation: application.userId.applicationInformation.militaryObligation??'',
            militaryObligationDetails: application.userId.applicationInformation.militaryObligationDetails??'',
            otherServiceObligations: application.userId.applicationInformation.otherServiceObligations??'',
            medicalTrainingInterrupted: application.userId.applicationInformation.medicalTrainingInterrupted??'',
            medicalTrainingInterruptedDetails: application.userId.applicationInformation.medicalTrainingInterruptedDetails??'',
            sigmaPhi: application.userId.applicationInformation.sigmaPhi??'',
            selectedTracks: application.selectedTracks ? formatSelectedTracks(application.selectedTracks) : [],
            selectedTracksRaw: application.selectedTracks ?  application.selectedTracks:[],
        }
        setApplicationToView(appToPush);
        setLoadingApplicationId(null); 
    }
}, [applicationData]);


useEffect(() => {
    if (applicationData!== undefined && applicationData.getApplicationsPagination.length > 0) {
        console.log(applicationToView, "ApplicationToView")
        setIsViewApplicationOpen(true);
        // console.log("applicationToView =>", applicationToView)
    }
}, [applicationToView]);


    
  const { data: programData, error: programError, isLoading: programLoading , refetch: refetchProgram} = useQuery(getProgramById, {
    variables: {
        id: programId
    },
});


  const { loading, error, data, refetch: refetchReviewers } = useQuery(GET_PROGRAM_REVIEWERS_BY_PROGRAM_ID, {
    variables: { programId: programId }, 
});

  const { loading: countLoading, error: countError, data: countData, refetch: refetchDataCount } = useQuery(getReviewersApplicationsByProgramCount, {
    variables: {reviewerId:selectedReviewer._id, programId:programId, offset, limit},
});

const {loading: loadingReviewerApplications, error: errorReviewerApplications, data: dataReviewerApplications , refetch: refetchReviewerApplications} = useQuery(GET_REVIEWERS_APPLICATIONS_BY_PROGRAM,{
    variables: {reviewerId:selectedReviewer._id, programId:programId, offset, limit, sort},
    skip: !selectedReviewer._id, applicationToView,
    fetchPolicy: 'network-only'
});

useEffect(() => {
    if (!loadingReviewerApplications && !errorReviewerApplications && dataReviewerApplications) {

        const sortedApplications = [...dataReviewerApplications.getReviewersApplicationsByProgram].sort((a, b) => a.lastName.localeCompare(b.lastName));

        setSelectedReviewerApplications(sortedApplications);
    }
}, [loadingReviewerApplications, errorReviewerApplications, dataReviewerApplications]);


useEffect(() => {
    if (countLoading) {
      return; // Or set a loading state if necessary
    } else if (countError) {
      console.error(countError);
      return; // Handle the error appropriately
    } else if (countData && countData.getReviewersApplicationsByProgramCount) {
      console.log("countData =>", countData)
      let countRes = countData.getReviewersApplicationsByProgramCount;
      if (JSON.stringify(totalApplications) !== JSON.stringify(countRes)) {
            setTotalApplications(countRes);
            console.log("countRes =>", countRes);
            console.log("total pages =>", Math.ceil(countRes/ limit))
            setTotalPages(Math.ceil(countRes/ limit))
       
      }
    }
  }, [countData, countError, countLoading, totalApplications, limit, setTotalApplications]);

  useEffect(() => {    
    if (!programLoading && !programError && programData) {
        let screenedDataObj = programData.getProgramById.screenedData
        if(JSON.stringify(screenedData) !== JSON.stringify(screenedDataObj)) {
            setScreenData(screenedDataObj)
            console.log("screenedData =>", screenedDataObj)
        }
    }
}, [programLoading, programError, screenedData, programData]);






useEffect(() => {
    if (!loading && !error && data) {
        
        const cleanedData = data.getProgramReviewersByProgramId.map(reviewer => {
            return {
                _id: reviewer.userId.id,
                firstName: reviewer.userId.firstName,
                lastName: reviewer.userId.lastName,
                name: reviewer.userId.firstName+' '+reviewer.userId.lastName,
                pendingReviews: reviewer.pendingReviews,
                completedReviews: reviewer.completedReviews,
                totalReviews: reviewer.pendingReviews + reviewer.completedReviews,
            }
        }
        );
        cleanedData.sort((a, b) => a.lastName.localeCompare(b.lastName));

        setReviewers(cleanedData);
        if(JSON.stringify(selectedReviewer) === '{}') {
            if(cleanedData.length > 0) {
                const selectedReviewerToShow = cleanedData[0]
                handleReviewerClick(selectedReviewerToShow)
            }
        }
       
    }
}, [loading, error, data]);
   
  const handleReviewerClick = (reviewer) => {
    setSelectedReviewer(reviewer);
    let reviewerApplicationsNotFull = applications.filter(application => application.reviewerId === reviewer._id);
    let reviewerApplicationsFull = reviewerApplicationsNotFull.map(application => {
        let applicationData = mockedApplications.find(app => app._id === application.applicationId);
        return {...application, ...applicationData};
    });
    setSelectedReviewerApplications(reviewerApplicationsFull);
}

const columns = [
    {
        Header: 'First Name',
        accessor: 'firstName',
        Cell: props => <div className="cell">{props.value}</div>,
    },
    {
        Header: 'Last Name',
        accessor: 'lastName',
        Cell: props => <div className="cell">{props.value}</div>,
    },
    {
        Header: 'Status',
        accessor: 'status',
        Cell: props => <div className="cell">{props.value}</div>,
    },
    {
        id: 'view',
        Header: '',
        accessor: 'view',
        Cell: ({ row }) => (
          <div  onClick={() => {
              setLoadingApplicationId(row.original.id);
              setSelectedAppId(row.original.id)
              console.log("selectedAppId =>", selectedAppId)
          }} style={{cursor:'pointer', color: '#145388', textDecoration: 'underline', fontWeight: '400' }}>
                {loadingApplicationId === row.original.id ? <Spinner /> : 'View Application'}
          </div>
        )
    },
      {
        id:'unassign',
        Header: '',
        accessor: 'unassign',
        Cell: ({ row }) => (
            <button className='green-button'  style={{padding:'1vw 1vh', height:'2vh', width:'fit-content', fontSize:'small'}}  onClick={() => {
                handleDeleteReviews([row.original])
              }}>Unassign</button>
        )
      }
];


const handleCloseModal = () => {
    setIsModalOpen(false);

};

const handleDeleteAllReviews = async () => {
    try {
        const { data } = await deleteAllReviewsForReviewerMutation({ variables: { reviewerId: selectedReviewer._id } });
        if (data) {
            // Refetch the data after the mutation is successful
            refetchReviewers();
            refetchReviewerApplications();
            setIsDeleteAllModalOpen(false);
        }
    } catch (error) {
        setDeleteAllError(error)
    }
};



const handleDeleteReviews = async (applications) => {
    console.log('handleDeleteReviews fired', applications);
    try {

        const reviews = [];
        for (let i = 0; i < applications.length; i++) {
            const review = {
                appId: applications[i].id,
                reviewerId: selectedReviewer._id,
            };

            reviews.push(review);
        }
        const input = { Reviews: reviews };
        console.log('input', input)

        const { data } = await deleteReview({ variables: { input: input } });

        if (data) {
            // Refetch the data after the mutation is successful
            refetchReviewers();
            refetchReviewerApplications();
        }
    } catch (error) {
        // Handle the error here. You might want to show an error message to the user.
    }
};

    const handlePageChange = (page) => {
        setCurrentPage(page);
        console.log("page =>", (page-1)* limit)
        setOffset((page-1)* limit)

    };

    if(!groupsLoading&&groupsData&&groupsData.getGroupsByProgramId) {
        let groupsToDisplay = groupsData.getGroupsByProgramId
        if(JSON.stringify(groupsToDisplay) !== JSON.stringify(groups)) {
            setGroups(groupsToDisplay)
        }
    }


    const remindReviewer = async () => {
        console.log("reviewer =>", selectedReviewer)
        if(selectedReviewer) {
            const reviewerId = selectedReviewer._id
            console.log("reviewerId =>", reviewerId)

            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/remind_reviewer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'App-Version': packageInfo.version
                },
                body: JSON.stringify({
                    reviewerId
                }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log(data); // Handle success response
            setSuccess(true)
            setTimeout(() => {
                setSuccess(false)
            }, 3000);
        }
    }

    const sortData = (column, direction) => {
        console.log("column =>", column, direction)
        setSort({ field: column, direction: direction === true ? "asc": "desc"})
        refetchReviewerApplications()
    }


    const handleApplyClick = (selectedGroups) => {
        const selectedIds = selectedReviewerApplications.map(row => row.id);
        const checkedGroupIds = selectedGroups.map(group => group.id); // Get the ids of the checked groups
        const updatedGroups = groups.map(group => {
            // Check if the group's id is in the checkedGroupIds array
            if (checkedGroupIds.includes(group.id)) {
                // Create a new group object instead of modifying the existing one
                const newGroup = { ...group };
                if (newGroup.applicationIds.some(id => selectedIds.includes(id))) {
                    newGroup.applicationIds = newGroup.applicationIds.filter(id => !selectedIds.includes(id));
                } else {
                    newGroup.applicationIds = [...newGroup.applicationIds, ...selectedIds];
                }
                return newGroup;
            }
            return group;
        });

        client.mutate({
            mutation: updateGroupsApplicationIds,
            variables: {
                groupIds: checkedGroupIds,
                applicationIds: selectedIds,
            }
        })


        setGroups(updatedGroups);
        setIsModalOpen(false);
    };
    if(loading)return <Spinner/>

    return (
        <>
        
        {isViewApplicationOpen && <ApplicationView application={applicationToView} onClose={() => setIsViewApplicationOpen(false)} screenedData={screenedData}/>}
        {!isViewApplicationOpen && 
        <div className='faculty-home-container'>
            
            {isLockedRubricModalOpen && (
                 <div className='modal-backdrop'>
                     <div className='add-graduation-modal' style={{minHeight:'30vh'}}>
                        <div className='modal-body' style={{marginBottom:'3vh'}}>
                            {!isRubricLocked&&(
                            <p>
                            Welcome to the Application Review Manager! As a reminder, your rubric must be saved and locked prior to beginning the review process. To review and lock your rubric, click the link below.
                     </p>
                            )}
                            {isRubricLocked&&(
                            <p>
                            Welcome to the Application Review Manager! As a reminder, once you begin assigning reviewers, you will not be able to unlock your rubric to edit or remove items. Are you sure that you want to proceed?
                        </p>
                            )}
                     </div>
                     
                     <div className='modal-footer' style={{display:'flex' , gap:'2vw', alignSelf:'center' }}>
                            <button className='green-button' onClick={() => setPage('rubric')}>View Rubric</button>
                            {isRubricLocked&&(
                            <button className='green-button' onClick={() => setIsLockedRubricModalOpen(false)}>Accept and Continue</button>
                            )}
                    </div>
                    </div>
                </div>
            )}
            {/* <Spinner/> */}
            <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop2' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="faculty-home-header">
                <h2 className="faculty-home-header-title">MANAGE REVIEWS</h2>
                <p className="faculty-home-header-description">Welcome to your review manager! Here you can assign all applicants, individually, or by group. You can also view existing reviewer assignments by clicking on their names below.</p>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'right', width:'98%'}}>
                <button  className="green-button" onClick={() => setIsModalOpen(!isModalOpen)}>Assign Reviews</button>
                </div>
            </div>
            <seperator style={{marginTop:'1.5vh'}} className="faculty-home-header-seperator"/>
            {isDeleteAllModalOpen&&
            <div className="modal-backdrop">
            <div className="assign-reviews-modal" style={{width:'fit-content'}}>
                <div className="faculty-mreviews-delete-all-modal-content">
                    {/* <h2 style={{fontSize:'5vh', fontWeight:'500'}}>Unassign All Reviews</h2> */}
                    <p>Are you sure you want to unassign <strong>all</strong> reviews for <strong>{selectedReviewer.firstName} {selectedReviewer.lastName}</strong>?</p>
                    {!deleteAllReviewsForReviewerLoading&&!deleteAllReviewsForReviewerError&&(
                    <div className="faculty-mreviews-delete-all-modal-buttons" style={{display:'flex', justifyContent:'center',gap:'1vw', marginTop:'5vh'}}>
                        <button className="green-button" onClick={handleDeleteAllReviews}>Yes</button>
                        <button className="green-button" onClick={() => setIsDeleteAllModalOpen(false)}>No</button>
                    </div>
                    )}
                    {deleteAllReviewsForReviewerError&&<label style={{color:'red', marginTop:'5vh'}}>An error occurred: {deleteAllError}. Please try again.</label>}
                    {deleteAllReviewsForReviewerLoading&&<Spinner/>}
                </div>
                </div>
            </div>}
           
            {isModalOpen&&<AssignReviewsModal reviewers={reviewers} onClose={handleCloseModal} refetchReviewerApplications={refetchReviewerApplications} refetchReviewers={refetchReviewers} />}
            <div className="faculty-mreviews-body-cont">
                <div className="faculty-mreviews-reviewers-cont">
                <h2>Reviewers</h2>
                {deleteLoading && <Spinner />}
                <div className="inner-faculty-items">
                {reviewers.map(reviewer => {
            // const reviewerApplications = applications.filter(application => application.reviewerId === reviewer._id);
            return <ReviewerItem reviewer={reviewer} selectedReviewer={selectedReviewer} onReviewerClick={handleReviewerClick}/>}
        )}
        </div>


         </div>
         <div className="faculty-mreviews-applications-cont">
         { success && <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '2vh'}}><label style={{ color: '#145388'}}>Reminder email sent successfully.</label></div>}

            <div className="faculty-mreviews-applications-header" style={{display:'flex', justifyContent:'space-between'}}>
            <h2>Applications</h2>
            <div style={{ display: 'flex'}}>
                <button className="green-button" style={{padding:'1vw 1vh', height:'2vh', width:'fit-content', fontSize:'small', marginRight: '1vw'}} onClick={() => setIsGroupsModalOpen(prevState => !prevState)}>Folders <img src={dropdownArrowIcon}/> </button>
                <div style={{position:'relative'}}>
                {
                    isGroupsModalOpen &&
                    <GroupsDropdown isOpen={isGroupsModalOpen} groups={groups} selectedGroups={checkedGroups} setSelectedGroups={setCheckedGroups} onApply={handleApplyClick} selectedRows={selectedReviewerApplications} finishMutation={() => refetch()} setIsOpen={setIsModalOpen} from={"manage"}/>
                }
                </div>
                <button style={{padding:'1vw 1vh', height:'2vh', width:'fit-content', fontSize:'small'}} className="green-button" onClick={() => {
                    setIsDeleteAllModalOpen(true)
                    }}>Unassign All</button>

                <button style={{padding:'1vw 1vh', height:'2vh', width:'fit-content', fontSize:'small', marginLeft: '1vw'}} className="green-button" onClick={() => {
                    remindReviewer()
                    }}>Remind Reviewer</button>

            </div>
        </div>
            {deleteLoading && <Spinner />}
            {
                selectedReviewerApplications.length === 0  && loadingReviewerApplications === false? 
                <div>
                    <label>No applications assigned to {selectedReviewer.firstName} {selectedReviewer.lastName}</label>
                </div>
                :
                <div>
                    <ReviewerApplicationsTable sortData={sortData} data={selectedReviewerApplications} selectedReviewer={selectedReviewer} columns={columns}/>
                    <div className="faculty-reviewes-table-footer">
                        <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
                    </div>
                </div>
            }
           
            </div>
           
           </div>
        </div>
}
        </>
    );
}

export default ManageReviews;

