import React,{useState, useContext} from "react";
import "./styles/index.css";
import { useQuery } from "@apollo/client"; 
import { ProgramIdContext } from '../../progIdContext';
import {UserContext} from '../../../../context/userContext';
import Spinner from "../../../common/spinner";
import IMHeader from "./header";
import ProgressCont from "./dayCustomize/progressCard"; 
import { SingleInterviewScheduleProvider } from "./SingleInterviewScheduleContext";
import { InterviewScheduleProvider } from "./interviewScheduleContext";
import EmailTemplate from "./emailTemplates";
import EmailTemplateMenu from "./emailTemplate/emailTemplatesMenu";
import InviteApplicant from "./invite";
import Plan from './plan';

const InterviewManager = () => {
  const [page, setPage] = useState("customize");
  const [template, setTemplate] = useState({});

    return (
        <div className='faculty-home-container'>
            <ellipse className='ellipseDesignTop1' />
            {/* <ellipse className='ellipseDesignTop2' /> */}
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="faculty-home-header">
            <IMHeader setPage={setPage} page={page}/>
            </div>
            <seperator className="faculty-home-header-seperator"/>
            {page === 'customize' && (
            <div className="faculty-home-body" style={{flexDirection:'row', gap:'.78vw'}}>
                <InterviewScheduleProvider>
            <ProgressCont comp='calendar' />
            <ProgressCont comp='progress' />
            </InterviewScheduleProvider>
                </div>
            )}
            {
                page === 'invite' && (
                    <InviteApplicant/>
                )
            }
            {
                page === 'plan' &&
                <SingleInterviewScheduleProvider>
                <Plan />
                </SingleInterviewScheduleProvider>
            }
            {page === 'emailTemplates' && (
            <div className="faculty-home-body" style={{flexDirection:'row', gap:'.78vw'}}>
                <EmailTemplate selectedTemplate={template} setTemplate={setTemplate} showFullTemplate={true}/>
                <EmailTemplateMenu template={template} setTemplate={setTemplate} />
                </div>
            )}


        </div>
        
    );
}

export default InterviewManager;

