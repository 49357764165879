import React from 'react';
import './styles/header.css'; // Assuming styles are in Header.css
import logo from '../../../assets/imgs/logo.svg'; // Replace with the path to your logo image

const Header = () => {
  return (
    <header className="header">
      <img src={logo} alt="Logo" className="logo" />
      {/* <h1 style={{ textAlign:'center', width: '100%', height: '87px', fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: 500, fontSize: '5vh', lineHeight: '120.5%', color: '#171717', flex: 'none', order: 1, flexGrow: 1}}>Smart Recruiting</h1> */}
      {/* <nav className="navigation"> */}
        {/* <a href="/faqs" className="nav-link">FAQs</a> 
        <a href="/contact" className="nav-link nav-link--contact">Contact Us</a>
        <a href="/participating-programs" className="nav-link nav-link--contact">Participating Programs</a> */}
      {/* </nav> */}
    </header>
  );
};

export default Header;
