import {gql} from '@apollo/client';

export const CREATE_POSITION = gql`
  mutation CreatePosition($input: PositionInput!) {
    createPosition(input: $input) {
      programId
      jobTitle
      summary
      numberOfOpenings
      positionStatus
    }
  }
`;

export const UPDATE_POSITION = gql`
  mutation UpdatePosition($positionId: String!, $input: PositionInput!) {
    updatePosition(positionId: $positionId, input: $input) {
    id
      programId
      jobTitle
      jobReferenceNumber
      department
    }
  }
`;

export const DELETE_POSITION = gql`
  mutation DeletePosition($id: ID!) {
    deletePosition(id: $id)
  }
`;


export const generatePositionDescription = gql`
    mutation generatePositionDescription($input: AiReqsInput!){
        generatePositionDescription(input: $input){
        fullJobDescription
        summary
        }
    }
`