export const genderDataValues = [
    {value:'male', label:'Male'},
    {value:'Male', label:'Male'},
    {value:'Man', label:'Man'},
    {value:'man', label:'Man'},
    {value:'female', label:'Female'},
    {value:'Female', label:'Female'},
    {value:'Woman', label:'Woman'},
    {value:'woman', label:'Woman'},
    {value:'transWoman',label:'Transgender Woman'},
    {value:'transFemale',label:'Trans Female'},
    {value:'transMan', label:'Transgender Man'},
    {value:'transMale',label:'Trans Male'},
    {value:'nonBinary',label:'Gender Nonbinary'},
    {value:'notListed',label:'Gender Not Listed'},
    {value:'noResponse',label:'Prefer Not To Respond'}
]

export const coreClerkshipDataValues = [
    {value:'pf', label:'Pass/Fail'},
    {value:'hpf', label:'Honors/Pass/Fail'},
    {value:'hhppf', label:'Honors/High Pass/Pass/Fail'},
    {value:'ng', label:'Numerical Grade'},
    {value:'lg', label:'Letter Grade'},
    {value:'other', label:'Other'},
    {value:'pass', label:'Pass'},
    {value:'fail', label:'Fail'},
    {value:'honors', label:'Honors'},
    {value:'high-pass', label:'High Pass'},

]
