import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import { Formik, label, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { VERIFY_TOKEN } from "./graphql/queries";
import { UPDATE_USER } from "./graphql/mutations";
import logo from "../../assets/imgs/logo.svg";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useNavigate } from 'react-router-dom';

import { Link } from "react-router-dom";
import Header from "../home/components/header.jsx";
import HomePageTitle from "../home/components/homePageTitle.jsx";
import computerScreen from "../../assets/imgs/computerScreen.svg"; // Import the computerScreen image
import RoleSelector from "../home/components/roleSelector.jsx";
import Footer from "../home/components/footer.jsx";

const validationSchema = Yup.object({
  firstName: Yup.string()
    .matches(/^[a-zA-Z]+$/, "Names must be words")
    .required("Required"),
  lastName: Yup.string()
    .matches(/^[a-zA-Z]+$/, "Names must be words")
    .required("Required"),
  phone: Yup.string()
    .matches(/^\+?\d+$/, "Phone number must be digits or can start with a '+'")
    .required("Required"),
    password: Yup.string()
      .min(8, "Password must contain at least one character, one number, and one special character")
      .matches(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}":;'<>?,./]).*$/, "Password must contain at least one character, one number, and one special character")
      .required('Required'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});

const CreateUser = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const { data, error } = useQuery(VERIFY_TOKEN, {
    variables: { token },
  });
  const [updateUser] = useMutation(UPDATE_USER);
  const [modalOpen, setModalOpen] = useState(false);
  const [accept, setAccept] = useState(false);
  const [userCreated, setUserCreated] = useState(false);
  const [displayTemp, setDisplayTemp] = useState(true);
  const[type, setType] = useState('');
  const inputRef = useRef(null);


 


  if (error) {
    return (
      <div
        className="faculty-home-container"
        style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}
      >
        <ellipse className="ellipseDesignTop1" />
        <ellipse className="ellipseDesignTop2" />
        <ellipse className="ellipseDesignTop3" />
        <ellipse className="ellipseDesignTop4" />
        <div className="faculty-home-header">
          <img src={logo} alt="logo" style={{ float: "right" }} />
          <h2 className="faculty-home-header-title">
            WELCOME TO Smart Recruiting
          </h2>
          {/* <p className="faculty-home-header-subtitle"> Please fill in you details below to finalize your account creation</p> */}
        </div>
        <seperator className="faculty-home-header-seperator" />
        <div className="faculty-home-body">
          <h2
            style={{
              marginTop: "14vh",
              color: "red",
              fontSize: "4vh",
              fontFamily: "Montserrat",
              fontWeight: "500",
            }}
          >
            Invalid or Expired Token, please contact Customer Support
          </h2>
        </div>
      </div>
    );
  } else {
    return (
      <main>
        <ellipse
          style={{
            position: "absolute",
            width: "134px",
            height: "126px",
            left: "1319px",
            top: "-17px",
            background: "#2a93d5",
            filter: "blur(118.5px)",
          }}
        />
        <Header style={{ padding: "2vw" }} />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img
            src={computerScreen}
            alt="Computer Screen"
            className="computerScreenImage"
            style={{ zIndex: 1 }}
            width="600"
            height="200"
            loading="lazy"
          />
          <ellipse
            style={{
              position: "absolute",
              width: "833px",
              height: "18vh",
              left: "-230px",
              top: "82vh",
              background: "#3F3F3F",
              opacity: "0.3",
              filter: "blur(50.5px)",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <HomePageTitle />
            <RoleSelector  setType={setType}/>
            <div className="signin-modal-backdrop">
              <div className="signin-modal" style={{width:'57vw', minHeight:'97vh', top:'1.5vh'}}>
                <div className="header-container" style={{justifyContent:'center'}}>
                  <h2 style={{fontSize:'5vh', fontWeight:'500', marginBottom:'2vh', width:'100%', borderBottom:'solid', fontFamily: 'Montserrat'}}>Create Your Account</h2>
                </div>
                {!userCreated && (
                  <div className="faculty-home-body">
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        phone: "",
                        password: "",
                        passwordConfirm: "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        updateUser({
                          variables: {
                            id: data.verifyToken.userId,
                            input: {
                              id: data.verifyToken.userId,
                              firstName: values.firstName,
                              lastName: values.lastName,
                              phone: values.phone,
                              password: values.password,
                              isPending: false,
                              accepteTermsOfService: accept,
                            },
                          },
                        }).then(() => {
                          setUserCreated(true);
                          setSubmitting(false);
                          setTimeout(() => {
                            navigate('/')
                          }, [2000])

                        });
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "4vh",
                            width: "57vw",
                            marginLeft: "11vw",
                            background: "white",
                            zIndex: "1",
                            // padding: "5vh",
                          }}
                        >
                          <div className="fields-group" style={{width:'85%'}}>
                            <label
                              className="four-group-inner-header"
                              style={{
                                fontSize: "4vh",
                                lineHeight: "unset",
                                fontWeight: "300",
                                marginBottom: "unset",
                              }}
                            >
                              First Name
                            </label>
                            <Field
                              name="firstName"
                              type="text"
                              className="application-form-standard-input"
                            />
                          </div>
                          <ErrorMessage
                            name="firstName"
                            component="div"
                            style={{
                              color: "red",
                              fontWeight: "500",
                              fontSize: "2vh",
                            }}
                          />
                          <div className="fields-group" style={{width:'85%'}}>
                            <label
                              className="four-group-inner-header"
                              style={{
                                fontSize: "4vh",
                                fontWeight: "300",
                                lineHeight: "unset",
                                marginBottom: "unset",
                              }}
                            >
                              Last Name
                            </label>
                            <Field
                              name="lastName"
                              type="text"
                              className="application-form-standard-input"
                            />
                          </div>
                          <ErrorMessage
                            name="lastName"
                            component="div"
                            style={{
                              color: "red",
                              fontWeight: "500",
                              fontSize: "2vh",
                            }}
                          />
                          <div className="fields-group" style={{width:'85%'}}>
                            <label
                              className="four-group-inner-header"
                              style={{
                                fontSize: "4vh",
                                fontWeight: "300",
                                lineHeight: "unset",
                                marginBottom: "unset",
                              }}
                            >
                              Cell Phone number
                            </label>
                            <div style={{display:'flex', flexDirection:'column'}}>
                            <PhoneInput
                              style={{
                                background:
                                  "linear-gradient(0deg, #f2f2f2, #f2f2f2), #fff",
                              }}
                              displayInitialValueAsLocalNumber={true}
                              defaultCountry="US"
                              ref={inputRef}
                              autoComplete="off"
                              value={values.phone}
                              onChange={(phone) =>
                                setFieldValue("phone", phone)
                              }
                              className="application-form-standard-input"
                            />
                            <label st className='phone-2fa-label'>This number must receive text messages for 2 Factor Authentication</label>
                          </div>
                          <ErrorMessage
                            name="phone"
                            component="div"
                            style={{
                              color: "red",
                              fontWeight: "500",
                              fontSize: "2vh",
                            }}
                          />
                          </div>
                          <div className="fields-group" style={{width:'85%'}}>
                            <label
                              className="four-group-inner-header"
                              style={{
                                fontSize: "4vh",
                                fontWeight: "300",
                                lineHeight: "unset",
                                marginBottom: "unset",
                              }}
                            >
                              Password
                            </label>
                            <Field
                              name="password"
                              type="password"
                              className="application-form-standard-input"
                            />
                          </div>
                          <ErrorMessage
                            name="password"
                            component="div"
                            style={{
                              color: "red",
                              fontWeight: "500",
                              fontSize: "2vh",
                            }}
                          />
                          <div className="fields-group" style={{width:'85%'}}>
                            <label
                              className="four-group-inner-header"
                              style={{
                                fontSize: "4vh",
                                fontWeight: "300",
                                lineHeight: "unset",
                                marginBottom: "unset",
                              }}
                            >
                              Confirm Password
                            </label>
                            <Field
                              name="passwordConfirm"
                              type="password"
                              className="application-form-standard-input"
                            />
                          </div>

                          <ErrorMessage
                            name="passwordConfirm"
                            component="div"
                            style={{
                              color: "red",
                              fontWeight: "500",
                              fontSize: "2vh",
                            }}
                          />
                          <label
                            className="terms-of-use-title"
                            onClick={() => setModalOpen(!modalOpen)}
                            style={{width:'50vw'}}
                          >
                            By clicking ‘Create User’ below, I acknowledge that
                            I have read and agree to the{" "}
                            <label style={{ color: "#145388", width:'50vw' }}>
                              Terms of Use
                            </label>{" "}
                            and the Applicant Code of Ethics as provided by DDH.
                          </label>
                          {modalOpen && (
                            <div className="modal-backdrop">
                              <div
                                className="add-field-modal"
                                style={{
                                  width: "70vw",
                                  height: "80vh",
                                  overflow: "scroll",
                                }}
                              >
                                <p className="terms-title">Terms of Service</p>
                                <p className="terms-normal">
                                  By using this website, the user ("You") is
                                  bound by the terms of this User Agreement
                                  ("Agreement"). By clicking Accept below, You
                                  acknowledge that You have read and agree to
                                  the terms in this Agreement.
                                </p>
                                <br />
                                <br />
                                <p className="terms-normal">
                                  This Agreement governs the use of The
                                  Residency Smart Recruiting (Open Application)
                                  through accessGME.com, which is owned and
                                  operated by Learner-Centric Applications, LLC
                                  ("DDH"). Use of accessGME.com constitutes
                                  agreement to all terms and conditions herein.
                                  This Agreement constitutes a contract between
                                  You and DDH. You accept the terms of this
                                  Agreement by access and submission of the
                                  Open Application through accessGME.com. If You
                                  violate this Agreement, Your access may be
                                  suspended or terminated without warning.
                                </p>
                                <br />
                                <br />
                                <p className="terms-normal">
                                  This Agreement may be revised periodically.
                                  You will be notified of the changes and asked
                                  to accept those changes as an updated
                                  Agreement. Continued use of accessGME.com
                                  constitutes Your acceptance of the newest
                                  Agreement.
                                </p>
                                <br />
                                <br />
                                <p
                                  className="terms-normal"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Users
                                </p>
                                <br />
                                <br />
                                <p className="terms-normal">
                                  To participate in the Open Application on
                                  accessGME.com, you must be (1) an applicant to
                                  residency currently eligible to begin training
                                  in the upcoming academic year ("Applicant"),
                                  (2) a person affiliated with an approved
                                  residency training program (“Program User”
                                  [e.g., Coordinator, Director, Reviewer]), (3)
                                  a person supporting a residency applicant with
                                  a letter of recommendation (“Recommender”) or
                                  (4) a person affiliated with an Applicant’s
                                  medical school verifying information, or
                                  uploading their medical school transcript or
                                  Medical School Performance Evaluation
                                  (“Medical School Representative”). The Central
                                  App is for use only to: (1) apply to a
                                  residency program (“Residency”), (2) to review
                                  an applicant’s data who is applying to Your
                                  residency program, or (3) to submit documents
                                  required for the application. Any other use of
                                  the Open Application is prohibited. Elements of the
                                  Open Application and accessGME.com, including
                                  content and software coding, are protected
                                  under copyright, trademark and other laws. Do
                                  not copy or adapt such code.
                                </p>
                                <br />
                                <br />
                                <p className="terms-normal">
                                  You are responsible for maintaining the
                                  confidentiality of Your account and all data
                                  available through that account, including
                                  Applicant data from Program Users’ accounts.
                                  As a verified recommender, reviewer, program
                                  director, or program coordinator, your name,
                                  email address, and institution will be
                                  searchable by both applicants using the letter
                                  of recommender search tool and unverified
                                  users seeking verification status. Do not
                                  share passwords, account information, or login
                                  credentials. You are liable for data breaches
                                  resulting from inappropriate account access.
                                  Immediately notify DDH of any unauthorized use
                                  of or access to Your account (
                                  <a
                                    href="mailto:help@accessGME.com"
                                    style={{ color: "blue" }}
                                  >
                                    help@accessGME.com
                                  </a>
                                  ).
                                </p>
                                <br />
                                <br />
                                <p className="terms-normal">
                                  Applicants will not submit any information on
                                  behalf of another Applicant, submit more than
                                  one application to a single specialty, submit
                                  any incomplete, false or inaccurate
                                  information or information which is not their
                                  own. Program Users will not access data not
                                  intended for their Residency. Both You and DDH
                                  agree to use appropriate physical, technical,
                                  and administrative measures to protect and
                                  secure Open Application data from loss, misuse,
                                  unauthorized access, disclosure, alteration or
                                  destruction. Violations will be reported to
                                  specialty society leadership, and Your
                                  accessGME.com account will be terminated.
                                </p>
                                <br />
                                <br />
                                <p className="terms-normal">
                                  DDH and accessGME.com will not tolerate
                                  defamatory, libelous, implicitly or explicitly
                                  offensive, vulgar, obscene, threatening,
                                  abusive, hateful, racist, or discriminatory
                                  content. Violations of system or network
                                  security may result in civil and/or criminal
                                  liability. DDH will investigate and cooperate
                                  with law enforcement authorities in
                                  prosecuting criminal violations.
                                </p>
                                <br />
                                <br />
                                <p
                                  className="terms-normal"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Obligations, Outcomes and Data{" "}
                                </p>
                                <br />
                                <br />
                                <p className="terms-normal">
                                  DDH will maintain confidentiality and privacy
                                  of data submitted to accessGME.com. DDH is
                                  responsible for the proper function and
                                  support of accessGME.com, but does not warrant
                                  errorless operations. We will address all
                                  concerns promptly (
                                  <a
                                    href="mailto:help@accessGME.com"
                                    style={{ color: "blue" }}
                                  >
                                    help@accessGME.com
                                  </a>
                                  ).
                                </p>
                                <br />
                                <br />
                                <p className="terms-normal">
                                  DDH is not responsible for any outcomes from
                                  Application through the Open Application or any
                                  residency selection decisions or outcomes. By
                                  using accessGME.com, You agree You will not
                                  hold DDH liable for any damages, either
                                  incidental or consequential. You agree that
                                  the maximum liability arising out of any
                                  action associated with DDH, regardless of the
                                  causes or damages, will not exceed Your cost
                                  for Application through the Open Application. You
                                  agree to indemnify and hold harmless DDH from
                                  any claims resulting from use of
                                  accessGME.com. Should You bring any legal
                                  action in connection with Your use or outcomes
                                  of the Open Application, You will be responsible
                                  for attorneys’ fees and all other reasonable
                                  costs to DDH as part of that action. The NRMP
                                  is responsible for outcomes of the Match. DDH
                                  maintains all rights and intellectual property
                                  associated with the Open Application and{" "}
                                  <a href="https://accessGME.com">
                                    accessGME.com
                                  </a>
                                  .
                                </p>
                                <br />
                                <br />
                                <p className="terms-normal">
                                  You are solely responsible for the information
                                  You submitted to Open Application. Data submitted
                                  must be accurate and describe You. You
                                  acknowledge and agree that You are solely
                                  responsible for the form, content and accuracy
                                  of any data or material you submit on
                                  accessGME.com. Participation in the Central
                                  App grants rights to your data to DDH
                                  perpetuity, Program Users and Recommenders in
                                  perpetuity. Your data may be used by DDH to
                                  improve the Application through the Central
                                  App or accessGME.com. DDH is not liable for
                                  the deletion, loss, or unauthorized
                                  modification of any data.
                                </p>
                                <br />
                                <br />
                                <p className="terms-normal">
                                  Programs are responsible for verifying the
                                  accuracy of all application data. DDH does not
                                  represent or guarantee the truthfulness,
                                  accuracy, or reliability of Open Application
                                  content. You acknowledge that use of
                                  accessGME.com and any reliance on Open Application
                                  data will be at Your own risk. Search, scoring
                                  and analytic features may yield disparate or
                                  incomplete results. Only a complete, holistic
                                  review of applications can provide a summation
                                  of Applicants by a Residency.
                                </p>
                                <br />
                                <br />
                                <p className="terms-normal">
                                  DDH may remove any content that violates this
                                  Agreement, violates applicable laws, rules or
                                  regulations, is abusive, disruptive, offensive
                                  or illegal, or violates the rights of persons.
                                  DDH reserves the right to expel Users and
                                  prevent their further access to AccessGME.com.
                                  DDH will make all reasonable efforts to verify
                                  Users and content on AccessGME.com. The
                                  Open Application may contain errors and feedback is
                                  welcome (
                                  <a
                                    href="mailto:help@accessGME.com"
                                    style={{ color: "blue" }}
                                  >
                                    help@accessGME.com
                                  </a>
                                  ). User-entered inaccuracies are the
                                  responsibility of the User. If You believe
                                  that something on{" "}
                                  <a href="https://accessGME.com">
                                    accessGME.com
                                  </a>{" "}
                                  violates this Agreement please contact us (
                                  <a
                                    href="mailto:help@accessGME.com"
                                    style={{ color: "blue" }}
                                  >
                                    help@accessGME.com
                                  </a>
                                  ).
                                </p>
                                <br />
                                <br />
                                <p className="terms-normal">
                                  These Terms are governed by the laws of
                                  Tennessee. Jurisdiction for any claims arising
                                  under this agreement shall lie within the
                                  exclusive jurisdiction of the United States
                                  District Court for the Middle District of
                                  Tennessee or a local court sitting in Davidson
                                  County, Tennessee. No changes to this
                                  Agreement shall be made except by a revised
                                  posting on this page.
                                </p>
                                <br />
                                <br />
                                <p className="terms-title">
                                  AUTHORIZATION FOR VERIFICATION OF USMLE SCORES
                                  AND TRANSFER OF TRANSCRIPT AND MSPE DOCUMENTS
                                  TO THE RESIDENCY Smart Recruiting
                                </p>
                                <br />
                                <br />
                                <p className="terms-normal">
                                  I request and grant my consent for my medical
                                  school officials to verify my USMLE scores and
                                  transfer my transcript and MSPE (Medical
                                  Student Performance Evaluation) documents to
                                  the Residency Smart Recruiting. I
                                  understand that this information is essential
                                  for the residency application process, and I
                                  willingly authorize the release of these
                                  documents to facilitate my application to
                                  residency programs. I acknowledge that the
                                  Residency Smart Recruiting is a secure
                                  platform designed to improve equity in the
                                  residency selection process by eliminating
                                  financial barriers and enhancing holistic
                                  review. I am aware that this platform has been
                                  rigorously developed and piloted, and its
                                  usage aims to promote a fair and transparent
                                  application process for all applicants.
                                </p>
                                <br />
                                <br />
                                <p className="terms-normal">
                                  By granting this consent, I confirm that I
                                  have carefully reviewed and understand the
                                  implications of sharing my USMLE scores,
                                  transcript, and MSPE documents through the
                                  Residency Smart Recruiting. I trust that
                                  the medical school and Residency Central
                                  Application will handle my information
                                  responsibly and with utmost confidentiality. I
                                  hereby agree to allow the medical school to
                                  proceed with the verification of my USMLE
                                  scores and the transfer of my transcript and
                                  MSPE documents to the Residency Central
                                  Application as part of my residency
                                  application process.
                                </p>
                                <br />
                                <br />
                                <p className="terms-title">
                                  APPLICANT CODE OF ETHICS
                                </p>
                                <br />
                                <br />
                                <p className="terms-normal">
                                  The information I submit in this application
                                  is complete, factual, and accurate.
                                </p>
                                <br />
                                <p className="terms-normal">
                                  There are no falsehoods by commission or
                                  omission, and the content is all my own work.
                                </p>
                                <br />
                                <p className="terms-normal">
                                  Applicants found in violation of the code of
                                  ethics will be reported to their medical
                                  school, program director groups, and to the
                                  appropriate member board of the American Board
                                  of Medical Specialties.
                                </p>
                                <br />
                                <p className="terms-normal">
                                  Match results based on irregularities or
                                  inaccuracies may not be honored by the
                                  program.
                                </p>
                                <div className="buttons-tos">
                                  <button
                                    type="submit"
                                    className="white-button"
                                    onClick={() => {
                                      setAccept(true);
                                      setModalOpen(!modalOpen);
                                    }}
                                  >
                                    Accept
                                  </button>
                                  <button
                                    type="submit"
                                    className="green-button"
                                    onClick={() => setModalOpen(!modalOpen)}
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
{
  console.log("phone before button =>", values.phone)
}
                          <button 
                          disabled={(values.firstName === "" || values.lastName === "" || values.phone === "" || values.phone === undefined || values.password === "" || values.passwordConfirm === "")  || 
                          (values.firstName !== "" && values.lastName !== "" && values.phone !== "" && values.password !== "" && values.passwordConfirm !== "" && values.password !== values.passwordConfirm)} 
                          type="submit" style={{width:'48vw'}} className="green-button">
                            Create User
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                )}
                {userCreated && (
                  <p
                    style={{
                      fontSize: "4vh",
                      color: "black",
                      fontFamily: "Montserrat",
                      textAlign: "center",
                      alignItems: "center",
                      display: "flex",
                      height: "70vh",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      whiteSpace: "break-spaces",
                    }}
                  >
                    Your user was created successfully. You will be redirected to your account shortly. If you were not redirected 
                    <Link style={{color: "#145388" }} to="/">click here</Link> to login manually
                    {/* <Link to="/">click here</Link> to login. */}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
};

export default CreateUser;
