import React,{useState,useEffect} from "react";
import backIcon from "../../../../assets/imgs/backIcon.svg"
import './responseView.css';
import logo from '../../../../assets/imgs/logo.svg'; // Import the logo image
import ResponseTable from './responseTable';
import { getReviewsByApplicationId } from '../completedReviews/graphql/queries';
import client from '../../../../config/apolloClient';
import { useQuery } from '@apollo/client';
import Spinner from "../../../common/spinner";

const ResponseModal = ({  responses, onClose }) => {
const [dataToUse, setDataToUse] = useState([]);
const [combinedColumns, setCombinedColumns] = useState([]);
const [loading, setLoading] = useState(true);


useEffect(() => {
console.log("responses in response modal =>", responses)
const rawData = responses;


    const aggregatedData = rawData.specificReviews.reduce((acc, review) => {
        // Create a unique key for the reviewer
        const reviewerKey = review.reviewerFirstName + ' ' + review.reviewerLastName;
    
        // If the reviewer doesn't exist in the accumulator, add them
        if (!acc[reviewerKey]) {
            acc[reviewerKey] = { reviewerName: reviewerKey };
        }
    
        // Add or overwrite the rubricLabel score for the reviewer
        acc[reviewerKey][review.rubricLabel] = review.rubricText ? review.rubricText : review.rubricResult;
    
        return acc;
    }, {});
    const dataToUse = Object.values(aggregatedData);

setDataToUse(dataToUse);

const uniqueHeaders = new Set();
const dynamicColumns = rawData.specificReviews.reduce((acc, response) => {
    if (!uniqueHeaders.has(response.rubricLabel)) {
        uniqueHeaders.add(response.rubricLabel);
        acc.push({
            Header: response.rubricLabel,
            accessor: response.rubricLabel,
        });
    }
    return acc;
}, []);

            

    const columns = [
        {
            id: 'reviewerName',
            accessor: 'reviewerName',
            Header: "Reviewer Name",
        }
    ]
    
    const combinedColumns = [...columns, ...dynamicColumns]

    setCombinedColumns(combinedColumns);
    setLoading(false);
}, [responses])



if (loading) {
    return <Spinner />
}
    return (
        <div className='modal-backdrop'>
            <div className="modal" style={{ height:'60vh', overflow: 'auto'}}>
                <div className="modal-header" style={{ marginTop: '4vh', marginLeft: '2vw'}}>
                    {/* <button style={{marginBottom:'2vh'}} className="white-button" onClick={onClose}><img src={backIcon}/> Back to Completed Reviews</button> */}
                    <h2 className="faculty-home-header-title">COMPLETED REVIEWS</h2>
                    <h2 className="faculty-home-header-title" onClick={() => onClose()} style={{ marginRight: '1vw', fontSize: '1.4rem', cursor: 'pointer'}}>X</h2>

                </div>
                <div className="modal-body">
                    <ResponseTable columns={combinedColumns} data={dataToUse} />
                </div>
            </div>
        </div>
    )
}

export default ResponseModal;