import { gql } from '@apollo/client';

export const APPLICANT_SCHEDULED_SUBSCRIPTION = gql`
  subscription OnApplicantScheduled($programIds: [ID!]!) {
    applicantScheduled(programIds: $programIds) {
    programId
    results {
      id
      userId
      profilePhotoUrl
      firstName
      lastName
      institution
      nrmpId
      invitationStatus
      interviewDate
    }
    }
  }
`;

export const POPULATED_DATES_CHANGE_SUBSCRIPTION = gql`
  subscription OnPopulatedDatesChange($programIds: [ID!]!) {
    populatedDatesChange(programIds: $programIds) {
      programId
      dates{
        date
        status
        isRegularDay
        startTime
        totalAvailableSlots
        currentAvailableSlots
      }
    }
  }
`;