import { gql } from "@apollo/client";

export const GET_PROGRAM_USERS_BY_PROGRAM_ID = gql`
query getProgramMembersByProgramId($programId: ID!) {
  getProgramMembersByProgramId(programId: $programId) {
      userId {
        id
        firstName
        lastName
        email
        isPending
      }
      roles
    }
  }
`;

export const LIST_POSITIONS = gql`
  query ListPositions($programId: ID!) {
    listPositions(programId: $programId) {
      id
      programId
      jobTitle
      summary
      numberOfOpenings
      positionStatus
    }
  }
`;


export const GET_POSITION = gql`
  query GetPosition($positionId: String!) {
    getPosition(positionId: $positionId) {
      programId
      jobTitle
      department
      hiringManager{
      id
      }
      summary
      detailedDescription
      responsibilities
      dayToDayActivities
      minimumQualifications
      preferredQualifications
      requiredSkills
      preferredSkills
      educationLevel
      certifications
      experienceLevel
      languagesRequired
      licenses
      employmentType
      jobLevel
      salaryRange {
        min
        max
      }
      benefitsOffered
      workSchedule
      workLocation {
        city
        state
        country
      }
      relocationAssistance
      closingDate
      requiredDocuments
      screeningQuestions
      positionStatus
      visibility
      legalDisclaimers
      securityClearanceRequired
      physicalRequirements
      backgroundCheckRequired
      drugTestRequired
    }
  }
`;