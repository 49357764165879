import { Form, Formik, Field, ErrorMessage } from 'formik';
import React, { useState, useEffect } from 'react';
import logo from '../../../../assets/imgs/logo.svg';
import * as Yup from "yup";

const validationSchema = Yup.object({
    lors: Yup.number()
    .typeError('Must be a number')
    .required('Required'),
    cost: Yup.number()
    .typeError('Must be a number')
    .required('Required'),
  });

const AddSpecialtyModal = ({ isOpen, onClose, saveSpecialty }) => {

    const addDateField = (values, setFieldValue) => {
        setFieldValue('importantDates', [...values.importantDates, '']);
    };

    const addShortAnswerField = (values, setFieldValue) => {
        if (values.shortAnswerQuestions.length < 3) {
            setFieldValue('shortAnswerQuestions', [...values.shortAnswerQuestions, '']);
        }
    };


    if (!isOpen) return null;
    return (
        <div className="modal-backdrop">
            <div className="add-field-modal">
                <div className='add-field-modal-header'>
                    <h2>ADD NEW SPECIALTY</h2>
                    <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
                </div>

                <Formik
                    initialValues={{
                        name: '',
                        description: '',
                        releaseDate: '',
                        importantDates: [],
                        shortAnswerQuestions: [],
                        degree: '',
                        lors: '',
                        mspe: '',
                        transcript: '',
                        step1: '',
                        step2: '',
                        cost: '',
                    }}
                    onSubmit={values => {
                        console.log("values =>", values)
                        saveSpecialty(values)
                        onClose()
                    }}
                    validationSchema={validationSchema}
                >
                 {({ handleSubmit, values, setFieldValue }) => (

                    <Form style={{ height: '55vh', overflowY: 'auto'}}>
                    <div className='application-form-field-group' >
                        <label htmlFor="name" className='applicant-form-field-label'>Name</label>
                        <Field name="name" placeholder="" className='application-form-standard-input' style={{width:'100%'}} />
                        <div className='application-form-error-message'> 
                            <ErrorMessage name="name" component="div" />
                        </div>
                    </div>
                    <div className='application-form-field-group' style={{marginBottom: '4vh'}}>
                        <label htmlFor="description" className='applicant-form-field-label'>Description</label>
                        <Field name="description" placeholder="" className='application-form-standard-input' style={{width:'100%'}} />
                        <div className='application-form-error-message'> 
                            <ErrorMessage name="description" component="div" />
                        </div>
                    </div>

                    <div className='application-form-field-group' style={{marginBottom: '4vh'}}>
                        <label htmlFor="releaseDate" className='applicant-form-field-label'>Release Date</label>
                        <Field name="releaseDate" placeholder="MM/DD/YYYY" className='application-form-standard-input' style={{width:'100%'}} />
                        <div className='application-form-error-message'> 
                            <ErrorMessage name="releaseDate" component="div" />
                        </div>
                    </div>

                    {/* important dates */}
                    <div className='application-form-field-group'>
                        <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                            <label className='applicant-form-field-label'>Important Dates</label>
                            <button
                                    type="button"
                                    onClick={() => addDateField(values, setFieldValue)}
                                    style={{
                                        marginLeft: '10px',
                                        cursor: 'pointer',
                                        border: 'none',
                                        background: 'linear-gradient(96.75deg, #2a93d5 0%, #596173 133.3%)',
                                        color: '#FFFFFF',
                                        borderRadius: '5px',
                                        padding: '5px 10px'
                                    }}
                                >
                                    Add Date
                            </button>
                        </div>
                                {values.importantDates.map((date, index) => (
                                    <div key={index} style={{ marginTop: '1vh' }}>
                                        <Field
                                            name={`importantDates[${index}]`}
                                            placeholder="MM/DD/YYYY"
                                            className='application-form-standard-input'
                                            style={{ width: '90%' }}
                                        />
                                      
                                    </div>
                                ))}
                                 
                    </div>
                        {/* Short answer questions */}
                        <div className='application-form-field-group'>
                           <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                <label className='applicant-form-field-label'>Short Answer Questions</label>
                                <button
                                    type="button"
                                    onClick={() => addShortAnswerField(values, setFieldValue)}
                                    style={{
                                        marginLeft: '10px',
                                        cursor: 'pointer',
                                        border: 'none',
                                        background: 'linear-gradient(96.75deg, #2a93d5 0%, #596173 133.3%)',
                                        color: '#FFFFFF',
                                        borderRadius: '5px',
                                        padding: '5px 10px'
                                    }}
                                    disabled={values.shortAnswerQuestions.length >= 3}
                                >
                                    Add Question
                                </button>
                            </div>
                            {values.shortAnswerQuestions.map((question, index) => (
                                <div key={index} style={{ marginTop: '1vh' }}>
                                    <Field
                                        name={`shortAnswerQuestions[${index}]`}
                                        placeholder="Enter question"
                                        className='application-form-standard-input'
                                        style={{ width: '90%' }}
                                    />
                                </div>
                            ))}
                            {values.shortAnswerQuestions.length >= 3 && (
                                <div className='application-form-error-message'>
                                    Maximum 3 questions allowed
                                </div>
                            )}
                        </div>
                    
                    
                    <label className='applicant-form-field-label' style={{fontWeight: 600, marginTop: '6vh'}}>Application Requirements</label>

                    <div className='application-form-field-group'>
                        <label htmlFor="degree" className='applicant-form-field-label'>Degree</label>
                        <Field name="degree" placeholder="" className='application-form-standard-input' style={{width:'100%'}} />
                        <div className='application-form-error-message'> 
                            <ErrorMessage name="degree" component="div" />
                        </div>
                    </div>
                    <div className='application-form-field-group'>
                        <label htmlFor="lors" className='applicant-form-field-label'>Lors</label>
                        <Field name="lors" placeholder="" className='application-form-standard-input' style={{width:'100%'}} />
                        <div className='application-form-error-message'> 
                            <ErrorMessage name="lors" component="div" />
                        </div>
                    </div>
                    <div className='application-form-field-group single' style={{marginBottom:'3vh'}}>
                        <label className='applicant-form-field-label'>MSPE</label>
                        <div role="group" aria-labelledby="radio-group" className='application-form-group-radio' style={{accentColor: '#145388'}}>
                        <label>
                        <Field style={{display:'unset', marginRight:'.4vw', fontSize: '18px'}} type="radio" name="mspe" value="yes" />
                        Yes
                        </label>
                        <label>
                        <Field style={{display:'unset', marginRight:'.4vw'}} type="radio" name="mspe" value="no" />
                        No
                        </label>
                        </div>
                        <div className='application-form-error-message'> 
                        <ErrorMessage name="mspe" component="div" />
                        </div>
                    </div>



                    <div className='application-form-field-group single' style={{marginBottom:'3vh'}}>
                        <label className='applicant-form-field-label'>Transcript</label>
                        <div role="groupTranscript" aria-labelledby="radio-group" className='application-form-group-radio' style={{accentColor: '#145388'}}>
                        <label>
                        <Field style={{display:'unset', marginRight:'.4vw', fontSize: '18px'}} type="radio" name="transcript" value="yes" />
                        Yes
                        </label>
                        <label>
                        <Field style={{display:'unset', marginRight:'.4vw'}} type="radio" name="transcript" value="no" />
                        No
                        </label>
                        </div>
                        <div className='application-form-error-message'> 
                        <ErrorMessage name="transcript" component="div" />
                        </div>
                    </div>
                    <div className='application-form-field-group single' style={{marginBottom:'3vh'}}>
                        <label className='applicant-form-field-label'>Step 1</label>
                        <div role="groupStep1" aria-labelledby="radio-group" className='application-form-group-radio' style={{accentColor: '#145388'}}>
                        <label>
                        <Field style={{display:'unset', marginRight:'.4vw', fontSize: '18px'}} type="radio" name="step1" value="yes" />
                        Yes
                        </label>
                        <label>
                        <Field style={{display:'unset', marginRight:'.4vw'}} type="radio" name="step1" value="no" />
                        No
                        </label>
                        </div>
                        <div className='application-form-error-message'> 
                        <ErrorMessage name="step1" component="div" />
                        </div>
                    </div>
                    <div className='application-form-field-group single' style={{marginBottom:'3vh'}}>
                        <label className='applicant-form-field-label'>Step 2</label>
                        <div role="groupStep2" aria-labelledby="radio-group" className='application-form-group-radio' style={{accentColor: '#145388'}}>
                        <label>
                        <Field style={{display:'unset', marginRight:'.4vw', fontSize: '18px'}} type="radio" name="step2" value="yes" />
                        Yes
                        </label>
                        <label>
                        <Field style={{display:'unset', marginRight:'.4vw'}} type="radio" name="step2" value="no" />
                        No
                        </label>
                        </div>
                        <div className='application-form-error-message'> 
                        <ErrorMessage name="step2" component="div" />
                        </div>
                    </div>
                    <div className='application-form-field-group'>
                        <label htmlFor="cost" className='applicant-form-field-label'>Cost ($)</label>
                        <Field name="cost" placeholder="" className='application-form-standard-input' style={{width:'100%'}} />
                        <div className='application-form-error-message'> 
                            <ErrorMessage name="cost" component="div" />
                        </div>
                    </div>

                    <div style={{ marginTop: '2vh', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <button type="submit" className='green-button' style={{
                            border: 'none',
                            outline: 'none',
                            cursor: 'pointer',
                            background: 'linear-gradient(96.75deg, #2a93d5 0%, #596173 133.3%)',
                            borderRadius: '7px',
                            padding: '12px 30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#FFFFFF',
                            fontWeight:'700',
                            width: '20vw'
                        }}
                        disabled={values.name === "" || values.description === "" || values.shortAnswerQuestions.length === 0 ||
                            values.releaseDate === "" || values.degree === "" || values.lors === "" || values.mspe === "" || 
                            values.transcript === "" || values.step1 === "" || values.step2 === "" || values.cost === ""}
                        >
                        Add Specialty
                        </button>
                        <button className='lor-modal-submit-button' onClick={onClose} style={{
                            outline: 'none',
                            cursor: 'pointer',
                            background: '#FFFFFF',
                            border: '1px solid #145388',
                            borderRadius: '7px',
                            padding: '12px 30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#145388',
                            fontWeight:'700',
                            width: '20vw'

                        }}>Cancel</button>
                        </div>
                    </Form>
                 )}
                </Formik>
            </div>
        </div>
    )

}

export default AddSpecialtyModal;