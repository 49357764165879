import React , {useState, useContext, Suspense} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/home'
import Applicant from './components/applicant';
import Faculty from './components/faculty';
import MedSchool from './components/medSchool';
import { pdfjs } from 'react-pdf';
import worker from 'pdfjs-dist/webpack'
import CreateUser from './components/auth/CreateUser';
import ApplicantDirectLogin from './components/auth/applicantDirectLogin';
import ApplicantDirectSchedule from './components/applicant/directSchedule';
import RequestAccess from './components/auth/requestAccess';
import CreateApplicant from './components/auth/createApplicant';
import MspeUpload from './components/applicant/documents/mspeUpload';
import TranscriptUpload from './components/applicant/documents/transcriptUpload';
import ScoresUploadVerify from './components/applicant/documents/scoresVerification';
import NotFound from './components/common/error';
import FAQS from './components/faqs';
import ParticipatingProgarms from './components/participatingPrograms';
import ContactUs from './components/contactUs';
import DirectLor from './components/general/directLor';
import ApplicantDirectApply from './components/auth/applicantDirectApply';
import Spinner from './components/common/spinner';
import UserProvider  from './context/userContext';
import { UserContext } from './context/userContext';
import ExmPDF from './components/pdf';
import PdfBackend from './components/pdf/pdfBackend';
import ApplicantApplyRoute from './components/applicant/applicantComponents/applyRoute';
import 'bootstrap/dist/css/bootstrap.min.css';

// Set the workerSrc property with an SRI hash for additional security
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@4.4.168/build/pdf.worker.min.mjs`;
// pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js';




const App = () => {
  return (
    <UserProvider>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} /> 
        <Route path="/reset/:token" element={<Home isReset={true} isVerify={false}/>} /> 
        <Route path="/request/:token" element={<RequestAccess isReset={true} isVerify={false}/>} /> 
        <Route path="/applicant" element={<ProtectedRoute component={Applicant} />} />
        <Route path="/faculty" element={<ProtectedRoute component={Faculty} />} />
        <Route path="/med-school" element={<ProtectedRoute component={MedSchool} />} />
        <Route path="/faqs" element={<FAQS />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/participating-programs" element={<ParticipatingProgarms />} />
        <Route path="/user-direct-login/:token" element={<ApplicantDirectLogin />} />
        <Route path="/direct-apply/:token" element={<ApplicantDirectApply />} />
        <Route path="/direct-schedule/:token" element={<ApplicantDirectSchedule />} />
        <Route path="/create-account/:token" element={<CreateUser />} />
        <Route path="/create-account-applicant/:token" element={<CreateApplicant />} />
        <Route path="/mspe-upload/:id" element={<MspeUpload />} />
        <Route path="/transcript-upload/:id" element={<TranscriptUpload />} />
        <Route path="/direct-lor/:id" element={<DirectLor />} />
        <Route path="/scores-verification/:id" element={<ScoresUploadVerify />} />
        <Route path="/verify/:token" element={<Home isVerify={true} isReset={false}/>} />
        <Route path="/example-pdf/:token" element={<ExmPDF />} />
        <Route path="/pdf-be/:token" element={<PdfBackend />} />
        <Route path="/apply/:token" element={<ApplicantApplyRoute />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
    </UserProvider>
  );
};

const ProtectedRoute = ({component: Component}) => {
  const { user, error, loading } = useContext(UserContext);
  
  // If the token is not verified yet, don't make a decision about redirection yet
  if (loading) {
    return <Spinner />
  }
if (!loading&&!error&&user) {
  if (user.userType === 'applicant') {
    return <Applicant />
  }
  if (user.userType === 'faculty' || user.userType === 'admin') {
    return <Faculty />
  } 
  if (user.userType === 'medSchool') {
    return <MedSchool />
  } 
}

if (!loading&&!error&&!user) {
  // If the token has been verified, check if the user is authenticated
  return <Navigate to="/" /> 
};
}


export default App;
