import React, { useState, useEffect, useRef, useContext } from "react";
import './styles/directSchedule.css';
import { useParams } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import { getUserVerificationTokenByOneTimeTokenQuery } from '../../../components/auth/graphql/queries';
import {getApplicantInterviewsQuery} from "../../applicant/interviewManager/graphql/queries";
import { getUserByInvitationTokenQuery } from "./graphql/queries";
import { UserContext } from '../../../context/userContext.js';
import { useNavigate } from 'react-router-dom';
import logo from "../../../assets/imgs/logo.svg";
import InterviewsList from "./components/interviewsList.jsx";
import useConditionalSubscription from "../../applicant/interviewManager/components/useConditionalSubscription";
import {
    declineInterviewInvitationMutation,
    scheduleApplicantInterviewMutation,
    cancelApplicantInterviewMutation
} from "../../applicant/interviewManager/graphql/mutations";
import {APPLICANT_SCHEDULED_SUBSCRIPTION,
    POPULATED_DATES_CHANGE_SUBSCRIPTION,
}from "../../applicant/interviewManager/graphql/subs";
import { isMobile } from 'react-device-detect';
import { ICalendar, GoogleCalendar, OutlookCalendar } from 'datebook';

const ApplicantDirectSchedule = () => {
    const { token } = useParams();
    const { user, setUser } = useContext(UserContext);
    const [userFromQuery, setUserFromQuery] = useState(null);
    const navigate = useNavigate();
    const [programId, setProgramId] = useState(null);
    const [applications, setApplications] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);


    const [declineInterviewInvitation] = useMutation(declineInterviewInvitationMutation);
    const [scheduleApplicantInterview] = useMutation(scheduleApplicantInterviewMutation);
    const [cancelApplicantInterview] = useMutation(cancelApplicantInterviewMutation);

    const { data: datesToSendData, error: datesToSendError } = useConditionalSubscription(POPULATED_DATES_CHANGE_SUBSCRIPTION, { programIds:[programId] });

    const { data, loading, error } = useQuery(getUserByInvitationTokenQuery, {
        variables: { token: token },
    });   

    useEffect(() => {
        if(!loading && !error && data) {
            console.log("data =>", data.getUserByInvitationToken)
            const userData = {
                userId: data.getUserByInvitationToken.userId,
                userType: data.getUserByInvitationToken.userType,
                token: data.getUserByInvitationToken.token
            }
            if(userData) {
                const userToken = userData.token
                localStorage.setItem('login_token', userData.token)
                if(userToken) {
                    setUser(userData);
                    setUserFromQuery(userData);
                    setProgramId(data.getUserByInvitationToken.programId)
                    setApplications(data.getUserByInvitationToken.interviews)
                }
            }
        }
    }, [loading, error, data])


    const handleDeclineInterview = async (applicationId) => {
        setIsLoading(true);
        try {
            await declineInterviewInvitation({
                variables: {
                    applicationId: applicationId
                }
            }).then((res) => {
                // find the index of the application in the applications array
                const index = applications.findIndex(application => application.id === applicationId);
                // remove the application from the applications array
                const updatedApplications = [...applications];
                updatedApplications.splice(index, 1, res.data.declineInterviewInvitation);
                // set the applications array with the updatedApplications
                setApplications(updatedApplications);
                setIsSuccess(true);
                setSuccessMessage('Interview Declined Successfully');
                setIsLoading(false);
            }
            );
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }

    const handleScheduleInterview = async (applicationId, date, programId) => {
        setIsLoading(true);
        try {
            const { data: scheduleResponse } = await scheduleApplicantInterview({
                variables: {
                    applicationIds: [applicationId],
                    programId: programId,
                    scheduleDate: date,
                },
            });

            scheduleResponse.scheduleApplicantInterview.forEach(updatedApplication => {
            const index = applications.findIndex(application => application.id === applicationId);
            if (index !== -1) { 
                const updatedApplications = [
                    ...applications.slice(0, index),
                    updatedApplication,
                    ...applications.slice(index + 1)
                ];

                setApplications(updatedApplications);
                setIsSuccess(true);
                setSuccessMessage('Interview Scheduled Successfully');
            }
        });
    
            // Assuming scheduleResponse is successful and you need to update the UI accordingly
          
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };



    const handleCancelInterview = async (applicationId, programId) => {
        setIsLoading(true);

        try {
            const cancelResponse = await cancelApplicantInterview({
                variables: {
                    applicationIds: [applicationId],
                    programId: programId
                }
            });

            // Assuming cancelResponse is successful and you need to update the UI accordingly
            const index = applications.findIndex(application => application.id === applicationId);
            if (index !== -1) { // Check if the application was found
                const updatedApplication = {
                    ...applications[index],
                    status: cancelResponse.data.cancelApplicantInterview[0].invitationStatus,
                    interviewDate: cancelResponse.data.cancelApplicantInterview[0].interviewDate
                };

                const newUpdatedApplications = [
                    ...applications.slice(0, index),
                    updatedApplication,
                    ...applications.slice(index + 1)
                ];

                setApplications(newUpdatedApplications);
                setIsSuccess(true);
                setSuccessMessage('Interview Cancelled Successfully');
            }

            setIsLoading(false);
        }
        catch (error) {
            console.log(error);
            setIsLoading(false);
        }

    }


    const handleSaveToCalendar = () => {
        const { interviewDate, startTime, programName } = applications[0];

        const startDateTime = new Date(`${interviewDate}T${startTime}`);
        const endDateTime = new Date(startDateTime.getTime() + 3 * 60 * 60 * 1000); // 3 hours later

        const config = {
            title: `Interview with ${programName}`,
            location: 'Online',
            description: 'Interview details and link to be provided.',
            start: startDateTime,
            end: endDateTime,
        };

        const icalendar = new ICalendar(config);
        const googleCalendar = new GoogleCalendar(config);
        const outlookCalendar = new OutlookCalendar(config);

        const downloadEvent = (service) => {
            switch (service) {
                case 'Google':
                    window.open(googleCalendar.render(), '_blank');
                    break;
                case 'Apple':
                    window.open(icalendar.render(), '_blank');
                    break;
                case 'Outlook':
                    window.open(outlookCalendar.render(), '_blank');
                    break;
                default:
                    console.log('Calendar service not supported');
            }
        };

        return (
            <div style={{display:'flex', gap:'10px', marginTop:'10px'}}>
                <button className="green-button" onClick={() => downloadEvent('Google')}>Save to Google Calendar</button>
                <button className="green-button" onClick={() => downloadEvent('Apple')}>Download for Apple Calendar</button>
                <button className="green-button" onClick={() => downloadEvent('Outlook')}>Save to Outlook Calendar</button>
            </div>
        );
    };




    return (
        <div>
                {!loading && userFromQuery && (
               <div
               className="direct-schedule-container"
             >
               <ellipse className="ellipseDesignTop1" />
               <ellipse className="ellipseDesignTop2" />
               <ellipse className="ellipseDesignTop3" />
               <ellipse className="ellipseDesignTop4" />
               <div className="faculty-home-header">
                 <img src={logo} alt="logo" style={{ float: "right" }} />
                 <h2 className="faculty-home-header-title">
                 Smart Recruiting
                 </h2>
                 <p className="faculty-home-header-subtitle"> To schedule your interview with: <strong>{applications[0].programName}, {applications[0].specialty}</strong>, click on an available date from the list below</p>
               </div>
               <seperator className="faculty-home-header-seperator" />
               <div className="faculty-home-body">
                {!isSuccess && (
               <InterviewsList 
                    programs={applications}
                     handleDeclineInterview={handleDeclineInterview}
                     handleScheduleInterview={handleScheduleInterview}
                     handleCancelInterview={handleCancelInterview}
                     isLoading={isLoading}
                     />
                )}
                {isSuccess && (
                    <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', marginTop:'5vh'}}>
                        <h2 style={{color:'green', fontSize:'3vh', fontWeight:'500'}}>{successMessage}</h2>
                        {!isMobile && (
                        <button className="applicant-im-list-item-footer-left-button" onClick={()=>navigate('/applicant')}>Continue to your account</button>
                        )}
                         {applications.length > 0 && handleSaveToCalendar(applications[0])}
                    </div>
                )}
               </div>
             </div>
                )}

             {!userFromQuery && !loading &&(
             <div
             className="faculty-home-container"
             style={{ padding:'1vw'}}
           >
             <ellipse className="ellipseDesignTop1" />
             <ellipse className="ellipseDesignTop2" />
             <ellipse className="ellipseDesignTop3" />
             <ellipse className="ellipseDesignTop4" />
             <div className="faculty-home-header">
               <img src={logo} alt="logo" style={{ float: "right" }} />
               <h2 className="faculty-home-header-title">
                 WELCOME TO Smart Recruiting
               </h2>
               {/* <p className="faculty-home-header-subtitle"> Please fill in you details below to finalize your account creation</p> */}
             </div>
             <seperator className="faculty-home-header-seperator" />
             <div className="faculty-home-body">
               <h2
                 style={{
                   marginTop: "14vh",
                   color: "red",
                   fontSize: "4vh",
                   fontFamily: "Montserrat",
                   fontWeight: "500",
                 }}
               >
                 Invalid or Expired Token, please contact Customer Support
               </h2>
             </div>
           </div>
           )}
        </div>
    )

}

export default ApplicantDirectSchedule;