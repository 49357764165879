import {gql} from '@apollo/client';

export const getUserByInvitationTokenQuery = gql`
    query getUserByInvitationToken($token: String!) {
        getUserByInvitationToken(token: $token) {
            userId
            userType
            programId
            token
            interviews{
            id
            programId
            programName
            status
            interviewDate
            specialty
            datesToSend {
                date
                status
                isRegularDay
                startTime
                totalAvailableSlots
                currentAvailableSlots
              }
            }
        }
    }
`;