import { gql } from "@apollo/client";

export const getAllInstitutionsQuery = gql`
    query getAllInstitutions {
        getAllInstitutions {
            name
            isInternational
            isCa
        }
    }
`


export const getUsersQuery = gql`
query getUsersWithPagination($offset: Int, $limit: Int, $filter: FilterInput, $sort: SortInput) {
    getUsersWithPagination(offset: $offset, limit:$limit, filter:$filter, sort:$sort) {
        id
        firstName
        lastName
        phone
        email
        userType
        permissions
        createdAt
        isVerified
        roles
        verificationToken
        isSuspend
        lastLogin
        lors {
            id
            recommenderEmail
            recommenderName
            stage
        }
        mspe {
            id
            stage
            docUrl
        }
        transcript {
            id
            stage
            docUrl
        }
        scores {
            id
            stage
            usmleStep1Score
            comlexLevel1Score
            usmleStep2Score
            usmleStep3Score
            comlexLevel2Score
            mspeReqEmailAdd
        }
        bypassSMS
        permanentBypassSMS
        institution
    }
}
`

export const getUsersCountQuery = gql`
    query getUsersCount($filter: FilterInput) {
        getUsersCount(filter: $filter)
    }
`

export const getSpecialtiesWithPaginationQuery = gql`
    query getSpecialtiesWithPagination($offset: Int, $limit: Int, $filter: FilterInput, $sort: SortInput){
        getSpecialtiesWithPagination(offset: $offset, limit: $limit, filter: $filter, sort: $sort) {
            id
            name
            releaseDate
            applicationRequirements {
                degree
                lors
                mspe
                transcript
                step1
                step2
                cost
            }
        }
    }
`

export const getSpecialtiesWithPaginationCountQuery = gql`
    query getSpecialtiesWithPaginationCount($filter: FilterInput) {
        getSpecialtiesWithPaginationCount(filter: $filter) 
    }
`

export const getInstitutionsWithPaginationQuery = gql`
    query getInstitutionsWithPagination($offset: Int, $limit: Int, $filter: FilterInput, $sort: SortInput){
        getInstitutionsWithPagination(offset: $offset, limit: $limit, filter: $filter, sort: $sort) {
            id
            name
            isInternational
            isCa
            isDo
        }
    }
`

export const getInstitutionsWithPaginationCountQuery = gql`
    query getInstitutionsWithPaginationCount($filter: FilterInput) {
        getInstitutionsWithPaginationCount(filter: $filter) 
    }
`

export const getProgramsWithPaginationQuery = gql`
    query getProgramsWithPagination($offset: Int, $limit: Int, $filter: FilterInput, $sort: SortInput){
        getProgramsWithPagination(offset: $offset, limit: $limit, filter: $filter, sort: $sort) {
            id
            name
            description
            isRubricLocked
            url
            specialtyId {
                id
                name
            }
            formalName
            ACGME_ID
            sql_id
            rubricTemplate {
                label
                inputType
                isPreInterview
                maxScale
                lowestScaleText
                highestScaleText
                dropdownOptions {
                    option
                    score
                }
                text
                order
                criteria {
                    name
                    description
                    weight
                    maxScore
                }
            }
            screenedData {
                sections {
                    personalInfo
                    residencyApplication
                    personalStatement
                    documents
                    lors
                }
                personalInfo {
                    profilePicture
                    firstName
                    lastName
                    email
                    phoneNumber
                    genderIdentity
                    nrmpId
                    aamcId
                    hometown
                    raceEthnicity
                    homeAddress
                    workAuthorization
                    preventResponsibilities
                    elaborateResponsibilities
                    misdemeanorOrFelony
                    misdemeanorOrFelonyDetails
                    couplesMatch
                    partnerField
                    previouslyApplied
                    malpracticeLawsuit
                    elaborateMalpractice
                    previousLastName
                    prefferedName
                    pronouns
                    usmleId
                    nbomeId
                    aoaId
                    permanentAddress
                    licensureRevoked
                    militaryObligation
                    otherServiceObligations
                    medicalTrainingInterrupted
                    sigmaPhi
                    dateOfBirth
                }
                residencyApplication {
                    medicalSchoolType
                    medicalSchoolName
                    fluencyInLanguagesBesidesEnglish
                    otherLanguagesFluentIn
                    otherDegreesInstitutionsDates
                    aoaStatus
                    ghhsStatus
                    medicalSchoolAwardsHonors
                    coreClerkshipsGraded
                    specifyClerkshipDetails
                    clerkshipGrades
                    awayRotations
                    nonCoreClerkshipGradedCourses
                    impactfulResearchPublications
                    additionalResearchBibliography
                    firstAuthorPublications
                    totalPeerReviewedPublications
                    posterPresentations
                    podiumPresentations
                    meaningfulExperiences
                    significantWorkExperiences
                    shortQuestions
                    hobbiesInterests
                  }
            }
        }
    }
`

export const getProgramsWithPaginationCountQuery = gql`
query getProgramsWithPaginationCount($filter: FilterInput) {
    getProgramsWithPaginationCount(filter: $filter) 
}
`

export const getApplicationsWithPaginationQuery = gql`
    query getApplicationsWithPaginationAdmin($offset: Int, $limit: Int, $filter: FilterInput, $sort: SortInput){
        getApplicationsWithPaginationAdmin(offset: $offset, limit: $limit, filter: $filter, sort: $sort) {
            id
            createdAt
            userId {
                id
                firstName
                lastName
            }
            programId {
                id
                name
            }
            masterApplicationId {
                id
                paymentStatus
            }
            specialtyId {
                name
            }
        }
    }
`

export const getApplicationsWithPaginationCountQuery = gql`
    query getApplicationsWithPaginationCount($filter: FilterInput) {
        getApplicationsWithPaginationCount(filter: $filter) 
    }
`

export const getLorsWithPaginationQuery = gql`
    query getLorsWithPagination ($offset: Int, $limit: Int, $filter: FilterInput, $sort: SortInput){
        getLorsWithPagination(offset: $offset, limit: $limit, filter: $filter, sort: $sort) {
            id
            applicationId
            applicantId {
                id
                firstName
                lastName
            }
            recommenderId {
                id
                firstName
                lastName
            }
            recommenderEmail
            recommenderName
            stage
            docUrl
            requestedAt
            submittedAt
            isSlor
        }
    }   
`


export const getLorsWithPaginationCountQuery = gql`
    query getLorsWithPaginationCount($filter: FilterInput) {
        getLorsWithPaginationCount(filter: $filter)
    }
`

export const GET_SCORE_BY_APPLICANTID = gql`
query GetScoreByApplicantId($applicantId: ID!) {
    getScoreByApplicantId(applicantId: $applicantId) {
            id
            applicantId
            uploaderId
            stage
            usmleStep1Score
            comlexLevel1Score
            usmleStep2Score
            usmleStep3Score
            comlexLevel2Score
            previousUsmleComlexFails
            usmleComlexDescription
            usmleDocs
            comlexDocs
            scoresVerified
            requestedAt
            submittedAt
            usmleScoresVerified
            comlexScoresVerified
            medicalSchoolEmail
            mspeReqEmailAdd
        }
}`



export const getAllProgramsQuery = gql`
query getPrograms {
    getPrograms {
        id
        name
        specialtyId
        specialtyDetails {
            _id
            name
        }
    }
}
`

export const getProgramMembersByProgramIdQuery = gql`
    query getProgramMembersByProgramId($programId: ID!) {
        getProgramMembersByProgramId(programId: $programId){
            userId {
                id
                firstName
                lastName
                email
                isPending
              }
              roles
        }
    }
`

export const getRubricsAnsweredQuery = gql`
    query getRubricsAnswered($programId: ID!) {
        getRubricsAnswered(programId: $programId)
    }
`

export const GetSpecialtiesQuery = gql`
    query getSpecialties {
        getSpecialties {
            id
            name
        }
    }
`


export const getProgramByMemberIdQuery = gql`
query getProgramByMemberId($userId: ID!) {
    getProgramByMemberId(userId: $userId) {
      id
    }
}
`