import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {useQuery} from '@apollo/client';
import { getAllProgramsQuery } from './graphql/queries';
import logo from '../../assets/imgs/logo.svg';
import backIcon from '../../assets/imgs/backIcon.svg'; // Import the backIcon image
import Spinner from '../common/spinner';
import './index.css';
import ProgramsListTable from "../applicant/applicantComponents/ProgramsListTable";

const ParticipatingProgarms = () => {
const [programsData, setProgramsData] = useState([]);

const { data, loading, error } = useQuery(getAllProgramsQuery);

const navigate = useNavigate();

const columns = [
    {
        Header: 'Program Name',
        accessor: 'programName',
    },
    {
        Header: 'Specialty',
        accessor: 'specialtyName',
    },
    {
        Header: 'Learn More',
        accessor: 'learnMore',
        Cell: ({ cell: { value } }) => (
          <a href={value} target="_blank" rel="noopener noreferrer" style={{color:'#145388',float:'right',marginRight:'6vw'}}>
            Learn More
          </a>
        ),
      },
]

// clean up the data
useEffect(() => {
    if (data && data.getPrograms) {
        const cleanedData = data.getPrograms
        .map(program => ({
          programName: program.name,
          specialtyName: program.specialtyDetails.name,
          learnMore: program.url,
        }))
        .filter(program => program.programName !== 'Admin program' && program.programName !== 'Recommenders program');
        console.log(cleanedData);
        // remove from the data "Admin program" and "Recommenders program"
       
        setProgramsData(cleanedData);
    }
}, [data]);



    
    if (loading) return <Spinner />;

    return (
        <div className='faculty-home-container' style={{width:'100%',marginLeft:'auto',marginRight:'auto', paddingLeft:'2vw'}}>
            <ellipse style={{ position: 'absolute', width: '134px', height: '126px', left: '1319px', top: '-17px', background: '#2a93d5', filter: 'blur(118.5px)' }} />
            <div className="top-faq">
                <p className='back-title' onClick={() => navigate('/')}><img src={backIcon} alt="back" style={{ marginRight: '0.8vw'}}/> Back</p>
                <img src={logo} alt="logo" style={{float:'left', marginRight: '2vw'}}/>
            </div>
            <p className='header-title'>Participating Programs</p>
            <ProgramsListTable columns={columns} data={programsData} />

            
        </div>
    );
}

export default ParticipatingProgarms;
