import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import logo from '../../../../assets/imgs/logo.svg';
import { useQuery } from "@apollo/client"; 
import {useDropzone} from 'react-dropzone';
import Spinner from "../../../common/spinner";
import packageInfo from '../../../../../package.json';

const UploadDocs = ({masterAppId}) => {
    const [uploadStatus, setUploadStatus] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [applicantFirstName, setApplicantFirstName] = useState('');
    const [applicantLastName, setApplicantLastName] = useState('');


    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop: (acceptedFiles) => {
            console.log("acceptedFiles", acceptedFiles)

            const file = acceptedFiles[0];
            console.log("file =>", file)
            if(file) {
                setSelectedFile(file);
            }
        },
        accept: 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document' ,
        multiple: false  // This ensures that multiple file selection is disabled

    });





const handleUpload = async () => {
    const file = selectedFile
    
    if(file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('masterAppId', masterAppId);

        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/upload_cv_file`, {
          headers: { 'App-Version': packageInfo.version },
          method: 'POST',
          body: formData
        });
        if(response.ok) {
            const data = await response.json(); // Only call .json() here if response is ok
            setUploadStatus('Success'); // Set upload status to success
            // Optionally, use data for further success handling
        } else {
            setUploadStatus('Error'); // Set upload status to error
        }
    }
}



   return (
    <div  style={{width:'100%',marginLeft:'auto',marginRight:'auto'}}>
        <p className="specific-rec-title" style={{textAlign:'center'}}>Here you can upload your CV file, please only upload .PDF or .Docx files </p>
        {isLoading && <Spinner />}
        {!isLoading && (
            <>
        <div className="faculty-home-header">
            
            {/* <p className="faculty-home-header-subtitle"> Please fill in you details below to finalize your account creation</p> */}
        </div>
        
        <div className="faculty-home-body">

        <div className="faculty-home-body">
        {selectedFile && 
  <div>
  {/* Check if the file is an image to display it in an img tag */}
  {selectedFile.type.startsWith('image/') && (
      <img src={URL.createObjectURL(selectedFile)} alt="Preview" style={{maxWidth: '100%', maxHeight: '400px'}} />
  )}
  {/* Check if the file is a PDF to display it in an iframe */}
  {selectedFile.type === 'application/pdf' && (
      <iframe src={URL.createObjectURL(selectedFile)} style={{width: '100%', height: '500px'}} frameBorder="0"></iframe>
  )}
  {/* Optionally, handle other file types here */}
  <p>File Name: {selectedFile.name}</p>
  <p>File Type: {selectedFile.type}</p>
  <p>File Size: {(selectedFile.size / (1024*1024)).toFixed(2)} MB</p>
</div>
            }


            {uploadStatus===''&&(
                <>
{!selectedFile&&
            <div className={ isDragActive ?'file-drop-zone hover':"file-drop-zone"} {...getRootProps()}>
            <input {...getInputProps()}  multiple={false}/>
                {
                    isDragActive ?
                    <p>Drop to upload the file</p> :
                    <p>Drag & drop your CV file here, or click to select files</p>
                }
            </div>
}
                <div className="specific-rec-submit" style={{gap:'1vh', display:'flex'}}>
                    {selectedFile&&
                    <button className='green-button' onClick={()=>setSelectedFile(null)} style={{padding:'1vh 1vw', width:'10vw'}}>Remove</button>
                }
                    <button className='green-button' disabled={!selectedFile} onClick={handleUpload} style={{padding:'1vh 1vw', width:'10vw'}}>Upload CV</button>
                </div>
                </>
            )}
        </div>
        


        {
    uploadStatus === 'Success' && <div className="upload-success">CV was uploaded successfully!</div>
}
{
    uploadStatus === 'Error' && <div className="upload-error">Failed to upload file.</div>
}
        </div>
        </>
)}
        
    </div>
   )




}


export default UploadDocs;