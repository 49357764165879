import { gql } from "@apollo/client";

export const updateApplicationRubricsMutation = gql`
    mutation updateApplicationRubrics($reviewerId: ID!, $applicationId: ID!, $rubrics: [RubricInput], $rubricsResult: RubricResultInput, $postRubricsResult: RubricResultInput) {
        updateApplicationRubrics(reviewerId: $reviewerId , applicationId: $applicationId, rubrics: $rubrics, rubricsResult:$rubricsResult, postRubricsResult: $postRubricsResult) {
            id
        }
    }

`
