import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
// import '../styles/step7.css';
import ApplicationFormStep1 from './step1';
import ApplicationFormStep2 from './step2';
import ApplicationFormStep3 from './step3';
import ApplicationFormStep4 from './step4';
import ApplicationFormStep5 from './step5';
import ApplicationFormStep6 from './step6Modified';
import { FormContext } from '../ApplicationForm';
import { useQuery, useMutation } from '@apollo/client';
import {getLegalDisclaimers} from '../graphql/queries';
import { createApplicationMutation, createMultipleApplicationsMutation } from '../graphql/mutations';
import client from '../../../../config/apolloClient';
import Spinner from '../../../common/spinner';
import ResponseModal from '../../../common/resModal';






const ApplicationFormStep7 = ({ applicant, applicationData, setPage, setIsApplicationOpen, updateApplicationData, updateUserData, isSubmitting, score, lorIdsToAdd, updateSelectedPrograms, selectedProgramsData, updatePhase,phases, setSelectedLors, specialty }) => {
    const { setStep } = useContext(FormContext);
    const [legalDisclaimers, setLegalDisclaimers] = useState(null);
    const [isConfirmation, setIsConfirmation] = useState(false);
    const [savingError, setSavingError] = useState('');
    const { loading, error, data } = useQuery(getLegalDisclaimers, {
        variables: { programId: applicationData.positionId.programId }
    });

    useEffect(() => {
        if (!loading && !error && data) {
            setLegalDisclaimers(data.getLegalDisclaimers);
        }
    }, [loading, error, data]);

    
    


const createApplication = async (applications) => {

    client.mutate({
        mutation: createMultipleApplicationsMutation,
        variables: {
            applications: JSON.stringify(applications)
        }
    })
    .then(res => {
        if (res.data.createMultipleApplications) {
        setIsConfirmation(true)
        }else{
            setIsConfirmation(true)
            setSavingError('There was an error submitting your application. Please try again later.')
        }
    })
    .catch(err => {
        setIsConfirmation(true)
        setSavingError(err.message)

    })
}

const handleCreateApplication = () => {

        const applications = []

        applications.push({
            userId: applicant.id,
            programId: applicationData.positionId.programId,
            positionId: applicationData.positionId.id,
            masterApplicationId: applicationData.id,
        })

        createApplication(applications)
}

if (loading) return <Spinner />;

    

    const checkFinishAll = phases.firstPhase && phases.secondPhase && phases.thirdPhase && phases.fourthPhase && phases.fifthPhase 
    if(!checkFinishAll) {return (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '95%', fontFamily: 'Montserrat'}}>
            <label>You must complete the following tasks before your application can be submitted</label>
            {!phases.firstPhase && <div style={{ color: '#145388', fontSize: '15px', cursor: 'pointer'}} onClick={() => setStep(1)}>Personal Information</div>}
            {!phases.secondPhase && <div style={{ color: '#145388', fontSize: '15px', cursor: 'pointer'}} onClick={() => setStep(2)}>Professional Information</div>}
            {!phases.thirdPhase && <div style={{ color: '#145388', fontSize: '15px', cursor: 'pointer'}} onClick={() => setStep(3)}>Cover Letter</div>}
            {!phases.fourthPhase && <div style={{ color: '#145388', fontSize: '15px', cursor: 'pointer'}} onClick={() => setStep(4)}>CV</div>}
            {!phases.fifthPhase && <div style={{ color: '#145388', fontSize: '15px', cursor: 'pointer'}} onClick={() => setStep(5)}>References</div>}
        </div>
    )
     } else {

        return (
<div>
 {isConfirmation&&<ResponseModal isOpen={isConfirmation} onClose={() => setIsConfirmation(false)} body={savingError===''?'Your application has been submitted successfully':savingError} />}
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '95%', fontFamily: 'Montserrat'}}>
    {!loading && legalDisclaimers && (
                <>
                    <p>{legalDisclaimers.defaultLegalDisclaimers}</p>
                    <p>{legalDisclaimers.defaultEqualEmploymentOpportunityStatement}</p>
                </>
            )}
        <button className='green-button' style={{alignSelf:'center'}} onClick={handleCreateApplication}>Confirm and Send Application</button>
        </div>    
</div>
    )}
}

export default ApplicationFormStep7;