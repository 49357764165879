import React,{useState, useContext} from "react";
import "./documents.css"
import Card from "./generalCard";
import ScoresCard from "./scoresCard";



const Documents = () => {
    const scoreValidationSubtitle= `Please enter your standardized exam scores. These scores will be verified by your medical school's student affairs office at the time of MSPE/Dean's Letter upload. This verification system helps us reduce cost by eliminating the additional NBME fee. Your MSPE will be released September 27th on the AAMC timeline.`
    const [isScore, setIsScore] = useState(false);
    const [isTranscript, setIsTranscript] = useState(false);
    const [isMSPE, setIsMSPE] = useState(false);

    



    return (
        <div className='applicant-home-container'>
            <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop2' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="applicant-home-header">
                <h2 className="applicant-home-header-title">DOCUMENTS</h2>
                <p className="applicant-home-header-subtitle"> On this page, you will see the status of your Documents. Your MSPE and Transcript requests were sent to your medical school automatically at the time of Token administration.</p>
                 {/* <br /> */}
                
            </div>
            <seperator className="applicant-home-header-seperator" style={{marginBottom:'0'}}/>
            <div className="documents-body">
                <div style={{display:'flex', gap:'2vw'}}>
            <Card header='Transcript Submission' cardInfo='transcript' setIsMSPE={setIsMSPE} setIsTranscript={setIsTranscript}/>
            <Card header='MSPE Submission' cardInfo='mspe' setIsMSPE={setIsMSPE} setIsTranscript={setIsTranscript}/>
            {/* <Card header='Score Verification Request' body='scoreValidation'/> */}
            </div>
            <ScoresCard setIsScore={setIsScore} isNested={false}/>
            </div>

        </div>
    );
}

export default Documents;

