import React, { useState, useEffect,useContext } from 'react';
import './index.css'; // Assuming styles are in Home.css
import LeftNavBar from './facultyComponents/LeftNavBar';
import FacultyHome from './facultyComponents/home';
import WorkFlows from './facultyComponents/workFlows';
import Rubric from './facultyComponents/rubric';
import CustomizeApplication from './facultyComponents/customizeApplication';
import MyTeam from './facultyComponents/myTeam';
import Recommendations from './facultyComponents/recommendations';
import Settings from './facultyComponents/settings';
import AccountAccess from './facultyComponents/accountAccess';
import ManageReviews from './facultyComponents/manageReviews';
import InterviewManager from './facultyComponents/interviewManager';
import Positions from './facultyComponents/positions';
import Applications from './facultyComponents/applications';
import Groups from './facultyComponents/groups';
import CompletedReviews from './facultyComponents/completedReviews';
import Tutorials from './facultyComponents/tutorials';
import AdminUsers from './facultyComponents/adminPortal/users';
import AdminSpecialties from './facultyComponents/adminPortal/specialties';
import AdminPrograms from './facultyComponents/adminPortal/programs';
import AdminMasterApplications from './facultyComponents/adminPortal/applications';
import AdminLors from './facultyComponents/adminPortal/lors';
import AdminDocuments from './facultyComponents/adminPortal/documents';
import AdminAnalytics from './facultyComponents/adminPortal/analytics';
import AdminInstitutions from './facultyComponents/adminPortal/institutions';
import AdminErasUpload from './facultyComponents/adminPortal/erasUpload';
import ContactUs from '../contactUs';
import { useQuery } from '@apollo/client';
import { getProgramById } from './facultyComponents/graphql/queries';
import { getUserById } from './facultyComponents/settings/graphql/queries';
import { ProgramIdContext } from './progIdContext';
import { UserContext } from '../../context/userContext';
import Spinner from '../common/spinner';
import ProgramSettings from './facultyComponents/settings/programSettings';
import Communication from './facultyComponents/emailTemplate';


    

const calculatePreSeason = (programId) => {
  if (process.env.REACT_APP_ENV === 'production') {
      const currentDate = new Date();
      const preSeasonDate = new Date('2024-09-16');
      if (currentDate < preSeasonDate) {
          return(true);
      }else{
          return(false);
      }
    }else{
      return(false);
    }
  }


function Faculty() {
    const { user } = useContext(UserContext);
    const programId = user.programId;
    const [page, setPage] = useState('home');
    const [userName, setUserName] = useState('');
    const [isPreSeason, setIsPreSeason] = useState(calculatePreSeason(programId));
    const [isApplicationsClicked, setIsApplicationsClicked] = useState(false);
    

    console.log(isPreSeason, "isPreSeason")
    
    console.log("programId =>", programId, user, page)

    const { loading, error, data, refetch } = useQuery(getProgramById, {
        variables: { id: programId },
    });

    if (loading) return <Spinner />;
    if (error) return <p>Error :(</p>;
    


  return (
    <ProgramIdContext.Provider value={programId}>
    {/* <main className="container mx-auto px-6 py-4" style={{paddingTop:0, paddingBottom:'0', paddingRight:'0', margin:0, padding:0}}> */}
    <main  style={{paddingTop:0, paddingBottom:'0', paddingRight:'0', margin:0, paddingLeft:'4vw'}}>
        <LeftNavBar userName={userName} userRoles={user.userPremissions} page={page} setPage={setPage} isPreSeason={isPreSeason} setIsApplicationsClicked={setIsApplicationsClicked}/>
        {page==='home'&&(<FacultyHome  setPage={setPage}/>)}
        {page==='rubric'&&(<Rubric programRubricsOriginal={data.getProgramById.rubricTemplate} isRubricLocked={data.getProgramById.isRubricLocked} refetchProgram={refetch}/>)}
        {page==='customizeApplication'&&(<CustomizeApplication  screenedData={data.getProgramById.screenedData}/>)}
        {page==='myTeam'&&(<MyTeam />)}
        {page==='recommendations'&&(<Recommendations />)}
        {page==='contactUs'&&(<ContactUs isNested={true} user={user} programData={data}/>)}
        {page==='settings'&&(<Settings setPage={setPage}/>)}
        {page==='programSettings'&&(<ProgramSettings setPage={setPage}/>)}
        {page==='accountAccess'&&(<AccountAccess setPage={setPage}/>)}
        {page==='manageReviews'&&(<ManageReviews isRubricLocked={data.getProgramById.isRubricLocked} setPage={setPage} />)}
        {page==='applications'&&(<Applications isApplicationsClicked={isApplicationsClicked} setIsApplicationsClicked={setIsApplicationsClicked} refetchProgram={refetch}/>)}
        {page==='groups'&&(<Groups  isApplicationsClicked={isApplicationsClicked} setIsApplicationsClicked={setIsApplicationsClicked} refetchProgram={refetch}/>)}
        {page==='completedReviews'&&(<CompletedReviews />)}
        {page==='tutorials'&&(<Tutorials />)}
        {page==='adminUsers'&&(<AdminUsers />)}
        {page==='adminSpecialties'&&(<AdminSpecialties />)}
        {page==='adminPrograms'&&(<AdminPrograms />)}
        {page==='adminMasterApplications'&&(<AdminMasterApplications />)}
        {page==='adminLors'&&(<AdminLors />)}
        {page==='adminDocuments'&&(<AdminDocuments />)}
        {page==='adminInstitutions'&&(<AdminInstitutions />)}
        {page==='adminAnalytics'&&(<AdminAnalytics />)}
        {page==='adminEras'&&(<AdminErasUpload />)}
        {page==='interviewManager'&&(<InterviewManager/>)}
        {page==='workFlows'&&(<WorkFlows refetch={refetch}/>)}
        {page==='positions'&&(<Positions refetch={refetch}/>)}
        {page==='emailTemplates'&&(<Communication refetch={refetch}/>)}

    </main>
    </ProgramIdContext.Provider>
  );
}

export default Faculty;