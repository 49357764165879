import { gql } from '@apollo/client';


export const revokeUserMutation = gql`
    mutation revokeUser($userId: ID, $revokedUserId: ID) {
        revokeUser(userId: $userId, revokedUserId: $revokedUserId){
            id
        }
    }
`

export const addUserToAccountAccessMutation = gql`
    mutation addUserToAccountAccess($userId: ID, $inviteUserId: ID) {
        addUserToAccountAccess(userId: $userId, inviteUserId: $inviteUserId){
            id
        }
    }
`

export const updateUserMutation = gql`
    mutation updateUser($id: ID!, $input: UpdateUserInput! ){
        updateUser(id: $id, input: $input) {
            id
        }
    }
`

export const updateUserPasswordMutation = gql`
    mutation updateUserPassword($id: ID!, $previousPass: String!, $newPass: String!) {
        updateUserPassword(id: $id, previousPass: $previousPass, newPass: $newPass) {
            id
        }
    }
`

export const createMasterApplicationMutation = gql`
    mutation createMasterApplication($input: CreateMasterApplicationInput!){
        createMasterApplication(input: $input) {
            id
        }
    }
`

export const updateMasterApplicationMutation = gql`
    mutation updateMasterApplication($id: ID!, $input: UpdateMasterApplicationInput!) {
        updateMasterApplication(id: $id, input: $input) {
            id
        }
    }
`

export const updateScoreMutation = gql`
    mutation updateScore($id: ID!, $input: ScoreInput!) {
        updateScore(id: $id, input: $input) {
            id
            applicantId
            uploaderId
            stage
            usmleStep1Score
            comlexLevel1Score
            usmleStep2Score
            usmleStep3Score
            comlexLevel2Score
            previousUsmleComlexFails
            usmleComlexDescription
            usmleDocs
            comlexDocs
            scoresVerified
            requestedAt
            submittedAt
            usmleScoresVerified
            comlexScoresVerified
            medicalSchoolEmail
            mspeReqEmailAdd
        }
    }

`

export const createScoreMutation = gql`
    mutation createScore($input: ScoreInput!) {
        createScore(input: $input) {
            id
            applicantId
            uploaderId
            stage
            usmleStep1Score
            comlexLevel1Score
            usmleStep2Score
            usmleStep3Score
            comlexLevel2Score
            previousUsmleComlexFails
            usmleComlexDescription
            usmleDocs
            comlexDocs
            scoresVerified
            requestedAt
            submittedAt
            usmleScoresVerified
            comlexScoresVerified
            medicalSchoolEmail
            mspeReqEmailAdd
        }
    }
`


export const createLorMutation = gql`
    mutation createLor($input: CreateLorInput!){
        createLor(input: $input) {
            id
            applicantId
            recommenderId
            stage
            docUrl
            requestedAt
            submittedAt
        }
    }
`

export const createApplicationMutation = gql`
    mutation createApplication($input: CreateApplicationInput!){
        createApplication(input: $input) {
            id
        }
    }
`

export const deleteLorMutation = gql`
    mutation deleteLor($id: ID!) {
        deleteLor(id: $id) {
            id
        }
    }

`