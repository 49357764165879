import React, { useState, useContext, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage,FieldArray } from 'formik';
import Select from 'react-select';
import packageInfo from '../../../../package.json';

import ReccomendersListTable from './reccomendersTable';
import LORModal from './lorModal';
import { createLorMutation, deleteLorMutation, sendLoRReminderMutation } from './graphql/mutations';
import { getAllLorsByUserIdQuery, getRecommenders} from './graphql/queries';
import {useQuery, useMutation} from '@apollo/client';
import { UserContext } from '../../../context/userContext';
import Spinner from "../../common/spinner";
import './styles/lorModal.css'


const exampleReccomenders = [
    {name:'Dr. John Doe', institution:'University of California, San Francisco', program:'Neurology', email:'jDoe@ucsf.edu'},
    {name:'Dr. Jane Smith', institution:'Harvard University', program:'Cardiology', email:'jSmith@harvard.edu'},
    {name:'Dr. Robert Johnson', institution:'Stanford University', program:'Oncology', email:'rJohnson@stanford.edu'},
    {name:'Dr. Laura Davis', institution:'Yale University', program:'Pediatrics', email:'lDavis@yale.edu'},
    {name:'Dr. Michael Miller', institution:'Princeton University', program:'Psychiatry', email:'mMiller@princeton.edu'},
    {name:'Dr. Emily Garcia', institution:'Columbia University', program:'Dermatology', email:'eGarcia@columbia.edu'},
    {name:'Dr. David Martinez', institution:'University of Chicago', program:'Radiology', email:'dMartinez@uchicago.edu'},
    {name:'Dr. Sarah Robinson', institution:'Massachusetts Institute of Technology', program:'Gastroenterology', email:'sRobinson@mit.edu'},
    {name:'Dr. James Clark', institution:'University of Pennsylvania', program:'Orthopedics', email:'jClark@upenn.edu'},
    {name:'Dr. Patricia Rodriguez', institution:'Duke University', program:'Endocrinology', email:'pRodriguez@duke.edu'},
    {name:'Test User', institution:'Duke University', program:'Endocrinology', email:'karin@dualitiia.com'},
]



const Lor = ({}) => {
const { user } = useContext(UserContext);
    
const [reccomenders, setReccomenders] = useState([]);
const [lorModalOpen, setLorModalOpen] = useState(false);
const [lors, setLors] = useState([])
const [isLoading, setIsLoading] = useState(false);
const [lorError, setLorError] = useState('');
const [lorSent, setLorSent] = useState(false);
const [lorDeleted, setLorDeleted] = useState(false);
const [deleteLor, { deleteLorData }] = useMutation(deleteLorMutation);
const [createLor, { createLorData }] = useMutation(createLorMutation);
const [sendLoRReminder, { sendLoRReminderData }] = useMutation(sendLoRReminderMutation);


const columns = [
    {
        Header: 'Recommender Name',
        accessor: 'recommenderName',
    },
    {
        Header: 'Recommender Email',
        accessor: 'recommenderEmail',
    },
    {
        Header:'Status',
        accessor:'stage',
    },
    {
        Header: '',
        accessor: 'action',
        Cell: ({ cell: { value }, row: { original } }) => {
            const [isOpen, setIsOpen] = React.useState(false);

            const sendReminder = (original) => {
                console.log("original =>", original)
                fetch(`${process.env.REACT_APP_SERVER_URL}/send_reminder_to_recommender`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'App-Version': packageInfo.version

                    },
                    body: JSON.stringify({
                        lorId: original.id,
                        email: original.recommenderEmail,
                        name: original.recommenderName
                    })
                })
               



                toggleOpen()
            }

            const cancelLor = (original) => {
                setIsLoading(true);
                deleteLor({
                    variables: {
                        id: original.id
                    }
                })
                .then(res => {
                    setLorDeleted(true)
                    setTimeout(() => {
                        setLorDeleted(false)
                    }, 3000);
                    refetch();
                    toggleOpen();
                    setIsLoading(false);
                })
            
              
            }
    
            const toggleOpen = () => {
                setIsOpen(!isOpen);
            };

            const handleSendReminder = (original) => {
                sendLoRReminder({
                    variables: {
                        id: original.id
                    }
                })
                .then(res => {
                    setLorSent(true)
                    setTimeout(() => {
                        setLorSent(false)
                    }, 3000);
                })
                toggleOpen();
            }

    
            return (
                <div className="dropdown-point">
                    <div className='toggle-points' onClick={toggleOpen}>
                        <span className='point'/>
                        <span className='point'/>
                        <span className='point'/>
                    </div>
                    {isOpen && (
                        <div className="dropdown-menu" style={{display:'flex',flexDirection:'column', padding:'10%',}}>
                            <a href="#" onClick={() => handleSendReminder(original)}>Remind</a>
                            <a href="#" onClick={() => cancelLor(original)}>Cancel</a>
                        </div>
                    )}
                </div>
            );
        }
    }
]


const sendRequest = (recommender) => {
    setIsLoading(true);
    setLorError(''); 
    console.log("recommender =>", recommender)

    createLor({
        variables: {
            input: {
                applicantId: user.userId,
                recommenderEmail: recommender.value.email,
                recommenderFirstName: recommender.value.name.split(' ')[0],
                recommenderLastName: recommender.value.name.split(' ')[1],
                isSlor: recommender.value.isSlor,
                
            }
        }
    }).then(res => {
        // Handle success
        refetch();
        setLorModalOpen(false);
        setIsLoading(false);
    }).catch(error => {
        // Handle error
        setLorError(error.message || 'An error occurred while creating the letter of recommendation.');
        setIsLoading(false);
    });

}

const { loading: loadingAllRecommenders, error: errorAllReccomenders, data: dataAllRecommenders } = useQuery(getRecommenders);

useEffect(() => {
    if (!loadingAllRecommenders && !errorAllReccomenders && dataAllRecommenders) {
        console.log(dataAllRecommenders, 'res');
// clean this up
        let dataToSet = dataAllRecommenders.getRecommenders.map((recommender) => {
            return {
                name: recommender.firstName + ' ' + recommender.lastName,
                email: recommender.email,
                institution: recommender.institution
            }
        }
        )

        setReccomenders(dataToSet);
    }
}, [loadingAllRecommenders, errorAllReccomenders, dataAllRecommenders]);



const { loading, error, data, refetch } = useQuery(getAllLorsByUserIdQuery, {
    variables: {
        applicantId: user.userId
    }
});

useEffect(() => {
    if (!loading && !error && data) {
        console.log("get lors =>", data)
        setLors(data.getAllLorsByUserId);
    }
}, [loading, error, data]);


useEffect(() => {
    console.log(reccomenders)
}
, [reccomenders])



return (
    <div className='lor-container' style={{padding:'2.78vw', paddingLeft:'17vw'}}>
                    <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop2' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="applicant-home-header">
                <h2 className="applicant-home-header-title">Letters of Recommendation</h2>
                <p className="applicant-home-header-subtitle" style={{textAlign:'justify'}}> On this page, you will request any letters of recommendation to be associated with your application(s). You will choose which of these letters to use within the application itself. 

If you are applying to Plastic Surgery, please select the Plastic Surgery Standardized Letter of Recommendation (SLOR) form to be sent to your recommender. If you are applying to a pilot program, please select 'Standard LOR' </p>
            </div>
            <seperator className="applicant-home-header-seperator"/>
            {isLoading && 
            <div style={{position:'relative', top:'22vh'}}>
            <Spinner />
            </div>
            }
<LORModal isVisible={lorModalOpen} setLorModalOpen={setLorModalOpen} reccomenders={reccomenders} sendRequest={sendRequest} errorMessage={lorError} setLorError={setLorError}/>
<div style={{display:'flex',flexDirection:'column'}}>
</div>

{ lorSent && <div style={{ display: 'flex', justifyContent: 'flex-end', color: "#145388", marginBottom: '2vh'}}><label>Reminder Sent Successfully.</label></div>}
{ lorDeleted && <div style={{ display: 'flex', justifyContent: 'flex-end', color: "#145388", marginBottom: '2vh'}}><label>LOR Cancelled Successfully.</label></div>}

<ReccomendersListTable columns={columns} data={lors} />
<button onClick={()=>{setLorModalOpen(true)}} className='applicant-form-back-button' style={{border: 'none',
    outline: 'none',
    cursor: 'pointer',
    background: '#145388',
    borderRadius: '7px',
    padding: '8px 30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px',
    color:'white',
    fontWeight:'500',
    fontFamily:'Montserrat',
    marginTop:'2vh'}}>New Request</button>
</div>
)}

export default Lor;