// Columns

export const tableColumns = [
    {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
             <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <input type="checkbox" {...row.getToggleRowSelectedProps()} />
          </div>
        )
      },
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'Status',
        accessor: 'totalReviews',
    },
    {
        Header: 'id',
        accessor: 'id',
    },
];

export const summaryTableColumns = [
{
    Header: 'id',
    accessor: 'id',
},
{
    Header: 'Name',
    accessor: 'name',
},
{
    Header: 'Status',
    accessor: 'status',
},
{
    Header:'To be assigned',
    accessor:'toBeAssigned',
}
]


export const groupTableColumns = [
    {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
                <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
            </div>
        ),
        Cell: ({ row }) => (
            <div>
            <input type="checkbox" {...row.getToggleRowSelectedProps()} />
            </div>
        )
        },
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'Number of applications',
        accessor: 'numApplications',
    },
    {
        Header: 'id',
        accessor: 'id',
    },
    {
        Header:'applicationsIds',
        accessor:'applicationsIds'
    }
];

export const applicationsTableColumns = [

{
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
                <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
            </div>
        ),
        Cell: ({ row }) => (
            <div>
            <input type="checkbox" {...row.getToggleRowSelectedProps()} />
            </div>
        )
        },
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header:'id',
        accessor:'id',
    }
]



export const summaryTableData = [
    {
        id: 1,
        name: 'John Doe',
        status: 'Assigned',
        toBeAssigned: 0,
    },
    {
        id: 2,
        name: 'Jane Dow',
        status: 'Assigned',
        toBeAssigned: 0,
    },
    {
        id: 3,
        name: 'John Smith',
        status: 'Assigned',
        toBeAssigned: 0,
    },
    {
        id: 4,
        name: 'Jane Smith',
        status: 'Assigned',
        toBeAssigned: 0,
    },

]


export const groupTableData = [
    {
        id: 1,
        name: 'Group 1',
        numApplications: 9,
    },
    {
        id: 2,
        name: 'Group 2',
        numApplications: 7,
    },
    {
        id: 3,
        name: 'Group 3',
        numApplications: 5,
    },
    {
        id: 4,
        name: 'Group 4',
        numApplications: 3,
    },
];

export const applicationsTableData = [
    {
        id: 1,
        name: 'John Moe Applicant',
    },
    {
        id: 2,
        name: 'Jane Dow Applicant',
    },
    {
        id: 3,
        name: 'John Smith Applicant',
    },
    {
        id: 4,
        name: 'Jane Smith Applicant',
    },
];





