import React,{useState, useContext} from "react";
import { UserContext } from '../../../context/userContext';
import './index.css';

const Advisors = ({ setPage }) => {
    const { user } = useContext(UserContext);

    return (
        <div className='faculty-home-container'>
                <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="faculty-home-header">
                <h2 className="faculty-home-header-title">Advisors</h2>
            </div>
            <seperator className="faculty-home-header-seperator"/>
            <div className="faculty-home-body"></div>
        </div>
    )
}

export default Advisors;