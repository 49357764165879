import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements, PaymentRequestButtonElement, PaymentElement } from '@stripe/react-stripe-js';
import Spinner from '../../../common/spinner';
import { Formik, Form, Field } from 'formik';
import ApplePayButton from './applePayButton';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import packageInfo from '../../../../../package.json';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const appearance = {
  theme: 'flat',
  variables: { colorPrimaryText: '#262626' },
};

const inputStyles = {
  base: {
    color: '#32325d',
    backgroundColor: '#f1f1f1',
    padding: '1rem',
    fontFamily: 'Arial, sans-serif',
    borderRadius: '10px',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: {
    color: '#fa755a',
  },
};


const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const CheckoutForm = ({applicationData, specialty, continueApplication, refetchApplications, applicant}) => {
  const stripe = useStripe();
  const [elements, setElements] = useState(useElements());
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [stripeEmail, setStripeEmail] = useState('');
  const [stripeName, setStripeName] = useState('');


 

  useEffect(() => {
    const fetchClientSecret = async () => {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/create-payment-intent`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'App-Version': packageInfo.version },
        body: JSON.stringify({
          amount: 100 * parseInt(specialty.applicationRequirements.cost),
          currency: 'usd',
          email: applicant.email,
          userId: applicationData.userId,
        }),
      });
      const data = await response.json();
      setClientSecret(data.clientSecret);
      const elements = stripe.elements({ clientSecret: data.clientSecret, appearance });
      const paymentElement = elements.create('payment', {});
      paymentElement.mount('#payment-element');
      setElements(elements)
      console.log("clientSecret =>", data.clientSecret)
    };
    if(Object.keys(applicationData).length > 0 && specialty) {
      console.log("applicationData =>", applicationData, applicant)
      console.log("specialty =>", specialty)
      fetchClientSecret();}
  }, [applicationData, specialty]);


  const handleSubmit = async (values) => {
    console.log("inside handleSubmit!!!", values)
    // setLoading(true);
    // setSubmitting(true);

    if (!stripe || !elements) {
      // setSubmitting(false);
      console.log("broke!")
      return;
    }

    console.log("elements =>", elements)
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: stripeName,
            email: stripeEmail,
          },
        },
      },
      redirect: 'if_required',
    });

    if (error) {
      setError(error.message);
      // setSubmitting(false);
      setLoading(false);
    } else {
      setSuccess(true);

      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/create-payment-success`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' , 'App-Version': packageInfo.version  },
        body: JSON.stringify({
          userId: applicationData.userId,
          name: stripeName,
          email: stripeEmail,
          applicationId: applicationData.id, 
          specialtyId: specialty.id,
          amount: 100 * parseInt(specialty.applicationRequirements.cost)
        }),
      });


      refetchApplications();
      setTimeout(() => {
        continueApplication();
      }, 2000);
      setError(null);
      // setSubmitting(false);
      setLoading(false);
    }
  }

  


  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Demo total',
          amount: 1000,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then((result) => {
        console.log("canMakePayment result:", result);
        if (result && result.applePay) {
          setPaymentRequest(pr);
        } else {
          console.log("Apple Pay is not available or not configured correctly.");
        }
      }).catch(error => {
        console.error("canMakePayment error:", error);
      });

      pr.on('paymentmethod', async (ev) => {
        const {error} = await stripe.confirmCardPayment(
          process.env.REACT_APP_STRIPE_SECRET, // Replace with your actual client secret
          {
            payment_method: ev.paymentMethod.id,
          },
          {
            handleActions: false,
          }
        );

        if (error) {
          ev.complete('fail');
        } else {
          ev.complete('success');
          const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment('client_secret_from_backend');
          if (confirmError) {
            console.error(confirmError);
          } else if (paymentIntent.status === 'succeeded') {
            console.log('Payment succeeded');
          }
        }
      });
    }
  }, [stripe]);


const hadnleEmailChange = (e) => {
  console.log("e =>", e)
  setStripeEmail(e.target.value)
}

const handleNameChange = (e) => {
  console.log("e =>", e)
  setStripeName(e.target.value)
}




  return (
    clientSecret && (

      <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>

            <p style={{ marginBottom: '1vh', fontFamily: 'Montserrat' }}>Residency Application For {specialty ? specialty.name : ""}</p>
            <p style={{ marginBottom: '2vh', fontFamily: 'Montserrat' }}>Cost: { specialty ? "$" +  specialty.applicationRequirements.cost  : ""}</p>

         

            <div id="payment-element">
              <paymentElement />
            </div>

            <div style={styles.inputContainer}>
                <label style={{ fontSize: '0.8rem', marginTop: '1vh'}} htmlFor="email">Email</label>
                {/* <Field id="email" name="email" type="email" placeholder="Email" required style={inputStyles.base} /> */}
                <input type="text" value={stripeEmail} onChange={(e) => hadnleEmailChange(e)} style={inputStyles.base}/>
            </div>
            <div style={styles.inputContainer}>
                <label style={{ fontSize: '0.8rem', marginTop: '0.7vh'}} htmlFor="name">Name on card</label>
                {/* <Field id="name" name="name" type="name" placeholder="Name" required style={inputStyles.base} /> */}
                <input type="text" value={stripeName} onChange={(e) => handleNameChange(e)} style={inputStyles.base}/>
            </div>


            {loading && <Spinner />}
            {!loading && !success &&
              <button onClick={handleSubmit} 
              disabled={!stripe || !stripeEmail || !stripeName} 
              style={!stripe || !stripeEmail || !stripeName ? { ...styles.button, ...styles.buttonDisabled } : styles.button}>
                Pay {specialty ? "$" + specialty.applicationRequirements.cost : ""}
              </button>
            }
            {error && <div style={styles.error}>{error}</div>}
            {success && <div style={styles.success}>Payment Successful!</div>}

    </Elements>
    )
  );
};

const styles = {
  form: {
    width: '400px',
    padding: '20px',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    backgroundColor: '#f9f9f9',
    marginTop: '2vh',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2vh',
  },
  button: {
    marginTop: '20px',
    padding: '10px',
    width: '100%',
    backgroundColor: '#145388',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px',
    cursor: 'pointer',
  },
  buttonDisabled: {
    marginTop: '20px',
    padding: '10px',
    width: '100%',
    backgroundColor: 'gray',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px',
    cursor: 'not-allowed',
  },
  error: {
    color: 'red',
    marginTop: '10px',
  },
  success: {
    color: 'green',
    marginTop: '10px',
  },
};

export default CheckoutForm;
