import React, {useState} from 'react';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import magGlass from '../../../../assets/imgs/magGlass.svg';

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) {
    const count = preGlobalFilteredRows.length;
  
    return (
      <span className='rt-search-bar'>
        <img src={magGlass} alt='magnifying glass' />
        <input
        style={{width:'30vw',height:'4.7vh',alignSelf:'center'}}
          value={globalFilter || ''}
          onChange={e => {
            setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
          }}
          placeholder={`Search ${count} records...`}
        />
      </span>
    );
  }


const RecommendationsTable = ({columns, data}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        state,
      } = useTable({ columns, data },useGlobalFilter, useSortBy);
    

      return (
          <table className='table-programs-list' {...getTableProps()}>
          <thead className='tHead-programs-list'>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    column.Header !== 'Learn More' ?
                    <th className='table-header-title' {...column.getHeaderProps(column.getSortByToggleProps())}>
          <span>
            {column.isSorted
              ? column.isSortedDesc
                ? ' 🔽'
                : ' 🔼'
              : ''}
          </span>
          {column.render('Header')}
                    </th>
                    :
                    <th className='table-header-title' style={{textAlign:'right'}} colSpan={headerGroups.reduce((total, hg) => total + hg.headers.length, 0)}>
                    <GlobalFilter
                      preGlobalFilteredRows={preGlobalFilteredRows}
                      globalFilter={state.globalFilter}
                      setGlobalFilter={setGlobalFilter}
                    />

                  
                  </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                const rowStyle = row.values.userName === "Pending Invitation" ? {background: 'gainsboro', fontStyle:'italic', fontWeight:'bold'} : {};
                return (
                  <tr className='programs-list-table-row table-row-height' {...row.getRowProps()} style={rowStyle}>
                      {row.cells.map(cell => (
                          <td className='programs-list-table-row-text' style={{fontSize:'2vh'}} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
      );
  }
  
    
    export default RecommendationsTable;


