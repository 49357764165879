import React, { useState } from 'react';
import logo from '../../../../assets/imgs/logo.svg';
import { useDropzone } from 'react-dropzone';
import Spinner from "../../../common/spinner";
import Papa from 'papaparse';
import * as XLSX from 'xlsx';

const knownFields = [
    'email',
    'firstName',
    'lastName',
    'phone',
    'gender',
    'homeAddress',
    'dateOfBirth',
    'workAuthorization',
    'misdemeanorOrFelony'
];

const knownFieldsDisplay = {
    'email': 'Email',
    'firstName': 'First Name',
    'lastName': 'Last Name',
    'phone': 'Phone',
    'gender': 'Gender',
    'homeAddress': 'Home Address',
    'dateOfBirth': 'Date of Birth',
    'workAuthorization': 'Work Authorization',
    'misdemeanorOrFelony': 'Misdemeanor/Felony'
};

function guessField(header) {
  const sanitized = header.toLowerCase().replace(/[^a-z0-9]/g, '');
  
  if (sanitized.includes('email')) return 'email';
  if (sanitized.includes('firstname')) return 'firstName';
  if (sanitized.includes('lastname')) return 'lastName';
  if (sanitized.includes('phone') || sanitized.includes('mobile') || sanitized.includes('contact')) return 'phone';
  if (sanitized.includes('gender')) return 'gender';
  if (sanitized.includes('address') && !sanitized.includes('email')) return 'homeAddress';
  if (sanitized.includes('birth') || sanitized.includes('dob')) return 'dateOfBirth';
  if (sanitized.includes('workauth')) return 'workAuthorization';
  if (sanitized.includes('misdemeanor') || sanitized.includes('felony')) return 'misdemeanorOrFelony';

  return '';
}

const ImportAppsModal = ({ isOpen, onClose, selectedPosition, programId, refetchApplications }) => {
    const [uploadStatus, setUploadStatus] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [headers, setHeaders] = useState([]);
    const [sampleRows, setSampleRows] = useState([]);
    const [mapping, setMapping] = useState({});
    const [isMappingConfirmed, setIsMappingConfirmed] = useState(false);
    const [isPreviewReady, setIsPreviewReady] = useState(false);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop: (acceptedFiles) => {
            const file = acceptedFiles[0];
            if(file) {
                setSelectedFile(file);
                parsePreview(file);
            }
        },
        accept: '.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        multiple: false
    });

    const parsePreview = (file) => {
      const extension = file.name.split('.').pop().toLowerCase();
      if(extension === 'csv') {
        parsePreviewCSV(file);
      } else if (extension === 'xlsx' || extension === 'xls') {
        parsePreviewExcel(file);
      } else {
        console.error('Unsupported file format for preview');
      }
    };

    const parsePreviewCSV = (file) => {
      Papa.parse(file, {
        header: true,
        preview: 5, // only read 5 rows
        complete: (results) => {
          if(results.errors.length > 0) {
            console.error('Error parsing CSV:', results.errors);
            return;
          }
          const { data, meta } = results;
          const fileHeaders = meta.fields || [];
          setHeaders(fileHeaders);
          setSampleRows(data);

          let initialMapping = {};
          fileHeaders.forEach(h => {
            initialMapping[h] = guessField(h);
          });
          setMapping(initialMapping);
          setIsPreviewReady(true);
        }
      });
    };

    const parsePreviewExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
      
          // Convert sheet to JSON with headers
          const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          const fileHeaders = sheetData[0];
          const rowsData = sheetData.slice(1); // excluding header row
      
          // Take first 5 rows for preview
          const previewRows = rowsData.slice(0,5).map(rowArr => {
            const rowObj = {};
            fileHeaders.forEach((h, i) => {
              rowObj[h] = rowArr[i] !== undefined ? rowArr[i] : null;
            });
            return rowObj;
          });
      
          setHeaders(fileHeaders);
          setSampleRows(previewRows);
      
          let initialMapping = {};
          fileHeaders.forEach(h => {
            initialMapping[h] = guessField(h);
          });
          setMapping(initialMapping);
          setIsPreviewReady(true);
        };
        reader.onerror = (err) => console.error('Error reading Excel file:', err);
        reader.readAsArrayBuffer(file);
      };
      

      const handleMappingChange = (header, value) => {
        setMapping(prev => {
          // Create a new mapping object
          const newMapping = { ...prev };
      
          // Remove the previous mapping of the selected value
          for (const key in newMapping) {
            if (newMapping[key] === value) {
              newMapping[key] = '';
            }
          }
      
          // Set the new mapping for the current header
          newMapping[header] = value;
          return newMapping;
        });
      };
      

    const handleConfirmMapping = () => {
      // User confirms the mapping
      setIsMappingConfirmed(true);
    };

    const handleUpload = async () => {
      if(!selectedFile) return;
      setIsLoading(true);

      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('positionId', selectedPosition);
      formData.append('programId', programId);
      formData.append('mapping', JSON.stringify(mapping));

      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/import_apps`, {
        method: 'POST',
        body: formData
      });
      setIsLoading(false);
      if(response.ok) {
        const data = await response.json();
        setUploadStatus('Success');
        refetchApplications();
      } else {
        setUploadStatus('Error');
      }
    };

  return (
    <div className="modal-backdrop" style={{ zIndex: '25' }}>
      <div className="add-field-modal" style={{ height: '95vh', overflow: 'unset', width:'65vw', maxHeight:'100vh' }}>
        <div className="add-field-modal-header">
          <h2>Import Applications</h2>
          <img src={logo} alt="Logo" className="logo" style={{ width: '4vw' }} />
        </div>
        
        <div className="faculty-home-body" style={{gap:'0'}}>

            {selectedFile && (
              <div style={{marginRight:'auto'}}>
                <p>File Name: {selectedFile.name}</p>
                {/* <p>File Type: {selectedFile.type}</p> */}
                <p>File Size: {(selectedFile.size / (1024*1024)).toFixed(2)} MB</p>
              </div>
            )}

            {uploadStatus === '' && !isPreviewReady && (
              <>
                {!selectedFile &&
                <>
                  <div className={ isDragActive ? 'file-drop-zone hover' : "file-drop-zone"} style={{width:'100%', marginTop:'12vh', height:'40vh'}} {...getRootProps()}>
                    <input {...getInputProps()} multiple={false}/>
                      {
                          isDragActive ?
                          <p>Drop to upload the file</p> :
                          <p>Drag & drop your file here, or click to select files</p>
                      }
                  </div>
                  <div style={{display:'flex', gap:'1vh', justifyContent:'center', marginTop:'15vh'}}>
                  <button className="white-button" style={{width:'15vw', flexBasis:'unset',flexGrow:'unset', gap:'unset'}} onClick={onClose} >
                    Cancel
                  </button>
                </div>
                </>
                }
                {selectedFile && !isPreviewReady && (
                  <p>Parsing file preview, please wait...</p>
                )}
              </>
            )}

            {isPreviewReady && !isMappingConfirmed && (
                <div>
                <h3>Mapping Headers</h3>
                <p>Below are the first 5 rows preview to help you determine the correct field mapping.</p>
                <div style={{overflowY:'scroll', height:'45vh', marginBottom:'2vh', width:'56vw'}}>
                <table style={{width:'100%', borderCollapse:'collapse', marginBottom:'20px'}}>
                  <thead>
                    <tr>
                      <th style={{borderBottom:'1px solid #ccc', padding:'5px'}}>Header</th>
                      <th style={{borderBottom:'1px solid #ccc', padding:'5px'}}>Sample Values (first 5 rows)</th>
                      <th style={{borderBottom:'1px solid #ccc', padding:'5px'}}>Map to Field</th>
                    </tr>
                  </thead>
                  <tbody>
                  {headers.map(header => (
  <tr key={header} style={{ backgroundColor: mapping[header] === '' ? 'lightcoral' : 'transparent' }}>
    <td style={{borderBottom:'1px solid #ccc', padding:'5px'}}>{header}</td>
    <td style={{borderBottom:'1px solid #ccc', padding:'5px'}}>
      <ul style={{listStyle:'none', padding:'0', margin:'0'}}>
        {sampleRows.map((r,i) => (
          <li key={i}>{r[header]}</li>
        ))}
      </ul>
    </td>
    <td style={{borderBottom:'1px solid #ccc', padding:'5px'}}>
      <select
        value={mapping[header]}
        onChange={(e) => handleMappingChange(header, e.target.value)}
      >
        <option value="">-- Select Field --</option>
        {knownFields.map(field => (
          <option key={field} value={field}>{knownFieldsDisplay[field]}</option>
        ))}
        <option value="ignore">Ignore</option>
      </select>
    </td>
  </tr>
))}
                  </tbody>
                </table>
                </div>
                <div style={{display:'flex', gap:'1vh', justifyContent:'center'}}>
                  <button className="white-button" style={{width:'15vw', flexBasis:'unset',flexGrow:'unset', gap:'unset'}} onClick={()=>{setSelectedFile(null);setIsPreviewReady(false);setHeaders([]);setSampleRows([]);setMapping({});}} >
                    Remove File
                  </button>
                  <button className="green-button" style={{width:'15vw', maxWidth:'15vw'}} onClick={handleConfirmMapping}  disabled={!selectedFile}>
                    Confirm Mapping
                  </button>
                </div>
              </div>
            )}

            {isMappingConfirmed && uploadStatus === '' && (
              <div style={{display:'flex',flexDirection:'column'}}>
                <p>All set. Click the button below to start uploading and creating records.</p>
                <button className='green-button' onClick={handleUpload} style={{padding:'1vh 1vw', width:'25vw', alignSelf:'center'}} disabled={!selectedFile || isLoading}>
                  {isLoading ? <Spinner/> : 'Upload & Create Records'}
                </button>
              </div>
            )}

            {uploadStatus === 'Success' && (
              <div>
                <p>Upload and record creation successful!</p>
                <button className='green-button' onClick={onClose}>Close</button>
              </div>
            )}

            {uploadStatus === 'Error' && (
              <div>
                <p>There was an error processing your file.</p>
                <button className='green-button' onClick={()=>setUploadStatus('')}>Try Again</button>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default ImportAppsModal;
