import {gql} from '@apollo/client';

export const UPDATE_USER = gql`
mutation UpdateUser($id: ID!, $input: UpdateUserInput!) {
    updateUser(id: $id, input: $input) {
        id
        email
        firstName
        lastName
        phone
        isPending
    }
}
`;

export const AuthenticateUserMutation = gql`
    mutation authenticateUser($email: String!, $password: String!) {
        authenticateUser(email: $email, password: $password) {
            id
            phone
        }
    }
`

export const SmsAuthenticateUserMutation = gql`
    mutation smsAuthenticateUser($id: ID!, $phoneNumber: String!, $code: String!){
        smsAuthenticateUser(id: $id, phoneNumber: $phoneNumber, code: $code) {
            userId
            userType
            token
            programId
            userPremissions
        }
    }
`

export const resetPasswordRequest = gql`
    mutation resetPasswordRequest($email: String!) {
        resetPasswordRequest(email: $email) {
            id
        }
    }
`

export const resetPassword = gql`
    mutation resetPassword($resetPasswordToken: String!, $password: String!) {
        resetPassword(resetPasswordToken: $resetPasswordToken, password: $password){
            id
        }
    }
`

export const createUser = gql`
    mutation createUser($input: CreateUserInput!) {
        createUser(input: $input) {
            id
        }
    }
`

export const verifyUser = gql`
    mutation verifyUser($token: String!) {
        verifyUser(token: $token) {
            userId
            userType
            token
            programId
            userPremissions
            institutionId
            institution
            email
        }
    }
`