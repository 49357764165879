import React,{useState, useContext, useEffect, useRef} from "react";


const AdminAnalytics = ({ setPage }) => {



    return (
        <div className='faculty-home-container'>
            <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop2' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="faculty-home-header">
                <h2 className="faculty-home-header-title">ANALYTICS</h2>
            </div>
            <seperator className="faculty-home-header-seperator"/>
        </div>
    )
}

export default AdminAnalytics;