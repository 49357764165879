import React, { useState, useContext, useEffect } from 'react';
import logo from '../../../../assets/imgs/logo.svg';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_POSITION, generatePositionDescription } from './graphql/mutations';
import {GET_PROGRAM_USERS_BY_PROGRAM_ID} from './graphql/queries';
import { ProgramIdContext } from "../../progIdContext";
import { UserContext } from '../../../../context/userContext';
import ReactMarkdown from 'react-markdown';
import dummyData from './dummyData';
import { f } from 'html2pdf.js';
import generateButton from '../../../../assets/imgs/generateButton.png';


const employmentTypes = [
    "Permanent Full-Time",
    "Contract Full-Time",
    "Freelance Full-Time",
    "Permanent Part-Time",
    "Contract Part-Time",
    "Freelance Part-Time",
    "Temporary Full-Time",
    "Temporary Part-Time",
    "Seasonal",
    "Freelancer",
    "Consultant",
    "Gig Worker",
    "Self-Employed",
    "Paid Internship",
    "Unpaid Internship",
    "Apprenticeship",
    "Traineeship",
    "Remote Full-Time",
    "Remote Part-Time",
    "Hybrid Full-Time",
    "Hybrid Part-Time",
    "On-Call",
    "Per Diem",
    "Shift Work",
    "Volunteer",
    "Commission-Based",
    "Probationary Employment",
    "Casual Work",
    "Project-Based",
    "Retainer-Based"
  ];

  const educationLevels = [
    "High School Diploma",
    "Associate Degree",
    "Bachelor's Degree",
    "Master's Degree",
    "Doctorate/PhD",
    "Professional Certification",
    "Vocational Training",
    "Some College",
    "GED",
    "No Formal Education",
    "Postdoctoral Studies"
  ];

  const experienceLevels = [
    "Entry Level",
    "Junior Level",
    "Mid Level",
    "Senior Level",
    "Lead",
    "Manager",
    "Director",
    "Executive",
    "Internship",
    "No Experience"
  ];
  
  

const InviteUserModal = ({ isOpen, onClose, onAddUser }) => {
  const programId = useContext(ProgramIdContext);
  const { user } = useContext(UserContext);
  const [generateDescription] = useMutation(generatePositionDescription);
  const [isEditing, setIsEditing] = useState(true);

  const { loading: loadingUsers, error: errorUsers, data: userData } = useQuery(GET_PROGRAM_USERS_BY_PROGRAM_ID, {
    variables: { programId }
    });
  const [createWorkflow, { data, loading, error }] = useMutation(CREATE_POSITION);
  const [hiringManagers, setHiringManagers] = useState([]);

    const [workflowData, setWorkflowData] = useState({
        jobTitle: '',
        programId: programId, 
        department:'',
        hiringManager:'',
        summary: '',
        detailedDescription:'',
        responsibilities: [''],
        dayToDayActivities: [''],

        //Requirements
        minimumQualifications: [''],
        preferredQualifications: [''],
        requiredSkills: [''],
        preferredSkills: [''],
        educationLevel: '',
        certifications: [''],
        experienceLevel: '',
        languagesRequired: [],
        licenses: [''],

        // Employment Details
        employmentType: '', // e.g., Full-time, Part-time
        jobLevel: '',       // e.g., Entry-level, Mid-level
        // jobCategory: String,
        // jobFunction: String,
        salaryRange: {
            min: Number,
            max: Number,
        },
        // commissionBonusStructure: String,
        benefitsOffered: [],
        workSchedule: String,

        workLocation: {
            city: '',
            state: '',
            country: '',
          },
        //   remoteOption: Boolean,
        //   travelRequirements: String,
          relocationAssistance: false,
          closingDate: '',
          requiredDocuments: [],
          screeningQuestions: [],
          positionStatus: 'Open', // e.g., Open, Closed, On Hold
          visibility: true,
        //   equalEmploymentOpportunityStatement: String,
          legalDisclaimers: '',
          securityClearanceRequired: false,
          physicalRequirements: '',
          backgroundCheckRequired: true,
          drugTestRequired: true,
    });

    useEffect(() => {
        if (!loadingUsers && !errorUsers && userData) {
            console.log('User Data:', userData);
            const hiringManagersToUpdate = userData.getProgramMembersByProgramId
    .filter(member => member.roles && member.roles.includes('HM')) // Ensure role is defined and includes 'HM'
            .map(member => {
                return {
                    userId: member.userId.id,
                    name: `${member.userId.firstName} ${member.userId.lastName}`
                };
            });
            console.log('Hiring Managers:', hiringManagersToUpdate);
            setHiringManagers(hiringManagersToUpdate);
        }
    }, [loadingUsers, errorUsers, userData]);
    


    

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setWorkflowData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
      try {
        const result = await createWorkflow({ variables: { input: workflowData } });
        console.log('Mutation result:', result);

        onAddUser()
        onClose();
    } catch (err) {
        console.error('Error executing mutation:', err);
    }
        // Handle form submission logic here
        console.log('Workflow Data:', workflowData);
        onAddUser();
        onClose();
    };



    const handleGenerateDescription = (values) => {


    
        generateDescription({
            variables: {
                input: {
                    programId: programId,
                    userId: user.userId,
                    input: {
                        jobTitle: workflowData.jobTitle,
                        department: workflowData.department,
                        responsibilities: workflowData.responsibilities,
                        dayToDayActivities: workflowData.dayToDayActivities,
                        minimumQualifications: workflowData.minimumQualifications,
                        preferredQualifications: workflowData.preferredQualifications,
                        certifications: workflowData.certifications,
                        educationLevel: workflowData.educationLevel,
                        experienceLevel: workflowData.experienceLevel,
                        licenses: workflowData.licenses,
                    }
                }
            }
        })
        .then((res) => {
            setIsEditing(false);
            const detailedDescriptionPlainText = res.data.generatePositionDescription.fullJobDescription
                .replace(/\*\*/g, '') // Remove bold markers
                .replace(/-/g, '')    // Remove list markers
                .replace(/\n\s*\n/g, '\n'); // Remove extra newlines


            const plainText = res.data.generatePositionDescription.summary
                .replace(/\*\*/g, '') // Remove bold markers
                .replace(/-/g, '')    // Remove list markers
                .replace(/\n\s*\n/g, '\n'); // Remove extra newlines

            setWorkflowData(prevData => ({
                ...prevData,
                detailedDescription: detailedDescriptionPlainText,
                summary: plainText

            }));
        })
      };
    




    const handleAddItem = (arrayName) => {
        setWorkflowData(prevData => ({
          ...prevData,
          [arrayName]: [...prevData[arrayName], '']
        }));
      };
      
      const handleRemoveItem = (arrayName, index) => {
        setWorkflowData(prevData => ({
          ...prevData,
          [arrayName]: prevData[arrayName].filter((_, i) => i !== index)
        }));
      };
      
      const handleItemChange = (arrayName, index, value) => {
        const newArray = [...workflowData[arrayName]];
        newArray[index] = value;
        setWorkflowData(prevData => ({
          ...prevData,
          [arrayName]: newArray
        }));
      };


    if (!isOpen) return null;

    return (
      <div className="modal-backdrop">
        <div className="add-field-modal">
          <div className="add-field-modal-header">
            <h2>CREATE NEW POSITION</h2>
            <img
              src={logo}
              alt="Logo"
              className="logo"
              style={{ width: "4vw" }}
            />
          </div>
          <form onSubmit={handleSubmit}>
            <div
              className="form-group"
              style={{ maxHeight: "66vh", overflowY: "scroll" }}
            >
              <label>Position Title</label>
              <input
                type="text"
                name="jobTitle"
                value={workflowData.jobTitle}
                onChange={handleChange}
                className="border rounded p-2 w-full mb-4"
              />
            
              <label>Department</label>
               <input
                type="text"
                name="department"
                value={workflowData.department}
                onChange={handleChange}
                className="border rounded p-2 w-full mb-4"
              />
              <label>Hiring Manager</label>
              <select
                style={{ width: "100%" }}
                className="border rounded p-2 w-full mb-4"
                value={workflowData.hiringManager !== '' ? workflowData.hiringManager : 'DEFAULT'}
                onChange={(e) => {
                    const selectedId = e.target.value;
                    setWorkflowData(prevData => ({
                        ...prevData,
                        hiringManager: selectedId,
                    }));
                }}
                >
                <option value="DEFAULT" disabled>Select a Hiring Manager</option>
                {hiringManagers.map((hiringManager, index) => (
                  <option key={hiringManager.id} value={hiringManager.userId}>
                    {hiringManager.name}
                  </option>
                ))}
              </select>

<label>Employment Type</label>
              <select
                style={{ width: "100%" }}
                className="border rounded p-2 w-full mb-4"
                value={workflowData.employmentType!==''?workflowData.employmentType:'DEFAULT'}
                onChange={(e) => {
                    const eType = e.target.value;
                    setWorkflowData(prevData => ({
                        ...prevData,
                        employmentType: eType,
                    }));
                }}
                >
                {employmentTypes.map((employmentType, index) => (
                  <option key={employmentType} value={employmentType}>
                    {employmentType}
                  </option>
                ))}
              </select>




      <label>Education Level</label>
              <select
                style={{ width: "100%" }}
                className="border rounded p-2 w-full mb-4"
                value={workflowData.educationLevel!==''?workflowData.educationLevel:'DEFAULT'}
                onChange={(e) => {
                    const eType = e.target.value;
                    setWorkflowData(prevData => ({
                        ...prevData,
                        educationLevel: eType,
                    }));
                }}
                >
                {educationLevels.map((educationLevel, index) => (
                  <option key={educationLevel} value={educationLevel}>
                    {educationLevel}
                  </option>
                ))}
              </select>

              <label>Experience Level</label>

                  <select
                style={{ width: "100%" }}
                className="border rounded p-2 w-full mb-4"
                value={workflowData.experienceLevel!==''?workflowData.experienceLevel:'DEFAULT'}
                onChange={(e) => {
                    const eType = e.target.value;
                    setWorkflowData(prevData => ({
                        ...prevData,
                        experienceLevel: eType,
                    }));
                }}
                >
                {experienceLevels.map((experienceLevel, index) => (
                  <option key={experienceLevel} value={experienceLevel}>
                    {experienceLevel}
                  </option>
                ))}
              </select>


            
            <label>Responsibilities</label>
            
      {workflowData.responsibilities.map((str, index) => (
        <div key={index} className="string-array-item" style={{display:'flex', justifyContent:'space-between'}}>
          <input
            type="text"
            value={str}
            onChange={(e) => handleItemChange('responsibilities',index, e.target.value)}
            className="border rounded p-2 mb-2"
            style={{width:'80%'}}
          />
          <button type="button"
           className='w-1/6 flex items-center justify-center text-white bg-gradient-to-r from-red-500 via-red-600 to-red-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2'
           onClick={() => handleRemoveItem('responsibilities',index)}>
            Remove
          </button>
        </div>
      ))}
      <button type="button"
                className='w-1/3 self-center text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2' 
                style={{ marginLeft: '33%' }}
            onClick={()=>handleAddItem('responsibilities')}>
        Add Responsibility
      </button>
    

            <label>Day to Day Activities</label>
            
      {workflowData.dayToDayActivities.map((str, index) => (
        <div key={index} className="string-array-item" style={{display:'flex', justifyContent:'space-between'}}>
          <input
            type="text"
            value={str}
            onChange={(e) => handleItemChange('dayToDayActivities',index, e.target.value)}
            className="border rounded p-2  mb-2"
            style={{width:'80%'}}
          />
          <button type="button"
          className='w-1/6 flex items-center justify-center text-white bg-gradient-to-r from-red-500 via-red-600 to-red-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2'
          onClick={() => handleRemoveItem('dayToDayActivities',index)}>
            Remove
          </button>
        </div>
      ))}
      <button type="button"
                            className='w-1/3 self-center text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2' 
                            style={{ marginLeft: '33%' }}
            onClick={()=>handleAddItem('dayToDayActivities')}>
        Add Activity
      </button>


            <label>Minimum Qualifications</label>
            
      {workflowData.minimumQualifications.map((str, index) => (
        <div key={index} className="string-array-item" style={{display:'flex', justifyContent:'space-between'}}>
          <input
            type="text"
            value={str}
            onChange={(e) => handleItemChange('minimumQualifications',index, e.target.value)}
            className="border rounded p-2  mb-2"
            style={{width:'80%'}}
          />
          <button type="button"
             className='w-1/6 flex items-center justify-center text-white bg-gradient-to-r from-red-500 via-red-600 to-red-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2'
          onClick={() => handleRemoveItem('minimumQualifications',index)}>
            Remove
          </button>
        </div>
      ))}

<button type="button" 
                            className='w-1/3 self-center text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2' 
                            style={{ marginLeft: '33%' }}
            onClick={()=>handleAddItem('minimumQualifications')}>
        Add Qualification
      </button>

            <label>Preferred Qualifications</label>
            
      {workflowData.preferredQualifications.map((str, index) => (
        <div key={index} className="string-array-item" style={{display:'flex', justifyContent:'space-between'}}>
          <input
            type="text"
            value={str}
            onChange={(e) => handleItemChange('preferredQualifications',index, e.target.value)}
            className="border rounded p-2 mb-2"
            style={{width:'80%'}}
          />
          <button 
           className='w-1/6 flex items-center justify-center text-white bg-gradient-to-r from-red-500 via-red-600 to-red-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2'
          type="button" onClick={() => handleRemoveItem('preferredQualifications',index)}>
            Remove
          </button>
        </div>
      ))}

<button type="button" 
                            className='w-1/3 self-center text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2' 
                            style={{ marginLeft: '33%' }}
            onClick={()=>handleAddItem('preferredQualifications')}>
        Add Qualification
      </button>

            <label>Certifications</label>
           
      {workflowData.certifications.map((str, index) => (
        <div key={index} className="string-array-item" style={{display:'flex', justifyContent:'space-between'}}>
          <input
            type="text"
            value={str}
            onChange={(e) => handleItemChange('certifications',index, e.target.value)}
            className="border rounded p-2 mb-2"
            style={{width:'80%'}}
          />
          <button type="button" 
           className='w-1/6 flex items-center justify-center text-white bg-gradient-to-r from-red-500 via-red-600 to-red-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2'
          onClick={() => handleRemoveItem('certifications',index)}>
            Remove
          </button>
        </div>
      ))}
       <button type="button"
                className='w-1/3 self-center text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2' 
                style={{ marginLeft: '33%' }}
            onClick={()=>handleAddItem('certifications')}>
        Add Certification
      </button>



<label>Licenses</label>

      {workflowData.licenses.map((str, index) => (
        <div key={index} className="string-array-item" style={{display:'flex', justifyContent:'space-between'}}>
          <input
            type="text"
            value={str}
            onChange={(e) => handleItemChange('licenses',index, e.target.value)}
            className="border rounded p-2 mb-2"
            style={{width:'80%'}}
          />
          <button type="button"
           className='w-1/6 flex items-center justify-center text-white bg-gradient-to-r from-red-500 via-red-600 to-red-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2'
          onClick={() => handleRemoveItem('licenses',index)}>
            Remove
          </button>
        </div>
      ))}

<button type="button" 
                className='w-1/3 self-center text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2' 
                style={{ marginLeft: '33%' }}
onClick={()=>handleAddItem('licenses')}>
        Add License
      </button>


                        <label>Description</label>
                           {isEditing ? (
                               <textarea
                                   name="detailedDescription"
                                   value={workflowData.detailedDescription}
                                   onChange={handleChange}
                                   className="border rounded p-2 w-full "
                                   style={{marginBottom:'rem'}}
                               />
                           ) : (
                               <ReactMarkdown className="markdown-preview">
                                   {workflowData.detailedDescription}
                               </ReactMarkdown>
                           )}

<button
                type="button"
                className="green-button"
                style={{fontSize: '12px', padding: '8px 15px', marginBottom: '1vh', marginLeft: 'auto'}}
                onClick={handleGenerateDescription}
                >
                AI Generate
                </button>

            <label>Position Summary</label>
            <textarea
                name="summary"
                value={workflowData.summary}
                onChange={handleChange}
                className="border rounded p-2 w-full mb-4"
              />






            </div>
            <div
              className="button-container"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "2vw",
              }}
            >
              <button
                type="submit"
                className="gradient-button"
                style={{ fontWeight: "500" }}
              >
                Create Workflow
              </button>

              <button type="button" className="white-button" onClick={onClose}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
};

export default InviteUserModal;