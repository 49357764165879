import React,{ useState, useEffect, useContext} from "react";
import GeneralTable from './generalTable';
import './applicationView.css';
import Spinner from "../../common/spinner";
import {allopathicMedicalSchools, osteopathicMedicalSchools} from '../../applicant/applicantComponents/applicationFormSteps/schoolNames';
import { UserContext } from '../../../context/userContext';
import packageInfo from '../../../../package.json';



const Documents = ({ docs, handleUpload, handleUpdate, setDocments, setSelectedDoc}) => {


    const [isOpen, setIsOpen] = useState(false);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [transId, setTransId] = useState("");
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [preViewDoc, setPreViewDoc] = useState(null);
    const { user } = useContext(UserContext);
    const [isDO, setIsDO] = useState(false);
    const [docId, setDocId] = useState('')
    const [docType, setDocType] = useState('')
    const [loading, setLoading] = useState(false);
    const [Error, setError] = useState('')
    


    useEffect(() => {
        const schoolName = user.institution;
        console.log("callled!", schoolName)
        if (osteopathicMedicalSchools.includes(schoolName)) {
            setIsDO(true)
        }
    }, [user])
   


    const columns = [
        {
            id: 'document',
            Header: <label style={{width:'4vw'}}>Document</label>,
            Cell: ({ row }) => {
                console.log("row =>", row.original); // Moved outside of JSX
                return (
                    row.original.file !== null&& row.original.file!=='' ?
                        <div style={{width:'4vw', cursor:'pointer', color:'#145388',textDecoration:'underline'}} onClick={()=>{setIsPreviewOpen(true); setPreViewDoc(row.original.file)}} >{row.original.name}</div>
                        :
                        <div style={{width:'4vw'}}>{row.original.name}</div>
                );
            },
        },
        {
            id: 'status',
            Header: <label style={{width:'3vw'}}>Status</label>,
            Cell: ({ row }) => (
                <div style={{width:'3vw'}}>{row.original.status}</div>
            )
        },
        {
            Header: 'Actions',
            accessor: 'action',
            Cell: ({ row }) => (
                isDO === false && row.original.type === "score" ? null: 
                     (                <div className="dropdown-point">
                     <div className='toggle-points' onClick={() => toggleOpen(row.original)}>
                         <span className='point'/>
                         <span className='point'/>
                         <span className='point'/>
                     </div>
                     {row.original.menuOpen && (
                         <div className="dropdown-menu" style={{display:'flex', position:'relative', zIndex: 999, right: '3vw', flexDirection:'column', padding:'10%',}}>
                             {
                                 row.original.status === "Need to Upload" ?
                                 // || row.original.status === "Pending" ? 
                                 <a href="#" onClick={() => {setSelectedDoc(row.original); toggleOpen(row.original); handleUpdate(row.original)}}>Upload Document</a>
                                 :
                                 <>
                                 <a href="#" onClick={() => {setSelectedDoc(row.original); toggleOpen(row.original); handleUpdate(row.original)}}>Update Document</a>
                                 <a href="#"  onClick={()=>{toggleOpen(row.original); setIsDeleteModalOpen(true); setDocId(row.original.id); setDocType(row.original.type)}}>Remove Document</a>
                                 </>
     
                             }
                             {
                                 row.original.type === "transcript"?
                                 <a href="#"  onClick={()=>{toggleOpen(row.original); setIsEmailModalOpen(true); setTransId(row.original.id)}}>Send Transcript Request</a>
                                 :
                                 ''
                             }
                         </div>
                     )}
                 </div>)

            ),
        },
    ]


    const toggleOpen = (doc) => {
        const docIdx = docs.findIndex(elem => elem.name === doc.name)
        console.log("docIdx =>", docIdx)
        var cpy = [...docs]
        cpy[docIdx].menuOpen = !cpy[docIdx].menuOpen
        cpy.map((elem, idx) => {
            if(docIdx !== idx) elem.menuOpen = false
            return elem
        })
        setDocments(cpy)
    };

    const handleSendTranscriptRequest = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/send_transcript_request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'App-Version': packageInfo.version
                },
                body: JSON.stringify({
                    email: email,
                    id: transId,
                }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log(data); // Handle success response
            // Optionally, close the modal or show a success message
            setIsEmailModalOpen(false);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            // Optionally, handle errors, such as showing an error message
        }
    }

    const handleRemoveDocument = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/remove_document`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'App-Version': packageInfo.version
                },
                body: JSON.stringify({
                    id: docId,
                    type: docType,
                }),
            });
            if (!response.ok) {
                setLoading(false);
                setError('The document could not be removed');
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            // Optionally, close the modal or show a success message
            setIsDeleteModalOpen(false);
        } catch (error) {
            // console.error('There was a problem with the fetch operation:', error);
            // Optionally, handle errors, such as showing an error message
            setLoading(false);
            setError('The document could not be removed');
        }
    }

    return (
        <div className="personal-container" style={{ height: '30vh', marginBottom: '5vh'}}>
{isPreviewOpen && (
                <div className="are-you-sure-modal">
                    <div className="are-you-sure-modal-content">
                        <div className="modal-header" style={{marginTop:'1vh', fontWeight:'500'}}>
                            <h2 style={{fontSize:'3vh', marginBottom:'3vh', alignSelf:'center', fontWeight:'500', fontFamily:'Montserrat'}}>Document Preview</h2>
                            <span style={{cursor:'pointer', fontSize:'3vh', paddingBottom:'3vh'}} className="close" onClick={() => setIsPreviewOpen(false)}>&times;</span>
                        </div>
                        {!preViewDoc && <Spinner/>}
                        {preViewDoc &&
                        <div className="email-modal-content" style={{justifyContent:'space-between', display:'flex'}}>
                            <iframe src={preViewDoc} style={{width: '100%', height: '500px'}} frameBorder="0"></iframe>
                        </div>
}
                    </div>
                </div>
            )
    
}

{isDeleteModalOpen && (
                   <div className="are-you-sure-modal">
                   <div className="are-you-sure-modal-content">
                           <div className="modal-header" style={{marginTop:'1vh', fontWeight:'500'}}>
                           <h2 style={{fontSize:'3vh', marginBottom:'3vh', alignSelf:'center', fontWeight:'500', fontFamily:'Montserrat'}}>Remove Document</h2>
                           <span style={{cursor:'pointer'}} className="close" onClick={() => setIsDeleteModalOpen(false)}>&times;</span>
                               </div>
                                 <p>Are you sure you want to remove this document?</p>
                           <div className="email-modal-content" style={{justifyContent:'space-between', display:'flex', width:'50%', alignSelf:'center',marginTop:'2vh'}}>
                               <button className="green-button" onClick={()=>handleRemoveDocument()}>Remove</button>
                               <button className="green-button" style={{backgroundColor:'white', border:'solid 1px #145388', color:'#145388'}} onClick={()=>setIsDeleteModalOpen(false)}>Cancel</button>

                           </div>
                       </div>
                   </div>
    
)}

            {isEmailModalOpen && (
                
                <div className="are-you-sure-modal">
        <div className="are-you-sure-modal-content">
                <div className="modal-header" style={{marginTop:'1vh', fontWeight:'500'}}>
                <h2 style={{fontSize:'3vh', marginBottom:'3vh', alignSelf:'center', fontWeight:'500', fontFamily:'Montserrat'}}>Send Transcript Request</h2>
                <span style={{cursor:'pointer'}} className="close" onClick={() => setIsEmailModalOpen(false)}>&times;</span>
                    </div>
                <div className="email-modal-content" style={{justifyContent:'space-between', display:'flex'}}>
                    <input style={{width:'30vw'}} onChange={(e) => setEmail(e.target.value)}    type="text" placeholder="Transcript Request Email" />
                    <button className="green-button" onClick={()=>handleSendTranscriptRequest()}>Send</button>
                </div>
            </div>
        </div>
           )  }
         <GeneralTable columns={columns} data={docs} onSelectionChange={() => {}} onSelectedApplication={() => {}} sortData={() => {}} />
        </div>
    )
}

export default Documents;