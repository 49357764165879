import React, { useState, useContext, useEffect } from 'react';
import { FormContext } from '../ApplicationForm';
import { Formik, Form, Field, ErrorMessage,FieldArray } from 'formik';
import Select from 'react-select';
import ReccomendersListTable from './reccomendersTable';
import LORModal from './lorModal';
import { createLorMutation, deleteLorMutation,updateLorApplicationsMutation} from '../graphql/mutations';
import { getAllLorsByUserIdQuery } from '../graphql/queries';
import AddRefModal from './addRef';

import client from '../../../../config/apolloClient';
import {useMutation} from '@apollo/client';


const ApplicationFormStep5 = ({isNested, applicant, updatePhase, updateUserData, updateLorInApplication, applicationData, phases, setSelectedLors, viewOnly}) => {
const { values, setStep, step, recommendersData, applications } = useContext(FormContext);
const [isEdit, setIsEdit] = useState(!viewOnly ? phases.fifthPhase? false : true: false);

const updateLorApplications = useMutation(updateLorApplicationsMutation)[0];



const applicationsLorsWithDup = applications && applications.filter(app => app.masterApplicationId.toString() === applicationData.id.toString())
.flatMap(app => app.lors);
console.log("before =>", applicationsLorsWithDup)
const applicationsLors =  Array.from(new Set(applicationsLorsWithDup))
console.log("applicationsLors =>", applicationsLors)



    
const [reccomenders, setReccomenders] = useState(null);
const [addRefModalOpen, setAddRefModalOpen] = useState(false);
const [lorModalOpen, setLorModalOpen] = useState(false);
const [lors, setLors] = useState([])
const [selectedCount, setSelectedCount] = useState(0);
const [selectedFlatRows, setSelectedFlatRows] = useState([]);
const [toggleRowSelected, setToggleRowSelected] = useState(() => {});

// const applicationsLors = applications.map(lor => lor.id)
console.log(selectedCount)

const handleCheck = (row, checked) => {
    // if(applicationsLors.includes(row.original.id)) return
    const newData = lors.map((item) => 
        item.id === row.original.id ? { ...item, checked: checked } : item
    )
    // console.log("newData =>", newData)
    setLors(newData)
    setSelectedLors(newData)
}


const handleSaveLors = () => {
  const allLorsIds = lors.map(lor => lor.id)
  const filteredChecked = lors.filter(elem => elem.checked === true)
  console.log("filteredChecked =>", filteredChecked.length)
  const lorIds = filteredChecked.map(lor => lor.id)
  updateLorApplications({
    variables: {
      id: applicationData.id, //applicationId
      applicationId: lorIds, //lorsAdded
      allLorsIds 
    }
  })
}

useEffect(() => {
    // go through the lors array and count the number of checked items
    const count = lors.filter(lor => lor.checked).length;
    setSelectedCount(count);
  }, [lors]);
    

const columns = [
    {
        Header: 'Select',
        accessor: 'select',
        Cell: ({ row }) => (
            <input
            style={{ accentColor: '#145388'}}
            type="checkbox"
            disabled={selectedCount >= 4 && !row.original.checked}
            checked={row.original.checked}
            onChange={(e) => {
              if(!viewOnly) handleCheck(row, e.target.checked);
            }}
          />
        ),
    },
    {
        Header: 'Recommender Name',
        accessor: 'recommenderName',
    },
    {
        Header: 'Recommender Email',
        accessor: 'recommenderEmail',
    },
    {
        Header:'Status',
        accessor:'stage',
    },

]

const sendRequest = (recommender) => {
    console.log("recommender =>", recommender)
    // create lor 
}

const getUserLors = () => {
  console.log("applicationData.id =>", applicationData.id)
    if(applicant !== undefined && applicant !== null) {
        client.query({
            query: getAllLorsByUserIdQuery,
            variables: {
                applicantId: applicant.id
            },
            fetchPolicy: 'network-only'
        })
        .then(res => {
            let lors = res.data.getAllLorsByUserId
            // Map through the lors array and for each lor, check if its id is in the applications.lors array
            lors = lors.map(lor => ({
                ...lor,
                checked: lor.applicationId.includes(applicationData.id) ? true : false //applications && applicationsLors ? applicationsLors.includes(lor.id) : false
            }));
            console.log("lors =>", lors)
            setLors(lors)
            setSelectedLors(lors)
        })
    }
}

useEffect(() => {
  getUserLors()

}, [])



return (
<>

<div style={{display:'flex',flexDirection:'column'}}>
  <h2 style={{fontFamily:'Montserrat'}}>References</h2>
 <AddRefModal setAddRefModalOpen={setAddRefModalOpen} isEdit={true} />
{/* <label style={{fontSize:'2vh',marginBottom:'3vh'}} className='applicant-form-field-label'>Below are all letters of recommendation requested or uploaded on your behalf. <br /> Here, you can select which to use in your application. You will need to select at least THREE letters of recommendation, FOUR maximum. <br /> If you would like to request additional letters, please do so in ‘Letters of Recommendation’ on the left sidebar. </label> */}
</div>


{!isNested && isEdit&& (
  <button 
  style={{marginTop: '4vh'}}
  className="mark-as-complete" type="button" onClick={(event) => {
    event.preventDefault()
    updatePhase("fifthPhase")
    // updateLorInApplication(lors)
    // handleSaveLors()
    updateUserData(values, 5);
    setStep(7);

  }}>
    Next
  </button>
)}

{!isNested && !isEdit && (
  <button className="white-button"
  style={{width:'100%', position:'relative', marginTop:'2vh', zIndex:'5'}}
  onClick={() => {
 setIsEdit(true)
 updatePhase("fifthPhase")
    }}>
    Reopen Task
  </button>
)}

</>
)}

export default ApplicationFormStep5;