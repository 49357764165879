import {gql} from '@apollo/client';

export const declineInterviewInvitationMutation = gql`
    mutation declineInterviewInvitation($applicationId: ID!) {
        declineInterviewInvitation(applicationId: $applicationId) {
            id
            programId
            programName
            status
            interviewDate
            specialty
            datesToSend {
                date
                status
                isRegularDay
                startTime
                totalAvailableSlots
                currentAvailableSlots
              }
        }
    }
`;

export const scheduleApplicantInterviewMutation = gql`
    mutation scheduleApplicantInterview($applicationIds: [ID!], $scheduleDate: String, $programId: ID) {
        scheduleApplicantInterview(applicationIds: $applicationIds, scheduleDate: $scheduleDate, programId: $programId) {
            id
            programId
            programName
            status
            interviewDate
            specialty
            datesToSend {
                date
                status
                isRegularDay
                startTime
                totalAvailableSlots
                currentAvailableSlots
              }
        }
    }
`;

export const cancelApplicantInterviewMutation = gql`
    mutation cancelApplicantInterview($applicationIds: [ID!], $programId: ID) {
        cancelApplicantInterview(applicationIds: $applicationIds, programId: $programId) {
            id
            invitationStatus
            interviewDate
        }
    }
`;