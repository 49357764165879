import { gql } from "@apollo/client";

export const getAllProgramsQuery = gql`
query getPrograms {
    getPrograms {
        name
        url
        specialtyDetails {
            name
        }
    }
}
`