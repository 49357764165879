import { gql } from "@apollo/client";

export const getProgramByIdQuery = gql`
query getProgramById($id: ID!) {
    getProgramById(id: $id) {
        id
        name
        description
        isRubricLocked
         defaultLegalDisclaimers
            defaultEqualEmploymentOpportunityStatement
        rubricTemplate {
            id
            label
            inputType
            isPreInterview
            maxScale
            lowestScaleText
            highestScaleText
            dropdownOptions {
                option
                score
            }
            text
            order
        }
    }
}
`;