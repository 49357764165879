import React,{useState, useContext, useEffect, useRef} from "react";
import {useQuery, useMutation} from '@apollo/client';
import { getApplicationsWithPaginationQuery, getApplicationsWithPaginationCountQuery} from './graphql/queries';
import GeneralTable from './generalTable';
import Pagination from './paginationComponent';
import PageSizeSelector from './pageSizeSelector';
import moment from 'moment';
import { UserContext } from '../../../../context/userContext';
import { updatePaymentDetailsMutation } from './graphql/mutations';

const AdminMasterApplications = ({ setPage }) => {
    const [applications, setApplications] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(20);
    const [filter, setFilter] = useState({field: 'userId.name', value: null});
    const [sort, setSort] = useState({ field: 'userId.name', direction: 'desc'});
    const [totalApplications, setTotalApplications] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [searchInput, setSearchInput] = useState('');
    const [selectedApplication, setSelectedApplication] = useState(null);
    const [otherViewOpen, setOtherViewOpen] = useState(false);
    const { user } = useContext(UserContext);
    const [showSuccess, setShowSuccess] = useState(false);
    const [loadingData, setLoadingData] = useState(false)

    const [updatePaymentDetails] = useMutation(updatePaymentDetailsMutation)

    const columns = [
        {
            accessor: 'userId.firstName',
            id: 'Applicant Name',
            Header: <label style={{width:'2vw'}}>Applicant Name</label>,
            Cell: ({ row }) => (
                <div style={{width:'2vw'}}>{`${row.original.userId.firstName} ${row.original.userId.lastName}`}</div>
            ),  
        },
        {
            accessor: 'programId.name',
            id: 'Program Name',
            Header: <label style={{width:'30vw'}}>Program Name</label>,
            Cell: ({ row }) => (
                <div style={{width:'30vw'}}>{`${row.original.programId.name}`}</div>
            ),  
        },
        {
            accessor: 'specialtyId.name',
            id: 'Inst Name',
            Header: <label style={{width:'4vw'}}>Specialty Name</label>,
            Cell: ({ row }) => (
                <div style={{width:'4vw'}}>{`${row.original.specialtyId.name}`}</div>
            ),  
        },
        {
            accessor: 'masterApplicationId.paymentStatus',
            id: 'paymentStatus',
            Header: <label style={{width:'4vw'}}>Payment Status</label>,
            Cell: ({ row }) => (
                <div style={{width:'4vw', textTransform: 'capitalize'}}>{`${row.original.masterApplicationId.paymentStatus}`}</div>
            ),  
        },
        {
            accessor: 'createdAt',
            id: 'CreatedAt',
            Header: <label style={{width:'4vw'}}>Created At</label>,
            Cell: ({ row }) => (
                <div style={{width:'4vw'}}>{row.original.createdAt ? moment(new Date(parseInt(row.original.createdAt))).format('MM/DD/YYYY HH:mm'): ""}</div>
            ),  
        },
        {
            id: 'actions',
            Header: <label style={{width:'4vw'}}>Actions</label>,
            Cell: ({ row }) => (
                <div className="dropdown-point">
                    <div className='toggle-points' onClick={() => toggleSelected(row.original)}>
                        <span className='point'/>
                        <span className='point'/>
                        <span className='point'/>
                    </div>
                    {
                        otherViewOpen === false && selectedApplication && row.original.id.toString() === selectedApplication.id.toString() && (
                        <div className="dropdown-menu" style={{display:'flex',zIndex: 999, right: '0', flexDirection:'column', padding:'10%',}}>
                            <a href="#" onClick={() => { setOtherViewOpen(true); updatePayment()}}>Waive payment for scholarship recipients</a>
                        </div>)
                    }
                </div> 
            ),  
        }, 
    ]



    const updatePayment = () => {
        updatePaymentDetails({
            variables: {
                masterApplicationId: selectedApplication.masterApplicationId.id,
                userId: user.userId
            }
        })
        .then(res => {
            refetchApplications()
            setShowSuccess(true)
            setOtherViewOpen(false)
            setSelectedApplication(null)
            setTimeout(() => {
                setShowSuccess(false)
            }, 5000);
        })
    }

    const toggleSelected = (application) => {
        if(selectedApplication === null) {
            setSelectedApplication(application)
        } else {
            if(selectedApplication.id.toString() === application.id.toString()) {
                // close menu
                setSelectedApplication(null)
            } else {
                // other user clicked
                setSelectedApplication(null)
                setSelectedApplication(application)
            }
        }
    }

    const { loading: loadingApplications, error: errorApplications, data: applicationsData, refetch: refetchApplications} = useQuery(getApplicationsWithPaginationQuery, {
        variables: {
            offset,
            limit,
            filter,
            sort
        }
    })

    const { loading: loadingApplicationsCount, error: errorApplicationsCount, data: applicationsCount, refetch: refetchApplicationsCount } = useQuery(getApplicationsWithPaginationCountQuery, {
        variables: {
            filter,
        }
    })

    const sortData = (column, direction) => {
        setSort({ field: column, direction: direction === true ? "asc": "desc"})
        refetchApplications()
    }

    useEffect(() => {
        setLoadingData(true)
        if(!loadingApplications && !errorApplications && applicationsData) {
            console.log("applicationsData =>", applicationsData.getApplicationsWithPaginationAdmin)
            setApplications(applicationsData.getApplicationsWithPaginationAdmin)
            setLoadingData(false)
        }
    }, [loadingApplications, errorApplications, applicationsData, setApplications])

    useEffect(() => {
        if(!loadingApplicationsCount && !errorApplicationsCount && applicationsCount) {
            console.log("applicationsCount =>", applicationsCount.getApplicationsWithPaginationCount)
            setTotalApplications(applicationsCount.getApplicationsWithPaginationCount)
            setTotalPages(Math.ceil(applicationsCount.getApplicationsWithPaginationCount/ Number(limit)))
        }
    }, [loadingApplicationsCount, errorApplicationsCount, applicationsCount,setTotalPages, setTotalApplications, limit])

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchInput(value)
        var searchObj = { ...filter}
        searchObj.value = value
        setLoadingData(true)
        setFilter(searchObj)
    }

    const handlePageSizeChange = (event) => {
        setLimit(Number(event.target.value));
        setOffset(0)
        setCurrentPage(1); // Reset to first page when page size changes
        setTotalPages(Math.ceil(totalApplications/ Number(event.target.value)))
        setLoadingData(true)
        refetchApplications()
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setOffset((page - 1) * limit )
        setLoadingData(true)
        refetchApplications()
    };


    return (
        <div className='faculty-home-container'>
            <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop2' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="faculty-home-header">
                <h2 className="faculty-home-header-title">APPLICATIONS</h2>
            </div>
            <seperator className="faculty-home-header-seperator"/>
            <div className="right-header-container" style={{ marginBottom: '2vh'}}>
                <div className="input-container">
                    <i className="fas fa-search"></i>
                    <input
                        className="applicant-top-bar-search-input"
                        placeholder="Search"
                        value={searchInput}
                        onChange={handleSearchChange}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                refetchApplications()
                            }
                        }}
                    />
                </div>
                { showSuccess && <label style={{ color: "#145388", fontFamily: 'Montserrat'}}>Waive payment updated successfully.</label>}
            </div>
            <GeneralTable columns={columns} data={applications} sortData={sortData} limit={limit} totalPages={totalPages} currentPage={currentPage} handlePageSizeChange={handlePageSizeChange} handlePageChange={handlePageChange} loadingData={loadingData}/>
            {/* <div className="faculty-application-table-footer" style={{ height: '4vh'}}>
                <PageSizeSelector itemsPerPage={limit} onPageSizeChange={handlePageSizeChange} />
                <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
            </div> */}
        </div>
    )
}

export default AdminMasterApplications;