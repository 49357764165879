import React, { useState, useEffect } from 'react';
import logo from '../../../../assets/imgs/logo.svg'
import fieldMapping from './fieldMapping';

const EditViewModal = ({ isOpen, onClose, fields, onSave, screenedData }) => {
var allAvilableFields = [
        // "AAMC ID",
        // "AOA ID",
        // "AOA Status",
        // "Comlex 1",
        // "Comlex 2",
        // "Couple's Match",
        "Email",
        // "First Author Publications",
        "First Name",
        "Gender",
        // "Gold Humanism Honor Society Status",
        "Home Address",
        // "Hometown",
        "Last Name",
        // "Medical School Name",
        // "Medical School State",
        // "Medical Training Interrupted",
        // "Military Obligation",
        // "NBOME ID",
        // "NRMP ID",
        // "Other Service Obligations",
        // "Peer-Reviewed Publications",
        "Phone Number",
        // "Podium Presentations",
        // "Poster Presentations",
        // "Preferred Name",
        // "Previous Applicant",
        // "Pronouns",
        // "Race/Ethnicity",
        // "Region",
        // "Signal",
        // "Signal Type",
        // "Sigma Sigma Phi",
        // "Step 1",
        // "Step 2",
        // "Submission Date",
        "US Citizen",
        // "USMLE ID",
        "Work Authorization",
        // "Selected Tracks",
        // "Previous Last Name",
        // "Licensure Revoked",
        // "Licensure Revoked Details",
        // "Military Obligation Details",
        // "Medical Training Interrupted",
        // "Medical Training Interrupted Details",        
    ];

    const [selectedFields, setSelectedFields] = useState(fields);
    
    let screenedFields = {
        ...screenedData.personalInfo,
        ...screenedData.residencyApplication,
    }
    let screenedNotShow = Object.keys(screenedFields).reduce((acc, key) => {
        if (screenedFields[key] === false) {
            acc[key] = screenedFields[key];
        }
        return acc;
    }, {});

    let notShowKeys = Object.keys(screenedNotShow)
    console.log("notShowKeys =>", notShowKeys)

    var allOptions = []
    allAvilableFields.forEach(option => {
        allOptions.push({ accessor: fieldMapping[option], label: option})
    })

    notShowKeys.forEach(key => {
        allOptions = allOptions.filter(elem => elem.accessor !== key)
    })

    console.log("allOptions =>", allOptions)
    
    var filteredFields = []
    allOptions.forEach(elem => filteredFields.push(elem.label))
    console.log("filteredFields =>", filteredFields)
    
    allAvilableFields = [...filteredFields]

    // notShowKeys.forEach(key => {
    //     fieldMapping = fieldMapping.filter(elem => elem.accessor !== key)
    // })

    useEffect(() => {
        setSelectedFields(fields.map(fieldAccessor => Object.keys(fieldMapping).find(key => fieldMapping[key] === fieldAccessor)));
    }, [fields]);

        
    const handleCheckboxChange = (event) => {
        const fieldName = event.target.name;
        const fieldAccessor = fieldMapping[fieldName];
    
        if (event.target.checked) {
            setSelectedFields([...selectedFields, fieldName]); // Add the field name, not the accessor
        } else {
            setSelectedFields(selectedFields.filter(field => field !== fieldName)); // Remove the field name, not the accessor
        }
    };


    return (
        <div className="modal-backdrop" style={{zIndex:'25'}}>
            <div className="add-field-modal" style={{ height: '70vh', overflow: 'scroll' }}>
                <div className='add-field-modal-header'>
                <h2>EDIT VIEW</h2>
                <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
                </div>
                <form>
                <div className="checkbox-container" style={{overflowY:'scroll', height:'43vh', marginBottom:'2vh'}}>
        {allAvilableFields.map((field, index) => (
            <div key={index} className="checkbox-item">
                <input 
                    type="checkbox" 
                    id={field} 
                    name={field} 
                    checked={selectedFields.includes(field)} // Check if selectedFields includes the field name
                    onChange={handleCheckboxChange}
                />
                <label htmlFor={field}>{field}</label>
            </div>
        ))}
    </div>
                    <div className='button-container' style={{display:'flex', flexDirection:'row', justifyContent:'space-between', gap:'2vw'}}>
                    <button type="button" className='gradient-button' onClick={() => onSave(selectedFields)}>Save</button>
                    <button type="button" className='white-button'  onClick={onClose}>Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditViewModal;