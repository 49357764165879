import React, { useState, useEffect, createContext } from 'react';
import {useQuery} from '@apollo/client';
import { getUserByToken } from '../components/home/components/graphql/queries';
import { allopathicMedicalSchools, osteopathicMedicalSchools } from '../components/applicant/applicantComponents/applicationFormSteps/schoolNames';

const schoolTypeSetup = (schoolName) => {
  if (allopathicMedicalSchools.includes(schoolName)) {
      return 'US Allopathic Medical School (MD)';
  } else if (osteopathicMedicalSchools.includes(schoolName)) {
    return 'US Osteopathic Medical School (DO)';
  } else {
    return 'International Medical School';
  }

  }

export const UserContext = createContext();

const UserProvider = ({ children }) => {
    console.log('UserProvider re-rendered');
  const [user, setUser] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [topUser, setTopUser] = useState(null);
  const [editViewFields, setEditViewFields] = useState([ 
        'firstName',
        'lastName',
        'email',
        'phone',
        'nrmpId',
        'workAuthorization',
        'gender',
        'hometown',
        'ethnicity',
        'coupleMatch',
        'medicalSchoolName',
        'aoaStatus',
        'createdAt']);
  const token = localStorage.getItem('login_token');
  const { loading, error, data } = useQuery(getUserByToken, {
    variables: { token },
    skip: !token, // skip the query if there is no token
  });

  useEffect(() => {
    console.log("use effect top called!")
    if (!token) {
        setUser(null);
    }
    if (!loading) {
      if (data) {
        let userData = {...data.getUserByToken};
        if (userData.userType === 'applicant') {
        const schoolName = data.getUserByToken.institution;
        userData.schoolType = schoolTypeSetup(schoolName);
        }
        if(!userData.savedEditView || userData.savedEditView.length === 0) {
           userData.savedEditView = editViewFields;
        }
        setUser(userData);
      }
      if (error) {
        console.error("Error verifying token", error);
        localStorage.removeItem('login_token'); // remove invalid token
      }
      if (token) {
        setIsVerified(true);
      }
      setDataLoaded(true);
    }
  }, [data, error, loading, token]);

  useEffect (() => {
    console.log(user, 'user in user context'
    )
  }, [user])

  useEffect (() => {
    console.log(topUser, 'topUser in user context'
    )
  }, [topUser])

    return dataLoaded ? (
        <UserContext.Provider value={{ user,topUser, setUser, isVerified, loading, error, setTopUser, editViewFields }}>
          {children}
          {console.log("childern =>", children)}
        </UserContext.Provider>
      ) : null;
};

export default UserProvider;