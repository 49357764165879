import { Form, Formik, Field, ErrorMessage } from 'formik';
import React, { useState, useEffect } from 'react';
import logo from '../../../../assets/imgs/logo.svg';
import * as Yup from "yup";

const validationSchema = Yup.object({
    lors: Yup.number()
    .typeError('Must be a number')
    .required('Required'),
    cost: Yup.number()
    .typeError('Must be a number')
    .required('Required'),
  });

const EditRequirementsModal = ({ isOpen, onClose, specialty, onEditSpecialty }) => {

    if (!isOpen) return null;
    return (
        <div className="modal-backdrop">
            <div className="add-field-modal">
                <div className='add-field-modal-header'>
                    <h2>EDIT SPECIALTY REQUIREMENTS</h2>
                    <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
                </div>

                <Formik
                    initialValues={{
                        degree: specialty.applicationRequirements.degree,
                        lors: specialty.applicationRequirements.lors,
                        mspe: specialty.applicationRequirements.mspe === true ? "yes" : specialty.applicationRequirements.mspe === "false" ? "no" : "",
                        transcript: specialty.applicationRequirements.transcript === true ? "yes" : specialty.applicationRequirements.transcript === "false" ? "no" : "",
                        step1: specialty.applicationRequirements.step1 === true ? "yes" : specialty.applicationRequirements.step1 === "false" ? "no" : "",
                        step2: specialty.applicationRequirements.step2 === true ? "yes" : specialty.applicationRequirements.step2 === "false" ? "no" : "",
                        cost: specialty.applicationRequirements.cost
                    }}
                    onSubmit={values => {
                        if(values.mspe === "yes") values.mspe = true
                        else if(values.mspe === "no") values.mspe = false
                        
                        if(values.transcript === "yes") values.transcript = true
                        else if(values.transcript === "no") values.transcript = false
                        
                        if(values.step1 === "yes") values.step1 = true
                        else if(values.step1 === "no") values.step1 = false
                        
                        if(values.step2 === "yes") values.step2 = true
                        else if(values.step2 === "no") values.step2 = false

                        console.log("values =>", values)

                        onEditSpecialty(values)
                        onClose()
                    }}
                    validationSchema={validationSchema}
                >
                 {({ handleSubmit, values }) => (

                    <Form style={{ height: '55vh', overflowY: 'auto'}}>
                    <div className='application-form-field-group'>
                        <label htmlFor="degree" className='applicant-form-field-label'>Degree</label>
                        <Field name="degree" placeholder="" className='application-form-standard-input' style={{width:'100%'}} />
                        <div className='application-form-error-message'> 
                            <ErrorMessage name="degree" component="div" />
                        </div>
                    </div>
                    <div className='application-form-field-group'>
                        <label htmlFor="lors" className='applicant-form-field-label'>Lors</label>
                        <Field name="lors" placeholder="" className='application-form-standard-input' style={{width:'100%'}} />
                        <div className='application-form-error-message'> 
                            <ErrorMessage name="lors" component="div" />
                        </div>
                    </div>
                    <div className='application-form-field-group single' style={{marginBottom:'3vh'}}>
                        <label className='applicant-form-field-label'>MSPE</label>
                        <div role="group" aria-labelledby="radio-group" className='application-form-group-radio' style={{accentColor: '#145388'}}>
                        <label>
                        <Field style={{display:'unset', marginRight:'.4vw', fontSize: '18px'}} type="radio" name="mspe" value="yes" />
                        Yes
                        </label>
                        <label>
                        <Field style={{display:'unset', marginRight:'.4vw'}} type="radio" name="mspe" value="no" />
                        No
                        </label>
                        </div>
                        <div className='application-form-error-message'> 
                        <ErrorMessage name="mspe" component="div" />
                        </div>
                    </div>



                    <div className='application-form-field-group single' style={{marginBottom:'3vh'}}>
                        <label className='applicant-form-field-label'>Transcript</label>
                        <div role="groupTranscript" aria-labelledby="radio-group" className='application-form-group-radio' style={{accentColor: '#145388'}}>
                        <label>
                        <Field style={{display:'unset', marginRight:'.4vw', fontSize: '18px'}} type="radio" name="transcript" value="yes" />
                        Yes
                        </label>
                        <label>
                        <Field style={{display:'unset', marginRight:'.4vw'}} type="radio" name="transcript" value="no" />
                        No
                        </label>
                        </div>
                        <div className='application-form-error-message'> 
                        <ErrorMessage name="transcript" component="div" />
                        </div>
                    </div>
                    <div className='application-form-field-group single' style={{marginBottom:'3vh'}}>
                        <label className='applicant-form-field-label'>Step 1</label>
                        <div role="groupStep1" aria-labelledby="radio-group" className='application-form-group-radio' style={{accentColor: '#145388'}}>
                        <label>
                        <Field style={{display:'unset', marginRight:'.4vw', fontSize: '18px'}} type="radio" name="step1" value="yes" />
                        Yes
                        </label>
                        <label>
                        <Field style={{display:'unset', marginRight:'.4vw'}} type="radio" name="step1" value="no" />
                        No
                        </label>
                        </div>
                        <div className='application-form-error-message'> 
                        <ErrorMessage name="step1" component="div" />
                        </div>
                    </div>
                    <div className='application-form-field-group single' style={{marginBottom:'3vh'}}>
                        <label className='applicant-form-field-label'>Step 2</label>
                        <div role="groupStep2" aria-labelledby="radio-group" className='application-form-group-radio' style={{accentColor: '#145388'}}>
                        <label>
                        <Field style={{display:'unset', marginRight:'.4vw', fontSize: '18px'}} type="radio" name="step2" value="yes" />
                        Yes
                        </label>
                        <label>
                        <Field style={{display:'unset', marginRight:'.4vw'}} type="radio" name="step2" value="no" />
                        No
                        </label>
                        </div>
                        <div className='application-form-error-message'> 
                        <ErrorMessage name="step2" component="div" />
                        </div>
                    </div>
                    <div className='application-form-field-group'>
                        <label htmlFor="cost" className='applicant-form-field-label'>Cost ($)</label>
                        <Field name="cost" placeholder="" className='application-form-standard-input' style={{width:'100%'}}  />
                        <div className='application-form-error-message'> 
                            <ErrorMessage name="cost" component="div" />
                        </div>
                    </div>

                    <div style={{ marginTop: '2vh', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <button type="submit" className='green-button' style={{
                            border: 'none',
                            outline: 'none',
                            cursor: 'pointer',
                            background: 'linear-gradient(96.75deg, #2a93d5 0%, #596173 133.3%)',
                            borderRadius: '7px',
                            padding: '12px 30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#FFFFFF',
                            fontWeight:'700',
                            width: '20vw'
                        }}
                        disabled={values.degree === "" || values.lors === "" || values.mspe === "" || 
                            values.transcript === "" || values.step1 === "" || values.step2 === "" || values.cost === ""}
                        >
                        Update Requirements
                        </button>
                        <button className='lor-modal-submit-button' onClick={onClose} style={{
                            outline: 'none',
                            cursor: 'pointer',
                            background: '#FFFFFF',
                            border: '1px solid #145388',
                            borderRadius: '7px',
                            padding: '12px 30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#145388',
                            fontWeight:'700',
                            width: '20vw'

                        }}>Cancel</button>
                        </div>
                    </Form>
                 )}
                </Formik>
            </div>
        </div>
    )

}

export default EditRequirementsModal;