import React,{useState, useContext, useEffect} from "react";
import ScreenedSectionsBar from "./screenedSectionsBar";
import ScreenedItem from "./screenedItem";
import { useMutation } from '@apollo/client';
import {UPDATE_SCREENED_DATA} from '../graphql/mutations';
import Spinner from "../../../../common/spinner";
import "./index.css";


function cleanData(data) {
    return JSON.parse(JSON.stringify(data), (key, value) =>
        key === '__typename' || key === 'clerkshipGrades' ? undefined : value
    );
}



const CustomizeApplication = ({screenedData, program}) => {

    const [currentSection, setCurrentSection] = useState('personalInfo');
    const cleanedData = cleanData(screenedData);
    const [data, setData] = useState(cleanedData);
    const programId = program.id;
    const [updateScreenedData, { loading }] = useMutation(UPDATE_SCREENED_DATA, {
        // onCompleted: (data) => {
        //     setData(prevState => ({
        //         ...prevState,
        //         [data.updateScreenedData.updatedField]: data.updateScreenedData.screenedData[data.updateScreenedData.updatedField]
        //     }));
        // }
    });
    let prevData = cleanedData;


    useEffect(() => {
        if (currentSection in data) {
            setData(prevState => ({
                ...prevState,
                [currentSection]: data[currentSection]
            }));
        }
    }, [currentSection]);

    useEffect(() => {
        if (JSON.stringify(data) !== JSON.stringify(prevData)) {
            updateScreenedData({ variables: { programId:programId, input:data } });
        }
    }, [data, programId, updateScreenedData]);
    


   
    return (
        <div>
       {loading && <Spinner />}
       {!loading && (
        <>
            <ScreenedSectionsBar setSection={setCurrentSection} section={currentSection} data={data} setData={setData} />
            <ScreenedItem  section={currentSection} data={data} setData={setData}/>
</>
         )}

           
        </div>
    );
}

export default CustomizeApplication;

