import React, { useState, useEffect } from 'react';
import logo from '../../../../assets/imgs/logo.svg'
import Select from 'react-select';

const AddNewGroupModal = ({ isOpen, onClose, onAddGroup, onEditGroup, groupToEdit, programMembers, programMembersOptions, createGroup }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [selectedMembers, setSelectedMembers] = useState([]);

    console.log("programMembersOptions inside add =>", programMembersOptions)

   

    useEffect(() => {
        
        if (groupToEdit) {
            setName(groupToEdit.name);
            setDescription(groupToEdit.description);
            setSelectedMembers(groupToEdit.members);
        } else {
            setName('');
            setDescription('');
            setSelectedMembers([]);
        }
    }, [groupToEdit]);


if (!isOpen) return null;


const handleSubmit = (event) => {
    event.preventDefault();
    const group = {
        name: name,
        description: description,
        members: selectedMembers,
        owner:'1553',
        applicationIds: groupToEdit ? groupToEdit.applicationIds : []
    };

    if (groupToEdit) {
        onEditGroup({...group, id: groupToEdit.id});
    } else {
        onAddGroup(group);
    }

    setDescription(''); // reset description
    setName(''); // reset name
    setSelectedMembers([]); // reset members
    onClose();
}

const handleChange = (selected) => {
    // Check if "Select All" option is selected
    if (selected.some(option => option.value === 'selectAll')) {
        // Filter out the "Select All" option and set all other options as selected
        const allOptionsSelected = programMembersOptions.filter(option => option.value !== 'selectAll');
        setSelectedMembers(allOptionsSelected);
    } else {
        setSelectedMembers(selected);
    }
}


const modifiedOptions = [{ label: "Select All", value: "selectAll" }, ...programMembersOptions];

return (

<div className="modal-backdrop">
<div className="add-field-modal">
    <div className='add-field-modal-header'>
    <h2>NEW GROUP</h2>
    <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
    </div>

    <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label>Name</label>
                <input
                className="application-form-standard-input"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

                <label>Description</label>
                <input
                className="application-form-standard-input"
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />

                <label>Members</label>
                {/* <input
                className="application-form-standard-input"
                    type="text"
                    value={members.join(',')}
                    onChange={(e) => setMembers(e.target.value.split(','))}
                /> */}
                 <Select
                    isMulti
                    name="fruits"
                    options={modifiedOptions}
                    value={selectedMembers}
                    onChange={handleChange}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '100%',
                          height: '61px',
                          boxSizing: 'border-box',
                          color: '#414141',
                          background: 'linear-gradient(0deg, #f2f2f2, #f2f2f2), #fff',
                          border: '1px solid #dbdbdb',
                          borderRadius: '8px',
                          outline: 'none',
                          padding: '11px 23px',  // Note: last padding value used, overriding previous
                          fontFamily: 'Montserrat',
                          fontSize: '20px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: '30px',
                          overflow:'scroll'
                        }),
                        option: (provided) => ({
                          ...provided,
                          // Add your custom styles here
                        }),
                        menu: (provided) => ({
                            ...provided,
                            zIndex: 1000  // Ensure this is higher than the z-index of other components
                          })
                        
                        // Add more parts to style as needed
                      }}
                />
               

</div>

        <div className='button-container' style={{display:'flex', flexDirection:'row', justifyContent:'space-between', gap:'2vw'}}>
        <button type="submit" 
        disabled={name === '' || description === '' ||  (selectedMembers !== null && selectedMembers !== undefined && selectedMembers.length === 0) || selectedMembers === null || selectedMembers === undefined}
        className='gradient-button'  style={{fontWeight:'500'}} onClick={() => groupToEdit === null ? createGroup(name, description, selectedMembers) : onEditGroup(groupToEdit, name, description, selectedMembers)}>{groupToEdit === null ? `Create Folder` : `Edit Folder`}</button>
            <button type="button" className='white-button'  onClick={onClose}>Cancel</button>
        </div>
    </form>
    
</div>
</div>
)
};

export default AddNewGroupModal;