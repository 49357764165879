const mockedGroups = [
    {
        name: 'Group 1',
        description: 'This is the first group',
        applicationIds: ['1','2','3','4','5'],
        owner: '1553',
        members: ['1553','1554','1555','1556','1557']
    },
    {
        name: 'Group 2',
        description: 'This is the second group',
        applicationIds: ['6','7','8','9','10'],
        owner: '1554',
        members: ['1553','1554','1555','1556','1557']
    },
    {
        name: 'Group 3',
        description: 'This is the third group',
        applicationIds: ['11','12','13','14','15'],
        owner: '1553',
        members: ['1553','1554','1555','1556','1557']
    },
    {
        name: 'Group 4',
        description: 'This is the fourth group',
        applicationIds: ['16','17','18','19','20'],
        owner: '1554',
        members: ['1553','1554','1555','1556','1557']
    },
    {
        name: 'Group 5',
        description: 'This is the fifth group',
        applicationIds: ['21','22','23','24','25'],
        owner: '1553',
        members: ['1553','1554','1555','1556','1557']
    }

];

export default mockedGroups;

