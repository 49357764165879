import React, { createContext, useState, useContext } from 'react';

// Step 1: Create the context
export const InterviewScheduleContext = createContext();

// Step 2: Create the Provider Component
export const InterviewScheduleProvider = ({ children }) => {
    const [selectedDates, setSelectedDates] = useState([]);
    const [page, setPage] = useState('');
    const [step, setStep] = useState(0);
    const [startTime, setStartTime] = useState('');
    const [interviewLength, setInterviewLength] = useState(0);
    const [breakTime, setBreakTime] = useState(0);
    const [slots, setSlots] = useState([]);
    const [dayFormat, setDayFormat] = useState(false);
    const [dayType, setDayType] = useState(false);
    const [numberOfSlots, setNumberOfSlots] = useState(0);
    const [isLocked, setIsLocked] = useState(false);
    const [interviewersPerInterviewee, setInterviewersPerInterviewee] = useState(0);
    const [numOfInterviewers, setNumOfInterviewers] = useState(0);


    const resetValues = () => {
        setSelectedDates([]);
        setPage('');
        setStep(0);
        setStartTime('');
        setInterviewLength(0);
        setBreakTime(0);
        setSlots([]);
        setDayFormat(false);
        setDayType(false);
        setNumberOfSlots(0);
        setIsLocked(false);
        setInterviewersPerInterviewee(0);
        setNumOfInterviewers(0);
    }



    const value = {
        selectedDates, setSelectedDates,
        page, setPage,
        step, setStep,
        startTime, setStartTime,
        interviewLength, setInterviewLength,
        breakTime, setBreakTime,
        slots, setSlots,
        dayFormat, setDayFormat,
        dayType, setDayType,
        numberOfSlots, setNumberOfSlots,
        isLocked, setIsLocked,
        interviewersPerInterviewee, setInterviewersPerInterviewee,
        numOfInterviewers, setNumOfInterviewers,        
        resetValues,
    };

    return (
        <InterviewScheduleContext.Provider value={value}>
            {children}
        </InterviewScheduleContext.Provider>
    );
};

// Custom hook to use the context
export const useInterviewSchedule = () => useContext(InterviewScheduleContext);