import React,{useState} from "react";
import "./index.css";
import eyeClosed from "../../../../assets/imgs/eyeClose.svg";
import openEye from "../../../../assets/imgs/openEye.svg"
import { handleError } from "@apollo/client/link/http/parseAndCheckHttpResponse";
import { Tooltip } from 'reactstrap';

const ScreenedSectionsBar = ({ setSection, section,data,setData}) => {
    const [currentSection, setCurrentSection] = useState(section);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
    const [tooltipOpenSecond, setTooltipOpenSecond] = useState(false);
    const toggleTooltipSecond = () => setTooltipOpenSecond(!tooltipOpenSecond);
    const [tooltipOpenThird, setTooltipOpenThird] = useState(false);
    const toggleTooltipThird = () => setTooltipOpenThird(!tooltipOpenThird);
    const [tooltipOpenForth, setTooltipOpenForth] = useState(false);
    const toggleTooltipForth = () => setTooltipOpenForth(!tooltipOpenForth);
    const [tooltipOpenFifth, setTooltipOpenFifth] = useState(false);
    const toggleTooltipFifth = () => setTooltipOpenFifth(!tooltipOpenFifth);
   

    // Use setSection to update the current section
    const handleSectionChange = (section) => {
        console.log(section)
        setSection(section);
    }

const selectedDesign = {
    background: '#1453884d',
    border: '2px solid #145388',
    cursor: 'pointer'
}
const unselectedDesign = {
    cursor: 'pointer'
}

    return (


    <div className="screened-sections-bar">
        <Tooltip placement="top" 
        isOpen={tooltipOpen}  
        target="TooltipExample" 
        toggle={toggleTooltip}
        className='tooltip-higher-zindex'
        style={{borderRadius:'4px', marginLeft:'11vw', border:'solid #145388 .1vh',fontFamily:'Montserrat',zIndex:100, backgroundColor:'white', color:'#145388', fontSize:'2vh', width:'26vw', textAlign:'center', padding:'2vh'}}
        >
            To hide or unhide this section, click on the eye icon
        </Tooltip>
        <div id="TooltipExample" onMouseOver={toggleTooltip} onMouseOut={toggleTooltip}>

        <div className={data.sections.personalInfo?"screened-sections-bar-item":"screened-sections-bar-item screened"}
        style={currentSection==='personalInfo'?selectedDesign:unselectedDesign}
         onClick={()=>handleSectionChange('personalInfo')}>
        <div className="step-indicator-number">1</div>
            <label style={{cursor:'pointer'}} onClick={()=>setCurrentSection('personalInfo')}>Personal Information</label>
            <img src={data.sections.personalInfo ? openEye : eyeClosed} alt="eye icon" onClick={()=>setData({...data, sections: {...data.sections, personalInfo:!data.sections.personalInfo}})}/>
            </div>
        </div>
        <Tooltip placement="top" 
        isOpen={tooltipOpenSecond}  
        target="TooltipExample2" 
        toggle={toggleTooltipSecond}
        className='tooltip-higher-zindex'
        style={{borderRadius:'4px', marginLeft:'11vw', border:'solid #145388 .1vh',fontFamily:'Montserrat',zIndex:100, backgroundColor:'white', color:'#145388', fontSize:'2vh', width:'26vw', textAlign:'center', padding:'2vh'}}
        >
            To hide or unhide this section, click on the eye icon
        </Tooltip>
        <div id="TooltipExample2" onMouseOver={toggleTooltipSecond} onMouseOut={toggleTooltipSecond}>

            <div className={data.sections.residencyApplication?"screened-sections-bar-item":"screened-sections-bar-item screened"} 
            style={currentSection==='residencyApplication'?selectedDesign:unselectedDesign}
            onClick={()=>handleSectionChange('residencyApplication')}>
        <div className="step-indicator-number">2</div>
            <label style={{cursor:'pointer'}} onClick={()=>setCurrentSection('residencyApplication')}>Residency Application</label>
            <img src={data.sections.residencyApplication ? openEye : eyeClosed} alt="eye icon" onClick={()=>setData({...data, sections:{...data.sections, residencyApplication:!data.sections.residencyApplication}})}/>
            </div>
        </div>
        
        <Tooltip placement="top" 
        isOpen={tooltipOpenThird}  
        target="TooltipExample3" 
        toggle={toggleTooltipThird}
        className='tooltip-higher-zindex'
        style={{borderRadius:'4px', marginLeft:'11vw', border:'solid #145388 .1vh',fontFamily:'Montserrat',zIndex:100, backgroundColor:'white', color:'#145388', fontSize:'2vh', width:'26vw', textAlign:'center', padding:'2vh'}}
        >
            To hide or unhide this section, click on the eye icon
        </Tooltip>
        <div id="TooltipExample3" onMouseOver={toggleTooltipThird} onMouseOut={toggleTooltipThird}>


            <div 
            className={data.sections.personalStatement?"screened-sections-bar-item":"screened-sections-bar-item screened"} 
            style={currentSection==='personalStatement'?selectedDesign:unselectedDesign}
            onClick={()=>handleSectionChange('personalStatement')}>
        <div className="step-indicator-number">3</div>
            <label style={{cursor:'pointer'}} onClick={()=>setCurrentSection('personalStatement')}>Personal Statement</label>
            <img src={data.sections.personalStatement ? openEye : eyeClosed} alt="eye icon" onClick={()=>setData({...data,sections:{ ...data.sections,personalStatement:!data.sections.personalStatement}})}/>
            </div>
        </div>        

        <Tooltip placement="top" 
        isOpen={tooltipOpenForth}  
        target="TooltipExample4" 
        toggle={toggleTooltipForth}
        className='tooltip-higher-zindex'
        style={{borderRadius:'4px', marginLeft:'11vw', border:'solid #145388 .1vh',fontFamily:'Montserrat',zIndex:100, backgroundColor:'white', color:'#145388', fontSize:'2vh', width:'26vw', textAlign:'center', padding:'2vh'}}
        >
            To hide or unhide this section, click on the eye icon
        </Tooltip>
        <div id="TooltipExample4" onMouseOver={toggleTooltipForth} onMouseOut={toggleTooltipForth}>


            <div 
            className={data.sections.documents?"screened-sections-bar-item":"screened-sections-bar-item screened"} 
            style={currentSection==='documents'?selectedDesign:unselectedDesign}
            onClick={()=>handleSectionChange('documents')}>
        <div className="step-indicator-number">4</div>
            <label style={{cursor:'pointer'}} onClick={()=>setCurrentSection('documents')}>Documents</label>
            <img src={data.sections.documents ? openEye : eyeClosed} alt="eye icon" onClick={()=>setData({...data, sections:{...data.sections,documents:!data.sections.documents} })}/>
            </div>
        </div>


        <Tooltip placement="top" 
        isOpen={tooltipOpenFifth}  
        target="TooltipExample5" 
        toggle={toggleTooltipFifth}
        className='tooltip-higher-zindex'
        style={{borderRadius:'4px', marginLeft:'11vw', border:'solid #145388 .1vh',fontFamily:'Montserrat',zIndex:100, backgroundColor:'white', color:'#145388', fontSize:'2vh', width:'26vw', textAlign:'center', padding:'2vh'}}
        >
            To hide or unhide this section, click on the eye icon
        </Tooltip>
        <div id="TooltipExample5" onMouseOver={toggleTooltipFifth} onMouseOut={toggleTooltipFifth}>


            <div 
            className={data.sections.lors?"screened-sections-bar-item":"screened-sections-bar-item screened"} 
            style={currentSection==='lors'?selectedDesign:unselectedDesign}
            onClick={()=>handleSectionChange('lors')}>
        <div className="step-indicator-number">5</div>
            <label style={{cursor:'pointer'}} onClick={()=>setCurrentSection('lors')}>Letters of Recommendation</label>
            <img src={data.sections.lors ? openEye : eyeClosed} alt="eye icon" onClick={()=>setData({...data,sections:{...data.sections,lors:!data.sections.lors} })}/>
            </div>


    </div>
    </div>


    )};

    export default ScreenedSectionsBar;