import React, { useState } from 'react';
import logo from '../../assets/imgs/logo.svg';



const ResponseModal = ({ isOpen, onClose,body }) => {
  

  return (
    <div className="modal-backdrop" style={{ zIndex: '25' }}>
      <div className="add-field-modal" style={{ height: '25vh', overflow: 'unset', width:'50vw', maxHeight:'100vh' }}>
        {/* <div className="add-field-modal-header"> */}
          {/* <h2>Import Applications</h2> */}
          {/* <img src={logo} alt="Logo" className="logo" style={{ width: '4vw' }} /> */}
        {/* </div> */}
        
        <div className="faculty-home-body" style={{gap:'0'}}>
            <p style={{fontWeight:'500', fontSize:'18px'}}>{body}</p>
          </div>
        <div className="add-field-modal-footer" style={{width:'100%', justifyContent:'center',display:'flex', marginTop:'5vh'}}>
            <button className="green-button" onClick={onClose}>
                Close
            </button>
        </div>
      </div>
    </div>
  );
};

export default ResponseModal;
