import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/imgs/logo.svg';
import backIcon from '../../assets/imgs/backIcon.svg'; // Import the backIcon image
import './index.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PhoneInputComponent from './phoneComponent';
import  Spinner  from '../../components/common/spinner';
import packageInfo from '../../../package.json';

const ContactUs = ({isNested, user, programData}) => {
    const navigate = useNavigate();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [userRole, setUserRole] = useState('');
    const [loading, setLoading] = useState(false);
    const formikRef = useRef();



    useEffect(() => {
        if(user) {
            console.log("user in contact us =>", user)
            if (user.userType === 'applicant') {
                setUserRole('Applicant');
            } else if (user.userType === 'faculty') {
                if (user.userPremissions.includes('PD')) {
                    setUserRole('Program Director');
                }
                else if (user.userPremissions.includes('PC')) {
                    setUserRole('Program Coordinator');
                }
                else if (user.userPremissions.includes('Reviewer')) {
                    setUserRole('Reviewer');
                }
                else if (user.userPremissions.includes('Recommender')) {
                    setUserRole('Recommender');
                }
                
            } else if (user.userType === 'medSchool') {
                setUserRole('Medical School Administrator');
            }
        }
    }, [user]);

    useEffect(() => {
        if (userRole && formikRef.current) {
          formikRef.current.setFieldValue("role", userRole, false);
        }
      }, [userRole, formikRef]);




    const submitForm = (values) => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_SERVER_URL}/send_support_ticket`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', 
                'App-Version': packageInfo.version
            },
            body: JSON.stringify(values) // Ensure you are sending the values directly
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network error, please try again');
            }
            setLoading(false);
            return response.json(); // Assuming the server responds with JSON
        })
        .then(data => {
            setLoading(false);
            setIsSubmitted(true);
        })
        .catch((error) => {
            setLoading(false);
            console.error("Error:", error);
            alert("Failed to send email. Please try again later."); // Or use a more sophisticated method to display the error
        });
    }

    const validationSchema = Yup.object().shape({
        full_name: Yup.string().required('Name is required'),
        email: Yup.string().email('Invalid email format').required('Email is required'),
        phone_number: Yup.string().required('Phone is required'),
        question: Yup.string().required('Message is required'),
    });

    const handleOnChange = (value) => {
        console.log("phone value =>", value)
    }
    const fieldStyle = {
        width: '39vw',
        color:'gray'
    }

    const upperStyle = {
gap:'1vw'
}




    return (
        <div className='faculty-home-container' style={isNested?{width:'100%', paddingTop:'0'}:{width:'100%',marginLeft:'auto',marginRight:'auto', paddingLeft:'2vw'}}>
            <ellipse style={{ position: 'absolute', width: '134px', height: '126px', left: '1319px', top: '-17px', background: '#2a93d5', filter: 'blur(118.5px)' }} />
            {loading&&
            <Spinner/>}
            {!isNested&& !loading&&
            <>
            <div className="top-faq">
                <p className='back-title' onClick={() => navigate('/')}><img src={backIcon} alt="back" style={{ marginRight: '0.8vw'}}/> Back</p>
                <img src={logo} alt="logo" style={{float:'left', marginRight: '2vw'}}/>
            </div>
            <p className='header-title'>Send Us Your Requests</p>
            {/* <p style={{fontSize:'18px'}}> You can also contact us via e-mail at <a style={{color:'#145388', fontWeight:'600', textDecoration:'underline'}} href='mailto:help@accessgme.com'>help@accessgme.com</a></p> */}
            <p className='middle-title'>We would love to respond to your queries and help you. Feel free to get in touch with us.</p>
            </>
            }
            {isNested&& !loading&&
            <>
             <p className='header-title' style={{marginTop:'0'}}>Open a Support Ticket</p>
             {/* <p style={{fontSize:'18px'}}> You can also contact us via e-mail at <a style={{color:'#145388', fontWeight:'600', textDecoration:'underline'}} href='mailto:help@accessgme.com'>help@accessgme.com</a></p> */}
            </>
            }
            
            <seperator className="applicant-home-header-seperator" style={{width: '89vw'}}/>
        {!isSubmitted&&
            <Formik
            innerRef={formikRef}
                validationSchema = {validationSchema}
                onSubmit={(values, actions) => submitForm(values)}
                initialValues = {{
                    full_name: isNested?user.firstName+' '+user.lastName:'',
                    role:userRole,
                    specialty:isNested&&programData?programData.getProgramById.specialtyId:'',
                    program:isNested&&programData?programData.getProgramById.id:'',
                    mainSubject:'',
                    email: isNested?user.email:'',
                    phone_number: isNested?user.phone:'',
                    question: ''
                }}
            >
            {({ handleSubmit, values, setFieldValue}) => (
                    <Form onSubmit={()=>submitForm(values)} style={{position:'relative'}}>
                        <div className='upper-form' style={isNested?{upperStyle}:{}}>
                            <div className='step1-upper-right-inner-container' style={{ width: '50%'}}>
                                <div className='application-form-field-group'>
                                <Field name="full_name" type="name" disabled={isNested} placeholder="Name (required)" style={isNested?fieldStyle:{}} className='contact-us-form-standard-input' />
                                <div className='application-form-error-message'> 
                                <ErrorMessage name="name" component="div" />
                                </div>
                                </div>
                            </div>
                            <div className='step1-upper-right-inner-container' style={{ width: '50%'}}>
                                <div className='application-form-field-group'>
                                <Field name="email" type="email" placeholder="Email (required)" disabled={isNested} style={isNested?fieldStyle:{}}  className='contact-us-form-standard-input' />
                                <div className='application-form-error-message'> 
                                <ErrorMessage name="email" component="div" />
                                </div>
                                </div>
                            </div>
                        </div>
                        

{!isNested&& !loading&&
                        <div className='upper-form' style={isNested?{upperStyle}:{}}>
                            <div className='step1-upper-right-inner-container' style={{ width: '50%'}}>
                                <div className='application-form-field-group'>
                                <Field as="select" name="role" style={isNested?fieldStyle:{}}  className='contact-us-form-standard-input'>
                                    <option value="">Select Your Role</option> 
                                    <option value="Applicant">Applicant</option>
                                    <option value="Program Director">Program Director</option>
                                    <option value="Program Coordinator">Program Coordinator</option>
                                    <option value="Medical School Administrator">Medical School Administrator</option>
                                    <option value="Reviewer">Reviewer</option>
                                    <option value="Recommender">Recommender</option>
                                </Field>
                                <div className='application-form-error-message'> 
                                <ErrorMessage name="name" component="div" />
                                </div>
                                </div>
                            </div>
                        <div className='application-form-field-group' style={{  width: '50%'}}>
                            <PhoneInputComponent isNested={isNested} phone={values.phone_number} handleOnChange={(val) => { setFieldValue("phone_number", val)}}/>
                        </div>
                        </div>
}

                        {!isNested && !loading&& values.role !== 'Medical School Administrator' && values.role !==""  &&
                        <div className='upper-form' style={isNested?{upperStyle}:{}}>
                              <div className='step1-upper-right-inner-container' style={{ width: '50%'}}>
                            <div className='application-form-field-group'>
                            <Field as="select"  style={isNested?fieldStyle:{}}  name="specialty" className='contact-us-form-standard-input'>
                                <option value="">Select Specialty</option> 
                                <option value="Plastic Surgery">Plastic Surgery</option>
                                <option value="Anesthesiology">Anesthesiology</option>
                            </Field>
                            </div>
                              </div>

                            <div className='step1-upper-right-inner-container' style={{ width: '50%'}}>
                            <div className='application-form-field-group'>
                            <Field name="program" type="text" placeholder="Program" style={isNested?fieldStyle:{}} className='contact-us-form-standard-input' />
                            </div>
                            </div>
                        </div>
                        }

            
            
                        <div className='application-form-field-group' >
                        <Field as="select"  style={isNested?{width:'79vw'}:{}}  name="mainSubject" className='contact-us-form-standard-input'>
                            <option value="">What is your concern regarding?</option> 
                            <option value="Accessing account">Accessing account</option>
                            <option value="Other">Other</option>
                            {values.role === 'Applicant' && (
                                <>
                                    <option value="Application deadlines">Application deadlines</option>
                                    <option value="Application content">Application content</option>
                                    <option value="Letters of recommendation">Letters of recommendation</option>
                                    <option value="Transcript or MSPEs">Transcript or MSPEs</option>
                                    <option value="Standardized scores">Standardized scores</option>
                                </>
                            )}
                            {['Program Director', 'Program Coordinator'].includes(values.role) && (
                                <>
                                    <option value="Managing my team">Managing my team</option>
                                    <option value="Reviewing applications">Reviewing applications</option>
                                    <option value="Letters of recommendation">Letters of recommendation</option>
                                </>
                            )}
                            {['Reviewer', 'Recommender'].includes(values.role) && (
                                <>
                                    <option value="Reviewing application">Reviewing application</option>
                                    <option value="Letters of recommendation">Letters of recommendation</option>
                                </>
                            )}
                            {values.role === 'Medical School Administrator' && (
                                <>
                                    <option value="Applicant Tokens">Applicant Tokens</option>
                                    <option value="Uploading documents">Uploading documents (MSPEs or transcripts)</option>
                                </>
                            )}


                        </Field>
                        </div>


                        <div className='application-form-field-group' >
                        <Field
                            as="textarea"
                            id="question"
                            name="question"
                            placeholder="Your message here..."
                            className='text-form-standard-input' 
                            style={isNested?{width:'79vw', border: '1px solid #dbdbdb'}:{border: '1px solid #dbdbdb'}}
                            //     
                            />
                            <ErrorMessage
                            name="message"
                            component="div"
                            style={{ color: 'red', marginTop: '5px' }}
                            />
                        </div>

                        <button  className='green-button' disabled={loading}  type="submit" style={isNested?{width:'100%', marginTop: '1.5vh'}:{marginTop: '1.5vh'}}>Send</button>
                    </Form>
            )}
            </Formik>
}
{isSubmitted&&
<h2 style={{textAlign:'center', fontSize:'5vh', color:'#145388', fontFamily:'Montserrat'}}>
    Your message has been sent successfully.
</h2>



}
        </div>
    )
}


export default ContactUs;