import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/imgs/logo.svg';
import backIcon from '../../assets/imgs/backIcon.svg'; // Import the backIcon image
import greenArrowDown from '../../assets/imgs/greenArrowDown.svg'; // Import the greenArrowDown image
import './index.css';
import DOMPurify from 'dompurify';

const FAQS = () => {

    const navigate = useNavigate();
    const questions = [
        {
            isOpen: false,
            outer: "What is CentralApp?",
            inner: `<div class="inner-container">CentralApp is an innovative residency application platform designed to improve equity and enhance holistic review. Developed by medical educators, CentralApp offers a modern, customizable interface that streamlines the application process, making it more efficient for both applicants and reviewers. The platform reduces application expenses by over 80% from alternative residency application services. It supports specialty and program-specific content while maintaining a condensed, digestible format, significantly reducing review time.</div>`
        }, 
        {
            isOpen: false,
            outer: "What are important dates for the CentralApp?",
            inner: `<div class="inner-container">The application will be released to programs starting on <strong>September 16th.</strong><br /> MSPEs will be released on <strong>September 25th</strong> in accordance with AAMC guidelines. Medical schools can submit the MSPE anytime in advance of this deadline, but it will not be viewable to programs until <strong>September 25th.</strong> </div>`
        },
        {
            isOpen: false,
            outer: "How was the Smart Recruiting developed?",
            inner: `<div class="inner-container">CentralApp began as a pilot project for Plastic Surgery applications (once called PSCA = Plastic Surgery Common Application). It was developed by a team of program directors, faculty from multiple institutions, residents, and medical students, leveraging their collective expertise and firsthand experience with the residency application process. Over the past five years, the application was created, piloted, and continuously revised to ensure it was user-friendly and efficient.  This collaborative effort focused on reducing application costs and improving the application review process, resulting in a platform that significantly decreases review time and enhances holistic application review.</div>`
        },
        {
            isOpen: false,
            outer: "Why was the Smart Recruiting created?",
            inner: `<div class="inner-container">CentralApp was created in response to growing concerns from both applicants and residency programs about the existing platform, the Electronic Residency Application Service (ERAS). Between 2011 and 2021, ERAS generated almost $1 billion in revenue from application fees (https://www.americanjournalofsurgery.com/article/S0002-9610(24)00236-8/abstract). On a per applicant basis, the average cost of applying to ERAS exceeded $2000 in 2021. This financial stress serves as a barrier to equity in the application process, particularly affecting students from disadvantaged backgrounds.  Therefore, a primary goal of CentralApp has always been to improve equity within the application process by eliminating these financial barriers. Additionally, CentralApp aims to address program concerns by offering a customizable, specialty-specific application that includes pertinent data and focused questions that help programs select the right applicants. By streamlining the application process and drastically reducing the time required for screening and reviewing, CentralApp emphasizes holistic review. By reducing application expenses by over 80% and providing a more efficient review process, CentralApp seeks to enhance the overall experience for both applicants and residency programs.</div>`
        },
        {
            isOpen: false,
            outer: "What specialties and programs are using CentralApp this year?",
            inner: `<div class="inner-container">CentralApp is being used specialty-wide in plastic surgery, and has been endorsed by the American Council of Educators in Plastic Surgery. CentralApp is also being used by a growing number of anesthesiology programs. For the most up to date information, please login to your applicant account where you can find a list of programs.</div>`
        },
        {
            isOpen: false,
            outer: "How does your program allow institutions to be EEOC compliant with regards to restricted demographic data, such as race, gender, etc.?",
            inner: `<div class="inner-container">Consistent with EEOC requirements, the Smart Recruiting allows for blinding of any demographic data during review and reporting of these demographic data for administrative oversight.</div>`
        },
        {
            isOpen: false,
            outer: "What filters are available to manage the database?",
            inner: `<div class="inner-container">The database can be filtered by any component or data element within the application.`
        },
        {
            isOpen: false,
            outer: "How do you allow users to create search fields?",
            inner: `<div class="inner-container">Users can search the database by any component within the application.</div>`
        },
        {
            isOpen: false,
            outer: "How do you verify submitted data?",
            inner: `<div class="inner-container"><span class="title-bold">Letters of recommendation</span><br/>
            Letters of recommendation - The Smart Recruiting is the only application platform that verifies authenticity through letter writers. Letter writers are verified internally through the source of the request.<br/><br/>
            <span class="title-bold">MSPE/Transcripts</span><br/>
            All applicants are verified by medical schools using a token system prior to beginning the application.  Medical schools will then have direct access to upload all relevant documents for their students.</div>`
        },
        {
            isOpen: false,
            outer: "Describe the support you provide to applicants and residency programs.",
            inner: `<div class="inner-container">We have both product and tech support available with an average response time of less than 24 hours. Any questions about application advice or details of the application process need to be directed to a mentor or a representative of a specialty society.</div>`
        },
    ]
    const [ allQuestions, setAllQuestions ] = useState(questions)

    const itemClicked = (index) => {
        let cpy = [...allQuestions]
        cpy[index].isOpen = !cpy[index].isOpen
        setAllQuestions(cpy)
    }

    const HtmlRenderer = ({ htmlString }) => {
        const createMarkup = (html) => {
            const cleanHtml = DOMPurify.sanitize(html, {
                ALLOWED_ATTR: ['class', 'className' ,'id', 'style', 'href', 'src', 'alt', 'title'], // Allow necessary attributes
            });
            return { __html: cleanHtml };
        };
    
        return (
            <div dangerouslySetInnerHTML={createMarkup(htmlString)} />
        );
    };
    const openStyle = {
        transform: 'rotate(180deg)'
    }
    

    return (
        <div className='faculty-home-container' style={{width:'100%',marginLeft:'auto',marginRight:'auto', paddingLeft:'2vw'}}>
            <ellipse style={{ position: 'absolute', width: '134px', height: '126px', left: '1319px', top: '-17px', background: '#2a93d5', filter: 'blur(118.5px)' }} />
            <div className="top-faq">
                <p className='back-title' onClick={() => navigate('/')}><img src={backIcon} alt="back" style={{ marginRight: '0.8vw'}}/> Back</p>
                <img src={logo} alt="logo" style={{float:'left', marginRight: '2vw'}}/>
            </div>
            <p className='header-title'>Open Application Background and FAQs</p>
            {
                allQuestions.map((question, index) => (
                    <div>
                        <div className='question-container'>
                            <div className='outer-container'>{question.outer}</div>
                            <div className='arrow-container' onClick={() => itemClicked(index)}><img style={question.isOpen?openStyle:{}} src={question.isOpen ?  greenArrowDown : greenArrowDown} alt="arrow"/></div>
                        </div>
                        {
                            question.isOpen &&  <div className='inner-title'><HtmlRenderer htmlString={question.inner} /></div>
                        }
                        
                    </div>
                ))
            }

            
        </div>
    );
}

export default FAQS;
