import React,{useState, useContext, useEffect} from "react";
import "./index.css";
import InviteUserModal from "./inviteUserModal";
import TeamMembersTable from "./teamMembersTable";
import UpdateRoleModal from "./updateRoleModal";
import { ProgramIdContext } from "../../progIdContext";
import {useQuery, useMutation} from '@apollo/client';
import {GET_PROGRAM_USERS_BY_PROGRAM_ID} from './graphql/queries';
import { REMOVE_PROGRAM_MEMBER, UPDATE_PROGRAM } from "./graphql/mutations";
import dropdownArrowIcon from '../../../../assets/imgs/DropdownArrowIcon.svg'
import Spinner from "../../../common/spinner";
import packageInfo from '../../../../../package.json';

const roleDisplayNames = {
    'PD': 'Program Director',
    'PC': 'Program Coordinator',
    'Reviewer': 'Reviewer',
    'Recommender': 'Recommender'
};

const transformRoles = (data) => {
    return data.map(item => {
        const userId = item.userId || {};
        return {
            id: userId.id,
            userName: userId.firstName ? userId.firstName + ' ' + userId.lastName : '',
            email: userId.email,
            roles: item.roles,
            roleDisplayNames: Array.isArray(item.roles) ? item.roles.map(role => roleDisplayNames[role]).join(', ') : '',
            isPending: userId.isPending
        };
    });
}

const MyTeam = () => {
    const programId = useContext(ProgramIdContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editingUser, setEditingUser] = useState(null);
    const [sort, setSort] = useState(JSON.parse(sessionStorage.getItem('sort')) || { field: 'lastName', direction: 'asc'});
    const [openMenuRowId, setOpenMenuRowId] = useState(null);
    const [removeProgramMember] = useMutation(REMOVE_PROGRAM_MEMBER);
    const [updateProgram] = useMutation(UPDATE_PROGRAM);

    const { loading, error, data: rawData, refetch } = useQuery(GET_PROGRAM_USERS_BY_PROGRAM_ID, {
        variables: { programId, sort },
    });
    
    useEffect(() => {
        if (!loading && !error && rawData) {
            setData(transformRoles(rawData.getProgramMembersByProgramIdWithSort));
        }
    }, [loading, error, rawData]);



    const [data, setData] = useState(rawData ? transformRoles(rawData.getProgramMembersByProgramIdWithSort) : []);


    const handleRemind = (user, id) => {
        console.log("send reminder!", user)
        fetch(`${process.env.REACT_APP_SERVER_URL}/send_invitation_reminder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'App-Version': packageInfo.version
            },
            body: JSON.stringify({
              user
            })
        })
        handleMenuToggle(id)
    }

    const handleMenuToggle = (rowId) => {
        setOpenMenuRowId((prev) => (prev === rowId ? null : rowId));
      };

    const columns = [
    {
        Header: 'id',
        accessor: 'id',
    },
        {
            Header: 'Name',
            accessor: 'userName',
        },
        {
            Header: 'Email Address',
            accessor: 'email',
        },
        {
            Header: 'Roles',
            accessor: 'roleDisplayNames',
        },
        {
            Header: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div className="dropdown" style={{border:'0',margin:'0',padding:'0'}}>
                <button className="white-button dropdown-toggle"  onClick={() => handleMenuToggle(row.id)}>
                    Manage 
                </button>
                {openMenuRowId === row.id && (
                    <div className="dropdown-menu team" style={{zIndex:'3', right:'-.5vw', textAlign: 'center'}}>
                        {row.original.isPending === true && <div className="dropdown-item" style={{ cursor: "pointer", padding:'1vh 2vw' }} onClick={() => handleRemind(row.original, row.id)}>Remind</div> }
                        <div className="dropdown-item team" style={{ cursor: "pointer" }} onClick={() => handleEdit(row.original, row.id)}>Edit</div>
                        <div className="dropdown-item team" style={{ cursor: "pointer"}} onClick={() => handleDelete(row.original, row.id)}>Delete</div>
                    </div>
                )}
                </div>
                // <div style={{display:'flex', flexDirection:'row', gap:'.2vw', marginRight:'.3vw'}}>
              
                // </div>
            ),
        },
        {
            Header:'isPending',
            accessor:'isPending',   
        }
    ]

    const handleEdit = (rowData, id) => {
        setEditingUser(rowData);
        handleMenuToggle(id)
        setIsEditModalOpen(true);
    }
    
    const handleDelete = async (rowData, id) => {
        console.log('Deleting user:', rowData);
        // Execute the mutation
        try {
            const response = await removeProgramMember({ variables: { programId:programId, userId:rowData.id} });
            refetch();
            handleMenuToggle(id)
        } catch (error) {
            console.error('Failed to delete program member:', error);
        }
    }

    
    const handleAddUser = () => {
        console.log('inside handleAddUser performing refetch')
        refetch();
    };

    const handleEditUser = async (editedUser) => {
        setData(prevData => {
            const updatedData = prevData.map(user => {
                if (user.email === editingUser.email) {
                    return {
                        ...user,
                        ...editedUser,
                        roleDisplayNames: editedUser.roles.map(role => roleDisplayNames[role]).join(', ')
                    };
                } else {
                    return user;
                }
            });
        
            // Transform the updated data into the desired format
            const members = updatedData.map(user => ({
                userId: user.id,
                roles: user.roles
            }));
        
            // Call the mutation here with the updated members
            updateProgram({ variables: { id: programId, input: { members } } })
            .then(response => {
                console.log('Update program response:', response);
                setEditingUser(null);
                setIsEditModalOpen(false);
            })
            .catch(error => {
                console.error('Failed to update program:', error);
            });
        
            return updatedData;
        });

    };

    const sortTeam = (column, direction) => {
        console.log("column in the sortApplications function=>", column)
        setSort({ field: column, direction: direction === true ? "asc": "desc"})
        refetch()
    }

    return (
        <div className='faculty-home-container'>
            <ellipse className='ellipseDesignTop1' />
            {/* <ellipse className='ellipseDesignTop2' /> */}
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="faculty-home-header">
                <h2 className="faculty-home-header-title">MY TEAM</h2>
                <p className="faculty-home-header-description">Manage your team here. Invite new members, manage roles, and create verified recommenders!</p>
                <button style={{float:'right'}} className="green-button" onClick={() => setIsModalOpen(!isModalOpen)}>Invite a Member +</button>
            </div>
            <seperator style={{marginTop:'6.5vh'}} className="faculty-home-header-seperator"/>

            {loading && <Spinner />}
            {error && <p>Error: {error.message}</p>}
            <TeamMembersTable columns={columns} data={data} sortData={sortTeam}/>
            <InviteUserModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onAddUser={handleAddUser}/>
            {editingUser && <UpdateRoleModal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} user={editingUser} onEditUser={handleEditUser}/>}
           
        </div>
    );
}

export default MyTeam;

