import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import logo from '../../../assets/imgs/logo.svg';
import { useQuery } from "@apollo/client"; 
import {useDropzone} from 'react-dropzone';
import { getScoresByIdQuery } from './graphql/queries';
import client from './../../../config/apolloClient';
import { updateScoreMutation } from './graphql/mutations';
import Spinner from '../../common/spinner';
import packageInfo from '../../../../package.json';

const ScoresUploadVerify = () => {
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop: async (acceptedFiles) => {
            console.log("acceptedFiles", acceptedFiles)

            const file = acceptedFiles[0];
            console.log("file =>", file)
            if(file) {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('scoreId', id);
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/upload_score_file`, {
                  method: 'POST',
                  headers: { 'App-Version': packageInfo.version },
                  body: formData
                });
                const data = await response.json();
                
            }
        },
        accept: 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document' ,
        multiple: false  // This ensures that multiple file selection is disabled

    });

    const [score, setScore] = useState({})

    const { id } = useParams();

    const { data: scoreData, error: scoreError, loading: scoreLoading } = useQuery(getScoresByIdQuery, {
        variables: {
            id
           
        },
    });

    if (scoreLoading) return <Spinner />;
    if (scoreError) return <p>Error :(</p>;
    else {
        const scoreFromQuery = scoreData.getScoreById
        console.log("scoreFromQuery =>", scoreFromQuery)
        if(JSON.stringify(scoreFromQuery) !== JSON.stringify(score)) {
            setScore(scoreFromQuery)
            console.log("score from query =>",scoreFromQuery)
        }
    }

    console.log("id =>", id)


    const verifyScores = () => {
        client.mutate({
            mutation: updateScoreMutation,
            variables: {
                id,
                input: {
                    scoresVerified: true
                }
            }
        })
    }


   return (
    <div className='faculty-home-container' style={{width:'100%',marginLeft:'auto',marginRight:'auto'}}>
           <ellipse className='ellipseDesignTop1' />
        <ellipse className='ellipseDesignTop2' />
        <ellipse className='ellipseDesignTop3' />
        <ellipse className='ellipseDesignTop4' />
        <div className="faculty-home-header">
            <img src={logo} alt="logo" style={{float:'right'}}/>
            <h2 className="faculty-home-header-title">UPLOAD & VERIFY SCORES</h2>
            {/* <p className="faculty-home-header-subtitle"> Please fill in you details below to finalize your account creation</p> */}
        </div>
        <seperator className="faculty-home-header-seperator"/>
        <div style={{ display: 'flex', flexDirection: 'column'}}>
        <p className="specific-rec-title" style={{ float: 'left', marginBottom: '3vh'}}>Please verify scores:</p>
        <p className="specific-rec-title" style={{ float: 'left', marginBottom: '3vh'}}>USMLE Step 1: {score && score.usmleStep1Score}</p>
        <p className="specific-rec-title" style={{ float: 'left', marginBottom: '3vh'}}>USMLE Step 2 CK: {score && score.usmleStep2Score}</p>
        <p className="specific-rec-title" style={{ float: 'left', marginBottom: '3vh'}}>USMLE Step 3: {score && score.usmleStep3Score}</p>
        <p className="specific-rec-title" style={{ float: 'left', marginBottom: '3vh'}}>COMLEX-USA Level 1: {score && score.comlexLevel1Score}</p>
        <p className="specific-rec-title" style={{ float: 'left', marginBottom: '3vh'}}>COMLEX-USA Level 2 CE: {score && score.comlexLevel2Score}</p>
        <div className="faculty-home-body" style={{ marginBottom: '2vh'}}>
            <button className='green-button' style={{padding:'1vh 1vw', width:'76vw'}} onClick={verifyScores}>Verify Scores</button>
        </div>
        <div className="faculty-home-body">
            <p className="specific-rec-title">Please upload your Scores file here</p>

            <div className={ isDragActive ?'file-drop-zone hover':"file-drop-zone"} {...getRootProps()}>
                <input multiple={false}/>
                {
                    isDragActive ?
                    <p>Drop to upload the file</p> :
                    <p>Drag & drop your scores file here, or click to select files</p>
                }
            </div>
                <div className="specific-rec-submit">
                    <button className='green-button' style={{padding:'1vh 1vw', width:'76vw'}}>Upload Scores</button>
                </div>
        </div>

        </div>
    </div>
   )




}


export default ScoresUploadVerify;