import React,{useState} from 'react';
import scoreIconGreen from '../../../../../assets/imgs/scoreIconGreen.svg';
import greenCheck from '../../../../../assets/imgs/greenCheck.svg';
import redX from '../../../../../assets/imgs/redX.svg';
import genderIcon from '../../../../../assets/imgs/genderIcon.svg';
import usCitIcon from '../../../../../assets/imgs/usCitIcon.svg';
import couplesIcon from '../../../../../assets/imgs/couplesIcon.svg';
import previousApplicant from '../../../../../assets/imgs/previousApplicant.svg';
import misdemeanourIcon from '../../../../../assets/imgs/misdemeanourIcon.svg';
import malpracticeIcon from '../../../../../assets/imgs/malpracticeIcon.svg';
import languagesIcon from '../../../../../assets/imgs/languagesIcon.svg';
import {genderDataValues} from './dataValuesConvert';


const ApplicationViewMainDemographics = ({application, screenedData}) => {
const yesDisplay = <div className='yes-display'><img className="img-yes" src={greenCheck} alt="Yes" />Yes</div> ;
const noDisplay = <div className='no-display'><img className="img-no" src={redX} alt="No" />No</div> ;



console.log('applicationUsCitz', application);

// consvert gender to label
const genderItem = genderDataValues.find(item => item.value === application.gender);
const genderLabel = genderItem ? genderItem.label : application.gender;

    return (
<div className="application-view-main-demographics">
<div className="application-view-main-demographics-header">
    <h2 className="application-view-main-title">Personal Information</h2>
    <seperator className="application-view-main-demographics-seperator"/>
</div>
<div className="application-view-main-demographics-body">
   
    {/* {
        screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
        screenedData.personalInfo.preventResponsibilities !== null && screenedData.personalInfo.preventResponsibilities !== undefined && screenedData.personalInfo.preventResponsibilities === true &&
        <div className="demographics-item">
            <img src={scoreIconGreen} alt="Icon" width="20" />
            <div className="demographics-label">Ability to carry out responsibilities:</div>
            <div className="demographics-value">{!application.carryingOutResponsibilities ? yesDisplay : noDisplay}</div>
        </div>
    } */}
    {/* {
        screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
        screenedData.personalInfo.raceEthnicity !== null && screenedData.personalInfo.raceEthnicity !== undefined && screenedData.personalInfo.raceEthnicity === true &&
        <div className="demographics-item">
            <img src={genderIcon} alt="Icon" width="20" />
            <div className="demographics-label">Race/Ethnicity</div>
            <div className="demographics-value" style={{textAlign:'right'}}>{application.ethnicity.join(', ')}</div>
        </div>
    } */}

    {/* {
        screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
        screenedData.personalInfo.hometown !== null && screenedData.personalInfo.hometown !== undefined && screenedData.personalInfo.hometown === true &&
        <div className="demographics-item">
            <img src={scoreIconGreen} alt="Icon" width="20" />
            <div className="demographics-label" style={{alignSelf:'flex-start'}}>Hometown:</div>
            <div className="demographics-value" style={{maxWidth:'20vw', width:'15vw', textAlignLast:'right'}}>{application.hometown}</div>
        </div>
    } */}
    {/* {
        screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
        screenedData.personalInfo.malpracticeLawsuit !== null && screenedData.personalInfo.malpracticeLawsuit !== undefined && screenedData.personalInfo.malpracticeLawsuit === true &&
        <div className="demographics-item">
            <img src={malpracticeIcon} alt="Icon" width="20" />
            <div className="demographics-label">Malpractice Lawsuit:</div>
            <div className="demographics-value">{application.user.malpractice ? yesDisplay: noDisplay}</div>
        </div>
    } */}
    {/* {
        screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
        screenedData.personalInfo.couplesMatch !== null && screenedData.personalInfo.couplesMatch !== undefined && screenedData.personalInfo.couplesMatch === true &&
        <div className="demographics-item">
            <img src={couplesIcon} alt="Icon" width="20" />
            <div className="demographics-label">Couple's Match:</div>
            <div className="demographics-value">{application.coupleMatch==='Yes' ? yesDisplay : noDisplay}</div>
        </div>
    } */}
    {/* {
        screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
        screenedData.personalInfo.couplesMatch !== null && screenedData.personalInfo.couplesMatch !== undefined && screenedData.personalInfo.couplesMatch === true &&
        <div className="demographics-item">
            <img src={couplesIcon} alt="Icon" width="20" />
            <div className="demographics-label">Partner's Field:</div>
            <div className="demographics-value">{application.partnerField}</div>
        </div>
    } */}
    {/* {
        screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
        screenedData.personalInfo.couplesMatch !== null && screenedData.personalInfo.couplesMatch !== undefined && screenedData.personalInfo.couplesMatch === true &&
        <div className="demographics-item">
            <img src={couplesIcon} alt="Icon" width="20" />
            <div className="demographics-label">Partner's Name:</div>
            <div className="demographics-value">{application.partnerName}</div>
        </div>
    } */}

{
        screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
        screenedData.personalInfo.genderIdentity !== null && screenedData.personalInfo.genderIdentity !== undefined && screenedData.personalInfo.genderIdentity === true &&
        <div className="demographics-item">
            {/* <img src={genderIcon} alt="Icon" width="20" /> */}
            <div className="demographics-label">Gender Identity:</div>
            <div className="demographics-value">{genderLabel}</div>
        </div>
    }


    
        <div className="demographics-item">
            {/* <img src={misdemeanourIcon} alt="Icon" width="20" /> */}
            <div className="demographics-label">Misdemeanor or Felony:</div>
            <div className="demographics-value">{application.misdemeanorOrFelony ? yesDisplay:noDisplay}</div>
        </div>
    {
        screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
        screenedData.personalInfo.workAuthorization !== null && screenedData.personalInfo.workAuthorization !== undefined && screenedData.personalInfo.workAuthorization === true &&
        <div className="demographics-item">
            {/* <img src={usCitIcon} alt="Icon" width="20" /> */}
            <div className="demographics-label">Work Authorization:</div>
            <div className="demographics-value" style={{width:'16vw'}}>{application.workAuthorization}</div>
        </div>

    }
    {
        screenedData !== null && screenedData !== undefined && screenedData.residencyApplication !== null && screenedData.residencyApplication !== undefined &&
        screenedData.residencyApplication.fluencyInLanguagesBesidesEnglish !== null && screenedData.residencyApplication.fluencyInLanguagesBesidesEnglish !== undefined && screenedData.residencyApplication.fluencyInLanguagesBesidesEnglish === true &&
        <div className="demographics-item">
            {/* <img src={languagesIcon} alt="Icon" width="20" /> */}
            <div className="demographics-label">Fluent in additional languages:</div>
            <div className="demographics-value">{application.isLanguages ? yesDisplay : noDisplay}</div>
        </div>
    }
    {/* {
        screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
        screenedData.personalInfo.previouslyApplied !== null && screenedData.personalInfo.previouslyApplied !== undefined && screenedData.personalInfo.previouslyApplied === true &&
        <div className="demographics-item">
            <img src={previousApplicant} alt="Icon" width="20" />
            <div className="demographics-label">Previous Applicant:</div>
            <div className="demographics-value">{application.previouslyAppliedToSpeciality === "Yes" ? yesDisplay: noDisplay}</div>
        </div>
    } */}
    {/* {
        screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
        screenedData.personalInfo.nrmpId !== null && screenedData.personalInfo.nrmpId !== undefined && screenedData.personalInfo.nrmpId === true &&
        <div className="demographics-item">
            <img src={scoreIconGreen} alt="Icon" width="20" />
            <div className="demographics-label">NRMP ID:</div>
            <div className="demographics-value">{application.nrmpId}</div>
        </div>
    } */}
    {/* {
        screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
        screenedData.personalInfo.nrmpId !== null && screenedData.personalInfo.nrmpId !== undefined && screenedData.personalInfo.nrmpId === true &&
        <div className="demographics-item">
            <img src={scoreIconGreen} alt="Icon" width="20" />
            <div className="demographics-label">AAMC ID:</div>
            <div className="demographics-value">{application.aamc}</div>
        </div>
    } */}
    {/* {
        screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
        screenedData.personalInfo.previousLastName !== null && screenedData.personalInfo.previousLastName !== undefined && screenedData.personalInfo.previousLastName === true &&
        <div className="demographics-item">
            <img src={scoreIconGreen} alt="Icon" width="20" />
            <div className="demographics-label">Previous Last Name:</div>
            <div className="demographics-value">{application.previousLastName}</div>
        </div>
    } */}
    {/* {
        screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
        screenedData.personalInfo.prefferedName !== null && screenedData.personalInfo.prefferedName !== undefined && screenedData.personalInfo.prefferedName === true &&
        <div className="demographics-item">
            <img src={scoreIconGreen} alt="Icon" width="20" />
            <div className="demographics-label">Preferred Name:</div>
            <div className="demographics-value">{application.prefferedName}</div>
        </div>
    } */}
    {/* {
        screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
        screenedData.personalInfo.pronouns !== null && screenedData.personalInfo.pronouns !== undefined && screenedData.personalInfo.pronouns === true &&
        <div className="demographics-item">
            <img src={scoreIconGreen} alt="Icon" width="20" />
            <div className="demographics-label">Pronouns:</div>
            <div className="demographics-value">{application.pronouns}</div>
        </div>
    } */}
    {/* {
        screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
        screenedData.personalInfo.usmleId !== null && screenedData.personalInfo.usmleId !== undefined && screenedData.personalInfo.usmleId === true &&
        <div className="demographics-item">
            <img src={scoreIconGreen} alt="Icon" width="20" />
            <div className="demographics-label">USMLE ID:</div>
            <div className="demographics-value">{application.usmleId}</div>
        </div>
    } */}
    {/* {
        screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
        screenedData.personalInfo.nbomeId !== null && screenedData.personalInfo.nbomeId !== undefined && screenedData.personalInfo.nbomeId === true &&
        <div className="demographics-item">
            <img src={scoreIconGreen} alt="Icon" width="20" />
            <div className="demographics-label">NBOME ID:</div>
            <div className="demographics-value">{application.nbomeId}</div>
        </div>
    } */}
    {/* {
        screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
        screenedData.personalInfo.aoaId !== null && screenedData.personalInfo.aoaId !== undefined && screenedData.personalInfo.aoaId === true &&
        <div className="demographics-item">
            <img src={scoreIconGreen} alt="Icon" width="20" />
            <div className="demographics-label">AOA ID:</div>
            <div className="demographics-value">{application.aoaId}</div>
        </div>
    } */}
    {/* {
        screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
        screenedData.personalInfo.permanentAddress !== null && screenedData.personalInfo.permanentAddress !== undefined && screenedData.personalInfo.permanentAddress === true &&
        <div className="demographics-item">
            <img src={scoreIconGreen} alt="Icon" width="20" />
            <div className="demographics-label">Permanent Address:</div>
            <div className="demographics-value"  style={{maxWidth:'20vw', width:'15vw', textAlignLast:'right'}}>{application.permanentAddress}</div>
        </div>
    } */}
    {/* {
        screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
        screenedData.personalInfo.licensureRevoked !== null && screenedData.personalInfo.licensureRevoked !== undefined && screenedData.personalInfo.licensureRevoked === true &&
        application !== null && application !== undefined && application.licensureRevoked && application.licensureRevoked === true &&
        <div className="demographics-item">
            <img src={scoreIconGreen} alt="Icon" width="20" />
            <div className="demographics-label">licensure Revoked Details:</div>
            <div className="demographics-value">{application.licensureRevokedDetails}</div>
        </div>
    } */}
    {/* {
        screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
        screenedData.personalInfo.militaryObligation !== null && screenedData.personalInfo.militaryObligation !== undefined && screenedData.personalInfo.militaryObligation === true &&
        application !== null && application !== undefined && application.militaryObligation && application.militaryObligation === true &&
        <div className="demographics-item">
            <img src={scoreIconGreen} alt="Icon" width="20" />
            <div className="demographics-label">Military Obligation Details:</div>
            <div className="demographics-value">{application.militaryObligationDetails}</div>
        </div>
    } */}
    {/* {
        screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
        screenedData.personalInfo.medicalTrainingInterrupted !== null && screenedData.personalInfo.medicalTrainingInterrupted !== undefined && screenedData.personalInfo.medicalTrainingInterrupted === true &&
        application !== null && application !== undefined && application.medicalTrainingInterrupted && application.medicalTrainingInterrupted === true &&
        <div className="demographics-item">
            <img src={scoreIconGreen} alt="Icon" width="20" />
            <div className="demographics-label">Medical Training Interrupted Details:</div>
            <div className="demographics-value">{application.medicalTrainingInterruptedDetails}</div>
        </div>
    } */}
    {/* {
        screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
        screenedData.personalInfo.otherServiceObligations !== null && screenedData.personalInfo.otherServiceObligations !== undefined && screenedData.personalInfo.otherServiceObligations === true &&
        <div className="demographics-item">
            <img src={scoreIconGreen} alt="Icon" width="20" />
            <div className="demographics-label">Other Service Obligations:</div>
            <div className="demographics-value">{application.otherServiceObligations}</div>
        </div>
    } */}


   {/* {
       application.carryingOutResponsibilities === true &&
        <div style={{ marginTop: '2vh'}}>
         <div className='demographics-label'>Is there anything that would prevent you from carrying out the responsibilities of a urology training program?</div>
         <div className='demographics-value' style={{float: 'left', minWidth:'100%'}}>{application.carryingOutResponsibilitiesElaboration}</div>
        </div>
   } */}
   {
       application.misdemeanorOrFelony === true &&
        <div style={{ marginTop: '2vh'}}>
         <div className='demographics-label'>Have you ever been convicted of a misdemeanor or felony?</div>
         <div className='demographics-value' style={{float: 'left', minWidth:'100%'}}>{application.misdemeanorOrFelonyDetails}</div>
        </div>
   }
   {/* {
       application.couplesMatch === true &&
        <div style={{ marginTop: '2vh'}}>
         <div className='demographics-label'>what field is your partner applying for?</div>
         <div className='demographics-value' style={{float: 'left', minWidth:'100%'}}>{application.partnerField}</div>
        </div>
   } */}
   {
       application.isLanguages === true &&
        <div style={{ marginTop: '2vh'}}>
         <div className='demographics-label'>What other language(s) are you fluent in?</div>
         <div className='demographics-value' style={{float: 'left', minWidth:'100%'}}>{application.fluentLanguages.join(', ')}</div>
        </div>
   }
   

  

   

   
   

   
   


  
    </div>
</div>

// Enter the elaboration fields here







    )
}

export default ApplicationViewMainDemographics;