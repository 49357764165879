import React, { useState } from 'react';
import './menuModal.css'; // Import your CSS file

const MenuModal = ({search, updateSearchField}) => {
  const [searchBy, setSearchBy] = useState('firstName');
  const [applicant, setApplicant] = useState('allApplicants');

  return (
    <div className="menu-modal">
      <div className="filter-section">
        <div className="filter-group">
          <h3 className='title-menu'>Search By:</h3>
          <label style={{fontFamily: 'Montserrat', fontSize: '0.8rem', fontWeight: 300}}>
            <input
                style={{ display: 'block', accentColor: "#145388"}}
                type="radio"
                value="firstName"
                checked={search.field === 'firstName'}
                onChange={() => { setSearchBy('firstName'); updateSearchField('firstName');}}
            />
            First Name
          </label>
          <label style={{fontFamily: 'Montserrat', fontSize: '0.8rem', fontWeight: 300}}>
            <input
                style={{ display: 'block', accentColor: "#145388"}}
                type="radio"
                value="lastName"
                checked={search.field === 'lastName'}
                onChange={() => { setSearchBy('lastName'); updateSearchField('lastName');}}
            />
            Last Name
          </label>
        </div>
        <div className="filter-group">
          <h3 className='title-menu'>Applicants:</h3>
          <label style={{fontFamily: 'Montserrat', fontSize: '0.8rem', fontWeight: 300}}>
            <input
                style={{ display: 'block', accentColor: "#145388"}}
                type="radio"
                value="allApplicants"
                checked={applicant === 'allApplicants'}
                onChange={() => setApplicant('allApplicants')}
            />
            All Applicants
          </label>
        </div>
      </div>
    </div>
  );
};

export default MenuModal;
