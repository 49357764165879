import React,{useState, useContext, useEffect, useRef} from "react";
import './applicationView.css';
import PrintIcon from '../../../assets/imgs/printIcon.svg';
import ApplicantViewNavBar from './applicantViewNavBar';
import PersonalStatement from './personalStatement';
import Lors from "./lors";
import Documents from './documents';
import Programs from './programs';
import { getApplicationsByApplicantIdQuery, getAllLorsByUserIdQuery } from './graphql/queries';
import { updateApplicantAccessMutation, updateUserStatusMutation } from './graphql/mutations';
import { useQuery, useMutation } from '@apollo/client';
import { UserContext } from '../../../context/userContext';
import DocsModal from './docsModal';
import Spinner from '../../common/spinner';
import { InstitutionIdContext } from '../institutionIdContext';


const ApplicationView = ({ selectedApplicant, onClose, setShouldRefetch, shouldRefetch, institutionId }) => {
    const { user } = useContext(UserContext);
    const sectionRefs = useRef({});
    const [currentSection, setCurrentSection] = useState(null); 
    const [applications, setApplications] = useState([]);
    const [ps, setPs] = useState([]);
    const [lors, setLors] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [updateDocsModalOpen, setUpdateDocsModalOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState(null)
    const [areYouSureModalOpen, setAreYouSureModalOpen] = useState(false)
    const [areYouSureFlagModalOpen, setAreYouSureFlagModalOpen] = useState(false)
    const [loadingStatus, setLoadingStatus] = useState(false)
    const [loadingFlag, setLoadingFlag] = useState(false)


    const [updateApplicantAccess, { data: updateAccessData }] = useMutation(updateApplicantAccessMutation, {
        onCompleted: (data) => {
            console.log("updateAccessData =>", data)
            if(data.updateApplicantAccess) {
                refetchApplications()
                onClose()
            }
        }
    });

    const [updateApplicantStatus, { data: updateStatusData }] = useMutation(updateUserStatusMutation, {
        onCompleted: (data) => {
            console.log("updateStatusData =>", data)
            if(data.updateUserStatus) {
                refetchApplications()
                onClose()
            }
        }
    });



    const initials = selectedApplicant.firstName.charAt(0) + selectedApplicant.lastName.charAt(0)
    const profilePicUrl =`https://ui-avatars.com/api/?name=${initials}`

    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };

    // queries
    const { data: applicationsData, error: applicationsError, isLoading: applicationsLoading , refetch: refetchApplications} = useQuery(getApplicationsByApplicantIdQuery, {
        variables: {
            applicantId: selectedApplicant.id,
        },
        fetchPolicy: 'network-only'
    });

    const { loading, error, data, refetch } = useQuery(getAllLorsByUserIdQuery, {
        variables: {
            applicantId: selectedApplicant.id,
        },
        fetchPolicy: 'network-only'
    });
    
    useEffect(() => {
        if (!loading && !error && data) {
            console.log("get lors =>", data)
            setLors(data.getAllLorsByUserId);
        }
    }, [loading, error, data]);

    useEffect(() => {
        if(applicationsLoading) {
            return;
        } else if(applicationsError) {
            console.error(applicationsError)
            return;
        } else if(applicationsData && applicationsData.getApplicationsByApplicantId) {
            console.log("applicationsData =>", applicationsData)
            let applications = applicationsData.getApplicationsByApplicantId
            setApplications(applications)
            // personal statement
            var psData =[]
            applications.forEach(application => {
                if(application.personalStatement !== undefined && application.personalStatement !== null) psData.push({
                    name: `${application.programId.name}'s Personal Statement`,
                    status: 'Pending', //?
                    ps: application.personalStatement})
            })
            console.log("psData =>", psData)
            if(JSON.stringify(psData) !== JSON.stringify(ps)) {
                setPs(psData)
            }
            // programs
            var progs = []
            applications.forEach(application => {
                console.log("application =>", application)
                if(application.programId !== undefined){
                    let filtered = progs.length > 0 ? progs.filter(el => el.programId.toString() === application.programId.id.toString()) : []
                    if(filtered.length === 0) {
                        progs.push({
                            programId: application.programId.id,
                            name: application.programId.name,
                            specialtyName: application.specialtyId.name,
                            signaled: application.isSignal,
                            city: '',
                            state: ''
                        })
                    }
                }
               
            })
            console.log("progs =>", progs)
            if(JSON.stringify(progs) !== JSON.stringify(programs)) {
                setPrograms(progs)
            }
        }
    }, [applicationsLoading, applicationsError, applicationsData, ps, setPs])


    useEffect(() => {
        console.log('selectedApplicant in the view - with useEffect')
        if(selectedApplicant !== null && selectedApplicant !== undefined) {
            buildDocs()
        }
    }, [selectedApplicant, setShouldRefetch, shouldRefetch])

    const buildDocs = () => {
        var docs = []
        console.log("we are in buildDocs =>", selectedApplicant)
        
        // mspe
        const mspeNotExists = selectedApplicant.mspe === null  || selectedApplicant.mspe === undefined
        docs.push({
            id: mspeNotExists? null : selectedApplicant.mspe.id,
            name: 'MSPE',
            status: mspeNotExists ? "Need to Upload" : selectedApplicant.mspe.stage,
            menuOpen: false,
            type: 'mspe',
            file: mspeNotExists?'': selectedApplicant.mspe.docUrl
        })
        const transcriptNotExists = selectedApplicant.transcript === null  || selectedApplicant.transcript === undefined
        docs.push({
            id: transcriptNotExists ? null : selectedApplicant.transcript.id,
            name: 'Transcript',
            status: transcriptNotExists ? "Need to Upload" : selectedApplicant.transcript.stage,
            menuOpen: false,
            type: 'transcript',
            file: transcriptNotExists?'':selectedApplicant.transcript.docUrl
        })
        const scoresNotExists = selectedApplicant.scores === null  || selectedApplicant.scores === undefined
        docs.push({
            id: scoresNotExists? null : selectedApplicant.scores.id,
            name: 'Standarized Scores',
            status: scoresNotExists ? "Need to Upload" : selectedApplicant.scores.stage,
            menuOpen: false,
            type: 'score',
            file: scoresNotExists?'':selectedApplicant.scores.docUrl
        })
        setDocuments(docs)

        if(institutionId === "6672c992b4519d1feba815e0") {
            const ecfmgReportNotExists = selectedApplicant.ecfmgStatusReport === null || selectedApplicant.ecfmgStatusReport === undefined
            docs.push({
                id: ecfmgReportNotExists ? null : selectedApplicant.ecfmgStatusReport.id,
                name: 'Ecfmg Status Report',
                status: ecfmgReportNotExists ? "Need to Upload": selectedApplicant.ecfmgStatusReport.stage,
                menuOpen: false,
                type: 'ecfmg',
                file: ecfmgReportNotExists ? '' : selectedApplicant.ecfmgStatusReport.docUrl
            })
            setDocuments(docs)
        }
       

    }




    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setCurrentSection(entry.target.id);
                    }
                });
            },
            { threshold: 0.12 }
        );
    
        Object.values(sectionRefs.current).forEach((section) => {
            if (section instanceof Element) {
                observer.observe(section);
            }
        });
    
        return () => {
            Object.values(sectionRefs.current).forEach((section) => {
                if (section instanceof Element) {
                    observer.unobserve(section);
                }
            });
        };
    }, []);

    const handleNavClick = (sectionId) => {
        sectionRefs.current[sectionId].scrollIntoView({ behavior: 'smooth' });
    };

    const handleAccessClick = () => {
       setAreYouSureModalOpen(prevState => !prevState)
    }

    const handleFlaggedClicked = () => {
        setAreYouSureFlagModalOpen(prevState => !prevState)
    }

    const handleFlaggedUpdate = () => {
        setLoadingFlag(true)
        if(selectedApplicant.status === "Flagged") {
            updateApplicantStatus({
                variables: {
                    applicantId: selectedApplicant.id,
                    status: "Application Started"
                }
            })
            .then(res => {
                setLoadingFlag(false)
            })
        } else {
            updateApplicantStatus({
                variables: {
                    applicantId: selectedApplicant.id,
                    status: "Flagged"
                }
            })
            .then(res => {
                setLoadingFlag(false)
            })
        }
    }

    const handleAccessUpdate = () => {
        setLoadingStatus(true)
        if(selectedApplicant.status === "Verified") {
            updateApplicantStatus({
                variables: {
                    applicantId: selectedApplicant.id,
                    status: "Application Suspended"
                }
            })
        } else {
            updateApplicantStatus({
                variables: {
                    applicantId: selectedApplicant.id,
                    status: "Verified"
                }
            })
        }

        updateApplicantAccess({
            variables: {
                id: selectedApplicant.id,
            }
        })
        .then(res => {
            setLoadingStatus(false)
        })
    }


      

    return (
        <div className="modal-overlay">
        <div className="med-shcool-applicationview-modal" >
          <button className="close-button" onClick={onClose}>X</button>
          <div className="med-home-header" style={{ marginTop: '4vh'}}>
          <div className="left-header-container">
                    <h2 className="faculty-home-header-title">APPLICATION VIEW</h2>
            </div>
            <div className="right-container">
            {areYouSureModalOpen && 
    <div className="are-you-sure-modal">
        <div className="are-you-sure-modal-content">
            <h2 style={{fontSize:'3vh', marginBottom:'3vh', alignSelf:'center', fontWeight:'500', fontFamily:'Montserrat'}}>Are you sure you want to {selectedApplicant.status === "Verified" ? "revoke" : "grant"} access to {selectedApplicant.firstName} {selectedApplicant.lastName}?</h2>
            <div className="buttons-container" style={{display:'flex', alignSelf:'center', justifyContent:'space-between', gap:'2vw'}}>
                <button className="green-button" disabled={loadingStatus} onClick={handleAccessUpdate}>Yes</button>
                <button className="white-button" onClick={() => setAreYouSureModalOpen(prevState => !prevState)}>No</button>
            </div>
            {
                loadingStatus && <div style={{marginTop: '3vh'}}><Spinner /></div>
            }
        </div>
    </div>
}
            {areYouSureFlagModalOpen && 
    <div className="are-you-sure-modal">
        <div className="are-you-sure-modal-content">
            <h2 style={{fontSize:'3vh', marginBottom:'3vh', alignSelf:'center', fontWeight:'500', fontFamily:'Montserrat'}}>Are you sure you want to {selectedApplicant.status === "Flagged" ? "unflag" : "flag"}  {selectedApplicant.firstName} {selectedApplicant.lastName}?</h2>
            <div className="buttons-container" style={{display:'flex', alignSelf:'center', justifyContent:'space-between', gap:'2vw'}}>
                <button className="green-button" disabled={loadingFlag} onClick={handleFlaggedUpdate}>Yes</button>
                <button className="white-button" onClick={() => setAreYouSureFlagModalOpen(prevState => !prevState)}>No</button>
            </div>
            {
                loadingFlag && <div style={{marginTop: '3vh'}}><Spinner /></div>
            }
        </div>
    </div>
}
                <button className="green-button" onClick={handleAccessClick}>{applicationsLoading?<Spinner/>:selectedApplicant.status === "Verified" ? "Revoke Access" :"Grant Access"}</button>
                <button className="green-button" style={{ marginLeft: '1vw'}} onClick={handleFlaggedClicked}>{applicationsLoading?<Spinner/>:selectedApplicant.status === "Flagged" ? "Unflag" :"Flag"}</button>
                {/* <button className="filter-button">
                    <img  className="applicant-filter-icon" style={{width:'44px', padding:'1vh'}} src={PrintIcon} alt="print" />
                </button> */}
            </div>
          </div>
          <seperator className="faculty-home-header-seperator"/>

            <div className="user-details-container">
                <img
                    style={selectedApplicant.profilePhotoUrl ? { height: "150px", paddingRight: "1vw", borderRadius: '50%' }:{ height:'16vh',  borderRadius: '100%' }}
                    src={selectedApplicant.profilePhotoUrl ? selectedApplicant.profilePhotoUrl : profilePicUrl}
                    alt="ProfilePicture"
                    className="application-view-main-header-profile-pic"
                />
                <div className="details-top">
                    <div className="applicant-details">
                        <div className="top-name">{selectedApplicant.firstName} {selectedApplicant.lastName}</div>
                        <div className="right-top-name">
                            <div className="time-cont">{new Date(parseInt(selectedApplicant.createdAt)).toLocaleString('en-US', options)}</div>
                          <div className="green-button" style={{ fontSize: '0.8rem', borderRadius: '20px', padding: '10px', gap: 0}}>
                                {/* <img  className="applicant-filter-icon" style={{ width:'30px', paddingRight: '2px'}} src={DoubleCheckIcon} alt="print" /> */}
                                {selectedApplicant.status}
                            </div> 
                        </div>
                    </div>
                    <div className="details-grid">
                        <div className="single-details">
                            <div className="inner-details">
                                    <p className="details-title">First Name:</p>
                                    <p className="details-value">{selectedApplicant.firstName}</p>
                            </div>
                            {/* <div className="inner-details">
                                    <p className="details-title">Suffix:</p>
                                    <p className="details-value">{}</p>
                            </div> */}
                            <div className="inner-details">
                                    <p className="details-title">AAMC ID:</p>
                                    <p className="details-value">{selectedApplicant.applicationInformation.aamc}</p>
                            </div>
                            <div className="inner-details">
                                    <p className="details-title">USMLE ID:</p>
                                    <p className="details-value">{selectedApplicant.usmleId}</p>
                            </div>
                        </div>
                        <div className="single-details">
                            <div className="inner-details">
                                    <p className="details-title">Middle Name:</p>
                                    <p className="details-value">{}</p>
                            </div>
                            <div className="inner-details">
                                    <p className="details-title">Birth Date:</p>
                                    <p className="details-value">{selectedApplicant.applicationInformation.dateOfBirth}</p>
                            </div>
                            <div className="inner-details">
                                    <p className="details-title">Degree Year:</p>
                                    <p className="details-value">{selectedApplicant.graduationYear}</p>
                            </div>
                            {/* <div className="inner-details">
                                    <p className="details-title">Token ID:</p>
                                    <p className="details-value">{}</p>
                            </div> */}
                        </div>
                        <div className="single-details">
                            <div className="inner-details">
                                    <p className="details-title">Last Name:</p>
                                    <p className="details-value">{selectedApplicant.lastName}</p>
                            </div>
                            <div className="inner-details">
                                    <p className="details-title">Email:</p>
                                    <p className="details-value">{selectedApplicant.email}</p>
                            </div>
                            {/* <div className="inner-details">
                                    <p className="details-title">Applied To Program:</p>
                                    <p className="details-value">{"Yes"}</p>
                            </div> */}
                            {/* <div className="inner-details">
                                    <p className="details-title">Advisor(s):</p>
                                    <p className="details-value">{selectedApplicant.usmleId}</p>
                            </div> */}
                        </div>
                      
                </div>
            </div>
            
            </div>

            <div className="nav-with-content">

                <ApplicantViewNavBar onNavClick={handleNavClick} currentSection={currentSection} />
            
                <div className="sections-container">
                <section id="documents" ref={(el) => (sectionRefs.current['documents'] = el)}>
                        <Documents docs={documents} setDocments={setDocuments} setSelectedDoc={setSelectedDoc} handleUpload={() => {setIsUpdate(false); setUpdateDocsModalOpen(prevState => !prevState)}} handleUpdate={() => { setIsUpdate(true); setUpdateDocsModalOpen(prevState => !prevState)}}/>
                    </section>
                    <section id="lors" ref={(el) => (sectionRefs.current['lors'] = el)}>
                        <Lors lors={lors} applications={applications}/>
                    </section>
                    <section id="personalStatment" ref={(el) => (sectionRefs.current['PersonalStatement'] = el)}>
                       <PersonalStatement applications={ps} />
                    </section>
                    <section id="programs" ref={(el) => (sectionRefs.current['programs'] = el)}>
                        <Programs programs={programs}/>
                    </section>
                    {/* <section id="interviews" ref={(el) => (sectionRefs.current['interviews'] = el)}>
                        Interviews
                    </section> */}
                    
                </div>

            </div>
        {
            updateDocsModalOpen &&
            <div>
                <DocsModal setShouldRefetch={setShouldRefetch} isOpen={updateDocsModalOpen} onClose={() => setUpdateDocsModalOpen(prevState => !prevState)} isUpdate={isUpdate} doc={selectedDoc} applicant={selectedApplicant}/>
            </div>
        }
        
        </div>
      </div>
    )
   
}

export default ApplicationView;