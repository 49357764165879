import { Form, Formik, Field, ErrorMessage } from 'formik';
import React, { useState, useEffect } from 'react';
import logo from '../../../../assets/imgs/logo.svg';
import * as Yup from "yup";
import moment from 'moment';

const releaseDateRegExp = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}$/;


const validationSchema = Yup.object({
    releaseDate:Yup.string()
    .required('Release date is required')
    .matches(releaseDateRegExp, 'Release date must be in the format MM/DD/YYYY'),
});

const EditTimelineModal = ({ isOpen, onClose, specialty, onEditTimeline }) => {
    
    if (!isOpen) return null;
    return (
        <div className="modal-backdrop">
        <div className="add-field-modal">
            <div className='add-field-modal-header'>
                <h2>EDIT SPECIALTY TIMELINE</h2>
                <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
            </div>
       
            <Formik
            initialValues={{
              releaseDate: specialty.releaseDate ? moment(parseInt(specialty.releaseDate)).format('MM/DD/YYYY') : ""
            }}
            onSubmit={values => {
                console.log("values =>", values)
                onEditTimeline(values)
                onClose()
            }}
            validationSchema={validationSchema}
        >
            {({ handleSubmit, values }) => (

            <Form style={{ height: '55vh', overflowY: 'auto'}}>
            <div className='application-form-field-group'>
                <label htmlFor="releaseDate" className='applicant-form-field-label'>Release Date</label>
                <Field name="releaseDate" placeholder="MM/DD/YYYY" className='application-form-standard-input' style={{width:'100%'}} />
                <div className='application-form-error-message'> 
                    <ErrorMessage name="releaseDate" component="div" />
                </div>
            </div>


            <div style={{ marginTop: '2vh', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <button type="submit" className='green-button' style={{
                            border: 'none',
                            outline: 'none',
                            cursor: 'pointer',
                            background: 'linear-gradient(96.75deg, #2a93d5 0%, #596173 133.3%)',
                            borderRadius: '7px',
                            padding: '12px 30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#FFFFFF',
                            fontWeight:'700',
                            width: '20vw'
                        }}
                        disabled={values.releaseDate === "" }
                        >
                        Update Timeline
                        </button>
                        <button className='lor-modal-submit-button' onClick={onClose} style={{
                            outline: 'none',
                            cursor: 'pointer',
                            background: '#FFFFFF',
                            border: '1px solid #145388',
                            borderRadius: '7px',
                            padding: '12px 30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#145388',
                            fontWeight:'700',
                            width: '20vw'

                        }}>Cancel</button>
                        </div>




            </Form>
            )}
            </Formik>
        </div>
        </div>

    )

}


export default EditTimelineModal;