import React,{useState, useContext, useEffect, useRef} from "react";
import {useQuery, useMutation} from '@apollo/client';
import { getSpecialtiesWithPaginationQuery, getSpecialtiesWithPaginationCountQuery } from './graphql/queries';
import { updateSpecialtyRequirementsMutation, updateSpecialtyMutation, createSpecialtyMutation } from './graphql/mutations';
import GeneralTable from './generalTable';
import Pagination from './paginationComponent';
import PageSizeSelector from './pageSizeSelector';
import moment from 'moment';
import EditRequirementsModal from './editRequirements';
import EditTimelineModal from './editTimeline';
import AddSpecialtyModal from './addSpecialtyModal';

const AdminSpecialties = ({ setPage }) => {
    const [specialties, setSpecialties] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(20);
    const [filter, setFilter] = useState({field: 'name', value: null});
    const [sort, setSort] = useState({ field: 'name', direction: 'desc'});
    const [totalSpecialties, setTotalSpecialties] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [searchInput, setSearchInput] = useState('');
    const [selectedSpecialty, setSelectedSpecialty] = useState(null);
    const [otherViewOpen, setOtherViewOpen] = useState(false);
    const [editReqOpen, setEditReqOpen] = useState(false);
    const [editTimelineOpen, setEditTimelineOpen] = useState(false);
    const [addSpecialtyOpen, setAddSpecialtyOpen] = useState(false);
    const [loadingData, setLoadingData] = useState(false)


    const [ updateSpecialtyRequirements ] = useMutation(updateSpecialtyRequirementsMutation)
    const [ updateSpecialty ] = useMutation(updateSpecialtyMutation)
    const [ createSpecialty ] = useMutation(createSpecialtyMutation)

    const { loading: loadingSpecialties, error: errorSpecialties, data: specialtiesData, refetch: refetchSpecialties} = useQuery(getSpecialtiesWithPaginationQuery, {
        variables: {
            offset,
            limit,
            filter,
            sort
        }
    })

    const { loading: loadingSpecialtiesCount, error: errorSpecialtiesCount, data: specialtiesCount, refetch: refetchSpecialtiesCount } = useQuery(getSpecialtiesWithPaginationCountQuery, {
        variables: {
            filter,
        }
    })


    const sortData = (column, direction) => {
        setSort({ field: column, direction: direction === true ? "asc": "desc"})
        refetchSpecialties()
    }

    useEffect(() => {
        setLoadingData(true)
        if(!loadingSpecialties && !errorSpecialties && specialtiesData) {
            console.log("specialtiesData =>", specialtiesData.getSpecialtiesWithPagination)
            setSpecialties(specialtiesData.getSpecialtiesWithPagination)
        }
        setLoadingData(false)
    }, [loadingSpecialties, errorSpecialties, specialtiesData, setSpecialties])

    useEffect(() => {
        if(!loadingSpecialtiesCount && !errorSpecialtiesCount && specialtiesCount) {
            console.log("specialtiesCount =>", specialtiesCount.getSpecialtiesWithPaginationCount)
            setTotalSpecialties(specialtiesCount.getSpecialtiesWithPaginationCount)
            setTotalPages(Math.ceil(specialtiesCount.getSpecialtiesWithPaginationCount/ Number(limit)))
        }
    }, [loadingSpecialtiesCount, errorSpecialtiesCount, specialtiesCount,setTotalPages, setTotalSpecialties, limit])

    const columns = [
        {
            accessor: 'name',
            id: 'name',
            Header: <label style={{width:'4vw'}}>Specialty Name</label>,
            Cell: ({ row }) => (
                <div style={{width:'4vw'}}>{row.original.name}</div>
            ),  
        }, 
        {
            accessor: 'releaseDate',
            id: 'releaseDate',
            Header: <label style={{width:'4vw'}}>Release Date</label>,
            Cell: ({ row }) => (
                <div style={{width:'4vw'}}>{row.original.releaseDate ? moment(new Date(parseInt(row.original.releaseDate))).format('MM/DD/YYYY HH:mm'): ""}</div>
            ),  
        },
        {
            id: 'actions',
            Header: <label style={{width:'4vw'}}>Actions</label>,
            Cell: ({ row }) => (
                <div className="dropdown-point">
                    <div className='toggle-points' onClick={() => toggleSelected(row.original)}>
                        <span className='point'/>
                        <span className='point'/>
                        <span className='point'/>
                    </div>
                    {
                        otherViewOpen === false && selectedSpecialty && row.original.id.toString() === selectedSpecialty.id.toString() && (
                        <div className="dropdown-menu" style={{display:'flex',zIndex: 999, right: '6vw', flexDirection:'column', padding:'10%',}}>
                            <a href="#" onClick={() => { setOtherViewOpen(true); setEditTimelineOpen(prevState => !prevState);}}>Edit Timeline</a>
                            <a href="#" onClick={() => { setOtherViewOpen(true); setEditReqOpen(prevState => !prevState); }}>Edit Application Requirements</a>
                        </div>)
                    }
                </div> 
            ),  
        }, 
    ]

    const toggleSelected = (specialty) => {
        if(selectedSpecialty === null) {
            setSelectedSpecialty(specialty)
        } else {
            if(selectedSpecialty.id.toString() === specialty.id.toString()) {
                // close menu
                setSelectedSpecialty(null)
            } else {
                // other user clicked
                setSelectedSpecialty(null)
                setSelectedSpecialty(specialty)
            }
        }
    }

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchInput(value)
        var searchObj = { ...filter}
        searchObj.value = value
        setLoadingData(true)
        setFilter(searchObj)
    }

    const handlePageSizeChange = (event) => {
        setLimit(Number(event.target.value));
        setOffset(0)
        setCurrentPage(1); // Reset to first page when page size changes
        setTotalPages(Math.ceil(totalSpecialties/ Number(event.target.value)))
        setLoadingData(true)
        refetchSpecialties()
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setOffset((page - 1) * limit )
        setLoadingData(true)
        refetchSpecialties()
    };

    const editSpecialtyRequirements = (input) => {
        console.log("input =>", input)
        updateSpecialtyRequirements({
            variables: {
                specialtyId: selectedSpecialty.id,
                applicationRequirements: {...input, lors: parseInt(input.lors)}
            }
        })
        .then(res => {
            refetchSpecialties()
            setSelectedSpecialty(null)
            setOtherViewOpen(false)
        })

    }

    const editTimeline = (timeline) => {
        console.log("timeline =>", timeline)
        updateSpecialty({
            variables: {
                id: selectedSpecialty.id,
                input: {
                    releaseDate: timeline.releaseDate
                }
            }
        })
        .then(res => {
            refetchSpecialties()
            setSelectedSpecialty(null)
            setOtherViewOpen(false)
        })
    }

    const saveSpecialty = (input) => {
        const newSpecialty = {
            name: input.name,
            description: input.description,
            importantDates: input.importantDates,
            applicationRequirements: {
                degree: input.degree,
                lors: parseInt(input.lors),
                mspe: input.mspe === "yes" ? true : false,
                transcript: input.transcript === "yes" ? true : false,
                step1: input.step1 === "yes" ? true : false,
                step2: input.step2 === "yes" ? true : false,
                cost: parseInt(input.cost)
            },
            releaseDate: input.releaseDate,
            shortAnswerQuestions: input.shortAnswerQuestions
        }

        console.log("new specialty =>", newSpecialty)
        createSpecialty({
            variables: {
                input:newSpecialty 
            }
        })
        .then(res => {
            refetchSpecialties()
        })
    }

    return (
        <div className='faculty-home-container'>
            <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop2' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="faculty-home-header">
                <h2 className="faculty-home-header-title">SPECIALTIES</h2>
            </div>
            <seperator className="faculty-home-header-seperator"/>
            <div className="right-header-container" style={{ marginBottom: '2vh'}}>
                <div className="input-container">
                    <i className="fas fa-search"></i>
                    <input
                        className="applicant-top-bar-search-input"
                        placeholder="Search"
                        value={searchInput}
                        onChange={handleSearchChange}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                refetchSpecialties()
                            }
                        }}
                    />
                </div>
                <button className="green-button" onClick={() => setAddSpecialtyOpen(prevState => !prevState)}>Create New Specialty</button>
            </div>
            <GeneralTable columns={columns} data={specialties} limit={limit} totalPages={totalPages} currentPage={currentPage} handlePageSizeChange={handlePageSizeChange} handlePageChange={handlePageChange} loadingData={loadingData} sortData={sortData}/>
            {/* <div className="faculty-application-table-footer" style={{ height: '4vh'}}>
                <PageSizeSelector itemsPerPage={limit} onPageSizeChange={handlePageSizeChange} />
                <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
            </div> */}


            {
                editReqOpen &&
                <EditRequirementsModal isOpen={editReqOpen} onClose={() => {setEditReqOpen(prevState => !prevState); setOtherViewOpen(false); }} specialty={selectedSpecialty} onEditSpecialty={editSpecialtyRequirements} />
            }

            {
                editTimelineOpen &&
                <EditTimelineModal  isOpen={editTimelineOpen} onClose={() => {setEditTimelineOpen(prevState => !prevState); setOtherViewOpen(false); }} specialty={selectedSpecialty} onEditTimeline={editTimeline} />
            }
            {
                addSpecialtyOpen &&
                <AddSpecialtyModal isOpen={addSpecialtyOpen} onClose={() => { setAddSpecialtyOpen(prevState => !prevState)}} saveSpecialty={saveSpecialty} />
            }





        </div>
    )
}

export default AdminSpecialties;