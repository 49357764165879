import * as Yup from 'yup';
import moment from 'moment';

const shortDateRegExp = /^(0[1-9]|1[0-2])\/\d{4}$/; // Regular expression for MM/YYYY

function countWords(input) {
    if (input) {
      const words = input.trim().split(/\s+/);
      return words.length;
    }
    return 0;
  }

const validationSchema = Yup.object().shape({
    startDate: Yup.string()
    .required('Start Date is required')
    .matches(shortDateRegExp, 'Start Date must be in the format MM/YYYY'),
  endDate: Yup.string()
    // .required('End Date is required')
    .matches(shortDateRegExp, 'End Date must be in the format MM/YYYY'),
 
        category: Yup.string().required('Category is required.'),
   
        description: Yup.string()
        .required('Description is required')
        .test(
          'wordCount',
          'Must be 100 words or less.',
          value => {
            if (!value) {
              return true;
            }
            const words = value.split(/\s+/);
            return words.length <= 100;
          }
        ),

  });

  
  export default validationSchema;