const sanitizeTimeFormat = (time) => {
  // Example implementation, adjust based on your format
  if (time.match(/^\d{2}:\d{2} [AP]M$/)) {
      return time; // Time is already in correct format
  }
  // Split the time into hours and minutes
  let [hours, minutes] = time.split(':').map(num => parseInt(num, 10));
  
  // Determine AM or PM
  const period = hours >= 12 ? 'PM' : 'AM';
  
  // Convert hours from 24-hour to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  
  // Format minutes to ensure two digits
  minutes = minutes.toString().padStart(2, '0');
  const correctedTime = `${hours}:${minutes} ${period}`;  
 
  return correctedTime;
};

const AddMinutes = (time, minsToAdd) => {

  if (time === undefined || time === null || time === '') {
    return '';
  }
  const sanitizedTime = sanitizeTimeFormat(time);

  console.log("sanitizedTime =>", sanitizedTime)
  // Splitting the time into hours, minutes, and period (AM/PM)
  let [hours, minutes] = sanitizedTime.split(':');
  const period = minutes.slice(-2);
  minutes = parseInt(minutes.slice(0, -2), 10);
  hours = parseInt(hours, 10);

  // Convert 12-hour format to 24-hour format for calculation
  if (period.toUpperCase() === 'PM' && hours < 12) {
      hours += 12;
  } else if (period.toUpperCase() === 'AM' && hours === 12) {
      hours = 0;
  }

  let totalMinutes = hours * 60 + minutes + minsToAdd; // Convert everything to minutes for addition
  let newHours = Math.floor(totalMinutes / 60) % 24; // Convert back to hours, adjusted for 24-hour format
  const newMinutes = totalMinutes % 60;

  // Convert 24-hour format back to 12-hour format with AM/PM
  const newPeriod = newHours >= 12 ? 'PM' : 'AM';
  newHours = newHours % 12;
  newHours = newHours || 12; // Adjust 0 hour to 12 for 12-hour clock

  return `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')} ${newPeriod}`;
};
export { AddMinutes, sanitizeTimeFormat };