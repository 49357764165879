import React, { useState,useContext, useEffect } from 'react';
import { FormContext } from '../ApplicationForm';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './checkoutForm';
import { useQuery } from '@apollo/client';
import { getSpecialtyByIdQuery } from '../graphql/queries';

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY
const stripePromise = loadStripe(stripePublicKey);




const PaymentView = ({ applicationData, refetchApplications, applicant }) => {
    const { values, setStep, step, applications } = useContext(FormContext);
    const [specialty, setSpecialty] = useState(null);
    const [isPayed, setIsPayed] = useState(applicationData.paymentStatus === 'paid' ? true : false);
 
    console.log("application data in payment =>", applicationData)


    const { data: specialtyData, error: specialtyError, isLoading: specialtyLoading , refetch} = useQuery(getSpecialtyByIdQuery, {
        variables: {
            id: applicationData.specialtyId
        },
        fetchPolicy: "network-only"
      });
    
      useEffect(() => {
        if (specialtyLoading) {
          return; // Or set a loading state if necessary
        } else if (specialtyError) {
          console.error(specialtyError);
          return; // Handle the error appropriately
        } else if (specialtyData && specialtyData.getSpecialtyById) {
          console.log("specialtyData =>", specialtyData)    
          setSpecialty(specialtyData.getSpecialtyById)    
        }
      }, [specialtyData, specialtyError, specialtyLoading, specialty, setSpecialty]);
    
      const continueApplication = () => {
        setStep(7)
      }

    return (
        <div style={{width:'56%'}}>
          {
            specialty && specialty.name === "Anesthesiology" ?
            <div>
                <p style={{fontWeight:'600', marginTop:'3vh', marginBottom:'5vh'}}>This pilot application is free for anesthesiology applicants.</p>
                <button className='green-button' onClick={continueApplication}>Review and Submit Your Application</button>
            </div>
            :
            <div>
                <label style={{ fontSize: '2rem', fontFamily: 'Montserrat'}}>Payment Page</label>
                {!isPayed &&(
                <Elements stripe={stripePromise} >
                    <CheckoutForm applicationData={applicationData} specialty={specialty} continueApplication={continueApplication} refetchApplications={refetchApplications} applicant={applicant}/>
                </Elements>
                )}
                {isPayed && (
                    <div>
                        <p style={{fontWeight:'600', marginTop:'3vh', marginBottom:'5vh'}}>Payment has been made successfully</p>
                        <button className='green-button' onClick={continueApplication}>Review and Submit Your Application</button>
                    </div>
                )}
              </div>
          }
         
        </div>
    )
}

export default PaymentView;