 const internationalSchools =[
'American University of Antigua College of Medicine',
'Al-Quds University',
'Xavier University School of Medicine, Aruba',
'Australian National University Medical School',
'Bond University Faculty of Health Sciences & Medicine',
'Curtin Medical School',
'Deakin University School of Medicine',
'Flinders University of South Australia School of Medicine',
'Griffith University School of Medicine',
'James Cook University College of Medicine and Dentistry',
'Macquarie University Faculty of Medicine & Health Sciences',
'Monash School of Medicine',
'The University of Western Australia Faculty of Health and Medical Sciences',
'University of Adelaide Faculty of Health and Medical Sciences',
'University of Melbourne Faculty of Medicine, Dentistry & Health Sciences',
'University of New England School of Rural Medicine',
'University of New South Wales Faculty of Medicine',
'University of Newcastle Faculty of Health and Medicine',
'University of Notre Dame Australia School of Medicine, Fremantle',
'University of Notre Dame Australia School of Medicine, Sydney',
'University of Queensland Faculty of Medicine',
'University of Sydney, Sydney Medical School',
'University of Tasmania School of Medicine',
'University of Wollongong School of Medicine',
'Western Sydney University School of Medicine',
'Medizinische Universität Wien',
'Azerbaijan Medical University',
'Arabian Gulf University College of Medicine and Medical Sciences',
'Royal College of Surgeons in Ireland - Medical University of Bahrain',
'Abdul Malek Ukil Medical College',
'Ad-din Women\'s Medical College',
'Anwer Khan Modern Medical College',
'Armed Forces Medical College (AFMC)',
'Army Medical College Rangpur',
'Ashiyan Medical College',
'B.G.C. Trust Medical College',
'Bangladesh Medical College (BMSRI)',
'Barind Medical College and Hospital',
'Bashundhara Ad-din Medical College',
'CARe Medical College',
'Faculté de Santé de Sorbonne Université, Paris, FRANCE',
'Universidad del Azuay',
'Università degli Studi di Pavia',
'Central Medical College, Comilla',
'Chattagram Maa-O-Shishu Hospital Medical College',
'Chittagong Medical College and Hospital',
'Comilla Medical College & Hospital',
'Community Based Medical College, Bangladesh (CBMCB)',
'Cox\'s Bazar Medical College',
'Delta Medical College',
'Dhaka Central International Medical College and Hospital',
'Dhaka Community Medical College',
'Dhaka Medical College and Hospital',
'Dhaka National Medical College & Hospital',
'Diabetic Association Medical College',
'Dr. Sirajul Islam Medical College and Hospital',
'East West Medical College',
'Eastern Medical College, Comilla',
'Enam Medical College and Hospital',
'Faridpur Medical College & Hospital',
'Gazi Medical College',
'Gonoshasthaya Samaj Vittik Medical College',
'Green Life Medical College and Hospital',
'Holy Family Red Crescent Medical College',
'Ibn Sina Medical College',
'Ibrahim Medical College',
'Institute of Applied Health Sciences (IAHS)',
'International Medical College',
'Islami Bank Medical College',
'Jahurul Islam Medical College & Hospital',
'Jalalabad Ragib-Rabeya Medical College',
'Jessore Medical College',
'Khulna Medical College',
'Khwaja Yunus Ali Medical College',
'Kumudini Women\'s Medical College',
'Kushtia Medical College',
'M Abdur Rahim Medical College',
'M.H. Samorita Hospital and Medical College',
'Mainamoti Medical College',
'Medical College for Women & Hospital',
'Monno Medical College',
'Mymensingh Medical College',
'North Bengal Medical College',
'North East Medical College',
'Northern International Medical College',
'Pabna Medical College',
'Parkview Medical College',
'Popular Medical College and Hospital',
'President Abdul Hamid Medical College and Hospital',
'Prime Medical College',
'Rajshahi Medical College & Hospital',
'Rangamati Medical College',
'Rangpur Community Medical College',
'Rangpur Medical College',
'Satkhira Medical College',
'Shahabuddin Medical College',
'Shaheed M. Monsur Ali Medical College Sirajganj',
'Shaheed Monsur Ali Medical College',
'Shaheed Suhrawardy Medical College',
'Shaheed Syed Nazrul Islam Medical College',
'Shaheed Tajuddin Ahmad Medical College',
'Shaheed Ziaur Rahman Medical College',
'Sheikh Hasina Medical College Jamalpur',
'Sheikh Hasina Medical College Tangail',
'Sheikh Sayera Khatun Medical College',
'Sher-E-Bangla Medical College',
'Sir Salimullah Medical College',
'Southern Medical College & Hospital',
'Sylhet M.A.G. Osmani Medical College',
'Sylhet Women\'s Medical College and Hospital',
'T.M.S.S. Medical College',
'Tairunnessa Memorial Medical College and Hospital',
'Universal Medical College',
'Uttara Adhunik Medical College',
'Zainul Haque Sikder Women\'s Medical College & Hospital',
'American University of Barbados School of Medicine',
'Ross University School of Medicine',
'University of the West Indies, Barbados Faculty of Medical Sciences',
'Belarusian State Medical University',
'Gomel State Medical University',
'Grodno State Medical University',
'Vitebsk State Medical University',
'Katholieke Universiteit Leuven (KULeuven) Faculteit Geneeskunde',
'Universiteit Antwerpen Faculteit Geneeskunde en Gezondheidswetenschappen',
'Universiteit Gent Faculteit Geneeskunde en Gezondheidswetenschappen',
'Vrije Universiteit Brussel Faculteit Geneeskunde & Farmacie',
'Centro Universitário Barão de Mauá (CUBM)',
'Centro Universitário Christus (Unichristus)',
'Centro Universitário de Volta Redonda (UniFOA) Escola de Ciências Médicas de Volta Redonda',
'Centro Universitário do Estado do Pará (CESUPA)',
'Centro Universitário São Camilo, São Paulo (SCAMILO)',
'Centro Universitário Serra dos Órgãos (UNIFESO) Centro de Ciências da Saúde',
'Escola Superior de Ciências da Santa Casa de Misericórdia de Vitória Faculdade de Medicina',
'Faculdade de Ciências da Saúde de Barretos Dr. Paulo Prata (FACISB)',
'Faculdade de Ciências Médicas da Santa Casa de São Paulo (FCMSCSP)',
'Faculdade de Ciências Médicas de Minas Gerais (FCMMG)',
'Faculdade de Medicina de Jundiaí (FMJ)',
'Faculdade de Medicina de Marília (FAMEMA)',
'Faculdade de Medicina de Petrópolis / Faculdade Arthur Sá Earp Neto (FMP/Fase)',
'Faculdade de Medicina de Ribeirão Preto (FMRP-USP)',
'Faculdade de Medicina de São José do Rio Preto (FAMERP)',
'Faculdade Evangélica Mackenzie do Paraná Faculdade de Medicina',
'Faculdade Israelita de Ciências da Saúde Albert Einstein (FICSAE)',
'Faculdade Pernambucana de Saúde (FPS)',
'Faculdade Santa Marcelina (FASM)',
'Pontifícia Universidade Católica de Minas Gerais (PUC MINAS)',
'Pontifícia Universidade Católica do Paraná Escola de Medicina, Câmpus Curitiba',
'Pontifícia Universidade Católica do Paraná Escola de Medicina, Câmpus Londrina',
'Pontifícia Universidade Católica do Rio Grande do Sul (PUCRS) Faculdade de Medicina',
'Universidade de Brasília (UnB) Faculdade de Medicina',
'Universidade de Santo Amaro (UNISA) Faculdade de Medicina',
'Universidade de São Paulo Faculdade de Medicina São Paulo (FMSP-USP)',
'Universidade Estadual de Campinas (UNICAMP) Faculdade de Ciências Médicas',
'Universidade Estadual Paulista \'Júlio de Mesquita Filho\' (UNESP) Faculdade de Medicina',
'Universidade Federal de Alagoas (UFAL) Faculdade de Medicina',
'Universidade Federal de Ciências da Saúde de Porto Alegre (UFCSPA) Faculdade de Medicina',
'Universidade Federal de Juiz de Fora (UFJF) Faculdade de Medicina Juiz de Fora',
'Universidade Federal de Minas Gerais (UFMG) Faculdade de Medicina',
'Universidade Federal de São Paulo (UNIFESP) Faculdade de Medicina',
'Universidade Federal de Viçosa (UFV)',
'Universidade Federal do Ceará Faculdade de Medicina Fortaleza',
'Universidade Federal do Ceará Faculdade de Medicina Sobral',
'Universidade Municipal de São Caetano do Sul Escola de Saúde, Campus Centro SCS',
'Universidade Regional de Blumenau (FURB) Faculdade de Medicina',
'Universidade Vila Velha (UVV)',
'St. Matthew\'s University School of Medicine (Grand Cayman)',
'Anhui Medical University Faculty of Medicine',
'Anhui University of Science and Technology College of Medicine',
'Baotou Medical College',
'Beihua University School of Medicine',
'Bengbu Medical College',
'Binzhou Medical College',
'Capital Medical University',
'Changchun University of Traditional Chinese Medicine',
'Changsha Medical University',
'Changzhi Medical College',
'Chengde Medical University',
'Chengdu Medical College',
'Chengdu University of Traditional Chinese Medicine',
'China Medical University',
'Chongqing Medical University',
'Dali University School of Medicine',
'Dalian Medical University',
'Fujian College of Traditional Chinese Medicine',
'Fujian Medical University',
'Gannan Medical University',
'Gansu College of Traditional Chinese Medicine',
'Guangdong Medical University',
'Guangxi Medical University',
'Guangxi University of Chinese Medicine',
'Guangzhou Medical University',
'Guilin Medical University',
'Guizhou Medical University',
'Hainan Medical University',
'Hangzhou School of Medicine, Hangzhou Normal University',
'Harbin Medical University',
'Hebei Medical University Clinical College',
'Hebei North University Faculty of Medicine',
'Hebei University Medical College',
'Hebei University of Engineering School of Medicine',
'Hubei Polytechnic University School of Medicine',
'Hubei University for Nationalities Medical School',
'Hubei University of Arts and Science Medical College',
'Hubei University of Medicine',
'Hubei University of Science and Technology Faculty of Medicine',
'Hunan Normal University College of Medicine',
'Hunan University of Chinese Medicine',
'Inner Mongolia Medical University',
'Inner Mongolia University for the Nationalities',
'Jiamusi University School of Medicine',
'Jianghan University School of Medicine',
'Jiangnan University Wuxi Medical College',
'Jiangsu University School of Medicine',
'Jiangxi Medical College of Nanchang University',
'Jilin Medical University',
'Jinan University School of Medicine',
'Jinggangshan University Medical School',
'Jining Medical University',
'Jinzhou Medical University',
'Jishou University School of Medicine',
'Jiujiang University Medical College',
'Kunming Medical University',
'Lanzhou University Faculty of Medicine',
'Medical College of China Three Gorges University',
'Medical College of Dalian University',
'Medical College of Henan University of Science and Technology',
'Medical College of Nanjing University',
'Medical College of Nankai University',
'Medical College of Wuhan University of Science and Technology',
'Medical College of Yan\'an University',
'Mudanjiang Medical University',
'Nanjing Medical University',
'Nantong University Medical School',
'Ningbo University Medical School',
'Ningxia Medical University',
'Norman Bethune Health Science Center of Jilin University',
'North China University of Science and Technology College of Medicine',
'North Sichuan Medical College',
'Northwest University for Nationalities College of Medicine',
'Panzhihua University School of Medical Science',
'Peking Union Medical University',
'Peking University Health Science Center',
'Qingdao University College of Medical Science',
'Qiqihar Medical University',
'Shaanxi University of Chinese Medicine',
'Shandong First Medical University',
'Shandong University Cheeloo College of Medicine',
'Shanghai Jiao Tong University School of Medicine',
'Shanghai Medical College, Fudan University',
'Shantou University Medical College',
'Shanxi Medical University',
'Shenyang Medical College',
'Shenzhen University College of Medicine',
'Shihezi University School of Medicine',
'Soochow University Medical College',
'Southeast University Medical College',
'Southern Medical University',
'Southwest Medical University',
'Tianjin Medical University School of Basic Medical Sciences',
'Tongji Medical College of Huazhong University of Science & Technology',
'Tongji University School of Medicine',
'Tsinghua University School of Medicine',
'University of South China Faculty of Medicine',
'Wannan Medical College',
'Weifang Medical University',
'Wenzhou Medical University',
'West China College of Medicine, Sichuan University',
'Wuhan University School of Medicine',
'Xi\'an Jiaotong University College of Medicine',
'Xi\'an Medical University',
'Xiamen University School of Medicine',
'Xiangya School of Medicine, Central South University',
'Xinjiang Medical University',
'Xinxiang Medical University',
'Xuzhou Medical University',
'Yanbian University Health Science Center',
'Yangtze University Medical School',
'Yangzhou University College of Medicine',
'Yichun University School of Medicine',
'Youjiang Medical College for Nationalities',
'Zhejiang Chinese Medical University',
'Zhejiang University School of Medicine',
'Zhengzhou University Medical School',
'Zhongshan School of Medicine, Sun Yat-Sen University',
'Zunyi Medical University',
'Corporación Universitaria Remington Facultad de Ciencias de la Salud',
'Fundación Universitaria de Ciencias de la Salud (FUCS) Facultad de Medicina',
'Fundación Universitaria Juan N. Corpas',
'Pontificia Universidad Javeriana Bogotá Facultad de Medicina',
'Pontificia Universidad Javeriana Cali Facultad de Ciencias de la Salud',
'Universidad Antonio Nariño Facultad de Medicina',
'Universidad Autónoma de Bucaramanga Facultad de Medicina',
'Universidad CES Escuela de Medicina',
'Universidad Cooperativa de Colombia Escuela de Medicina, Medellin',
'Universidad de Antioquia Facultad de Medicina',
'Universidad de Boyacá Facultad de Ciencias de la Salud',
'Universidad de Caldas Facultad de Ciencias para la Salud',
'Universidad de Cartagena Facultad de Medicina',
'Universidad de Ciencias Aplicadas y Ambientales Facultad de Ciencias Médicas',
'Universidad de la Sabana Facultad de Medicina',
'Universidad de Los Andes Facultad de Medicina (Colombia)',
'Universidad de Manizales Campo de Ciencias de la Salud',
'Universidad de Santander (UDES) Facultad de Ciencias de la Salud',
'Universidad del Cauca Facultad de Ciencias de la Salud',
'Universidad del Magdalena Facultad de Ciencias de la Salud',
'Universidad del Norte Programa de Medicina',
'Universidad del Quindío Facultad de Ciencias de la Salud',
'Universidad del Rosario Escuela de Medicina y Ciencias de la Salud',
'Universidad del Sinú Elias Bechara Zainúm Escuela de Medicina Cartagena',
'Universidad del Sinú Facultad de Ciencias de la Salud Montería',
'Universidad del Tolima Facultad de Ciencias de la Salud',
'Universidad del Valle Escuela de Medicina, Cali',
'Universidad El Bosque, Escuela Medicina Colombiana',
'Universidad ICESI Facultad de Ciencias de la Salud',
'Universidad Industrial de Santander Escuela de Medicina',
'Universidad Libre de Cali Facultad de Ciencias de la Salud',
'Universidad Libre de Colombia Facultad de Ciencias de la Salud',
'Universidad Militar Nueva Granada Facultad de Medicina',
'Universidad Nacional de Colombia Facultad de Medicina',
'Universidad Pedagógica y Tecnológica de Colombia Facultad de Ciencias de la Salud',
'Universidad Pontificia Bolivariana Facultad de Medicina',
'Universidad Simón Bolívar Facultad de Ciencias de la Salud',
'Universidad Surcolombiana Facultad de la Salud',
'Universidad Tecnológica de Pereira Facultad de Ciencias de la Salud',
'Universidad de Ciencias Medicas Andrés Vesalio Guzmán (UCIMED) Escuela de Medicina',
'Universidad de Costa Rica Facultad de Medicina',
'Universidad de Iberoamérica Escuela de Medicina',
'Universidad Hispanoamericana',
'Universidad Latina de Costa Rica Facultad de Ciencias de la Salud, Escuela de Medicina',
'Avalon University School of Medicine',
'St. Martinus University Faculty of Medicine',
'European University Cyprus School of Medicine',
'University of Cyprus Medical School',
'University of Nicosia Medical School',
'1. Lékařská Fakulta, Univerzity Karlovy',
'2. Lékařská Fakulta, Univerzity Karlovy',
'3. Lékařská Fakulta, Univerzity Karlovy',
'Fakulta Vojenského Zdravotnictví, Univerzity Obrany',
'Lékařská Fakulta UK v Hradci Králové',
'Lékařská Fakulta v Plzni, Univerzita Karlova v Praze',
'Masarykova Univerzita Lékařská Fakulta',
'Ostravská Univerzita v Ostravě Lékařská Fakulta',
'Univerzity Palackého v Olomouci Lékařská Fakulta',
'All Saints University School of Medicine, Dominica',
'American Canadian School of Medicine (ACSOM)',
'Instituto Tecnológico de Santo Domingo (INTEC) Facultad de Ciencias de la Salud',
'O&Med Medical School, Universidad Dominicana O&M',
'Pontificia Universidad Católica Madre y Maestra (PUCMM) Facultad de Ciencias de la Salud',
'Pontificia Universidad Católica Madre y Maestra Facultad de Ciencias de la Salud Santo Domingo',
'Universidad Autónoma de Santo Domingo Facultad de Ciencias de la Salud',
'Universidad Católica Nordestana (UCNE) Facultad de Ciencias Medicas',
'Universidad Central del Este (UCE) Facultad de Medicina',
'Universidad Eugenio Maria de Hostos Escuela de Medicina',
'Universidad Iberoamericana (UNIBE) School of Medicine, Santo Domingo',
'Universidad Nacional Pedro Henríquez Ureña (UNPHU) Escuela de Medicina',
'Universidad Tecnológica de Santiago Escuela de Medicina, Santiago Campus',
'Universidad Tecnológica de Santiago Escuela de Medicina, Santo Domingo Campus',
'Ain Shams University Faculty of Medicine',
'Al-Azhar University Faculty of Medicine for Boys',
'Al-Azhar University Faculty of Medicine for Girls',
'Alexandria University Faculty of Medicine',
'Assiut University Faculty of Medicine',
'Beni Suef University Faculty of Medicine',
'Cairo University Faculty of Medicine',
'College of Human Medicine Benha University',
'Damietta Faculty of Medicine, Al-Azhar University',
'Mansoura University Faculty of Medicine',
'Menoufia University Faculty of Medicine',
'Qena Faculty of Medicine, South Valley University',
'Sohag University Faculty of Medicine',
'Suez Canal University Faculty of Medicine',
'Tanta University Faculty of Medicine',
'Zagazig University Faculty of Medicine',
'Universidad Evangélica de El Salvador Facultad de Medicina',
'Akaki Tsereteli State University Faculty of Medicine',
'Alte University School of Medicine',
'Batumi Shota Rustaveli State University Faculty of Natural Sciences and Health Care',
'BAU International University Faculty of Medicine',
'Caucasus International University Faculty of Medicine',
'Caucasus University School of Medicine and Healthcare Management',
'David Aghmashenebeli University of Georgia Faculty of Medicine',
'David Tvildiani Medical University AIETI Medical School',
'East European University Faculty of Healthcare Sciences',
'East-West Teaching University School of Medicine',
'European University Faculty of Medicine',
'Georgian American University',
'Georgian National University SEU Faculty of Medicine',
'Grigol Robakidze University School of Medicine',
'Ilia State University Faculty of Natural Sciences and Medicine',
'Ivane Javakhishvili Tbilisi State University Faculty of Medicine',
'Ken Walker International University Faculty of Medicine and Stomatology',
'Kutaisi University Faculty of Medicine',
'New Vision University School of Medicine',
'Petre Shotadze Tbilisi Medical Academy Faculty of Medicine',
'Tbilisi State Medical University Faculty of Medicine',
'Teaching University Geomedi Faculty of Medicine',
'University of Georgia School of Health Sciences and Public Health',
'Charité-Universitätsmedizin Berlin Medizinische Fakultät',
'St. George\'s University School of Medicine',
'Universidad Francisco Marroquín Facultad de Medicina',
'Universidad Mariano Gálvez de Guatemala Facultad de Ciencias Médicas y de la Salud',
'Universidad Rafael Landívar Facultad de Ciencias de la Salud',
'Texila American University College of Medicine',
'University of Guyana School of Medicine',
'Semmelweis University Faculty of General Medicine',
'University of Debrecen Faculty of Medicine',
'University of Pécs Medical School',
'University of Szeged Faculty of Medicine',
'Employees\' State Insurance Corporation Medical College, Bangalore',
'Employees\' State Insurance Corporation Medical College, Faridabad',
'Employees\' State Insurance Corporation Medical College, Gulbarga',
'Employees\' State Insurance Corporation Medical College, Joka',
'Employees\' State Insurance Corporation Medical College, Sanathnagar',
'A.C.S. Medical College and Hospital',
'A.C.S.R. Government Medical College',
'A.J. Institute of Medical Sciences and Research Centre',
'Aarupadai Veedu Medical College',
'Acharya Shri Chander College of Medical Sciences',
'Adesh Institute of Medical Sciences & Research',
'Adichunchanagiri Institute of Medical Sciences',
'Agartala Government Medical College',
'Al-Ameen Medical College',
'Al-Azhar Medical College and Super Speciality Hospital',
'All India Institute of Medical Sciences, Bhopal',
'All India Institute of Medical Sciences, Bhubaneswar',
'All India Institute of Medical Sciences, Jodhpur',
'All India Institute of Medical Sciences, New Delhi',
'All India Institute of Medical Sciences, Patna',
'All India Institute of Medical Sciences, Raipur',
'All India Institute of Medical Sciences, Rishikesh',
'Alluri Sitaram Raju Academy of Medical Sciences',
'Amala Institute of Medical Sciences',
'American International Institute of Medical Sciences',
'Amrita School of Medicine',
'Ananta Institute of Medical Sciences & Research Centre',
'Andaman & Nicobar Islands Institute of Medical Sciences',
'Andhra Medical College',
'Annapoorna Medical College and Hospital',
'Anugrah Narayan Magadh Medical College and Hospital',
'Apollo Institute of Medical Sciences and Research, Chittoor',
'Apollo Institute of Medical Sciences and Research, Hyderabad',
'Armed Forces Medical College',
'Army College of Medical Sciences',
'Ashwini Rural Medical College, Hospital and Research Centre',
'Assam Medical College',
'Azeezia Institute of Medical Science',
'B.G.S. Global Institute of Medical Sciences',
'B.J. Government Medical College, Pune',
'B.J. Medical College, Ahmedabad',
'B.K.L. Walawalkar Rural Medical College',
'B.P.S. Government Medical College for Women',
'Baba Raghav Das Medical College',
'Bangalore Medical College and Research Institute',
'Bankura Sammilani Medical College',
'Basaveshwara Medical College and Hospital',
'Belgaum Institute of Medical Sciences',
'Bhagwan Mahavir Institute of Medical Sciences',
'Bharat Ratna Late Shri Atal Bihari Vajpyee Memorial Medical College',
'Bharati Vidyapeeth Medical College and Hospital, Sangli',
'Bharati Vidyapeeth Medical College, Pune',
'Bhaskar Medical College',
'Bidar Institute of Medical Sciences',
'Burdwan Medical College',
'C.U. Shah Medical College',
'Calcutta National Medical College',
'Career Institute of Medical Sciences & Hospital',
'Chalmeda Anand Rao Institute of Medical Sciences',
'Chamarajanagar Institute of Medical Sciences',
'Chandulal Chandrakar Memorial Medical College',
'Chengalpattu Medical College',
'Chettinad Hospital & Research Institute',
'Chhattisgarh Institute of Medical Sciences',
'Chintpurni Medical College and Hospital, Gurdaspur',
'Chirayu Medical College and Hospital',
'Christian Medical College, Ludhiana',
'Christian Medical College, Vellore',
'Coimbatore Medical College',
'College of Medicine and J.N.M. Hospital',
'College of Medicine and Sagore Dutta Hospital',
'D.M. Wayanad Institute of Medical Sciences',
'D.Y. Patil Medical College, Kolhapur',
'D.Y. Patil University School of Medicine',
'Darbhanga Medical College and Hospital',
'Dayanand Medical College',
'Deccan College of Medical Sciences',
'Dhanalakshmi Srinivasan Medical College and Hospital, Perambalur',
'Dr. B.R. Ambedkar Medical College',
'Dr. Baba Saheb Ambedkar Medical College',
'Dr. D.Y. Patil Medical College, Hospital and Research Centre',
'Dr. Panjabrao Deshmukh Memorial Medical College',
'Dr. Pinnamaneni Siddhartha Institute of Medical Sciences',
'Dr. Rajendra Prasad Government Medical College',
'Dr. S.N. Medical College',
'Dr. Shankarrao Chavan Government Medical College, Nanded',
'Dr. Somervell Memorial CSI Hospital & Medical College',
'Dr. Ulhas Patil Medical College & Hospital',
'Dr. V.R.K. Women\'s Medical College',
'Dr. Vaishampayan Memorial Medical College',
'Dr. Vithalrao Vikhe Patil Foundation\'s Medical College',
'Dr.Vasantrao Pawar Medical College Hospital and Research Centre',
'E.S.I.C. Medical College & Postgraduate Institute of Medical Sciences and Research',
'Era\'s Lucknow Medical College',
'F.H. Medical College & Hospital',
'Father Muller Medical College',
'Fathima Institute of Medical Sciences, Kadapa',
'G.C.S. Medical College, Hospital and Research Centre',
'G.M.E.R.S. Medical College and Hospital, Dharpur Patan',
'G.M.E.R.S. Medical College and Hospital, Gandhinagar',
'G.M.E.R.S. Medical College and Hospital, Himmatnagar',
'G.M.E.R.S. Medical College and Hospital, Sola',
'G.M.E.R.S. Medical College, Gotri',
'G.M.E.R.S. Medical College, Junagadh',
'G.M.E.R.S. Medical College, Valsad',
'G.S.L. Medical College, Rajahmundry',
'G.S.V.M. Medical College',
'Gadag Institute of Medical Sciences, Mallasamudra',
'Gajra Raja Medical College',
'Gandhi Medical College and Hospital, Secunderabad',
'Gandhi Medical College, Bhopal',
'Gauhati Medical College',
'Gayatri Vidya Parishad Institute of Health Care & Medical Technology',
'Geetanjali Medical College and Hospital',
'Gian Sagar Medical College',
'GITAM Institute of Medical Sciences and Research',
'Goa Medical College',
'Government Dharmapuri Medical College',
'Government Doon Medical College',
'Government Erode Medical College and Hospital Perundurai',
'Government Kilpauk Medical College',
'Government Medical College & ESIC Hospital, Coimbatore',
'Government Medical College Akola',
'Government Medical College Amritsar',
'Government Medical College Anantapuramu',
'Government Medical College and Hospital Cuddalore',
'Government Medical College Aurangabad',
'Government Medical College Bhavnagar',
'Government Medical College Chandigarh',
'Government Medical College Chandrapur',
'Government Medical College Ernakulam',
'Government Medical College Haldwani',
'Government Medical College Jammu',
'Government Medical College Kannur',
'Government Medical College Kota',
'Government Medical College Kottayam',
'Government Medical College Kozhikode',
'Government Medical College Latur',
'Government Medical College Mahabubnagar',
'Government Medical College Miraj',
'Government Medical College Nagpur',
'Government Medical College Nizamabad',
'Government Medical College Omandurar',
'Government Medical College Patiala',
'Government Medical College Srinagar',
'Government Medical College Surat',
'Government Medical College Thrissur',
'Government Medical College Vellore',
'Government Medical College, Kadapa',
'Government Medical College, Ongole',
'Government Medical College, Srikakulam',
'Government Sivagangai Medical College',
'Government Thiruvannamalai Medical College',
'Government Villupuram Medical College',
'Grant Government Medical College, Mumbai',
'Great Eastern Medical School and Hospital',
'Gujarat Adani Institute of Medical Sciences',
'Gulbarga Institute of Medical Sciences',
'Guntur Medical College',
'Guru Gobind Singh Medical College',
'H.B.T. Medical College & Dr. R.N. Cooper Municipal General Hospital',
'Hamdard Institute of Medical Sciences & Research, New Delhi',
'Hassan Institute of Medical Sciences',
'Heritage Institute of Medical Sciences, Varanasi',
'Hi-Tech Medical College and Hospital, Bhubaneswar',
'Hi-Tech Medical College and Hospital, Rourkela',
'Himalayan Institute of Medical Sciences',
'Hind Institute of Medical Sciences, Lucknow',
'Index Medical College & Research Centre',
'Indian Institute of Medical Science & Research',
'Indira Gandhi Government Medical College, Nagpur',
'Indira Gandhi Institute of Medical Sciences',
'Indira Gandhi Medical College and Research Institute',
'Indira Gandhi Medical College, Shimla',
'Institute of Medical Sciences & SUM Hospital',
'Institute of Medical Sciences, Varanasi',
'Institute of Postgraduate Medical Education & Research, Kolkata',
'Integral Institute of Medical Sciences & Research',
'IQ City Medical College',
'J.J.M. Medical College, Davangere',
'J.S.S. Medical College',
'Jaipur National University Institute for Medical Sciences and Research Centre',
'Jawaharlal Institute of Post-Graduate Medical Education and Research (JIPMER)',
'Jawaharlal Nehru Institute of Medical Sciences, Porompat',
'Jawaharlal Nehru Medical College, Ajmer',
'Jawaharlal Nehru Medical College, Aligarh',
'Jawaharlal Nehru Medical College, Belgaum',
'Jawaharlal Nehru Medical College, Bhagalpur',
'Jawaharlal Nehru Medical College, Wardha',
'Jhalawar Medical College',
'JMF\'s A.C.P.M. Medical College',
'Jorhat Medical College and Hospital',
'Jubilee Mission Medical College and Research Institute',
'K.A.P. Viswanatham Government Medical College',
'K.D. Medical College Hospital & Research Centre',
'K.J. Somaiya Medical College',
'K.M.C.T. Medical College',
'K.P.C. Medical College and Hospital',
'K.S. Hegde Medical Academy',
'K.V.G. Medical College',
'Kakatiya Medical College',
'Kalinga Institute of Medical Sciences',
'Kamineni Academy of Medical Sciences & Research Center',
'Kamineni Institute of Medical Sciences',
'Kanachur Institute of Medical Sciences',
'Kannur Medical College',
'Kanyakumari Government Medical College',
'Karnataka Institute of Medical Sciences',
'Karpaga Vinayaga Institute of Medical Sciences',
'Karpagam Faculty of Medical Sciences and Research',
'Karuna Medical College',
'Karwar Institute of Medical Sciences',
'Kasturba Medical College Mangalore',
'Kasturba Medical College Manipal',
'Katihar Medical College',
'Katuri Medical College, Guntur',
'Kempegowda Institute of Medical Sciences',
'Khaja Bandanawaz University Faculty of Medical Sciences',
'King George\'s Medical University',
'Kodagu Institute of Medical Sciences',
'Kolkata Medical College & Hospital',
'Konaseema Institute of Medical Sciences & Research Foundation',
'Koppal Institute of Medical Sciences',
'Krishna Vishwa Vidyapeeth Faculty of Medical Sciences',
'Kurnool Medical College',
'L.N. Medical College and Research Centre',
'Lady Hardinge Medical College',
'Lala Lajpat Rai Memorial Medical College',
'Late Baliram Kashyap Memorial Government Medical College',
'Lokmanya Tilak Municipal Medical College',
'M.E.S. Medical College',
'M.N.R. Medical College',
'M.P. Shah Government Medical College',
'M.S. Ramaiah Medical College',
'M.V.J. Medical College and Research Hospital',
'Madha Medical College and Research Institute',
'Madras Medical College',
'Madurai Medical College',
'Mahadevappa Rampure Medical College',
'Maharaja Agrasen Medical College',
'Maharaja Krishna Chandra Gajapati Medical College',
'Maharajah\'s Institute of Medical Sciences, Vizianagaram',
'Maharani Laxmi Bai Medical College',
'Maharashtra Institute of Medical Education & Research (MIMER)',
'Maharashtra Institute of Medical Sciences & Research (MIMSR)',
'Maharishi Markandeshwar Institute of Medical Sciences & Research',
'Maharishi Markandeshwar Medical College & Hospital, Solan',
'Mahatma Gandhi Institute of Medical Sciences, Wardha',
'Mahatma Gandhi Medical College and Hospital, Jaipur',
'Mahatma Gandhi Medical College and Research Institute',
'Mahatma Gandhi Memorial Medical College, Indore',
'Mahatma Gandhi Memorial Medical College, Jamshedpur',
'Mahatma Gandhi Mission\'s Medical College, Aurangabad',
'Mahatma Gandhi Mission\'s Medical College, Navi Mumbai',
'Mahavir Institute of Medical Sciences',
'Maheshwara Medical College',
'Major S.D. Singh Medical College and Hospital',
'Malabar Medical College',
'Malankara Orthodox Syrian Church Medical College',
'Malda Medical College and Hospital',
'Malla Reddy Institute of Medical Sciences',
'Mallareddy Medical College for Women',
'Mamata Medical College',
'Mandya Institute of Medical Sciences',
'Mata Gujri Memorial Medical College',
'Maulana Azad Medical College',
'Mayo Institute of Medical Sciences',
'Medical College Baroda',
'Medical College Thiruvananthapuram',
'MediCiti Institute of Medical Sciences',
'Meenakshi Medical College Hospital and Research Institute',
'Melmaruvathur Adhiparasakthi Institute of Medical Sciences & Research',
'Midnapore Medical College',
'Mohan Kumaramangalam Medical College',
'Moti Lal Nehru Medical College, Allahabad',
'Mount Zion Medical College',
'Murshidabad Medical College and Hospital',
'Muzaffarnagar Medical College',
'Mysore Medical College and Research Institute',
'N.C. Medical College & Hospital',
'N.K.P. Salve Institute of Medical Sciences',
'N.R.I. Institute of Medical Sciences, Visakhapatnam',
'N.R.I. Medical College, Guntur',
'Nalanda Medical College',
'Narayan Medical College and Hospital',
'Narayana Medical College',
'Narendra Modi Medical College Ahmedabad',
'National Institute of Medical Sciences and Research Medical College and Hospital',
'Navodaya Medical College',
'Netaji Subhash Chandra Bose Medical College',
'Nilratan Sircar Medical College',
'Nimra Institute of Medical Sciences',
'North Bengal Medical College',
'North Delhi Municipal Corporation Medical College',
'North Eastern Indira Gandhi Regional Institute of Health and Medical Sciences',
'Osmania Medical College',
'P.E.S. Institute of Medical Sciences and Research',
'P.K. Das Institute of Medical Sciences',
'P.S.G. Institute of Medical Sciences and Research',
'Pacific Institute of Medical Sciences',
'Pacific Medical College & Hospital',
'Pandit Deendayal Upadhyay Medical College, Rajkot',
'Paracelsus Medical University',
'Parul Institute of Medical Sciences & Research',
'Patna Medical College',
'People\'s College of Medical Sciences & Research Centre',
'Pondicherry Institute of Medical Sciences and Research',
'Pramukhswami Medical College',
'Prathima Institute of Medical Sciences',
'Pt. B.D.S. Postgraduate Institute of Medical Sciences',
'Pt. Jawahar Lal Nehru Memorial Medical College Raipur',
'Punjab Institute of Medical Sciences',
'Pushpagiri Institute of Medical Sciences and Research Centre',
'R.G. Kar Medical College',
'R.K.D.F. Medical College Hospital & Research Centre, Jatkhedi',
'R.U.H.S. College of Medical Sciences',
'R.V.M. Institute of Medical Sciences and Research Center',
'Raichur Institute of Medical Sciences',
'Rajarajeswari Medical College & Hospital',
'Rajarshi Chhatrapati Shahu Maharaj Government Medical College Kolhapur',
'Rajendra Institute of Medical Sciences, Ranchi',
'Rajiv Gandhi Institute of Medical Sciences, Adilabad',
'Rajiv Gandhi Medical College and Chhatrapati Shivaji Maharaj Hospital',
'Rajkiya Medical College, Jalaun',
'Rajshree Medical Research Institute',
'Rama Medical College and Hospital, Hapur',
'Rama Medical College and Hospital, Kanpur',
'Rangaraya Medical College',
'Ravindra Nath Tagore Medical College',
'Regional Institute of Medical Sciences',
'Rohilkhand Medical College & Hospital',
'Rural Medical College of Pravara Medical Trust',
'Ruxmaniben Deepchand Gardi Medical College',
'S. Nijalingappa Medical College and H.S.K. Hospital & Research Centre',
'S.D.M. Medical College',
'S.G.T. Medical College, Hospital and Research Institute',
'S.M.B.T. Institute of Medical Sciences & Research Centre',
'S.R.M. Medical College Hospital and Research Centre',
'S.S. Institute of Medical Sciences & Research Centre, Davangere',
'S.V.S. Medical College',
'Santhiram Medical College',
'Santosh Medical College',
'Sapthagiri Institute of Medical Sciences & Research Centre',
'Saraswathi Institute of Medical Sciences',
'Saraswati Medical College',
'Sardar Patel Medical College',
'Sarojini Naidu Medical College',
'Saveetha Medical College and Hospital',
'Sawai Man Singh Medical College',
'School of Medical Sciences and Research, Greater Noida',
'Seth G.S. Medical College',
'Shadan Institute of Medical Sciences Teaching Hospital & Research Centre',
'Shahed Nirmal Mahto Medical College and Hospital',
'Shaheed Hasan Khan Mewati Government Medical College',
'Shaikh-Ul-Hind Maulana Mahmood Hasan Medical College',
'Sher-i-Kashmir Institute of Medical Sciences (SKIMS)',
'Shimoga Institute of Medical Sciences',
'Shri B.M. Patil Medical College Hospital & Research Centre, B.L.D.E. University',
'Shri Bhausaheb Hire Government Medical College',
'Shri Guru Ram Rai Institute of Medical & Health Sciences',
'Shri Ram Murti Smarak Institute of Medical Sciences',
'Shri Sathya Sai Medical College and Research Institute',
'Shri Vasantrao Naik Government Medical College',
'Shridevi Institute of Medical Sciences & Research Hospital',
'Shyam Shah Medical College',
'Siddhartha Medical College',
'Sikkim Manipal Institute of Medical Sciences, Sikkim Manipal University',
'Silchar Medical College',
'Smt. B.K. Shah Medical Institute and Research Centre',
'Smt. Kashibai Navale Medical College and Hospital',
'Smt. N.H.L. Municipal Medical College',
'Sree Balaji Medical College and Hospital',
'Sree Gokulam Medical College & Research Foundation',
'Sree Mookambika Institute of Medical Sciences',
'Sree Narayana Institute of Medical Sciences',
'Sree Uthradom Thirunal Academy of Medical Sciences',
'Sri Aurobindo Institute of Medical Sciences',
'Sri Devaraj Urs Medical College',
'Sri Guru Ram Das Institute of Medical Sciences & Research',
'Sri Krishna Medical College',
'Sri Lakshmi Narayana Institute of Medical Sciences',
'Sri Manakula Vinayagar Medical College & Hospital',
'Sri Muthukumaran Medical College Hospital and Research Institute',
'Sri Padmavathi Medical College for Women',
'Sri Ramachandra Institute of Higher Education and Research (Deemed University)',
'Sri Siddhartha Medical College',
'Sri Venkateshwaraa Medical College Hospital & Research Centre',
'Sri Venkateswara Medical College',
'Srinivas Institute of Medical Sciences and Research Centre',
'Sriram Chandra Bhanja Medical College',
'St. John\'s Medical College',
'Stanley Medical College',
'Subbaiah Institute of Medical Sciences',
'Subharti Medical College',
'Surat Municipal Institute of Medical Education and Research',
'Swami Ramanand Teerth Rural Medical College',
'T.D. Medical College',
'T.S. Misra Medical College & Hospital',
'Tagore Medical College and Hospital',
'Teerthankar Mahaveer Medical College',
'Terna Medical College',
'Thanjavur Medical College',
'The Oxford Medical College, Hospital & Research Centre',
'Theni Government Medical College',
'Thoothukudi Medical College',
'Tirunelveli Medical College',
'Topiwala National Medical College',
'Travancore Medical College',
'Trichy SRM Medical College Hospital and Research Centre',
'Tripura Medical College & Dr. B. R. Ambedkar Memorial Teaching Hospital',
'University College of Medical Sciences and Guru Teg Bahadur Hospital',
'Uttar Pradesh University of Medical Sciences',
'Vardhman Mahavir Medical College & Safdarjung Hospital',
'Veer Chandra Singh Garhwali Government Institute of Medical Science & Research',
'Veer Surendra Sai Institute of Medical Sciences and Research',
'Velammal Medical College Hospital and Research Institute',
'Venkateshwara Institute of Medical Sciences',
'Vijayanagar Institute of Medical Sciences',
'Vinayaka Mission\'s Kirupananda Variyar Medical College, Salem',
'Vinayaka Mission\'s Medical College, Karaikal',
'Viswabharathi Medical College',
'Vydehi Institute of Medical Sciences & Research Centre, Bangalore',
'Yenepoya Medical College',
'Jakarta Christian University (UKRIDA) Faculty of Medicine',
'Universitas Ahmad Yani Fakultas Kedokteran',
'Universitas Airlangga Fakultas Kedokteran',
'Universitas Andalas Fakultas Kedokteran',
'Universitas Baiturrahmah Fakultas Kedokteran',
'Universitas Brawijaya Fakultas Kedokteran',
'Universitas Diponegoro Fakultas Kedokteran',
'Universitas Gadjah Mada Sekip Fakultas Kedokteran, Kesehatan Masyarakat dan Keperawatan',
'Universitas Haluoleo Fakultas Kedokteran',
'Universitas Hang Tuah Fakultas Kedokteran',
'Universitas Hasanuddin Fakultas Kedokteran',
'Universitas Indonesia Fakultas Kedokteran',
'Universitas Islam Sultan Agung (UNISSULA) Fakultas Kedokteran',
'Universitas Islam Sumatra Utara Fakultas Kedokteran',
'Universitas Jenderal Soedirman Fakultas Kedokteran dan Ilmu-Ilmu Kesehatan',
'Universitas Katolik Indonesia Atma Jaya Fakultas Kedokteran',
'Universitas Kristen Indonesia Fakultas Kedokteran',
'Universitas Kristen Maranatha Fakultas Kedokteran',
'Universitas Lambung Mangkurat Fakultas Kedokteran',
'Universitas Methodist Indonesia Faculty of Medicine',
'Universitas Muhammadiyah Jakarta Fakultas Kedokteran dan Kesehatan',
'Universitas Muhammadiyah Malang Fakultas Kedokteran',
'Universitas Muhammadiyah Yogyakarta Fakultas Kedokteran',
'Universitas Mulawarman Fakultas Kedokteran',
'Universitas Muslim Indonesia Fakultas Kedokteran',
'Universitas Padjadjaran Fakultas Kedokteran',
'Universitas Pelita Harapan Fakultas Kedokteran',
'Universitas Pembangunan Nasional Veteran Fakultas Kedokteran',
'Universitas Prima Indonesia Fakultas Kedokteran',
'Universitas Riau Fakultas Kedokteran',
'Universitas Sam Ratulangi Fakultas Kedokteran',
'Universitas Sebelas Maret Fakultas Kedokteran',
'Universitas Sriwijaya Fakultas Kedokteran',
'Universitas Sumatera Utara Fakultas Kedokteran',
'Universitas Syiah Kuala Fakultas Kedokteran',
'Universitas Tarumanagara Fakultas Kedokteran',
'Universitas Trisakti Fakultas Kedokteran',
'Universitas Udayana Fakultas Kedokteran',
'Universitas Wijayakusuma Fakultas Kedokteran',
'Universitas Yarsi Fakultas Kedokteran',
'Abadan University of Medical Sciences Medical School',
'Ahvaz Jondishapour University of Medical Sciences',
'Alborz University of Medical Sciences School of Medicine',
'Arak University of Medical Sciences School of Medicine',
'Ardabil University of Medical Sciences Faculty of Medicine',
'Army University of Medical Sciences Medical School',
'Babol University of Medical Sciences Faculty of Medicine',
'Bam University of Medical Sciences and Health Services',
'Birjand University of Medical Sciences and Health Services Medical School',
'Bushehr University of Medical Sciences and Health Services',
'Dezful University of Medical Sciences and Health Services',
'Fasa University of Medical Sciences Faculty of Medicine',
'Golestan University of Medical Sciences Medical School',
'Gonabad University of Medical Sciences and Health',
'Guilan University of Medical Sciences',
'Hamadan University of Medical Sciences Medical School',
'Hormozgan University of Medical Sciences (HUMS) Faculty of Medicine',
'Ilam University of Medical Sciences School of Medicine',
'Iran University of Medical Sciences (IUMS) School of Medicine',
'Iranshahr University of Medical Sciences & Health Services',
'Isfahan University of Medical Sciences Faculty of Medicine',
'Islamic Azad University Ardabil Branch Faculty of Medicine',
'Islamic Azad University Kazeroon Branch Faculty of Medicine',
'Islamic Azad University Kerman Branch Faculty of Medicine',
'Islamic Azad University Mashhad Branch Faculty of Medicine',
'Islamic Azad University Najafabad Branch Faculty of Medical Science',
'Islamic Azad University Qeshm Branch Faculty of Medicine',
'Islamic Azad University Sari Branch Faculty of Medicine',
'Islamic Azad University Shahrood Branch Faculty of Medicine',
'Islamic Azad University Tabriz Branch Faculty of Medicine',
'Islamic Azad University Tehran Faculty of Medicine',
  "Islamic Azad University Tonekabon Branch Faculty of Medicine",
  "Islamic Azad University Yazd Ali ibn Abitaleb Medical School",
  "Islamic Azad University Zahedan Branch Faculty of Medicine",
  "Jahrom University of Medical Sciences Faculty of Medicine",
  "Jiroft University of Medical Sciences and Health Services",
  "Kashan University of Medical Sciences and Health Services Faculty of Medicine",
  "Kerman University of Medical Sciences Faculty of Medicine",
  "Kermanshah University of Medical Sciences School of Medicine",
  "Kurdistan University of Medical Sciences School of Medicine",
  "Lorestan University of Medical Sciences School of Medicine",
  "Mashhad University of Medical Sciences Faculty of Medicine",
  "Mazandaran University of Medical Sciences Faculty of Medicine",
  "North Khorasan University of Medical Sciences Faculty of Medicine",
  "Qazvin University of Medical Sciences Shahid Babaei Faculty of Medicine",
  "Qom Islamic Azad University Medicine College",
  "Qom University of Medical Sciences Faculty of Medicine",
  "Rafsanjan University of Medical Sciences School of Medicine",
  "Sabzevar Faculty of Medical Sciences School of Medicine",
  "Semnan University of Medical Sciences Faculty of Medicine",
  "Shahed University Faculty of Medicine",
  "Shahid Beheshti University of Medical Sciences Faculty of Medicine",
  "Shahid Sadooghi University of Medical Sciences College of Medicine",
  "Shahrekord University of Medical Sciences Faculty of Medicine",
  "Shahroud University of Medical Sciences Faculty of Medicine",
  "Shiraz University of Medical Sciences School of Medicine",
  "Tabriz University of Medical Sciences Faculty of Medicine",
  "Tehran University of Medical Sciences School of Medicine",
  "Urmia University of Medical Sciences Faculty of Medicine",
  "Yasuj University of Medical Sciences Faculty of Medicine",
  "Zabol University of Medical Sciences and Health Sciences",
  "Zahedan University of Medical Sciences Faculty of Medicine",
  "Zanjan University of Medical Sciences Faculty of Medicine",
  "Al-Kindy College of Medicine",
  "Al-Nahrain University College of Medicine",
  "Hawler Medical University College of Medicine",
  "Kufa University College of Medicine",
  "University of Al-Mustansiriyah College of Medicine",
  "University of Baghdad College of Medicine",
  "University of Basrah College of Medicine",
  "University of Duhok College of Medicine",
  "University of Mosul College of Medicine",
  "University of Wasit College of Medicine",
  "NUI Galway School of Medicine",
  "Royal College of Surgeons in Ireland School of Medicine",
  "Trinity College Dublin School of Medicine",
  "University College Cork School of Medicine",
  "Universidade Federal de Sergipe",
  "University College Dublin School of Medicine",
  "University of Limerick School of Medicine",
  "Bar-Ilan University Faculty of Medicine in the Galilee",
  "Ben-Gurion University of the Negev Faculty of Health Sciences",
  "Ruth and Bruce Rappaport Faculty of Medicine, Technion Israel Institute of Technology",
  "Sackler School of Medicine, Tel Aviv University",
  "Universidade Federal do Rio de Janeiro - Brazil",
  "B.P Koirala Institute of Health Sciences",
  'University of Cologne',
  "Ternopil National Medical University",
  "University of Paris Saclay",
  "Universidad Autonoma de Nuevo Leon",
  "University of Novi Sad Faculty of Medicine",
  "National University of Singapore",
  "Medical University of Vienna",
  "Heinrich Heine University Düsseldorf",
  "The Hebrew University-Hadassah Medical School",
  "University of the West Indies Faculty of Medical Sciences, Jamaica",
  "Aichi Medical University",
  "Akita University School of Medicine",
  "Asahikawa Medical University",
  "Chiba University School of Medicine",
  "Dokkyo Medical University",
  "Ehime University School of Medicine",
  "Fujita Health University School of Medicine",
  "Fukuoka University School of Medicine",
  "Fukushima Medical University School of Medicine",
  "Gifu University School of Medicine",
  "Gunma University Faculty of Medicine",
  "Hamamatsu University School of Medicine",
  "Hirosaki University School of Medicine",
  "Hiroshima University Faculty of Medicine",
  "Hokkaido University School of Medicine",
  "Hyogo College of Medicine",
  "Iwate Medical University School of Medicine",
  "Jichi Medical University School of Medicine",
  "Jikei University School of Medicine",
  "Juntendo University School of Medicine",
  "Kagawa University Faculty of Medicine",
  "Kagoshima University Faculty of Medicine",
  "Kanazawa Medical University School of Medicine",
  "Kanazawa University School of Medicine",
  "Kansai Medical University School of Medicine",
  "Kawasaki Medical School",
  "Keio University School of Medicine",
  "Kindai University School of Medicine",
  "Kitasato University School of Medicine",
  "Kobe University School of Medicine",
  "Kumamoto University School of Medicine",
  "Kurume University School of Medicine",
  "Kyorin University School of Medicine",
  "Kyoto Prefectural University of Medicine",
  "Kyoto University Faculty of Medicine",
  "Kyushu University Faculty of Medicine",
  "Mie University School of Medicine",
  "Nagasaki University School of Medicine",
  "Nagoya City University Medical School",
  "Nagoya University School of Medicine",
  "Nara Medical University Faculty of Medicine",
  "National Defense Medical College",
  "Nihon University School of Medicine",
  "Niigata University School of Medicine",
  "Nippon Medical School",
  "Oita University Faculty of Medicine",
  "Okayama University School of Medicine",
  "Osaka City University Medical School",
  "Osaka Medical College",
  "Osaka University School of Medicine",
  "Saga University Faculty of Medicine",
  "Saitama Medical University Faculty of Medicine",
  "Sapporo Medical University School of Medicine",
  "Shiga University of Medical Science",
  "Shinshu University Faculty of Medicine",
  "Showa University School of Medicine",
  "St. Marianna University School of Medicine",
  "Teikyo University School of Medicine",
  "Toho University Faculty of Medicine",
  "Tohoku University School of Medicine",
  "Tokai University School of Medicine",
  "Tokushima University School of Medicine",
  "Tokyo Medical and Dental University Faculty of Medicine",
  "Tokyo Medical University",
  "Tokyo Women's Medical University School of Medicine",
  "Tottori University Faculty of Medicine",
  "University of Miyazaki Faculty of Medicine",
  "University of Occupational & Environmental Health School of Medicine",
  "University of the Ryukyus Faculty of Medicine",
  "University of Tokyo Faculty of Medicine",
  "University of Toyama School of Medicine",
  "University of Tsukuba School of Medicine",
  "University of Yamanashi Faculty of Medicine",
  "Wakayama Medical University School of Medicine",
  "Yamagata University School of Medicine",
  "Yamaguchi University Faculty of Medicine and Health Sciences",
  "Yokohama City University School of Medicine",
  "Al-Balqa Applied University Faculty of Medicine",
  "Hashemite University Faculty of Medicine",
  "Jordan University of Science and Technology Faculty of Medicine",
  "Mu'tah University Faculty of Medicine",
  "University of Jordan Faculty of Medicine",
  "Yarmouk University Faculty of Medicine",
  "International Kazakh-Turkish University named after Khoja Ahmet Yassawi Faculty of Medicine",
  "JSC National Medical University",
  "Karaganda Medical University",
  "Kazakh-Russian Medical University",
  "NJSC Astana Medical University",
  "Semey Medical University",
  "South Kazakhstan State Pharmaceutical Academy",
  "West Kazakhstan State Medical University named after Marat Ospanov",
  "Kuwait University Faculty of Medicine",
  "Adam University School of Medicine",
  "Al-Farabi Kazakh National University Faculty of Medicine and Health Care",
  "Ala-Too International University",
  "Avicenna International Medical University",
  "Eurasian International University Faculty of Medicine",
  "I.K. Akhunbaev Kyrgyz State Medical Academy Faculty of General Medicine",
  "International Higher School of Medicine",
  "International Medical University",
  "International School of Medicine, International University of Kyrgyzstan Eastern Medical Campus",
  "Jalal-Abad State University Medical Faculty",
  "Kyrgyz-Russian Slavic State University (KRSU) Medical Faculty",
  "Osh International Medical University",
  "Osh State University Medical Faculty",
  "Royal Metropolitan University",
  "S. Tentishev Asian Medical Institute",
  "Salymbekov University Faculty of Medicine",
  "American University of Beirut Faculty of Medicine",
  "Beirut Arab University Faculty of Medicine",
  "LAU Gilbert and Rose-Marie Chagoury School of Medicine",
  "Université Saint Esprit de Kaslik Faculté de Médecine et des Sciences Médicales",
  "University of Balamand Faculty of Medicine and Medical Sciences",
  "Libyan International Medical University Faculty of Medicine",
  "Misurata University Faculty of Medicine",
  "University of Benghazi Faculty of Medicine",
  "University of Tripoli Faculty of Medicine",
  "University of Zawia Faculty of Medicine",
  "Lithuanian University of Health Sciences Faculty of Medicine",
  "Vilnius University Faculty of Medicine",
  "Asian Institute of Medicine, Science and Technology (AIMST)",
  "International Islamic University Malaysia Kulliyyah of Medicine",
  "International Medical School, Management and Science University",
  "International Medical University Faculty of Medicine and Health",
  "Jeffrey Cheah School of Medicine and Health Sciences (Monash University)",
  "Lincoln University College Faculty of Medicine",
  "MAHSA University Faculty of Medicine",
  "Manipal University College Malaysia",
  "Newcastle University Medicine Malaysia",
  "Perdana University Graduate School of Medicine",
  "Perdana University Royal College of Surgeons in Ireland (PU-RCSI) School of Medicine",
  "Quest International University Perak Faculty of Medicine",
  "RCSI & UCD Malaysia Campus",
  "SEGi University",
  "Taylor's University School of Medicine",
  "UCSI University Faculty of Medicine and Health Sciences",
  "Universiti Kebangsaan Malaysia Fakulti Perubatan",
  "Universiti Kuala Lumpur Royal College of Medicine Perak",
  "Universiti Malaya Fakulti Perubatan",
  "Universiti Malaysia Sabah Fakulti Perubatan dan Sains Kesihatan",
  "Universiti Malaysia Sarawak Fakulti Perubatan dan Sains Kesihatan",
  "Universiti Putra Malaysia Fakulti Perubatan dan Sains Kesihatan",
  "Universiti Sains Islam Malaysia Fakulti Perubatan dan Sains Kesihatan",
  "Universiti Sains Malaysia School of Medical Sciences",
  "Universiti Sultan Zainal Abidin Faculty of Medicine",
  "Universiti Teknologi Mara Faculty of Medicine",
  "Universiti Tunku Abdul Rahman Faculty of Medicine and Health Sciences",
  "University of Cyberjaya Faculty of Medicine",
  "Benemérita Universidad Autónoma de Puebla Facultad de Medicina",
  "Centro de Estudios Universitarios Xochicalco, Escuela de Medicina Ensenada",
  "Centro de Estudios Universitarios Xochicalco, Escuela de Medicina Mexicali",
  "Centro de Estudios Universitarios Xochicalco, Escuela de Medicina Tijuana",
  "Escuela Médico Militar, Universidad del Ejército y Fuerza Aérea",
  "Escuela Médico Naval de la Secretaría de Marina",
  "Instituto de Estudios Superiores de Chiapas Escuela de Medicina Tuxtla Gutierrez",
  "Instituto Politécnico Nacional Escuela Nacional de Medicina y Homeopatía",
  "Instituto Politécnico Nacional Escuela Superior de Medicina",
  "Tecnológico de Monterrey Escuela de Medicina Monterrey",
  "Universidad Anáhuac Cancún Escuela Internacional de Medicina",
  "Universidad Anáhuac Mayab Escuela de Medicina",
  "Universidad Anáhuac México Escuela de Medicina",
  "Universidad Anáhuac Puebla Escuela de Medicina",
  "Universidad Anáhuac Querétaro Escuela de Medicina",
  "Universidad Anáhuac Xalapa Escuela de Medicina",
  "Universidad Autónoma Benito Juárez de Oaxaca Facultad de Medicina y Cirugía",
  "Universidad Autónoma de Aguascalientes Centro de Ciencias de la Salud",
  "Universidad Autónoma de Baja California Escuela de Ciencias de la Salud Ensenada",
  "Universidad Autónoma de Baja California Escuela de Ciencias de la Salud, Unidad Valle de las Palmas",
  "Universidad Autónoma de Baja California Facultad de Medicina Mexicali",
  "Universidad Autónoma de Baja California Tijuana Facultad de Medicina y Psicología",
  "Universidad Autónoma de Campeche Facultad de Medicina",
  "Universidad Autónoma de Chiapas Facultad de Medicina Humana",
  "Universidad Autónoma de Chihuahua Facultad de Medicina",
  "Universidad Autónoma de Ciudad Juárez Instituto de Ciencias Biomédicas",
  "Universidad Autónoma de Coahuila Escuela de Medicina de Torreón",
    "Universidad Autónoma de Coahuila Facultad de Medicina Saltillo",
    "Universidad Autónoma de Durango Campus Zacatecas Escuela de Medicina",
    "Universidad Autónoma de Guadalajara Facultad de Medicina Guadalajara",
    "Universidad Autónoma de Guadalajara Facultad de Medicina Tabasco",
    "Universidad Autónoma de Nayarit Escuela de Medicina",
    "Universidad Autónoma de Querétaro Facultad de Medicina",
    "Universidad Autónoma de San Luis Potosí Facultad de Medicina",
    "Universidad Autónoma de Sinaloa Facultad de Medcina Culiacán",
    "Universidad Autónoma de Tamaulipas Facultad de Medicina de Tampico 'Dr. Alberto Romo Caballero'",
    "Universidad Autónoma de Tamaulipas Facultad de Medicina Humana de Matamoros",
    "Universidad Autónoma de Yucatán Facultad de Medicina",
    "Universidad Autónoma de Zacatecas Unidad Académica de Medicina y Ciencias de la Salud",
    "Universidad Autónoma del Estado de Hidalgo Instituto de Ciencias de la Salud",
    "Universidad Autónoma del Estado de México Facultad de Medicina",
    "Universidad Autónoma del Estado de Morelos Facultad de Medicina",
    "Universidad Cristóbal Colón Escuela de Medicina",
    "Universidad Cuauhtémoc Plantel Aguascalientes Escuela de Medicina",
    "Universidad de Colima Facultad de Medicina",
    "Universidad de Guadalajara Centro Universitario de Ciencias de la Salud",
    "Universidad de Guadalajara Centro Universitario del Sur",
    "Universidad de Guanajuato División de Ciencias de la Salud",
    "Universidad de la Salle Facultad Mexicana de Medicina",
    "Universidad de las Américas Puebla",
    "Universidad de Montemorelos Escuela de Medicina",
    "Universidad de Monterrey Facultad de Medicina",
    "Universidad de Sonora Departamento de Medicina y Ciencias de la Salud",
    "Universidad del Noreste Escuela de Medicina 'Dr. José Sierra Flores'",
    "Universidad del Valle de México Campus Querétaro Facultad de Medicina",
    "Universidad del Valle de México Campus Zapopan",
    "Universidad Juárez Autónoma de Tabasco Escuela de Medicina Humana",
    "Universidad Juárez del Estado de Durango Facultad de Ciencias de la Salud de Gómez Palacio",
    "Universidad Juárez del Estado de Durango Facultad de Medicina y Nutrición",
    "Universidad Justo Sierra Escuela de Medicina",
    "Universidad Lamar Escuela de Medicina, Campus Vallarta",
    "Universidad Latinoamericana Escuela de Medicina, Campus Cuernavaca",
    "Universidad Marista de Mérida",
    "Universidad Nacional Autónoma de México Facultad de Estudios Superiores Iztacala",
    "Universidad Nacional Autónoma de México Facultad de Estudios Superiores Zaragoza",
    "Universidad Nacional Autónoma de México Facultad de Medicina",
    "Universidad Pablo Guajardo Chávez",
    "Universidad Panamericana Escuela de Medicina",
    "Universidad Popular Autónoma del Estado de Puebla Facultad de Medicina",
    "Universidad Quetzalcóatl en Irapuato Escuela de Medicina",
    "Universidad Regional del Sureste Escuela de Medicina e Cirugía",
    "Universidad Vasco de Quiroga Escuela de Medicina General",
    "Universidad Veracruzana Facultad de Medicina Minatitlán",
    "Universidad Veracruzana Facultad de Medicina Orizaba-Córdoba",
    "Universidad Veracruzana Facultad de Medicina Poza Rica",
    "Universidad Veracruzana Facultad de Medicina Veracruz",
    "Universidad Veracruzana Facultad de Medicina Xalapa",
    "Universidad Westhill Facultad de Medicina",
    "State University of Medicine and Pharmacy Nicolae Testemitanu",
    "Erasmus Universiteit Faculteit der Geneeskunde",
    "Leiden University Medical Center",
    "Maastricht University Faculty of Health, Medicine and Life Sciences",
    "Radboud Universiteit Nijmegen Faculteit der Medische Wetenschappen",
    "Rijksuniversiteit Groningen Faculteit der Medische Wetenschappen",
    "Universiteit Utrecht Faculteit Geneeskunde",
    "Universiteit van Amsterdam Faculteit der Geneeskunde",
    "VUmc School of Medical Sciences",
    "Otago Medical School",
    "University of Auckland Faculty of Medicine and Health Sciences",
    "Abbottabad International Medical College",
    "Abwa Medical College",
    "Aga Khan University Medical College",
    "Akhtar Saeed Medical & Dental College",
    "Al-Aleem Medical College",
    "Al-Nafees Medical College",
    "Al-Tibri Medical College",
    "Allama Iqbal Medical College",
    "Ameer-ud-Din Medical College",
    "Amna Inayat Medical College",
    "Army Medical College",
    "Avicenna Medical College",
    "Ayub Medical College",
    "Azad Jammu & Kashmir Medical College",
    "Aziz Fatimah Medical & Dental College",
    "Azra Naheed Medical College",
    "Bacha Khan Medical College",
    "Bahria University Medical & Dental College",
    "Bakhtawar Amin Medical and Dental College",
    "Bannu Medical College",
    "Baqai Medical College",
    "Bilawal Medical College",
    "Bolan Medical College",
    "C.M.H. Institute of Medical Sciences, Bahawalpur",
    "C.M.H. Kharian Medical College",
    "C.M.H. Lahore Medical College and Institute of Dentistry",
    "C.M.H. Multan Institute of Medical Sciences",
    "Central Park Medical College",
    "Chandka Medical College",
    "Continental Medical College",
    "D.G. Khan Medical College",
    "Dow International Medical College (DIMC)",
    "Dow Medical College",
    "F.M.H. College of Medicine and Dentistry",
    "Faisalabad Medical University",
    "Fatima Jinnah Medical University",
    "Fazaia Medical College",
    "Fazaia Ruth Pfau Medical College",
    "Federal Medical and Dental College",
    "Foundation University Medical College (FUMC)",
    "Frontier Medical & Dental College, Abbottabad",
    "Gajju Khan Medical College",
    "Gambat Medical College of Pir Abdul Qadir Shah Jeelani Institute of Medical Sciences (PAQSJIMS)",
    "Ghulam Mohammad Mahar Medical College",
    "Gomal Medical College",
    "Gujranwala Medical College",
    "H.B.S. Medical & Dental College",
    "Hamdard College of Medicine & Dentistry",
    "Hashmat Medical & Dental College",
    "HITEC Institute of Medical Sciences",
    "Independent Medical College",
    "Indus Medical College",
    "Islam Medical College",
    "Islamabad Medical & Dental College",
    "Islamic International Medical College",
    "Isra University, Hyderabad Faculty of Medicine and Allied Medical Sciences",
    "Jinnah Medical and Dental College",
    "Jinnah Medical College",
    "Jinnah Sindh Medical University",
    "Kabir Medical College",
    "Karachi Institute of Medical Sciences (KIMS)",
    "Karachi Medical and Dental College",
    "Khairpur Medical College",
    "Khawaja Muhammad Safdar Medical College",
    "Khyber Girls Medical College",
    "Khyber Medical College",
    "King Edward Medical University",
    "KMU Institute of Medical Sciences",
    "Lahore Medical and Dental College",
    "Liaquat College of Medicine and Dentistry",
    "Liaquat National Hospital and Medical College",
    "Liaquat University of Medical & Health Sciences Jamshoro",
    "Mohiuddin Islamic Medical College",
    "Mohtarma Benazir Bhutto Shaheed Medical College",
    "Muhammad Medical College",
    "Multan Medical and Dental College",
    "Nawaz Sharif Medical College",
    "Niazi Medical and Dental College",
    "Nishtar Medical University",
    "Northwest School of Medicine",
    "Nowshera Medical College",
    "Pak International Medical College",
    "Pak Red Crescent Medical & Dental College",
    "People's University of Medical & Health Sciences for Women",
    "Peshawar Medical College",
    "Poonch Medical College",
    "Quaid-e-Azam Medical College",
    "Quetta Institute of Medical Sciences",
    "Rahbar Medical and Dental College",
    "Rai Medical College",
    "Rashid Latif Medical College",
    "Rawal Institute of Health Sciences",
    "Rawalpindi Medical University",
    "Rehman Medical College",
    "Sahara Medical College",
    "Sahiwal Medical College",
    "Saidu Medical College",
    "Sargodha Medical College",
    "Services Institute of Medical Sciences",
    "Shaheed Mohtarma Benazir Bhutto Medical College",
    "Shahida Islam Medical College",
    "Shaikh Khalifa Bin Zayed Al-Nahyan Medical and Dental College",
    "Shalamar Medical and Dental College",
    "Sharif Medical and Dental College",
    "Sheikh Zayed Medical College and Hospital",
    "Shifa College of Medicine",
    "Sialkot Medical College",
    "Sir Syed College of Medical Sciences for Girls",
    "United Medical & Dental College",
    "University College of Medicine & Dentistry Lahore",
    "University Medical & Dental College Faisalabad",
    "Wah Medical College",
    "Women Medical & Dental College",
    "Yusra Medical & Dental College",
    "Ziauddin Medical College",
    "Universidad de Panamá Facultad de Medicina",
    "Angeles University Foundation School of Medicine",
    "Ateneo de Zamboanga University College of Medicine",
    "Cebu Doctors' University College of Medicine",
    "Cebu Institute of Medicine",
    "Davao Medical School Foundation College of Medicine",
    "De La Salle Medical and Health Sciences Institute College of Medicine",
    "Dr. Jose P. Rizal College of Medicine",
    "Far Eastern University Institute of Medicine, Nicanor Reyes Medical Foundation",
    "Manila Central University-Filemon D. Tanchoco Foundation College of Medicine",
    "Our Lady of Fatima University College of Medicine",
    "San Beda University College of Medicine",
    "Silliman University Medical School",
    "St. Luke's Medical Center College of Medicine - William H. Quasha Memorial (SLMCCM-WHQM)",
    "University of Santo Tomas Faculty of Medicine and Surgery",
    "University of the City of Manila College of Medicine",
    "University of the East/Ramon Magsaysay Memorial Medical Center College of Medicine",
    "University of the Philippines Manila College of Medicine",
    "West Visayas State University College of Medicine",
    "Collegium Medicum im. Ludwika Rydygiera, Uniwersytet Mikolaja Kopernika w Toruniu",
    "Faculty of Medicine and Dentistry in Zabrze",
    "Jagiellonian University Medical College",
    "Jan Kochanowski University Collegium Medicum",
    "Krakowska Akademia im. Andrzeja Frycza Modrzewskiego Wydział Lekarski i Nauk o Zdrowiu",
    "Medical University of Bialystok Faculty of Medicine",
    "Medical University of Gdańsk Faculty of Medicine",
    "Medical University of Lodz Faculty of Medicine",
    "Medical University of Lublin",
    "Medical University of Silesia, School of Medicine in Katowice",
    "Medical University of Warsaw Faculty of Medicine",
    "Opole University Faculty of Medicine",
    "Pomorski Uniwersytet Medyczny w Szczecinie",
    "Poznan University of Medical Sciences Center for Medical Education in English",
    "Poznan University of Medical Sciences, Faculty of Medicine I",
    "Uczelnia Łazarskiego Wydział Medyczny",
    "University of Rzeszów Faculty of Medicine",
    "University of Warmia and Mazury in Olsztyn, Collegium Medicum, School of Medicine",
    "University of Zielona Góra Faculty of Medicine and Health Sciences",
    "Wroclaw Medical University Faculty of Medcine",
    "Wyższa Szkoła Techniczna w Katowicach Wydział Nauk Medycznych",
    "Weill Cornell Medical College in Qatar",
    "Universitatea de Medicină și Farmacie Grigore T. Popa Facultatea de Medicină",
    "Universitatea de Medicină și Farmacie Victor Babeş Facultatea de Medicină",
    "Universitatea de Medicină, Farmacie, Științe și Tehnologie 'George Emil Palade' Târgu Mureș",
    "Altai State Medical University",
    "Bashkir State Medical University",
    "Cecenja State University Medical Institute",
    "Ingush State University Faculty of Medicine",
    "Kazan State Medical University",
    "Kemerovo State Medical University",
    "Kirov State Medical University",
    "Kursk State Medical University",
  "North Ossetian State Medical Academy",
  "Samara State Medical University",
  "Siberian State Medical University",
  "Smolensk State Medical University",
  "Volgograd State Medical University",
  "Saba University School of Medicine",
  "Medical University of the Americas (Nevis)",
  "University of Medicine and Health Sciences, St. Kitts",
  "Windsor University School of Medicine",
  "Saint James School of Medicine St. Vincent and the Grenadines",
  "Trinity School of Medicine",
  "Oceania University of Medicine",
  "Al Baha University School of Medicine",
  "Al Jouf University College of Medicine",
  "Al-Imam Muhammad Bin Saud Islamic University College of Medicine",
  "Alfaisal University College of Medicine",
  "Almajmaah University College of Medicine",
  "Batterjee Medical College for Sciences and Technology",
  "College of Medicine Jeddah, King Saud Bin Abdulaziz University for Health Sciences",
  "College of Medicine of Vision Colleges",
  "Dar Al Uloom University College of Medicine",
  "Ibn Sina National College for Medical Studies Faculty of Medicine",
  "Imam Abdulrahman Bin Faisal University College of Medicine",
  "Jazan University Faculty of Medicine",
  "King Abdulaziz University College of Medicine",
  "King Faisal University, College of Medicine in Al-Ahsa",
  "King Khalid University College of Medicine and Health Sciences",
  "King Saud bin Abdulaziz University for Health Sciences College of Medicine",
  "King Saud University, Riyadh College of Medicine",
  "Najran University College of Medicine",
  "Prince Sattam bin Abdulaziz University College of Medicine",
  "Princess Nourah Bint Abdulrahman University College of Medicine",
  "Qassim University College of Medicine",
  "Sulaiman Alrajhi University College of Medicine",
  "Taibah University College of Medicine",
  "Taif University College of Medicine",
  "Umm Al-Qura University Faculty of Medicine",
  "University of Ha’il College of Medicine",
  "University of Tabuk Faculty of Medicine",
  "Duke-NUS Medical School",
  "American University of the Caribbean School of Medicine",
  "Caribbean Medical University School of Medicine",
  "University of Geneva",
  "Ajou University School of Medicine",
  "Catholic Kwandong University College of Medicine",
  "Catholic University of Daegu School of Medicine",
  "Catholic University of Korea College of Medicine",
  "Cha University School of Medicine",
  "Chonnam National University College of Medicine",
  "Chosun University College of Medicine",
  "Chung-Ang University College of Medicine",
  "Chungbuk National University College of Medicine",
  "Chungnam National University College of Medicine",
  "Dankook University College of Medicine",
  "Dong-A University College of Medicine",
  "Dongguk University School of Medicine",
  "Eulji University College of Medicine",
  "Ewha Womans University School of Medicine",
  "Gachon University School of Medicine",
  "Gyeongsang National University College of Medicine",
  "Hallym University College of Medicine",
  "Hanyang University College of Medicine",
  "Inha University College of Medicine",
  "Inje University College of Medicine",
  "Jeju National University School of Medicine",
  "Jeonbuk National University College of Medicine",
  "Kangwon National University School of Medicine",
  "Keimyung University College of Medicine",
  "Konkuk University College of Medicine",
  "Konyang University College of Medicine",
  "Korea University College of Medicine",
  "Kosin University College of Medicine",
  "Kyung Hee University College of Medicine",
  "Kyungpook National University School of Medicine",
  "Pusan National University School of Medicine",
  "Seoul National University College of Medicine",
  "Soon Chun Hyang University College of Medicine",
  "Sungkyunkwan University School of Medicine & Graduate School of Medicine",
  "University of Ulsan College of Medicine",
  "Wonkwang University College of Medicine",
  "Yeungnam University College of Medicine",
  "Yonsei University College of Medicine",
  "Yonsei University Wonju College of Medicine",
  "Universidad Complutense de Madrid Facultad de Medicina",
  "Universidad de Castilla-La Mancha Facultad de Medicina de Albacete",
  "Universidad de Navarra Facultad de Medicina",
  "Universidad Francisco de Vitoria Facultad de Ciencias Biosanitarias",
  "Faculty of Health-Care Sciences, Eastern University, Sri Lanka",
  "General Sir John Kotelawala Defence University Faculty of Medicine",
  "Rajarata University of Sri Lanka Faculty of Medicine and Allied Sciences",
  "University of Colombo Faculty of Medicine",
  "University of Jaffna Faculty of Medicine",
  "University of Kelaniya Faculty of Medicine",
  "University of Peradeniya Faculty of Medicine",
  "University of Ruhuna Faculty of Medicine",
  "University of Sri Jayewardenepura Faculty of Medical Sciences",
  "University of Gezira Faculty of Medicine",
  "University of Khartoum Faculty of Medicine",
  "Universität Basel Medizinische Fakultät",
  "Universität Bern Medizinische Fakultät",
  "Universität Zürich Medizinische Fakultät",
  "Université de Fribourg Faculté des Sciences et de Médecine",
  "Université de Genève Faculté de Médecine",
  "Université de Lausanne Faculté de Biologie et de Médecine",
  "University of Damascus Faculty of Medicine",
  "University of Tishreen Faculty of Medicine",
  "Chang Gung University College of Medicine",
  "China Medical University College of Medicine",
  "Chung Shan Medical University",
  "Fu Jen Catholic University School of Medicine",
  "Kaohsiung Medical University",
  "Mackay Medical College",
  "National Cheng Kung University College of Medicine",
  "National Defense Medical Center",
  "National Taiwan University College of Medicine",
  "National Yang-Ming University School of Medicine",
  "Taipei Medical University College of Medicine",
  "Tzu Chi University College of Medicine",
  "Avicenna Tajik State Medical University",
  "Burapha University Faculty of Medicine",
  "Chiang Mai University Faculty of Medicine",
  "Chulalongkorn University Faculty of Medicine",
  "Faculty of Medicine Vajira Hospital",
  "HRH Princess Chulabhorn College of Medical Science",
  "Khon Kaen University Faculty of Medicine",
  "King Mongkut's Institute of Technology Ladkrabang Faculty of Medicine",
  "Mae Fah Luang University School of Medicine",
  "Mahasarakham University Faculty of Medicine",
  "Naresuan University Faculty of Medicine",
  "Phramongkutklao Medical College",
  "Prince of Songkla University Faculty of Medicine",
  "Princess of Naradhiwas University Faculty of Medicine",
  "Ramathibodi Hospital Faculty of Medicine",
  "Rangsit University Faculty of Medicine",
  "Siam University Faculty of Medicine",
  "Siriraj Hospital Faculty of Medicine",
  "Srinakharinwirot University Faculty of Medicine",
  "Suranaree University of Technology Institute of Medicine",
  "Thammasat University Faculty of Medicine",
  "Ubon Ratchathani University College of Medicine and Public Health",
  "Walailak University School of Medicine",
  "University of the West Indies Faculty of Medicine St. Augustine, Trinidad",
  "Acibadem Mehmet Ali Aydinlar Üniversitesi Tıp Fakültesi",
  "Akdeniz Üniversitesi Tıp Fakültesi",
  "Ankara Üniversitesi Tıp Fakültesi",
  "Ataturk Üniversitesi Tıp Fakültesi",
  "Baskent Üniversitesi Tıp Fakültesi",
  "Bezmialem Vakif Üniversitesi Tıp Fakültesi",
  "Canakkale Onsekiz Mart Üniversitesi Tıp Fakültesi",
  "Celal Bayar Üniversitesi Tıp Fakültesi",
  "Cukurova Üniversitesi Tıp Fakültesi",
  "Cumhuriyet Üniversitesi Tıp Fakültesi",
  "Dicle Üniversitesi Tıp Fakültesi",
  "Dokuz Eylül Üniversitesi Tıp Fakültesi",
  "Ege Üniversitesi Tıp Fakültesi",
  "Erciyes Üniversitesi Tıp Fakültesi",
  "Eskişehir Osmangazi Üniversitesi Tıp Fakültesi",
  "Firat Üniversitesi Tıp Fakültesi",
  "Gazi Üniversitesi Tıp Fakültesi",
  "Gaziantep Üniversitesi Tıp Fakültesi",
  "Hacettepe Üniversitesi Tıp Fakültesi",
  "İnönü Üniversitesi Tıp Fakültesi",
  "İstanbul Medipol Üniversitesi Tıp Fakültesi",
  "İstanbul Üniversitesi, Cerrahpasa Tıp Fakültesi",
  "İstanbul Üniversitesi, İstanbul Tıp Fakültesi",
  "İzmir Kâtip Çelebi Üniversitesi Tıp Fakültesi",
  "Karadeniz Teknik Üniversitesi Tıp Fakültesi",
  "Koç Üniversitesi Tıp Fakültesi",
  "Kocaeli Üniversitesi Tip Fakültesi",
  "Marmara Üniversitesi Tıp Fakültesi",
  "Mersin Üniversitesi Tıp Fakültesi",
  "Namik Kemal Üniversitesi Tıp Fakültesi",
  "Ondokuz Mayis Üniversitesi Tıp Fakültesi",
  "Pamukkale Üniversitesi Tıp Fakültesi",
  "Sağlık Bilimleri Üniversitesi Gülhane Tıp Fakültesi",
  "Selçuk Üniversitesi Tıp Fakültesi",
  "Süleyman Demirel Üniversitesi Tıp Fakültesi",
  "Trakya Üniversitesi Tıp Fakültesi",
  "Ufuk Üniversitesi Tıp Fakültesi",
  "Uludag Üniversitesi Tıp Fakültesi",
  "Yeditepe Üniversitesi Tıp Fakültesi",
  "Zonguldak Bülent Ecevit University Tıp Fakültesi",
  "Dnipro State Medical University",
  "International European University European Medical School",
  "Kharkiv National Medical University",
  "Odessa National Medical University",
  "Poltava State Medical University",
  "Sumy State University Medical Institute",
  "Ajman University College of Medicine",
  "Dubai Medical College for Girls",
  "Gulf Medical University College of Medicine",
  "Khalifa University College of Medicine and Health Sciences",
  "Mohammed Bin Rashid University of Medicine and Health Sciences",
  "RAK College of Medical Sciences",
  "United Arab Emirates University College of Medicine and Health Sciences",
  "University of Sharjah College of Medicine",
  "Barts and the London School of Medicine and Dentistry",
  "Brighton and Sussex Medical School",
  "Bristol Medical School, University of Bristol",
  "Cardiff University School of Medicine",
  "Edinburgh Medical School, College of Medicine and Veterinary Medicine, University of Edinburgh",
  "Hull York Medical School",
  "Imperial College London Faculty of Medicine",
  "Keele University School of Medicine",
  "King's College London GKT School of Medicine",
  "Lancaster University Medical School",
  "Leicester Medical School, University of Leicester",
  "Manchester Medical School",
  "Newcastle University Faculty of Medical Sciences",
  "Norwich Medical School, University of East Anglia",
  "Peninsula Medical School, University of Plymouth",
  "Queen's University Belfast School of Medicine, Dentistry and Biomedical Sciences",
  "Sheffield University School of Medicine and Biomedical Sciences",
  "St. George's University of London",
  "Swansea University Medical School",
  "UCL Medical School",
  "University of Aberdeen School of Medicine, Medical Sciences and Nutrition",
  "University of Birmingham College of Medical and Dental Sciences",
  "University of Buckingham Medical School",
  "University of Cambridge School of Clinical Medicine",
  "University of Central Lancashire School of Medicine",
  "University of Dundee School of Medicine",
  "University of Exeter College of Medicine and Health",
  "University of Glasgow School of Medicine, Dentistry and Nursing",
  "University of Leeds School of Medicine",
  "University of Liverpool School of Medicine",
  "University of Nottingham School of Medicine",
  "University of Oxford Medical Sciences Division",
  "University of Southampton Faculty of Medicine",
  "Warwick Medical School",
  "Tashkent Pediatric Medical Institute",
  'Universidad Central de Venezuela',
];

export const formattedSchools = internationalSchools.map(school => ({
  label: school,
  value: school
}));
