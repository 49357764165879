import React,{useState, useContext, useEffect} from "react";
import ScreenedSectionsBar from "./screenedSectionsBar";
import ScreenedItem from "./screenedItem";
import { ProgramIdContext } from "../../progIdContext";
import { useMutation } from '@apollo/client';
import {UPDATE_SCREENED_DATA} from '../graphql/mutations';
import Spinner from "../../../common/spinner";
import "./index.css";


function cleanData(data) {
    var cleaned = JSON.parse(JSON.stringify(data), (key, value) =>
        key === '__typename' || key === 'clerkshipGrades' || key === 'elaborateResponsibilities' 
        || key === 'misdemeanorOrFelonyDetails' || key === 'elaborateMalpractice' 
        || key === 'aoaStatus' || key === 'otherLanguagesFluentIn'
        || key === 'medicalSchoolType' || key === 'medicalSchoolName' ? undefined : value
    );

    const personalData = {
        profilePicture: cleaned.personalInfo.profilePicture,
        firstName: cleaned.personalInfo.firstName,
        lastName: cleaned.personalInfo.lastName,
        prefferedName: cleaned.personalInfo.prefferedName,
        previousLastName: cleaned.personalInfo.previousLastName,
        pronouns: cleaned.personalInfo.pronouns,
        dateOfBirth: cleaned.personalInfo.dateOfBirth,
        email: cleaned.personalInfo.email,
        phoneNumber: cleaned.personalInfo.phoneNumber,
        genderIdentity: cleaned.personalInfo.genderIdentity,
        nrmpId: cleaned.personalInfo.nrmpId,
        usmleId: cleaned.personalInfo.usmleId,
        ...cleaned.personalInfo
    }



    const residencyData = {
        ...cleaned.residencyApplication,
        sigmaPhi: cleaned.personalInfo.sigmaPhi
    }

    cleaned.personalInfo = personalData
    cleaned.residencyApplication = residencyData
    delete cleaned.personalInfo.sigmaPhi

    console.log("cleaned =>", cleaned)

    return cleaned
}



const CustomizeApplicationSettings = ({screenedData}) => {

    const [currentSection, setCurrentSection] = useState('personalInfo');
    const cleanedData = cleanData(screenedData);
    const [data, setData] = useState(cleanedData);
    const programId = useContext(ProgramIdContext);
    const [updateScreenedData, { loading }] = useMutation(UPDATE_SCREENED_DATA, {
        onCompleted: (data) => {
            setData(prevState => ({
                ...prevState,
                [data.updateScreenedData.updatedField]: data.updateScreenedData.screenedData[data.updateScreenedData.updatedField]
            }));
           

        }
    });
    let prevData = cleanedData;


    useEffect(() => {
        if (currentSection in data) {
            setData(prevState => ({
                ...prevState,
                [currentSection]: data[currentSection]
            }));
        }
    }, [currentSection]);

    useEffect(() => {
        console.log("data before update =>", data)
        if (JSON.stringify(cleanData(data)) !== JSON.stringify(prevData)) {
            let cpy = {...data}
            cpy.personalInfo.sigmaPhi = data.residencyApplication.sigmaPhi

            console.log("data after update =>", cpy)

            updateScreenedData({ variables: { programId:programId, input:cpy } });
        }
    }, [data, programId, updateScreenedData]);


    const updateDataFromScreened = (key, value) => {
        console.log("updateDataFromScreened", key, value, currentSection)
        var modifiedData = {
            ...prevData,
            [currentSection]: {
                ...prevData[currentSection],
                [key]: value
            }
        }
      

        if(key === 'sigmaPhi') {
           modifiedData.residencyApplication.sigmaPhi = value
        }
        console.log("modifiedData =>", modifiedData)
        setData(modifiedData)
       

    }
    


   
    return (
        <div>
       {loading && <Spinner />}
       {!loading && (
        <>
            <ScreenedSectionsBar setSection={setCurrentSection} section={currentSection} data={data} setData={setData} />
            <ScreenedItem  section={currentSection} data={data} setData={setData} updateDataFromScreened={updateDataFromScreened}/>
</>
         )}

           
        </div>
    );
}

export default CustomizeApplicationSettings;

