import React, { useState, useContext } from 'react';
import logo from '../../../../assets/imgs/logo.svg'
import {useMutation} from '@apollo/client';
import {INVITE_USER} from './graphql/mutations';


const InviteUserModal = ({ isOpen, onClose, onAddUser, program }) => {
    const [email, setEmail] = useState('');
    const [roles, setRoles] = useState([]);
    const [isReviewerAutoSelected, setIsReviewerAutoSelected] = useState(false);
    const [inviteUser, { data, loading, error }] = useMutation(INVITE_USER);
    const programId = program.id;

if (!isOpen) return null;

const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("handleSubmit called!")
    const newUser = {
        programId: programId,
        // userName: 'Pending Invitation',
        email: email,
        roles: roles,
        // action: 'action',
    };
    console.log("newUser =>", newUser)
    try {
        const result = await inviteUser({ variables: { input:newUser } });
        console.log('Mutation result:', result);

        onAddUser()
        onClose();
    } catch (err) {
        console.error('Error executing mutation:', err);
    }

    setRoles([]); // reset roles
    setEmail(''); // reset email

}

const handleRoleChange = (e) => {
    const { value, checked } = e.target;
    if (value === 'PD' || value === 'PC') {
        setRoles((prevRoles) => {
          let newRoles = checked ? [...prevRoles, value] : prevRoles.filter((role) => role !== value);
  
          // Automatically add 'Reviewer' if either 'PD' or 'PC' is checked
          if (checked && !newRoles.includes('Reviewer')) {
            newRoles.push('Reviewer');
            setIsReviewerAutoSelected(true); // Mark as auto-selected
          }
  
          // Remove 'Reviewer' only if both 'PD' and 'PC' are unchecked
          if (!checked && newRoles.includes('Reviewer') && !newRoles.includes(value === 'PD' ? 'PC' : 'PD')) {
            newRoles = newRoles.filter((role) => role !== 'Reviewer');
            setIsReviewerAutoSelected(false); // Mark as not auto-selected
          }
  
          return newRoles;
        });
      } else if (value === 'Reviewer') {
        // Only allow 'Reviewer' to be toggled if neither 'PD' nor 'PC' is checked
        if (!roles.includes('PD') && !roles.includes('PC')) {
          setRoles((prevRoles) =>
            checked ? [...prevRoles, value] : prevRoles.filter((role) => role !== value)
          );
          setIsReviewerAutoSelected(false); // Mark as not auto-selected
        }
      } else {
        setRoles((prevRoles) =>
          checked ? [...prevRoles, value] : prevRoles.filter((role) => role !== value)
        );
      }

};


return (

<div className="modal-backdrop">
<div className="add-field-modal">
    <div className='add-field-modal-header'>
    <h2>INVITE A MEMBER</h2>
    <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
    </div>
    {error && <p style={{ color: 'red' }}>{error.message}</p>}
    <form onSubmit={handleSubmit}>
        <div className="form-group">
            <label style={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                color: '#848484',
                lineHeight: '24px',
                marginBottom: '19px',
            }}>Enter the email address of the team member you would like to invite.</label>
          <input
                            type="email"
                            className="application-form-standard-input"
                            onChange={(e) => setEmail(e.target.value)}
                        />
 <label style={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                color: '#848484',
                lineHeight: '24px',
                marginBottom: '2vh',
                marginTop: '2vh'    
            }}>Select roles</label>
            <div className='role-selection-container' style={{display:'flex', flexDirection:'row'}}>

            <div style={{display:'flex'}}>
            <input type="checkbox" id="PD" name="PD" value="PD" 
            checked={roles.includes('PD')} onChange={handleRoleChange} />
            <label for="role1">Program Director</label>
            </div>
            
            <div style={{display:'flex'}}>
            <input type="checkbox" id="PC" name="PC" value="PC"  checked={roles.includes('PC')} onChange={handleRoleChange}
            />
            <label for="role2">Program Coordinator</label>
            </div>
            
            <div style={{display:'flex'}}>
            <input type="checkbox" id="Reviewer" name="Reviewer" value="Reviewer" 
            checked={roles.includes('Reviewer')} onChange={handleRoleChange}   style={{
                accentColor: roles.includes('Reviewer') ? (isReviewerAutoSelected ? '#8f8686' : '#145388') : 'transparent', }}
            />
            <label for="role3">Reviewer</label>
            </div>
            
            <div style={{display:'flex'}}>
            <input type="checkbox" id="Recommender" name="Recommender" value="Recommender" 
            checked={roles.includes('Recommender')} onChange={handleRoleChange}
            />
            <label for="role4">Recommender</label>
            </div>

            </div>
        </div>

        <div className='button-container' style={{display:'flex', flexDirection:'row', justifyContent:'space-between', gap:'2vw'}}>
          <button 
          disabled={email === "" || (roles && roles.length === 0)}
          className='gradient-button'  style={{fontWeight:'500'}} onClick={handleSubmit}>Send Invite</button>
            <button type="button" className='white-button'  onClick={onClose}>Cancel</button>
        </div>
    </form>
    
</div>
</div>
)
};

export default InviteUserModal;