import { gql } from "@apollo/client";

export const GET_MSPES_BY_APPLICANT_ID = gql`
  query getMspesByApplicantId($applicantId: ID!) {
    getMspesByApplicantId(applicantId: $applicantId) {
        id
        applicantId
        stage
        requestedAt
        submittedAt
        uploaderEmail
    }
  }
`;

export const GET_TRANSRIPTS_BY_APPLICANT_ID = gql`
query GetTranscriptsByApplicantId($applicantId: ID!) {
    getTranscriptsByApplicantId(applicantId: $applicantId) {
        id
        applicantId{
            id
            firstName
            lastName
        }
        stage
        requestedAt
        submittedAt
        uploaderEmail
    }
}
`;


export const getTranscriptByIdQuery = gql`
    query getTranscriptById($id: ID!) {
        getTranscriptById(id: $id) {
            id
            applicantId{
                firstName
                lastName
            }
            stage
            requestedAt
            submittedAt
            uploaderEmail
        }
    }
`;

export const GET_SCORE_BY_APPLICANTID = gql`
query GetScoreByApplicantId($applicantId: ID!) {
    getScoreByApplicantId(applicantId: $applicantId) {
        id
        applicantId
        uploaderId
        stage
        usmleStep1Score
        comlexLevel1Score
        usmleStep2Score
        usmleStep3Score
        comlexLevel2Score
        previousUsmleComlexFails
        usmleComlexDescription
        usmleDocs
        comlexDocs
        scoresVerified
        requestedAt
        mspeReqEmailAdd
    }
}
`;

export const getScoresByIdQuery = gql`
    query getScoreById($id: ID!) {
        getScoreById(id: $id) {
            id
            applicantId
            uploaderId
            stage
            usmleStep1Score
            comlexLevel1Score
            usmleStep2Score
            usmleStep3Score
            comlexLevel2Score
            previousUsmleComlexFails
            usmleComlexDescription
            usmleDocs
            comlexDocs
            scoresVerified
            requestedAt
        }
    }
`