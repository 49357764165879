const ProgressBar = ({ step }) => {
  // Adjusted for 9 steps (0 to 8)
  const percentage = (step / 9) * 100; // Now correctly calculates percentage for 9 steps
  return (
    <div style={{ width: '100%', backgroundColor: '#E0E0E0', borderRadius: '10px', height: '20px' }}>
      <div style={{
        width: `${percentage}%`,
        backgroundColor: '#145388',
        height: '100%',
        borderRadius: '10px',
        transition: 'width 0.3s ease-in-out'
      }} />
    </div>
  );
};


export default ProgressBar;