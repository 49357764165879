import React, { useState, useEffect } from 'react';
import EmailTemplate from "./emailTemplates";
import EmailTemplateMenu from "./emailTemplatesMenu";


function Communication({}) {
    const [template, setTemplate] = useState({});
    const [topBarStatus, setTopBarStatus] = useState('sms');

  return (
    <div style={{marginLeft:'9vw'}}>
      <ellipse style={{ position: 'absolute', width: '29vw', height: '56vh', left: '55vw', top: '28vh', background: '#2a93d5', filter: 'blur(248px)', opacity:'.2' }} />
      {/* <div className="faculty-application-top-bar-buttons1" style={{width:'22vw'}}>
      <div 
      className={ topBarStatus === 'sms' ?"faculty-application-top-bar-link":"faculty-application-top-bar-link-inactive"} 
      style={{width:'9vw'}} 
      onClick={()=> setTopBarStatus('sms')}
      >SMS Templates</div>
      <div 
      className={ topBarStatus === 'email' ?"faculty-application-top-bar-link":"faculty-application-top-bar-link-inactive"} 
      style={{width:'9vw'}} 
      onClick={()=> setTopBarStatus('email')}
      >Email Templates</div>
    </div> */}
     
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div className="faculty-home-body" style={{flexDirection:'row', gap:'.78vw', marginTop:'5vh'}}>
                <EmailTemplateMenu template={template} setTemplate={setTemplate} />
                <EmailTemplate selectedTemplate={template} setTemplate={setTemplate} showFullTemplate={true}/>
                </div>

    </div>

    {/* <Footer /> */}
    </div>
  );
}

export default Communication;