import React,{useState, useEffect, useRef} from "react";
import "../../interviewManager/styles/applicantInterviewManager.css";
import '../styles/directSchedule.css';
import StatusIndicator from "../../../faculty/facultyComponents/interviewManager/inviteComponents/statusIndicator";
import moment from "moment";
import Spinner from "../../../common/spinner";



const InterviewsList = ({programs, handleDeclineInterview, handleScheduleInterview, handleCancelInterview, isLoading}) => {
    const [dropdownVisible, setDropdownVisible] = useState({});
    const dropdownRefs = useRef([]);

console.log("programs", programs);    

    const toggleDropdown = (index, event) => {
        event.stopPropagation(); // Prevent click from immediately triggering the document listener
        setDropdownVisible(prevState => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };



    useEffect(() => {
        const handleClickOutside = (event) => {
            Object.entries(dropdownRefs.current).forEach(([key, ref]) => {
                if (ref && !ref.contains(event.target)) {
                    setDropdownVisible(prevState => ({
                        ...prevState,
                        [key]: false,
                    }));
                }
            });
        };

        // Add event listener
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []); 

if(isLoading){
    return <Spinner />
}

return(
    <div>
        {programs.map((program, index) => (
            <div key={index} className="applicant-im-list-item direct">
                <div className="applicant-im-list-item-header" style={{display:'flex',flexDirection:'row', justifyContent:'space-between', marginBottom:'.2vh'}}>
                    <p className="applicant-im-list-item-header-title direct">{program.programName}, {program.specialty}</p>
                    <StatusIndicator status={program.status} interviewDate={program.interviewDate} />
                    </div>
                    <seperator className="faculty-home-header-seperator" style={{marginBottom:'0', marginTop:'1vh'}}/>
                    {program.status === 'Scheduled' && (
                        <div className="applicant-im-list-item-footer direct">
                                    {program.datesToSend.map((date, index) => (
                                           (date.currentAvailableSlots!==0) &&(
                                        <>
                                        <div key={index} className="applicant-im-dates-dropdown-item direct">
                                            <div>
                                            <p >{moment(date.date).format('MMMM D, YYYY')}</p>
                                            {/* <img style={{marginRight:'.5vw'}} src={checkIconWithCircleTeal} alt="check icon" /> */}
                                            <p >{date.currentAvailableSlots} out of {date.totalAvailableSlots} slots left</p>
                                            <p >{date.startTime}</p>
                                            </div>
                                            
                                            {date.date !== program.interviewDate && (
                                            <button className="applicant-im-list-item-footer-left-button" onClick={()=>handleScheduleInterview(program.id, date.date, program.programId)}>
                                            Reschedule
                                        </button>
                                            )}
                                            {date.date === program.interviewDate && (
                                                <button className="applicant-im-list-item-footer-right-button direct" onClick={()=>handleCancelInterview(program.id,program.programId)} >Cancel</button>
                                            )}
                                            </div>
                                        
                                        <seperator style={{margin:'0'}} className="faculty-home-header-seperator"  />
                                        </>
                                           )
                                    ))
                                    }
                                  
                                </div>

                    )}

                    {(program.status === 'Response Pending' || program.status === 'Canceled') && (
                        <div className="applicant-im-list-item-footer direct">
                                    {program.datesToSend.map((date, index) => (
                                        date.currentAvailableSlots!==0 &&(
                                            <>
                             <div key={index} className="applicant-im-dates-dropdown-item direct">
                                            <p >{moment(date.date).format('MMMM D, YYYY')}</p>
                                            {/* <img style={{marginRight:'.5vw'}} src={checkIconWithCircleTeal} alt="check icon" /> */}
                                            <p >{date.currentAvailableSlots} out of {date.totalAvailableSlots} slots left</p>
                                            <p >{date.startTime}</p>       
                                            {date.date !== program.interviewDate && (
                                            <button className="applicant-im-list-item-footer-left-button" onClick={()=>handleScheduleInterview(program.id, date.date, program.programId)}>
                                            Schedule
                                        </button>
                                            )}
                                            {date.date === program.interviewDate && (
                                                <button className="applicant-im-list-item-footer-right-button direct" onClick={()=>handleDeclineInterview(program.id)}>Decline</button>
                                            )}
                                        </div>
                                        <seperator style={{margin:'0'}} className="faculty-home-header-seperator"  />
                                        </>
                                           )
                                    ))
                                    }
                                  
                                </div>

                    )}

                </div>
        ))}
    </div>
)

}

export default InterviewsList;