import React,{useState, useContext, useEffect, useRef} from "react";
import {useQuery, useMutation} from '@apollo/client';
import { getProgramsWithPaginationQuery, getProgramsWithPaginationCountQuery } from "./graphql/queries";
import GeneralTable from './generalTable';
import Pagination from './paginationComponent';
import PageSizeSelector from './pageSizeSelector';
import EditProgramModal from './editProgram';
import AddProgramModal from './AddProgram';
import { createProgramMutation, UPDATE_PROGRAM } from './graphql/mutations';

const AdminPrograms = ({ setPage }) => {
    const [programs, setPrograms] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(20);
    const [filter, setFilter] = useState({field: 'name', value: null});
    const [sort, setSort] = useState({ field: 'name', direction: 'desc'});
    const [totalPrograms, setTotalPrograms] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [searchInput, setSearchInput] = useState('');
    const [selectedProgram, setSelectedProgram] = useState(null);
    const [otherViewOpen, setOtherViewOpen] = useState(false);
    const [editProgramModalOpen, setEditProgramModalOpen] = useState(false);
    const [addProgramModalOpen, setAddProgramModalOpen] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    
    const [createSpecialty] = useMutation(createProgramMutation)
    const [updateProgram] = useMutation(UPDATE_PROGRAM);

    const columns = [
        {
            accessor: 'name',
            id: 'name',
            Header: <label style={{width:'4vw'}}>Program Name</label>,
            Cell: ({ row }) => (
                <div style={{width:'37vw'}}>{row.original.name}</div>
            ),  
        }, 
        {
            accessor: 'specilatyName',
            id: 'specilatyName',
            Header: <label style={{width:'4vw'}}>specialty Name</label>,
            Cell: ({ row }) => (
                <div style={{width:'10vw'}}>{row.original.specialtyId.name}</div>
            ),  
        }, 
        {
            id: 'actions',
            Header: <label style={{width:'4vw'}}>Actions</label>,
            Cell: ({ row }) => (
                <div className="dropdown-point">
                    <div className='toggle-points' onClick={() => toggleSelected(row.original)}>
                        <span className='point'/>
                        <span className='point'/>
                        <span className='point'/>
                    </div>
                    {
                        otherViewOpen === false && selectedProgram && row.original.id.toString() === selectedProgram.id.toString() && (
                        <div className="dropdown-menu" style={{display:'flex',zIndex: 999, right: '3vw', flexDirection:'column', padding:'10%',}}>
                            <a href="#" onClick={() => { setOtherViewOpen(true); setEditProgramModalOpen(prevState => !prevState)}}>Edit Program</a>
                        </div>)
                    }
                </div> 
            ),  
        }, 
    ]


    const sortData = (column, direction) => {
        setSort({ field: column, direction: direction === true ? "asc": "desc"})
        refetchPrograms()
    }

    const toggleSelected = (program) => {
        if(selectedProgram === null) {
            setSelectedProgram(program)
        } else {
            if(selectedProgram.id.toString() === program.id.toString()) {
                // close menu
                setSelectedProgram(null)
            } else {
                // other user clicked
                setSelectedProgram(null)
                setSelectedProgram(program)
            }
        }
    }



    const { loading: loadingPrograms, error: errorPrograms, data: programsData, refetch: refetchPrograms} = useQuery(getProgramsWithPaginationQuery, {
        variables: {
            offset,
            limit,
            filter,
            sort
        }
    })

    const { loading: loadingProgramsCount, error: errorProgramsCount, data: programsCount, refetch: refetchProgramsCount } = useQuery(getProgramsWithPaginationCountQuery, {
        variables: {
            filter,
        }
    })

    useEffect(() => {
        setLoadingData(true)
        if(!loadingPrograms && !errorPrograms && programsData) {
            console.log("programsData =>", programsData.getProgramsWithPagination)
            setPrograms(programsData.getProgramsWithPagination)
            setLoadingData(false)
        }
    }, [loadingPrograms, errorPrograms, programsData, setPrograms])

    useEffect(() => {
        if(!loadingProgramsCount && !errorProgramsCount && programsCount) {
            console.log("specialtiesCount =>", programsCount.getProgramsWithPaginationCount)
            setTotalPrograms(programsCount.getProgramsWithPaginationCount)
            setTotalPages(Math.ceil(programsCount.getProgramsWithPaginationCount/ Number(limit)))
        }
    }, [loadingProgramsCount, errorProgramsCount, programsCount,setTotalPages, setTotalPrograms, limit])


    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchInput(value)
        var searchObj = { ...filter}
        searchObj.value = value
        setLoadingData(true)
        setFilter(searchObj)
    }

    const handlePageSizeChange = (event) => {
        setLimit(Number(event.target.value));
        setOffset(0)
        setCurrentPage(1); // Reset to first page when page size changes
        setTotalPages(Math.ceil(totalPrograms/ Number(event.target.value)))
        setLoadingData(true)
        refetchPrograms()
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setOffset((page - 1) * limit )
        setLoadingData(true)
        refetchPrograms()
    };

    const editProgram = (input) => {
        console.log("input =>", input)

        updateProgram({
            variables: {
                id: selectedProgram.id,
                input: { ...input }
            }
        })
        .then(res => {
            setEditProgramModalOpen(false)
            refetchPrograms()
        })
    }

    const saveProgram = (input) => {
        console.log("input =>", input)

        const newProgram = {
            ...input,
            members: [],
            rubricTemplate: [],
            filters: []
        }

        createSpecialty({
            variables: {
                input: newProgram
            }
        })
        .then(res => {
            refetchPrograms()
        })




    }

    return (
        <div className='faculty-home-container'>
            <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop2' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="faculty-home-header">
                <h2 className="faculty-home-header-title">PROGRAMS</h2>
            </div>
            <seperator className="faculty-home-header-seperator"/>
            <div className="right-header-container" style={{ marginBottom: '2vh'}}>
                <div className="input-container">
                    <i className="fas fa-search"></i>
                    <input
                        className="applicant-top-bar-search-input"
                        placeholder="Search"
                        value={searchInput}
                        onChange={handleSearchChange}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                refetchPrograms()
                            }
                        }}
                    />
                </div>
                <button className="green-button" onClick={() => setAddProgramModalOpen(prevState => !prevState)}>Create New Program</button>
            </div>
            <GeneralTable columns={columns} data={programs} sortData={sortData} limit={limit} totalPages={totalPages} currentPage={currentPage} handlePageSizeChange={handlePageSizeChange} handlePageChange={handlePageChange} loadingData={loadingData}/>

            {/* <div className="faculty-application-table-footer" style={{ height: '4vh'}}>
                <PageSizeSelector itemsPerPage={limit} onPageSizeChange={handlePageSizeChange} />
                <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
            </div> */}
            {
                editProgramModalOpen &&
                <EditProgramModal isOpen={editProgramModalOpen} onClose={() => {setEditProgramModalOpen(prevState => !prevState); setOtherViewOpen(false); } } program={selectedProgram} onEditProgram={editProgram} />
            }
            {
                addProgramModalOpen && 
                <AddProgramModal isOpen={addProgramModalOpen} onClose={() => {setAddProgramModalOpen(prevState => !prevState); setOtherViewOpen(false);}} onSaveProgram={saveProgram} />


            }
        </div>
    )
}

export default AdminPrograms;