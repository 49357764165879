import React, { useState, useEffect } from 'react';
import './styles/roleSelector.css'; // Assuming styles are in RoleSelector.css
import loginSelectorIcon from '../../../assets/imgs/loginSelectorIcon.svg'; // Import the loginSelectorIcon image

const RoleSelector = ({ onSignInClick, setType }) => {
  const [role, setRole] = useState('student');

useEffect(() => {
  setType(role);
}, [role]);

  const handleChange = (event) => {
    console.log('Setting role to:', event.target.value); // Debugging line
    setRole(event.target.value);
    setType(event.target.value); // This will now correctly update the parent component's state
  };


  return (
    <div className="role-selector-container">
      <div className='sign-in-button-container'> 
      <button className='sign-in-button' onClick={onSignInClick}>Sign In</button>
      </div>
    </div>
  );
};

export default RoleSelector;
