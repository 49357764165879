const dummyData ={
    hiringManagers:[
        {id:'1',
        name:'Avi sfdsf'
        },
        {id:'2',
        name:'Avi fff'
        },
        {id:'3',
        name:'Avi sfdsafs'
        },
        {id:'4',
        name:'Avi xcvxcvcxv'
        },
        {id:'5',
        name:'Avi zzcxvxcvxz'
        },
        {id:'6',
        name:'Avi gfdgsgds'
        },
    ]

}

export default dummyData