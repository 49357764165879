import React,{useState} from 'react';
import {useDropzone} from 'react-dropzone';
import { Formik, Field, Form } from 'formik';
import Spinner from '../../../common/spinner';
import { Document, Page } from 'react-pdf';
import './lor.css';
import packageInfo from '../../../../../package.json';



const PlasticSurgeryForm = ({specialty, lor, refetchLors, setUploadStatus, isSlorEdit}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [LoRStage, setLoRStage] = useState(lor.stage);
  const [numPages, setNumPages] = useState(1);
  
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop: (acceptedFiles) => {
        console.log("acceptedFiles", acceptedFiles)

        const file = acceptedFiles[0];
        console.log("file =>", file)
        if(file) {
            setSelectedFile(file);
        }
    },
    accept: 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document' ,
    multiple: false  // This ensures that multiple file selection is disabled
});

const uploadFile = async (values) => {
  setIsLoading(true);
  if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('isSlor', 'true');
      formData.append('lorId', lor.id);
      formData.append('email', values.email);
      formData.append('howLongKnownTheApplicant', values.howLongKnownTheApplicant);
      formData.append('improvementAspect', values.improvementAspect);
      formData.append('natureOfContact', values.natureOfContact);
      formData.append('presentPosition', values.presentPosition);
      formData.append('radioAcademicSkills', parseInt(values.radioAcademicSkills));
      formData.append('radioCommunicationSkills', parseInt(values.radioCommunicationSkills));
      formData.append('radioOperativeSkill', parseInt(values.radioCommunicationSkills));
      formData.append('radioOverallComparedToOtherApplicants', parseInt(values.radioOverallComparedToOtherApplicants));
      formData.append('radioPatientCare', parseInt(values.radioPatientCare));
      formData.append('radioProfessionalism', parseInt(values.radioProfessionalism));
      formData.append('radioResearchAndTeaching', parseInt(values.radioResearchAndTeaching));
      formData.append('radioSelfInitiative', parseInt(values.radioSelfInitiative));
      formData.append('radioTeamPlayer', parseInt(values.radioTeamPlayer));
      formData.append('radioWorkEthic', parseInt(values.radioWorkEthic));
      formData.append('rank', values.rank);
      formData.append('strongestPoint', values.strongestPoint);
      formData.append('willingToDiscuss', values.willingToDiscuss);
      formData.append('writtenComments', values.writtenComments);
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/upload_lor_file`, {
          method: 'POST',
          headers: {'App-Version': packageInfo.version},
          body: formData
      });
      const data = await response.json();
      
      if (response.ok) {
          refetchLors({ fetchPolicy: 'network-only' });
          setUploadStatus('success');
          setIsLoading(false);
          refetchLors()
      } else {
          setUploadStatus('error');
          setIsLoading(false);
      }

  } else {
    // only fields changes without file
    const formData = new FormData();
    formData.append('isSlor','true');
    formData.append('lorId', lor.id);
    formData.append('email', values.email);
    formData.append('howLongKnownTheApplicant', values.howLongKnownTheApplicant);
    formData.append('improvementAspect', values.improvementAspect);
    formData.append('natureOfContact', values.natureOfContact);
    formData.append('presentPosition', values.presentPosition);
    formData.append('radioAcademicSkills', parseInt(values.radioAcademicSkills));
    formData.append('radioCommunicationSkills', parseInt(values.radioCommunicationSkills));
    formData.append('radioOperativeSkill', parseInt(values.radioCommunicationSkills));
    formData.append('radioOverallComparedToOtherApplicants', parseInt(values.radioOverallComparedToOtherApplicants));
    formData.append('radioPatientCare', parseInt(values.radioPatientCare));
    formData.append('radioProfessionalism', parseInt(values.radioProfessionalism));
    formData.append('radioResearchAndTeaching', parseInt(values.radioResearchAndTeaching));
    formData.append('radioSelfInitiative', parseInt(values.radioSelfInitiative));
    formData.append('radioTeamPlayer', parseInt(values.radioTeamPlayer));
    formData.append('radioWorkEthic', parseInt(values.radioWorkEthic));
    formData.append('rank', values.rank);
    formData.append('strongestPoint', values.strongestPoint);
    formData.append('willingToDiscuss', values.willingToDiscuss);
    formData.append('writtenComments', values.writtenComments);
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/upload_lor_fields`, {
      headers:{'App-Version': packageInfo.version},
      method: 'POST',
        body: formData
    });
    const data = await response.json();
    
    if (response.ok) {
        refetchLors({ fetchPolicy: 'network-only' });
        setUploadStatus('success');
        setIsLoading(false);
        refetchLors()
    } else {
        setUploadStatus('error');
        setIsLoading(false);
    }
  }
};


const radioButtonCategories = [
    {value:'radioOverallComparedToOtherApplicants',label:'Overall compared to other applicants'},
    {value:'radioWorkEthic', label:'Work Ethic'},
    {value:'radioOperativeSkill',label:'Operative Skill'},
    {value:'radioSelfInitiative', label:'Self-Initiative'},
    {value:'radioCommunicationSkills',label:'Communication Skills'},
    {value:'radioAcademicSkills', label:'Academic Skills'},
    {value:'radioTeamPlayer', label:'Team Player'},
    {value:'radioPatientCare', label:'Patient Care'},
    {value:'radioResearchAndTeaching', label:'Research and Teaching'},
    {value:'radioProfessionalism', label:'Professionalism'}
  ];


      

    return (

    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
    <Formik
  initialValues={{
    presentPosition: lor && lor.slor ? lor.slor.presentPosition? lor.slor.presentPosition: lor.recommenderId.recommenderPosition: '',
    // presentPosition: lor && lor.slor ? lor.slor.presentPosition? lor.slor.presentPosition: lor.recommenderId.presentPosition,
    email:lor.recommenderId.email,
    phoneNumber: lor.recommenderId.phone,
    howLongKnownTheApplicant: lor && lor.slor ? lor.slor.howLongKnownTheApplicant: '',
    natureOfContact: lor && lor.slor ? lor.slor.natureOfContact: '',
    strongestPoint: lor && lor.slor ? lor.slor.strongestPoint: '',
    improvementAspect: lor && lor.slor ? lor.slor.improvementAspect: '',
    rank: lor && lor.slor ? lor.slor.rank: '',
    willingToDiscuss: lor && lor.slor ? lor.slor.willingToDiscuss: '',
    writtenComments: lor && lor.slor ? lor.slor.writtenComments: '',
    signature:'',
    file: lor ? lor.docUrl: '',
    ...radioButtonCategories.reduce((acc, category) => {
      const categoryValue = lor && lor.slor && lor.slor[category.value] ? lor.slor[category.value].toString() : 'unable';
      acc[category.value] = categoryValue;
      return acc; 
    }, {}), 
  }}
  onSubmit={async (values) => {
    uploadFile(values);


  }}
>
      <Form style={{display:'flex', flexDirection:'column'}}>
        <label className='applicant-form-field-label' htmlFor="presentPosition">Present Position</label>
        <Field id="presentPosition" name="presentPosition" disabled={isSlorEdit} placeholder="" className='application-form-standard-input one'/>

        <label className='applicant-form-field-label' htmlFor="phoneNumber">Phone Number</label>
        <Field id="phoneNumber" name="phoneNumber" placeholder="" disabled={true} className='application-form-standard-input one'/>

        <label className='applicant-form-field-label' htmlFor="strongestPoint">Email</label>
        <Field id="email" name="email" placeholder="" disabled={true}  className='application-form-standard-input one'/>

        <label className='applicant-form-field-label' htmlFor="strongestPoint">How long have you known the applicant?</label>
        <Field id="howLongKnownTheApplicant" name="howLongKnownTheApplicant" disabled={isSlorEdit} placeholder="" className='application-form-standard-input one'/>

        <label className='applicant-form-field-label' htmlFor="natureOfContact">Nature of contact with the applicant</label>
        <Field className='application-form-standard-input one' disabled={isSlorEdit}  as="select" id="natureOfContact" name="natureOfContact">
            <option value="">Select...</option>
            <option value="advisor">Advisor</option>
            <option value="generalSurgeryResidencyProgramDirector">General Surgery Residency Program Director</option>
            <option value="rotatedOnMyService">The applicant has rotated on my service</option>
            <option value="plasticSurgeryRotation">On a plastic surgery rotation</option>
            <option value="differentRotation">On a different rotation</option>
            <option value="writtenPaperWithTheApplicant">I have a written paper with the applicant</option>
            <option value="performedResearch">The applicant has performed research</option>
            <option value="notWorkedDirectly">I have not worked directly with the applicant; based on others' evaluations</option>
            <option value="other">Other</option>
        </Field>


<div style={{marginBottom:'2vh'}}>
        {radioButtonCategories.map((category) => {
  const formattedCategory = category.value.replace(/\s+/g, '-').toLowerCase();
  return (
    <React.Fragment  key={category.value}>
      <label className='applicant-form-field-label'>{category.label}</label>
      <div className="radio-group" style={{display:'flex',flexDirection:'row', justifyContent:'space-around', marginTop:'1vh', marginBottom:'2vh' }}>
        {Array.from({ length: 10 }, (_, i) => i + 1).map((value) => (
         <React.Fragment key={value}>
         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '10px' }}> {/* Adjusted for clarity and added margin for spacing */}
           <label htmlFor={`radio-${formattedCategory}-${value}`} style={{ marginBottom: '5px', textAlign:'center' }}> {value * 10}th percentile</label>
           <Field type="radio" disabled={isSlorEdit}  style={{display:'unset', accentColor:'#145388', width:'2vh', height:'2vh'}} id={`radio-${formattedCategory}-${value}`} name={category.value} value={value.toString()} />
         </div>
       </React.Fragment>
        ))}
               <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '10px' }}>
          <label htmlFor={`radio-${formattedCategory}-unable`} style={{ marginBottom: '5px', textAlign:'center' }}>Unable to evaluate this trait</label>
          <Field type="radio" disabled={isSlorEdit} style={{display:'unset', accentColor:'#145388', width:'2vh', height:'2vh'}} id={`radio-${formattedCategory}-unable`} name={category.value} value="unable" />
        </div>
        
      </div>
    </React.Fragment>
  );
})}
</div>




        <label className='applicant-form-field-label' htmlFor="strongestPoint">What is the applicant's strongest point?</label>
        <Field id="strongestPoint" disabled={isSlorEdit}  name="strongestPoint" placeholder="" style={{height:'15vh'}} className='application-form-standard-input textBox'/>

        <label  className='applicant-form-field-label' htmlFor="improvementAspect">What aspect(s) of this applicant would you like to see improve?</label>
        <Field id="improvementAspect" disabled={isSlorEdit}  name="improvementAspect" placeholder="" style={{height:'15vh'}} className='application-form-standard-input textBox'/>

        <label className='applicant-form-field-label' htmlFor="rank">Out of a list of 20 applicants your program ranked last year, you would rank this applicant</label>
        <Field as="select" disabled={isSlorEdit}  id="rank" name="rank" className='application-form-standard-input one'>
          <option value="">Select...</option>
          <option value="n1">Number 1</option>
          <option value="n2to5">2-5</option>
          <option value="n5to10">5-10</option>
          <option value="n10to20">10-20</option>
          <option value="wouldNotRank">would not rank</option>
          <option value="NA">Unable to evaluate this trait</option>

        </Field>

        <label className='slor-form-field-label' htmlFor="willingToDiscuss" style={{fontSize:'18px'}}>I am willing to discuss this applicant</label>
        <div className="radio-group" style={{display:'flex',flexDirection:'row'}}>
        <Field type="radio" disabled={isSlorEdit}  style={{display:'unset', marginBottom:'2vh', accentColor:'#145388', alignSelf:'center'}} id="willingToDiscussYes" name="willingToDiscuss" value="yes" />
        <label className='slor-form-field-label discussion-label' htmlFor="willingToDiscussYes">Yes</label>
        <Field type="radio" disabled={isSlorEdit}  style={{display:'unset', marginBottom:'2vh', accentColor:'#145388', alignSelf:'center'}} id="willingToDiscussNo" name="willingToDiscuss" value="no" />
        <label className='slor-form-field-label discussion-label' htmlFor="willingToDiscussNo">No</label>
        </div>

        <label className='applicant-form-field-label' htmlFor="writtenComments">Written comments</label>
        <Field id="writtenComments" disabled={isSlorEdit}  name="writtenComments" placeholder="" style={{height:'15vh'}} className='application-form-standard-input textBox' />

        {
          LoRStage === 'Submitted' &&
          <div className="faculty-home-body">
                <p className="specific-rec-title">Your letter of recommendation has been submitted</p>
                <button className='green-button' onClick={()=>setLoRStage('Pending')} style={{padding:'1vh 1vw', width:'76vw'}}>Replace LoR File</button>

                <Document
                  file={lor.docUrl}
                  onLoadSuccess={onDocumentLoadSuccess}
              >
                  {Array.from(new Array(numPages), (el, index) => (
                      <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                  ))}
              </Document>
            </div>
        }
        {LoRStage === 'Pending' && selectedFile && 
              <div>
              {/* Check if the file is an image to display it in an img tag */}
              {selectedFile.type.startsWith('image/') && (
                  <img src={URL.createObjectURL(selectedFile)} alt="Preview" style={{maxWidth: '100%', maxHeight: '400px'}} />
              )}
              {/* Check if the file is a PDF to display it in an iframe */}
              {selectedFile.type === 'application/pdf' && (
                  <iframe src={URL.createObjectURL(selectedFile)} style={{width: '100%', height: '500px'}} frameBorder="0"></iframe>
              )}
              {/* Optionally, handle other file types here */}
              <p>File Name: {selectedFile.name}</p>
              <p>File Type: {selectedFile.type}</p>
              <p>File Size: {(selectedFile.size / (1024*1024)).toFixed(2)} MB</p>
            </div>
            }
            {LoRStage === 'Pending' && !selectedFile && 
            <div className={ isDragActive ?'file-drop-zone hover':"file-drop-zone"} style={{zIndex:'50'}} {...getRootProps()}>
            <input {...getInputProps()}  multiple={false}/>
           {
               isDragActive ?
               <p>Drop to upload the file</p> :
               <p style={{top:'46vh'}}>Drag & drop your letter of recommendation here, or click to select files</p>
           }
       </div>
}


    

 
{!isLoading && <button className='green-button' disabled={(!selectedFile&&!lor.docUrl)} style={{marginTop:'2vh'}} type="submit">Submit</button>}
        {isLoading && 
        <div className='white-button' style={{height:'5vh'}} >
        <Spinner/>  
        </div>
        }
      </Form>
    </Formik>
  </div>
);
}
export default PlasticSurgeryForm;