// NotFound.js
import React from 'react';
import logo from '../../assets/imgs/logo.svg';

const NotFound = () => {
  return (
    <div className='faculty-home-container' style={{width:'100%',marginLeft:'auto',marginRight:'auto'}}>
          <div className="not-found-container">
            <img src={logo} alt="logo" style={{float:'left', marginRight: '2vw'}}/>
            <h2 className="faculty-home-header-title">404 - Page Not Found!</h2>
            <p className="faculty-home-header-subtitle"> Sorry, the page you are looking for does not exist.</p>
        </div>

        
    </div>
  );
}

export default NotFound;
