import {gql} from '@apollo/client';

export const updateProgramPopulatedDates = gql`
    mutation updateProgramPopulatedDates($programId: ID!, $input: ProgramScheduleInput!) {
        updateProgramPopulatedDates(programId: $programId, input: $input) {
            populatedDates{
                date
                status
                isRegularDay
                startTime
                totalAvailableSlots{
                    level
                    slotsNumber
                }
                currentAvailableSlots{
                    level
                    slotsNumber
                }
                breakTime
                interviewLength
                slots{
                    startTime
                    endTime
                    item
                    itemColor
                }
                interviewersPerInterviewee
                numOfInterviewers

            }
        }
    }
`;

export const editEmailTemplateInProgramMutation = gql`
  mutation editEmailTemplateInProgram($_id: ID!, $emailSubject: String!, $emailBody: String!) {
    editEmailTemplateInProgram(
      _id: $_id
      emailSubject: $emailSubject
      emailBody: $emailBody
    ) {
      _id
      programId
      emailSubject
      emailBody
      name
      attachments
    }
  }
`;

export const addEmailAttachmentMutation = gql`
  mutation($_id: ID!, $fileName: String!) {
    addEmailAttachment(_id: $_id, fileName: $fileName) {
      _id
      programId
      emailSubject
      emailBody
      name
      attachments
    }
  }
`;

export const removeEmailAttachmentMutation = gql`
  mutation removeEmailAttachment($_id: ID!, $fileName: String!) {
    removeEmailAttachment(_id: $_id, fileName: $fileName) {
      _id
      programId
      emailSubject
      emailBody
      name
      attachments
    }
  }
`;


export const inviteApplicantForInterviewMutation = gql`
    mutation inviteApplicantForInterview($applicationIds: [ID!]) {
        inviteApplicantForInterview(applicationIds: $applicationIds) {
            id
            profilePhotoUrl
            firstName
            lastName
            institution
            nrmpId
            invitationStatus
        }
    }
`;


export const cancelApplicantInvitationMutation = gql`
    mutation cancelApplicantInvitation($applicationIds: [ID!]) {
            cancelApplicantInvitation(applicationIds: $applicationIds) {
            id
            profilePhotoUrl
            firstName
            lastName
            institution
            nrmpId
            invitationStatus
        }
    }
`;


export const scheduleApplicantInterviewMutation = gql`
    mutation scheduleApplicantInterview($applicationIds: [ID!], $scheduleDate: String, $programId: ID) {
        scheduleApplicantInterview(applicationIds: $applicationIds, scheduleDate: $scheduleDate, programId: $programId) {
            id
            profilePhotoUrl
            firstName
            lastName
            institution
            nrmpId
            invitationStatus
            interviewDate
        }
    }
`;

export const cancelApplicantInterviewMutation = gql`
    mutation cancelApplicantInterview($applicationIds: [ID!], $programId: ID) {
        cancelApplicantInterview(applicationIds: $applicationIds, programId: $programId) {
            id
            profilePhotoUrl
            firstName
            lastName
            institution
            nrmpId
            invitationStatus
            interviewDate
        }
    }
`;

export const addApplicantToWaitlistMutation = gql`
    mutation addApplicantToWaitlist($applicationIds: [ID!], $scheduleDate:String, $programId: ID) {
        addApplicantToWaitlist(applicationIds: $applicationIds, scheduleDate:$scheduleDate, programId: $programId) {
          applicantId
          applicationId
          addedToWaitingListAt
          applicantOrder
        }
    }
`;