import {gql} from '@apollo/client';

export const CREATE_WORKFLOW = gql`
  mutation CreateWorkflow($input: WorkflowInput!) {
    createWorkflow(input: $input) {
      id
      name
      description
      steps {
        name
        order
      }
      triggers {
        event
      }
      status
      version
    }
  }
`;

export const DELETE_WORKFLOW = gql`
  mutation DeleteWorkflow($id: ID!) {
    deleteWorkflow(id: $id)
  }
`;

export const UPDATE_PROGRAM = gql`
  mutation UpdateProgram($id: ID!, $input: UpdateProgramInput!) {
    updateProgram(id: $id, input: $input) {
        deleted
    }
  }
`;

export const INVITE_USER = gql`
  mutation InviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      userId {
        id
        firstName
        lastName
        email
        isPending
      }
      roles
    }
  }
`;

export const REMOVE_PROGRAM_MEMBER = gql`
mutation RemoveProgramMember($programId: ID!, $userId: ID!) {
  removeProgramMember(programId: $programId, userId: $userId) {
    userId {
        id
        firstName
        lastName
        email
        isPending
      }
      roles
  }
}
`;
