import React,{useState, useEffect, useContext} from "react";
import {useQuery} from "@apollo/client";
import {ProgramIdContext} from '../../progIdContext';
import Spinner from "../../../common/spinner";
import {getProgramEmailTemplatesQuery} from "./graphql/queries";

const EmailTemplateMenu = ({template, setTemplate}) => {
    const [page, setPage] = useState("emailTemplates");
    const [templates, setTemplates] = useState([]);
    const programId = useContext(ProgramIdContext);
    const {loading, error, data} = useQuery(getProgramEmailTemplatesQuery, {
        variables: {programId},
    });

    const itemStyle = { fontFamily: 'Montserrat', fontSize: '1vw', cursor: 'pointer', marginBottom:'.5vh', lineHeight:'4vh'}
    const selectedStyle = {...itemStyle,  fontWeight: '500', color: '#145388',backgroundColor:'white'}
    const unselectedStyle = {...itemStyle, fontWeight: '300', color: 'white'}



    useEffect(() => {
        if (data) {
            setTemplates(data.getProgramEmailTemplates);
            setTemplate(data.getProgramEmailTemplates[0]);
        }
    }, [data]);

    if (loading) return <Spinner />;

    return (
        <div style={{alignSelf:'flex-start', minWidth:'15vw', width:'15vw', marginTop:'9vh'}}>
            {/* <div className="items-contianer" style={{background:'linear-gradient(180deg, #4A9EA0 0%, #547682 100%)',  padding:'1vw', height:'80vh'}}> */}
            <div className="items-contianer" style={{background:'#145388',padding:'1vw', height:'77vh'}}>
            <h2 style={{fontFamily:'Montserrat', color:'white',fontWeight:'500', marginBottom:'2vh', fontSize:'4vh'}}>Templates:</h2>
            {templates.map((templateArrItem) => (
                <div key={templateArrItem._id}>
                    <h3 
                    style={templateArrItem._id === template._id ? selectedStyle : unselectedStyle}
                    onClick={()=>setTemplate(templateArrItem)}
                    >{templateArrItem.name}</h3>
                </div>
            ))}
            </div>
        </div>
    );
}

export default EmailTemplateMenu;