import React,{useState,useContext, useEffect} from "react";
import "./index.css";
// import Card from "../../../../components/common/card"
import { updateUserMutation,
       updateUserPasswordMutation,
       revokeUserMutation,
       addUserToAccountAccessMutation,
       updateLegalDisclaimersMutation,
       updateProgramMutation,
     } from './graphql/mutations';
import { getUserById, getUserWithAccountUsersById, getUsersQuery } from './graphql/queries';
import { useQuery, useMutation } from '@apollo/client';
import { UserContext } from '../../../../context/userContext';
import Spinner from "../../../common/spinner";


const ProgramDetails = ({programData, refetch}) => {
const { user, setUser, setTopUser } = useContext(UserContext);
const [programName, setProgramName] = useState('');
const [initialProgramName, setInitialProgramName] = useState('');
const [programDescription, setProgramDescription] = useState('');
const [initialProgramDescription, setInitialProgramDescription] = useState('');
const [programUrl, setProgramUrl] = useState('');
const [initialProgramUrl, setInitialProgramUrl] = useState('');
const [isLoading, setIsLoading] = useState(false);

const [updateProgram] = useMutation(updateProgramMutation);

useEffect(() => {
    if(programData){
        setProgramName(programData.name);
        setInitialProgramName(programData.name);
        setProgramDescription(programData.description);
        setInitialProgramDescription(programData.description);
        setProgramUrl(programData.url);
        setInitialProgramUrl(programData.url);
    }
}, [programData]);


const handleSave = () => {
    setIsLoading(true);
    updateProgram({
        variables: {
            id: programData.id,
            input:{
            name: programName,
            description: programDescription,
            url: programUrl
        }
        }
    }).then(res => {
        refetch();
        setIsLoading(false);
    }).catch(err => {
        console.log("err =>", err)
    })
}

if(isLoading){
    return <Spinner />
}


    return (
        <div>
            <div>
            <button 
            className="green-button"
                style={{marginLeft: 'auto'}}
                disabled={programName === initialProgramName && programDescription === initialProgramDescription && programUrl === initialProgramUrl}
                onClick={handleSave}
                >Save</button>
            <div
              className="form-group"
              style={{ maxHeight: "66vh", overflowY: "scroll" }}
            >
              <label style={{color:'black'}}>Company Name</label>
              <input
                type="text"
                name="jobTitle"
                value={programName}
                onChange={(e) => setProgramName(e.target.value)}
                className="border rounded p-2 w-full mb-4"
              />
            
             <label style={{color:'black'}}>Company Description</label>
              <textarea
                name="detailedDescription"
                value={programDescription}
                onChange={(e) => setProgramDescription(e.target.value)}
                className="border rounded p-2 h-30vh w-full mb-4"
            />

               <label style={{color:'black'}}>Company URL</label>
               <input
                type="text"
                name="department"
                value={programUrl}
                onChange={(e) => setProgramUrl(e.target.value)}
                className="border rounded p-2 w-full mb-4"
              />
            




            </div>


                </div>
        </div>
    )
}

export default ProgramDetails;