import React, { useContext, useState,useEffect } from "react";
import "./styles/leftNavBar.css";
import navBarLogo from "../../../assets/imgs/navBarLogo.svg";
import Spinner from "../../common/spinner";
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/userContext';
// import packageInfo from '../../package.json';
import packageInfo from '../../../../package.json';


const LeftNavBar = ({ userRoles, page, setPage }) => {

    const { user, topUser, setUser, setTopUser } = useContext(UserContext);
    const [initials, setInitials] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (user && user.firstName && user.lastName) {
            setInitials(user.firstName.charAt(0) + user.lastName.charAt(0));
            setIsLoading(false);
        }
    }, [user]);

    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('login_token');
        navigate('/');
    }

    const accountAccessClicked = () => {
        if(topUser !== null && topUser !== undefined) {
            setUser(topUser)
            setTopUser(null)
            setPage('home')
        } else {
            setPage('accountAccess')
        }
       
    }
    if (isLoading) {
        return <Spinner/>
    }

    return (
        <div className='left-navBar-container'>
            <div className="left-navBar-header">
                <div >
                    <img className="left-navBar-profile-photo" src={user.profilePhotoUrl?user.profilePhotoUrl:`https://ui-avatars.com/api/?name=${initials}`} alt="profile" />
                    </div>
                <div >
                    <h2 className="left-navBar-header-profile-name">{user.firstName} {user.lastName}</h2>
                </div>
            </div>
            <ul>
                {/* We have four roles on the faculty side: program director, program coordinator, reviewer, and recommender. 
                Recommenders effectively only have the ability to upload a LOR, and their account is created when a student sends an invitation but that user is not affiliated with a team. 
                Reviewers can view only the applications that are assigned to them and have recommender privileges. 
                PDs and PCs have the same functionality and can do everything. 
 */}
                <li className={`left-navBar-item ${page === 'applicants' ? 'active' : ''}`} onClick={() => setPage('applicants')}>Students</li>
                <li className={`left-navBar-item ${page === 'tokens' ? 'active' : ''}`} onClick={() => setPage('tokens')}>Tokens</li>
                <li className={`left-navBar-item ${page === 'team' ? 'active' : ''}`} onClick={() => setPage('team')}>Manage Team</li>
                {/* <li className={`left-navBar-item ${page === 'advisors' ? 'active' : ''}`} onClick={() => setPage('advisors')}>Advisors</li>
                <li className={`left-navBar-item ${page === 'analytics' ? 'active' : ''}`} onClick={() => setPage('analytics')}>Analytics</li>
                <li className={`left-navBar-item ${page === 'exports' ? 'active' : ''}`} onClick={() => setPage('exports')}>Exports</li>
                <li className={`left-navBar-item ${page === 'setup' ? 'active' : ''}`} onClick={() => setPage('setup')}>Setup</li> */}
             
            </ul>
            <separator className="left-navBar-separator"/>
            <ul>
            <li className={`left-navBar-item ${page === 'accountAccess' ? 'active' : ''}`} onClick={accountAccessClicked}>{topUser !== null && topUser !== undefined? "Back to my account" : "Account Access"}</li>
                <li className={`left-navBar-item ${page === 'settings' ? 'active' : ''}`} onClick={() => setPage('settings')}>Settings</li>
                <li className={`left-navBar-item ${page === 'help' ? 'active' : ''}`} onClick={() => setPage('help')}>Help</li>
                <li className={`left-navBar-item ${page === 'help' ? 'active' : ''}`} onClick={() => setPage('userGuide')}>User Guide</li>
                <li className="left-navBar-item" onClick={handleLogout}>Log Out</li>
            </ul>
            <div className="left-navBar-footer">
                {/* <img src={navBarLogo} alt="logo" /> */}
                <p className="left-navBar-footer-text">{packageInfo.version}</p>
                <p className="left-navBar-footer-text">{new Date().getFullYear() } DDH | All Rights Reserved</p>
            </div>
        </div>
    );
}

export default LeftNavBar;