import React, { useState, useContext } from 'react';
import { Formik, Form, Field, ErrorMessage,FieldArray } from 'formik';
import { FormContext } from '../ApplicationForm';
import validationSchema from './workExpValidationSchema';


function countWords(input) {
  if (input) {
    const words = input.trim().split(/\s+/);
    return words.length;
  }
  return 0;
}

const WorkExp = ({isEdit}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null); 
  const { values, setStep, step, shortQuestions, setFieldValue, isSubmitting } = useContext(FormContext);
  const [initialValues, setInitialValues] = useState({ employer: '', jobTitle: '', startDate: '', endDate: '', mentorName: '', mentorEmail: '', description: ''})


  const removeWorkExp = (index) => {
    let updatedWorkExperiences = values.workExperiences.filter((_, i) => i !== index);
    setFieldValue('workExperiences', updatedWorkExperiences);
  };


          // workExperiences

          const addOrEditWorkExp = (workExp) => {
            let currentWorkExperiences = [...values.workExperiences];
            // Check if there is only one element and if all its values are empty strings
            if (editingIndex !== null) {
              currentWorkExperiences[editingIndex] = workExp; // Replace the element at the editing index
            }else if (currentWorkExperiences.length === 1 && Object.values(currentWorkExperiences[0]).every(value => value === '')) {
              currentWorkExperiences = [workExp]; // Replace the empty element with the new element
            } else {
              currentWorkExperiences.push(workExp); // Add the new element
            }
           
            setFieldValue('workExperiences', currentWorkExperiences);
            setIsModalOpen(false);
            setInitialValues({ employer: '', jobTitle: '', startDate: '', endDate: '', mentorName: '', mentorEmail: '', description: ''})
            setEditingIndex(null);
          }


          const editWorkExp = (index) => {
            setEditingIndex(index); // Set the current editing index
            setInitialValues(values.workExperiences[index])
            setIsModalOpen(true);

          };



  return (
    <>
   {values.workExperiences.map((workExperience, index) => (
  <div className='application-form-field-group two' style={{flexDirection:'row', gap:'2vw', alignItems:'baseline'}}>
      <>
      <div style={{display:'flex', width:'100%'}}>
        <p style={{
          fontFamily: 'Montserrat',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '18px',
          color: 'rgb(71, 71, 71)',
          lineHeight: '27px',
          marginBottom: '12px',
          textAlign: 'justify',
          width:'76%'
        }}>
         <div className="application-view-main-education-degrees-section" style={{display:'flex', flexDirection:'row'}}>
                    <div>
                        <div className="date" style={{marginLeft:'3vw', width:'13vw'}}>{workExperience.startDate} - {workExperience.endDate}</div>
                        <div className="ball"/>
                    </div>
                    <div className="institution-and-degree" style={{marginBottom:'2vh', textAlign:'justify', marginRight:'1vw'}}>
                        <div className="institution" style={{marginLeft:'1vw'}}>{workExperience.title}</div>
                        <div className="degree" style={{marginLeft:'1vw', fontWeight:'500'}}>{workExperience.employer}</div>
                        <div className="degree" style={{marginLeft:'1vw'}}>Mentor: {workExperience.mentorName},  {workExperience.mentorEmail}</div>
                        <div className="degree" style={{marginLeft:'1vw'}}>{workExperience.description}</div>
                    </div>
                </div>
        </p>
        <div style={{display:'flex', flexDirection:'row', gap:'1vw'}}>
        <button className='white-button' disabled={!isEdit} style={{flexGrow:'unset', flexBasis:'unset', padding:'8px 30px', alignSelf:'center'}} onClick={() => removeWorkExp(index)}>Remove</button>
        <button className='green-button' disabled={!isEdit} style={{alignSelf:'center'}} onClick={() => editWorkExp(index)}>Edit</button>
        </div>
      </div>
      </>


    </div>
    ))}



    <button className='green-button'  style={{width:'100%', marginBottom:'3vh'}} onClick={()=>setIsModalOpen(true)} disabled={values.workExperiences.length >= 3 || !isEdit}>Add Work Experience</button>
    {isModalOpen && (
  <div className='modal-backdrop'>
    <div className='add-research-modal' style={{minHeight:'71vh', maxHeight:'99vh'}}>
      <div className='add-research-modal-header'>
        <h3 style={{textAlign:'center'}}>Add Work Experience</h3>
      </div>
      <div className='add-research-modal-body'>
      <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {formikProps => (
        <Form style={{display:'flex', flexDirection:'column'}}>
          <div style={{ height: '56vh', overflowY: 'auto', gap:'2vh'}}>
          <div className="col" style={{ marginBottom: '2vh'}}>
                     {/* <label className="four-group-inner-header" htmlFor={`workExperiences.${index}.employer`}>Employer</label> */}
                        <Field
                        disabled={!isEdit}
                        name='employer'
                        placeholder="Employer"
                        type="text"
                        className='application-form-standard-input'
                        style={{width:'100%'}}
                        />
                        <ErrorMessage
                        name={`employer`}
                        component="div"
                        className="field-error"
                        style={{width:'23vw'}}
                        />
                 </div>
                 <div className="col" style={{ marginBottom: '2vh'}}>
                        {/* <label className='four-group-inner-header' htmlFor={`workExp.${index}.title`}>Title</label> */}
                            <Field
                            disabled={!isEdit}
                            name={`jobTitle`}
                            placeholder="Title"
                            type="text"
                            className='application-form-standard-input'
                            style={{width:'100%'}}
                            />
                            <ErrorMessage
                            name={`jobTitle`}
                            component="div"
                            className="field-error"
                            />
                </div>
                <div className="col" style={{ marginBottom: '2vh'}}>
                        {/* <label className='four-group-inner-header' htmlFor={`workExperiences.${index}.startDate`}>Start Date</label> */}
                            <Field
                            disabled={!isEdit}
                            name={`startDate`}
                            type="Text"
                            className='application-form-standard-input'
                            style={{width:'100%'}}
                            placeholder="Start date (MM/YYYY)"
                            pattern="(?:0[1-9]|1[0-2])/[0-9]{4}"
                            title="Enter date in MM/YYYY format"
                            />
                            <ErrorMessage
                            disabled={!isEdit}
                            name={`startDate`}
                            component="div"
                            className="field-error"
                            />
                </div>
                <div className="col" style={{ marginBottom: '2vh'}}>
                        {/* <label className='four-group-inner-header' htmlFor={`workExperiences.${index}.endDate`}>End Date</label> */}
                            <Field
                            disabled={!isEdit}
                            name={`endDate`}
                            type="Text"
                            className='application-form-standard-input'
                            style={{width:'100%'}}
                            placeholder="End Date (MM/YYYY) - leave blank if ongoing"
                            pattern="(?:0[1-9]|1[0-2])/[0-9]{4}"
                            title="Enter date in MM/YYYY format"
                            />
                            <ErrorMessage
                            name={`endDate`}
                            component="div"
                            className="field-error"
                            />
                </div>
                <div className="col" style={{ marginBottom: '2vh'}}>
                        {/* <label className='four-group-inner-header' htmlFor={`workExperiences.${index}.mentorName`}>Mentor Name</label> */}
                            <Field
                            disabled={!isEdit}
                            name={`mentorName`}
                            placeholder="Mentor Name"
                            type="text"
                            className='application-form-standard-input'
                            style={{width:'100%'}}
                            />
                            <ErrorMessage
                            name={`mentorName`}
                            component="div"
                            className="field-error"
                            />
                </div>
                <div className="col" style={{ marginBottom: '2vh'}}>
                        {/* <label className='four-group-inner-header' htmlFor={`workExperiences.${index}.mentorEmail`}>Mentor Email</label> */}
                            <Field
                            disabled={!isEdit}
                            name={`mentorEmail`}
                            placeholder="Mentor Email"
                            type="email"
                            className='application-form-standard-input'
                            style={{width:'100%'}}
                            />
                            <ErrorMessage
                            name={`mentorEmail`}
                            component="div"
                            className="field-error"
                            />
                </div>
                <div className="col" >
                        
                        <Field as="textarea" 
                        disabled={!isEdit} 
                        name={`description`} 
                        style={{height:'12vh',width:'100%', marginBottom:'0'}}
                        placeholder="A brief description of your role and its significance" 
                        className='application-form-standard-input textBox' 
                        onChange={(e) => {
                          const words = countWords(e.target.value)
                          console.log("words =>", words)
                          if (words <= 100) {
                            formikProps.setFieldValue("description", e.target.value);
                          }
                        }}
                      />
                            <ErrorMessage
                            name={`description`}
                            component="div"
                            className="field-error"
                            />
                            <div>Word count: {countWords(formikProps.values.description)}/100</div>
                </div>
          </div>
          <div style={{ display:'flex', gap:'2vw', alignSelf:'center', marginTop: '2vh'}}>
            { console.log("", isEdit, formikProps)}
            <button className='green-button' disabled={!isEdit || formikProps.isValid} onClick={()=>addOrEditWorkExp(formikProps.values)} >Add</button>
            <button className='white-button' disabled={!isEdit} onClick={()=>setIsModalOpen(false)}>Cancel</button>
          </div>
        </Form>
      )}
    </Formik>

{/* { category: '', title: '', authors:'', journal:'', datePublished:'', pubMedId:'', isFirstAuthor:'', significantResearchExperience:false, meeting:'', meetingDate:'', order:0} */}
      </div>
    </div>
  </div>
)}
    </>
)
}

export default WorkExp;