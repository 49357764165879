import React, { useState, useContext, useEffect } from 'react';
import logo from '../../../../assets/imgs/logo.svg'

import { useMutation } from '@apollo/client';
import {ProgramIdContext} from '../../progIdContext'
import Spinner from '../../../common/spinner';

const DropDownScoresModal = ({ isOpen, onClose, onUpdateWeight, lastRubricOrder, existingRubric, activeTab, rubrics, selectedRubric, onUpdateOptions }) => {
    const programId = useContext(ProgramIdContext);
    const [isLoading, setIsLoading] = useState(false);
    const [label, setLabel] = useState(existingRubric?existingRubric.label:'');
    const [inputType, setInputType] = useState(existingRubric?existingRubric.inputType:'text');
    const [dropdownOptions, setDropdownOptions] = useState(existingRubric?existingRubric.dropdownOptions:[]);
    const [maxScale, setMaxScale] = useState(existingRubric?existingRubric.maxScale:5); // Default value set to 5
    const [lowestScaleText, setLowestScaleText] = useState(existingRubric?existingRubric.lowestScaleText:'');
    const [highestScaleText, setHighestScaleText] = useState(existingRubric?existingRubric.highestScaleText:'');
    // const [createRubricTemplate, { data }] = useMutation(CREATE_RUBRIC_TEMPLATE);

    const [relevantRubrics, setRelevantRubrics] = useState([]);
    const [relevantOptions, setRelevantOptions] = useState([]);

    console.log("selectedRubric =>", selectedRubric)

    

    useEffect(() => {
        if (selectedRubric && selectedRubric.dropdownOptions && selectedRubric.dropdownOptions.length > 0) {
            setRelevantOptions(selectedRubric.dropdownOptions);
        }
    }, [selectedRubric]);


    const hadnleSetRubricsWeight = async () => {
        onUpdateWeight(relevantRubrics);
    
    };



const handleUpdateDropdownScore = () => {
    onUpdateOptions(relevantOptions, selectedRubric)
}


const handleEditScore = (option, value) => {
    setRelevantOptions(prevOptions => {
        console.log("prevOptions =>", prevOptions, option, value)
        const newOptions =  prevOptions.map(prevOption => 
            prevOption.option === option ? {...prevOption, score: value} : prevOption)
        console.log("newOptions =>", newOptions)
        return newOptions
    })
}


        if (!isOpen) return null;
        if (isLoading) return <Spinner />;
    return (
        <div className="modal-backdrop">
            <div className="add-field-modal" style={{height:'97vh'}}>
                <div className='add-field-modal-header'>
                <h2>Dropdown Scores</h2>
                <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
                </div>
                { relevantOptions && relevantOptions.length > 0 && (
                    <div className='relevant-rubrics' style={{height:'71vh', maxHeight:'71vh', overflowY:'scroll', display:'flex',flexDirection:'column',gap:'2vh', marginTop: '2vh'}}>
                            {relevantOptions.map(option => (
                                <div className='category-weight-cont'  style={{display:'flex', gap: '2vw'}}>
                                      <label style={{
                            fontFamily: 'Montserrat',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '22px',
                            color: '#145388',
                            lineHeight: '24px',
                            marginBottom: '19px',
                            width:'20vw'
                            }}>{option.option}</label>
                             <input
                             style={{
                                fontFamily: 'Montserrat',
                                lineHeight: '24px',
                                height: '4vh',
                             }}
                              value={option.score}
                                type="text"
                                min='1'
                                max='100'
                                className="application-form-standard-input"
                                onChange={(e) => {
                                    if (e.target.value !== '') {
                                        const value = parseInt(e.target.value, 10);
                                        console.log("value =>", value)
                                        handleEditScore(option.option, isNaN(value) ? 0 : value)
                                    }else{
                                     handleEditScore(option.option, '')
                                    }
                                }}
                                onKeyDown={(e) => {
                                    // if (e.key === 'Enter') {
                                    //     e.preventDefault();
                                    //     // If the input is empty, set the weight to 1
                                    //     if (e.target.value === '') {
                                    //         handleAddOption(rubric.id, 1);
                                    //     }
                                    //     e.target.blur();
                                    // }
                                }}
                                placeholder="Type Your Value and Press Enter"
                            />
                                </div>
                            ))}
                    </div>
                )}
                {!selectedRubric.dropdownOptions || selectedRubric.dropdownOptions.length === 0 && (
                    <div className='add-field-modal-content'>
                        <div className='add-field-modal-content-row'>
                        No relevant dropdown options found
                        </div>
                    </div>
                )}
                
                    <div className='button-container' style={{display:'flex', flexDirection:'row', justifyContent:'space-between', gap:'2vw'}}>
                    <button type="button" className='gradient-button' onClick={handleUpdateDropdownScore}
                    disabled={selectedRubric.dropdownOptions && selectedRubric.dropdownOptions.length === 0}
                    >Update</button>
                    <button type="button" className='white-button'  onClick={onClose}>Cancel</button>
                    </div>

            </div>
        </div>
    );
};

export default DropDownScoresModal;