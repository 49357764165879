import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneInputComponent = ({phone, handleOnChange, isNested}) => {

  return (
    <PhoneInput
      country={'us'}
      value={phone}
      onChange={(value) => handleOnChange(value)}
      inputStyle={{
        width: isNested?'39vw':'42vw',
        height: '61px',
        backgroundColor: '#f2f2f2',
        border: '1px solid #dbdbdb',
        borderRadius: '8px',
        paddingLeft: '50px', // Adjust padding to prevent overlap with flag
        fontSize: '20px',
        fontFamily: 'Montserrat',
        color: '#414141',
        boxSizing: 'border-box'
      }}
      buttonStyle={{
        backgroundColor: '#f2f2f2',
        border: 'none',
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px',
      }}
      dropdownStyle={{
        backgroundColor: '#fff',
        color: 'black',
        fontFamily: 'Montserrat',
        width: '20vw'
      }}
    />
  );
};

export default PhoneInputComponent;
