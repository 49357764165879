import { gql } from "@apollo/client";


export const updateGroupsApplicationIds = gql`
  mutation updateGroupsApplicationIds($groupIds: [ID!], $applicationIds: [ID!]) {
    updateGroupsApplicationIds(groupIds: $groupIds, applicationIds:$applicationIds) {
      id
      name
      description
      membersIds
      applicationIds
      createdAt
    }
  }

`

export const addCompletedReviewSettings = gql`
  mutation addCompletedReviewSettings($programId: ID, $settingsTitle: String, $selectedOrder: Boolean, $rubricsOrder: [RubricOrderInput]) {
    addCompletedReviewSettings(programId: $programId, settingsTitle: $settingsTitle, selectedOrder: $selectedOrder, rubricsOrder: $rubricsOrder) {
      id
      completedReviewsSettings {
        title
        name
        highestRank
        rubricsOrder {
            label
            result
            type
        }
      }
    }
  }
`