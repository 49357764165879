import React,{useState, useContext, useEffect, useRef} from "react";
import ReactDOM from 'react-dom';
import EditViewModal from "./editViewModal";
import "./index.css";
import magGlass from '../../../../assets/imgs/magGlass.svg'
import dropdownArrowIcon from '../../../../assets/imgs/DropdownArrowIcon.svg'
import filterIcon from '../../../../assets/imgs/filterIcon.svg'
import activeFilterIcon from '../../../../assets/imgs/activeFilterIcon.svg'
import backIcon from '../../../../assets/imgs/backIcon.svg'
import ApplicationsTable from "./applicationsTable";
import mockedData from './mockData/mockedApplicants.js'
import mockedFields from './mockData/mockedFields.js'
import allTableColumns from './tablecolumns.jsx'
import fieldMapping from './fieldMapping.js'
import mockedGroups from './mockData/mockedGroups.js'
import GroupsDropdown from "./groupsDropdown";
import ApplicationView from '../applicationView';
// import ApplicationView from '../applicationViewForPrint';
import DownloadPDF from './downloadPDF';
import { getApplicationsPaginationQuery , getGroupsByProgramIdQuery, getProgramById, getApplicationsCountQuery, getApplicationsCountsByStatus } from '../graphql/queries';
import {LIST_POSITIONS} from '../positions/graphql/queries';
import { useQuery } from '@apollo/client';
import { ProgramIdContext } from '../../progIdContext';
import {updateApplicationStatus, updateGroupsApplicationIds, removeAllApplicationsFromGroupMutation, removeApplicationFromGroupMutation, updateApplicationArchivedMutation, updateUserWatchApplicationMutation, updateSavedEditView, inviteUserToApply  } from '../graphql/mutations';
import client from "../../../../config/apolloClient";
import AdvancedFiltersModal from "./advancedFiltersModal";
import {UserContext} from '../../../../context/userContext';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import JSZip from 'jszip';
import ComponentToPrint from './downloadPDFView';
import ReactDOMServer from 'react-dom/server';
import Pagination from './paginationComponent';
import PageSizeSelector from './pageSizeSelector';
import Spinner from "../../../common/spinner";
import moment from 'moment';
import packageInfo from '../../../../../package.json';
import { saveAs } from 'file-saver';
import {fieldMappingForSortAndFilter} from './fieldMapping';
import AddApplicantModal from "./addApplicantModal.jsx";
import ImportAppsModal from "./importApplicationsModal";
import ResponseModal from '../../../common/resModal.jsx';
import PromptModal from "../../../common/promptModal.jsx";


function formatSelectedTracks(selectedTracks) {
const justTheTrackNamesArray = selectedTracks.map(track => track.trackName)
// Cat, Adv, Phys, CC, Rsrch, Peds
const formattedNames = justTheTrackNamesArray.map(name => {
    if (name === 'Categorical') {
        return 'Cat'
    } else if (name === 'Advanced') {
        return 'Adv'
    } else if (name === 'Physician Only') {
        return 'Phys'
    } else if (name === 'Critical Care') {
        return 'CC'
    } else if (name === 'Research') {
        return 'Rsrch'
    } else if (name === 'Peds/Anesthesiology') {
        return 'Peds'
  }
})
return formattedNames.join(', ')
}


const Applications = ({selectedGroup, refetchGroupsApps, setSelectedGroup, onBack, isApplicationsClicked, setIsApplicationsClicked, refetchProgram }) => {
    const { user, setUser } = useContext(UserContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDownloadDropdownOpen, setIsDownloadDropdownOpen] = useState(false);
    const [isEditViewModalOpen, setIsEditViewModalOpen] = useState(false);
    const [isAddApplicantModalOpen, setIsAddApplicantModalOpen] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [isAllApplications, setIsAllApplications] = useState(true);
    const initialFieldsState = mockedFields.map(fieldName => fieldMapping[fieldName]);
    const [mockedFieldsState, setMockedFields] = useState(user.savedEditView)
    const [areRowsSelected, setAreRowsSelected] = useState(false);
    const [mockedGroupsState, setMockedGroups] = useState(mockedGroups);
    const [groups, setGroups] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedApplication, setSelectedApplication] = useState(null);
    const [checkedGroups, setCheckedGroups] = useState([]);
    const [isApplicationViewOpen, setIsApplicationViewOpen] = useState(false);
    const [userId, setUserId] = useState(null)
    const [limit, setLimit] = useState(parseInt(sessionStorage.getItem('pageSize'))||10)
    const [offset, setOffset] = useState(0)
    const [filter, setFilter] = useState({field: null, value: null})
    const [sort, setSort] = useState(JSON.parse(sessionStorage.getItem('sort')) || { field: 'userId.lastName', direction: 'asc'});
    const [applications, setApplications] = useState([])
    const [isInsideGroups, setIsInsideGroups] = useState(false);
    const [applicationsIds, setApplicationsIds] = useState([]);
    const [screenedData, setScreenData] = useState({})
    const [filters, setFilters] = useState(selectedGroup?[]:JSON.parse(sessionStorage.getItem('filters')) || []);
    const [search, setSearch] = useState({field: null, value: null});
    const [searchInput, setSearchInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0)
    const [totalApplications, setTotalApplications] = useState(0);
    const [totalApplicationsForUser, setTotalApplicationsForUser] = useState(0);
    const [totalSignaledApplications, setTotalSignaledApplications] = useState(0);
    const [totalNewApplications, setTotalNewApplications] = useState(0);
    const [totalCompletedApplications, setTotalCompletedApplications] = useState(0);
    const [topBarStatus, setTopBarStatus] = useState(0); //0 - all applications, 1 - my applications, 2 - signal, 3 - new
    const componentRef = useRef();
    const programId = useContext(ProgramIdContext);
    const applicationViewMainRef = useRef();
    const hiddenContainerRef = useRef();
    const [capturing, setCapturing] = useState(false);
    const [screenshots, setScreenshots] = useState([]);
    const indeterminateRef = React.useRef(null);
    const downDropdownRef = React.useRef(null);
    const [allRendered, setAllRendered] = useState(false);
    const [isFileDownloading, setIsFileDownloading] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [preventFilters, setPreventFilters] = useState(false);
    const [pageChanged, setPageChanged] = useState(false);
    const [prevPageClicked, setPrevPageClicked] = useState(false);
    const [positions, setPositions] = useState([]);
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [isImportAppsModalOpen, setIsImportAppsModalOpen] = useState(false);
    const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);
    const [responseBody, setResponseBody] = useState(null);
    const [isPromptModalOpen, setIsPromptModalOpen] = useState(false);
    const [countByStatus, setCountByStatus] = useState({
        allApplications: 0,
        newApplications: 0,
        leadApplications: 0,
        reviewApplications: 0,
        phoneApplications: 0,
        interviewApplications: 0,
        offerApplications: 0,
        acceptedApplications: 0,
        rejectedApplications: 0
    });


    const {loading:loadingPositions, error:errorPositions, data:positionsData, refetch:refetchPositions} = useQuery(LIST_POSITIONS, {
        variables: { programId }
    });

    useEffect(() => {
        if (!loadingPositions && !errorPositions && positionsData) {
            if (positionsData.listPositions.length > 0) {
            setPositions(positionsData.listPositions);
            setSelectedPosition(positionsData.listPositions[0].id)
            }
        }
    }, [loadingPositions, errorPositions, positionsData]);

    useEffect(() => {
        if(user) {
            const isPDPC = user.userPremissions.includes("PD") ||  user.userPremissions.includes("PC")
            console.log("isPDPC =>", isPDPC)
            if(isPDPC === false) {
                setUserId(user.id)
                showApplicationsByUserClicked()
            }
        }
    }, [user])

    useEffect(() => {
        // This code will run after the userId state has been updated
        refetchApplications();
      }, [userId]);


console.log('SELECTED GROUP', selectedGroup)

useEffect(() => {
    if (pageChanged && applications.length > 0 && !applicationsLoading) {
        if (!prevPageClicked){
            setSelectedApplication(applications[0]);
        } else {
            setSelectedApplication(applications[applications.length - 1]);
        }
            
        setIsLoading(false);
        setPageChanged(false); // Reset the pageChanged flag
    }
}, [applications, pageChanged]);


    useEffect(() => {
        // Store parameters in session storage whenever they change
        if(preventFilters === false) sessionStorage.setItem('filters', JSON.stringify(filters));
        sessionStorage.setItem('sort', JSON.stringify(sort));
        // sessionStorage.setItem('search', JSON.stringify(search));
    }, [filters, sort, search]);


    useEffect(() => {
        console.log("isApplicationsClicked changed!", isApplicationsClicked)
        if(isApplicationViewOpen && isApplicationsClicked) {
            setIsApplicationViewOpen(false)
        }
    }, [isApplicationsClicked])


    const createPdf = async () => {
        if (!allRendered) return;
        const pages = Array.from(document.querySelectorAll('.a4-container'));
        const canvases = await Promise.all(pages.map(page => {
            
            console.log("page.offsetWidth =>", page.offsetWidth)

            return html2canvas(page, {
                scale: 1, // Increase scale for better resolution
                useCORS: true,
                logging: true,
                scrollX: 0,
                scrollY: 0,
        }
        )}));

        const pdf = new jsPDF('p', 'mm', 'a4');

        canvases.forEach((canvas, index) => {
            const imgData = canvas.toDataURL('image/png');
            
            // Skip this canvas if there's no image data
            if (imgData === 'data:,') {
                console.error('No image data in canvas');
                return;
            }
    
            const imgWidth = 180; // A4 width in mm
            const pageHeight = 340; // A4 height in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            
            if (index > 0) {
                pdf.addPage();
            }
        
            pdf.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight);
        });
        const fileName = `${selectedRows[0].lastName}_${selectedRows[0].firstName}_${selectedRows[0].aamc}_application.pdf`;
        pdf.save(fileName);
    };


    const createPdfInBackEnd = async () => {
        setIsFileDownloading(true);
        const dataToSend = [];
         selectedRows.forEach(id => {
            dataToSend.push({
                applicationId: id.id,
                fileName: `${id.lastName}_${id.firstName}_${id.aamc}_application`,
                token:localStorage.getItem('login_token')
            })
        })
        console.log("dataToSend =>", dataToSend)
        let backendPath = process.env.NODE_ENV === 'development' ? 'http://localhost:4100/generate-pdf' : 'https://pdfbecreator.accessgme.com/generate-pdf';

        fetch(backendPath, {
        // fetch(`http://localhost:7071/api/caPDFCreator`, {
        // fetch(`https://centralapppdfcreator.azurewebsites.net/api/caPDFCreator?code=lg46yvyp1WP_UzYoI0m4FmzJn988i0dwxmbfyuYPbnpOAzFu698m-Q%3D%3D`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'App-Version': packageInfo.version
            },
            body: JSON.stringify({
                applications: dataToSend
            })
        })
        .then(response => response.json())
        .then(json => {
            const pdfUrl = json.body.url; // Extract the URL from the response
            window.open(pdfUrl, '_blank');
            setIsFileDownloading(false);
        })
        .catch(error =>{
            console.error('Error:', error)
            setIsFileDownloading(false);
        } );
    }
    
 


    
    const { data: programData, error: programError, isLoading: programLoading} = useQuery(getProgramById, {
        variables: {
            id: programId
        },
    });

// status applications count
const { data: statusCountData, error: statusCountError, isLoading: statusCountLoading , refetch: statusRefetchCount} = useQuery(getApplicationsCountsByStatus, {
    variables: {
       positionId: selectedPosition,
    },
    fetchPolicy: 'network-only'
});

useEffect(() => {
    if (selectedPosition !== null && selectedPosition !== undefined) {
        statusRefetchCount()
    }
}, [selectedPosition])



    // all applications count
    const { data: countData, error: countError, isLoading: countLoading , refetch: refetchCount} = useQuery(getApplicationsCountQuery, {
        variables: {
            userId: filters.some(filter => filter.column === 'reviewDetails.stage') ? user.userId : userId,
            programId,
            // limit,
            // offset,
            filters:{filterConditions: filters},
            search,
            // sort,
            insideGroups: isInsideGroups,
            applicationsIds
        },
        fetchPolicy: 'network-only'
    });

    
    // my applications count
    const { data: countUserData, error: countUserError, isLoading: countUserLoading , refetch: refetchUserCount} = useQuery(getApplicationsCountQuery, {
        variables: {
            userId: user.userId,
            programId,
            // limit,
            // offset,
            filters:{ filterConditions: [{ column: "reviewDetails.stage", condition: "Equals", value: "Pending"}]},
            
            search,
            // sort,
            insideGroups: isInsideGroups,
            applicationsIds,
        },
        fetchPolicy: 'network-only'
    });

    // signaled applications count
    const { data: countSignaledData, error: countSignaledError, isLoading: countSignaledLoading , refetch: refetchSignaledCount} = useQuery(getApplicationsCountQuery, {
        variables: {
            userId: (user.userPremissions.includes("PD") ||  user.userPremissions.includes("PC")) ? null : user.userId,
            programId,
            limit,
            offset,
            filters: {filterConditions: [{column: "isSignal", condition: "Equals", value: "true"}]},
            search,
            sort,
            insideGroups: isInsideGroups,
            applicationsIds
        },
        fetchPolicy: 'network-only'
    });

    // new applications
    console.log("user before query =>", user)
    const { data: countNewData, error: countNewError, isLoading: countNewLoading , refetch: refetchNewCount} = useQuery(getApplicationsCountQuery, {
        variables: {
            userId: (user.userPremissions.includes("PD") ||  user.userPremissions.includes("PC")) ? null : user.userId,
            programId,
            limit,
            offset,
            filters: { filterConditions: [...filters, { column: "createdAt", condition: "After", value: user.userLogins.length > 1 ? user.userLogins[user.userLogins.length - 1] : user.lastLogin}]},
            search,
            sort,
            insideGroups: isInsideGroups,
            applicationsIds
        },
        fetchPolicy: 'network-only'
    });

// my completed reviews
    const { data: countCompletedReviewsData, error: countCompletedReviewsError, isLoading: countCompletedReviewsLoading , refetch: refetchCompletedCount} = useQuery(getApplicationsCountQuery, {
        variables: {
            userId: user.userId,
            programId,
            filters: { filterConditions: [{ column: "reviewDetails.stage", condition: "Equals", value: "Completed"}]},
            search,
            insideGroups: isInsideGroups,
            applicationsIds
        },
        fetchPolicy: 'network-only'
    });


    const { data: applicationsData, error: applicationsError, loading: applicationsLoading, refetch:refetchApplications } = useQuery(getApplicationsPaginationQuery, {
        variables: {
            // userId: filters.some(filter => filter.column === 'reviewDetails.stage') ? user.userId : userId,
            userId: filters.some(filter => filter.column === 'reviewDetails.stage') ? user.userId : userId,
            programId,
            limit,
            offset,
            filters:{filterConditions: filters},
            search,
            sort,
            insideGroups: isInsideGroups,
            applicationsIds,
            positionId: selectedPosition
        },
        fetchPolicy: 'network-only'
    });

    const { data: groupsData, error: groupsError, isLoading: groupsLoading , refetch} = useQuery(getGroupsByProgramIdQuery, {
        variables: {
            programId,
            filter: { field: 'name', value: null }
        },
        fetchPolicy:'network-only'
    });


    useEffect(() => {

            let countRes = 0;
            if(topBarStatus === 0) {
                countRes = countByStatus.allApplications;
            }
            if(topBarStatus === 1) {
                countRes = countByStatus.newApplications;
            }
            if(topBarStatus === 3) {
                countRes = countByStatus.leadApplications;
            }
            if(topBarStatus === 4) {
                countRes = countByStatus.reviewApplications;
            }
            if(topBarStatus === 5) {
                countRes = countByStatus.phoneApplications;
            }
            if(topBarStatus === 6) {
                countRes = countByStatus.interviewApplications;
            }
            if(topBarStatus === 7) {
                countRes = countByStatus.offerApplications;
            }
            if(topBarStatus === 8) {
                countRes = countByStatus.acceptedApplications;
            }
            if(topBarStatus === 9) {
                countRes = countByStatus.rejectedApplications;
            }
            // if (JSON.stringify(totalApplications) !== JSON.stringify(countRes)) {
            setTotalApplications(countRes);
            console.log("countRes for calculation =>", countRes);
            console.log("total pages =>", Math.ceil(countRes/ limit))
            setTotalPages(Math.ceil(countRes/ limit))
        // }
    }, [topBarStatus, countData]);



    useEffect(() => {
        if (statusCountLoading) {
          return; // Or set a loading state if necessary
        } else if (statusCountError) {
          console.error(statusCountError);
          return; // Handle the error appropriately
        } else if (statusCountData && statusCountData.getApplicationsCountsByStatus) {
          setCountByStatus(statusCountData.getApplicationsCountsByStatus);
        }
      }, [statusCountData, countError, countLoading]);


    useEffect(() => {
        if (countLoading) {
          return; // Or set a loading state if necessary
        } else if (countError) {
          console.error(countError);
          return; // Handle the error appropriately
        } else if (countData && countData.getApplicationsCount) {
          console.log("countData =>", countData)
          let countRes = countData.getApplicationsCount;
          if (JSON.stringify(totalApplications) !== JSON.stringify(countRes)) {
              if(topBarStatus === 0) {
                setTotalApplications(countRes);
                console.log("countRes =>", countRes);
                console.log("total pages =>", Math.ceil(countRes/ limit))
                setTotalPages(Math.ceil(countRes/ limit))
              }
           
          }
        }
      }, [countData, countError, countLoading, totalApplications]);

    useEffect(() => {
        if (countUserLoading) {
          return; // Or set a loading state if necessary
        } else if (countUserError) {
          console.error(countUserError);
          return; // Handle the error appropriately
        } else if (countUserData && countUserData.getApplicationsCount) {
          console.log("countUserData =>", countUserData)
          let countRes = countUserData.getApplicationsCount;
          if (JSON.stringify(totalApplicationsForUser) !== JSON.stringify(countRes)) {
            setTotalApplicationsForUser(countRes);
            console.log("countRes =>", countRes);
            // console.log("total pages =>", Math.ceil(countRes/ limit))
            // setTotalPages(Math.ceil(countRes/ limit))
          }
        }
      }, [countUserData, countUserError, countUserLoading, totalApplicationsForUser, setTotalApplicationsForUser]);
   
      useEffect(() => {
        if (countSignaledLoading) {
          return; // Or set a loading state if necessary
        } else if (countSignaledError) {
          console.error(countSignaledError);
          return; // Handle the error appropriately
        } else if (countSignaledData && countSignaledData.getApplicationsCount) {
          console.log("countSignaledData =>", countSignaledData)
          let countRes = countSignaledData.getApplicationsCount;
          if (JSON.stringify(totalSignaledApplications) !== JSON.stringify(countRes)) {
            setTotalSignaledApplications(countRes);
            console.log("countRes =>", countRes);
            // console.log("total pages =>", Math.ceil(countRes/ limit))
            // setTotalPages(Math.ceil(countRes/ limit))
          }
        }
      }, [countSignaledData, countSignaledError, countSignaledLoading, totalSignaledApplications, setTotalSignaledApplications]);
     
      useEffect(() => {
        if (countNewLoading) {
          return; // Or set a loading state if necessary
        } else if (countNewError) {
          console.error(countNewError);
          return; // Handle the error appropriately
        } else if (countNewData && countNewData.getApplicationsCount) {
          console.log("countNewData =>", countNewData)
          let countRes = countNewData.getApplicationsCount;
          if (JSON.stringify(totalNewApplications) !== JSON.stringify(countRes)) {
            setTotalNewApplications(countRes);
            console.log("countRes =>", countRes);
            // console.log("total pages =>", Math.ceil(countRes/ limit))
            // setTotalPages(Math.ceil(countRes/ limit))
          }
        }
      }, [countNewData, countNewError, countNewLoading, totalNewApplications, setTotalNewApplications]);

      useEffect(() => {
        if (countCompletedReviewsLoading) {
          return; // Or set a loading state if necessary
        } else if (countCompletedReviewsError) {
          console.error(countCompletedReviewsError);
          return; // Handle the error appropriately
        } else if (countCompletedReviewsData && countCompletedReviewsData.getApplicationsCount) {
          let countRes = countCompletedReviewsData.getApplicationsCount;
          if (JSON.stringify(totalCompletedApplications) !== JSON.stringify(countRes)) {
            setTotalCompletedApplications(countRes);
           
            // console.log("total pages =>", Math.ceil(countRes/ limit))
            // setTotalPages(Math.ceil(countRes/ limit))
          }
        }
      }, [countCompletedReviewsData]);

    const handlePageSizeChange = (event) => {
        if(event.target.value === '99999') {
            setOffset(0)
            setLimit(null)
            setCurrentPage(1); // Reset to first page when page size changes
            setTotalPages(1)
            refetchApplications()
        }else{
        setOffset(0)
        sessionStorage.setItem('pageSize', event.target.value);
        setCurrentPage(1); // Reset to first page when page size changes
        setTotalPages(Math.ceil(totalApplications/ Number(event.target.value)))
        setLimit(Number(event.target.value));
        refetchApplications()
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setOffset((page - 1) * limit )
        refetchApplications()

    };


    const handleClickOutside = (event) => {
        if (downDropdownRef.current && !downDropdownRef.current.contains(event.target)) {
            console.log("clicked outside!")
          // Close the dropdown
          setIsDownloadDropdownOpen(false);
        }
      };

      useEffect(() => {

        if (selectedGroup!==null && selectedGroup!==undefined && selectedGroup.name==='Rejected') {
            setFilters([...filters, { column: 'archived', condition: 'Equals', value: 'true' }])
        } else {
            if(filters.some(filter => filter.column === 'archived')) {
                setFilters(filters.filter(filter => filter.column !== 'archived'))
            }
        }

        if(selectedGroup !== null && selectedGroup !== undefined && isInsideGroups === false) {
            setApplicationsIds(selectedGroup.applicationIds)
            setIsInsideGroups(true)
        } 
    }, [selectedGroup])


    useEffect(() => {
        if (isDownloadDropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
          } else {
            document.removeEventListener('mousedown', handleClickOutside);
          }
      
          // Clean up the event listener on component unmount
          return () => {
            document.removeEventListener('mousedown', handleClickOutside);
          };
    }, [isDownloadDropdownOpen])

    React.useEffect(() => {

        if (indeterminateRef.current) {
            indeterminateRef.current.indeterminate = false; // or true, depending on your logic
        }
        return () => {
            setSearch({ field: 'value', value: '' });
            sessionStorage.setItem('search', JSON.stringify({ field: '', value: '' }));
        };
    }, []);
    

 


   

    if(!applicationsLoading && applicationsData && applicationsData.getApplicationsPagination) {
        console.log("applicationsData =>", applicationsData)
            let modifiedApplications = []
            applicationsData.getApplicationsPagination.forEach(application => {
                let appToPush = {
                    id: application.id,
                    region: application.region ?? '',
                    medicalSchoolState: application.medicalSchoolState ?? '',
                    firstName: application.userId?.firstName ?? '',
                    lastName: application.userId?.lastName ?? '',
                    email: application.userId?.email ?? '',
                    phone: application.userId?.phone ?? '',
                    createdAt: application.createdAt ?? '',
                    dateOfBirth: application.userId?.applicationInformation?.dateOfBirth ?? '',
                    coupleMatch: application.userId?.applicationInformation?.coupleMatch === 'yes' ? "Yes" : "No",
                    partnerField: application.userId?.applicationInformation?.partnerFeild ?? '',
                    misdemeanorOrFelony: application.userId?.applicationInformation?.misdemeanorOrFelony ?? '',
                    misdemeanorOrFelonyDetails: application.userId?.applicationInformation?.misdemeanorOrFelonyDetails ?? '',
                    nrmpId: application.userId?.applicationInformation?.nrmpId ?? '',
                    workAuthorization: application.userId?.applicationInformation?.workAuthorization ?? '',
                    usCitizen: application.userId?.applicationInformation?.workAuthorization === "U.S. Citizen, National, or Legal Permanent Resident (e.g., Refugee, Asylee)" ? "Yes" : "No",
                    gender: application.userId?.applicationInformation?.gender ?? '',
                    otherRaceEthnicity: application.userId?.applicationInformation?.otherRaceEthnicity ?? '',
                    otherWorkAuthorization: application.userId?.applicationInformation?.otherWorkAuthorization ?? '',
                    partnerName: application.userId?.applicationInformation?.partnerName ?? '',
                    hometown: application.userId?.applicationInformation?.homeTown ?? '',
                    address: application.userId?.applicationInformation?.homeAddress ?? '',
                    ethnicity: application.userId?.applicationInformation?.ethnicity ?? '',
                    medicalSchoolName: application.userId?.applicationInformation?.medicalSchoolName ?? '',
                    aoaStatus: application.userId?.applicationInformation?.aoaStatus ?? '',
                    goldHumanism: application.userId?.applicationInformation?.goldHumanism ?? '',
                    firstAuthorPublications: application.userId?.applicationInformation?.firstAuthorPublications ?? '',
                    peerReviewedPublications: application.userId?.applicationInformation?.peerReviewedPublications ?? '',
                    posterPresentations: application.userId?.applicationInformation?.posterPresentations ?? '',
                    podiumPresentations: application.userId?.applicationInformation?.podiumPresentations ?? '',
                    aamc: application.userId?.applicationInformation?.aamc ?? '',
                    previouslyAppliedToSpeciality: application.masterApplicationId?.previouslyAppliedToSpecialty === true ? "Yes" : "No",
                    isSignal: application.isSignal === true ? "Yes" : "No" ?? '',
                    signalType: application.signalType ?? '',
                    usmleStep1Score: application.scoreId?.usmleStep1Score ?? '',
                    usmleStep2Score: application.scoreId?.usmleStep2Score ?? '',
                    usmleStep3Score: application.scoreId?.usmleStep3Score ?? '',
                    comlexLevel1Score: application.scoreId?.comlexLevel1Score ?? '',
                    comlexLevel2Score: application.scoreId?.comlexLevel2Score ?? '',
                    user: application.userId ?? '',
                    masterApplicationId: application.masterApplicationId?._id ?? '',
                    researchExperience: application.masterApplicationId?.researchExperience ?? '',
                    carryingOutResponsibilities: application.masterApplicationId?.carryingOutResponsibilities ?? '',
                    status: application.masterApplicationId?.status ?? '',
                    carryingOutResponsibilitiesElaboration: application.masterApplicationId?.carryingOutResponsibilitiesElaboration ?? '',
                    workExperiences: application.masterApplicationId?.workExperiences ?? '',
                    meaningfulExperiences: application.masterApplicationId?.meaningfulExperiences ?? '',
                    hobbiesAndInterests: application.masterApplicationId?.hobbiesAndInterests ?? '',
                    shortAnswerQuestions: application.masterApplicationId?.shortAnswerQuestions ?? '',
                    personalStatement: application.masterApplicationId?.personalStatement ?? '',
                    signalEssay: application.signalEssay ?? '',
                    applicationRubrics: application.applicationRubrics ?? '',
                    watchUsersIds: application.watchUsersIds ?? '',
                    isLanguages: application.userId?.applicationInformation?.isLanguages ?? '',
                    fluentLanguages: application.userId?.applicationInformation?.fluentLanguages ?? '',
                    medicalSchoolType: application.userId?.applicationInformation?.medicalSchoolType ?? '',
                    archived: application.archived ? application.archived : false,
                    previousLastName: application.userId?.applicationInformation?.previousLastName ?? '',
                    prefferedName: application.userId?.applicationInformation?.prefferedName ?? '',
                    pronouns: application.userId?.applicationInformation?.pronouns ?? '',
                    usmleId: application.userId?.applicationInformation?.usmleId ?? '',
                    nbomeId: application.userId?.applicationInformation?.nbomeId ?? '',
                    aoaId: application.userId?.applicationInformation?.aoaId ?? '',
                    permanentAddress: application.userId?.applicationInformation?.permanentAddress ?? '',
                    licensureRevoked: application.userId?.applicationInformation?.licensureRevoked ?? '',
                    licensureRevokedDetails: application.userId?.applicationInformation?.licensureRevokedDetails ?? '',
                    militaryObligation: application.userId?.applicationInformation?.militaryObligation ?? '',
                    militaryObligationDetails: application.userId?.applicationInformation?.militaryObligationDetails ?? '',
                    otherServiceObligations: application.userId?.applicationInformation?.otherServiceObligations ?? '',
                    medicalTrainingInterrupted: application.userId?.applicationInformation?.medicalTrainingInterrupted ?? '',
                    medicalTrainingInterruptedDetails: application.userId?.applicationInformation?.medicalTrainingInterruptedDetails ?? '',
                    sigmaPhi: application.userId?.applicationInformation?.sigmaPhi ?? '',
                    selectedTracks: application.selectedTracks ? formatSelectedTracks(application.selectedTracks) : [],
                    selectedTracksRaw: application.selectedTracks ? application.selectedTracks : [],
                    reviewDetails: application.reviewDetails && application.reviewDetails > 0 ? application.reviewDetails : [],
                }
                modifiedApplications.push(appToPush)
            })
            if(JSON.stringify(modifiedApplications) !== JSON.stringify(applications)) {
                setApplications(modifiedApplications)
            }
        
    } 

    

    if(!groupsLoading&&groupsData&&groupsData.getGroupsByProgramId) {
        let groupsToDisplay = groupsData.getGroupsByProgramId
        if(JSON.stringify(groupsToDisplay) !== JSON.stringify(groups)) {
            setGroups(groupsToDisplay)
        }
    }


    if (programLoading) return <Spinner />;
    if (programError) return <p>Error :(</p>;
    if (!programData || !programData.getProgramById) return <p>No data available.</p>; // Add this line
    let screenedDataObj = programData.getProgramById.screenedData;
    if (JSON.stringify(screenedData) !== JSON.stringify(screenedDataObj)) {
        setScreenData(screenedDataObj);
        console.log("screenedData =>", screenedDataObj);
    }
    

    const getFullPathFromField = (column) => {
        switch(column) {
            case 'Submission Date': 
            return 'createdAt'
            case 'First Name':
            return 'users.firstName'
            case 'Last Name':
            return 'users.lastName'
            case 'Email':
            return 'users.email'
            case 'Phone Number':
            return 'users.phone'
            case 'NRMP ID':
            return 'users.applicationInformation.nrmpId'
            case 'US Citizen':
            return 'users.applicationInformation.workAuthorization'
            case 'Gender Identity': 
            return 'users.applicationInformation.gender'
            case 'Hometown': 
            return 'users.applicationInformation.homeTown'
            case 'Race/Ethnicity':
            return 'users.applicationInformation.ethnicity'
            case "Couple's Match":
            return 'users.applicationInformation.coupleMatch'
            case 'Medical School Name':
            return 'users.applicationInformation.medicalSchoolName'
            case 'AOA Status':
            return 'users.applicationInformation.aoaStatus'
            case 'Gold Humanism Honor Society Status':
            return 'users.applicationInformation.goldHumanism'
            case 'First Author Publications':
            return 'users.applicationInformation.firstAuthorPublications'
            case 'Peer-reviewed publications':
            return 'users.applicationInformation.peerReviewedPublications'
            case 'Poster presentations':
            return 'users.applicationInformation.posterPresentations'
            case 'Podium presentations':
            return 'users.applicationInformation.podiumPresentations'
            case 'AAMC ID':
            return 'users.applicationInformation.aamc'
            case 'Previous Applicant':
            return 'masterApplicationId.previouslyAppliedToSpecialty'
            case 'Signal':
            return 'isSignal'
            case 'Signal Type':
            return 'signalType'
            case 'Step 1':
            return 'scoreId.usmleStep1Score'
            case 'Step 2':
            return 'scoreId.usmleStep2Score'
            case 'Comlex 1':
            return 'scoreId.comlexLevel1Score'
            case 'Comlex 2': 
            return 'scoreId.comlexLevel2Score'
            case 'Previous Last Name':
            return 'users.applicationInformation.previousLastName'
            case 'Preffered Name':
            return 'users.applicationInformation.prefferedName'
            case 'Pronouns':
            return 'users.applicationInformation.pronouns'
            case 'USMLE Id':
            return 'users.applicationInformation.usmleId'
            case 'NBOME Id':
            return 'users.applicationInformation.nbomeId'
            case 'AOA Id':
            return 'users.applicationInformation.aoaId'
            case 'Permanent Address':
            return 'users.applicationInformation.permanentAddress'
            case 'Licensure Revoked':
            return 'users.applicationInformation.licensureRevoked'
            case 'Licensure Revoked Details':
            return 'users.applicationInformation.licensureRevokedDetails'
            case 'Military Obligation':
            return 'users.applicationInformation.militaryObligation'
            case 'Military Obligation Details':
            return 'users.applicationInformation.militaryObligationDetails'
            case 'Other Service Obligations':
            return 'users.applicationInformation.otherServiceObligations'
            case 'Medical Training Interrupted':
            return 'users.applicationInformation.medicalTrainingInterrupted'
            case 'Medical Training Interrupted Details':
            return 'users.applicationInformation.medicalTrainingInterruptedDetails'
            case 'Sigma Sigma Phi':
            return 'users.applicationInformation.sigmaPhi'
            case 'Region': 
            return 'region'
            case 'Medical School State':
            return 'medicalSchoolState'
 

            default:
            return ''
        }
    }
  

    const sortApplication = async(column, direction) => {
        const coloumnToSend = await getFullPathFromField(column);
        setSort({ field: coloumnToSend, direction: direction === true ? "desc" : "asc" });
        console.log("column in the sortApplications function=>", column, coloumnToSend);
        refetchApplications();
      };
      



    const isRowSelected = (id) => {
        const isSelected = selectedRows.some(row => row.id === id);
        return isSelected
    }

    const handleCheckboxChange = (row) => {
        if (row === 'SelectAll') {
            if(selectAll) {
                setSelectedRows([])
            } else {
                setSelectedRows(applications)
            }
            setSelectAll(!selectAll)
            return 
        }

        let selectedRowId = row.original.id
        let filtered = selectedRows.filter(elem => elem.id.toString() === selectedRowId.toString())
        if(filtered.length > 0) {
            setSelectedRows(prevSelected => prevSelected.filter(selected => selected.id.toString() !== row.original.id.toString()))
        } else {
            setSelectedRows(prevSelected => [...prevSelected, row.original])
        }




        // setSelectedRows(prevSelected =>
        //   isRowSelected(row.original.id)
        //     ? prevSelected.filter(selected => selected.id !== row.original.id) // Remove row if selected
        //     : [...prevSelected, row] // Add row if not selected
        // );
    }; 

    const updateUserViewApplication = (application) => {
        client.mutate({
            mutation: updateUserWatchApplicationMutation,
            variables: {
                applicationId: application.id,
                userId: user.userId
            }
        })
        .then(res => {
            refetchApplications()
        })
    }


    // go over the applications, and change the values of the each obejct's key: create at to be a date in the following format: row.original.createdAt !== "" ? moment(parseInt(row.original.createdAt)).format('MM/DD/YY') : ""
    const dataToRender =  applications.map(application => {
        let modifiedApplication = {...application}
        modifiedApplication.createdAt = application.createdAt !== "" ? moment(parseInt(application.createdAt)).format('MM/DD/YY') : ""
        return modifiedApplication
    })



    const defaultColumns = [
        {
            id: 'selection',
            width: 'fit-content',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                 <input type="checkbox" ref={indeterminateRef} {...getToggleAllRowsSelectedProps()} checked={selectAll} onChange={() => handleCheckboxChange('SelectAll')} style={{ accentColor: "#145388", padding:'1vh 2vw', marginLeft:'.6vw'}}/>
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <input type="checkbox" {...row.getToggleRowSelectedProps()} checked={isRowSelected(row.original.id)}   onChange={() => handleCheckboxChange(row)} style={{ padding:'1vh 2vw', accentColor: "#145388"}} />
              </div>
            )
          },
          
        {
            Header: () => <div style={{margin:'0 .5vw 0 .5vw', cursor:'default'}} title="Unopened Application">🟢</div>,
            width: 'fit-content',
            Cell: ({ row }) => !row.original.watchUsersIds.includes(user.userId) ? <div style={{margin:'0 .5vw 0 .5vw', cursor:'default'}} title="Unopened Application">🟢</div> : <div style={{ width: '100%', textAlign: 'center', color:'white', padding:'.2vh'}}>O</div>,
            id: 'greenDot',
            accessor: 'watchUsersIds'
        },
        {
            id: 'remove',
            Header: () => <div onClick={handleRemoveAllApplicationsFromGroup} title="Remove All Applications" style={{ cursor:'pointer', color: 'red',fontWeight:'600', minWidth:'0', margin:'0 .5vw 0 .5vw' }}>
            X
          </div>,
           accessor: 'remove',
           
           Cell: ({ row }) => (
            <label title="Remove From Folder" onClick={() => {handleRemoveApplicationFromGroup(row.original)}} style={{ cursor:'pointer', color: 'red',fontWeight:'600', minWidth:'0' }}>
            X
          </label>
           )
        },
        {
            id: 'view',
            // Header: () => <div style={{ textAlign: 'center', color:'white', padding:'.2vh'}}>View</div>,
            Header: () => <div style={{margin:'0 1.9vw 0 2vw'}}>View</div>,
            accessor: 'view',
            width: 'fit-content',
            Cell: ({ row }) => (
                <label onClick={() => {handleSelectedApplication(row.original); setIsApplicationViewOpen(true); setIsApplicationsClicked(false); updateUserViewApplication(row.original);}} style={{ cursor:'pointer', color: '#145388', textDecoration: 'underline',fontWeight:'400', padding:'1vh 2vw' }}>
                View
              </label>
            )
          },

    ];

    const archiveApplication = (application) => {
        console.log("application to archive =>", application)
        client.mutate({
            mutation: updateApplicationArchivedMutation,
            variables: {
                applicationId: application.id,
                archived: true 
            }
        })
    }

    const btnColumns = [
        // {
        //     id: 'archiveBtn',
        //     Header: <label></label>,
        //     Cell: ({ row }) => (
        //       <div>
        //          <button className='green-button' style={{padding:'1vh 2vw', marginRight: '1vw'}} onClick={() => archiveApplication(row.original)}>Archive</button>
        //       </div>
        //     ),
        //     width: 300
        //   },
    ]




      const handleSelectedApplication = (row) => {
        setSelectedApplication(row);
    };

    const handleEditViewSave =  async (selectedFields) => {
        // Map the selectedFields (which are headers) to their corresponding accessors
        const selectedAccessors = selectedFields.map(fieldName => fieldMapping[fieldName]);
        
        await client.mutate({
                mutation: updateSavedEditView,
                variables: {
                    userId: user.userId,
                    view: selectedAccessors
                }
            })
        setMockedFields(selectedAccessors);
        setUser({...user, savedEditView: selectedAccessors})
        setIsEditViewModalOpen(false);
    };


    var dynamicColumns = mockedFieldsState.map(fieldAccessor => {
        const header = Object.keys(fieldMapping).find(key => fieldMapping[key] === fieldAccessor);
        return header ? { id: header, Header: header, accessor: fieldAccessor, isSorted: true } : null;
    }).filter(Boolean);

    let screenedFields = {
        ...screenedData.personalInfo,
        ...screenedData.residencyApplication,
    }
    let screenedNotShow = Object.keys(screenedFields).reduce((acc, key) => {
        if (screenedFields[key] === false) {
            acc[key] = screenedFields[key];
        }
        return acc;
    }, {});

    console.log("screenedNotShow =>", screenedNotShow)

    let notShowKeys = Object.keys(screenedNotShow)
    console.log("notShowKeys =>", notShowKeys)

    notShowKeys.forEach(key => {
        dynamicColumns = dynamicColumns.filter(elem => elem.accessor !== key)
    })
    
    console.log("dynamicColumns =>", dynamicColumns)


    let coulmnsBeforeUpdate = [...defaultColumns, ...dynamicColumns, ...btnColumns];
    
    if(!isInsideGroups) {
        // remove the remove column
        coulmnsBeforeUpdate = coulmnsBeforeUpdate.filter(elem => elem.id !== 'remove')
    }

    const columns = coulmnsBeforeUpdate;





    const updateSelectedRows = (rows) => {
        setSelectedRows(rows)
    }

    
    const handleApplyClick = async (updatedGroups) => {
        // Assuming updatedGroup is a single group object with updated applicationIds
        try {
            console.log("updatedGroups =>", updatedGroups)
            await Promise.all(updatedGroups.map(async updatedGroup => {
                const response = await client.mutate({
                    mutation: updateGroupsApplicationIds,
                    variables: {
                        groupIds: [updatedGroup.id],
                        applicationIds: updatedGroup.applicationIds,
                    }
                });
        
                const updatedGroupFromResponse = response.data.updateGroupsApplicationIds;
        
                // Update the groupsState by merging the updated group
                setGroups(prevGroups => prevGroups.map(group =>
                    group.id === updatedGroupFromResponse.id ? updatedGroupFromResponse : group
                ));
        
                // Handle specific group updates if necessary
                if (isInsideGroups && selectedGroup.id === updatedGroupFromResponse.id) {
                    setApplicationsIds(updatedGroupFromResponse.applicationIds);
                    refetchApplications();
                }
            }))

         
        } catch (err) {
            console.error("Error updating group:", err);
        }
    };


    const handleRemoveAllApplicationsFromGroup = () => {
        setIsLoading(true);
        client.mutate({
            mutation: removeAllApplicationsFromGroupMutation,
            variables: {
                groupId: selectedGroup.id,
            }
        }).then(res => {
            console.log('just got back from the backend', res)
            setSelectedGroup(res.data.removeAllApplicationsFromGroup)
            setApplicationsIds([])
        }).catch(err => {
            console.error(err)
            setIsLoading(false);
        }
        ).finally(() => {
            refetchApplications()
             refetchNewCount()
            refetchSignaledCount()
            refetchCount()
            setIsLoading(false);
        })
    }

    
    const handleRemoveApplicationFromGroup = (row) => {
        // setIsLoading(true);
        const applicationId = row.id;
        const groupId = selectedGroup.id;
        client.mutate({
            mutation: removeApplicationFromGroupMutation,
            variables: {
                groupId:groupId,
                applicationId: applicationId,
            }
        }).then(res => {
            console.log('just got back from the backend', res)
            setSelectedGroup(res.data.removeApplicationFromGroup)
            setApplicationsIds(res.data.removeApplicationFromGroup.applicationIds)

        }).catch(err => {
            console.error(err)
            setIsLoading(false);
        }
        ).finally(() => {
            refetchApplications()
             refetchNewCount()
            refetchSignaledCount()
            refetchCount()
            // setIsLoading(false);
        })
    }


    const handleInviteToApply = (selectedRows) => {
        console.log('invite to apply from within the applicationview', selectedRows)
        let succsses = 0;
        let fails = 0;
        for (let i = 0; i < selectedRows.length; i++) {
            client.mutate({
                mutation: inviteUserToApply,
                variables: {
                    applicationId: selectedRows[i].id,
                }
            }).then(res => {
                console.log('just got back from the backend', res.data.inviteUserToApply)
                if (res.data.inviteUserToApply) {
                    succsses += 1;
                }else {
                fails += 1;
                }
            }
            ).catch(err => {
                console.error(err)
            }
            )

    }

    setResponseBody(`${succsses} out of ${selectedRows.length} invitations were sent successfully. ${fails} failed.`);
    setIsResponseModalOpen(true);
}

const handleRejectApplications = () => {

    setIsPromptModalOpen(false);

    let successes = 0;
    let fails = 0;
    for (let i = 0; i < selectedRows.length; i++) {
        client.mutate({
            mutation: updateApplicationStatus,
            variables: {
                applicationId: selectedRows[i].id,
                status: 'Rejected',
            }
        }).then(res => {
            console.log('just got back from the backend', res.data.updateApplicationStatus)
            if (res.data.updateApplicationStatus) {
                successes += 1;
            } else {
                fails += 1;
            }
        }).catch(err => {
            console.error(err)
        }
        )
        setResponseBody(`${successes} out of ${selectedRows.length} were rejected successfully. ${fails} failed.`);
        setIsResponseModalOpen(true);
        refetchApplications();
        statusRefetchCount();
    }
}


const handleClickRejected = (selectedRows) => {
    const applicationNames = selectedRows.map(row => ` ${row.firstName} ${row.lastName}`);
    setResponseBody(`Are you sure you want to reject the applications of ${applicationNames}?`);
    setIsPromptModalOpen(true);

}

const handleStatusChange = (selectedRows, status) => {
    console.log('status change from within the applicationview', selectedRows, status)
    console.log(typeof selectedRows)
    let succsses = 0;
    let fails = 0;
    for (let i = 0; i < selectedRows.length; i++) {
        client.mutate({
            mutation: updateApplicationStatus,
            variables: {
                applicationId: selectedRows[i].id,
                status: status,
            }
        }).then(res => {
            // console.log('just got back from the backend', res.data.inviteUserToApply)
            if (res.data.updateApplicationStatus) {
                succsses += 1;
            }else {
            fails += 1;
            }
        }
        ).catch(err => {
            console.error(err)
        }
        )
}
setResponseBody(`${succsses} out of ${selectedRows.length} moved to ${status}. ${fails} failed.`);
setIsResponseModalOpen(true);
}


    const handleFilterRemove = (newFilters) => {
        console.log('new filters on delete', newFilters)
        setFilters(newFilters);
      };


      function debounce(func, delay) {
        let debounceTimer;
        return function() {
            const context = this;
            const args = arguments;
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func.apply(context, args), delay);
        }
    }


const debouncedSearch = debounce(value => {
    setSearch({ field: 'value', value: value })
}, 1500);

const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchInput(value)
    if (value === '') {
        setSearch({ field: 'value', value: '' });
        return;
    }
    debouncedSearch(value); // Update the search query after debounce
}

const nextApplicationClicked = () => {
    let selectedIdx = applications.findIndex(elem => elem.id.toString() === selectedApplication.id.toString());

    if (selectedIdx + 1 < applications.length) {
        // Move to the next application in the current page
        let nextSelectedIdx = selectedIdx + 1;
        let nextApplication = applications[nextSelectedIdx];
        setSelectedApplication({ ...nextApplication });
    } else if (currentPage < totalPages) {
        setPrevPageClicked(false);
        setPageChanged(true);
        // Calculate the new page and offset
        const newPage = currentPage + 1;
        const newOffset = newPage * limit;

        setPageChanged(true);
        // Update the state with the new values
        setCurrentPage(newPage);
        setOffset(newOffset);

        // Use a callback to ensure refetchApplications uses the updated state
        refetchApplications();
        setIsLoading(true);
        // setTimeout(() => {
        //     setIsLoading(false);
        // }, 1000);
        // setSelectedApplication(applications[0]);
};
}
const previousApplicationClicked = () => {
    let selectedIdx = applications.findIndex(elem => elem.id.toString() === selectedApplication.id.toString());

    if (selectedIdx > 0) {
        // Move to the previous application in the current page
        let previousSelectedIdx = selectedIdx - 1;
        let previousApplication = applications[previousSelectedIdx];
        setSelectedApplication({ ...previousApplication });
    } else if (currentPage > 1) {
        setPageChanged(true); // Indicate that the page has changed
        setPrevPageClicked(true);
        // Calculate the new page and offset
        const newPage = currentPage - 1;
        const newOffset = (newPage - 1) * limit;

        // Update the state with the new values
        setCurrentPage(newPage);
        setOffset(newOffset);

        // Refetch applications with the new offset
        refetchApplications();
        setIsLoading(true);
    }
};
    const handleFiltersChange = (newFilters) => {
        newFilters = newFilters.map(filter => {
          const { __typename, ...rest } = filter;
          return rest;
        });

        setFilters(newFilters);
      };
    
    function checkImageType(file) {
        const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
        if (validImageTypes.includes(file.type)) {
            console.log('Valid image file.');
            return true;
        } else {
            console.error('Invalid image file.');
            return false;
        }
    }


    const DownloadCSV = () => {
        console.log("columns =>", columns)
        console.log("applications =>", applications)
        console.log("selectedRows =>", selectedRows)

        const dataToDisplay = selectedRows.length === 0 ? applications: selectedRows
        const csvRows = [];
        // Get headers
        const filteredColumns = columns.filter(elem => elem.id !== 'selection' && elem.id !== 'greenDot' && elem.id !== 'view')
        var headers = filteredColumns.map(column => column.Header).filter(header => typeof header === 'string');
        csvRows.push(headers.join(','));
    
        // Get rows data
        dataToDisplay.forEach(application => {
            const values = headers.map(header => {
                const accessor = filteredColumns.find(column => column.Header === header).accessor;
                if(accessor === "createdAt") console.log("accessor =>", accessor, application[accessor])
                return accessor === "createdAt" ? moment(parseInt(application[accessor])).format('MM/DD/YYYY HH:mm') : `"${application[accessor]}"`; // Wrap in quotes to handle commas in data
            });
            csvRows.push(values.join(','));
        });
    
        console.log("filteredColumns => ", filteredColumns)
        // Create blob and download
        const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'applications.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const handleExportToPDF = async () => {
        setIsLoading(true);
        const element = hiddenContainerRef.current;
    
        // Wait for all images to load
        const images = element.querySelectorAll('img');
        console.log("images =>", images)
        const imageLoadPromises = Array.from(images).map(img => {
            if (img.complete) return Promise.resolve();
            return new Promise(resolve => {
            img.onload = () => {
                console.log(`Image loaded: ${img.src}`);
                resolve();
            };
            img.onerror = () => {
                console.error(`Error loading image: ${img.src}`);
                resolve(); // Resolve even on error to avoid hanging
            };
            });
        });
    
        try {
            await Promise.all(imageLoadPromises);
            console.log('All images loaded');
        } catch (error) {
            console.error('Error loading images', error);
            setIsLoading(false);
            return;
        }
    
        try {
            const canvas = await html2canvas(element, { useCORS: false });
            const imgData = canvas.toDataURL('image/png');
            console.log("imgData =>", imgData)
            checkImageType(imgData)
            const pdf = new jsPDF();
            const imgWidth = pdf.internal.pageSize.getWidth();
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
            pdf.save('component.pdf');
        } catch (error) {
            console.error('Error generating PDF', error);
        }
    
        setIsLoading(false);
    };




     
    const showApplicationsByUserClicked = () => {
        setTopBarStatus(1)
        setFilters([])
        let filtersToUpdate = filters.filter(filter => filter.column !== 'reviewDetails.stage')
        filtersToUpdate.push({ column: "reviewDetails.stage", condition: "Equals", value: "Pending"})
        setFilters(filtersToUpdate)
        // setIsAllApplications(false)
        setUserId(user.userId)
        // refetchApplications()
        // refetchUserCount()
        console.log("total pages =>", Math.ceil(totalApplicationsForUser/ limit))
        setTotalPages(Math.ceil(totalApplicationsForUser/ limit))
    }

    const showAllApplicationsClicked = () => {
        setTopBarStatus(0)
        setFilters([])
        // setIsAllApplications(true)
        setUserId(null)
        refetchApplications()
        // refetchCount()
        console.log("total pages =>", Math.ceil(totalApplications/ limit))
        setTotalPages(Math.ceil(totalApplications/ limit))
    }

    const showSignaledApplications = () => {
        setTopBarStatus(2)
        setFilters([{column: "isSignal", condition: "Equals", value: "true"}])
        if (user.userPremissions.includes("PD") ||  user.userPremissions.includes("PC")) {
        setUserId(null)
        } else {
            setUserId(user.userId)
        }
        refetchApplications()
        // refetchSignaledCount()
        console.log("total pages =>", Math.ceil(totalSignaledApplications/ limit))
        setTotalPages(Math.ceil(totalSignaledApplications/ limit))
    }

    const showPhoneScreen = () => {
        setTopBarStatus(5)
        setFilters([{column: "masterApplication.status", condition: "Equals", value: "Phone Screen"}])

        refetchApplications()
        // refetchSignaledCount()
        // console.log("total pages =>", Math.ceil(totalSignaledApplications/ limit))
        setTotalPages(Math.ceil(totalSignaledApplications/ limit))
    }

    const showSelectedForInterview = () => {
        setTopBarStatus(6)
        setFilters([{column: "masterApplication.status", condition: "Equals", value: "Interview"}])

        refetchApplications()
        // refetchSignaledCount()
        // console.log("total pages =>", Math.ceil(totalSignaledApplications/ limit))
        setTotalPages(Math.ceil(totalSignaledApplications/ limit))
    }
    const showOffer = () => {
        setTopBarStatus(7)
        setFilters([{column: "masterApplication.status", condition: "Equals", value: "Offer"}])

        refetchApplications()
        // refetchSignaledCount()
        // console.log("total pages =>", Math.ceil(totalSignaledApplications/ limit))
        setTotalPages(Math.ceil(totalSignaledApplications/ limit))
    }

    const showAccepted = () => {
        setTopBarStatus(8)
        setFilters([{column: "masterApplication.status", condition: "Equals", value: "Accepted"}])

        refetchApplications()
        // refetchSignaledCount()
        // console.log("total pages =>", Math.ceil(totalSignaledApplications/ limit))
        setTotalPages(Math.ceil(totalSignaledApplications/ limit))
    }


    const showToReviewApplications = () => {
        setTopBarStatus(4)
        setFilters([{column: "masterApplication.status", condition: "Equals", value: "Review"}])

        refetchApplications()
        // refetchSignaledCount()
        // console.log("total pages =>", Math.ceil(totalSignaledApplications/ limit))
        setTotalPages(Math.ceil(totalSignaledApplications/ limit))
    }

    const showLeads = () => {
        setTopBarStatus(3)
        setFilters([{column: "masterApplication.status", condition: "Equals", value: "Lead"}])

        refetchApplications()
        // refetchSignaledCount()
        // console.log("total pages =>", Math.ceil(totalSignaledApplications/ limit))
        setTotalPages(Math.ceil(totalSignaledApplications/ limit))
    }

    const showNewApplications = () => {
        setTopBarStatus(1)
        // setFilters([{ column: "createdAt", condition: "After", value: user.userLogins.length > 1 ? user.userLogins[user.userLogins.length - 2] : user.lastLogin}])
        setFilters([{column: "masterApplication.status", condition: "Equals", value: "New"}])
        setUserId(user.userId)
        refetchApplications()
        // refetchNewCount()
        console.log("total pages =>", Math.ceil(totalNewApplications/ limit))
        setTotalPages(Math.ceil(totalSignaledApplications/ limit))
    }

    const showCompletedApplications = () => {
        setTopBarStatus(9)
        // setFilters([{ column: "reviewDetails.stage", condition: "Equals", value: "Completed"}])
        setFilters([{column: "masterApplication.status", condition: "Equals", value: "Rejected"}])
        // setUserId(user.userId)
        refetchApplications()
        // refetchCompletedCount()
        // console.log("total pages =>", Math.ceil(totalCompletedApplications/ limit))
        setTotalPages(Math.ceil(totalCompletedApplications/ limit))
    }

    const calcAppIdx = () => {
        if(selectedApplication) {
            const idx = (currentPage - 1) * limit + (applications.findIndex(elem => elem.id.toString() === selectedApplication.id.toString()))
            return idx
        }
    }
        

     


if(isLoading) return <Spinner />

    return (
        
<>
{!isApplicationViewOpen&&(
        <div className='faculty-home-container'>
            <GroupsDropdown isOpen={isModalOpen} groups={groups} selectedGroups={checkedGroups} setSelectedGroups={setCheckedGroups} onApply={handleApplyClick} selectedRows={selectedRows} finishMutation={() => refetch()} setIsOpen={setIsModalOpen} from={"applications"}/>
            {isEditViewModalOpen&&<EditViewModal isOpen={isEditViewModalOpen} onClose={()=>setIsEditViewModalOpen(!isEditViewModalOpen)} fields={mockedFieldsState}  onSave={handleEditViewSave}  screenedData={screenedData}/>}    
            {isImportAppsModalOpen&&<ImportAppsModal isOpen={isEditViewModalOpen} onClose={()=>setIsImportAppsModalOpen(!isImportAppsModalOpen)} selectedPosition={selectedPosition} programId={programId} refetchApplications={refetchApplications}/>}    
            {isAddApplicantModalOpen&&<AddApplicantModal isOpen={isEditViewModalOpen} onClose={()=>setIsAddApplicantModalOpen(!isAddApplicantModalOpen)}  selectedPosition={selectedPosition} programId={programId} refetchApplications={refetchApplications} statusRefetchCount={statusRefetchCount}/>}    
            {isFilterOpen&&<AdvancedFiltersModal onClose={() => setIsFilterOpen(prevState => !prevState)} parentFilters={filters} onFiltersApply={handleFiltersChange}  onFilterRemove={handleFilterRemove}/>}
            {isResponseModalOpen&&<ResponseModal isOpen={isResponseModalOpen} onClose={()=>setIsResponseModalOpen(!isResponseModalOpen)} body={responseBody} />}
            {isPromptModalOpen&&<PromptModal isOpen={isResponseModalOpen} onClose={()=>setIsPromptModalOpen(!isPromptModalOpen)} body={responseBody} onAccept={handleRejectApplications} />}
            
            <ellipse className='ellipseDesignTop1' />
            {/* <ellipse className='ellipseDesignTop2' /> */}
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="faculty-home-header" style={{display:'flex', gap:'3vw'}}>
                {selectedGroup&&<button style={{marginBottom:'2vh'}} className="white-button" onClick={onBack}><img src={backIcon}/> Back to Folders</button>}
                
            <h2 className="faculty-home-header-title">{selectedGroup ? selectedGroup.name : 'APPLICANTS'}</h2>
            {!selectedGroup&&(
                             <select
                             style={{ width: "100%" }}
                             className="border rounded p-2 w-full mb-4"
                             value={selectedPosition}
                             onChange={(e) => {
                                 const selectedId = e.target.value;
                                 console.log("selectedId =>", selectedId)
                                  setSelectedPosition(selectedId);
                             }}
                             >
                             {positions.map((position, index) => (
                               <option key={position.id} value={position.id}>
                                 {position.jobTitle}
                               </option>
                             ))}
                           </select>
            )}
                {selectedGroup&&<p className="faculty-home-header-description">{selectedGroup ? selectedGroup.description :''}</p>}
            </div>
            
            <div className="faculty-application-top-bar" style={{height:'5vh'}}>
            {/* <div className={(isSearchOpen||isFilterOpen)?"faculty-application-top-bar-search-open":"faculty-application-top-bar-search"}> */}
            <div className={(isSearchOpen)?"faculty-application-top-bar-search-open":"faculty-application-top-bar-search"} style={{width:'48vw'}}>
            
            {isSearchOpen&&(
                
            <div className="search-bar-container">
            <img  className={'search-bar-icon-open'} src={magGlass} alt="search" onClick={() => {
            setIsSearchOpen(!isSearchOpen);
        }}/>
            <input className="faculty-application-top-bar-search-input" placeholder="Search by name or email" value={searchInput} onChange={handleSearchChange} />
            </div>
            
            )}

            {/* {(!isSearchOpen&&!isFilterOpen)&&<img  className={'search-bar-icon'} style={{height:'6vh'}} src={magGlass} alt="search" onClick={() => setIsSearchOpen(!isSearchOpen)}/>}
            {(!isSearchOpen&&!isFilterOpen)&&<img  className={'filter-icon'} style={{width:'44px', padding:'1vh'}} src={filterIcon} alt="search" onClick={() => setIsFilterOpen(!isFilterOpen)}/>}
            {(!isSearchOpen&&!isFilterOpen)&&<div className={isAllApplications?"faculty-application-top-bar-link":"faculty-application-top-bar-link-inactive"} onClick={()=>setIsAllApplications(true)}>All Applications ({dataToRender.length})</div>}
            {(!isSearchOpen&&!isFilterOpen)&&<div className={!isAllApplications?"faculty-application-top-bar-link":"faculty-application-top-bar-link-inactive"} onClick={()=>setIsAllApplications(false)}>My Applications to Review ({dataToRender.length})</div>} */}
            

            
            
            </div>
            <div className="faculty-application-top-bar-buttons">
            {(!isSearchOpen)&&<img  className={'search-bar-icon'} style={{height:'6vh'}} src={magGlass} alt="search" onClick={() => setIsSearchOpen(!isSearchOpen)}/>}
            {(!isSearchOpen)&&<img  className={'filter-icon'} style={{width:'44px', padding:'1vh', background:filters.length>0?'#145388':''}} src={filters.length>0?activeFilterIcon:filterIcon} alt="search" onClick={() => setIsFilterOpen(!isFilterOpen)}/>}
            {/* <button className="green-button" onClick={() => setIsModalOpen(!isModalOpen)}>Folders <img src={dropdownArrowIcon}/> </button> */}
            <button style={{}} className="green-button" onClick={() => setIsEditViewModalOpen(!isEditViewModalOpen)}>Edit View</button>
            <button style={{}} className="green-button" onClick={() => setIsAddApplicantModalOpen(!isAddApplicantModalOpen)} selectedPosition={selectedPosition}>+ Add Applicant</button>
            <button style={{}} className="green-button" onClick={() => setIsImportAppsModalOpen(!isImportAppsModalOpen)}>+ Import Applicants</button>
            {(topBarStatus===1)&&(<button style={{flexGrow:'unset', flexBasis:'unset', padding:'8px 30px'}} className="white-button" disabled={selectedRows.length===0} onClick={() => handleStatusChange(selectedRows, 'Review')}>Move to Review</button>)}
            {topBarStatus===3&&(<button style={{flexGrow:'unset', flexBasis:'unset', padding:'8px 30px'}} className="white-button" disabled={selectedRows.length===0} onClick={() => handleInviteToApply(selectedRows)}>Invite to Apply</button>)}
            {(topBarStatus===4)&&(<button style={{flexGrow:'unset', flexBasis:'unset', padding:'8px 15px'}} className="white-button" disabled={selectedRows.length===0} onClick={() => handleStatusChange(selectedRows, 'Phone Screen')}>Move to Phone Screen</button>)}
            {(topBarStatus===5)&&(<button style={{flexGrow:'unset', flexBasis:'unset', padding:'8px 15px'}} className="white-button" disabled={selectedRows.length===0} onClick={() => handleStatusChange(selectedRows, 'Interview')}>Move to Interview</button>)}
            {(topBarStatus===6)&&(<button style={{flexGrow:'unset', flexBasis:'unset', padding:'8px 15px'}} className="white-button" disabled={selectedRows.length===0} onClick={() => handleStatusChange(selectedRows, 'Offer')}>Send an Offer</button>)}
            {(topBarStatus===7)&&(<button style={{flexGrow:'unset', flexBasis:'unset', padding:'8px 15px'}} className="white-button" disabled={selectedRows.length===0} onClick={() => handleStatusChange(selectedRows, 'Accepted')}>Mark as Accepted</button>)}
            {(topBarStatus!==9) && <button className="green-button" disabled={selectedRows.length===0} onClick={() => handleClickRejected(selectedRows)}  style={{background:'#d66262'}}>Reject</button>}
            <div className="dropdown" style={{border:'0',margin:'0',padding:'0'}}>
        <button className="green-button dropdown-toggle" style={{height:'100%'}} onClick={() => setIsDownloadDropdownOpen(prev => !prev)}>
            {/* Download <img src={dropdownArrowIcon}/> */}
            Download
        </button>
        {isDownloadDropdownOpen && (
            <div className="dropdown-menu" style={{zIndex:'3', right:'-.5vw'}} ref={downDropdownRef}>
                <div className="dropdown-item" style={{ cursor: "pointer" }} onClick={DownloadCSV}>Download CSV</div>
                {isFileDownloading && <Spinner />}
            {!isFileDownloading && 
                <div className="dropdown-item" style={{ cursor: "pointer" }} onClick={createPdfInBackEnd}>{capturing ? 'Downloading...' : 'Download PDF'}</div>}
            </div>
        )}
        </div>
            </div>
            </div>

            <seperator className="faculty-home-header-seperator"/>
            <div className="faculty-application-top-bar-buttons1">

            {(!isSearchOpen)&&<div className={ topBarStatus === 0 ?"faculty-application-top-bar-link":"faculty-application-top-bar-link-inactive"} style={{width:'9vw'}} onClick={()=> showAllApplicationsClicked()}>All ({countByStatus.allApplications})</div>}
            {(!isSearchOpen)&&<div className={ topBarStatus === 1 ?"faculty-application-top-bar-link":"faculty-application-top-bar-link-inactive"} style={{width:'10vw'}} onClick={() => {showNewApplications(); setPreventFilters(false);}}>New ({countByStatus.newApplications})</div>}
            {(!isSearchOpen)&&<div className={ topBarStatus === 3 ?"faculty-application-top-bar-link":"faculty-application-top-bar-link-inactive"} style={{width:'10vw'}} onClick={() => {showLeads(); setPreventFilters(true)}}>Leads ({countByStatus.leadApplications})</div>}
            {(!isSearchOpen)&&<div className={ topBarStatus === 4 ?"faculty-application-top-bar-link":"faculty-application-top-bar-link-inactive"} style={{width:'9vw'}} onClick={() => {showToReviewApplications(); setPreventFilters(false);}}>Review ({countByStatus.reviewApplications})</div>}
            {(!isSearchOpen)&&<div className={ topBarStatus === 5 ?"faculty-application-top-bar-link":"faculty-application-top-bar-link-inactive"} style={{width:'9vw'}} onClick={() => {showPhoneScreen(); setPreventFilters(false);}}>Phone Screen ({countByStatus.phoneApplications})</div>}
            {(!isSearchOpen)&&<div className={ topBarStatus === 6 ?"faculty-application-top-bar-link":"faculty-application-top-bar-link-inactive"} style={{width:'9vw'}} onClick={() => {showSelectedForInterview(); setPreventFilters(false);}}>Interview ({countByStatus.interviewApplications})</div>}
            {(!isSearchOpen)&&<div className={ topBarStatus === 7 ?"faculty-application-top-bar-link":"faculty-application-top-bar-link-inactive"} style={{width:'9vw'}} onClick={() => {showOffer(); setPreventFilters(false);}}>Offer ({countByStatus.offerApplications})</div>}
            {(!isSearchOpen)&&<div className={ topBarStatus === 8 ?"faculty-application-top-bar-link":"faculty-application-top-bar-link-inactive"} style={{width:'10vw'}} onClick={() => {showAccepted(); setPreventFilters(true)}}>Accepted/Hired ({countByStatus.acceptedApplications})</div>}
            {(!isSearchOpen)&&<div className={ topBarStatus === 9 ?"faculty-application-top-bar-link":"faculty-application-top-bar-link-inactive"} style={{width:'10vw'}} onClick={() => {showCompletedApplications(); setPreventFilters(false);}}>Rejected ({countByStatus.rejectedApplications})</div>}

            </div>
            <div className="faculty-home-body" style={{maxHeight:'70vh', gap:'0'}}>
                {applicationsLoading? <Spinner />:
                    <ApplicationsTable columns={columns} data={dataToRender} onSelectionChange={updateSelectedRows} onSelectedApplication={handleSelectedApplication} sortData={sortApplication} />
            
                }
                <div className="faculty-application-table-footer" style={{ height: '4vh'}}>
                <PageSizeSelector itemsPerPage={limit} onPageSizeChange={handlePageSizeChange} />
                <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
                </div>

            </div>
           
        </div>
)}
            {isApplicationViewOpen&&(
                 <ApplicationView handleStatusChange={handleStatusChange} handleInviteToApply={handleInviteToApply} refetchGroups={refetchGroupsApps} applicationIdx={calcAppIdx()} groups={groups} key={selectedApplication.id} applications={applications} refetchApplications={refetchApplications} totalApplications={totalApplications} application={selectedApplication} onClose={() => setIsApplicationViewOpen(false)} nextApplicationClicked={nextApplicationClicked} previousApplicationClicked={previousApplicationClicked} screenedData={screenedData}/>
            )}

        </>

    );
}

export default Applications;

