import { useState } from 'react';
import logo from '../../../../assets/imgs/logo.svg';
import Spinner from '../../../common/spinner';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { resetPasswordRequest } from './graphql/mutations';
import { useMutation } from "@apollo/client";
import packageInfo from '../../../../../package.json';

const ResetPasswordModal = ({ isOpen, onClose, user, refetchUsers}) => {
    const [loadingLink, setLoadingLink] = useState(false);
    const [link, setLink] = useState(null);
    const [copied, setCopied] = useState(false);
    const [sent, setSent] = useState(false);
    const [resetPasswordReqMutation] = useMutation(resetPasswordRequest);



    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
    };

    const generateLink = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_reset_password_link`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'App-Version': packageInfo.version
                },
                body: JSON.stringify({
                   id: user.id,
                }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log(data); // Handle success response
            const linkFromToken = data.resetLink
            setLink(linkFromToken)
        }catch(err) {

        }
    }

    const sendResetLink = () => {
        setSent(false)
        resetPasswordReqMutation({
            variables: {
              email: user.email
            }
          }).then(res => {
            setSent(true);
          }
          ).catch(error => {
          })
    }



    if(!isOpen) return null;
    return (
        <div className="modal-backdrop">
            <div className="add-field-modal">
                <button className='close-icon' onClick={onClose}>X</button>
                <div className='add-field-modal-header' style={{ marginTop: '3vh'}}>
                    <h2>Reset Password Link</h2>
                    <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
                </div>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '80%'}}>
                        <button style={{ marginTop:'3vh', marginBottom: '4vh' }} className='green-button' onClick={generateLink}>Generate reset password link</button>
                        <button style={{ marginTop:'3vh', marginBottom: '4vh' }} className='green-button' onClick={sendResetLink}>Send reset link to user</button>
                    </div>
                    { loadingLink && <Spinner />}
                    {
                        link &&
                        <div>
                            <label>{link}</label>
                            <CopyToClipboard text={link} onCopy={handleCopy}>
                                <button className="green-button" style={{ cursor: 'pointer', marginTop: '3vh' }}>Copy to clipboard</button>
                               
                            </CopyToClipboard>
                            {copied && <span style={{ color: '#145388' }}>Link copied!</span>}
                        </div>
                    }
                    { sent && <span style={{ color: '#145388' }}>Link sent to user</span>}

                </div>
            </div>
        </div>
    )
}

export default ResetPasswordModal;