import React, { useState,useEffect, useContext } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../styles/calendar.css'; 
import {getProgramPopulatedDatesQuery, getSpecificProgramPopulatedDateQuery} from "../graphql/queries";
import {scheduleApplicantInterviewMutation} from "../graphql/mutations";
import {useMutation, useQuery} from '@apollo/client';
import Spinner from '../../../../common/spinner';
import moment from 'moment';
import {
  POPULATED_DATES_CHANGE_SUBSCRIPTION,
}from "../graphql/subs";
import useConditionalSubscription from "./useConditionalSubscription";

const ManuallyScheduleModal = ({programId, setIsManuallyScheduleModalOpen, selectedApplicants, updateUsers}) => {
  const [populatedDates, setPopulatedDates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);


  const [scheduleApplicantInterview] = useMutation(scheduleApplicantInterviewMutation);
  const { data: datesToSendData, error: datesToSendError } = useConditionalSubscription(POPULATED_DATES_CHANGE_SUBSCRIPTION, { programIds:[programId] });


  const { data: scheduleOverview, loading: scheduleOverviewLoading, error: scheduleOverviewError } = useQuery(getProgramPopulatedDatesQuery, {
    variables: { programId: programId },
  });

useEffect(() => {
  if (datesToSendData) {
  //  go over the datesToSendData.dates array, and for each object, make the date a date object

  const populatedDatesToUpdate = datesToSendData.populatedDatesChange.dates.map(({ date, status }) => ({
    date: new Date(date),
    status: parseInt(status)
  }));
// go over the populatedDates array and update the status of the date if it exists in the populatedDatesToUpdate array
  const updatedPopulatedDates = populatedDates.map(populatedDate => {
    const foundDate = populatedDatesToUpdate.find(dateToUpdate => dateToUpdate.date.getTime() === populatedDate.date.getTime());
    if (foundDate) {
      return { ...populatedDate, status: foundDate.status };
    }
    return populatedDate;
  });

  console.log('updatedPopulatedDates', updatedPopulatedDates);
setPopulatedDates(updatedPopulatedDates);
  }
}, [datesToSendData]);

useEffect(() => {
  if (scheduleOverview && scheduleOverview.getProgramPopulatedDates) {
    const populatedDatesToUpdate = scheduleOverview?.getProgramPopulatedDates?.map(({ date, status }) => ({
      date: new Date(date),
      status
    })) || [];
    

    setPopulatedDates(populatedDatesToUpdate);
  }
}, [scheduleOverview]);

const handleClose = () => {
  setIsManuallyScheduleModalOpen(false);
};


  //   // check if this date is in the past
  //   const today = new Date();
  //   if (date.getTime() < today.getTime()) {
  //     return;
  //   }
  //   // Assuming date is a Date object and not an array, even when selectRange is false
  //   const dateIndex = selectedDates.findIndex(
  //     (selectedDate) => selectedDate.getTime() === date.getTime()
  //   );
  
  //   if (dateIndex > -1) {
  //     // Date is already selected, remove it
  //     setSelectedDates(selectedDates.filter((_, index) => index !== dateIndex));
  //   } else {
  //     // format the dates array to be in ascending order and then set it
  //     const sortedDates = [...selectedDates, date].sort((a, b) => a - b);
  //     // Date is not selected, add it
  //     setSelectedDates(sortedDates);
  //   }
  // };

const handleDateClick = async (date) => {
  setIsLoading(true);
  const applicationIds = selectedApplicants.map(user => user.id);
  const formattedDate = moment(date).format('MM/DD/YYYY');
  const { data, error } = await scheduleApplicantInterview({
    variables: {
      programId,
      applicationIds,
      scheduleDate: date,
    },
    update: (cache, { data }) => {
      if (data && data.scheduleApplicantInterview) {
        updateUsers(data.scheduleApplicantInterview);
    }
    }
  });

}


  return (
    <div className='modal-backdrop'>
  {scheduleOverviewLoading && (
    <Spinner style={{alignSelf:'center'}}/>
  )}
{scheduleOverview && !scheduleOverviewError && !scheduleOverviewLoading&& (
  <div className="calendar-container" style={{background:'white', width:'50vw'}}>
    <p style={{background:'#145388', color:'white', padding:'1vw', textAlign:'center', fontSize:'2vh', fontWeight:'500'}}> Click on an available day to schedule your applicants</p>
  <Calendar
  onChange={(date) => handleDateClick(date)}
  value={null} 
  selectRange={false}
  minDetail="month"
  prev2Label={null}
  next2Label={null}
  tileClassName={({ date, view }) => {
    // Logic to gray out dates not in the current month
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    if (date.getTime() < today.getTime()) {
      return 'past-date'; 
    }
  
    // // Existing logic to highlight selected dates
    // if (selectedDates.some(selectedDate => selectedDate.getTime() === date.getTime())) {
    //   return 'highlight'; // This class will highlight selected dates
    // }
    const foundDate = populatedDates.find(populatedDate => populatedDate.date.getTime() === date.getTime());
  if (foundDate) {
    // Apply different classes based on the status
    switch (foundDate.status) {
      case 0:
        return 'date-full';
      case 1:
        return 'date-available';
      case 2:
        return 'date-locked';
      default:
        return '';
    }
  }
  }}
/>
<button className='green-button' style={{width:'50%', margin:'1vh 0 1vh 0 ', alignSelf:'center'}} onClick={handleClose} >Cancel</button>
  </div>
)}

</div>
  );
};

export default ManuallyScheduleModal;
