import React from 'react';
import logo from '../../assets/imgs/logo.svg';
import './styles/spinner.css';

export default function Spinner() {
    return (
        <div className="spinner">
            <div className="spinner-container">
                <img src={logo} className='spinner-img' alt="Loading..." />
                <div className="spinnerBorder" />
            </div>
        </div>
    );
}
