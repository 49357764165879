import React,{useState, useEffect, useRef} from "react";
import "../styles/applicantInterviewManager.css";
import StatusIndicator from "../../../faculty/facultyComponents/interviewManager/inviteComponents/statusIndicator";
import greenArrowDown from "../../../../assets/imgs/greenArrowDown.svg";
import DropdownArrowIcon from "../../../../assets/imgs/DropdownArrowIcon.svg";
import checkIconWithCircleTeal from "../../../../assets/imgs/checkIconWithCircleTeal.svg";
import moment from "moment";
import Spinner from "../../../common/spinner";



const InterviewsList = ({programs, handleDeclineInterview, handleScheduleInterview, handleCancelInterview, isLoading}) => {
    const [dropdownVisible, setDropdownVisible] = useState({});
    const dropdownRefs = useRef([]);

console.log("programs", programs);    

    const toggleDropdown = (index, event) => {
        event.stopPropagation(); // Prevent click from immediately triggering the document listener
        setDropdownVisible(prevState => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };



    useEffect(() => {
        const handleClickOutside = (event) => {
            Object.entries(dropdownRefs.current).forEach(([key, ref]) => {
                if (ref && !ref.contains(event.target)) {
                    setDropdownVisible(prevState => ({
                        ...prevState,
                        [key]: false,
                    }));
                }
            });
        };

        // Add event listener
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []); 

if(isLoading){
    return <Spinner />
}

return(
    <div>
        {programs.map((program, index) => (
            <div key={index} className="applicant-im-list-item">
                <div className="applicant-im-list-item-header">
                    <p className="applicant-im-list-item-header-title">{program.programName}, {program.specialty}</p>
                    <separator className="applicant-im-list-separator"  />
                    <div className="applicant-im-list-item-body">
                        <div className="applicant-im-list-item-body-status">
                    <StatusIndicator status={program.status} interviewDate={program.interviewDate} />
                        </div>
                    </div>
                    {program.status === 'Scheduled' && (
                        <div className="applicant-im-list-item-footer">
                            <button className="applicant-im-list-item-footer-left-button" onClick={(event) => toggleDropdown(index, event)}>
                                Reschedule
                            <img src={greenArrowDown} alt="dropdown arrow"  style={{padding:'1vh'}}/>
                            </button>
                            <button className="applicant-im-list-item-footer-right-button" onClick={()=>handleCancelInterview(program.id,program.programId)} >Cancel</button>
                            {dropdownVisible[index] && (
                                <div className="applicant-im-dates-dropdown" ref={el => dropdownRefs.current[index] = el}>
                                     <p className="applicant-im-list-header" style={{fontSize:'15px'}}>
                                     Available Dates
                                    </p>
                                    <p style={{color: '#7B7B7B', fontStyle:'italic', textAlign:'center', fontWeight:'400', fontSize:'11px', cursor:'default'}}>
                                    Click on a date to schedule your interview
                                    </p>
                                    <seperator style={{marginTop:'1vh', marginBottom:'0'}} className="faculty-home-header-seperator"  />
                                    {program.datesToSend.map((date, index) => (
                                           (date.currentAvailableSlots!==0&&date.date !== program.interviewDate) &&(
                                        <>
                                        <div key={index} className="applicant-im-dates-dropdown-item" onClick={()=>handleScheduleInterview(program.id, date.date, program.programId)}>
                                            <p style={{marginLeft:'.5vw'}}>{moment(date.date).format('MMMM D, YYYY')}</p>
                                            {/* <img style={{marginRight:'.5vw'}} src={checkIconWithCircleTeal} alt="check icon" /> */}
                                            <div style={{marginRight:'.5vw'}}>
                                            <p style={{fontSize:'10px'}}>{date.currentAvailableSlots} slots left</p>
                                            <p style={{fontSize:'10px'}}>{date.startTime}</p>
                                            </div>
                                        </div>
                                        <seperator style={{margin:'0'}} className="faculty-home-header-seperator"  />
                                        </>
                                           )
                                    ))
                                    }
                                  
                                </div>
                            )}
                        </div>
                    )}

                    {(program.status === 'Response Pending' || program.status === 'Canceled') && (
                        <div className="applicant-im-list-item-footer">
                            <button className="applicant-im-list-item-footer-left-button" onClick={(event) => toggleDropdown(index, event)}>
                            Schedule
                            <img src={greenArrowDown} alt="dropdown arrow"  style={{padding:'1vh'}}/>
                            </button>
                            <button className="applicant-im-list-item-footer-right-button" onClick={()=>handleDeclineInterview(program.id)}>Decline</button>
                            {dropdownVisible[index] && (
                                <div className="applicant-im-dates-dropdown" ref={el => dropdownRefs.current[index] = el}>
                                     <p className="applicant-im-list-header" style={{fontSize:'15px'}}>
                                     Available Dates
                                    </p>
                                    <p style={{color: '#7B7B7B', fontStyle:'italic', textAlign:'center', fontWeight:'400', fontSize:'11px', cursor:'default'}}>
                                    Click on a date to schedule your interview
                                    </p>
                                    <seperator style={{marginTop:'1vh', marginBottom:'0'}} className="faculty-home-header-seperator"  />
                                    {program.datesToSend.map((date, index) => (
                                        date.currentAvailableSlots!==0 &&(
                                            <>
                                        <div key={index} className="applicant-im-dates-dropdown-item" onClick={()=>handleScheduleInterview(program.id, date.date, program.programId)}>
                                            <p style={{marginLeft:'.5vw'}}>{moment(date.date).format('MMMM D, YYYY')}</p>
                                            {/* <img style={{marginRight:'.5vw'}} src={checkIconWithCircleTeal} alt="check icon" /> */}
                                            <div style={{marginRight:'.5vw'}}>
                                            <p style={{fontSize:'10px'}}>{date.currentAvailableSlots} slots left</p>
                                            <p style={{fontSize:'10px'}}>{date.startTime}</p>
                                            </div>
                                        </div>
                                        <seperator style={{margin:'0'}} className="faculty-home-header-seperator"  />
                                        </>
                                        )
                                        ))
                                    }
                                  
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        ))}
    </div>
)

}

export default InterviewsList;