import React,{useState} from 'react';
import { useTable } from 'react-table';
import './styles/interviewScheduleTable.css';



const InterviewScheduleTable = ({data, columns, setCalColumns,setCalData, setInterviewers}) => {
    const [hoveredColumn, setHoveredColumn] = useState(null);

const handleRemoveInterviewer = (columnIndex) => {
    const r = columns[columnIndex].Header;
    if (/^R\d+$/.test(r)) {
        return;
    }
    let newColumns = [...columns];

    let roomNumber = 1;
    // go over the columns and see how many columns are there without an id field
    const columnsWithoutId = newColumns.filter(column => column.id === '');
    console.log(columnsWithoutId,'columnsWithoutId');
    if(columnsWithoutId.length > 0 ){
    // go over the columnsWihtoutId array, and flat it just to the Header field
    let columnsWithoutIdHeader = columnsWithoutId.map(column => column.Header);
    const numbers = columnsWithoutIdHeader.map(header => parseInt(header.slice(1))).sort((a, b) => a - b);
    console.log(numbers,'numbers');

        while (numbers.includes(roomNumber)) {
            roomNumber++;
        }
    }


    newColumns[columnIndex].Header=`R${roomNumber}`;
    newColumns[columnIndex].accessor=`R${roomNumber}`;
    newColumns[columnIndex].id='';
    console.log(`R${roomNumber}`)

    setCalColumns(newColumns);
}


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    return (
        <table {...getTableProps()} style={{ width: '100%', borderCollapse: 'collapse', border: 'none' }}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, columnIndex) => (
                            <th
                            className={ (/^R\d+$/.test(column.Header)? '':column.Header===''? '': 'room-interviewer-name')}
                                {...column.getHeaderProps()}
                                style={{
                                    borderBottom: '1px solid #E1E1E1',
                                    borderRight: columnIndex === headerGroup.headers.length - 1 ? 'none' : '1px solid #E1E1E1',
                                    background: 'white',
                                    color: 'black',
                                    fontWeight: '200',
                                    width: `${100 / columns.length}%`,
                                    textAlign: 'center',
                                    borderLeft: columnIndex === 0 ? 'none' : '1px solid #E1E1E1',
                                    padding:'0 1vw',
                                    position: 'relative',
                                }}
                                onMouseEnter={() => setHoveredColumn(columnIndex)}
                                onMouseLeave={() => setHoveredColumn(null)}
                                onClick={() => handleRemoveInterviewer(columnIndex)}
                            >
                                {column.render('Header')}
                                {hoveredColumn === columnIndex && !(/^R\d+$/.test(column.Header)) && column.Header!=='' &&(
                                <div style={{
                                    position: 'absolute',
                                    width: '100%', // Adjust as needed
                                    top: '100%', // Position right below the th
                                    left: 0,
                                    backgroundColor: 'red', // Example background color
                                    color: 'white', // Example text color
                                    textAlign: 'center',
                                    paddingTop: '1vh',
                                    fontWeight: '500',
                                    // Additional styling as needed
                                }}>
                                    Click to remove
                                </div>
                            )}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, rowIndex) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell, cellIndex) => {
                                // Always render the first cell normally
                                if (cellIndex === 0) {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            style={{
                                                padding: '10px',
                                                border: '1px solid #E1E1E1',
                                                textAlign: 'center',
                                                borderLeft: 'none', // Remove left border for the first cell
                                                borderBottom: rowIndex === rows.length - 1 ? 'none' : '1px solid #E1E1E1', // Remove bottom border for the last row
                                            }}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                } else if (cellIndex === 1 && row.original.isFullWidth) {
                                    // For the second cell, check if it should span the remaining columns
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            colSpan={columns.length - 1} // Span across all remaining columns
                                            style={{
                                                padding: '10px',
                                                border: '1px solid #E1E1E1',
                                                textAlign: 'center',
                                                backgroundColor: row.original.cellColor,
                                                ...row.original.fontStyle,
                                                borderRight: 'none', // Remove right border for full-width cells
                                                borderBottom: rowIndex === rows.length - 1 ? 'none' : '1px solid #E1E1E1', // Remove bottom border for the last row
                                            }}
                                        >
                                            {row.original.fullWidthContent}
                                        </td>
                                    );
                                } else if (!row.original.isFullWidth) {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            style={{
                                                padding: '10px',
                                                border: '1px solid #E1E1E1',
                                                textAlign: 'center',
                                                borderRight: cellIndex === row.cells.length - 1 ? 'none' : '1px solid #E1E1E1', // Remove right border for the last cell
                                                borderBottom: rowIndex === rows.length - 1 ? 'none' : '1px solid #E1E1E1', // Remove bottom border for the last row
                                            }}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                }
                                return null; // For cells beyond the first in a full-width row, don't render anything
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default InterviewScheduleTable;