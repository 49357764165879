import React,{useState,useContext, useEffect} from "react";
import "./index.css";
// import Card from "../../../../components/common/card"
import { updateUserMutation, updateUserPasswordMutation, revokeUserMutation, addUserToAccountAccessMutation } from './graphql/mutations';
import { getUserById, getUserWithAccountUsersById, getUsersQuery } from './graphql/queries';
import {useQuery} from '@apollo/client';
import client from '../../../../config/apolloClient';
import { UserContext } from '../../../../context/userContext';
import AccountsTable from './accountsTable';
import { useNavigate } from 'react-router-dom';
import Spinner from "../../../common/spinner";
import Select from 'react-select';
import { ProgramIdContext } from '../../progIdContext';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import packageInfo from '../../../../../package.json';
import { Card, CardBody, CardImg, Collapse } from "reactstrap";
import toggleArrowUpIcon from "../../../../assets/imgs/toggleArrowUpIcon.svg";
import toggleArrowIcon from "../../../../assets/imgs/toggleArrowIcon.svg";


const Settings = ({ setPage }) => {
    const { user, setUser, setTopUser } = useContext(UserContext);
    const navigate = useNavigate();

    const [userData, setUserData] = useState({})
    const [isEditable, setIsEditable] = useState(false)
    const [isChangePassword, setIsChangePassword] = useState(false)
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [institution, setInstitution] = useState("")
    const [currentPass, setCurrentPass] = useState("")
    const [newPass, setNewPass] = useState("")
    const [confirmPass, setConfirmPass] = useState("")
    const [accountUsers, setAccountUsers] = useState([])
    const [optionalUsers, setOptionalUsers] = useState([])
    const [options, setOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState({})
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [editSuccess, setEditSuccess] = useState(false);
    const [accordion, setAccordion] = useState([false, false])


    const programId = useContext(ProgramIdContext);

    const { data, loading, error, refetch } = useQuery(getUserWithAccountUsersById, {
        variables: { userId: user.userId },
    });

    const { data: usersData, loading: usersLoading, error: usersError, refetch: usersRefetch } = useQuery(getUsersQuery, {
        variables: { 
            programId
        },
    });
    

    useEffect(() => {
        if (!loading && !error && data) {
            console.log("user in settings =>", data.getUserWithAccountUsersById)
            const user = data.getUserWithAccountUsersById
            if(JSON.stringify(user) !== JSON.stringify(userData)) {
                setUserData(user);
                console.log("user =>", user)
                setAccountUsers(user.accountAccessIds)
            }
        }
    }, [data]);


    useEffect(() => {
        console.log("accountUsers =>", accountUsers)
        if(accountUsers !== null && accountUsers !== undefined) {
            let cpy = [...optionalUsers]
            cpy = cpy.filter(elem => {
                const result = accountUsers.filter(accountUser => accountUser.id.toString() === elem.id.toString())
                if(result.length === 0) return elem
            })
            console.log("cpy =>", cpy)
            setOptionalUsers(cpy)
            let newOptions = []
            cpy.map(user => {
                newOptions.push({ value: user.id, label: `${user.firstName} ${user.lastName},${user.institution} (${user.email})`})
            })
            setOptions(newOptions)
        }
        
    }, [accountUsers])

    useEffect(() => {
        if(!usersLoading && !usersError && usersData) {
            console.log("all users => ", usersData.getProgramMembersByProgramId)
            var users = usersData.getProgramMembersByProgramId
            var modifiedUsers = []
            users.forEach(user => modifiedUsers.push({
                id: user.userId.id,
                firstName: user.userId.firstName,
                lastName: user.userId.lastName,
                email: user.userId.email,
                institution: user.userId.institution,
            }))
            if(JSON.stringify(optionalUsers) !== JSON.stringify(modifiedUsers)) {
                setOptionalUsers(modifiedUsers)
                let newOptions = []
                modifiedUsers.map(user => {
                    newOptions.push({ value: user.id, label: `${user.firstName} ${user.lastName} - (${user.email})`})
                })
                setOptions(newOptions)
            }
        }
    }, [usersData])

    const updateTopUser = (userToUpdate) => {
        const existingUser = user
        setTopUser(existingUser)
        setUser(userToUpdate)
        setPage("home")
    }

    const revokeUser = (revokeUser) => {
        console.log("loggedUser =>", user.userId)
        console.log("user to revoke =>", revokeUser.id)
        client.mutate({
            mutation: revokeUserMutation,
            variables: {
                userId: user.userId,
                revokedUserId: revokeUser.id
            }
        })
        .then(res => {
            refetch()
        })
    }

    const toggleAccordion = tab => {
        console.log("toggleAccordion clicked =>", tab)
        const prevState = [...accordion];
        console.log("prevState =>", prevState)
        const state = prevState.map((x, index) => (tab === index ? !x : false));
        console.log("newState =>", state)

        setAccordion(state)
      };


    const columns = [
        {
            Header: 'Name',
            id: 'name',
            accessor: 'name',
            Cell: ({ row }) => {
                console.log("row =>", row)
                return (
                <label>{row.original.firstName} {row.original.lastName}</label>
            )}
        },
        {
            Header: 'Email',
            id: 'email',
            accessor: 'email',
            Cell: ({ row }) => (
                <label>{row.original.email}</label>
            )
        },
        {
            Header: 'Institution',
            id: 'institution',
            accessor: 'institution',
            Cell: ({ row }) => (
                <label>{row.original.institution}</label>
            )
        },
        {
            Header: 'Revoke access',
            id: 'btn',
            accessor: 'btn',
            Cell: ({ row }) => (
                <button className="white-button" onClick={() => revokeUser(row.original)}>Revoke</button>
            )
        }
    ]


    const updateFormData = () => {
        if(userData) {
            setFirstName(userData.firstName)
            setLastName(userData.lastName)
            setEmail(userData.email)
            setPhone(userData.phone)
            setInstitution(userData.institution)

        }
    }

    const updateData = () => {
        client.mutate({
            mutation: updateUserMutation,
            variables: {
                id: user.userId,
                input: {
                    firstName,
                    lastName,
                    email,
                    phone,
                    institution,
                    userType: user.userType
                }
            }
        })
        .then(res => {
            console.log("user updated!", res)
            setEditSuccess(true)
            setTimeout(() => {
                setEditSuccess(false)
            }, 3000);
        })
    }

    React.useEffect(() => {
        updateFormData()
    }, [userData])


    const handleImageChange = async (event) => {
        const file = event.target.files[0];
        console.log("file =>", file)
        const previewImg = document.getElementById('previewImage');
        if(file) {
            const reader = new FileReader();
        
            reader.onload = function(e) {
              previewImg.src = e.target.result; // Set the src attribute of the img tag to the data URL of the selected file
            };
            const fileInput = document.getElementById('imageInput');

            reader.readAsDataURL(fileInput.files[0]);

            const formData = new FormData();
            formData.append('image', file);
            formData.append('loginToken', localStorage.getItem('login_token'));
    
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/upload_profile`, {
                method: 'POST',
                headers: {'App-Version': packageInfo.version},
                body: formData
              });
            const data = await response.json();
            console.log('pdf uploaded successfully:');
        }

      
      
    }

    const inviteUser = () => {
        const inviteUserId = selectedOption.value

        console.log("user to invite =>", selectedOption)
        client.mutate({
            mutation: addUserToAccountAccessMutation,
            variables: {
                userId: user.userId,
                inviteUserId
            }
        })
        .then(res => {
            refetch()
        })

    }


    const updateUserPassword = async () => {
        if(newPass !== confirmPass) {
            // alert password different
            console.log("different pass!")
        } else {
            client.mutate({
                mutation: updateUserPasswordMutation,
                variables: {
                    id: user.userId,
                    previousPass: currentPass,
                    newPass
                }
            })
            .then(res => {
                setPasswordChanged(true)
                setTimeout(() => {
                    setPasswordChanged(false)
                }, 5000);
            })
        }
    }

    console.log("user in settings =>", userData)


    useEffect(() => {

        console.log("accordion updated =>", accordion)
    }, [accordion])



  
    return (
        <div className='applicant-home-container'>
            <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop2' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="applicant-home-header">
                <h2 className="applicant-home-header-title">SETTINGS</h2>
                <seperator className="applicant-home-header-seperator"/>
            </div>
                {loading && <Spinner />}
                {error && <p>Error: {error.message}</p>}
                
                
                {/* profile image */}
                <div className="position-relative">
                <Card className="mb-4 settings-main-card" style={{height:'31vh', border: 'none', display: 'flex', flexDirection: 'row-reverse'}}>
                    <div className="position-relative">
                        <CardImg top id="previewImage" src={userData.profilePhotoUrl ? userData.profilePhotoUrl : "https://via.placeholder.com/282"} alt="Card image cap" className="settings-main-card-image" style={{ height: '100%', width: '16vw', objectFit: 'cover'}}/>
                        <input type="file" id="imageInput" accept="image/*" onChange={handleImageChange} style={{marginBottom:'4vh'}}/>

                    </div>
                        <CardBody style={{ width: '14vw'}}>
                        <div className="overview-title">Overview</div>
                            <label className="text-small font-weight-light card-over-normal">{userData? userData.firstName : ''}</label>
                            <label className="text-small font-weight-light card-over-normal">{userData ? userData.lastName : ''}</label>
                        </CardBody>
                    </Card>
              </div>


              <div xxs="12" md="12">
                <Card className="toggle-card">
                <div className="card-toggle-title">
                    <label>Personal Information</label>
                    <img 
                    onClick={() => toggleAccordion(0)}
                    src={accordion[0] ? toggleArrowUpIcon : toggleArrowIcon}
                    alt="toggle-icon" className="toggle-icon" />
                </div>
                
                
                    <Collapse isOpen={accordion[0]}>
                        <div className="p-4">
                        <div className='settings-container'>
                        { editSuccess && <div style={{ display: 'flex', justifyContent: 'flex-end', color: "#145388"}}><label>Personal Information Changed Successfully.</label></div> }
                        <row className='settings-row'>
                            <column className='settings-column'>
                                {console.log("firstName =>", firstName)}
                                    <label className='settings-label' htmlFor="firstName">First Name</label>
                                    <input className="settings-input"  value={firstName} type="firstName" id="firstName" disabled={!isEditable} onChange={(e) => setFirstName(e.target.value)} />
                                </column>

                                <column className='settings-column'>
                                    <label className='settings-label' htmlFor="lastName">Last Name</label>
                                    <input className="settings-input" value={lastName} type="lastName" id="lastName" disabled={!isEditable} onChange={(e) => setLastName(e.target.value)}/>
                                </column>

                                <column className='settings-column'>
                                    <label className='settings-label' htmlFor="email">Email Address</label>
                                    <input className="settings-input" value={email} type="email" id="email" disabled={!isEditable} onChange={(e) => setEmail(e.target.value)}/>
                                </column>
                        </row>

                        <row className='settings-row'>
                        <column className='settings-column'>
                                    <label className='settings-label' htmlFor="phoneNumber">Phone Number</label>
                                    <PhoneInput
                                        defaultCountry="US"
                                        placeholder="Enter your Cellphone number"
                                        value={phone}
                                        onChange={setPhone}
                                        className="signin-input"
                                        style={{marginBottom:0}}
                                        disabled={!isEditable}
                                        />

                                </column>

                                <column className='settings-column'>
                                    <label className='settings-label' htmlFor="institution">Institution</label>
                                    <input className="settings-input" value={institution} type="institution" id="institution"  disabled={!isEditable} onChange={(e) => setInstitution(e.target.value)}/>
                                </column>
                        </row>
                    </div>
                    <row className='settings-row1'>
                    {!isEditable &&(<button className="edit-button" onClick={()=>setIsEditable(!isEditable)}>Edit</button>)}
                    {isEditable &&(<button className="edit-button" 
                    disabled={firstName === "" || lastName === "" || email === "" || phone === "" || institution === ""}
                    onClick={()=>{
                        setIsEditable(!isEditable)
                        updateData()
                    }}>Save</button>)}
                    {isEditable &&(<button className="edit-button" onClick={()=>setIsEditable(!isEditable)} style={{color:'#145388', border:' solid #145388', backgroundColor:'white', position:'relative', }}>Cancel</button>)}
                    </row>
                        </div>
                    </Collapse>
                </Card>
            </div>
                
            <div xxs="12" md="12">
                <Card className="toggle-card">
                <div className="card-toggle-title">
                    <label>Password</label>
                    <img 
                    onClick={() => toggleAccordion(1)}
                    src={accordion[0] ? toggleArrowUpIcon : toggleArrowIcon}
                    alt="toggle-icon" className="toggle-icon" />
                </div>
                
                
                    <Collapse isOpen={accordion[1]}>
                        <div className="p-4">
                { passwordChanged && <div style={{ display: 'flex', justifyContent: 'flex-end', color: "#145388"}}><label>Password Changed Successfully.</label></div> }
                <row className='settings-row'>
                    <column className='settings-column'>
                                <label className='settings-label' htmlFor="phoneNumber">Current Password</label>
                                <input className="settings-input" type="password" id="password"  value={currentPass} onChange={(e) => setCurrentPass(e.target.value)}/>
                            </column>

                            <column className='settings-column'>
                                <label className='settings-label' htmlFor="institution">New Password</label>
                                <input className="settings-input" type="password" id="password"  value={newPass} onChange={(e) => setNewPass(e.target.value)}/>
                            </column>
                            <column className='settings-column'>
                                <label className='settings-label' htmlFor="institution">Confirm Password</label>
                                <input className="settings-input" type="password" id="password" value={confirmPass} onChange={(e) => setConfirmPass(e.target.value)}/>
                            </column>
                </row>
                <row className='settings-row1'>
                <button className="edit-button" onClick={updateUserPassword}>Update Password</button>
                </row>
                        </div>
                    </Collapse>
                </Card>
                </div>
                

              
                
                {/* <h2 className="settings-body-top" style={{marginTop: '2vh'}}>Manage Account Access</h2>
                <row className='settings-row'>
                    <AccountsTable columns={columns} data={accountUsers} />
                </row>
                <row className='settings-row' style={{ marginTop: '2vh'}}>
                    <div style={{ width: '90%'}}>
                    <Select
                        placeholder="Type to search..."
                        name="accountAccessUser"
                        options={options}
                        onChange={(option) => {
                           setSelectedOption(option)
                        }}
                        styles={{
                            control: (provided) => ({
                            ...provided,
                            width: '100%',
                            height: '61px',
                            boxSizing: 'border-box',
                            color: '#414141',
                            background: 'linear-gradient(0deg, #f2f2f2, #f2f2f2), #fff',
                            border: '1px solid #dbdbdb',
                            borderRadius: '8px',
                            outline: 'none',
                            padding: '11px 23px',  // Note: last padding value used, overriding previous
                            fontFamily: 'Montserrat',
                            fontSize: '20px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '30px'
                            }),
                            option: (state, provided) => ({
                            ...provided,
                            fontFamily: 'Montserrat',
                            padding: '0.4rem',
                            backgroundColor: state.isFocused ? '#145388' : 'white',
                            color: state.isFocused ? 'white' : '#414141',
                            ':hover': {
                              backgroundColor: '#145388',
                              color: 'white',
                            }
                            // Add your custom styles here
                            }),
                          
                            
                            // Add more parts to style as needed
                        }}
                        />
                    </div>
                    <button className="green-button" onClick={inviteUser}>Invite</button>
                </row> */}


        </div>
    );
}

export default Settings;

