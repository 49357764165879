import { gql } from '@apollo/client';

export const createMasterApplicationMutation = gql`
    mutation createMasterApplication($input: CreateMasterApplicationInput!){
        createMasterApplication(input: $input) {
            id
            userId
            specialtyId
            paymentStatus
            releaseDate
            name
            createdAt
            updatedAt 
            firstPhase
            secondPhase
            thirdPhase
            fourthPhase
            fifthPhase
            sixthPhase
            seventhPhase
            carryingOutResponsibilities
            carryingOutResponsibilitiesElaboration
            previouslyAppliedToSpecialty
            applicationYear
            personalStatement
            researchExperience {
                category
                title
                authors
                journal
                datePublished
                pubMedId
                isFirstAuthor
                significantResearchExperience
                meeting
                meetingDate
                order
            }
            additionalResearchUrl
            meaningfulExperiences {
                category
                title
                startDate
                endDate
                description
            }
            workExperiences {
                employer
                jobTitle
                startDate
                endDate
                mentorName
                mentorEmail
                description
            }
            shortAnswerQuestions {
                question
                answer
            }
            hobbiesAndInterests
            approvedCodeOfEthics
            shortAnswerQuestions {
                question
                answer
            }
            hobbiesAndInterests
            references{
            name
            company
            role
            phone
            email
            }
            cvUrl
            additionalDocsUrl
            status
        }
    }
`


export const createApplicationMutation = gql`
    mutation createApplication($input: CreateApplicationInput!){
        createApplication(input: $input) {
            id
        }
    }
`

export const CREATE_MANUAL_APPLICATION = gql`
    mutation createManualApplication($input: CreateManualAppInput!){ 
        createManualApplication(input: $input) {
            id
        }
    }
`
