import React,{ useState, useEffect, useContext} from "react";
import GeneralTable from './generalTable';
import './applicationView.css';

const Programs = ({ programs }) => {
    const columns = [
        {
            id: 'name',
            Header: <label >Program Name</label>,
            Cell: ({ row }) => (
                <div >{row.original.name}</div>
            )
        },
        {
            id: 'specialty',
            Header: <label>Specialty</label>,
            Cell: ({ row }) => (
                <div>{row.original.specialtyName}</div>
            )
        },
        {
            id: 'signaled',
            Header: <label>Signaled</label>,
            Cell: ({ row }) => (
                <div>{row.original.signaled === true ? "Yes" : "No"}</div>
            )
        },
        // {
        //     id: 'city',
        //     Header: <label>City</label>,
        //     Cell: ({ row }) => (
        //         <div>{row.original.city}</div>
        //     )
        // },
        // {
        //     id: 'state',
        //     Header: <label>State</label>,
        //     Cell: ({ row }) => (
        //         <div>{row.original.state}</div>
        //     )
        // },
    ]


    return (
        <div className="personal-container">
         <GeneralTable columns={columns} data={programs} onSelectionChange={() => {}} onSelectedApplication={() => {}} sortData={() => {}} />
        </div>
    )
}

export default Programs;