import React,{useState, useEffect} from "react";
import "./index.css";
import eyeClosed from "../../../../assets/imgs/eyeClose.svg";
import openEye from "../../../../assets/imgs/openEye.svg"

const ScreenedItem = ({section, data, setData, updateDataFromScreened}) => {
    const [screenedItems, setScreenedItems] = useState(data);
    console.log(screenedItems)
    console.log(data[section])
    

const displayNameMapping = {
    profilePicture: 'Profile Picture',
    firstName: 'First Name',
    lastName: 'Last Name',
    prefferedName: 'Preferred Name',
    email: 'Email',
    phoneNumber: 'Phone Number',
    genderIdentity: 'Gender',
    nrmpId: 'NRMP ID',
    aamcId: 'AAMC ID',
    hometown: 'Hometown',
    raceEthnicity: 'Race/Ethnicity',
    homeAddress: 'Home Address',
    workAuthorization: 'Work Authorization',
    preventResponsibilities: 'Preventing Responsibilities',
    elaborateResponsibilities: 'Preventing Responsibilities Details',
    misdemeanorOrFelony:'Misdemeanor/Felony Conviction',
    misdemeanorOrFelonyDetails: 'Misdemeanor/Felony Conviction Details',
    couplesMatch: 'Couples Match',
    partnerField: 'Partner Field',
    previouslyApplied: 'Previously Applied to Specialty',
    malpracticeLawsuit: 'Malpractice Lawsuit',
    elaborateMalpractice:'Malpractice Lawsuit Details',
    dateOfBirth: 'Date of Birth',
    medicalSchoolType: 'Medical School Type',
    medicalSchoolName: 'Medical School Name',
    fluencyInLanguagesBesidesEnglish: 'Fluency in Languages Besides English',
    otherLanguagesFluentIn: 'Other Languages Fluent In',
    otherDegreesInstitutionsDates: 'Other Degrees',
    aoaStatus: 'AOA Status',
    ghhsStatus: 'GHHS (Gold Humanism Honor Society) Status',
    medicalSchoolAwardsHonors: 'Medical School Awards/Honors',
    coreClerkshipsGraded: 'Core Clerkships Graded',
    specifyClerkshipDetails: 'Specify Clerkship Details',
    awayRotations: 'Away Rotations',
    nonCoreClerkshipGradedCourses: 'Non-Core Clerkship Graded Courses',
    impactfulResearchPublications: 'Impactful Research Publications',
    additionalResearchBibliography: 'Additional Research Bibliography',
    firstAuthorPublications: 'Number of First Author Publications',
    totalPeerReviewedPublications: 'Number Peer-Reviewed Publications',
    posterPresentations: 'Number of Poster Presentations',
    podiumPresentations: 'Number of Podium Presentations',
    meaningfulExperiences: 'Significant Experiences',
    significantWorkExperiences: 'Work Experiences',
    shortQuestions: 'Short Questions',
    hobbiesInterests: 'Hobbies/Interests',
    previousLastName: 'Previous Last Name',
    pronouns: 'Pronouns',
    usmleId: 'USMLE ID',
    nbomeId: 'NBOME ID',
    aoaId: 'AOA ID',
    permanentAddress: 'Permanent Address',
    licensureRevoked: 'Licensure Revoked',
    militaryObligation: 'Military Obligation',
    otherServiceObligations:'Other Service Obligations',
    medicalTrainingInterrupted: 'Medical Training Interrupted',
    sigmaPhi: 'Sigma Sigma Phi',
}

const updateScreenedItems = (key, value) => {
    console.log("key, value =>", key, value)
    // setData(prevState => ({
    //     ...prevState,
    //     [section]: {
    //         ...prevState[section],
    //         [key]: value
    //     }
    // }));

    updateDataFromScreened(key, value)
}

useEffect(() => {
    setScreenedItems(data[section]);
}, [data, section]);




    return (


<div className="page-wrapper" style={{display:'flex'}}>
    {(section === 'personalStatement' || section === 'documents' || section === 'lors') &&  data.sections[section]&&(
        <div className="screened-item-container" style={{marginTop:'5vh',justifyContent:'center', fontFamily:'Montserrat', width:'100%'}}>
            This Section will be visible to reviewers
            </div>
    )
}
{(section === 'personalInfo' || section=== 'residencyApplication') && data.sections[section]&& (
        <div className="screened-items-container">
        {(screenedItems && Object.keys(screenedItems).filter(key => key !== '__typename').map((key) => (
            <React.Fragment key={key}>
                    <div className="screened-item-container">
                    <div className="screened-item-button" onClick={() => updateScreenedItems(key, !screenedItems[key])}>
                    <img src={screenedItems[key] ? openEye : eyeClosed} alt="eye icon" />
                    <label>{screenedItems[key] ? 'Include' : 'Exclude'}</label>
                </div>
                <div className={screenedItems[key] ? 'screened-item-details' : 'screened-item-details screened'}>
                {displayNameMapping[key] || key}
                </div>
                </div>
            </React.Fragment>
        )))}
</div>
)}

{ !data.sections[section]&& (
  <div className="screened-item-container" style={{marginTop:'5vh',justifyContent:'center', fontFamily:'Montserrat', width:'100%'}}>
  This Section will be hidden from reviewers
  </div>
)
}

</div>



    )};

    export default ScreenedItem;