import React,{useState, useEffect, useContext} from "react";
import "./styles/applicantInterviewManager.css";
import { useQuery, useMutation, useSubscription } from "@apollo/client"; 
import {UserContext} from '../../../context/userContext';
import Spinner from "../../common/spinner";
import ApplicantCalendar from "./components/applicantCalendar";
import InterviewsList from "./components/interviewsList";
import DateDetails from "./components/dateDetails";
import {getApplicantInterviewsQuery,
} from "./graphql/queries";
import {
    declineInterviewInvitationMutation,
    scheduleApplicantInterviewMutation,
    cancelApplicantInterviewMutation
} from "./graphql/mutations";
import {APPLICANT_SCHEDULED_SUBSCRIPTION,
    POPULATED_DATES_CHANGE_SUBSCRIPTION,
}from "./graphql/subs";

import useConditionalSubscription from "./components/useConditionalSubscription";
import logo from '../../../assets/imgs/logo.svg';


const InterviewManager = () => {
  
    const { user } = useContext(UserContext);
    const [applications, setApplications] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [programIds, setProgramIds] = useState([]);
    const [resError, setResError] = useState(null);

    const { loading, error, data } = useQuery(getApplicantInterviewsQuery, {
        variables: { userId: user.userId },
        fetchPolicy: 'network-only'
    });

    const [declineInterviewInvitation] = useMutation(declineInterviewInvitationMutation);
    const [scheduleApplicantInterview] = useMutation(scheduleApplicantInterviewMutation);
    const [cancelApplicantInterview] = useMutation(cancelApplicantInterviewMutation);
    

    const { data: applicantUpdatesData, error: applicantUpdateError } = useConditionalSubscription(APPLICANT_SCHEDULED_SUBSCRIPTION, { programIds });
    const { data: datesToSendData, error: datesToSendError } = useConditionalSubscription(POPULATED_DATES_CHANGE_SUBSCRIPTION, { programIds });
    
useEffect(() => {
    if (datesToSendData) {
        // find the programId from the datesToSendData and locate the applications with the same programId
        // then replace the dates in the applications with the dates from the datesToSendData

        const programId = datesToSendData.populatedDatesChange.programId;
        const dates = datesToSendData.populatedDatesChange.dates;
        let updatedApplications = applications.map(app => {
            if (app.programId === programId) {
                return { ...app, datesToSend: dates };
            }
            return app;
        });

      
        setApplications(updatedApplications);
    }

    
}, [datesToSendData]);



    useEffect(() => {
        if (!loading && data) {
            setApplications(data.getApplicantInterviews);
            // create a new array, take all programIds from the data.getApplicantInterviews and set it to programIds
            const programIds = data.getApplicantInterviews.map(application => application.programId);
            console.log('programIds', programIds);
            setProgramIds(programIds);
        }
    }
    , [data]);

    if (loading) {
        return <Spinner />;
    }

    const handleDeclineInterview = async (applicationId) => {
        setIsLoading(true);
        try {
            await declineInterviewInvitation({
                variables: {
                    applicationId: applicationId
                }
            }).then((res) => {
                // find the index of the application in the applications array
                const index = applications.findIndex(application => application.id === applicationId);
                // remove the application from the applications array
                const updatedApplications = [...applications];
                updatedApplications.splice(index, 1, res.data.declineInterviewInvitation);
                // set the applications array with the updatedApplications
                setApplications(updatedApplications);
                setIsLoading(false);
            }
            );
        } catch (error) {
            console.log(error);
            if (error.graphQLErrors && error.graphQLErrors.length > 0) {
                error.graphQLErrors.forEach(({ message, locations, path }) => {
                    setResError(message)
                    setIsLoading(false)
                });
            }

        }
    }

    const handleScheduleInterview = async (applicationId, date, programId) => {
        setIsLoading(true);
        try {
            const { data: scheduleResponse } = await scheduleApplicantInterview({
                variables: {
                    applicationIds: [applicationId],
                    programId: programId,
                    scheduleDate: date,
                },
            });

            scheduleResponse.scheduleApplicantInterview.forEach(updatedApplication => {
            const index = applications.findIndex(application => application.id === applicationId);
            if (index !== -1) { 
                const updatedApplications = [
                    ...applications.slice(0, index),
                    updatedApplication,
                    ...applications.slice(index + 1)
                ];

                setApplications(updatedApplications);
            }
        });
    
            // Assuming scheduleResponse is successful and you need to update the UI accordingly
          
        } catch (error) {
            console.log(error);
            if (error.graphQLErrors && error.graphQLErrors.length > 0) {
                error.graphQLErrors.forEach(({ message, locations, path }) => {
                    setResError(message)
                    setIsLoading(false)
                });
            }
        } finally {
            setIsLoading(false);
        }
    };



    const handleCancelInterview = async (applicationId, programId) => {
        setIsLoading(true);

        try {
            const cancelResponse = await cancelApplicantInterview({
                variables: {
                    applicationIds: [applicationId],
                    programId: programId
                }
            });

            // Assuming cancelResponse is successful and you need to update the UI accordingly
            const index = applications.findIndex(application => application.id === applicationId);
            if (index !== -1) { // Check if the application was found
                const updatedApplication = {
                    ...applications[index],
                    status: cancelResponse.data.cancelApplicantInterview[0].invitationStatus,
                    interviewDate: cancelResponse.data.cancelApplicantInterview[0].interviewDate
                };

                const newUpdatedApplications = [
                    ...applications.slice(0, index),
                    updatedApplication,
                    ...applications.slice(index + 1)
                ];

                setApplications(newUpdatedApplications);
            }

            setIsLoading(false);
        }
        catch (error) {
            console.log(error);
            if (error.graphQLErrors && error.graphQLErrors.length > 0) {
                error.graphQLErrors.forEach(({ message, locations, path }) => {
                    setResError(message)
                    setIsLoading(false)
                });
            }
        }

    }
    
    

    return (
        <div className='faculty-home-container'>
            <ellipse className='ellipseDesignTop1' />
            {/* <ellipse className='ellipseDesignTop2' /> */}
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="applicant-home-header">
                <h2 className="applicant-home-header-title" >INTERVIEW MANAGER</h2>                
            </div>
            <seperator className="faculty-home-header-seperator"/>
            <div className="applicant-interviewManager-body">
                <div className="applicant-interviewManager-body-left-container">
                    <p className="applicant-im-list-header">
                        INTERVIEWS
                    </p>
                    <seperator style={{marginTop:'1vh'}} className="faculty-home-header-seperator"  />
                    <InterviewsList 
                    programs={applications}
                     handleDeclineInterview={handleDeclineInterview}
                     handleScheduleInterview={handleScheduleInterview}
                     handleCancelInterview={handleCancelInterview}
                     isLoading={isLoading}
                     />
                </div>
                <div className="applicant-interviewManager-body-right">
                    <div className="applicant-interviewManager-body-right-top-container">
                        <ApplicantCalendar 
                        applications={applications}
                        setSelectedDate={setSelectedDate}
                        selectedDate={selectedDate}
                        />
                    </div>
                    <div className="applicant-interviewManager-body-right-bottom-container">
                        <DateDetails
                        selectedDate={selectedDate}
                        applications={applications}
                        handleScheduleInterview={handleScheduleInterview}
                        handleCancelInterview={handleCancelInterview}
                        isLoading={isLoading}
                        />
                    </div>
                </div>

        </div>
        {
            resError && 
            <div className="modal-backdrop">
                <div className="add-field-modal" style={{ height: '30vh', overflow: 'scroll', fontFamily: 'Montserrat'}}>
                    <div className='add-field-modal-header'>
                    <h2>Error</h2>
                    <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
                    </div>
                    {resError}
                    <div className='button-container' style={{display:'flex', flexDirection:'row', justifyContent:'flex-end', gap:'2vw', marginTop: '2vh'}}>
                    <button className='green-button' onClick={() => setResError(null)}>Close</button>
                    </div>
                </div>
            </div>
        }
        </div>
        
    );
}

export default InterviewManager;

