import React,{useState} from "react";
import "./styles/archive.css"
// import Card from "../../common/card";


const Archive = () => {
  
    return (
        <div className='applicant-home-container'>
            <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop2' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="applicant-home-header">
                <h2 className="applicant-home-header-title">ARCHIVE</h2>
                <seperator className="applicant-home-header-seperator"/>
                <p className="applicant-home-header-subtitle">This feature will become available to you after application submission. All applications from previous cycles will remain in your archive for 5 years.</p>
            </div>
            <div className="archive-body">
            </div>

        </div>
    );
}

export default Archive;

