import React,{ useState, useEffect } from "react";
import "./styles/home.css";
import SpecialtyItem from "./SpecialtyItem";
import ProgramsListTable from "./ProgramsListTable";
import backIcon from '../../../assets/imgs/backIcon.svg'; // Import the backIcon image
import timeleftIcon from '../../../assets/imgs/timeLeftIcon.svg'; // Import the timeleftIcon image
import gradCap from '../../../assets/imgs/gradCap.svg'; // Import the timeleftIcon image
import scoreIcon from '../../../assets/imgs/scoreIcon.svg'; // Import the timeleftIcon image
import letterPaper from '../../../assets/imgs/letterPaper.svg'; // Import the timeleftIcon image
import anesthesiology from "../../../assets/imgs/anesthesiology.svg";
import plasticSurgery from "../../../assets/imgs/plasticSurgery.svg";
import specialtyIcon from '../../../assets/imgs/specialtyIcon.svg'; // Import the specialtyIcon image
import { 
    GetSpecialtiesQuery, 
    GetProgramsBySpecialtyIdQuery,
    getMasterApplicationByUserIdAndSpecialtyIdQuery,
    LIST_POSITIONS_FOR_APP,
    GET_POSITION
} from './graphql/queries';
import { createMasterApplicationMutation, updateApplicantGraduationYearMutation } from './graphql/mutations';
import client from '../../../config/apolloClient.js';
import Spinner from "../../common/spinner";
import { useMutation, useQuery } from "@apollo/client";


const ApplicantHome = ({ applicant, setPage, setSelectedApplication, getAllMasterApplications, getApplicant }) => {
    const [isSpecialtyOpen, setIsSpecialtyOpen] = useState(false)
    const [specialtyData, setSpecialtyData] = useState({})
    const [specialties, setSpecialties] = useState(null)
    const [data, setData] = useState([])
    const [applicationExists, setApplicationExists] = useState(false)
    const [loading, setLoading] = useState(false);
    const [graduationUpdated, setGraduationUpdated] = useState(false);
    const [graduationYear, setGraduationYear] = useState(null);
    const [positions, setPositions] = useState([]);
    const [specificPosition, setSpecificPosition] = useState(null);

    const [updateGraduation] = useMutation(updateApplicantGraduationYearMutation);
    const {loading:loadingSpePos, error:errorSpePos, data:dataSpePos} = useQuery(GET_POSITION);

    const {loading:loadingPostions, error:errorPositions, data:dataPositions} = useQuery(LIST_POSITIONS_FOR_APP);

    useEffect(() => {
        if (!loadingPostions && !errorPositions && dataPositions) {



            // console.log("dataPositions =>", dataPositions.listPositionsForApplicant)
            const updatedPostions = dataPositions.listPositionsForApplicant.map(position => {
                return {
                    ...position,
                    programName: position.programDetails.name
                }
            }
            )
            setPositions(updatedPostions);
        }
    }, [loadingPostions, errorPositions, dataPositions]);




    const columns = [
        // {
        //     Header: 'id',
        //     accessor: 'id',
        // },
            {
                Header: 'Title',
                accessor: 'jobTitle',
            },
            {
                Header: 'Description',
                accessor: 'summary',
            },
            {
            Header: 'Recruiter',
            accessor: 'programName',
            },
            {
                Header: 'Action',
                accessor: 'action',
                Cell: ({ row }) => (
                    <div className="dropdown" style={{border:'0',margin:'0',padding:'0'}}>
                    <button className="white-button"  onClick={() => handlePosClick(row)}>
                        View Position
                    </button>
                    </div>
                    // <div style={{display:'flex', flexDirection:'row', gap:'.2vw', marginRight:'.3vw'}}>
                  
                    // </div>
                ),
            },
        ]

        const handlePosClick = (row) => {
            console.log("row.original.id =>", row.original.id); // Debugging line
            client.query({
                query: GET_POSITION,
                variables: {
                    positionId: row.original.id
                }
            })
            .then(res => {
                console.log("position =>", res.data.getPosition);
                setSpecificPosition(res.data.getPosition);
                setIsSpecialtyOpen(true);
            })
        }
    


    const buildProgramsData = (specialtyId) => {
        console.log("specialtyId =>", specialtyId)
        client.query({
            query: GetProgramsBySpecialtyIdQuery,
            variables: {
                specialtyId
            }
        })
        .then(res => {
            console.log("programs result =>", res)
            let programs = res.data.getProgramsBySpecialtyId
            let data = []

            programs.forEach(program => {
                data.push({
                    programName: program.name,
                    location: 'Location 1',
                    learnMore: program.url,
                })
            })

            setData(data)
        })
    }

    useEffect(() => {
        console.log("inside use effect!")
        getSpecialties()
      }, [])

    const getSpecialties = () => {
        client.query({
            query: GetSpecialtiesQuery,
            variables: {},
            fetchPolicy: 'network-only'
        })
        .then(res => {
            let specialties = res.data.getSpecialties
            setSpecialties(specialties)
            console.log("specialties =>", specialties)
        })
    }

    const calculateDaysLeft = (specialty) => {
        console.log("specialty.releaseDate =>", specialty.releaseDate)
        let target = new Date(parseInt(specialty.releaseDate))
        console.log("date =>", target)
         // Get the current date
        const currentDate = new Date();

        // Calculate the difference in milliseconds between the target date and the current date
        const difference = target.getTime() - currentDate.getTime();
        console.log("difference =>", difference)
        // Convert the difference from milliseconds to days
        let daysLeft = Math.ceil(difference / (1000 * 60 * 60 * 24));
        console.log("daysLeft =>", daysLeft)
        if(daysLeft < 0) daysLeft = 0
        console.log("daysLeft =>", daysLeft)
        return daysLeft;
    }


    const startApplication = (specialty) => {
        if(specialty !== null && specialty !== undefined) {
            console.log("data =>", specialty, applicant)
            if(applicationExists) {
                getAllMasterApplications()
                setPage("appManager")
            }
            else {
                setLoading(true)
                client.mutate({
                    mutation: createMasterApplicationMutation,
                    variables: {
                        input: {
                            userId: applicant.id,
                            specialtyId: specialty.id, 
                            paymentStatus: 'unpaid',
                            firstPhase: false,
                            secondPhase: false,
                            thirdPhase: false,
                            fourthPhase: false,
                            fifthPhase: false,
                            sixthPhase: false,
                            seventhPhase: false 
                        }
                    }
                })
                .then(res => {
                    console.log("master application created!", res)
                    let masterApplication = res.data.createMasterApplication
                    setLoading(false)
                    setSelectedApplication({
                        ...masterApplication,
                        title: masterApplication.name,
                        daysLeft: calculateDaysLeft(masterApplication.releaseDate),
                        applicationStatus: CheckStatus(masterApplication),
                        applicationProgress: calculateApplicationProgress(masterApplication) 
                    })
                    if(masterApplication) {
                        setPage("appManager")
                    }
                })
            }
        }
       
       

    }

    const CheckStatus = (application) => {
        console.log("CheckStatus =>", CheckStatus)
        let conditionsResult = application.firstPhase && application.secondPhase && application.thirdPhase && application.fourthPhase && application.fifthPhase && application.sixthPhase
        if(conditionsResult === false) return "In Process"
        return "Closed"
    }

    const calculateApplicationProgress = (application) => {
        let partWeight = parseFloat(100 / 7)
        console.log("application in cal =>", application)
        let progress = 0
        if(application.firstPhase) progress += partWeight
        if(application.secondPhase) progress += partWeight
        if(application.thirdPhase) progress += partWeight
        if(application.fourthPhase) progress += partWeight
        if(application.fifthPhase) progress += partWeight
        if(application.sixthPhase) progress += partWeight
        if(application.seventhPhase) progress += partWeight
        console.log("partWeight =>", partWeight)
        console.log("progress =>", progress)
        return progress
    }

    const updateSpecialty = (spec) => {
        console.log("receive spec =>", spec)
        setSpecialtyData(spec)
        getSpecialties()
        // startApplication(spec)
        // checkIfMasterApplicationExists(spec)
    }

    const checkIfMasterApplicationExists = (specialty) => {
        client.query({
            query: getMasterApplicationByUserIdAndSpecialtyIdQuery,
            variables: {
                userId: applicant.id,
                specialtyId: specialty.id
            }
        })
        .then(res => {
            const masterApplication = res.data.getMasterApplicationByUserIdAndSpecialtyId
            if(masterApplication) {
                console.log("application to select =>", masterApplication)
                let application = {
                    ...masterApplication,
                    title: masterApplication.name,
                    daysLeft: calculateDaysLeft(masterApplication.releaseDate),
                    applicationStatus: CheckStatus(masterApplication),
                    applicationProgress: calculateApplicationProgress(masterApplication) 
                }
                setSelectedApplication(application)
                setApplicationExists(true)
            }
            else {
                setSelectedApplication(null)
                setApplicationExists(false)
            }

        })
    }



    


    return (
        <div className='applicant-home-container' style={{paddingTop:'1vh'}}>
            <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop2' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            {!isSpecialtyOpen && (
                <>
            <div className="applicant-home-header">
                <h2 className="applicant-home-header-title">Smart Recruiting</h2>
                <p className="applicant-home-header-subtitle" style={{fontSize:'1.9vh'}}> Welcome to your Dashboard! Here you can find all open positions currently availble. Click on a postion to learn more about their specific requirements and instructions.</p>
            </div>
            <seperator className="applicant-home-header-seperator"/>
            <div className="applicant-home-body">
            <ProgramsListTable columns={columns} data={positions} />


                </div>
                </>
            )}
            {isSpecialtyOpen && (
                <>
                {/* <img src={specialtyIcon} alt='specialty icon' className='specialtyIconBackground' /> */}
                {/* <img src={ specialtyData.title==='Anesthesiology'?anesthesiology:plasticSurgery} alt='specialty icon' className='specialtyIconBackground' /> */}

                
                <div className="specialty-details-container">
                <div className="return-home-container">
                    <img
                        src={backIcon}
                        alt="Back Icon"
                        className="back-icon"
                        onClick={() => setIsSpecialtyOpen(!isSpecialtyOpen)}
                    />
                    <div className="return-home" onClick={() => setIsSpecialtyOpen(!isSpecialtyOpen)}>Home/{specificPosition.jobTitle}</div>
                </div>
                <div className="specialty-availability-container" style={{justifyContent:'flex-end'}}>
                {/* {specialtyData.isOpen ? <div className="status-tag open">Open</div> : <div className="status-tag closed">Closed</div>} */}
                {/* <div className="status-tag open">Open</div> */}
                <div className="days-left-container">
                    {/* <img src={timeleftIcon} alt="timeleft icon" /> */}
                    {/* <div className="days-left">Application deadline: {specificSpecialty.closingDate}</div> */}
                    <div style={{color:'black', fontWeight:'500', marginLeft:'auto'}} className="days-left">Application deadline: 10/10/2025</div>
                </div>
                </div>
                <div className="specialty-details-title-container">
                    <h2 className="specialty-details-title">{specificPosition.jobTitle}, {specificPosition.programDetails.name}, {specificPosition.department}</h2>
                    { loading && <Spinner /> }
                </div>

                <div className="specialty-details-title-container">
                    <p>{specificPosition.summary}</p>
                </div>

                <div className="specialty-details-description">
                    <p className="specialty-details-description-text">{specialtyData.description}</p>
                </div>
                {/* Position Conditions */}
                <div className="specialty-details-requirements-container">
                    <h3 className="specialty-details-requirements-title">Position Details:</h3>
                    <ul className="specialty-details-requirements-list">
                        <li className="specialty-details-requirements-item"> <img className="specialty-details-requirements-item-icon" src={gradCap}/>Employment Type: {specificPosition.employmentType}</li>
                        <li className="specialty-details-requirements-item"><img className="specialty-details-requirements-item-icon" src={letterPaper}/>Salary Range: {specificPosition.salaryRange.min} - {specificPosition.salaryRange.max}</li>
                        <li className="specialty-details-requirements-item"><img className="specialty-details-requirements-item-icon" src={letterPaper}/>Benefits:
                        {specificPosition.benefitsOffered.map((benefit) => (
                            <li key={benefit}>{benefit}</li>
                        ))}</li>
                        <li className="specialty-details-requirements-item"><img className="specialty-details-requirements-item-icon" src={letterPaper}/>Responsibilities:
                        {specificPosition.responsibilities.map((resp) => (
                            <li key={resp}>{resp}</li>
                        ))}</li>
                        <li className="specialty-details-requirements-item"><img className="specialty-details-requirements-item-icon" src={letterPaper}/>Day to Day Activities:
                        {specificPosition.dayToDayActivities.map((act) => (
                            <li key={act}>{act}</li>
                        ))}</li>
                            <li className="specialty-details-requirements-item"> <img  className="specialty-details-requirements-item-icon" src={letterPaper}/> Work Schedule: {specificPosition.workSchedule}</li>
                            <li className="specialty-details-requirements-item"> <img  className="specialty-details-requirements-item-icon" src={letterPaper}/> Location: {specificPosition.workLocation.city}, {specificPosition.workLocation.state}, {specificPosition.workLocation.country}</li>
                    </ul>
                </div>

                {/* Job Details and Requirements */}

                <div className="specialty-details-requirements-container">
                    <h3 className="specialty-details-requirements-title">Position Requirements:</h3>
                    <ul className="specialty-details-requirements-list">
                    <li className="specialty-details-requirements-item"><img className="specialty-details-requirements-item-icon" src={letterPaper}/>Minimum Qualifications: 
                        {specificPosition.minimumQualifications.map((qual) => (
                            <li key={qual}>{qual}</li>
                        ))}</li>

                    <li className="specialty-details-requirements-item"><img className="specialty-details-requirements-item-icon" src={letterPaper}/>Preferred Qualifications: 
                        {specificPosition.preferredQualifications.map((qual) => (
                            <li key={qual}>{qual}</li>
                        ))}</li>

                    <li className="specialty-details-requirements-item"><img className="specialty-details-requirements-item-icon" src={letterPaper}/>Required Skills: 
                        {specificPosition.requiredSkills.map((skill) => (
                            <li key={skill}>{skill}</li>
                        ))}</li>

                    <li className="specialty-details-requirements-item"><img className="specialty-details-requirements-item-icon" src={letterPaper}/>Preferred Skills: 
                        {specificPosition.preferredSkills.map((skill) => (
                            <li key={skill}>{skill}</li>
                        ))}</li>

                    <li className="specialty-details-requirements-item"> <img className="specialty-details-requirements-item-icon" src={gradCap}/>Education Level: {specificPosition.educationLevel}</li>

                    <li className="specialty-details-requirements-item"><img className="specialty-details-requirements-item-icon" src={letterPaper}/>Certifications:
                        {specificPosition.certifications.map((cert) => (
                            <li key={cert}>{cert}</li>
                        ))}</li>

                    <li className="specialty-details-requirements-item"><img className="specialty-details-requirements-item-icon" src={letterPaper}/>Licenses:
                        {specificPosition.licenses.map((lice) => (
                            <li key={lice}>{lice}</li>
                        ))}</li>
                    <li className="specialty-details-requirements-item"> <img className="specialty-details-requirements-item-icon" src={gradCap}/>Experience Level: {specificPosition.experienceLevel}</li>

                    <li className="specialty-details-requirements-item"><img className="specialty-details-requirements-item-icon" src={letterPaper}/>Languages Required:
                        {specificPosition.languagesRequired.map((lang) => (
                            <li key={lang}>{lang}</li>
                        ))}</li>
                    </ul>
                </div>



                <div className="specialty-details-requirements-container">
                    <h3 className="specialty-details-requirements-title">Application Requirements:</h3>
                    <ul className="specialty-details-requirements-list">
                      
                    <li className="specialty-details-requirements-item"><img className="specialty-details-requirements-item-icon" src={letterPaper}/>Required Documents:
                        {specificPosition.requiredDocuments.map((doc) => (
                            <li key={doc}>{doc}</li>
                        ))}</li>

                    <li className="specialty-details-requirements-item"> <img className="specialty-details-requirements-item-icon" src={gradCap}/>Security Clearance Required: {specificPosition.securityClearanceRequired}</li>
                    <li className="specialty-details-requirements-item"> <img className="specialty-details-requirements-item-icon" src={gradCap}/>Physical Requirements: {specificPosition.physicalRequirements}</li>
                    <li className="specialty-details-requirements-item"> <img className="specialty-details-requirements-item-icon" src={gradCap}/>Background Check Required: {specificPosition.backgroundCheckRequired}</li>
                    <li className="specialty-details-requirements-item"> <img className="specialty-details-requirements-item-icon" src={gradCap}/>Drug Test Required: {specificPosition.drugTestRequired}</li>

                    </ul>
                </div>

                
                <div className="specialty-details-requirements-container">
                    <h3 className="specialty-details-requirements-title">Detailed Description</h3>
                    <p>{specificPosition.detailedDescription}</p>
                </div>


                </div>
              
              
                </>
                )}
        </div>
    );
}

export default ApplicantHome;

