import React, { useState } from 'react';
import './styles/lorModal.css'; // Import the styles
import backIcon from '../../../assets/imgs/backIcon.svg'; // Import the backIcon image
import logo from '../../../assets/imgs/logo.svg'; // Import the logo image
import Select from 'react-select';
import { Formik, Field, ErrorMessage, Form } from 'formik';


const LORModal = ({isVisible, onClose, reccomenders, setLorModalOpen, sendRequest, errorMessage, setLorError }) => {

  const [isManual, setIsManual] = useState(false)
  const [recommender, setRecommender] = useState(null)
  const [isSlor, setIsSlor] = useState(null)

  const handleSubmission = (recommender) => {
    recommender.value.isSlor = isSlor
    sendRequest(recommender)
    setIsManual(false)
    setIsSlor(null)
    setRecommender(null)

  }
  const handleCancel = () => {
    setLorError('')
    setLorModalOpen(false)
    setIsManual(false)
    setRecommender(null)
    setIsSlor(null)
  }
  



  return (
    <>
    {isVisible && (
    <div className="signin-modal-backdrop">
    <div className="lor-modal" style={{background: 'white',
    border: '1px solid #C6C6C6',
    borderRadius: '12px',
    padding: '58px',
    width: '822px',
    margin: '50px 0',
    position:'absolute',
    }}>
    <div className='header-container' style={{flexDirection:'row-reverse', marginBottom:'5vh'}}>
<img src={logo} alt='Logo' className='logo' style={{width:'5vw'}} />
<label style={{fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '2vw',
    textTransform: 'uppercase',
    color: '#171717',
    lineHeight: '39px'}}className='header-text'>REQUEST A LETTER OF RECOMMENDATION</label>
</div>

{!isManual && (
  <>
<Select
     placeholder="Select a recommender (Type in a name or email)"
     name="lorRecommender"
     options={[
        ...reccomenders.map(reccomender => ({value: reccomender, label: `${reccomender.name} - ${reccomender.email}`})),
        {value: 'manual', label: "Can't find your recommender? Enter manually"}
      ]}
      onChange={(option) => {
        setLorError('')
        setRecommender(option)
        if (option.value === 'manual') {
          setIsManual(true)
        } else {
          console.log(option.label, option.value)
        }
      }}
      styles={{
        control: (provided) => ({
          ...provided,
          width: '100%',
          height: '61px',
          boxSizing: 'border-box',
          color: '#414141',
          background: 'linear-gradient(0deg, #f2f2f2, #f2f2f2), #fff',
          border: '1px solid #dbdbdb',
          borderRadius: '8px',
          outline: 'none',
          padding: '11px 23px',  // Note: last padding value used, overriding previous
          fontFamily: 'Montserrat',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '30px'
        }),
        option: (provided) => ({
          ...provided,
          // Add your custom styles here
        }),
        
        // Add more parts to style as needed
      }}
      filterOption={(option, inputValue) => {
        return option.label.toLowerCase().includes(inputValue.toLowerCase()) || option.value === 'manual';
      }}
    />
{recommender !== null&&(
<Select
     placeholder="Which specialties are you applying for?"
     name="isSlor"
     options={[
      {value:'yes', label:'Plastic Surgery (Plastic Surgery SLOR)'},
      {value:'no', label:'Other Specialties (Standard LOR)'}
      ]}
      onChange={(option) => {
        setLorError('')
        setIsSlor(option.value==='yes' ? true : false)
      }}
      styles={{
        control: (provided) => ({
          ...provided,
          width: '100%',
          height: '61px',
          boxSizing: 'border-box',
          color: '#414141',
          background: 'linear-gradient(0deg, #f2f2f2, #f2f2f2), #fff',
          border: '1px solid #dbdbdb',
          borderRadius: '8px',
          outline: 'none',
          padding: '11px 23px',  // Note: last padding value used, overriding previous
          fontFamily: 'Montserrat',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '30px',
          marginTop: '2vh'
        }),
        option: (provided) => ({
          ...provided,
          // Add your custom styles here
        }),
        
        // Add more parts to style as needed
      }}
      filterOption={(option, inputValue) => {
        return option.label.toLowerCase().includes(inputValue.toLowerCase()) || option.value === 'manual';
      }}
    />
    )}
    {errorMessage && <div style={{color: 'red', marginTop:'1vh'}}>{errorMessage}</div>}
        <div className='lor-modal-footer' style={{display:'flex', gap:'2vw', }}></div>


    <div className='lor-modal-footer' style={{display:'flex', gap:'2vw', marginTop:'5vh'}}>
    <button onClick={() => handleSubmission(recommender)} className='lor-modal-back-button' style={{
         border: 'none',
         outline: 'none',
         cursor: 'pointer',
         background: 'linear-gradient(96.75deg, #2a93d5 0%, #596173 133.3%)',
         borderRadius: '7px',
         padding: '12px 30px',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         gap: '5px',
         flexGrow: 1,
         flexBasis: 0,
         color: '#FFFFFF',
         fontWeight:'700'
    }}>
    Send Request
    </button>
    <button className='lor-modal-submit-button' onClick={handleCancel} style={{
          border: 'none',
          outline: 'none',
          cursor: 'pointer',
          background: '#FFFFFF',
          border: '1px solid #145388',
          borderRadius: '7px',
          padding: '12px 30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '5px',
          flexGrow: 1,
          color: '#145388',
          flexBasis: 0,
          fontWeight:'700'
    }}>Cancel</button>
      </div>
      </>
    
)}

{isManual && (
<>
<Formik
    initialValues={{
      recFirstName: '',
      recLastName: '',
      recEmail: '',
      recInst: '',
      isSlor: null
    }}
    onSubmit={values => {
      const value ={
        name: `${values.recFirstName} ${values.recLastName}`,
        email: values.recEmail,
        institution: values.recInst,
        isSlor: values.isSlor === 'yes' ? true : false

      }
      const value1 = {
        value
      }
      console.log("inside submit => ", values.isSlor)
     handleSubmission(value1)
    }}
  >
    <Form style={{ height: '55vh', overflowY: 'auto'}}>
    <div className='application-form-field-group'>
    <label htmlFor="recFirstName" className='applicant-form-field-label'>First Name</label>
    <Field name="recFirstName" placeholder="" className='application-form-standard-input' style={{width:'100%'}} />
    <div className='application-form-error-message'> 
  <ErrorMessage name="recFirstName" component="div" />
  </div>
  </div>

  <div className='application-form-field-group'>
  <label htmlFor="recLastName" className='applicant-form-field-label'>Last Name</label>
  <Field name="recLastName" placeholder="" className='application-form-standard-input'  style={{width:'100%'}}/>
  <div className='application-form-error-message'> 
  <ErrorMessage name="recLastName" component="div" />
  </div>
  </div>


    <div className='application-form-field-group'>
    <label htmlFor="recEmail" className='applicant-form-field-label'>Email</label>
    <Field name="recEmail" placeholder="" className='application-form-standard-input'  style={{width:'100%'}}/>
    <div className='application-form-error-message'> 
  <ErrorMessage name="recEmail" component="div" />
  </div>
  </div>

  <div className='application-form-field-group'>
  <label htmlFor="recInst" className='applicant-form-field-label'>Institution</label>
  <Field name="recInst" placeholder="" className='application-form-standard-input' style={{width:'100%'}}/>
  <div className='application-form-error-message'> 
  <ErrorMessage name="recInst" component="div" />
  </div>
    </div>



    <div className='application-form-field-group single' style={{marginTop:'3vh', marginBottom: 0}}>
  <label htmlFor="isSlor" className='applicant-form-field-label'>Which specialties are you applying for?</label>
  {recommender !== null&&(
<Select
     placeholder="Which specialties are you applying for?"
     name="isSlor"
     options={[
      {value:'yes', label:'Plastic Surgery (Plastic Surgery SLOR)'},
      {value:'no', label:'Other Specialties (Standard LOR)'}
      ]}
      onChange={(option) => {
        setLorError('')
        setIsSlor(option.value==='yes' ? true : false)
      }}
      styles={{
        control: (provided) => ({
          ...provided,
          width: '100%',
          height: '61px',
          boxSizing: 'border-box',
          color: '#414141',
          background: 'linear-gradient(0deg, #f2f2f2, #f2f2f2), #fff',
          border: '1px solid #dbdbdb',
          borderRadius: '8px',
          outline: 'none',
          padding: '11px 23px',  // Note: last padding value used, overriding previous
          fontFamily: 'Montserrat',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '30px',
          marginTop: '2vh'
        }),
        option: (provided) => ({
          ...provided,
          // Add your custom styles here
        }),
        
        // Add more parts to style as needed
      }}
      filterOption={(option, inputValue) => {
        return option.label.toLowerCase().includes(inputValue.toLowerCase()) || option.value === 'manual';
      }}
    />
    )}
  <div className='application-form-error-message'>
    <ErrorMessage name="isSlor" component="div" />
  </div>
</div>
{errorMessage && <div style={{color: 'red', marginTop:'1vh'}}>{errorMessage}</div>}
        <div className='lor-modal-footer' style={{display:'flex', gap:'2vw', marginTop:'5vh'}}>
    {/* <button onClick={() => handleSubmission()} type="submit" className='lor-modal-back-button' style={{ */}
    
    
    
    
    
    
    
    
    
    
    
    
    
    <button type="submit" className='lor-modal-back-button' style={{
         border: 'none',
         outline: 'none',
         cursor: 'pointer',
         background: 'linear-gradient(96.75deg, #2a93d5 0%, #596173 133.3%)',
         borderRadius: '7px',
         padding: '12px 30px',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         gap: '5px',
         flexGrow: 1,
         flexBasis: 0,
         color: '#FFFFFF',
         fontWeight:'700'
    }}>
    Send Request
    </button>
    <button className='lor-modal-submit-button' onClick={handleCancel} style={{
          border: 'none',
          outline: 'none',
          cursor: 'pointer',
          background: '#FFFFFF',
          border: '1px solid #145388',
          borderRadius: '7px',
          padding: '12px 30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '5px',
          flexGrow: 1,
          color: '#145388',
          flexBasis: 0,
          fontWeight:'700'
    }}>Cancel</button>
      </div>
    </Form>
  </Formik>
  
    </>
  

)}
    {/* <div className='lor-modal-footer' style={{display:'flex', gap:'2vw', marginTop:'5vh'}}>
    <button onClick={() => handleSubmission()} className='lor-modal-back-button' style={{
         border: 'none',
         outline: 'none',
         cursor: 'pointer',
         background: 'linear-gradient(96.75deg, #2a93d5 0%, #596173 133.3%)',
         borderRadius: '7px',
         padding: '12px 30px',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         gap: '5px',
         flexGrow: 1,
         flexBasis: 0,
         color: '#FFFFFF',
         fontWeight:'700'
    }}>
    Send Request
    </button>
    <button className='lor-modal-submit-button' onClick={()=>{setLorModalOpen(false); setIsManual(false)}} style={{
          border: 'none',
          outline: 'none',
          cursor: 'pointer',
          background: '#FFFFFF',
          border: '1px solid #145388',
          borderRadius: '7px',
          padding: '12px 30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '5px',
          flexGrow: 1,
          color: '#145388',
          flexBasis: 0,
          fontWeight:'700'
    }}>Cancel</button>
      </div> */}

    </div>
    </div>
    )}
    </>
  );
};

export default LORModal;
