import { gql } from '@apollo/client';

export const inviteApplicantMutation = gql`
    mutation inviteApplicant($input: InviteApplicantInput!){
        inviteApplicant(input: $input) {
            id
            firstName
            lastName
            email
            institutionId
        }
    }

`